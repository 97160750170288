// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
  FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START,
  privateAccountMyBoutiqueFetched,
  privateAccountMyBoutiqueFetchError
} from 'actions/privateAccountMyBoutiqueActions';
import type { FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START_ACTION } from 'actions/privateAccountMyBoutiqueActions';
import PrivateAccountMyBoutiqueApi from 'api/privateAccountMyBoutique/PrivateAccountMyBoutiqueApi';
import type { GeneratorType } from 'sagas/root';
import { convertPrivateAccountMyBoutiqueFromApi } from 'services/PrivateAccountMyBoutique';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function(apiExecutor: ApiExecutorType) {
  const privateAccountMyBoutiqueApi = new PrivateAccountMyBoutiqueApi(apiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START, fetchPrivateAccountMyBoutique);
  };

  function* fetchPrivateAccountMyBoutique(action: FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START_ACTION): Saga<void> {
    try {
      const myBoutique = yield call(privateAccountMyBoutiqueApi.fetchPrivateAccountMyBoutique);

      yield put(privateAccountMyBoutiqueFetched(convertPrivateAccountMyBoutiqueFromApi(myBoutique)));
    } catch (e) {
      yield put(privateAccountMyBoutiqueFetchError(e));
    }
  }
}
