// @flow
import type { RawYoutubePlaylist, YoutubePlaylist } from 'types/YoutubePlaylist';
import { nullYoutubePlaylist } from 'types/YoutubePlaylist';
import { mergeIntoDefault } from 'utils/objectUtils';
import { handleYoutubeThumbnailsUrl } from 'utils/youtubeApiUtils';

export function convertPlaylistFromApi(rawYoutubePlaylist: RawYoutubePlaylist): YoutubePlaylist {
  if (!rawYoutubePlaylist || !rawYoutubePlaylist.items || rawYoutubePlaylist.items.length === 0) {
    return nullYoutubePlaylist;
  }

  const {
    id,
    snippet: {
      localized: { title, description },
      publishedAt,
      thumbnails
    }
  } = rawYoutubePlaylist.items[0];
  return mergeIntoDefault(nullYoutubePlaylist, {
    id,
    url: `/ffr-tv-channel/${id}`,
    title,
    description,
    publishedAt,
    image: handleYoutubeThumbnailsUrl(thumbnails)
  });
}

export function convertPlaylistsFromApi(rawYoutubePlaylist: RawYoutubePlaylist): Array<YoutubePlaylist> {
  if (!rawYoutubePlaylist || !rawYoutubePlaylist.items || rawYoutubePlaylist.items.length === 0) {
    return [];
  }

  return rawYoutubePlaylist.items.map(item =>
    mergeIntoDefault(nullYoutubePlaylist, {
      id: item.id,
      url: `/ffr-tv-channel/${item.id}`,
      title: item.snippet.localized.title,
      description: item.snippet.localized.description,
      publishedAt: item.snippet.publishedAt,
      image: handleYoutubeThumbnailsUrl(item.snippet.thumbnails)
    })
  );
}
