// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START,
  FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS,
  FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE
} from 'actions/ffr-tv/youtubePlaylistsChannelActions';

import { type YoutubePlaylist } from 'types/YoutubePlaylist';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  playlists: Array<YoutubePlaylist>,
  statusPlaylist: Status,
  statusMorePlaylist: Status
};

const initialState: State = {
  playlists: [],
  statusPlaylist: STATUS_LOADING,
  statusMorePlaylist: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START:
      return {
        ...state,
        statusPlaylist: STATUS_LOADING
      };
    case FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS:
      return {
        ...state,
        playlists: action.payload.playlists,
        statusPlaylist: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE:
      return {
        ...state,
        statusPlaylist: STATUS_FAILURE
      };
    default:
      return state;
  }
}
