// @flow
import FidNewBadgeModal from 'components/fragments/modal/modalContents/FidNewBadgeModal';
import { connect } from 'react-redux';

import { postUserFid } from 'actions/userActions';
import type { DispatchProps, StateProps } from 'components/fragments/modal/modalContents/FidNewBadgeModal';
import type { DispatchType, StateType } from 'types/Actions';
import type { EditProfileValueType } from 'types/User';

export default connect(
  (state: StateType): StateProps => ({
    token: state.userState.userPref.token
  }),
  (dispatch: DispatchType): DispatchProps => ({
    postUserFid: (action: string, value: EditProfileValueType, token: string) =>
      dispatch(postUserFid(action, value, token))
  })
)(FidNewBadgeModal);
