// @flow

import { takeLatest, put, call, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  FETCH_VIDEO_PLAYERS_START,
  playersFromVideoFetched,
  playersFromVideoFetchError
} from 'actions/ffr-tv/youtubeVideoPlayersActions';
import JoueurApi from 'api/joueur/JoueurApi';
import type { FETCH_VIDEO_PLAYERS_START_ACTION } from 'actions/ffr-tv/youtubeVideoPlayersActions';

export default function(apiExecutor: ApiExecutorType) {
  const videoPlayerApi = new JoueurApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_VIDEO_PLAYERS_START, fetchVideoPlayers);
  };

  function* fetchVideoPlayers(action: FETCH_VIDEO_PLAYERS_START_ACTION): Saga<void> {
    const { playersName } = action.payload;
    try {
      const players =yield all(playersName.map(playerName => call(videoPlayerApi.fetchJoueurByName, playerName)));
      yield put(playersFromVideoFetched(players));
    } catch (e) {
      yield put(playersFromVideoFetchError(e));
    }
  }
}
