// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import CompetitionsApi from 'api/competition/CompetitionApi';
import { FETCH_COMPETITION_START, competitionFetched, competitionFetchError } from 'actions/competitionActions';

export default function(apiExecutor: ApiExecutorType) {
  const competitionsApi = new CompetitionsApi(apiExecutor);

  return function* competitionsSaga(): GeneratorType {
    yield takeLatest(FETCH_COMPETITION_START, fetchCompetition);
  };

  function* fetchCompetition(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const competitions = yield call(competitionsApi.fetchCompetition, slug);
      
      yield put(competitionFetched(competitions));
    } catch (e) {
      yield put(competitionFetchError());
    }
  }
}
