// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_PLAYLISTS_LINKED_START,
  FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS,
  FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE
} from 'actions/ffr-tv/youtubePlaylistsLinkedActions';

import { type YoutubePlaylist } from 'types/YoutubePlaylist';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  playlists: Array<YoutubePlaylist>,
  statusPlaylists: Status,
  statusMorePlaylists: Status
};

const initialState: State = {
  playlists: [],
  statusPlaylists: STATUS_DEFAULT,
  statusMorePlaylists: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_PLAYLISTS_LINKED_START:
      return {
        ...state,
        statusPlaylists: STATUS_LOADING
      };
    case FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS:
      return {
        ...state,
        playlists:action.payload.playlists,
        statusPlaylists: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE:
      return {
        ...state,
        statusPlaylists: STATUS_FAILURE
      };

    default:
      return state;
  }
}
