// @flow

import type { CompetitionType } from 'types/Competitions';

export const INITIAL_FETCH_COMPETITIONS_START = 'INITIAL_FETCH_COMPETITIONS_START';
export const FETCH_COMPETITIONS_START = 'FETCH_COMPETITIONS_START';
export const FETCH_COMPETITIONS_BY_SLUG_START = 'FETCH_COMPETITIONS_BY_SLUG_START';
export const FETCH_COMPETITIONS_SUCCESS = 'FETCH_COMPETITIONS_SUCCESS';
export const FETCH_COMPETITIONS_BY_SLUG_SUCCESS = 'FETCH_COMPETITIONS_BY_SLUG_SUCCESS';
export const FETCH_COMPETITIONS_FAILURE = 'FETCH_COMPETITIONS_FAILURE';

export type INITIAL_FETCH_COMPETITIONS_START_ACTION = {
  type: 'INITIAL_FETCH_COMPETITIONS_START',
  payload: {
    filters: Object,
  }
};

export type FETCH_COMPETITIONS_START_ACTION = {
  type: 'FETCH_COMPETITIONS_START',
  payload: {
    filters: Object,
  }
};

export type FETCH_COMPETITIONS_BY_SLUG_START_ACTION = {
  type: 'FETCH_COMPETITIONS_BY_SLUG_START',
  payload: {
    slug: string,
  }
};

export type FETCH_COMPETITIONS_BY_SLUG_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_BY_SLUG_SUCCESS',
  payload: {
    competition: CompetitionType,
  }
};

export type FETCH_COMPETITIONS_FAILURE_ACTION = {
  type: 'FETCH_COMPETITIONS_FAILURE'
};

export type FETCH_COMPETITIONS_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_SUCCESS',
  payload: {
    competitions: Array<CompetitionType>
  }
};

export type Action =
  | INITIAL_FETCH_COMPETITIONS_START_ACTION
  | FETCH_COMPETITIONS_START_ACTION
  | FETCH_COMPETITIONS_FAILURE_ACTION
  | FETCH_COMPETITIONS_SUCCESS_ACTION
  | FETCH_COMPETITIONS_BY_SLUG_START_ACTION
  | FETCH_COMPETITIONS_BY_SLUG_SUCCESS_ACTION;


export function initialFetchCompetitions(filters: Object): INITIAL_FETCH_COMPETITIONS_START_ACTION {
  return {
    type: INITIAL_FETCH_COMPETITIONS_START,
    payload: {
      filters,
    }
  };
}

export function fetchCompetitions(filters: Object): FETCH_COMPETITIONS_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_START,
    payload: {
      filters,
    }
  };
}

export function fetchCompetitionsBySlug(slug: string): FETCH_COMPETITIONS_BY_SLUG_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_BY_SLUG_START,
    payload: {
      slug,
    }
  };
}

export function competitionsFetched(competitions: Array<CompetitionType>): FETCH_COMPETITIONS_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_SUCCESS,
    payload: { competitions }
  };
}

export function competitionBySlugFetched(competition: CompetitionType): FETCH_COMPETITIONS_BY_SLUG_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_BY_SLUG_SUCCESS,
    payload: { competition }
  };
}

export function competitionsFetchError(): FETCH_COMPETITIONS_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITIONS_FAILURE
  };
}

