// @flow
import React from 'react';

import type { FullUserType, EditProfileValueType } from 'types/User';
import { convertEncodedHTML } from 'utils/convertUtils';

export type StateProps = {
  userPref: FullUserType
};

export type DispatchProps = {
  displayModal: (modalObject: string) => void,
  postUserPref: (action: string, id: EditProfileValueType, token: string) => void
};

type Props = DispatchProps &
  StateProps & {
    content: any,
    hideModal: () => void
  };

function TicketingRemoveModalContent({ content, displayModal, hideModal, postUserPref, userPref }: Props) {
  if (!content) {
    return null;
  }

  return (
    <div className="modal-content box remove-favorite-player modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-4">Êtes-vous sûr de vous ?</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-3">
          <p className="mb-0">
            {'Voulez-vous vraiment retirer '}
            <strong>{convertEncodedHTML(content.title)}</strong>
            {' de la liste de vos alertes billetterie ?'}
          </p>
        </div>
        <a
          className="btn btn--primary btn--full js-confirm mb-2"
          onClick={() => {
            postUserPref('billetterie-alerte-del', content.id, userPref.token);
            displayModal('MODAL_TICKETING_REMOVE_SUCCESS');
          }}
          role="button"
          tabIndex="0"
        >
          Retirer
        </a>
        <p className="modal__footer-option">
          <a onClick={hideModal} role="button" tabIndex="0">
            Garder cette alerte billetterie
          </a>
        </p>
      </div>
    </div>
  );
}

export default TicketingRemoveModalContent;
