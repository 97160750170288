// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import JoueurApi from 'api/joueur/JoueurApi';
import { fetchJoueurAgregateurFromGraph } from 'api/joueur/JoueurGraphQl';
import SeasonsApi from 'api/seasons/SeasonsApi';
import {
  FETCH_JOUEUR_START,
  joueurFetched,
  joueurFetchError,
  FETCH_JOUEUR_AGREGATEUR_START,
  joueurAgregateurFetched,
  joueurAgregateurFetchError,
  FETCH_JOUEURTEAM_START,
  FETCH_JOUEURTEAM_INITIAL,
  joueursTeamFetched,
  joueursTeamFetchError
} from 'actions/joueurActions';
import { seasonsFetched } from 'actions/seasonsActions';
import { setDefaultSeason } from 'actions/filterOptionsActions';
import type {
  FETCH_JOUEUR_START_ACTION,
  FETCH_JOUEUR_AGREGATEUR_START_ACTION,
  FETCH_JOUEURTEAM_START_ACTION,
  FETCH_JOUEURTEAM_INITIAL_ACTION
} from 'actions/joueurActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const joueurApi = new JoueurApi(apiExecutor);
  const seasonsApi = new SeasonsApi(staticApiExecutor);

  return function* joueurSaga(): GeneratorType {
    yield takeLatest(FETCH_JOUEUR_START, fetchJoueur);
    yield takeLatest(FETCH_JOUEUR_AGREGATEUR_START, fetchJoueurAgregateur);
    yield takeLatest(FETCH_JOUEURTEAM_START, fetchJoueursTeam);
    yield takeLatest(FETCH_JOUEURTEAM_INITIAL, initialFetchJoueursTeam);
  };

  function* fetchJoueur(action: FETCH_JOUEUR_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const joueur = yield call(joueurApi.fetchJoueurBySlug, slug);
      yield put(joueurFetched(joueur));
    } catch (e) {
      yield put(joueurFetchError(e));
    }
  }

  function* fetchJoueurAgregateur(action: FETCH_JOUEUR_AGREGATEUR_START_ACTION): Saga<void> {
    try {
      const { id } = action.payload;
      const joueurAgregateur = yield call(fetchJoueurAgregateurFromGraph, id);

      yield put(joueurAgregateurFetched(joueurAgregateur));
    } catch (e) {
      yield put(joueurAgregateurFetchError(e));
    }
  }

  function* initialFetchJoueursTeam(action: FETCH_JOUEURTEAM_INITIAL_ACTION): Saga<void> {
    try {
      const { filters } = action.payload;
      const { equipe } = action.payload.filters;
      const seasons = yield call(seasonsApi.getSeasonsList, equipe);
      const seasonsJoueurs = seasons.filter(season => season.joueurs === true);
      const joueurs = yield call(joueurApi.fetchJoueursTeam, {
        ...filters,
        season: filters.season ? filters.season : seasonsJoueurs[seasonsJoueurs.length - 1].id
      });
      let defaultSeason = seasonsJoueurs[seasonsJoueurs.length - 1].name;
      if (filters.season && filters.season[0]) {
        const saisonFilter = seasonsJoueurs.filter(saison => saison.id === filters.season[0]);
        if (saisonFilter && saisonFilter[0]) {
          defaultSeason = saisonFilter[0].name;
        }
      }
      yield put(setDefaultSeason(defaultSeason));
      yield put(seasonsFetched(seasons));
      yield put(joueursTeamFetched(joueurs));
    } catch (e) {
      yield put(joueursTeamFetchError(e));
    }
  }

  function* fetchJoueursTeam(action: FETCH_JOUEURTEAM_START_ACTION): Saga<void> {
    try {
      const { filters } = action.payload;
      const joueurs = yield call(joueurApi.fetchJoueursTeam, filters);
      yield put(joueursTeamFetched(joueurs));
    } catch (e) {
      yield put(joueursTeamFetchError(e));
    }
  }
}
