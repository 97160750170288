// @flow
import App from 'components/app/App';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchSettings, increaseDashboardPopInCount, increaseNewsletterPopInCount } from 'actions/appActions';
import { fetchCategories } from 'actions/categoriesActions';
import { fetchMenu } from 'actions/menuActions';
import { displayModal } from 'actions/modalActions';
import { fetchPageDetailPreview } from 'actions/pageActions';
import { fetchPartners } from 'actions/partnerActions';
import { keycloakConnect, postUserPref } from 'actions/userActions';
import type { StateProps, DispatchProps } from 'components/app/App';
import type { StateType, DispatchType } from 'types/Actions';
import type { EditProfileValueType } from 'types/User';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      acceptCookie: state.appState.acceptCookie,
      dashboard_popup: state.settingsState.dashboard_popup,
      dashboardConnected: state.appState.dashboardConnected,
      dashboardPopInCount: state.appState.dashboardPopInCount,
      error: state.userState.error,
      favicons: state.settingsState.favicons,
      keycloakData: state.userState.keycloakData,
      linkPreview: state.pageDetailPreviewState.link,
      login_url: state.settingsState.login_url,
      newsletterSubscribed: state.appState.newsletterSubscribed,
      newsletterPopInCount: state.appState.newsletterPopInCount,
      titleLigue: state.settingsState.title,
      userPref: state.userState.userPref,
      current_competition: state.settingsState.current_competition,
    }),
    (dispatch: DispatchType): DispatchProps => ({
      displayModal: (modalObject: string, content: Object) => dispatch(displayModal(modalObject, content)),
      fetchCategories: () => dispatch(fetchCategories()),
      fetchMenu: () => dispatch(fetchMenu()),
      fetchPageDetailPreview: (id: number) => dispatch(fetchPageDetailPreview(id)),
      fetchPartners: () => dispatch(fetchPartners()),
      fetchSettings: () => dispatch(fetchSettings()),
      increaseDashboardPopInCount: () => dispatch(increaseDashboardPopInCount()),
      increaseNewsletterPopInCount: () => dispatch(increaseNewsletterPopInCount()),
      keycloakConnect: () => dispatch(keycloakConnect()),
      postUserPref: (action: string, value: EditProfileValueType, token: string) =>
        dispatch(postUserPref(action, value, token))
    })
  )(App)
);
