// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ID_CHANNEL } from 'constants/tvConstants';

import {
  FETCH_YOUTUBE_VIDEOS_PLAYLIST_START,
  VideosPlaylistFetched,
  VideosPlaylistFetchError,
  type FETCH_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION
} from 'actions/ffr-tv/youtubeVideosPlaylistActions';

import {
  FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START,
  moreVideosPlaylistFetched,
  moreVideosPlaylistFetchError,
  type FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION
} from 'actions/ffr-tv/youtubeMoreVideosPlaylistActions';

import VideosPlaylistApi from 'api/ffrtv/VideosPlaylistApi';

export default function(apiExecutor: ApiExecutorType) {
  const videosPlaylistApi = new VideosPlaylistApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_VIDEOS_PLAYLIST_START, fetchYoutubeVideosPlaylist);
    yield takeLatest(FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START, fetchMoreYoutubeVideosPlaylist);
  };

  function* fetchYoutubeVideosPlaylist(action: FETCH_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION): Saga<void> {
    try {
      const {
        payload: { idPlaylist }
      } = action;
      const rawYoutubePlaylistItems = yield call(videosPlaylistApi.fetchPlaylistItems, idPlaylist, 50);
      if (rawYoutubePlaylistItems.channelId !== YOUTUBE_ID_CHANNEL) {
        yield put(VideosPlaylistFetchError());
      } else {
        const listvideoId = rawYoutubePlaylistItems.youtubePlaylistItem.map(item => item.id);
        const listvideos = yield call(videosPlaylistApi.fetchYoutubeVideoFromIds, listvideoId);
        yield put(VideosPlaylistFetched(listvideos, rawYoutubePlaylistItems.nextPageToken));
      }
    } catch (e) {
      yield put(VideosPlaylistFetchError());
    }
  }

  function* fetchMoreYoutubeVideosPlaylist(action: FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION): Saga<void> {
    const {
      payload: { idPlaylist, nextPageToken }
    } = action;
    try {
      const rawYoutubePlaylistItems = yield call(videosPlaylistApi.fetchPlaylistItems, idPlaylist, 50, nextPageToken);
      const listvideoId = rawYoutubePlaylistItems.youtubePlaylistItem.map(item => item.id);
      const listvideos = yield call(videosPlaylistApi.fetchYoutubeVideoFromIds, listvideoId);
      yield put(moreVideosPlaylistFetched(listvideos, rawYoutubePlaylistItems.nextPageToken));
    } catch (e) {
      yield put(moreVideosPlaylistFetchError());
    }
  }
}
