// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';

export type LogeRencontreType = {
  id: number,
  adversaire: string,
  reception: boolean,
  france_logo: string,
  adversaire_logo: string,
  date_rencontre: string,
  equipe: string,
  link: string,
};

export type LogeListType = {
  id: number,
  title: string,
  order: number,
  description: string,
  meta_title: string,
  meta_description: string,
  slug: string,
  link: string,
  nombreDePlace: number,
  exclusivite: string,
  prix: number,
  prix_base: number,
  repas: string,
  cadeau: string,
  placeDeParking: string,
  logeDescription: string,
  rencontres: Array<LogeRencontreType>,
  featured_media: {
    src: string,
  },
  venue: string,
  position: string,
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
  featuredVideo?: Object,
};

export type RawLogeListType = SEOMetaType & {
  id: number,
  title: {
    raw: string,
  },
  content: {
    data: Array<BlocContent>
  },
  slug: string,
  link: string,
  excerpt: {
    raw: string,
  },
  meta_title: string,
  meta_description: string,
  featured_media: {
    src: string,
    legend: string
  },
  menu_order: number,
  rencontres: Array<LogeRencontreType>,
  meta: {
    nombreDePlace: number,
    exclusivite: string,
    repas: string,
    cadeau: string,
    placeDeParking: string,
    description: string,
    prix: number,
    prix_base: number,
    venue: string,
    featuredVideo?: Object,
    position: string,
  },
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
};

export const nullLogeList: LogeListType = {
  id: 0,
  title: '',
  description: '',
  slug: '',
  order: 0,
  link: '',
  meta_title: '',
  meta_description: '',
  nombreDePlace: 0,
  prix: 0,
  prix_base: 0,
  exclusivite: '',
  repas: '',
  cadeau: '',
  placeDeParking: '',
  logeDescription: '',
  venue: '',
  position: '',
  rencontres: [{
    id: 0,
    adversaire: '',
    reception: false,
    france_logo: '',
    adversaire_logo: '',
    equipe: '',
    date_rencontre: '',
    link: '',
  }],
  featured_media: {
    src: '',
  },
  featuredVideo: {},
  tags_list: [{
    id: 0,
    name: '',
    image: {
      src: '',
    },
  }],
};
