// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  SEND_CENTENAIRE_FORM_START,
  SEND_CENTENAIRE_FORM_SUCCESS,
  SEND_CENTENAIRE_FORM_FAILURE,
} from 'actions/centenaireFormActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  sendStatus: Status,
};

const initialState: State = {
  sendStatus: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case SEND_CENTENAIRE_FORM_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case SEND_CENTENAIRE_FORM_SUCCESS:
      return {
        ...state,
        sendStatus: STATUS_SUCCESS
      };
    case SEND_CENTENAIRE_FORM_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    default:
      return state;
  }
}
