// @flow

export const YOUTUBE_FFR_CHANNEL_ID = 'UCnH0bSmQqDBfNj9kZzCaINw';
export const YOUTUBE_SPOTLIGHT_PLAYLIST_ID = 'PLrmhsG_kz7UOo_ytZvdc0Uhu6AGesOmVr';
export const YOUTUBE_PLAYLIST_OF_THE_MONTH_ID = 'PLrmhsG_kz7UPPbZwXkVAV_pAmPMOOc0lB';
export const YOUTUBE_PLAYLIST_RUGBY_A_7_ID = 'PLrmhsG_kz7UP-WLnXCMV_-iH0BnLfuQna';
export const YOUTUBE_PLAYLIST_ARBITRAGE_ID = 'PLrmhsG_kz7UNjxkc4XbaAUL1dpBn7Dnlo';
export const YOUTUBE_ID_CHANNEL = 'UCnH0bSmQqDBfNj9kZzCaINw';
export const NB_VIDEOS_RENDER = 15;
export const NB_PLAYLISTS_RENDER = 15;
// Premier élement du tableau doit être l'id de la playlist de la sélection du mois.
// Les ids suivants affichent autant de playlist que vous passez d'ids de playlist sur la page ffr-home
export const TAB_PLAYLISTS_RENDER_HOME = [
  YOUTUBE_PLAYLIST_OF_THE_MONTH_ID,
  YOUTUBE_PLAYLIST_RUGBY_A_7_ID,
  YOUTUBE_PLAYLIST_ARBITRAGE_ID
];
export const YOUTUBE_REGEX = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌[\w\?‌=]*)?/gm;  // eslint-disable-line max-len
export const ARTICLE_REGEX = new RegExp(
  `http(?:s?):\\/\\/(?:www\.)?${window.location.hostname}:${
    window.location.port
  }\\/actualites\\/([\\w\\-_]*)\\/([\\w\\-_]*)?`,
  'g'
);
