// @flow
import type { RencontreType } from 'types/Rencontre';

export type ListHomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BreadcrumbType = {
  name: string,
  to: string
};

export type HomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slugs: Object,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocMainPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string
};

export type EntrepriseBreadcrumb = {
  slug: string,
  title: string
};

export type BlocFaq = {
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  parent_slugs: string[],
  texte_cta: string
};

export type ClubSemaine = {
  title: string,
  slug_complet: string,
  embleme: string
};

export type HomePratiques = {
  titre_liste: string,
  bloc_principal: BlocMainPratique,
  pratiques: {
    pratique_5: BlocPratique,
    pratique_7: BlocPratique,
    pratique_10: BlocPratique,
    pratique_15: BlocPratique
  }
};

export type FaviconType = {
  'android-192': string,
  'android-256': string,
  'apple-touch-icon-152': string,
  'apple-touch-icon-180': string,
  'favicon-16': string,
  'favicon-32': string,
  'mstile-150': string
};

export type liensRsType = {
  fb_lien: string,
  instagram_lien: string,
  twitter_lien: string,
  youtube_lien: string,
  linkedin_lien: string,
  twitch_lien: string,
  snapchat_lien: string,
  tik_tok_lien: string,
  x_lien: string,
  discord_lien: string
};

export type ParionsSportCoteType = {
  URL: string,
  date_retraitement: string,
  delai: string,
  event_description: string,
  event_id: string,
  event_path_1_description: string,
  event_path_1_id: string,
  event_path_1_tagDescription: string,
  event_path_2_description: string,
  event_path_2_id: string,
  event_path_2_tagDescription: string,
  event_startDateTime: string,
  event_startDateTime_NEW: string,
  event_type: string,
  market_cutoffDateTime: string,
  market_cutoffDateTime_NEW: string,
  market_description: string,
  market_id: string,
  market_marketTypeId: string,
  market_status: string,
  outcome_description: string,
  outcome_id: string,
  outcome_price: string,
  outcome_price_2: string,
  period_description: string,
  period_id: string,
  sport_description: string,
  sport_id: string
};

export const nullParionsSportCote = {
  URL: '',
  date_retraitement: '',
  delai: '',
  event_description: '',
  event_id: '',
  event_path_1_description: '',
  event_path_1_id: '',
  event_path_1_tagDescription: '',
  event_path_2_description: '',
  event_path_2_id: '',
  event_path_2_tagDescription: '',
  event_startDateTime: '',
  event_startDateTime_NEW: '',
  event_type: '',
  market_cutoffDateTime: '',
  market_cutoffDateTime_NEW: '',
  market_description: '',
  market_id: '',
  market_marketTypeId: '',
  market_status: '',
  outcome_description: '',
  outcome_id: '',
  outcome_price: '',
  outcome_price_2: '',
  period_description: '',
  period_id: '',
  sport_description: '',
  sport_id: ''
};

export type SettingsType = {
  ligue: string,
  title: string,
  description: string,
  breadcrumb_offres_entreprise: EntrepriseBreadcrumb[],
  dashboard_popup: {
    format: string,
    image_desktop: string,
    image_mobile: string
  },
  url: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  cat_calendrier_equipes: string[],
  saison_en_cours: number,
  liste_cards: ListHomeCardType[],
  class_ligue: string,
  titre_liste_cards: string,
  gerer_club: HomeCardType,
  bulletin_officiel: HomeCardType,
  trouver_pratiques: HomePratiques,
  accreditations: Array<{ label: string, value: string }>,
  clubs_semaine: ClubSemaine[],
  bloc_entreprise: {
    object_id: number,
    type_name: string,
    title: string,
    description: string,
    texte_cta: string,
    featured_image: string,
    featured_media: Object,
    slug_complet: string
  },
  bloc_trouver_club: {
    featured_image: string,
    featured_media: Object
  },
  blocs_faq: BlocFaq[],
  blocs_trouver_club: BlocFaq[],
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  push_rencontre?: RencontreType,
  pratiques: string[],
  pratiques_clubs: string[],
  boutique: {
    link: string,
    title: string,
    image: string,
    description: string,
    cta: string,
    team_home: boolean,
    banner_only: boolean
  },
  fid_page: string,
  home_push_event: {
    title: string,
    lien: string,
    cta: string,
    description: string,
    background: string,
    background_mobile: string,
    classsup: string
  },
  private_gestion_activite: ListHomeCardType[],
  private_maboutique: {
    title: string,
    lien: string,
    cta: string,
    products: {
      title: string,
      url: string,
      image: string,
      discount: string
    }[]
  },
  login_url: string,
  logout_url: string,
  current_competition: {
    slug: string,
    name: string,
    label: string
  },
  current_competitions: CurrentCompetitionsType[],
  parionsSportCote: ParionsSportCoteType
};

export type CurrentCompetitionsType = {
  slug: string,
  name: string,
  label: string,
  home_block_data: {
    title_one: string,
    title_two: string,
    title_three: string,
    description: string,
    cta: string,
    link: string,
    image: string,
    logo: string,
    title_is_centered: boolean
  }
};

export type RawSettingsType = {
  ligue: string,
  title: string,
  description: string,
  url: string,
  email: string,
  breadcrumb_offres_entreprise: EntrepriseBreadcrumb[],
  dashboard_popup: {
    format: string,
    image_desktop: string,
    image_mobile: string
  },
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  cat_calendrier_equipes: string[],
  saison_en_cours: number,
  class_ligue: string,
  titre_liste_cards: string,
  liste_cards: HomeCardType[],
  'gerer-club': HomeCardType,
  'bulletin-officiel': HomeCardType,
  liste_blocs_un: {
    gros_bloc: HomeCardType,
    petit_bloc: HomeCardType
  },
  fid_page: string,
  home_push_event:
    | false
    | {
        title: string,
        lien: string,
        cta: string,
        description: string,
        background: string,
        background_mobile: string,
        classsup: string
      },
  blocs_faq: BlocFaq[],
  blocs_trouver_club: BlocFaq[],
  bloc_trouver_club: {
    featured_image: string,
    featured_media: Object
  },
  trouver_pratiques: HomePratiques,
  accreditations: Array<{ label: string, value: string }>,
  clubs_semaine: ClubSemaine[],
  bloc_entreprise: {
    object_id: number,
    type_name: string,
    title: string,
    description: string,
    texte_cta: string,
    featured_image: string,
    featured_media: Object,
    slug_complet: string
  },
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  pratiques: string[],
  pratiques_clubs: string[],
  push_rencontre?: RencontreType,
  boutique: {
    link: string,
    title: string,
    image: string,
    description: string,
    cta: string,
    team_home: boolean,
    banner_only: boolean
  },
  private_gestion_activite: ListHomeCardType[],
  private_maboutique: {
    title: string,
    lien: string,
    cta: string,
    products: {
      title: string,
      url: string,
      image: string,
      discount: string
    }[]
  },
  login_url: string,
  logout_url: string,
  current_competition: {
    slug: string,
    name: string,
    label: string
  },
  current_competitions: CurrentCompetitionsType[],
  parions_sport_cote: ParionsSportCoteType
};

export const nullSettings: SettingsType = {
  ligue: '',
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  logo_transparent: '',
  breadcrumb_offres_entreprise: [],
  dashboard_popup: {
    format: 'image',
    image_desktop: '',
    image_mobile: ''
  },
  cat_calendrier_equipes: [],
  saison_en_cours: 0,
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  class_ligue: '',
  titre_liste_cards: '',
  gerer_club: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {},
    featured_image: {},
    texte_cta: ''
  },
  bulletin_officiel: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {
      '1': '',
      '2': ''
    },
    featured_image: {},
    texte_cta: ''
  },
  accreditations: [],
  clubs_semaine: [],
  trouver_pratiques: {
    titre_liste: 'Trouver le rugby qui vous correspond',
    bloc_principal: {
      object_id: 0,
      type_name: '',
      slug_complet: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    },
    pratiques: {
      pratique_5: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_7: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_10: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_15: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      }
    }
  },
  bloc_entreprise: {
    object_id: 0,
    type_name: '',
    title: '',
    description: '',
    texte_cta: '',
    featured_image: '',
    featured_media: {},
    slug_complet: ''
  },
  bloc_trouver_club: {
    featured_image: '',
    featured_media: {}
  },
  blocs_faq: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  blocs_trouver_club: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: '',
    linkedin_lien: '',
    twitch_lien: '',
    snapchat_lien: '',
    tik_tok_lien: '',
    x_lien: '',
    discord_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  actualite_topbar: {
    link: '',
    title: '',
    label: ''
  },
  pratiques: [],
  pratiques_clubs: [],
  boutique: {
    link: '',
    title: '',
    image: '',
    description: '',
    cta: '',
    team_home: false,
    banner_only: false
  },
  fid_page: '',
  home_push_event: {
    title: '',
    lien: '',
    cta: '',
    description: '',
    background: '',
    background_mobile: '',
    classsup: ''
  },
  private_gestion_activite: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  private_maboutique: {
    title: '',
    lien: '',
    cta: '',
    products: [
      {
        title: '',
        url: '',
        image: '',
        discount: ''
      }
    ]
  },
  login_url: '',
  logout_url: '',
  current_competition: {
    slug: '',
    name: '',
    label: ''
  },
  current_competitions: [],
  parionsSportCote: nullParionsSportCote
};
