// @flow

import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import { convertVideoPlayerFromApi } from 'services/PlayerVideo';

function VideoPlayerApi(executor: ApiExecutorType) {
  return { fetchVideoPlayer };

  function fetchVideoPlayer(idVideo: string) {
    const url = `/videos?key=${YOUTUBE_ACCESS_TOKEN}&part=snippet&id=${idVideo}`;
    return executor.get(url).then(response => convertVideoPlayerFromApi(response));
  }
}

export default VideoPlayerApi;
