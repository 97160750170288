// @flow
import React, { PureComponent } from 'react';
import { getPlaceholder } from 'utils/assetsUtils';
import _ from 'lodash';

type Props = {
  illustration: string,
  srcset: Object,
  forceState: ?'thumbnail' | 'medium' | 'medium_large' | 'large',
  baliseImg?: boolean,
  itemProp?: string,
};

type State = {
  lockState: boolean,
};

type SourceType = {
  type: string,
  src: string,
  width: number,
  height: number,
};

class BackgroundImage extends PureComponent<Props, State> {
  htmlElement: ?HTMLElement;
  props: Props;
  state: State = {
    lockState: false,
  };

  static defaultProps = {
    srcset: {
      type: 'default',
      illustration: '',
      width: 0,
      height: 0,
    },
    forceState: null,
  };

  get isLocked() {
    return this.state.lockState;
  }

  componentDidMount() {
    this.updateBackgroundSource(this.getLightSource());
    this.lazyLoad(this.getTargetSource());
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.srcset.type === 'default' && this.props.srcset.type !== 'default') {
      this.updateBackgroundSource(this.getBetterSource());
    }
    if (prevProps.illustration !== this.props.illustration) {
      this.lazyLoad(this.getTargetSource());
    }
  }

  getFallback(): SourceType {
    const { illustration } = this.props;

    const src = illustration && illustration.length > 0
      ? illustration
      : getPlaceholder();

    return {
      type: 'default',
      src,
      width: 0,
      height: 0,
    };
  }

  getLightSource() {
    const { srcset } = this.props;
    if (srcset && srcset.thumbnail) {
      return {
        type: 'thumbnail',
        ...srcset.thumbnail
      };
    }

    return this.getFallback();
  }

  getTargetSource() {
    const { forceState, srcset } = this.props;

    if (!forceState) {
      return this.getBetterSource();
    }

    if (srcset && srcset.hasOwnProperty(forceState)) {
      return {
        type: forceState,
        ...srcset[forceState],
      };
    }

    return this.getFallback();
  }

  getBetterSource() {
    const { srcset } = this.props;
    if (srcset && srcset.medium_large) {
      return {
        type: 'medium_large',
        ...srcset.medium_large,
      };
    }

    if (srcset && srcset.medium) {
      return {
        type: 'medium',
        ...srcset.medium,
      };
    }

    return this.getFallback();
  }

  lazyLoad(source: SourceType): void {
    const { src, width, height } = source;
    const image = new Image();
    image.onload = (e: Event) => this.updateBackgroundSource(source);

    image.src = src || '';
    width && (image.width = width);
    height && (image.height = height);
  }

  updateBackgroundSource(source: SourceType): void {
    if (!this.htmlElement || !source) {
      return;
    }

    this.htmlElement.style.backgroundImage = `url("${source.src}")`;
    this.htmlElement.setAttribute('data-background-type', source.type);
  }

  render() {
    const rest = _.omit(this.props,
      'illustration',
      'srcset',
      'forceState',
      'baliseImg',
      'itemProp'
    );
    const {itemProp} = this.props;
    return (
      <div
        ref={ref => this.htmlElement = ref}
        {...rest} >
        {this.props.baliseImg && 
          <img src={this.props.illustration} itemProp={itemProp} alt=""/>
        }
      </div>
    );
  }

}

export default BackgroundImage;
