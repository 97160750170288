// @flow

import React from 'react';

import CardCarousel from 'components/fragments/card/CardCarousel';
import CardVideo from 'components/fragments/card/CardVideo';
import { Link } from 'react-router-dom';

export default function YoutubePlaylist(props: Object) {
  let { isdefaultplaylist, ishomeplaylist, videos, title, playlistid } = props;

  if (!videos) {
    return null;
  }

  try {
    videos = JSON.parse(videos);
  } catch (e) {
    return null;
  }

  if (!Array.isArray(videos)) {
    return null;
  }

  videos = videos.filter(({ kind, status }) => 'youtube#playlistItem' && status && status.privacyStatus === 'public');
  if (!videos.length) {
    return null;
  }

  return (
    <div
      className={`content-bloc--youtube-playlist${ishomeplaylist === 'true' ? ' home-playlist' : ''}${
        isdefaultplaylist === 'true' ? ' default-playlist' : ''
      }`}
    >
      <section className="section">
        {title && <h2 className="">{title}</h2>}

        <div className="container">
          <CardCarousel
            url=""
            elements={videos.slice(1).map(({ id, snippet, contentDetails }) => ({
              id,
              title: snippet.title,
              duration: '',
              img: snippet.thumbnails.medium.url,
              url: `/ffr-tv-player/${contentDetails.videoId}`
            }))}
            component={CardVideo}
            isSmall={false}
          />
        </div>

        <Link to={`/ffr-tv-playlist/${playlistid}`} className="link-chevron link-chevron--block mt-1">
          <span>Voir toutes les vidéos</span>
          <i className="icon icon-chevron-right"></i>
        </Link>
      </section>
    </div>
  );
}
