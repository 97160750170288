/* eslint-disable react/prop-types */
import React from 'react';
// import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from 'swiper/modules';
// https://stackoverflow.com/a/71980866 => mais si différent message d'erreur sur le front
// https://swiperjs.com/react#usage-with-create-react-app => casse tout pareil
import 'swiper/css';
import "swiper/css/navigation";

import CardVideo from 'components/fragments/card/CardVideo';

import './YoutubePlaylistCarousel.css';

function YoutubePlaylistHome({ attrs }) {
  let videos = attrs.videos;

  if (!videos) {
    return null;
  }

  try {
    videos = JSON.parse(videos);
  } catch (e) {
    return null;
  }

  if (!Array.isArray(videos)) {
    return null;
  }

  videos = videos.filter(({ kind, status }) => 'youtube#playlistItem' && status && status.privacyStatus === 'public');
  if (!videos.length) {
    return null;
  }

  return (
    <Swiper
      className="youtube-plylist--carousel"
      modules={[Navigation, A11y]}
      spaceBetween={20}
      slidesPerView={'auto'}
      centeredSlides={true}
      watchSlidesProgress={true}
      loop={true}
      navigation
    >
      {videos.map((video, index) => {
        return (
          <SwiperSlide key={video.id}>
            <CardVideo
              id={video.id}
              img={video.snippet.thumbnails.medium.url}
              title={video.snippet.title}
              url={`/ffr-tv-player/${video.contentDetails.videoId}`}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default YoutubePlaylistHome;
