// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';
import type { YoutubePlaylistItem } from 'types/YoutubePlaylistItems';

export const FETCH_YOUTUBE_VIDEOS_CHANNEL_START = 'FETCH_YOUTUBE_VIDEOS_CHANNEL_START';
export const FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS = 'FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS';
export const FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE = 'FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE';
export const FETCH_YOUTUBE_VIDEO_CHANNEL_START = 'FETCH_YOUTUBE_VIDEO_CHANNEL_START';
export const FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS = 'FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS';
export const FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE = 'FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE';

export type FETCH_YOUTUBE_VIDEOS_CHANNEL_START_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_CHANNEL_START',
  payload: { idChannel: string, firstvideoId?: string }
};

export type FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS',
  payload: {
    playlistItems: Array<YoutubePlaylistItem>
  }
};

export type FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE'
};

export type FETCH_YOUTUBE_VIDEO_CHANNEL_START_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEO_CHANNEL_START',
  payload: { idChannel: string }
};

export type FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS',
  payload: {
    video: YoutubeVideo,
    idChannelUploads: string
  }
};

export type FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE'
};

export type Action =
  | FETCH_YOUTUBE_VIDEOS_CHANNEL_START_ACTION
  | FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION
  | FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION
  | FETCH_YOUTUBE_VIDEO_CHANNEL_START_ACTION
  | FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE_ACTION
  | FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS_ACTION;

export function fetchVideosChannel(
  idChannel: string,
  firstvideoId?: string
): FETCH_YOUTUBE_VIDEOS_CHANNEL_START_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_CHANNEL_START,
    payload: {
      idChannel,
      firstvideoId
    }
  };
}

export function VideosChannelFetched(
  playlistItems: Array<YoutubePlaylistItem>,
): FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS,
    payload: { playlistItems }
  };
}

export function VideosChannelFetchError(): FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE
  };
}

export function fetchVideoChannel(idChannel: string): FETCH_YOUTUBE_VIDEO_CHANNEL_START_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEO_CHANNEL_START,
    payload: { idChannel }
  };
}

export function VideoChannelFetched(
  video: YoutubeVideo,
  idChannelUploads: string
): FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS,
    payload: { video, idChannelUploads }
  };
}

export function VideoChannelFetchError(): FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE
  };
}
