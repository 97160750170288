// @flow

import {
  STATUS_LOADING,
  STATUS_FAILURE,
  STATUS_SUCCESS,
  STATUS_DEFAULT,
  STATUS_UNKNOWN
} from 'constants/statusConstants';
import {
  FETCH_PAGE_PREVIEWBYID_START,
  FETCH_PAGE_PREVIEWBYID_SUCCESS,
  FETCH_PAGE_PREVIEWBYID_FAILURE,
  FETCH_UNKNOWN
} from 'actions/pageActions';

import type { DocumentType, MemberType, DepartmentType, ArbitreType } from 'types/PageDetail';
import type { ActionType } from 'types/Actions';
import type { Status } from 'types/Status';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import type { AcademieType } from 'types/Academie';
import { nullSeoMeta } from 'types/SEOType';

export type State = SEOMetaType & {
  title: string,
  content: Array<BlocContent>,
  slug: string,
  link: string,
  featured_media: {
    src: string,
    legend: string
  },
  status: Status,
  documents: Array<DocumentType>,
  membersGroups: Array<{
    name: string,
    child: Array<MemberType>
  }>,
  liste_enfants: Array<any>,
  departmentsGroups: Array<DepartmentType>,
  arbitresGroups: Array<ArbitreType>,
  academies: Array<AcademieType>,
  sidebar_content: Array<BlocContent>,
  template: string,
  redirection: string,
};

const initialState: State = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  link: '',
  featured_media: {
    src: '',
    legend: ''
  },
  status: STATUS_DEFAULT,
  documents: [],
  membersGroups: [],
  liste_enfants: [],
  departmentsGroups: [],
  arbitresGroups: [],
  academies: [],
  sidebar_content: [],
  template: '',
  redirection: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_PREVIEWBYID_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PAGE_PREVIEWBYID_SUCCESS:
      return {
        ...state,
        title: action.payload.pageDetail.title,
        content: action.payload.pageDetail.content,
        slug: action.payload.pageDetail.slug,
        link: action.payload.pageDetail.link,
        featured_media: action.payload.pageDetail.featured_media,
        documents: action.payload.pageDetail.documents,
        membersGroups: action.payload.pageDetail.membersGroups,
        departmentsGroups: action.payload.pageDetail.departmentsGroups,
        arbitresGroups: action.payload.pageDetail.arbitresGroups,
        academies: action.payload.pageDetail.academies,
        sidebar_content: action.payload.pageDetail.sidebar_content,
        meta_description: action.payload.pageDetail.meta_description,
        meta_title: action.payload.pageDetail.meta_title,
        template: action.payload.pageDetail.template,
        liste_enfants: action.payload.pageDetail.liste_enfants,
        redirection: action.payload.pageDetail.redirection,
        status: STATUS_SUCCESS
      };
    case FETCH_PAGE_PREVIEWBYID_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}
