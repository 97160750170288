// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_SEMINAIRE_SUCCESS,
  FETCH_SEMINAIRE_START,
} from 'actions/seminaireActions';

import type { Status } from 'types/Status';
import type { SeminaireType } from 'types/Seminaire';
import type { ActionType } from 'types/Actions';

export type State = {
  seminaires: Array<SeminaireType>,
  status: Status,
};

const initialState: State = {
  seminaires: [],
  status: STATUS_UNKNOWN,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SEMINAIRE_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_SEMINAIRE_SUCCESS:
      return {
        ...state,
        seminaires: action.payload.seminaires,
        status: STATUS_SUCCESS
      };
    default:
      return state;
  }
}
