// @flow
import { connect } from 'react-redux';

import { fetchJoueursearch } from 'actions/joueursearchActions';
import AddAFavoritePlayerModalContent from 'components/profile/MesFavoris/AddAFavoritePlayerModalContent';

import type { DispatchProps, StateProps } from 'components/profile/MesFavoris/AddAFavoritePlayerModalContent';
import type { DispatchType, StateType } from 'types/Actions';

export default connect(
  (state: StateType): StateProps => ({
    joueurs_favoris: state.userState.userPref.joueurs_favoris,
    joueurs_search: state.joueursearchState.joueursearch,
    joueurs_search_status: state.joueursearchState.status,
    joueurs_suivis_detail: state.userState.userPref.joueurs_suivis_detail,
    postUserPrefStatus: state.userState.status
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchJoueursearch: () => dispatch(fetchJoueursearch())
  })
)(AddAFavoritePlayerModalContent);
