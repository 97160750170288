// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import ClubApi from 'api/club/ClubApi';
import { FETCH_CLUBS_START, clubsFetched, clubsFetchError } from 'actions/clubsActions';
import { FETCH_CLUB_START, clubFetched, clubFetchError } from 'actions/clubActions';

import type { FETCH_CLUBS_START_ACTION } from 'actions/clubsActions';
import type { FETCH_CLUB_START_ACTION } from 'actions/clubActions';

export default function(ffrApiExecutor: ApiExecutorType, ffrStaticApiExecutor: ApiExecutorType) {
  const clubApiStatic = ClubApi(ffrStaticApiExecutor);
  const clubApi = ClubApi(ffrApiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_CLUBS_START, fetchClubs);
    yield takeLatest(FETCH_CLUB_START, fetchClub);
  };

  function* fetchClubs(action: FETCH_CLUBS_START_ACTION): Saga<void> {
    try {
      const clubs = yield call(clubApiStatic.getClubs);
      yield put(clubsFetched(clubs));
    } catch (e) {
      yield put(clubsFetchError(e));
    }
  }

  function* fetchClub(action: FETCH_CLUB_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const club = yield call(clubApi.getClub, slug);
      if (!club.name) {
        throw new Error('No club with slug ' + slug);
      }
      yield put(clubFetched(club));
    } catch (e) {
      yield put(clubFetchError());
    }
  }
}
