// @flow
import React, { useRef } from 'react';
import { TwitchClip, TwitchEmbed } from 'react-twitch-embed';

type Props = {
  attributes: {
    name: string,
    value: string
  }[]
}

function EmbedTwitch({attributes}: Props) {
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.

  const handleReady = (e) => {
    embed.current = e;
  };

  // CSS
  const classAttribute = attributes.find(attribute => attribute.name === 'class');
  const containerClassName = classAttribute ? classAttribute.value : '';

  // Props
  const videoAttribute = attributes.find(attribute => attribute.name === 'data-video');
  const channelAttribute = attributes.find(attribute => attribute.name === 'data-channel');
  const clipAttribute = attributes.find(attribute => attribute.name === 'data-clip');

  let twitchProps = {
    autoplay: false,
    darkMode: false,
    height: '100%',
    muted: true,
    onVideoReady: handleReady,
    width: '100%',
  }

  /**
   * Cas vidéos et channel
   */
  if (!!videoAttribute || !!channelAttribute) {
    const videoId = videoAttribute ? videoAttribute.value : '';
    const channelId = channelAttribute ? channelAttribute.value : '';

    if (!!videoId) {
      twitchProps = {
        ...twitchProps,
        video: videoId,
        id: `twitch-embed-${videoId}`
      }
    }

    if (!!channelId) {
      twitchProps = {
        ...twitchProps,
        channel: channelId,
        id: `twitch-embed-${channelId}`
      }
    }
  
    return (
      <div className={containerClassName}>
        <TwitchEmbed {...twitchProps} />
      </div>
    ) 
  }

  /**
   * Cas du clip
   */
  if (!!clipAttribute) {
    const clipId = clipAttribute.value;

    if (!!clipId) {
      twitchProps = {
        ...twitchProps,
        clip: clipId
      }
    }
  
    return (
      <div className={containerClassName}>
        <TwitchClip {...twitchProps} />
      </div>
    ) 
  }

  return null;
}

export default EmbedTwitch;
