/**
 * Batch.com Module
 *
 * @package react-batch
 * @author  kent1 <quentin@apsulis.io>
 */

//
let initialized = false;

/**
 * Utilities
 */

const verifyInit = () => {
  if (!initialized) {
    console.warn('BAtch not initialized before using call ReactBatch.init with required params');
  }
  return initialized;
};

const defaultOptions = {
    debug: false
};
//
export default {
  init(setupConfig, batchSDKUIConfig = defaultOptions) {
    /* Load remote Batch SDK JavaScript code */
    /* eslint-disable */
    if ('PushManager' in window) {
      (function(b,a,t,c,h,e,r){h='batchSDK';b[h]=b[h]||function() {
  (b[h].q=b[h].q||[]).push(arguments);};e=a.createElement(t),r=a.getElementsByTagName(t)[0];
        e.async=1;e.src=c;r.parentNode.insertBefore(e,r);})(window,document,'script','https://via.batch.com/v2/bootstrap.min.js');

      /* Initiate Batch SDK opt-in UI configuration (native prompt) */
      setupConfig.ui.native = {};

      /* Use a specific configuration for the Firefox web browser (custom prompt) */
      if (navigator.userAgent.indexOf("Firefox") !== -1) {
          setupConfig.ui = {
              alert: {
                  icon: 'https://www.ffr.fr/favicon/android-chrome-192x192.png'
              }
          };
      }
      //console.log(batchSDK);
      /* Finalize the Batch SDK setup */
      /* eslint-disable */
      batchSDK('setup', setupConfig);
      /* eslint-enable */
      initialized = true;
    }
  },

  batchSDK(...args) {
    if (!verifyInit()) {
      return;
    }

    batchSDK(...args); // eslint-disable-line no-undef
  },
};
