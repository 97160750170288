// @flow

import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import { handleTagEvent } from 'utils/tagManagerUtils';

import type { SearchClub } from 'types/Club';

type Props = {
    hits: Array<SearchClub>
};

function Clubs(props: Props) {
    return (
        <ul>
            {props.hits.map((club) => (
                <li key={club.id}>
                    <Link
                        to={`/clubs/${club.identifiant}`}
                        onClick={handleTagEvent('recherche geolocalisee', 'clic dropdown page club', club.nom)}
                    >
                        <img src={'https://api-agregateur-static.ffr.fr/assets' + club.embleme} alt={club.nom} width="16" height="16" />
                        {club.nom}
                    </Link>
                </li>
            ))}
        </ul>
    );
}

export default connectHits(Clubs);