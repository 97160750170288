// @flow
import { connect } from 'react-redux';

import NextRencontreCard from 'components/competitions/NextRencontreCard';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/competitions/NextRencontreCard';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  })
)(NextRencontreCard);
