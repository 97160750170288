// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { getPlaceholder } from 'utils/assetsUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { MOBILE_SIZE } from 'constants/app';
import { nullFeatureMediaType } from 'types/Joueur';
import type { FullUserType } from 'types/User';
import { dynamicClassName } from 'utils/dynamicClassName';

import './CardPlayer.css';

export type StateProps = {
  userPref: FullUserType
};

type Props = StateProps & {
  description?: string,
  featured_media?: Object,
  isCompetitionPlayer?: boolean,
  isConteneurBleu?: boolean,
  noTextOverflow?: boolean,
  poste: string,
  title: string,
  url: string
};

class CardPlayer extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    featured_media: nullFeatureMediaType,
    title: '',
    poste: '',
    url: ''
  };

  render() {
    const {
      userPref,
      description,
      url,
      featured_media,
      isCompetitionPlayer,
      isConteneurBleu,
      title,
      poste,
      noTextOverflow
    } = this.props;
    const { pathname } = window.location;
    const containerClasses = dynamicClassName('card card--picture card-player--compact');
    !isCompetitionPlayer && !isConteneurBleu && containerClasses.add('box');
    isCompetitionPlayer && containerClasses.add('is-competition-players');
    isConteneurBleu && containerClasses.add('is-conteneur-bleu');

    if (url) {
      return (
        <>
          {/* desktop version */}
          <Link
            to={url}
            className={`${containerClasses.build()} ${isCompetitionPlayer ? 'display-desktop-only' : ''}`}
            onClick={handleTagEvent('module_joueur', `clic ${title}`, pathname, userPref)}
          >
            {!!description && (
              <div className="card__header">
                <p>{description}</p>
              </div>
            )}

            <BackgroundImage
              className="card__thumbnail img-blue"
              illustration={featured_media ? featured_media.src : getPlaceholder()}
              srcset={featured_media && featured_media.sizes}
              forceState={window.innerWidth <= MOBILE_SIZE ? 'medium' : 'medium_large'}
            />

            <div className="card__body">
              <h3 className="card__title">{title}</h3>
              {!noTextOverflow ? (
                <p className="card__desc ft-h6 ft-up ft-truncat">{poste}</p>
              ) : (
                <p className="card__desc ft-h6 ft-up">{poste}</p>
              )}
            </div>
          </Link>

          {/* mobile and tablet */}
          <Link
            to={url}
            className={`${containerClasses.build()} ${isCompetitionPlayer ? 'display-til-tablet' : 'display-none'}`}
            onClick={handleTagEvent('module_joueur', `clic ${title}`, pathname, userPref)}
          >
            {!!description && (
              <div className="card__header--mobile">
                <p>{description}</p>
              </div>
            )}

            <div className="card__content--mobile">
              <BackgroundImage
                className="card__thumbnail--mobile img-blue"
                illustration={featured_media ? featured_media.src : getPlaceholder()}
                srcset={featured_media && featured_media.sizes}
                forceState={window.innerWidth <= MOBILE_SIZE ? 'medium' : 'medium_large'}
              />

              <div className="card__body--mobile">
                <h3 className="card__title">{title}</h3>
                {!noTextOverflow ? (
                  <p className="card__desc ft-h6 ft-up ft-truncat">{poste}</p>
                ) : (
                  <p className="card__desc ft-h6 ft-up">{poste}</p>
                )}
              </div>
            </div>
          </Link>
        </>
      );
    }

    return (
      <div className={containerClasses.build()}>
        <BackgroundImage
          className="card__thumbnail img-blue"
          illustration={featured_media ? featured_media.src : getPlaceholder()}
          srcset={featured_media && featured_media.sizes}
          forceState={window.innerWidth <= MOBILE_SIZE ? 'medium' : 'medium_large'}
        />
        <div className="card__body">
          <h3 className="card__title">{title}</h3>
          {!noTextOverflow ? (
            <p className="card__desc ft-h6 ft-up ft-truncat">{poste}</p>
          ) : (
            <p className="card__desc ft-h6 ft-up">{poste}</p>
          )}
        </div>
      </div>
    );
  }
}

export default CardPlayer;
