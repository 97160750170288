// @flow
import TicketingSuccessModalContent from 'components/fragments/modal/modalContents/TicketingSuccessModalContent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/modal/modalContents/TicketingSuccessModalContent';

export default connect((state: StateType): StateProps => ({
  push_rencontre: state.settingsState.push_rencontre,
  userPref: state.userState.userPref
}))(TicketingSuccessModalContent);
