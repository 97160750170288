// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loading from 'components/fragments/Loading';
import PrivateRoute from 'containers/app/PrivateRouteContainer';

import type { ComponentType } from 'react';

const makeAsyncComponent = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading isBlue backgroundColor={'#013974'} />;
    }
  });
};

const makeAsyncComponentWhite = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading isWhite backgroundColor={'#ffffff'} />;
    }
  });
};

const makeAsyncComponentGrey = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading />;
    }
  });
};

const TagArticle = makeAsyncComponent(() => import('containers/tagarticle/TagArticleContainer'));
const Auteur = makeAsyncComponent(() => import('containers/auteur/AuteurContainer'));
const Newsletter = makeAsyncComponent(() => import('containers/newsletter/NewsletterContainer'));
const NewsletterCategoryContainer = makeAsyncComponent(() =>
  import('containers/newsletter/NewsletterCategoryContainer')
);
const Article = makeAsyncComponentWhite(() => import('containers/newsletter/ArticleContainer'));
const Billboard = makeAsyncComponent(() => import('containers/billboard/BillboardContainer'));
const Club = makeAsyncComponentWhite(() => import('containers/club/ClubContainer'));
const ClubFinderContainer = makeAsyncComponent(() => import('containers/club/ClubFinderContainer'));
const ClubFinderResultContainer = makeAsyncComponentGrey(() => import('containers/club/ClubFinderResultContainer'));
const JobDetailContainer = makeAsyncComponent(() => import('containers/job/JobDetailContainer'));
const LogeListContainer = makeAsyncComponent(() => import('containers/entreprise/loge/LogeListContainer'));
const SeminaireContainer = makeAsyncComponent(() => import('containers/entreprise/seminaire/SeminaireContainer'));
const LogeDetailContainer = makeAsyncComponent(() => import('containers/entreprise/loge/LogeDetailContainer'));
const Joueur = makeAsyncComponent(() => import('containers/joueur/JoueurContainer'));
const PageListContainer = makeAsyncComponent(() => import('containers/page/PageListContainer'));
const PageNodeContainer = makeAsyncComponent(() => import('containers/page/PageNodeContainer'));
const PageNotFound = makeAsyncComponent(() => import('components/app/PageNotFound'));
const SocialWallContainer = makeAsyncComponent(() => import('containers/social/SocialWallContainer'));
const TeamDetailContainer = makeAsyncComponent(() => import('containers/team/TeamDetailContainer'));
const TeamPlayersContainer = makeAsyncComponent(() => import('containers/team/TeamPlayersContainer'));
const TeamActusContainer = makeAsyncComponent(() => import('containers/team/TeamActusContainer'));
const TeamStaffsContainer = makeAsyncComponent(() => import('containers/team/TeamStaffsContainer'));
const TeamStaffContainer = makeAsyncComponent(() => import('containers/team/TeamStaffContainer'));
const TeamPlayerContainer = makeAsyncComponent(() => import('containers/team/TeamPlayerContainer'));
const TeamAgendaContainer = makeAsyncComponent(() => import('containers/team/TeamAgendaContainer'));

const ContactWrapperContainer = makeAsyncComponent(() => import('containers/contact/ContactWrapperContainer'));
const FaqWrapperContainer = makeAsyncComponent(() => import('containers/contact/FaqWrapperContainer'));
const EventDetailContainer = makeAsyncComponentWhite(() => import('containers/event/EventDetailContainer'));
const EventListContainer = makeAsyncComponent(() => import('containers/event/EventListContainer'));
const CompetitionListingContainer = makeAsyncComponent(() =>
  import('containers/competitions/CompetitionListingContainer')
);
const CompetitionDetailContainer = makeAsyncComponent(() =>
  import('containers/competitions/CompetitionDetailContainer')
);
const AllCompetitionsResultsContainer = makeAsyncComponent(() =>
  import('containers/competitions/AllCompetitionsResultsContainer')
);
const HomeTVContainer = makeAsyncComponent(() => import('containers/ffrtv/home/HomeTVContainer'));
const SearchWrapper = makeAsyncComponent(() => import('components/search/SearchWrapper'));
const PageStaticContainer = makeAsyncComponent(() => import('containers/page/PageStaticContainer'));

const RencontreContainer = makeAsyncComponent(() => import('containers/rencontre/RencontreContainer'));
const ChannelContainer = makeAsyncComponent(() => import('containers/ffrtv/ChannelContainer'));
const PlaylistContainer = makeAsyncComponent(() => import('containers/ffrtv/PlaylistContainer'));
const VideoPlayerContainer = makeAsyncComponent(() => import('containers/ffrtv/VideoPlayerContainer'));
const DashboardContainer = makeAsyncComponentGrey(() => import('containers/profile/DashboardContainer'));

const CentenaireResultContainer = makeAsyncComponent(() => import('containers/centenaire/CentenaireResultContainer'));
const CentenaireMapContainer = makeAsyncComponent(() => import('containers/centenaire/CentenaireMapContainer'));
const CentenairePageContainer = makeAsyncComponent(() => import('containers/centenaire/CentenairePageContainer'));

const CompetitionInterationalesContainer = makeAsyncComponent(() => import('containers/competitionsInternationales/competitionsInternationalesContainer'));

Billboard.preload();
Article.preload();

export function withProps(externalProps: Object) {
  return (WrapperComponent: ComponentType<Object>) => {
    return class HOSWrapperComponent extends React.PureComponent<Object> {
      render() {
        return <WrapperComponent {...this.props} {...externalProps} />;
      }
    };
  };
}

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Billboard} />
    <PrivateRoute path="/tableau-de-bord" component={DashboardContainer} />
    <Route path="/tags/:slug" component={TagArticle} />
    <Route path="/auteurs/:slug" component={Auteur} />
    <Route path="/actualites/:category/:slug" component={Article} />
    <Route exact path="/actualites" component={Newsletter} />
    <Route exact path="/actualites/:category" component={NewsletterCategoryContainer} />
    <Route exact path="/clubs/:slug" component={Club} />
    <Redirect from="/clubs" to="/trouver-un-club-de-rugby" />
    <Route exact path="/trouver-un-club-de-rugby" component={ClubFinderContainer} />
    <Route exact path="/trouver-un-club-de-rugby/resultats-de-recherche" component={ClubFinderResultContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug" component={TeamDetailContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/actualites" component={TeamActusContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/joueurs" component={TeamPlayersContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/joueurs/:joueur" component={TeamPlayerContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/agenda" component={TeamAgendaContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/staff" component={TeamStaffsContainer} />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/staff/:staff" component={TeamStaffContainer} />
    <Route
      exact
      path="/equipe-de-france/:typeRugby/:slug/rencontres/:competition/:rencontre/:tab?"
      component={RencontreContainer}
    />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/competitions" component={CompetitionListingContainer} />
    <Route
      exact
      path="/equipe-de-france/:typeRugby/:slug/competitions/:competitionSlug"
      component={CompetitionDetailContainer}
    />
    <Route exact path="/equipe-de-france/:typeRugby/:slug/resultats" component={AllCompetitionsResultsContainer} />
    <Route exact path="/joueurs/:slug" component={Joueur} />
    <Route exact path="/social-wall" component={SocialWallContainer} />
    <Route exact path="/contact" component={ContactWrapperContainer} />
    <Route exact path="/contact/:slugTheme" component={ContactWrapperContainer} />
    <Route exact path="/contact/nous-contacter" component={withProps({ form: true })(ContactWrapperContainer)} />
    <Route exact path="/faq" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme" component={FaqWrapperContainer} />
    <Route exact path="/faq/:slugTheme/:slugQuestion" component={FaqWrapperContainer} />
    <Route exact path="/recherche" component={SearchWrapper} />
    <Route exact path="/evenements/:slug" component={EventDetailContainer} />
    <Route path="/evenements/:year/:month" component={EventListContainer} />
    <Route path="/evenements" component={EventListContainer} />
    <Route exact path="/ffr-tv" component={HomeTVContainer} />
    <Route
      exact
      path="/mentions-legales"
      component={withProps({ pageSlug: 'mentions-legales' })(PageStaticContainer)}
    />
    <Route exact path="/cgu" component={withProps({ pageSlug: 'cgu' })(PageStaticContainer)} />
    <Route 
      exact
      path="/charte-de-protection-des-donnees-a-caractere-personnel"
      component={withProps({ pageSlug: 'charte-de-protection-des-donnees-a-caractere-personnel' })(PageStaticContainer)} />
    <Route
      exact
      path="/politique-de-cookies"
      component={withProps({ pageSlug: 'politique-de-cookies' })(PageStaticContainer)}
    />
    <Route
      exact
      path="/donnees-personnelles"
      component={withProps({ pageSlug: 'donnees-personnelles' })(PageStaticContainer)}
    />
    <Route
      exact
      path="/ffr-websites-use-policies"
      component={withProps({ pageSlug: 'ffr-websites-use-policies' })(PageStaticContainer)}
    />
    <Route
      exact
      path="/:slug/:slugNode/:slugNodeChild/hospitalites/:matchSlug/:logeSlug/:menuSlug?"
      component={LogeDetailContainer}
    />
    <Route exact path="/:slug/:slugNode/:slugNodeChild/hospitalites/:matchSlug" component={LogeListContainer} />
    <Route exact path="/:slug/:slugNode/:slugNodeChild/seminaires/:slugSeminaire?" component={SeminaireContainer} />
    <Route exact path="/ffr-tv-channel" component={ChannelContainer} />
    <Route exact path="/ffr-tv-playlist/:idPlaylist" component={PlaylistContainer} />
    <Route exact path="/ffr-tv-player/:idVideo" component={VideoPlayerContainer} />
    <Route exact path="/:slug" component={PageListContainer} />
    <Route exact path="/:one/:two/:three/offre-emploi/:slug" component={JobDetailContainer} />
    <Route exact path="/histoires-de-rugby/resultats-de-recherche" component={CentenaireResultContainer} />
    <Route exact path="/histoires-de-rugby/carte" component={CentenaireMapContainer} />
    <Route path="/histoires-de-rugby/:document" component={CentenairePageContainer} />
    <Route path="/competitions-internationales/:competition" component={CompetitionInterationalesContainer}/>
    <Route path="/:slug/:slugNode" component={PageNodeContainer} />
    <Route path="/404" component={PageNotFound} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
