// import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createTransform, persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from 'reducers/index';
import rootSaga from 'sagas/root';
import keycloakMiddleware from 'sagas/keycloakMiddleware';

const COOKIE_ACCEPT_LIFESPAN_IN_MONTH = 6;

function getRehydratedAppState(appState) {
  const { acceptCookieDate, showCookieInfo, showAlertInfo } = appState;
  if (showCookieInfo) {
    return appState;
  }

  if (!acceptCookieDate) {
    return { acceptCookie: false, acceptCookieDate: null, showCookieInfo: true, showAlertInfo };
  } else {
    const now = new Date();
    const maxDate = new Date(acceptCookieDate);
    maxDate.setMonth(maxDate.getMonth() + COOKIE_ACCEPT_LIFESPAN_IN_MONTH);

    if (now > maxDate) {
      return { acceptCookie: false, acceptCookieDate: null, showCookieInfo: true, showAlertInfo };
    }
  }

  return appState;
}

const sagaMiddleware = createSagaMiddleware();
const appStateTransform = createTransform(inboundState => inboundState, getRehydratedAppState, {
  whitelist: ['appState']
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['appState', 'newsletterState', 'tagsState', 'menuState'],
  transforms: [appStateTransform]
};
const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = window.location.host.includes('localhost')
  ? composeWithDevTools(applyMiddleware(sagaMiddleware, keycloakMiddleware))
  : applyMiddleware(sagaMiddleware, keycloakMiddleware);

export const store = createStore(persistedReducer, {}, middleware);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
