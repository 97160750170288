// npm imports
import * as Keycloak from 'keycloak-js';
import ReactBatch from '../tracking/batch';

// local imports
import {
  KEYCLOAK_CONNECT,
  keycloakConnected,
  keycloakConnectError,
  fetchUserFid,
  fetchUserPref,
  userPrefFetched
} from 'actions/userActions';
import { getInitSsoOptions } from 'constants/sso';
import { nullUserPrefs } from 'types/User';

// eslint-disable-next-line
let authenticated = false;
// eslint-disable-next-line
let keycloakData = null;
let keycloak = null;

// middleware
export default store => next => action => {
  switch (action.type) {
    case KEYCLOAK_CONNECT:
      //* check authetification
      const initSsoOptions = getInitSsoOptions();
      keycloak = Keycloak(initSsoOptions);
      let options = {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
      };

      // ajout de async pour créer une fonction asynchrone --> on peut donc soit await keycloakData = keycloak.loadUserProfile() soit tel que là
      keycloak
        .init(options)
        .then(async authenticated => {
          if (authenticated) {
            authenticated = true;

            keycloak.onTokenExpired = () => {
              // first param = minValidity in seconds
              keycloak
                .updateToken(50)
                .then(refreshed => {
                  // if (refreshed){
                  // 	// eslint-disable-next-line
                  // 		console.log('refreshed ');
                  // } else {
                  // 	// eslint-disable-next-line
                  // 		console.log('not refreshed ');
                  // }

                  store.dispatch(keycloakConnected({ keycloak, authenticated }));
                })
                .error(() => {
                  console.error('Failed to refresh token ');
                });
            };

            ReactBatch.batchSDK(function(api) {
              api.setCustomUserID(keycloak.tokenParsed.sub);
            });

            store.dispatch(keycloakConnected({ keycloak, authenticated }));
            store.dispatch(fetchUserPref(true, keycloak.token));
            store.dispatch(fetchUserFid(keycloak.token));
          } else {
            authenticated = false;
            if (window.location.pathname.match('tableau-de-bord')) {
              if (keycloak && keycloak.createLoginUrl) {
                window.location.href = keycloak.createLoginUrl();
              } else {
                window.location.href = '/';
              }
            }

            ReactBatch.batchSDK(function(api) {
              api.setCustomUserID(null);
            });

            store.dispatch(keycloakConnected({ keycloak, authenticated }));

            store.dispatch(userPrefFetched(nullUserPrefs));
          }
        })
        .catch(error => {
          console.error(error);
          store.dispatch(keycloakConnectError(error));
        });

      next(action);
      break;
    default:
      next(action);
  }
};
