// @flow
import React, { PureComponent } from 'react';
import Label from 'components/fragments/text/Label';
import { nullClub } from 'types/Club';
import type { ClubPreviewType } from 'types/Club';
import type { FullUserType } from 'types/User';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';
import { handleTagEvent }from 'utils/tagManagerUtils';

export type StateProps = {
  userPref: FullUserType,
};

type Props = StateProps & {
  club: ClubPreviewType,
  position?: number,
  distance: number,
  searchedPractices?: string[],
  sidebar?: boolean
};

class CardClub extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    club: nullClub
  };

  renderLabel = () => {
    const { practices } = this.props.club;
    const { searchedPractices } = this.props;

    if (practices) {
      return practices.map<any>((label, index) => {
        const hasSearchedPractice = searchedPractices && searchedPractices.includes(label);
        return (
          <Label label={label} key={index} isRounded isGrey={!hasSearchedPractice} isAzure={hasSearchedPractice} />
        );
      });
    }

    return null;
  };

  render() {
    const { img, name, slug } = this.props.club;
    const { userPref, sidebar, position, distance, searchedPractices } = this.props;
    const displayedDistance = Math.round(distance * 10) / 10;

    const ContainerClassName = dynamicClassName('box card card--club');
    sidebar && ContainerClassName.add('card--small mb-2');
    !sidebar && ContainerClassName.add('mb-1');
    return (
      <Link
        to={`/clubs/${slug}`}
        className={ContainerClassName.build()}
        title={`Se rendre sur la page du club ${name}`}
        onClick={handleTagEvent(
          'module club',
          `clic ${name}`,
          window.location.pathname,
          userPref
        )}>
        <img src={img} alt={name} height="48" width="48" />
        <div className="card__body">
          <div>
            <h4 className="card__title ft-truncat">{name}</h4>
            { displayedDistance !== 0 &&
            <p className="card__desc ft-h6">
              {displayedDistance}
              KM
            </p>}
            {position && <span className="card__position ft-h6">{position}</span>}
            {searchedPractices && this.renderLabel()}
          </div>
        </div>
      </Link>
    );
  }
}

export default CardClub;
