// @flow
import React, { PureComponent } from 'react';
import type { ArticleType, CategoryArticleType } from 'types/Article';
import DropdownRadioInput from 'components/fragments/input/DropdownRadioInput';
import { formatFullDate, getDateFromString } from 'utils/dateUtils';
import BackgroundImage from 'components/fragments/BackgroundImage';

type ComponentState = {
  catId: number,
  maxNews: number
};

export type StateProps = {
  categoriesArticle: Array<CategoryArticleType>
};

export type DispatchProps = {
  fetchArticlesStaticCategories: () => void
};

type Props = StateProps & DispatchProps;

class CardEnDirect extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    catId: 0,
    maxNews: 4
  };

  componentDidMount() {
    const { categoriesArticle } = this.props;

    if (categoriesArticle === []) {
      this.props.fetchArticlesStaticCategories();
    }
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    const { maxNews } = this.state;
    if (window.innerWidth < 840 && maxNews !== 3) {
      this.setState({ maxNews: 3 });
    }
    if (window.innerWidth >= 840 && maxNews !== 4) {
      this.setState({ maxNews: 4 });
    }
  }

  handleChange = (value: any) => {
    this.setState({
      catId: value
    });
  };

  /**
   * Récupérer toutes les news puis :
   * - les trier par date
   * - enlever les doublons
   * - retourner uniquement les 6 dernières
   */
  getNews = (): Array<ArticleType> => {
    let news = [];
    let ids = [];
    if (this.props.categoriesArticle && this.props.categoriesArticle.length > 0) {
      this.props.categoriesArticle.forEach(cat => {
        if (cat.items && cat.items.length > 0) {
          cat.items.forEach(actu => {
            news.push(actu);
          });
        }
      });
    }

    return news
      .filter(item => {
        return ids.indexOf(item.link) === -1 ? ids.push(item.link) : false;
      })
      .sort((a, b) => {
        var x = a.date.toString();
        var y = b.date.toString();
        return y < x ? -1 : y > x ? 1 : 0;
      })
      .slice(0, 6);
  };

  renderNews() {
    const { categoriesArticle } = this.props;
    const { catId, maxNews } = this.state;
    const news: Array<ArticleType> = this.getNews();
    if (catId === 0) {
      // $FlowFixMe
      return news.map<any>((news, index) => {
        /**
         * La première news est déjà en home
         */
        if (index === 0 || index > maxNews + 1) {
          return null;
        }
        const { illustration, title, label, slug, link, srcset } = news;
        const date = getDateFromString(news.date);
        return (
          <a
            key={slug}
            href={link}
            title={`Lire la suite de l'article : ${title}`}
            role="button"
            tabIndex={0}
            className={'news news--row'}
            rel="nofollow"
          >
            <article>
              <BackgroundImage
                className="news__thumbnail img-blue"
                illustration={illustration}
                srcset={srcset}
                forceState={'thumbnail'}
              />
              <div>
                <span className="news__title ft-h5">{title}</span>
                <p className="ft-h6">
                  <span className="news__cat ft-up">{label.name}</span>
                  <span className="news__date">{formatFullDate(date)}</span>
                </p>
              </div>
            </article>
          </a>
        );
      });
    } else {
      return categoriesArticle.map<any>((cat, index) => {
        if (cat.id === catId) {
          return cat.items.map<any>((news, index) => {
            if (index > maxNews) {
              return null;
            }
            const { illustration, title, label, slug, link, srcset } = news;
            const date = getDateFromString(news.date);
            return (
              <a
                key={slug}
                href={link}
                title={`Lire la suite de l'article : ${title}`}
                role="button"
                tabIndex={0}
                className={'news news--row'}
              >
                <article>
                  <BackgroundImage
                    className="news__thumbnail img-blue"
                    illustration={illustration}
                    srcset={srcset}
                    forceState={'thumbnail'}
                  />
                  <div>
                    <span className="news__title ft-h5">{title}</span>
                    <p className="ft-h6">
                      <span className="news__cat ft-up">{label.name}</span>
                      <span className="news__date">{formatFullDate(date)}</span>
                    </p>
                  </div>
                </article>
              </a>
            );
          });
        }
        return null;
      });
    }
  }

  render() {
    const { categoriesArticle } = this.props;
    const { maxNews, catId } = this.state;
    if (!categoriesArticle || categoriesArticle === 0) {
      return '';
    }
    const categories_options = categoriesArticle.map<{ label: string, value: number }>(cat => {
      return { label: cat.name, value: cat.id };
    });
    categories_options.unshift({ label: maxNews === 4 ? 'Toutes les catégories' : 'Toutes catégories', value: 0 });
    categories_options.forEach((option, index) => {
      if (option.label.length > 25) {
        option.label = option.label.substring(0, 24) + '...';
      }
      return null;
    });
    let dropdownLabel = categories_options[0].label;
    if (catId) {
      const dropdownLabels = categories_options.filter(cat => {
        return cat.value === catId;
      });
      if (dropdownLabels.length > 0) {
        dropdownLabel = dropdownLabels[0].label;
      }
    }

    return (
      <div className="card--news-direct mb-4">
        <div className="card__head">
          <h3 className="ft-h3">En direct</h3>
          <DropdownRadioInput
            options={categories_options}
            inputChanged={this.handleChange}
            label={dropdownLabel}
            radio={true}
            maj={true}
            title={true}
            noBorder={true}
          />
        </div>
        <div className="box box--white">
          {this.renderNews()}
          <a href={'/actualites'} role="button" tabIndex={0} className={'link-chevron link-chevron--block'}>
            <span>Voir toutes les actualités</span> <i className="icon icon-chevron-right"></i>
          </a>
          <a href={'/actualites'} role="button" tabIndex={0} className={'btn btn--primary btn--full'}>
            <i className="icon icon-documents is-inline"></i>
            <span>Voir toutes les actualités</span>
          </a>
        </div>
      </div>
    );
  }
}

export default CardEnDirect;
