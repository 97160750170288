// @flow

import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { formatFullDate } from 'utils/dateUtils';
import { getPlaceholder } from 'utils/assetsUtils';
import { Link } from 'react-router-dom';
import BackgroundImage from 'components/fragments/BackgroundImage';

type Props = {
  url: string,
  backgroundUrl?: Object,
  title: string,
  linkLabel: string,
  category?: string,
  date?: Date | string;
  desc?: string,
  titleClass?: string,
  isCompact?: boolean,
  handleOnClick?: Function,
};

class CardThumbnail extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    url: '#',
    isCompact: false,
    backgroundUrl: getPlaceholder(),
    title: 'Bulletins officiels',
    linkLabel: 'En savoir plus'
  };

  render() {
    const {
      url,
      isCompact,
      title,
      desc,
      linkLabel,
      backgroundUrl,
      titleClass,
      category,
      date,
      handleOnClick,
    } = this.props;
    const classNamesCard = dynamicClassName('box card card--content');
    isCompact && classNamesCard.add('card--compact');
    const classNamesTitle = dynamicClassName('card__title');
    titleClass && classNamesTitle.add(titleClass);

    return (
      <Link
        to={url}
        className={classNamesCard.build()}
      >
        <BackgroundImage
          className="card__thumbnail img-blue"
          illustration={backgroundUrl ? backgroundUrl.src : getPlaceholder()}
          srcset={backgroundUrl && backgroundUrl.sizes} />
        <div className="card__body">
          {(category || date) && 
          <p className="ft-h6">
          <span className="news__cat ft-up">{category}</span>
          <span className="news__date">{date && formatFullDate(new Date(date))}</span>
          </p>
          }
          <h3 className={classNamesTitle.build()}>{title}</h3>
          {!isCompact && desc && <p className="card__desc">{desc}</p>}
        </div>
        <div
          className="card__footer"
          onClick={handleOnClick}
        >
          <span
            className="link-chevron"
          >
            {linkLabel}
            <i className="icon icon-chevron-right" />
          </span>
        </div>
      </Link>
    );
  }
}

export default CardThumbnail;
