// @flow

import type { CompetitionType } from 'types/Competition';
import { nullCompetition } from 'types/Competition';

export const FETCH_COMPETITION_START = 'FETCH_COMPETITION_START';
export const FETCH_COMPETITION_SUCCESS = 'FETCH_COMPETITION_SUCCESS';
export const FETCH_COMPETITION_FAILURE = 'FETCH_COMPETITION_FAILURE';

export type FETCH_COMPETITION_START_ACTION = {
  type: 'FETCH_COMPETITION_START',
  payload: {
    slug: string,
    competition: CompetitionType
  }
};

export type FETCH_COMPETITION_FAILURE_ACTION = {
  type: 'FETCH_COMPETITION_FAILURE'
};

export type FETCH_COMPETITION_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITION_SUCCESS',
  payload: {
    competition: CompetitionType
  }
};

export type Action =
  | FETCH_COMPETITION_START_ACTION
  | FETCH_COMPETITION_FAILURE_ACTION
  | FETCH_COMPETITION_SUCCESS_ACTION;

export function fetchCompetition(slug: string): FETCH_COMPETITION_START_ACTION {
  return {
    type: FETCH_COMPETITION_START,
    payload: {
      slug,
      competition: nullCompetition
    }
  };
}

export function competitionFetched(competition: CompetitionType): FETCH_COMPETITION_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITION_SUCCESS,
    payload: { competition }
  };
}

export function competitionFetchError(): FETCH_COMPETITION_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITION_FAILURE
  };
}
