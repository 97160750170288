// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import SeasonsApi from 'api/seasons/SeasonsApi';
import { FETCH_SEASON_START, seasonsFetched, seasonsFetchError  } from 'actions/seasonsActions';
import type { FETCH_SEASON_START_ACTION } from 'actions/seasonsActions';

export default function(apiExecutor: ApiExecutorType) {
  const seasonsApi = new SeasonsApi(apiExecutor);

  return function* seasonsSaga(): GeneratorType {
    yield takeLatest(FETCH_SEASON_START, fetchSeasons);
  };

  function* fetchSeasons(action: FETCH_SEASON_START_ACTION): Saga<void> {
    try {
      const seasons = action.payload ? 
        yield call(seasonsApi.getSeasonsList, action.payload.slug) : 
        yield call(seasonsApi.getSeasonsList) ;
      yield put(seasonsFetched(seasons));
    } catch (error) {
      yield put(seasonsFetchError(error));
    }
  }
}