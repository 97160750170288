// @flow
import { type RawYoutubeSearch, nullRawYoutubeSearch } from 'types/YoutubeSearch';

import type { RawYoutubeVideo, YoutubeVideo, RawYoutubeSpotlightType } from 'types/YoutubeVideo';
import { nullYoutubeVideo } from 'types/YoutubeVideo';

import type { RawYoutubeChannel } from 'types/YoutubeChannel';
import { nullRawYoutubeChannel } from 'types/YoutubeChannel';

import type { RawYoutubePlaylistItems, YoutubePlaylistItems } from 'types/YoutubePlaylistItems';
import { nullYoutubePlaylistItems, nullYoutubePlaylistItem } from 'types/YoutubePlaylistItems';

import { mergeIntoDefault } from 'utils/objectUtils';
import { formatYoutubeDuration } from 'utils/dateUtils';
import { handleYoutubeThumbnailsUrl } from 'utils/youtubeApiUtils';

export function convertVideoIdFromApiSearch(rawYoutubeSearch: RawYoutubeSearch): RawYoutubeSearch {
  if (!rawYoutubeSearch || !rawYoutubeSearch.items || rawYoutubeSearch.items.length === 0) {
    return nullRawYoutubeSearch;
  }
  return rawYoutubeSearch;
}

export function convertVideoIdFromApiPlaylistItems(
  rawYoutubePlaylistItems: RawYoutubePlaylistItems
): YoutubePlaylistItems {
  if (!rawYoutubePlaylistItems || !rawYoutubePlaylistItems.items || rawYoutubePlaylistItems.items.length === 0) {
    return nullYoutubePlaylistItems;
  }
  return mergeIntoDefault(nullYoutubePlaylistItems, {
    youtubePlaylistItem: rawYoutubePlaylistItems.items.map(item =>
      mergeIntoDefault(nullYoutubePlaylistItem, {
        id: item.snippet.resourceId.videoId,
        title: item.snippet.title
      })
    ),
    etag: rawYoutubePlaylistItems.etag,
    nextPageToken: rawYoutubePlaylistItems.nextPageToken,
    channelId: rawYoutubePlaylistItems.items[0].snippet.channelId
  });
}

export function convertChannelFromApi(rawYoutubeChannel: RawYoutubeChannel): RawYoutubeChannel {
  if (!rawYoutubeChannel || !rawYoutubeChannel.items || rawYoutubeChannel.items.length === 0) {
    return nullRawYoutubeChannel;
  }

  return rawYoutubeChannel;
}

export function convertYoutubeLiveFromApi(rawYoutubeSearch: RawYoutubeSearch): ?string {
  if (!rawYoutubeSearch || !rawYoutubeSearch.items || rawYoutubeSearch.items.length === 0) {
    return null;
  }

  return rawYoutubeSearch.items[0].id.videoId;
}

export function convertSpotlightVideoIdFromApi(rawYoutubeSpotlight: RawYoutubeSpotlightType): YoutubeVideo {
  const spotlight = rawYoutubeSpotlight[0];

  if (!spotlight) return nullYoutubeVideo;

  return mergeIntoDefault(nullYoutubeVideo, {
    ...convertVideoFromApi(
      {
        etag: '',
        kind: '',
        pageInfo: { totalResults: 1, resultsPerPage: 1 },
        items: [spotlight.youtube_video]
      },
      ''
    ),
    img: spotlight.featured_media.src
  });
}

export function convertVideoFromApi(rawYoutubeVideo: RawYoutubeVideo, playlistId: string): YoutubeVideo {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return nullYoutubeVideo;
  }

  const video = rawYoutubeVideo.items[0];

  const {
    id,
    snippet: { title, description, channelId, channelTitle },
    contentDetails: { duration }
  } = video;

  return mergeIntoDefault(nullYoutubeVideo, {
    id,
    title,
    description,
    channelId,
    channelTitle,
    playlistId,
    duration: formatYoutubeDuration(duration)
  });
}

export function convertMultipleVideoFromApi(rawYoutubeVideo: RawYoutubeVideo): Array<YoutubeVideo> {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return [];
  }

  return rawYoutubeVideo.items.map(video => {
    const {
      id,
      snippet: { title, description, channelId, channelTitle, publishedAt, thumbnails, tags },
      contentDetails: { duration }
    } = video;
    return (
      nullYoutubeVideo,
      {
        id,
        title,
        description,
        playlistId: '',
        playlistTitle: '',
        channelId,
        channelTitle,
        publishedAt,
        url: `/ffr-tv-player/${id}`,
        img: handleYoutubeThumbnailsUrl(thumbnails),
        duration: formatYoutubeDuration(duration),
        tags: tags ? tags : []
      }
    );
  });
}
