// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import type { RouterProps } from 'types/Router';

type Props = RouterProps & {
  attributes: any
};

class CompetitionLinearCourseTitle extends Component<Props> {
  render() {
    const { attributes } = this.props;

    const isPostCompetition = !!(
      attributes.find(attr => attr.name === 'ispostcompetition') &&
      attributes.find(attr => attr.name === 'ispostcompetition').value === 'true'
    );

    let firstTitle = attributes.find(attr => attr.name === 'titlefirstpart')?.value || '';
    let secondTitle = attributes.find(attr => attr.name === 'titlesecondpart')?.value || '';

    if (!!isPostCompetition) {
      const isClassementRoute = this.props.location.pathname.includes('classement');

      if (!isClassementRoute) {
        firstTitle = attributes.find(attr => attr.name === 'postcompetitiontitlefirstpart')?.value || '';
        secondTitle = attributes.find(attr => attr.name === 'postcompetitiontitlesecondpart')?.value || '';
      }
    }

    if (!firstTitle && !secondTitle) {
      return null;
    }

    return (
      <h2 className="divided">
        {!!firstTitle && <span className="first">{firstTitle}</span>}
        {!!secondTitle && <span className="second second--dark">{secondTitle}</span>}
      </h2>
    );
  }
}

export default withRouter(CompetitionLinearCourseTitle);
