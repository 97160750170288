// @flow
import React, { PureComponent } from 'react';

import { shouldUsePlaceholder } from 'utils/assetsUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';

import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType
};

type Props = StateProps & {
  rencontre: any,
  equipeNom: string,
  competitionTitle: string,
  isSmall?: boolean,
  isFirst?: boolean
};

export default class NextRencontreCard extends PureComponent<Props> {
  render() {
    const {
      userPref,
      rencontre: {
        reception,
        adversaire,
        embleme_france,
        embleme_adversaire,
        adversaire_logo,
        france_logo,
        lieu,
        date_rencontre,
        billetterie,
        link
      },
      isSmall,
      isFirst,
      competitionTitle
    } = this.props;

    const date = new Date(date_rencontre.replace(' ', 'T'));
    const francePlaysAtHome = reception && reception !== 0;
    const logoFrance = embleme_france ? embleme_france : france_logo;
    const logoAdversaire = embleme_adversaire ? embleme_adversaire : adversaire_logo;
    const matchName = francePlaysAtHome ? `France-${adversaire}` : `${adversaire}-France`;
    const className = dynamicClassName('box box--white card card--nextGameFull');
    isSmall && className.add('mb-1');
    isFirst && className.add('mt-3');

    return (
      <div className={className.build()} key={`Match_${matchName}_${date_rencontre}`}>
        <div className="card__header">
          <span className="ft-h6 ft-up">{competitionTitle}</span>
          <span className="ft-h6 ft-up">
            {date && `Le ${date.toLocaleDateString()} à ${date_rencontre.substring(11, 16).replace(':', 'h')}`}
          </span>
        </div>
        <div className="card__body">
          <a href={link} title={`Voir les informations du match ${matchName}`}>
            <p className="ft-h3 ft-400">
              <img
                src={shouldUsePlaceholder(
                  { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                  true
                )}
                alt={`Logo ${francePlaysAtHome ? 'France' : adversaire}`}
                width="40"
                height="40"
              />
              {francePlaysAtHome ? 'France' : adversaire}
            </p>
            <p className="ft-h3 ft-400">
              <img
                src={shouldUsePlaceholder(
                  { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                  false
                )}
                alt={`Logo ${francePlaysAtHome ? adversaire : 'France'}`}
                width="40"
                height="40"
              />
              {francePlaysAtHome ? adversaire : 'France'}
            </p>
          </a>
          <ul className="list-type ft-h6 ft-up mt-4 mb-0 mb-md-1 mb-lg-3">
            {lieu && (
              <li>
                <a
                  href={`https://www.google.com/maps/search/${lieu}`}
                  title="Voir l'adresse sur Google Map (nouvel onglet)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon icon-place is-inline" />
                  {lieu}
                </a>
              </li>
            )}
            <li>
              <i className="icon icon-clock is-inline" />
              {date_rencontre.substring(11, 16).replace(':', 'h')}
            </li>
          </ul>
        </div>
        {billetterie !== '' && (
          <a
            href={billetterie}
            className={`btn btn--primary card--nextGameFull__button ${isSmall ? 'prevent-float' : ''}`}
            onClick={handleTagEvent('billet_match', 'achat_billet', matchName, userPref)}
          >
            <i className="icon icon-store is-inline" />
            <span>Acheter vos billets</span>
          </a>
        )}
      </div>
    );
  }
}
