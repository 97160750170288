// @flow

import type { ThemeType, QuestionType } from 'types/Faq';

export const FETCH_FAQ_THEME_START = 'FETCH_FAQ_THEME_START';
export const FETCH_FAQ_THEME_SUCCESS = 'FETCH_FAQ_THEME_SUCCESS';
export const FETCH_FAQ_THEME_FAILURE = 'FETCH_FAQ_THEME_FAILURE';

export const FETCH_FAQ_QUESTIONS_START = 'FETCH_FAQ_QUESTIONS_START';
export const FETCH_FAQ_QUESTIONS_SUCCESS = 'FETCH_FAQ_QUESTIONS_SUCCESS';
export const FETCH_FAQ_QUESTIONS_FAILURE = 'FETCH_FAQ_QUESTIONS_FAILURE';

export type FETCH_FAQ_THEME_START_ACTION = {
  type: 'FETCH_FAQ_THEME_START'
};

export type FETCH_FAQ_THEME_FAILURE_ACTION = {
  type: 'FETCH_FAQ_THEME_FAILURE'
};

export type FETCH_FAQ_THEME_SUCCESS_ACTION = {
  type: 'FETCH_FAQ_THEME_SUCCESS',
  payload: {
    themes: ThemeType[]
  }
};

export type FETCH_FAQ_QUESTIONS_START_ACTION = {
  type: 'FETCH_FAQ_QUESTIONS_START'
};

export type FETCH_FAQ_QUESTIONS_FAILURE_ACTION = {
  type: 'FETCH_FAQ_QUESTIONS_FAILURE'
};

export type FETCH_FAQ_QUESTIONS_SUCCESS_ACTION = {
  type: 'FETCH_FAQ_QUESTIONS_SUCCESS',
  payload: {
    questions: QuestionType[]
  }
};

export type Action =
  | FETCH_FAQ_THEME_START_ACTION
  | FETCH_FAQ_THEME_FAILURE_ACTION
  | FETCH_FAQ_THEME_SUCCESS_ACTION
  | FETCH_FAQ_QUESTIONS_START_ACTION
  | FETCH_FAQ_QUESTIONS_FAILURE_ACTION
  | FETCH_FAQ_QUESTIONS_SUCCESS_ACTION;

export function fetchThemes(): FETCH_FAQ_THEME_START_ACTION {
  return {
    type: FETCH_FAQ_THEME_START
  };
}

export function ThemesFetched(themes: ThemeType[]): FETCH_FAQ_THEME_SUCCESS_ACTION {
  return {
    type: FETCH_FAQ_THEME_SUCCESS,
    payload: { themes }
  };
}

export function ThemesFetchError(): FETCH_FAQ_THEME_FAILURE_ACTION {
  return {
    type: FETCH_FAQ_THEME_FAILURE
  };
}

export function fetchQuestions(): FETCH_FAQ_QUESTIONS_START_ACTION {
  return {
    type: FETCH_FAQ_QUESTIONS_START
  };
}

export function QuestionsFetched(questions: QuestionType[]): FETCH_FAQ_QUESTIONS_SUCCESS_ACTION {
  return {
    type: FETCH_FAQ_QUESTIONS_SUCCESS,
    payload: { questions }
  };
}

export function QuestionsFetchError(): FETCH_FAQ_QUESTIONS_FAILURE_ACTION {
  return {
    type: FETCH_FAQ_QUESTIONS_FAILURE
  };
}
