// @flow
import React, { Component, Fragment } from 'react';

import ModalFormContact from 'components/entreprise/ModalFormContact';
import Loading from 'components/fragments/Loading';
import { STATUS_LOADING } from 'constants/statusConstants';

import type { ContactFormType } from 'types/ContactForm';
import type { Status } from 'types/Status';

export type StateProps = {
  contactFormList: Array<ContactFormType>,
  fetchStatus: Status
};

export type DispatchProps = {
  onSubmit: (formInputs: Object, id: number) => void
};

type Props = {
  content: {
    people: number,
    options: Array<string>,
    id: number,
    title: string,
    selectedDej: string
  }
} & StateProps &
  DispatchProps;

class ModalFormSeminaire extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.content !== this.props.content) {
      return true;
    }

    return false;
  }

  _getSeminaireForm = () => {
    const { contactFormList } = this.props;

    if (contactFormList && contactFormList.length > 0) {
      const seminaireForm = contactFormList.filter(form => form.title === 'seminaire');

      if (seminaireForm && seminaireForm.length > 0) {
        return seminaireForm[0].id;
      }
    }

    return 0;
  };

  _handleSubmit = (values: Object) => {
    const { onSubmit, content } = this.props;
    const { name, email, company, message, phone } = values;

    const formId = this._getSeminaireForm();

    const recap = `Déjeuner :
      ${content && content.selectedDej} \n
      Nombre d'invités : ${content && content.people} \n
      Options : \n
      ${content &&
        content.options.reduce((acc, opt) => {
          return `${acc} ${opt} \n`;
        }, '')}
      `;
    const formData = {
      'seminaire-nom': name,
      'seminaire-email': email,
      'seminaire-message': message,
      'seminaire-societe': company,
      'seminaire-telephone': phone,
      'seminaire-id': content && content.id,
      'seminaire-recap': recap
    };

    onSubmit(formData, formId);
  };

  _renderFormInputs = () => {
    const {
      fetchStatus,
      content: { title }
    } = this.props;

    if (fetchStatus === STATUS_LOADING) {
      return <Loading />;
    }

    return <ModalFormContact onSubmit={this._handleSubmit} tagManagerContent={title} />;
  };

  _renderOptions = () => {
    const { content } = this.props;

    if (content && content.options) {
      const selectedOptions = content.options.map((option, index) => <p key={`option-${index}`}>{option}</p>);

      return (
        <div>
          <h6>Options selectionnées</h6>
          {selectedOptions}
        </div>
      );
    }

    return null;
  };

  render() {
    const { content } = this.props;

    return (
      <Fragment>
        <h5 className="ft-400 mb-1">Récapitulatif de l’événement</h5>
        <div className="modal__event-abstract mb-4">
          <div>
            <h6>Déjeuner</h6>
            <p>{content && content.selectedDej}</p>
          </div>
          <div>
            <h6>invités</h6>
            <p>{content && content.people} invités</p>
          </div>
          {this._renderOptions()}
        </div>
        <h5 className="ft-400 mb-1">Informations de contact</h5>
        {this._renderFormInputs()}
      </Fragment>
    );
  }
}

export default ModalFormSeminaire;
