// @flow

import type { JobListType } from 'types/JobList';
import type { JobDetailType } from 'types/JobDetail';
import type { JobFiltersType, JobFiltersSearchType } from 'types/JobFilter';

export const FETCH_JOB_LIST_FILTER_START = 'FETCH_JOB_LIST_FILTER_START';
export const FETCH_JOB_LIST_START = 'FETCH_JOB_LIST_START';
export const FETCH_JOB_LIST_WITHOUT_FILTER_START = 'FETCH_JOB_LIST_WITHOUT_FILTER_START';
export const FETCH_JOB_LIST_FILTER_SUCCESS = 'FETCH_JOB_LIST_FILTER_SUCCESS';
export const FETCH_JOB_LIST_SUCCESS = 'FETCH_JOB_LIST_SUCCESS';
export const FETCH_JOB_LIST_FAILURE = 'FETCH_JOB_LIST_FAILURE';

export const FETCH_JOB_DETAIL_START = 'FETCH_JOB_DETAIL_START';
export const FETCH_JOB_DETAIL_SUCCESS = 'FETCH_JOB_DETAIL_SUCCESS';
export const FETCH_JOB_DETAIL_FAILURE = 'FETCH_JOB_DETAIL_FAILURE';

export const FETCH_JOB_PREVIEWBYID_START = 'FETCH_JOB_PREVIEWBYID_START';
export const FETCH_JOB_PREVIEWBYID_SUCCESS = 'FETCH_JOB_PREVIEWBYID_SUCCESS';
export const FETCH_JOB_PREVIEWBYID_FAILURE = 'FETCH_JOB_PREVIEWBYID_FAILURE';

export const FETCH_UNKNOWN = 'FETCH_UNKNOWN';

export type FETCH_JOB_LIST_FILTER_START_ACTION = {
  type: 'FETCH_JOB_LIST_FILTER_START',
};

export type FETCH_JOB_LIST_START_ACTION = {
  type: 'FETCH_JOB_LIST_START',
  payload: {
    filters?: JobFiltersSearchType,
  },
};

export type FETCH_JOB_LIST_WITHOUT_FILTER_START_ACTION = {
  type: 'FETCH_JOB_LIST_WITHOUT_FILTER_START',
  payload: {
    filters?: JobFiltersSearchType
  },
};

export type FETCH_JOB_LIST_FAILURE_ACTION = {
  type: 'FETCH_JOB_LIST_FAILURE'
};

export type FETCH_JOB_LIST_SUCCESS_ACTION = {
  type: 'FETCH_JOB_LIST_SUCCESS',
  payload: {
    jobs: Array<JobListType>,
    maxPage: number,
    totalJobs: number,
    jobFilters?: JobFiltersType,
  }
};

export type FETCH_JOB_LIST_FILTER_SUCCESS_ACTION = {
  type: 'FETCH_JOB_LIST_FILTER_SUCCESS',
  payload: {
    jobFilters: JobFiltersType,
  }
};

export type FETCH_JOB_DETAIL_START_ACTION = {
  type: 'FETCH_JOB_DETAIL_START',
  payload: {
    slug: string
  }
};

export type FETCH_JOB_DETAIL_FAILURE_ACTION = {
  type: 'FETCH_JOB_DETAIL_FAILURE'
};

export type FETCH_JOB_DETAIL_SUCCESS_ACTION = {
  type: 'FETCH_JOB_DETAIL_SUCCESS',
  payload: {
    details: JobDetailType,
    analytics: boolean
  }
};

export type FETCH_JOB_PREVIEWBYID_START_ACTION = {
  type: 'FETCH_JOB_PREVIEWBYID_START',
  payload: {
    id: number
  }
};

export type FETCH_JOB_PREVIEWBYID_FAILURE_ACTION = {
  type: 'FETCH_JOB_PREVIEWBYID_FAILURE'
};

export type FETCH_JOB_PREVIEWBYID_SUCCESS_ACTION = {
  type: 'FETCH_JOB_PREVIEWBYID_SUCCESS',
  payload: {
    details: JobDetailType,
    analytics: boolean
  }
};

export type FETCH_UNKNOWN_ACTION = {
  type: 'FETCH_UNKNOWN'
};

export type Action =
  | FETCH_JOB_LIST_FILTER_START_ACTION
  | FETCH_JOB_LIST_START_ACTION
  | FETCH_JOB_LIST_WITHOUT_FILTER_START_ACTION
  | FETCH_JOB_LIST_FAILURE_ACTION
  | FETCH_JOB_LIST_SUCCESS_ACTION
  | FETCH_JOB_LIST_FILTER_SUCCESS_ACTION
  | FETCH_JOB_DETAIL_START_ACTION
  | FETCH_JOB_DETAIL_FAILURE_ACTION
  | FETCH_JOB_DETAIL_SUCCESS_ACTION
  | FETCH_JOB_PREVIEWBYID_START_ACTION
  | FETCH_JOB_PREVIEWBYID_FAILURE_ACTION
  | FETCH_JOB_PREVIEWBYID_SUCCESS_ACTION
  | FETCH_UNKNOWN_ACTION;

export function fetchJobFilters(): FETCH_JOB_LIST_FILTER_START_ACTION {
  return {
    type: FETCH_JOB_LIST_FILTER_START,
  };
}

export function initialFetchJobList(filters?: JobFiltersSearchType): FETCH_JOB_LIST_START_ACTION {
  return {
    type: FETCH_JOB_LIST_START,
    payload: { filters }
  };
}

export function fetchJobList(filters?: JobFiltersSearchType): FETCH_JOB_LIST_WITHOUT_FILTER_START_ACTION {
  return {
    type: FETCH_JOB_LIST_WITHOUT_FILTER_START,
    payload: { filters }
  };
}

export function jobListFetched(
  jobList: Array<JobListType>,
  maxPage: number,
  totalJobs: number,
  jobFilters?: JobFiltersType,
): FETCH_JOB_LIST_SUCCESS_ACTION {
  return {
    type: FETCH_JOB_LIST_SUCCESS,
    payload: { jobs: jobList, jobFilters, maxPage, totalJobs },
  };
}

export function jobListFiltersFetched(
  jobFilters: JobFiltersType,
): FETCH_JOB_LIST_FILTER_SUCCESS_ACTION {
  return {
    type: FETCH_JOB_LIST_FILTER_SUCCESS,
    payload: { jobFilters },
  };
}

export function jobListFetchError(): FETCH_JOB_LIST_FAILURE_ACTION {
  return {
    type: FETCH_JOB_LIST_FAILURE,
  };
}

export function fetchJobDetail(slug: string): FETCH_JOB_DETAIL_START_ACTION {
  return {
    type: FETCH_JOB_DETAIL_START,
    payload: { slug },
  };
}

export function jobDetailFetched(jobDetail: JobDetailType, analytics?: boolean): FETCH_JOB_DETAIL_SUCCESS_ACTION {
  return {
    type: FETCH_JOB_DETAIL_SUCCESS,
    payload: {
      details: jobDetail,
      analytics: analytics ? analytics : false,
    },
  };
}

export function jobDetailFetchError(): FETCH_JOB_DETAIL_FAILURE_ACTION {
  return {
    type: FETCH_JOB_DETAIL_FAILURE,
  };
}

export function fetchJobDetailPreview(id: number): FETCH_JOB_PREVIEWBYID_START_ACTION {
  return {
    type: FETCH_JOB_PREVIEWBYID_START,
    payload: { id }
  };
}

export function jobDetailPreviewFetched(
  jobDetail: JobDetailType,
  analytics?: boolean
): FETCH_JOB_PREVIEWBYID_SUCCESS_ACTION {
  return {
    type: FETCH_JOB_PREVIEWBYID_SUCCESS,
    payload: {
      details: jobDetail,
      analytics: analytics ? analytics : false
    }
  };
}

export function jobDetailPreviewFetchError(): FETCH_JOB_PREVIEWBYID_FAILURE_ACTION {
  return {
    type: FETCH_JOB_PREVIEWBYID_FAILURE,
  };
}

export function fetchUnknown(): FETCH_UNKNOWN_ACTION {
  return {
    type: FETCH_UNKNOWN,
  };
}
