// @flow
import React from 'react';

import RadioInput from 'components/fragments/input/RadioInput';
import type { FullUserType, EditProfileValueType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  userPref: FullUserType
};

export type DispatchProps = {
  displayModal: (modalObject: string) => void,
  postUserPref: (action: string, id: EditProfileValueType, token: string) => void
};

type Props = DispatchProps &
  StateProps & {
    content: any,
    hideModal: () => void
  };

function UnsubscribeNewsletterModalContent({ content, displayModal, hideModal, postUserPref, userPref }: Props) {
  const sendUnsubscription = () => {
    const allInput = [...document.getElementsByTagName('input')];
    allInput.forEach(element => {
      element.checked = false;
    });
    postUserPref('newsletter-change', [], userPref.token);
    displayModal('MODAL_UNSUBSCRIBE_NEWSLETTER_DONE');
  };

  return (
    <div className="modal-content box newsletter-unsubscribe modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-4">Désinscription de la newsletter</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-5">
          <div className="circle-icon circle-icon--check" />
          <p className="mb-0">
            Vous avez bien été <strong>désinscrit</strong> de la newsletter de la FFR.
          </p>
        </div>
        <p className="mb-2 ft-black-secondary">En 2 clics, dites-nous pourquoi :</p>
        <RadioInput id="pivDvtQSZVaM" label="Le contenu ne m’intéresse pas" />
        <RadioInput id="hJuETJFIioUI" label="J'en reçois trop" />
        <RadioInput id="TowUtwMAVRzv" label="Je ne m'intéresse plus au rugby" />
        <RadioInput id="yCvZnbmcQAyB" classNames="radio mb-3" label="Autre" />
        <a
          className="btn btn--primary btn--full js-confirm mb-1"
          onClick={handleTagEvent('espace perso', 'desinscription_newsletter', '', userPref, sendUnsubscription)}
          role="button"
          tabIndex="0"
        >
          Envoyer
        </a>
      </div>
    </div>
  );
}

export default UnsubscribeNewsletterModalContent;
