// @flow
import Modal from 'components/fragments/modal/Modal';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps, StateProps } from 'components/fragments/modal/Modal';
import { hideModal } from 'actions/modalActions';

export default connect(
  (state: StateType): StateProps => ({
    modalObject: state.modalState.modalObject,
    content: state.modalState.content,
    force: state.modalState.force
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal())
  })
)(Modal);
