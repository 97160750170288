// @flow
import { connect } from 'react-redux';

import ModalFormCentenaire from 'components/centenaire/ModalFormCentenaire';
import { postCentenaireForm } from 'actions/centenaireFormActions';
import { fetchClubs } from 'actions/clubsActions';

import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps, StateProps } from 'components/centenaire/ModalFormCentenaire';

import { displayModal } from 'actions/modalActions';

export default connect(
  (state: StateType): StateProps => ({
    clubs: state.clubsState.clubs,
    sendStatus: state.centenaireFormState.sendStatus
  }),
  (dispatch: DispatchType): DispatchProps => ({
    onSubmit: (formInputs: Object) => dispatch(postCentenaireForm(formInputs)),
    fetchClubsPreview: () => dispatch(fetchClubs()),
    displayModal: (modalObject: string, content: Object, force: boolean) =>
      dispatch(displayModal(modalObject, content, force))
  })
)(ModalFormCentenaire);
