// @flow

export type RawYoutubeChannel = {
  kind: string,
  etag: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: string,
    contentDetails: {
      relatedPlaylists: {
        uploads: string,
        watchHistory: string,
        watchLater: string
      }
    }
  }>
};

export const nullRawYoutubeChannel = {
  kind: '',
  etag: '',
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0
  },
  items: [
    {
      kind: '',
      etag: '',
      id: '',
      contentDetails: {
        relatedPlaylists: {
          uploads: '',
          watchHistory: '',
          watchLater: ''
        }
      }
    }
  ]
};

export type RawYoutubeChannelInfo = {
  etag: string,
  items: Array<{
    etag: string,
    id: string,
    kind: string,
    snippet: {
      description: string,
      localized: {
        description: string,
        title: string
      },
      publishedAt: string,
      thumbnails: {
        default: { height: number, url: string, width: number },
        high: {
          height: number,
          url: string,
          width: number
        },
        medium: {
          height: number,
          url: string,
          width: number
        },
        title: string
      }
    }
  }>,
  kind: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  }
};

export type YoutubeChannelInfo = {
  id: string,
  title: string,
  description: string,
  publishedAt: string
};

export const nullChannel = {
  id: '',
  title: '',
  description: '',
  publishedAt: ''
};
