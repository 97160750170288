// @flow
import React from 'react';

import AddAFavoriteTeamModalContent from 'containers/profile/MesFavoris/AddAFavoriteTeamModalContentContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function AddAFavoriteTeamSearchPanelModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box add-favorite-item-modal modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />

      <div className="modal__wrapper">
        <AddAFavoriteTeamModalContent hideModal={hideModal} />
      </div>
    </div>
  );
}

export default AddAFavoriteTeamSearchPanelModalContent;
