// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_RANDOM_VIDEO_PLAYER_START = 'FETCH_RANDOM_VIDEO_PLAYER_START';
export const FETCH_RANDOM_VIDEO_PLAYER_SUCCESS = 'FETCH_RANDOM_VIDEO_PLAYER_SUCCESS';
export const FETCH_RANDOM_VIDEO_PLAYER_FAILURE = 'FETCH_RANDOM_VIDEO_PLAYER_FAILURE';

export type FETCH_RANDOM_VIDEO_PLAYER_START_ACTION = {
  type: 'FETCH_RANDOM_VIDEO_PLAYER_START',
  payload: { idVideo: string }
};

export type FETCH_RANDOM_VIDEO_PLAYER_FAILURE_ACTION = {
  type: 'FETCH_RANDOM_VIDEO_PLAYER_FAILURE'
};

export type FETCH_RANDOM_VIDEO_PLAYER_SUCCESS_ACTION = {
  type: 'FETCH_RANDOM_VIDEO_PLAYER_SUCCESS',
  payload: {
    randomVideos: Array<YoutubeVideo>
  }
};

export type Action =
  | FETCH_RANDOM_VIDEO_PLAYER_START_ACTION
  | FETCH_RANDOM_VIDEO_PLAYER_FAILURE_ACTION
  | FETCH_RANDOM_VIDEO_PLAYER_SUCCESS_ACTION;

export function fetchRandomVideosPlayer(idVideo: string): FETCH_RANDOM_VIDEO_PLAYER_START_ACTION {
  return {
    type: FETCH_RANDOM_VIDEO_PLAYER_START,
    payload: { idVideo }
  };
}

export function randomVideosPlayerFetched(randomVideos: Array<YoutubeVideo>): FETCH_RANDOM_VIDEO_PLAYER_SUCCESS_ACTION {
  return {
    type: FETCH_RANDOM_VIDEO_PLAYER_SUCCESS,
    payload: { randomVideos }
  };
}

export function randomVideosPlayerFetchError(error: any): FETCH_RANDOM_VIDEO_PLAYER_FAILURE_ACTION {
  return {
    type: FETCH_RANDOM_VIDEO_PLAYER_FAILURE
  };
}
