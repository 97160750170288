// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_YOUTUBE_CHANNEL_START,
  channelDataFetched,
  channelDataFetchedError,
  type FETCH_YOUTUBE_CHANNEL_START_ACTION
} from 'actions/ffr-tv/youtubeChannelAction';
import ChannelApi from 'api/ffrtv/ChannelApi';

export default function(apiExecutor: ApiExecutorType) {
  const channelApi = new ChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_CHANNEL_START, fetchChannelData);
  };

  function* fetchChannelData(action: FETCH_YOUTUBE_CHANNEL_START_ACTION): Saga<void> {
    const {
      payload: { idChannel }
    } = action;
    try {
      const channel = yield call(channelApi.fetchChannelData, idChannel);
      yield put(channelDataFetched(channel));
    } catch (e) {
      yield put(channelDataFetchedError());
    }
  }
}
