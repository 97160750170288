// @flow

import React, { useEffect, useState } from 'react';
import Logo from './logo_bleu.svg';

import './facilitiButton.css';

type Props = {
  isAside?: boolean,
  isFooter?: boolean
};

function FacilitiButton({ isAside, isFooter }: Props) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (isAside) {
      let duration = 3000;
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        duration = 1500;
      }

      setTimeout(() => {
        setIsOpen(false);
      }, duration);
    } else {
      setIsOpen(false);
    }
  }, []);

  const showAndHide = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button
      type="button"
      data-faciliti-popin
      style={{
        margin: 0
      }}
      className={`faciliti-button ${isAside && isOpen ? 'is-open' : ''} ${isAside ? 'is-aside' : ''} ${
        isFooter ? 'is-footer' : ''
      }`}
      aria-label="FACIL'iti : Adaptez l’affichage"
      onClick={showAndHide}
    >
      <img src={Logo} alt="Gérer les options d’affichage" />
      <span className="cta-text">Adaptez {isAside && <br />} l&apos;affichage</span>
    </button>
  );
}

export default FacilitiButton;
