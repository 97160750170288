// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_NEW_VIDEOS_START = 'FETCH_NEW_VIDEOS_START';
export const FETCH_NEW_VIDEOS_SUCCESS = 'FETCH_NEW_VIDEOS_SUCCESS';
export const FETCH_NEW_VIDEOS_FAILURE = 'FETCH_NEW_VIDEOS_FAILURE';


export type FETCH_NEW_VIDEOS_START_ACTION = {
  type: 'FETCH_NEW_VIDEOS_START',
  payload: { idChannel: string }
};

export type FETCH_NEW_VIDEOS_SUCCESS_ACTION = {
  type: 'FETCH_NEW_VIDEOS_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>,
  }
};

export type FETCH_NEW_VIDEOS_FAILURE_ACTION = {
  type: 'FETCH_NEW_VIDEOS_FAILURE'
};


export type Action =
  | FETCH_NEW_VIDEOS_START_ACTION
  | FETCH_NEW_VIDEOS_FAILURE_ACTION
  | FETCH_NEW_VIDEOS_SUCCESS_ACTION;

export function fetchNewVideos(
  idChannel: string,
): FETCH_NEW_VIDEOS_START_ACTION {
  return {
    type: FETCH_NEW_VIDEOS_START,
    payload: {
      idChannel
    }
  };
}

export function newVideosFetched(
  videos: Array<YoutubeVideo>
): FETCH_NEW_VIDEOS_SUCCESS_ACTION {
  return {
    type: FETCH_NEW_VIDEOS_SUCCESS,
    payload: { videos }
  };
}

export function newVideosFetchError(): FETCH_NEW_VIDEOS_FAILURE_ACTION {
  return {
    type: FETCH_NEW_VIDEOS_FAILURE
  };
}
