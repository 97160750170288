// @flow
import React, { PureComponent } from 'react';
import { handleTagEvent }from 'utils/tagManagerUtils';
import { socialNetworks } from 'utils/socialShareUtils';
import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType
};

export type Props = StateProps & {
  url: string,
  title: string,
};

class SocialButtons extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    url: ''
  };
  render() {
    const { userPref, url, title } = this.props;
    const socialNetworksButtons: Array<any> = Object.keys(socialNetworks).map(key => {
      const ShareButton = socialNetworks[key];
      let classNames = key === 'mail' ? 'btn btn--social btn--small btn--primary btn--medium' : 
      `btn btn--social btn--small btn--${key} btn--medium`;
      if (key === 'twitter') {
        classNames = `${classNames} btn--x is-dark`;
      }
      const propsShare = {
        url: url,
        className: classNames,
        role: 'button',
        tabIndex: 0,
        quote: (key === 'facebook') ? title : false,
        title: (key === 'twitter') ? title : false,
        subject: (key === 'mail') ? title : false,
      };

      return (
        <ShareButton
          {...propsShare}
          beforeOnClick={handleTagEvent(
            'partage',
            title,
            key,
            userPref
          )}
          key={`${key}-share-button`}>
          <i className={`icon icon-${key}`} />
        </ShareButton>
      );
    });
    return socialNetworksButtons; 
  }
}
export default SocialButtons;
