// @flow
import React, { PureComponent } from 'react';
import { nullAcademie } from 'types/Academie';
import type { AcademiePreviewType } from 'types/Academie';

type Props = {
  academie: AcademiePreviewType,
  position?: number,
  design?: string
};

class CardAcademie extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    academie: nullAcademie,
    position: 0,
    design: ''
  };

  render() {
    const { 
      academie : {
        name,
        ligue,
        lycee,
        adresse,
        ville,
        formateur,
        formateur_email,
        formateur_telephone
      },
      position,
      design
    } = this.props;

    return (
      <div className={ design === 'marker' ? 
        "card card--club card--clubSmall mb-1" : 
        "box box--white card card--club card--clubSmall mb-1"}>
        <div className="card__body">
          <div>
            <h4 className="card__title ft-card__title ft-truncat ft-h5">{name}</h4>
            { ligue && 
            <p className="card__desc ft-h6 ft-up">{ ligue } </p> }
            { lycee && 
            <p className="card__desc card__desc--address">
              <i className="icon icon-place is-inline"></i>
              <span>
                <a 
                  href={`https://www.google.com/maps/dir/?api=1&destination=${lycee}+${adresse ? adresse : ''}+${ville ? ville : ''}`}
                  title={ "Visualiser l'adresse du club sur Google Maps (nouvel onglet)"}
                  target="_blank"
                  rel="noopener noreferrer">
                  { lycee }
                  <br />{ adresse }
                  <br />{ ville }
                </a>
              </span>
            </p> }

            { formateur && formateur_email && 
            <p className="card__desc">
              <i className="icon icon-account is-inline"></i>
              <span>
                <a href={`mailto:${formateur_email}`} rel="noopener noreferrer">
                  { formateur }
                </a>
              </span>
            </p> }
            { formateur && !formateur_email && 
            <p className="card__desc">
              <i className="icon icon-account is-inline"></i>
              <span>
                  { formateur }
              </span>
            </p>
            }
            { formateur_telephone && 
            <p className="card__desc">
              <i className="icon icon-phone is-inline"></i>
              <span>
                <a href={`tel:${formateur_telephone}`} rel="noopener noreferrer">
                  { formateur_telephone }
                </a>
              </span>
            </p> }
            <span className="card__position ft-h6">{position}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CardAcademie;
