// @flow

import React, { Component } from 'react';
import type { FaitDeJeuType } from 'types/Rencontre';
import { getIconClassName } from 'utils/rencontreUtils';

type Props = {
  faitsDeJeu: Array<FaitDeJeuType>,
  francePlaysAtHome: boolean,
  france_score_mi_temps: number,
  france_score: number,
  adversaire_score: number,
  adversaire_score_mi_temps: number,
  rencontre_live: boolean
};

type State = {
  firstHalf: Array<FaitDeJeuType>,
  secondHalf: Array<FaitDeJeuType>
};

export default class Highlights extends Component<Props, State> {
  state: State = {
    firstHalf: [],
    secondHalf: []
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const firstHalf = [];
    const secondHalf = [];
    const { faitsDeJeu, rencontre_live } = props;
    const { firstHalf: prevFirstHalf, secondHalf: prevSecondHalf } = state;
    const totalFaitsDeJeu = prevFirstHalf.length + prevSecondHalf.length;

    if (faitsDeJeu && faitsDeJeu.length > 0 && totalFaitsDeJeu !== faitsDeJeu.length) {
      props.faitsDeJeu.forEach(fait => {
        if (fait.quand !== '-1') {
          const extraTimeRegExp = /[0-9][0-9][+]([0-9]*)+/g;
          if (Number(fait.quand) < 40) {
            // $FlowFixMe.
            fait.quandFinal = Number(fait.quand);
            firstHalf.push(fait);
          } else if (Number(fait.quand) > 40) {
            // $FlowFixMe.
            fait.quandFinal = Number(fait.quand);
            secondHalf.push(fait);
          } else if (fait.quand.match(extraTimeRegExp) && Number(fait.quand.substr(0, 2)) <= 40) {
            // $FlowFixMe.
            fait.quandFinal = Number(fait.quand.substr(0, 2));
            firstHalf.push(fait);
          } else if (fait.quand.match(extraTimeRegExp) && Number(fait.quand.substr(0, 2)) <= 80) {
            // $FlowFixMe.
            fait.quandFinal = Number(fait.quand.substr(0, 2));
            secondHalf.push(fait);
          } else {
            secondHalf.push(fait);
          }
        }
      });
      if (rencontre_live === true) {
        // $FlowFixMe.
        firstHalf.sort((a, b) => Number(a.quandFinal) - Number(b.quandFinal));
        // $FlowFixMe.
        secondHalf.sort((a, b) => Number(a.quandFinal) - Number(b.quandFinal));
      } else {
        // $FlowFixMe.
        firstHalf.sort((a, b) => Number(b.quandFinal) - Number(a.quandFinal));
        // $FlowFixMe.
        secondHalf.sort((a, b) => Number(b.quandFinal) - Number(a.quandFinal));
      }
      return { firstHalf, secondHalf };
    }

    return null;
  }

  doesLocalTeamWon = () => {
    const { france_score_mi_temps, adversaire_score_mi_temps, francePlaysAtHome } = this.props;
    const className = 'is-winner';
    if (francePlaysAtHome && france_score_mi_temps > adversaire_score_mi_temps) {
      return className;
    } else if (!francePlaysAtHome && adversaire_score_mi_temps > france_score_mi_temps) {
      return className;
    }
    return '';
  };

  doesChallengerTeamWon = () => {
    const { france_score_mi_temps, adversaire_score_mi_temps, francePlaysAtHome } = this.props;
    const className = 'is-winner';
    if (francePlaysAtHome && france_score_mi_temps > adversaire_score_mi_temps) {
      return '';
    } else if (!francePlaysAtHome && adversaire_score_mi_temps > france_score_mi_temps) {
      return '';
    } else {
      return className;
    }
  };

  renderHighlightsCircles = (faitsDeJeu: Array<FaitDeJeuType>): Array<React$Element<'div'>> => {
    const { francePlaysAtHome } = this.props;
    return faitsDeJeu.map((fait, index) => {
      const isContentLeft =
        (francePlaysAtHome && fait.equipe === 'france') || (!francePlaysAtHome && fait.equipe === 'adversaire');
      const circleContentClasses = `circle__content circle__content--info ${
        isContentLeft ? 'circle__content--left' : 'circle__content--right'
      }`;
      const circleTimeClasses = `circle__content circle__content--time ${
        isContentLeft ? 'circle__content--right' : 'circle__content--left'
      }`;
      const isFirstOrLastClasses =
        index === 0
          ? 'highlight__circle highlight__circle--last'
          : index === faitsDeJeu.length - 1
          ? 'highlight__circle highlight__circle--first'
          : 'highlight__circle';
      const displayedType =
        fait.quoi === 'carton_jaune' || fait.quoi === 'carton_rouge'
          ? fait.quoi.split('_')[0] + ' ' + fait.quoi.split('_')[1]
          : fait.quoi;

      const isLeftOrRight = `${isFirstOrLastClasses} ${isContentLeft ? 'hightlight__circle__content--left' : 'hightlight__circle__content--right'}`;
      return (
        <div key={`${fait.quoi}_${fait.joueur}_${fait.quand}'`} className={isLeftOrRight+' '+fait.quoi}>
          <p className={circleContentClasses}>
            
            {fait.quoi !== 'remplacement' &&
            <>{`${displayedType.substr(0, 1).toUpperCase()}${displayedType.slice(1).replace('enalite', 'énalité')}`}</>}
            {fait.quoi === 'essai' && fait.raison === 'De pénalité' && <span>{fait.raison}</span>}
            {((fait.quoi !== 'essai' || fait.raison !== 'De pénalité')) && <span>{fait.joueur}</span>}
            {fait.quoi === 'remplacement' &&
            <>{fait.remplace}</>}
          </p>
          <div className="circle__icon">
            <i className={getIconClassName(fait.quoi)}></i>
          </div>
          <p className={circleTimeClasses}>
            <b>{`${fait.quand} '`}</b>
          </p>
        </div>
      );
    });
  };

  render() {
    const { firstHalf, secondHalf } = this.state;
    const { francePlaysAtHome, france_score_mi_temps, adversaire_score_mi_temps,rencontre_live } = this.props;
    const homeScore = francePlaysAtHome ? france_score_mi_temps : adversaire_score_mi_temps;
    const visitorScore = francePlaysAtHome ? adversaire_score_mi_temps : france_score_mi_temps;
    
    if (rencontre_live === true) {
      return (
        <div className="row mt-3">
          <div className="col col-lg-8on9">
            <div className="highlight is-live">
              <div className="highlight__title highlight__title--end"> Début du match </div>
              {this.renderHighlightsCircles(firstHalf)}
              <div className="highlight__halfTime">
                <div>mi-temps</div>
                <p>
                  <span className={this.doesLocalTeamWon()}>{homeScore}</span>
                  {' : '}
                  <span className={this.doesChallengerTeamWon()}>{visitorScore}</span>
                </p>
              </div>
              {this.renderHighlightsCircles(secondHalf)}
              <div className="highlight__title highlight__title--start"> Fin de match </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row mt-3">
        <div className="col col-lg-8on9">
          <div className="highlight">
            <div className="highlight__title highlight__title--end"> Fin du match </div>
            {this.renderHighlightsCircles(secondHalf)}
            <div className="highlight__halfTime">
              <div>mi-temps</div>
              {(homeScore > 0 || visitorScore > 0) && 
              <p>
                <span className={this.doesLocalTeamWon()}>{homeScore}</span>
                {' : '}
                <span className={this.doesChallengerTeamWon()}>{visitorScore}</span>
              </p>}
            </div>
            {this.renderHighlightsCircles(firstHalf)}
            <div className="highlight__title highlight__title--start"> Début de match </div>
          </div>
        </div>
      </div>
    );
  }
}
