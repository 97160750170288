// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_VIDEO_PLAYERS_START,
  FETCH_VIDEO_PLAYERS_SUCCESS,
  FETCH_VIDEO_PLAYERS_FAILURE
} from 'actions/ffr-tv/youtubeVideoPlayersActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import type { JoueurType } from 'types/Joueur';

export type State = {
  players: Array<JoueurType>,
  status: Status
};

const initialState: State = {
  players: [],
  status: STATUS_DEFAULT,
  error: {}
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_VIDEO_PLAYERS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_VIDEO_PLAYERS_SUCCESS:
      return {
        ...state,
        players: action.payload.players,
        status: STATUS_SUCCESS
      };
    case FETCH_VIDEO_PLAYERS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
