// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import NextRencontreCard from 'containers/competitions/NextRencontreCardContainer';
import type { RencontreType } from 'types/Rencontre';
import type { FullUserType } from 'types/User';

export type StateProps = {
  push_rencontre?: RencontreType,
  userPref: FullUserType
};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

function TicketingSuccessModalContent({
  content,
  hideModal,
  push_rencontre,
  userPref: {
    profil: { prenom }
  }
}: Props) {
  const renderPushRencontre = () => {
    if (push_rencontre) {
      const {
        meta: rencontre,
        meta: { equipeNom },
        competition: { name: competitionTitle }
      } = push_rencontre;

      return (
        <div className="modal__wrapper modal__wrapper--footer">
          <p className="ft-black ft-500 mb-2">Ça peut vous intéresser…</p>
          <NextRencontreCard isSmall rencontre={rencontre} competitionTitle={competitionTitle} equipeNom={equipeNom} />
        </div>
      );
    }

    return null;
  };
  return (
    <div className="modal-content box ticketing-signup-validation modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-4">C’est noté {prenom} !</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-4">
          <div className="circle-icon circle-icon--check"></div>
          <p className="mb-0">
            L’alerte a été ajoutée à votre espace perso FFR. N’hésitez pas à vous y rendre pour gérer toutes vos
            alertes.
          </p>
        </div>
        <Link
          className="btn btn--primary btn--full js-confirm mb-1"
          onClick={hideModal}
          to={'/tableau-de-bord/mes-alertes-billetterie'}
        >
          Accéder à mon espace perso FFR
        </Link>
      </div>
      {renderPushRencontre()}
    </div>
  );
}

export default TicketingSuccessModalContent;
