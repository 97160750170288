// @flow

import {
  FETCH_JOUEURSEARCH_START,
  FETCH_JOUEURSEARCH_SUCCESS,
  FETCH_JOUEURSEARCH_FAILURE
} from 'actions/joueursearchActions';
import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';

import type { ActionType } from 'types/Actions';
import type { JoueursearchType } from 'types/Joueursearch';
import type { Status } from 'types/Status';

export type State = {
  joueursearch: JoueursearchType,
  status: Status,
};

const initialState: State = {
  joueursearch: [],
  status: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_JOUEURSEARCH_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_JOUEURSEARCH_SUCCESS:
      return {
        ...state,
        joueursearch: action.payload.joueursearch,
        status: STATUS_SUCCESS,
      };
    case FETCH_JOUEURSEARCH_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}