// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertRencontreFromApi } from 'services/Rencontre';
import { convertObjectToQueryString } from 'utils/urlUtils';

function RencontreApi(executor: ApiExecutorType) {
  return {
    fetchRencontreBySlug,
    fetchRencontreByFilters,
  };

  function fetchRencontreBySlug(slug: string) {
    const url = `/ffr/v1/rencontres_itl?slug=${slug}`;
    return executor
      .get(url)
      .then(response => convertRencontreFromApi(response[0]));
  }

  function fetchRencontreByFilters(filters: Object) {
    const url = `/ffr/v1/rencontres_itl${convertObjectToQueryString({ ...filters})}&per_page=100`;
    return executor
      .get(url)
      .then(response => response.map(res => convertRencontreFromApi(res)));
  }
};

export default RencontreApi;
