// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertYoutubeLiveFromApi, convertVideoFromApi } from 'services/Tv';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import { YOUTUBE_FFR_CHANNEL_ID } from 'constants/tvConstants';

function TvApi(executor: ApiExecutorType) {
  return { fetchYoutubeLiveVideo, fetchVideoFromId };

  function fetchYoutubeLiveVideo() {
    return executor
      .get(`/search?part=id&eventType=live&key=${YOUTUBE_ACCESS_TOKEN}&channelId=${YOUTUBE_FFR_CHANNEL_ID}&type=video`)
      .then(response => {
        const videoId = convertYoutubeLiveFromApi(response);
        if (videoId) return fetchVideoFromId(videoId, '');
        else return null;
      });
  }

  function fetchVideoFromId(id: string, playlistId: string) {
    return executor
      .get(`/videos?part=contentDetails,snippet&id=${id}&key=${YOUTUBE_ACCESS_TOKEN}`)
      .then(response => convertVideoFromApi(response, playlistId));
  }
}

export default TvApi;
