// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_VIDEOS_PLAYLIST_START,
  FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS,
  FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE
} from 'actions/ffr-tv/youtubeVideosPlaylistActions';

import {
  FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START,
  FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS,
  FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE
} from 'actions/ffr-tv/youtubeMoreVideosPlaylistActions';

import { type YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  videos: Array<YoutubeVideo>,
  statusVideos: Status,
  nextPageToken: string
};

const initialState: State = {
  videos: [],
  statusVideos: STATUS_DEFAULT,
  nextPageToken: ''
};
export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_VIDEOS_PLAYLIST_START:
      return {
        ...state,
        statusVideos: STATUS_LOADING
      };
    case FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS:
      return {
        ...state,
        videos: [...initialState.videos, ...action.payload.videos],
        statusVideos: STATUS_SUCCESS,
        nextPageToken: action.payload.nextPageToken
      };
    case FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };
    case FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START:
      return {
        ...state
      };
    case FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS: {
      return {
        ...state,
        statusVideos: STATUS_SUCCESS,
        videos: [...state.videos, ...action.payload.videos],
        nextPageToken: action.payload.nextPageToken
      };
    }
    case FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };

    default:
      return state;
  }
}
