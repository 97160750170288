// @flow

import type { EventMatchType } from 'types/Event';

export const FETCH_MATCH_EVENTS_START = 'FETCH_MATCH_EVENTS_START';
export const FETCH_MATCH_EVENTS_SUCCESS = 'FETCH_MATCH_EVENTS_SUCCESS';
export const FETCH_MATCH_EVENTS_FAILURE = 'FETCH_MATCH_EVENTS_FAILURE';

export type FETCH_MATCH_EVENTS_START_ACTION = {
  type: 'FETCH_MATCH_EVENTS_START',
  payload: {
    from: Date,
    to: Date
  }
};

export type FETCH_MATCH_EVENTS_FAILURE_ACTION = {
  type: 'FETCH_MATCH_EVENTS_FAILURE'
};

export type FETCH_MATCH_EVENTS_SUCCESS_ACTION = {
  type: 'FETCH_MATCH_EVENTS_SUCCESS',
  payload: {
    matchEvents: Array<EventMatchType>
  }
};

export type Action =
  | FETCH_MATCH_EVENTS_START_ACTION
  | FETCH_MATCH_EVENTS_FAILURE_ACTION
  | FETCH_MATCH_EVENTS_SUCCESS_ACTION;

export function fetchMatchEvents(from: Date, to: Date): FETCH_MATCH_EVENTS_START_ACTION {
  return {
    type: FETCH_MATCH_EVENTS_START,
    payload: {
      from,
      to
    }
  };
}

export function matchEventsFetched(matchEvents: Array<EventMatchType>): FETCH_MATCH_EVENTS_SUCCESS_ACTION {
  return {
    type: FETCH_MATCH_EVENTS_SUCCESS,
    payload: { matchEvents }
  };
}

export function matchEventsFetchError(error: any): FETCH_MATCH_EVENTS_FAILURE_ACTION {
  return {
    type: FETCH_MATCH_EVENTS_FAILURE
  };
}
