// @flow

export type RawYoutubeSearch = {
  kind: string,
  etag: string,
  nextPageToken: string,
  prevPageToken: string,
  regionCode: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: {
      kind: string,
      videoId: string
    },
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      tags: Array<string>,
      categoryId: string,
      liveBroadcastContent: string,
      localized: {
        title: string,
        description: string
      },
      defaultAudioLanguage: string
    },
    contentDetails: {
      duration: string,
      dimension: string,
      definition: string,
      caption: string,
      licensedContent: boolean,
      projection: string
    }
  }>
};

export const nullRawYoutubeSearch = {
  kind: '',
  etag: '',
  nextPageToken: '',
  prevPageToken: '',
  regionCode: '',
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0
  },
  items: [
    {
      kind: '',
      etag: '',
      id: {
        kind: '',
        videoId: ''
      },
      snippet: {
        publishedAt: '',
        channelId: '',
        title: '',
        description: '',
        thumbnails: {
          default: {
            url: '',
            width: 0,
            height: 0
          },
          medium: {
            url: '',
            width: 0,
            height: 0
          },
          high: {
            url: '',
            width: 0,
            height: 0
          },
          standard: {
            url: '',
            width: 0,
            height: 0
          },
          maxres: {
            url: '',
            width: 0,
            height: 0
          }
        },
        channelTitle: '',
        tags: [],
        categoryId: '',
        liveBroadcastContent: '',
        localized: {
          title: '',
          description: ''
        },
        defaultAudioLanguage: ''
      },
      contentDetails: {
        duration: '',
        dimension: '',
        definition: '',
        caption: '',
        licensedContent: false,
        projection: ''
      }
    }
  ]
};
