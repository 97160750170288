// @flow

import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import { convertPlaylistFromApi, convertPlaylistsFromApi } from 'services/YoutubePlaylist';

function PlaylistApi(executor: ApiExecutorType) {
  return { fetchPlaylist, fetchPlaylists };

  function fetchPlaylist(idPlaylist: string) {
    return executor

      .get(`/playlists?key=${YOUTUBE_ACCESS_TOKEN}&part=contentDetails,snippet&id=${idPlaylist}`)
      .then(convertPlaylistFromApi);
  }
  function fetchPlaylists(tabIdPlaylist: Array<string>) {
    return executor

      .get(`/playlists?key=${YOUTUBE_ACCESS_TOKEN}&part=contentDetails,snippet&id=${tabIdPlaylist.join()}`)
      .then(convertPlaylistsFromApi);
  }
}
export default PlaylistApi;
