// @flow
import HeaderFindClub from 'components/header/HeaderFindClub';
import { connect } from 'react-redux';

import type { StateType, DispatchType } from 'types/Actions';
import type { DispatchProps } from 'components/header/HeaderFindClub';
import { setCompetitions, setPractices, setDistance } from 'actions/filterOptionsActions';

export default connect(
  (state: StateType, ...ownProps: any) => ({
    clubs: state.clubsState.clubs,
    competitions: state.filterOptionsState.competitions,
    practices: state.filterOptionsState.practices,
    activateDistance: state.filterOptionsState.activateDistance,
    userPref: state.userState.userPref
  }),
  (dispatch: DispatchType): DispatchProps => ({
    setCompetitions: (competition: Array<string>) => dispatch(setCompetitions(competition)),
    setPractices: (practice: Array<string>) => dispatch(setPractices(practice)),
    setDistance: (distance: string) => dispatch(setDistance(distance))
  })
)(HeaderFindClub);
