// @flow

import type { YoutubePlaylist } from 'types/YoutubePlaylist';

export const FETCH_HOME_YOUTUBE_PLAYLISTS_START = 'FETCH_HOME_YOUTUBE_PLAYLISTS_START';
export const FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS = 'FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS';
export const FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE = 'FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE';

export type FETCH_HOME_YOUTUBE_PLAYLISTS_START_ACTION = {
  type: 'FETCH_HOME_YOUTUBE_PLAYLISTS_START',
  payload: { tabIdPlaylists: Array<string> }
};

export type FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE_ACTION = {
  type: 'FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE'
};

export type FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS_ACTION = {
  type: 'FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS',
  payload: {
    playlists: Array<YoutubePlaylist>
  }
};

export type Action =
  | FETCH_HOME_YOUTUBE_PLAYLISTS_START_ACTION
  | FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE_ACTION
  | FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS_ACTION;

export function fetchYoutubePlaylists(tabIdPlaylists: Array<string>): FETCH_HOME_YOUTUBE_PLAYLISTS_START_ACTION {
  return {
    type: FETCH_HOME_YOUTUBE_PLAYLISTS_START,
    payload: { tabIdPlaylists }
  };
}

export function playlistsFetched(playlists: Array<YoutubePlaylist>): FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS_ACTION {
  return {
    type: FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS,
    payload: { playlists }
  };
}

export function playlistFetchError(): FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE_ACTION {
  return {
    type: FETCH_HOME_YOUTUBE_PLAYLISTS_FAILURE
  };
}
