// @flow
// import { Teams } from 'constants/TeamMenuConstants';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';

export type EquipeSearchType = {
  featured_image: string,
  id: number,
  title: string,
}

export type TeamList = {
  type_rugby : string,
  title: string,
  link: string,
  link_results: string,
  defaut_home: boolean,
  home: boolean,
  dernieres_rencontres: Array<{
    adversaire: string,
    embleme_france: string,
    embleme_adversaire: string,
    reception: boolean,
    competition: string,
    journee: string,
    date_rencontre: string,
    lieu: string,
    france_score: number,
    adversaire_score: number,
    link: string,
    }>,
  boutique: {
    nom: string,
    link: string,
    featured_image: string,
    srcset: SourceSetType,
    },
  prochaine_rencontre: {
    adversaire: string,
    embleme_france: string,
    embleme_adversaire: string,
    reception: boolean,
    competition: string,
    journee: string,
    date_rencontre: string,
    lieu: string,
    link: string,
    billetterie: string,
    background: string,
    featured_media: Object,
  },
  joueur_du_mois: {
    nom: string,
    link: string,
    featured_image: string,
    srcset: SourceSetType,
  },
  news: Array<{
    title: string,
    date: string,
    link: string,
  }>,
  news_cat: {
    slug: string,
    title: string,
  },
  defaultImgTeam: string
};

export const nullTeamList : TeamList = {
  type_rugby : "",
  title: "",
  link: "",
  link_results: "",
  defaut_home: false,
  home: false,
  dernieres_rencontres: [],
  boutique: {
    nom: "",
    link: "",
    featured_image: "",
    featured_media: 0,
    srcset: {},
    },
  prochaine_rencontre: {
    adversaire: "",
    embleme_france: "",
    embleme_adversaire: "",
    reception: false,
    competition: "",
    journee: "",
    date_rencontre: "",
    lieu: "",
    link: '',
    billetterie: "",
    background: "",
    featured_media: {},
    },
  joueur_du_mois: {
    nom: "",
    link: "",
    featured_image: "",
    featured_media: 0,
    srcset: {},
  },
  news: [],
  news_cat: {
    slug: '',
    title: '',
  },
  bloc_results_past: {},
  bloc_prochaine_rencontre: {},
  defaultImgTeam : ""
};

export type RawTeamListType = {
    type_rugby : string,
    defaut_home: boolean,
    home: boolean,
    title: {
      raw: string,
      rendered: string
    },
    link: string,
    link_results: string,
    dernieres_rencontres: Array<{
      adversaire: string,
      embleme_france: string,
      embleme_adversaire: string,
      reception: boolean,
      competition: string,
      journee: string,
      date_rencontre: string,
      lieu: string,
      france_score: number,
      adversaire_score: number
    }>,
    boutique: {
      nom: string,
      link: string,
      featured_image: string,
      featured_media: 
      | number
      | {
        id: number,
        src: string,
        legend: string,
        alt: string,
        width: number,
        height: number,
        sizes: Object
      },
    },
    news: Array<{
      title: string,
      date: string, 
      link: string, 
    }>,
    news_cat: {
      slug: string,
      title: string,
    },
    prochaine_rencontre: {
      adversaire: string,
      embleme_france: string,
      embleme_adversaire: string,
      reception: boolean,
      competition: string,
      journee: string,
      date_rencontre: string,
      lieu: string,
      link: string,
      billetterie: string,
      background: string,
      featured_media: Object,
    },
    joueur_du_mois: {
      nom: string,
      link: string,
      featured_image: string,
      featured_media: 
      | number
      | {
        id: number,
        src: string,
        legend: string,
        alt: string,
        width: number,
        height: number,
        sizes: Object
      },
    },
    bloc_results_past: BlocContent,
    bloc_prochaine_rencontre: BlocContent,
    defaultImgTeam: string
  };
