// @flow
import type { JoueursearchType } from 'types/Joueursearch';

export const FETCH_JOUEURSEARCH_START = 'FETCH_JOUEURSEARCH_START';
export const FETCH_JOUEURSEARCH_SUCCESS = 'FETCH_JOUEURSEARCH_SUCCESS';
export const FETCH_JOUEURSEARCH_FAILURE = 'FETCH_JOUEURSEARCH_FAILURE';

export type FETCH_JOUEURSEARCH_START_ACTION = {
    type: 'FETCH_JOUEURSEARCH_START',
};

export type FETCH_JOUEURSEARCH_SUCCESS_ACTION = {
    type: 'FETCH_JOUEURSEARCH_SUCCESS',
    payload: {
        joueursearch: JoueursearchType,
    },
};

export type FETCH_JOUEURSEARCH_FAILURE_ACTION = {
    type: 'FETCH_JOUEURSEARCH_FAILURE',
};

export type Action = 
    | FETCH_JOUEURSEARCH_START_ACTION
    | FETCH_JOUEURSEARCH_SUCCESS_ACTION
    | FETCH_JOUEURSEARCH_FAILURE_ACTION;

export function fetchJoueursearch(): FETCH_JOUEURSEARCH_START_ACTION {
    return {
        type: FETCH_JOUEURSEARCH_START
    };
};

export function joueursearchFetched(datas: any): FETCH_JOUEURSEARCH_SUCCESS_ACTION {
    const { joueursearch } = datas;

    return {
        type: FETCH_JOUEURSEARCH_SUCCESS,
        payload: { joueursearch },
    };
};

export function joueursearchFetchError(error: any): FETCH_JOUEURSEARCH_FAILURE_ACTION {
    return {
        type: FETCH_JOUEURSEARCH_FAILURE,
    };
};