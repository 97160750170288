// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import AuteurApi from 'api/auteur/AuteurApi';
import ArticleApi from 'api/article/ArticleApi';
import { 
  FETCH_AUTEUR_START, 
  FETCH_ARTICLES_AUTEUR_START,
  articlesAuteurFetched,
  articlesAuteurFetchError,
  auteurFetched, 
  auteurFetchError,
} from 'actions/auteurActions';
import type { 
  FETCH_AUTEUR_START_ACTION, 
  FETCH_ARTICLES_AUTEUR_START_ACTION,
} from 'actions/auteurActions';

export default function(apiExecutor: ApiExecutorType) {
  const auteurApi = new AuteurApi(apiExecutor);
  const articleApi = new ArticleApi(apiExecutor);
  return function* auteurSaga(): GeneratorType {
    yield takeLatest(FETCH_AUTEUR_START, fetchAuteur);
    yield takeLatest(FETCH_ARTICLES_AUTEUR_START, fetchArticlesAuteur);
  };

  function* fetchAuteur(action: FETCH_AUTEUR_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const auteur = yield call(auteurApi.fetchAuteurBySlug, slug);
      yield put(auteurFetched(auteur));
    } catch (e) {
      yield put(auteurFetchError(e));
    }
  }
  function* fetchArticlesAuteur(action: FETCH_ARTICLES_AUTEUR_START_ACTION): Saga<void> {
    try {
      const { auteur, page, per_page } = action.payload;
      const { articles, articleNumber } = yield call(
        articleApi.fetchArticleByAuteur,
        page,
        per_page,
        auteur
      );
      yield put(articlesAuteurFetched(articles, articleNumber));
    } catch (e) {
      yield put(articlesAuteurFetchError(e));
    }
  }
}
