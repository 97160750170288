// @flow
import React, { PureComponent } from 'react';

import { handleTagEvent } from 'utils/tagManagerUtils';

import type { EditProfileValueType, FullUserType } from 'types/User';

export type DispatchProps = {
  displayModal: (modalObject: string, content: Object, force: boolean) => void,
  postUserPref: (action: string, value: EditProfileValueType, token: string) => void
};

export type StateProps = {
  userPref: FullUserType
};

type Props = DispatchProps &
  StateProps & {
    isFavoriteItem: boolean,
    featured_image: string,
    id: number,
    item: string,
    link?: string,
    title: string
  };

class ItemBadge extends PureComponent<Props> {
  handleAddToFavorite = (e: MouseEvent, id: number) => {
    const {
      item,
      postUserPref,
      title,
      userPref,
      userPref: { token }
    } = this.props;

    e.stopPropagation();
    e.preventDefault();

    const redirection = window.location.pathname;

    const postUserPrefAction = item === 'joueurs' ? 'joueurs-favoris-add' : `${item}-favoris-add`;

    postUserPref(postUserPrefAction, `${id}`, token);
    handleTagEvent('ajout_favoris', title, redirection, userPref)();
  };

  handleRemoveFromFavorite = (id: number) => {
    const { displayModal, item, title, userPref } = this.props;
    const redirection = window.location.pathname;
    const modalToDisplay = item === 'joueurs' ? 'MODAL_REMOVE_FAVORITE' : 'MODAL_REMOVE_FAVORITE_TEAM';

    handleTagEvent('ajout_favoris', title, redirection, userPref)();
    displayModal(modalToDisplay, { title, id }, true);
  };

  render() {
    const { isFavoriteItem, featured_image, id, title } = this.props;
    const favoriteItemClass = isFavoriteItem ? 'is-favorite' : '';

    return (
      <li className={`badge item-badge ft-700 ${favoriteItemClass}`}>
        {isFavoriteItem && (
          <div className="already-favorite">
            <i className="icon icon-check"></i>
          </div>
        )}
        {isFavoriteItem && (
          <button
            className="delete-favorite"
            onClick={() => this.handleRemoveFromFavorite(id)} // delete favorite on click
          >
            <i className="icon icon-close"></i>
          </button>
        )}
        <div>
          <div className="badge__circle" onTouchEnd={e => !isFavoriteItem && this.handleAddToFavorite(e, id)}>
            <div className="badge__inner-circle">
              <div className="badge__inner-image" style={{ backgroundImage: `url(${featured_image})` }}></div>
            </div>
          </div>
          <p className="mt-1 ft-tertiary ft-700 badge__desc">{title}</p>
        </div>
        {!isFavoriteItem && (
          <button
            className="add-favorite"
            onClick={e => this.handleAddToFavorite(e, id)} // add favorite on click
          >
            <i className="icon icon-close"></i>
          </button>
        )}
      </li>
    );
  }
}

export default ItemBadge;
