// @flow
import React from 'react';

import SelectInput from 'components/fragments/input/SelectInput';
import { capitalizeFirstLetter } from 'utils/textUtils';

type Props = {
  currentUserProfile: string,
  profile_list: string[],
  setCurrentUserProfile(value: string): void,
  title: string
};

function Switcher({ currentUserProfile, profile_list, setCurrentUserProfile, title }: Props) {
  const formated_profile_list = profile_list.map(profile => {
    return {
      label: capitalizeFirstLetter(profile),
      value: profile
    };
  });

  return (
    <>
      <h4>{title}</h4>
      <SelectInput
        placeholder="Sélectionner le profil utilisateur à tester"
        value={currentUserProfile}
        onChange={setCurrentUserProfile}
        options={formated_profile_list}
        className="mb-7"
      />
    </>
  );
}

export default Switcher;
