// @flow
import { takeLatest, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { DISPLAY_MODAL } from "actions/modalActions";
import { increaseDashboardPopInCount } from 'actions/appActions';

export default function(apiExecutor: ApiExecutorType) {

  return function* dashboardPopinSaga(): GeneratorType {
    yield takeLatest(DISPLAY_MODAL, modalDisplayed);
  };
  function* modalDisplayed(): Saga<void> {
    try {
      yield put(increaseDashboardPopInCount());
    } catch (e) {
      console.error(e);
    }
  }
}
