// @flow

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { formatFullDateString, getDayName } from 'utils/dateUtils';

function RencontreItlNextMatch(props: Object) {
  const hit = JSON.parse(props.hit);
  const {
    match: {
      params: { competition, matchSlug }
    },
    subblockdescription,
    subblockimage,
    subblocktitle
  } = props;

  const date = new Date(hit.date_rencontre);

  const url_relative = hit.url_relative;
  const splited_url_relative = url_relative.split('/');
  let match_slug = '';
  if (!!splited_url_relative.length) {
    match_slug = splited_url_relative[splited_url_relative.length - 1];
  }

  return (
    <>
      <div className="rencontre-itl--next-match">
        <div className="section-container">
          <h2 className="">{!!hit.match_ouverture ? "Match d'ouverture" : 'Prochain match'}</h2>
          <div>
            {hit.reception ? (
              <div className="team">
                <img src={hit.france_drapeau || hit.embleme_france} alt="" />
                <p>France</p>
              </div>
            ) : (
              <div className="team">
                <img src={hit.adversaire_drapeau || hit.embleme_adversaire} alt="" />
                <p>{hit.adversaire}</p>
              </div>
            )}

            <span className="versus">VS</span>

            {hit.reception ? (
              <div className="team">
                <img src={hit.adversaire_drapeau || hit.embleme_adversaire} alt="" />
                <p>{hit.adversaire}</p>
              </div>
            ) : (
              <div className="team">
                <img src={hit.france_drapeau || hit.embleme_france} alt="" />
                <p>France</p>
              </div>
            )}
          </div>

          {date instanceof Date && !isNaN(date) && (
            <div className="date">
              <p>
                {getDayName(date.getDay())} {formatFullDateString(date)}
              </p>
              <p>
                {date.getHours()}h{date.getMinutes() < 10 ? '0' : ''}
                {date.getMinutes()}
                {hit.lieu && ' — '}
                {hit.lieu}
              </p>
            </div>
          )}

          {/* {!!hit.billetterie && (
            <a
              className="btn--competitions-internationales"
              href={
                !!hit.billetterie && !!hit.billetterie.includes('billetterie.ffr.fr')
                  ? hit.billetterie
                  : 'https://billetterie.ffr.fr/'
              }
            >
              Je prends mes places
            </a>
          )}

          {!hit.billetterie && (
            <Link
              className="btn--competitions-internationales link-container-btn"
              to={`/competitions-internationales/${competition}/matchs-et-resultats`}
            >
              Voir l&apos;agenda
            </Link>
          )} */}

          {!!hit.news_prematch ? (
            <Link
              className="btn--competitions-internationales link-container-btn"
              to={`/competitions-internationales/${competition}/matchs-et-resultats/${match_slug}`}
            >
              Toutes les informations
            </Link>
          ) : (
            <Link
              className="btn--competitions-internationales link-container-btn"
              to={`/competitions-internationales/${competition}/matchs-et-resultats`}
            >
              Voir l&apos;agenda
            </Link>
          )}
        </div>
      </div>

      {!matchSlug && (
        <div className="rencontre-itl--group">
          <div className="section-container">
            <h3>{subblocktitle}</h3>

            <div className="link-and-image-container">
              <div className="link-container">
                <p>{subblockdescription}</p>

                <Link
                  className="link-container-btn"
                  to={`/competitions-internationales/${competition}/matchs-et-resultats`}
                >
                  Je découvre
                </Link>
              </div>

              {!!subblockimage && <img src={subblockimage} alt="" />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(RencontreItlNextMatch);
