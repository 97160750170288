// @flow
import React from 'react';

import type { FullUserType, EditProfileValueType } from 'types/User';

export type StateProps = {
  userPref: FullUserType
};

export type DispatchProps = {
  postUserPref: (action: string, id: EditProfileValueType, token: string) => void
};

type Props = DispatchProps &
  StateProps & {
    content: any,
    hideModal: () => void
  };

function RemoveFavoriteTeamModalContent({ content, hideModal, postUserPref, userPref }: Props) {
  return (
    <div className="modal-content box remove-favorite-player modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />

      <div className="modal__wrapper">
        <h3 className="mb-4">Êtes-vous sûr de vous ?</h3>

        <div className="modal__body modal__body--medium narrow-2 mb-3">
          <p className="mb-0">
            {'Voulez-vous vraiment retirer '}
            <strong>{content.title}</strong>
            {' de la liste de vos équipes favorites ?'}
          </p>
        </div>
        <a
          className="btn btn--primary btn--full js-confirm mb-2"
          onClick={() => {
            postUserPref('equipes-favoris-del', content.id, userPref.token);
            hideModal();
          }}
          role="button"
          tabIndex="0"
        >
          Retirer
        </a>
        <p className="modal__footer-option">
          <a onClick={hideModal} role="button" tabIndex="0">
            Le garder parmi mes équipes favorites
          </a>
        </p>
      </div>
    </div>
  );
}

export default RemoveFavoriteTeamModalContent;
