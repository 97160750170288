// @flow

import { ApolloClient } from 'apollo-client';
import { GRAPH_URL } from 'constants/api';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
};

const httpLink = new HttpLink({
	uri: GRAPH_URL,
});

export const client = new ApolloClient({
	// $FlowFixMe
	link: httpLink,
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions,
});
