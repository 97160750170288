// @flow
import async from 'async';
import type { ClubPreviewType, ClubType, SearchClub } from 'types/Club';
import { SORT_NAME, SORT_DISTANCE } from 'constants/sortConstants';
import { getDistanceFromLatLonInKm } from 'utils/distanceUtils';

export function queryClubsAndCities(
  clubList: Array<ClubPreviewType>,
  query: string
): Promise<{ cities: Array<string>, clubs: Array<ClubPreviewType> }> {
  return new Promise((resolve, reject) => {
    let cities = [];
    let clubs = [];

    async.each(
      clubList,
      (club, callback) => {
        if (clubs.length < 5 && club.searchName.toLowerCase().includes(query)) 
          clubs = [...clubs, club];
        if (cities.length < 5) {
          const city = club.adress.locality.toLowerCase();
          if (city.includes(query)) cities = [...cities.filter(c => c !== club.adress.locality), club.adress.locality];
        }
        callback();
      },
      err => {
        if (!err) resolve({ cities, clubs });
        else reject(err);
      }
    );
  });
}

function isQueryInClubName(query: string, club: ClubPreviewType) {
  return club.searchName.toLowerCase().includes(query);
}

function isQueryInClubCity(query: string, club: ClubPreviewType) {
  const city = club.adress.locality.toLowerCase();
  return city.includes(query);
}

function clubHasRugbyTypes(club: ClubPreviewType, types: Array<string>) {
  return types.every(type => club.practices.includes(type));
}

function clubHasCompetitionTypes(club: ClubPreviewType, types: Array<string>) {
  return types.every(type => club.competitions.includes(type));
}

function clubIsInDistance(club: ClubPreviewType, lat: number, long: number, distance: string) {
  return distance === '' || 
    getDistanceFromLatLonInKm(club.lat, club.long, lat, long) < parseInt(distance, 10);
}

export function getClubsNearby(
  clubs: Array<ClubPreviewType>, 
  currentClub: ClubType, 
  distance: string, 
  limit: ?number
): Array<ClubPreviewType> {
  if (clubs.length === 0) {
    return [];
  }

  const clubNearby = clubs
    .filter(c => c.lat !== currentClub.lat && c.long !== currentClub.long)
    .filter(club => clubIsInDistance(club, currentClub.lat, currentClub.long, distance) && club)
    .sort((a, b) => {
      const { lat, long } = currentClub;
      if (getDistanceFromLatLonInKm(a.lat, a.long, lat, long) < getDistanceFromLatLonInKm(b.lat, b.long, lat, long)) 
        return -1;
      if (getDistanceFromLatLonInKm(a.lat, a.long, lat, long) > getDistanceFromLatLonInKm(b.lat, b.long, lat, long)) 
        return 1;
      return 0;
    })
    .slice(0, limit || clubs.length);

  return clubNearby;
}

export function queryClubsWithFilter(
  clubList: Array<ClubPreviewType>,
  lat: number,
  long: number,
  query: string,
  rugbyTypes: Array<string>,
  competitionTypes: Array<string>,
  distance: string
): Promise<Array<ClubPreviewType>> {
  return new Promise((resolve, reject) => {
    let clubs = [];

    async.each(
      clubList,
      (club, callback) => {
        if (
          (query === '' || (isQueryInClubName(query, club) || isQueryInClubCity(query, club))) &&
          clubHasRugbyTypes(club, rugbyTypes) &&
          clubHasCompetitionTypes(club, competitionTypes) &&
          clubIsInDistance(club, lat, long, distance)
        ) {
          clubs = [...clubs, club];
        }
        callback();
      },
      err => {
        if (!err) resolve(clubs);
        else reject(err);
      }
    );
  });
}

export function sortClubs(
  clubs: ClubPreviewType[],
  sort: SORT_NAME | SORT_DISTANCE,
  currentLat: number,
  currentLong: number
): ClubPreviewType[] {
  if (sort === SORT_NAME)
    return clubs.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
  else
    return clubs.sort((a, b) => {
      const distanceA = a._geoDistance ? 
        a._geoDistance : getDistanceFromLatLonInKm(a.lat, a.long, currentLat, currentLong);
      const distanceB = b._geoDistance ? 
        b._geoDistance : getDistanceFromLatLonInKm(b.lat, b.long, currentLat, currentLong);
      return distanceA < distanceB ? -1 : distanceA > distanceB ? 1 : 0;
    });
}

export function getDisplayedClubs(
  clubs: ClubPreviewType[],
  perPage: number,
  currentPage: number
): ClubPreviewType[] {
  return clubs.slice((currentPage - 1) * perPage, currentPage * perPage);
}

function uniqueArray( arr ) {
  var a = [];
  for (var i=0, l=arr.length; i<l; i++)
    if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
      a.push(arr[i]);
  return a;
}
export function getPracticesFromClubs(clubs: Array<ClubPreviewType>): Array<string> {
  if (!Array.from) {
    /**
     * Cas des vieux IE
     */
    return clubs.reduce((practices, club) => {
      return uniqueArray([...practices, ...club.practices]);
    }, []);
  } else {
    return Array.from(
      new Set(
        clubs.reduce((practices, club) => {
          return [...practices, ...club.practices];
        }, [])
      )
    );
  }
}

export function getCompetitionsFromClubs(clubs: Array<ClubPreviewType>): Array<string> {
  if (!Array.from) {
    /**
     * Cas des vieux IE
     */
    return uniqueArray(clubs.reduce((competitions, club) => {
      return [...competitions, ...club.competitions];
    }, []));
  } else {
    return Array.from(
      new Set(
        clubs.reduce((competitions, club) => {
          return [...competitions, ...club.competitions];
        }, [])
      )
    );
  }
}

export function hitToClubPreview(hit: SearchClub): ClubPreviewType {
    return {
        id: hit.id,
        img: 'https://api-agregateur-static.ffr.fr/assets' + hit.embleme,
        name: hit.nom,
        searchName: hit.nom,
        lat: hit._geoloc ? hit._geoloc.lat : 0,
        long: hit._geoloc ? hit._geoloc.lng : 0,
        competitions: [],
        practices: hit.pratiques,
        adress: {
            complement: '',
            street: '',
            postalCode: hit['Adresse.codePostal'],
            locality: hit['Adresse.localite']
        },
        slug: hit.identifiant,
        _geoDistance: hit._geoDistance ? hit._geoDistance : 0
    };
}
