// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  FETCH_REMATCH_START,
  FETCH_REMATCH_SUCCESS,
  FETCH_REMATCH_FAILURE,
  SEND_REMATCH_FORM_START,
  SEND_REMATCH_FORM_SUCCESS,
  SEND_REMATCH_FORM_FAILURE,
} from 'actions/rematchFormActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  sendStatus: Status,
  data: Object,
};

const initialState: State = {
  sendStatus: STATUS_DEFAULT,
  data: {},
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_REMATCH_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case FETCH_REMATCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        sendStatus: STATUS_SUCCESS
      };
    case FETCH_REMATCH_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    case SEND_REMATCH_FORM_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case SEND_REMATCH_FORM_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        sendStatus: STATUS_SUCCESS
      };
    case SEND_REMATCH_FORM_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    default:
      return state;
  }
}
