// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_STAFF_START,
  FETCH_STAFF_SUCCESS,
  FETCH_STAFF_FAILURE,
  FETCH_STAFFTEAM_START,
  FETCH_STAFFTEAM_SUCCESS,
  FETCH_STAFFTEAM_FAILURE,
  RESET_FETCH_STAFF,
} from 'actions/staffActions';
import { nullStaff } from 'types/Staff';

import type { StaffType } from 'types/Staff';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  staff: StaffType,
  staffs: Array<StaffType>,
  status: Status
};

const initialState: State = {
  staff: nullStaff,
  staffs: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case RESET_FETCH_STAFF:
      return initialState;
    case FETCH_STAFF_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload.staff,
        status: STATUS_SUCCESS
      };
    case FETCH_STAFF_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_STAFFTEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_STAFFTEAM_SUCCESS:
      return {
        ...state,
        staffs: action.payload.staffs,
        status: STATUS_SUCCESS
      };
    case FETCH_STAFFTEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
