// @flow
import type { SeminaireType, RawSeminaireType } from 'types/Seminaire';
import { nullSeminaire } from 'types/Seminaire';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertSeminaireFromApi(rawSeminaire: RawSeminaireType): SeminaireType {
  if (!rawSeminaire) {
    return nullSeminaire;
  }

  const {
    title,
    id,
    content,
    slug,
    link,
    meta_title,
    meta_description,
    featured_media,
    meta: {
      duree,
      prix_base
    },
    tags_list,
    options,
    dejeuner,
    featured_video: { data },
  } = rawSeminaire;

  return mergeIntoDefault(nullSeminaire, {
    id,
    title: title.raw,
    slug,
    duree,
    options,
    dejeuner,
    content,
    prix_base,
    link,
    meta_title,
    meta_description,
    featured_media,
    tags_list,
    featured_video: {
      data,
    },
  });
}
