// @flow

export const RESET_SEASON = 'RESET_SEASON';
export const FETCH_SEASON_START = 'FETCH_SEASON_START';
export const FETCH_SEASON_SUCCESS = 'FETCH_SEASON_SUCCESS';
export const FETCH_SEASON_FAILURE = 'FETCH_SEASON_FAILURE';

export type RESET_SEASON_ACTION = {
  type: 'RESET_SEASON',
};

export type FETCH_SEASON_START_ACTION = {
  type: 'FETCH_SEASON_START',
  payload?: {
    slug: string,
  }
};

export type FETCH_SEASON_SUCCESS_ACTION = {
  type: 'FETCH_SEASON_SUCCESS',
  payload: {
    seasons: Array<Object>
  }
};

export type FETCH_SEASON_FAILURE_ACTION = {
  type: 'FETCH_SEASON_FAILURE'
};

export type Action =
  | RESET_SEASON_ACTION
  | FETCH_SEASON_START_ACTION
  | FETCH_SEASON_SUCCESS_ACTION
  | FETCH_SEASON_FAILURE_ACTION;

export const resetSeason = (): RESET_SEASON_ACTION => {
  return {
    type: RESET_SEASON,
  };
};

export const fetchSeason = (slug?: string): FETCH_SEASON_START_ACTION => {
  if (slug) {
    return {
      type: FETCH_SEASON_START,
      payload: { slug },
    };
  }
  return {
    type: FETCH_SEASON_START,
  };
};

export const seasonsFetched = (seasons: Array<Object>): FETCH_SEASON_SUCCESS_ACTION => {
  return {
    type: FETCH_SEASON_SUCCESS,
    payload : { seasons }
  };
};

export const seasonsFetchError = (error: any): FETCH_SEASON_FAILURE_ACTION => {
  return {
    type: FETCH_SEASON_FAILURE,
    payload: { error }
  };
};
