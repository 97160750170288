// @flow

import { connect } from 'react-redux';

import MyFavoriteClubs from 'components/profile/DashboardHome/MyFavoriteClubs';
import type { StateProps } from 'components/profile/DashboardHome/MyFavoriteClubs';
import type { StateType } from 'types/Actions';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref,
  })
)(MyFavoriteClubs);