// @flow
import React from 'react';

import Highlights from 'components/rencontre/Highlights';
import MatchStats from 'components/rencontre/MatchStats';
import TeamComposition from 'components/rencontre/TeamComposition';

type Props = {
  attributes: any
};

function MatchDataForPost({ attributes }: Props) {
  const matchIdData = attributes.find(attr => attr.name === 'matchid');
  const matchHitData = attributes.find(attr => attr.name === 'hit');
  if (!matchIdData || !matchIdData.value || !matchHitData || !matchHitData.value) {
    return null;
  }

  const rencontre = JSON.parse(matchHitData.value);
  const {
    faits_de_jeu,
    afficher_faits,
    afficher_stats,
    adversaire_score,
    adversaire_score_mi_temps,
    france_score,
    france_score_mi_temps,
    reception,
    adversaire,
    afficher_compos,
    joueurs,
    staffs
  } = rencontre;

  if (!afficher_faits && !afficher_stats && !afficher_compos) {
    return null;
  }

  let showStats = !!afficher_stats;
  let rencontreData = { ...rencontre };
  if (showStats) {
    showStats = rencontre.faits_de_jeu.length > 0;
    if (!showStats) {
      showStats =
        parseInt(rencontre.france_nb_cartons_jaunes, 10) > 0 ||
        parseInt(rencontre.france_nb_cartons_rouges, 10) > 0 ||
        parseInt(rencontre.france_nb_drops, 10) > 0 ||
        parseInt(rencontre.france_nb_essais, 10) > 0 ||
        parseInt(rencontre.france_nb_penalites, 10) > 0 ||
        parseInt(rencontre.france_nb_transformations, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_cartons_jaunes, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_cartons_rouges, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_drops, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_essais, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_penalites, 10) > 0 ||
        parseInt(rencontre.adversaire_nb_transformations, 10) > 0;
    }
  }

  rencontreData = {
    ...rencontreData,
    meta: {
      ...rencontre
    }
  };

  return (
    <div className="match-data-for-post">
      {showStats && (
        <MatchStats reception={reception} equipeNom={'France'} adversaire={adversaire} rencontreData={rencontreData} />
      )}

      {!!afficher_compos && (
        <div className="team-composition">
          <TeamComposition joueurs={joueurs} staffs={staffs} faits={faits_de_jeu} />{' '}
        </div>
      )}

      {rencontre.afficher_faits === '1' && (
        <div className="single-match__highlights">
          <h2 className="divided mb-7">
            <span className="first">Aperçu</span>
            <span className="second"> du match</span>
          </h2>
          <Highlights
            faitsDeJeu={faits_de_jeu}
            francePlaysAtHome={reception}
            france_score_mi_temps={france_score_mi_temps}
            france_score={france_score}
            adversaire_score={adversaire_score}
            adversaire_score_mi_temps={adversaire_score_mi_temps}
            rencontre_live={true}
          />
        </div>
      )}
    </div>
  );
}

export default MatchDataForPost;
