// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  RESET_FETCH_JOUEUR,
  FETCH_JOUEUR_START,
  FETCH_JOUEUR_SUCCESS,
  FETCH_JOUEUR_FAILURE,
  FETCH_JOUEUR_AGREGATEUR_START,
  FETCH_JOUEUR_AGREGATEUR_SUCCESS,
  FETCH_JOUEUR_AGREGATEUR_FAILURE,
  FETCH_JOUEURTEAM_START,
  FETCH_JOUEURTEAM_SUCCESS,
  FETCH_JOUEURTEAM_FAILURE
} from 'actions/joueurActions';
import { nullJoueur, nullJoueurAgregateur } from 'types/Joueur';
import type { JoueurAgregateurType, JoueurType } from 'types/Joueur';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  joueur: JoueurType,
  joueurAgregateur: JoueurAgregateurType,
  joueurs: Array<JoueurType>,
  status: Status,
  statusJoueurAgregateur: Status
};

const initialState: State = {
  joueur: nullJoueur,
  joueurAgregateur: nullJoueurAgregateur,
  joueurs: [],
  status: STATUS_LOADING,
  statusJoueurAgregateur: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case RESET_FETCH_JOUEUR:
      return initialState;
    case FETCH_JOUEUR_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_JOUEUR_SUCCESS:
      return {
        ...state,
        joueur: action.payload.joueur,
        status: STATUS_SUCCESS
      };
    case FETCH_JOUEUR_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_JOUEUR_AGREGATEUR_START:
      return {
        ...state,
        statusJoueurAgregateur: STATUS_LOADING
      };
    case FETCH_JOUEUR_AGREGATEUR_SUCCESS:
      return {
        ...state,
        joueurAgregateur: action.payload.joueurAgregateur,
        statusJoueurAgregateur: STATUS_SUCCESS
      };
    case FETCH_JOUEUR_AGREGATEUR_FAILURE:
      return {
        ...state,
        statusJoueurAgregateur: STATUS_FAILURE
      };
    case FETCH_JOUEURTEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_JOUEURTEAM_SUCCESS:
      return {
        ...state,
        joueurs: action.payload.joueurs,
        status: STATUS_SUCCESS
      };
    case FETCH_JOUEURTEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
