// @flow

import type { YoutubePlaylist } from 'types/YoutubePlaylist';

export const FETCH_YOUTUBE_PLAYLISTS_LINKED_START = 'FETCH_YOUTUBE_PLAYLISTS_LINKED_START';
export const FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS = 'FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS';
export const FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE = 'FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE';

export type FETCH_YOUTUBE_PLAYLISTS_LINKED_START_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_LINKED_START',
  payload: { idChannel: string, idPlaylist: string }
};

export type FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE'
};

export type FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS',
  payload: {
    playlists: Array<YoutubePlaylist>
  }
};

export type Action =
  | FETCH_YOUTUBE_PLAYLISTS_LINKED_START_ACTION
  | FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE_ACTION
  | FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS_ACTION;

export function fetchYoutubePlaylistsLinked(
  idChannel: string,
  idPlaylist: string
): FETCH_YOUTUBE_PLAYLISTS_LINKED_START_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_LINKED_START,
    payload: { idChannel, idPlaylist }
  };
}

export function playlistsLinkedFetched(
  playlists: Array<YoutubePlaylist>
): FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_LINKED_SUCCESS,
    payload: { playlists }
  };
}

export function playlistsLinkedFetchError(error: any): FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_LINKED_FAILURE
  };
}
