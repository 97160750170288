// @flow

import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import { convertPlaylistsChannelFromApi, verifPlaylistsChannelFromApi } from 'services/Channels';

function PlaylistsChannelApi(executor: ApiExecutorType) {
  return { fetchPlaylistsChannel, fetchPlaylistsChannelInfo };

  function fetchPlaylistsChannel(idChannel: string, maxResults: number, nextPageToken?: ?string) {
    let request = `/playlists?key=${YOUTUBE_ACCESS_TOKEN}&maxResults=${maxResults}&part=contentDetails,snippet&channelId=${idChannel}`;
    if (nextPageToken) request = request.concat(`&pageToken=${nextPageToken}`);
    return executor.get(request).then(convertPlaylistsChannelFromApi);
  }

  function fetchPlaylistsChannelInfo(idChannel: string, maxResults: number, nextPageToken?: ?string) {
    let request = `/playlists?key=${YOUTUBE_ACCESS_TOKEN}&maxResults=${maxResults}&part=contentDetails,snippet&channelId=${idChannel}`;
    if (nextPageToken) request = request.concat(`&pageToken=${nextPageToken}`);
    return executor.get(request).then(verifPlaylistsChannelFromApi);
  }
}

export default PlaylistsChannelApi;
