// @flow

import type { YoutubePlaylist } from 'types/YoutubePlaylist';

export const FETCH_YOUTUBE_PLAYLIST_START = 'FETCH_YOUTUBE_PLAYLIST_START';
export const FETCH_YOUTUBE_PLAYLIST_SUCCESS = 'FETCH_YOUTUBE_PLAYLIST_SUCCESS';
export const FETCH_YOUTUBE_PLAYLIST_FAILURE = 'FETCH_YOUTUBE_PLAYLIST_FAILURE';

export type FETCH_YOUTUBE_PLAYLIST_START_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLIST_START',
  payload: { idPlaylist: string }
};

export type FETCH_YOUTUBE_PLAYLIST_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLIST_FAILURE'
};

export type FETCH_YOUTUBE_PLAYLIST_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLIST_SUCCESS',
  payload: {
    playlist: YoutubePlaylist
  }
};

export type Action =
  | FETCH_YOUTUBE_PLAYLIST_START_ACTION
  | FETCH_YOUTUBE_PLAYLIST_FAILURE_ACTION
  | FETCH_YOUTUBE_PLAYLIST_SUCCESS_ACTION;

export function fetchYoutubePlaylist(idPlaylist: string): FETCH_YOUTUBE_PLAYLIST_START_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLIST_START,
    payload: { idPlaylist }
  };
}

export function playlistFetched(
  playlist: YoutubePlaylist
): FETCH_YOUTUBE_PLAYLIST_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLIST_SUCCESS,
    payload: { playlist }
  };
}

export function playlistFetchError(): FETCH_YOUTUBE_PLAYLIST_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLIST_FAILURE
  };
}
