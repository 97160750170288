/* eslint-disable no-extra-boolean-cast */
// @flow

import React, { Component } from 'react';
import { getRencontresDateFormat, doesLocalTeamWon, doesChallengerTeamWon } from 'utils/rencontreUtils';
import { shouldUsePlaceholder } from 'utils/assetsUtils';
import { dynamicClassName } from 'utils/dynamicClassName';

import './SingleMatchCard.css';

type Props = {
  rencontre: any,
  equipeNom: string,
  simple: boolean
};

export default class SingleMatchCard extends Component<Props> {
  render() {
    const { rencontre, equipeNom, simple } = this.props;

    const {
      adversaire,
      adversaire_score,
      competition_internationale_link,
      competition_internationale_link_is_blank,
      date_rencontre,
      embleme_adversaire,
      embleme_france,
      france_score,
      reception,
      rencontre_live,
      rencontre_terminee,
      billetterie,
      france_drapeau,
      france_logo,
      adversaire_logo,
      adversaire_drapeau,
      broadcaster_logo
    } = rencontre;

    const cardClassName = dynamicClassName('single-match-card');
    rencontre_live === true && rencontre_terminee === false && cardClassName.add('is-live');
    rencontre_terminee === true && cardClassName.add('is-over');

    const formatedRencontreDate = getRencontresDateFormat(date_rencontre);
    const minutes = new Date(date_rencontre).getMinutes();
    const formatedRencontreTime = `${new Date(date_rencontre).getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`;

    let blocTitle = `A venir - ${!!formatedRencontreDate ? formatedRencontreDate : ''} à ${formatedRencontreTime}`;
    let logoTitle = '';
    if (rencontre_live === true && rencontre_terminee === false) {
      blocTitle = `En direct sur `;
      if (!!broadcaster_logo) {
        logoTitle = broadcaster_logo;
      }
    } else if (rencontre_terminee === true) {
      blocTitle = `Terminé - ${!!formatedRencontreDate ? formatedRencontreDate : ''} à ${formatedRencontreTime}`;
    }

    let logoFrance =
      embleme_france || france_drapeau || france_logo ? embleme_france || france_drapeau || france_logo : '';
    let logoAdversaire =
      embleme_adversaire || adversaire_drapeau || adversaire_logo
        ? embleme_adversaire || adversaire_drapeau || adversaire_logo
        : '';
    const francePlaysAtHome = reception === 1 || reception === '1' || !!reception;

    return (
      <div
        key={`NextMatch ${
          francePlaysAtHome ? equipeNom + '/' + adversaire : adversaire + '/' + equipeNom
        } ${date_rencontre}`}
        className={cardClassName.build()}
      >
        <div className="block-competition__head ft-h6 ft-up">
          {blocTitle}
          {logoTitle && <img src={logoTitle} alt="Télévision" />}
        </div>

        {!!competition_internationale_link && !simple ? (
          <a
            className="match box mb-6"
            href={competition_internationale_link}
            title={`Voir les informations du match ${
              francePlaysAtHome ? 'France/' + adversaire : adversaire + '/France'
            }`}
            target={competition_internationale_link_is_blank === 'true' ? '_blank' : '_self'} rel="noreferrer"
          >
            <div className="match__data">
              <div className="match__club">
                <p className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                  {francePlaysAtHome ? 'France' : adversaire}
                  <img
                    src={shouldUsePlaceholder(
                      { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                      true
                    )}
                    alt={`Logo ${francePlaysAtHome ? 'France' : adversaire}`}
                  />
                </p>
                {!!rencontre_terminee || (!rencontre_terminee && !!rencontre_live) ? (
                  <p className="match__score">
                    <span className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                      {francePlaysAtHome ? france_score : adversaire_score}
                    </span>
                  </p>
                ) : (
                  <p className="match__score null">
                    <span>-</span>
                  </p>
                )}
              </div>

              <div className="match__info">
                <p className="match__score">
                  <span className="match__colon"> - </span>
                </p>
              </div>

              <div className="match__club">
                <p className={doesChallengerTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                  <img
                    src={shouldUsePlaceholder(
                      { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                      false
                    )}
                    alt={`Logo ${francePlaysAtHome ? adversaire : 'France'}`}
                  />
                  {francePlaysAtHome ? adversaire : 'France'}
                </p>
                {!!rencontre_terminee || (!rencontre_terminee && !!rencontre_live) ? (
                  <p className="match__score">
                    <span className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                      {francePlaysAtHome ? adversaire_score : france_score}
                    </span>
                  </p>
                ) : (
                  <p className="match__score null">
                    <span>-</span>
                  </p>
                )}
              </div>
            </div>
          </a>
        ) : (
          <div
            className="match box mb-6"
            to={competition_internationale_link}
            title={`Voir les informations du match ${
              francePlaysAtHome ? 'France/' + adversaire : adversaire + '/France'
            }`}
          >
            <div className="match__data">
              <div className="match__club">
                <p className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                  {francePlaysAtHome ? 'France' : adversaire}
                  <img
                    src={shouldUsePlaceholder(
                      { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                      true
                    )}
                    alt={`Logo ${francePlaysAtHome ? 'France' : adversaire}`}
                  />
                </p>
                {!!rencontre_terminee || (!rencontre_terminee && !!rencontre_live) ? (
                  <p className="match__score">
                    <span className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                      {francePlaysAtHome ? france_score : adversaire_score}
                    </span>
                  </p>
                ) : (
                  <p className="match__score null">-</p>
                )}
              </div>

              <div className="match__info">
                {(!!rencontre_terminee || (!rencontre_terminee && !!rencontre_live)) && (
                  <span className="match__colon"> - </span>
                )}

                {!rencontre_terminee && !rencontre_live && (
                  <div>
                    <span>{formatedRencontreTime}</span>
                  </div>
                )}
              </div>

              <div className="match__club">
                <p className={doesChallengerTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                  <img
                    src={shouldUsePlaceholder(
                      { embleme_adversaire: logoAdversaire, embleme_france: logoFrance, reception },
                      false
                    )}
                    alt={`Logo ${francePlaysAtHome ? adversaire : 'France'}`}
                  />
                  {francePlaysAtHome ? adversaire : 'France'}
                </p>
                {!!rencontre_terminee || (!rencontre_terminee && !!rencontre_live) ? (
                  <p className="match__score">
                    <span className={doesLocalTeamWon({ adversaire_score, france_score }, francePlaysAtHome)}>
                      {francePlaysAtHome ? adversaire_score : france_score}
                    </span>
                  </p>
                ) : (
                  <p className="match__score null">-</p>
                )}
              </div>
            </div>

            {!rencontre_terminee && !!billetterie && !!billetterie.includes('billetterie.ffr.fr') && (
              <a className="ticket-link" href={billetterie} target="_blank" rel="noopener noreferrer">
                Billets
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
}
