//@flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Loading from 'components/fragments/Loading';
import { ADMIN_USERS } from 'components/profile/DashboardHome';
import { STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';

import { getPublicAssets } from 'utils/assetsUtils';
import { ffrUserName } from 'utils/nameUtils';

import type { Status } from 'types/Status';
import type { FullUserType, UserFidType } from 'types/User';
import type { MenuElementType } from 'types/MenuElement';

import ForYou from './ForYou';
import General from './General';
import QuickAccess from './QuickAccess';

import './MenuDashboard.css';

export type StateProps = {
  userFid: UserFidType,
  userFidStatus: Status,
  userPref: FullUserType,
  userPrefStatus: Status,
  menuElements: MenuElementType[]
};

type Props = {
  currentUserProfile: string,
  match?: Object,
  onCloseMenu?: Function,
  logout_url: string
} & StateProps;

class MenuDashboard extends Component<Props> {
  /* renderQuickAccess = () => {
    const { userPref, menuElements } = this.props;
    if (menuElements) {
      return menuElements.map(element => {
        const { object_id, url, title } = element;
        return (
          <a
            key={object_id}
            className="link-competition link-competition--card link-competition--access box"
            href={url}
            onClick={handleTagEvent(
              'espace perso',
              'clic_lien_acces_rapide',
              title,
              userPref
            )}
            target="_blank"
            rel="noopener noreferrer"
            title={title}
          >
            <div>
              <span className="link-competition__title ft-h4">
                {title}
                <i className="icon icon-external" />
              </span>
            </div>
          </a>
        );
      });
    }
    return null;
  }; */

  onCloseMenu = () => {
    if (this.props.onCloseMenu) {
      this.props.onCloseMenu();
    }
  };

  render() {
    const { onCloseMenu } = this;
    const {
      currentUserProfile,
      userPref,
      userFid: { fid },
      userFidStatus,
      userPref: {
        avatar,
        licence,
        my_boutique_profile_list,
        profil: { prenom, email },
        ligue: { website, nom },
        club: { website: clubWebsite, nom: clubNom }
      },
      userPrefStatus,
      logout_url
    } = this.props;

    let {
      userPref: { is_player, is_dirigeant, is_arbitre }
    } = this.props;

    const isAvatar = !avatar ? getPublicAssets('/img/placeholder/placeholder-profil.png') : avatar;

    // profile to use to display menus
    if (ADMIN_USERS.includes(email) && !!currentUserProfile) {
      if (currentUserProfile === 'joueur') {
        is_player = true;
      } else if (currentUserProfile === 'dirigeant') {
        is_dirigeant = true;
      } else if (currentUserProfile === 'arbitre') {
        is_arbitre = true;
      } else if (currentUserProfile === 'defaut') {
        is_player = false;
        is_dirigeant = false;
        is_arbitre = false;
      }
    }

    return (
      <div className="dashboard-menu box">
        <div className={'dashboard-menu__back'}>
          <Link to="/" title="Retour au site">
            <span className="ft-h5">
              <i className="icon is-inline icon-chevron-left" /> Retour au site
            </span>
          </Link>
        </div>
        <div className="dashboard-menu__header">
          <Link to="/tableau-de-bord" title="Mon profil">
            <img src={getPublicAssets('/img/logo/new_logo_ffr.svg')} alt="Logo FFR" />
            <h1 className="ft-up ft-h5">Espace perso</h1>
          </Link>
        </div>

        {userPrefStatus === STATUS_LOADING ? (
          <Loading isWhite />
        ) : (
          <>
            <div className="dashboard-menu__profil">
              <i className="icon icon-close menu__close js-showMenu" role="button" tabIndex={0} onClick={onCloseMenu} />
              <div>
                <img src={isAvatar} alt="Profil utilisateur" />
              </div>
              <p className="ft-primary mt-2 message_bienvenue degrade_fid">{`Bienvenue`}</p>
              <p className="ft-primary">{`${ffrUserName(prenom)}`}</p>
              {userPref.email.includes('apsulis.io') && !!fid && fid.data && (
                <span className="fid_statut">{(fid && fid.data.statut_licence) || (fid && `Débutant`)}</span>
              )}
            </div>

            <ForYou
              hasFidelite={
                userPref.email.includes('apsulis.io') &&
                userFidStatus === STATUS_SUCCESS &&
                !!fid &&
                fid.data &&
                fid.data.status &&
                fid.data.status === 'enabled'
              }
              hasClub={!!clubNom}
              hasLicence={!!licence}
              hasLigue={!!nom}
              isClubOfficial={is_dirigeant}
              isPlayer={is_player}
              isReferee={is_arbitre}
              onCloseMenu={onCloseMenu}
            />

            <General
              logoutUrl={logout_url}
              onCloseMenu={onCloseMenu}
              hasMyBoutiquePrivatePage={!!my_boutique_profile_list.length}
            />

            <QuickAccess
              club={clubWebsite}
              hasLicence={!!licence}
              ligue={website}
              isClubOfficial={is_dirigeant}
              isReferee={is_arbitre}
              userPref={userPref}
            />
          </>
        )}
      </div>
    );
  }
}

export default MenuDashboard;
