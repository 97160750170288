// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import type { ArticleType } from 'types/Article';
import Label from 'components/fragments/text/Label';
import { nullLoadingArticle } from 'types/Article';
import type { FullUserType } from 'types/User';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { Link } from 'react-router-dom';
import { getDateFromString, formatFullDate } from 'utils/dateUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

type Props = {
  content: ArticleType,
  small?: boolean,
  containerStyle?: string,
  userPref?: FullUserType,
};

class BillBoardCard extends PureComponent<Props> {
  props: Props

  static defaultProps = {
    content: nullLoadingArticle,
    small: false,
    containerStyle: '',
  };

  render() {
    const {
      content,
      small,
      containerStyle,
      userPref
    } = this.props;

    const classNamesCardContainer = dynamicClassName('box card card--billboard');
    const date = formatFullDate(getDateFromString(content.date));
    small && classNamesCardContainer.add('is-small-desktop');
    containerStyle && classNamesCardContainer.add(containerStyle);

    return (
      <Link
        title={`Lire la suite de l'article : ${content.title}`}
        to={`/actualites/${content.label.slug}/${content.slug}`}
        className={classNamesCardContainer.build()}
        onClick={handleTagEvent(
          'module_actualite',
          content.title,
          '',
          userPref
        )}>
        <BackgroundImage
          className="card__thumbnail img-blue"
          illustration={content.illustration}
          srcset={content.srcset}
        />
        <div className="card__body">
          <p className="card__date ft-h6">{date}</p>
          <h2 className="card__title">{content.title}</h2>
        </div>
        <div className="card__footer">
          <span className="btn btn--cheer">
            <i className="icon icon-chevron-right link-icon is-inline" />
            <span>Lire la suite</span>
          </span>
        </div>
        <div className="card__type">
          <Label label={content.label.name} />
        </div>
      </Link>
    );
  }
}

export default BillBoardCard;
