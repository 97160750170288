// @flow
import React from 'react';
import * as DOMPurify from 'dompurify';

import type { BlockType } from 'types/Directus';

type Props = {
  block: BlockType
};

function DirectusBlocks({ block }: Props) {
  if (block.type === 'paragraph') {
    return <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(block.data.text) }} />;
  }

  return null;
}

export default DirectusBlocks;
