// @flow

export type RawYoutubePlaylistsChannel = {
  kind: string,
  etag: string,
  nextPageToken: string,
  prevPageToken: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: [
    {
      kind: string,
      etag: string,
      id: string,
      snippet: {
        publishedAt: string,
        channelId: string,
        title: string,
        description: string,
        thumbnails: {
          default: {
            url: string,
            width: number,
            height: number
          },
          medium: {
            url: string,
            width: number,
            height: number
          },
          high: {
            url: string,
            width: number,
            height: number
          },
          standard: {
            url: string,
            width: number,
            height: number
          },
          maxres: {
            url: string,
            width: number,
            height: number
          }
        },
        channelTitle: string,
        localized: {
          title: string,
          description: string
        }
      },
      contentDetails: {
        itemCount: number
      }
    }
  ]
};

export const nullPlaylistsChannel = {
  kind: '',
  etag: '',
  pageInfo: { totalResults: 0, resultsPerPage: 0 },
  nextPageToken: '',
  prevPageToken: '',
  totalResults: 0,
  items: [
    {
      id: '',
      publishedAt: '',
      channelId: '',
      title: '',
      description: '',
      itemCount: 0,
      image: '',
      url: '',
      thumbnails: {
        default: {
          url: '',
          width: 0,
          height: 0
        },
        medium: {
          url: '',
          width: 0,
          height: 0
        },
        high: {
          url: '',
          width: 0,
          height: 0
        }
      }
    }
  ]
};

export type YoutubePlaylistChannel = {
  etag: string,
  nextPageToken: string,
  prevPageToken: string,
  totalResults: number,
  items: Array<{
    id: string,
    image: string,
    url: string,
    publishedAt: string,
    channelId: string,
    title: string,
    description: string,
    itemCount: number,
    thumbnails: {
      default: {
        url: string,
        width: number,
        height: number
      },
      medium: {
        url: string,
        width: number,
        height: number
      },
      high: {
        url: string,
        width: number,
        height: number
      }
    }
  }>
};

export type YoutubePlaylistSearchId = {
  kind: string,
  etag: string,
  nextPageToken: string,
  regionCode: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: [
    {
      kind: string,
      etag: string,
      id: {
        kind: string,
        playlistId: string
      }
    }
  ]
};
