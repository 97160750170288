// @flow
import type { PrivateAccountMyBoutiqueType } from 'types/PrivateAccountMyBoutiqueType';

export const FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START = 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START';
export const FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE = 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE';
export const FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS = 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS';

export type FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START'
};

export type FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE'
};

export type FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS',
  payload: {
    myBoutique: PrivateAccountMyBoutiqueType[]
  }
};

export type Action =
  | FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START_ACTION
  | FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE_ACTION
  | FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS_ACTION;

export function fetchPrivateAccountMyBoutique(): FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START
  };
}

export function privateAccountMyBoutiqueFetched(
  myBoutique: PrivateAccountMyBoutiqueType[]
): FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS,
    payload: { myBoutique }
  };
}

export function privateAccountMyBoutiqueFetchError(error: any): FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE
  };
}
