export const SORT_NAME = 'sort_name';
export const SORT_DATE = 'sort_date';
export const SORT_DISTANCE = 'sort_distance';

export const SORT_COMPETITIONS_BASE = [
  {
    index: 1,
    value: "Seniors"
  },
  {
    index: 5,
    value: "Juniors"
  },
  {
    index: 9,
    value: "Cadets"
  },
];

export const SORT_COMPETITIONS_EXTENSION = [
  {
    index: 1,
    value: "féminines"
  },
  {
    index: 2,
    value: "territoriales"
  },
];
