// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { FETCH_PAGE_LIST_SUCCESS } from 'actions/pageActions';

import type { ActionType } from 'types/Actions';
import type { HomeCardType, ListHomeCardType } from 'types/Settings';
import type { CardType } from 'types/PageList';

export type State = {
  liste_cards_home: ListHomeCardType[],
  liste_cards: CardType[],
  gerer_club: HomeCardType,
  bulletin_officiel: HomeCardType,
  titre_liste_cards: string,
  private_gestion_activite: ListHomeCardType[],
};

const initialState: State = {
  liste_cards_home: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: ''
    }
  ],
  gerer_club: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    parent_slugs: {},
    title: '',
    description: '',
    featured_image: {},
    texte_cta: ''
  },
  bulletin_officiel: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    parent_slugs: {},
    title: '',
    description: '',
    featured_image: {},
    texte_cta: ''
  },
  titre_liste_cards: '',
  private_gestion_activite: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ]
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        liste_cards_home: action.payload.settings.liste_cards,
        gerer_club: action.payload.settings.gerer_club,
        bulletin_officiel: action.payload.settings.bulletin_officiel,
        titre_liste_cards: action.payload.settings.titre_liste_cards,
        private_gestion_activite: action.payload.settings.private_gestion_activite,
      };
    case FETCH_PAGE_LIST_SUCCESS:
      return {
        ...state,
        liste_cards: action.payload.pageList.liste_enfants,
      };
    default:
      return state;
  }
}
