// @flow

import { connect } from 'react-redux';
import { postUserFid } from 'actions/userActions';

import FidInscription from 'components/profile/Fidelite/fidInscription';
import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/profile/Fidelite/fidInscription';
import type { EditProfileValueType } from 'types/User';

import { withRouter } from 'react-router-dom';

export default withRouter(
  connect(
    (state: StateType): StateProps => ({
      fid_page: state.settingsState.fid_page,
      userFid: state.userState.userFid,
      userPref: state.userState.userPref
    }),
    (dispatch: DispatchType): DispatchProps => ({
      postUserFid: (action: string, value: EditProfileValueType, token: string, redirect?: string) =>
        dispatch(postUserFid(action, value, token, redirect))
    })
  )(FidInscription)
);
