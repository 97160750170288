// https://stackoverflow.com/a/48269720
let Components = {};

// ok espace perso page des favoris - pp ok
Components[
  'MODAL_ADD_A_FAVORITE_PLAYER_SEARCH_PANEL'
] = require('./AddAFavoritePlayerSearchPanelModalContent.js').default;
// ok espace perso page des favoris - pp ok
Components['MODAL_ADD_A_FAVORITE_TEAM_MODAL_CONTENT'] = require('./AddAFavoriteTeamSearchPanelModalContent.js').default;
// ok page d'un joueur - pp ok
Components[
  'MODAL_ADD_FAVORITE_PLAYER'
] = require('../../../../containers/fragments/modal/modalContents/AddFavoritePlayerModalContentContainer.js').default;
// commenté dans NewsletteOptinModal et dans NewsletterSubscriber
Components['MODAL_CUSTOMIZE_NEWSLETTER'] = require('./CustomizeNewsletterModalContent.js').default;
// ! pas pu tester
Components['MODAL_CENTENAIRE_FORM_DOWNLOAD'] = require('./CentenaireFormDownloadModalContent.js').default;
// ok à partir du bouton contribuer sur les pages /histoires-de-rugby CentenaireSharePicture - pp ok
Components['MODAL_CENTENAIRE_FORM_EMAIL'] = require('./CentenaireFormEmailModalContent.js').default;
// ! pas pu tester
Components[
  'MODAL_CENTENAIRE_FORM_ERROR'
] = require('../../../../containers/fragments/modal/modalContents/CentenaireFormErrorModalContentContainer.js').default;
// ! pas pu tester
Components[
  'MODAL_CENTENAIRE_FORM_OK'
] = require('../../../../containers/fragments/modal/modalContents/CentenaireFormOkModalContentContainer.js').default;
// ok à partir du bouton contribuer sur les pages /histoires-de-rugby CentenaireSharePicture - pp ok
Components['MODAL_CENTENAIRE_FORM_UPLOAD'] = require('./CentenaireFormUploadModalContent.js').default;
Components['MODAL_CONTACT_FORM_FAILED'] = require('./ContactFormFailedModalContent.js').default;
Components['MODAL_CONTACT_FORM_SUCCESS'] = require('./ContactFormSuccessModalContent.js').default;
// ok dans App -> accès à l'espace perso proposée lorsqu'on est connecté --> ! pp : pas à chaque fois
Components['MODAL_DASHBOARD_ACCESS_CONNECTED_IMG'] = require('./DashboardAccessConnectedImgModalContent.js').default;
// ok dans App -> proposition de création ou de connexion à l'espace perso proposée - pp ok
Components[
  'MODAL_DASHBOARD_ACCESS_NOT_CONNECTED_IMG'
] = require('./DashboardAccessNotConnectedImgModalContent.js').default;
// ok espace perso page des favoris pp - ok
Components[
  'MODAL_FAVORITE_BADGE_REDIRECTION_ON_MOBILE'
] = require('./FavoriteBadgeRedirectionOnMobileModalContent.js').default;
Components[
  'MODAL_FID_NEW_BADGE'
] = require('../../../../containers/fragments/modal/modalContents/FidNewBadgeModalContainer.js').default;
// ok espace perso page des favoris pp - ok
Components['MODAL_FORM_SUCCESS_SIMPLE'] = require('./FormSuccessSimpleModalContent.js').default;
// peut pas tester car pas de match : /entreprises/offres-entreprises-et-stages-sportifs/nos-offres-loges-salons-seminaires/hospitalites/offre-du-moment/dans-les-coulisses
Components['MODAL_LOGE_FORM'] = require('./LogeFormModalContent.js').default;
// peut pas tester à cause de point précédente
Components[
  'MODAL_LOGE_SUCCESS'
] = require('../../../../containers/fragments/modal/modalContents/LogeSuccessModalContentContainer.js').default;
// ok à l'enregistrement des modifs dans les newsletter de l'espace perso - pp ok
Components['MODAL_SUCCESS'] = require('./ModalSuccessModalContent.js').default;
// ok espace perso page - dashboard - mynewsletterfeed Gérer mes préférences - pp ok
Components['MODAL_NEWS_SUBSCRIPTION'] = require('./NewsSubscriptionModalContent.js').default;
//ok dnas newslettersubscriptionsaga -> a forcé le failed en ajoutant le retour de l'api à la main - peut pas tester en pp
Components['MODAL_NEWSLETTER_FAILED'] = require('./NewsletterFailedModalContent.js').default;
// a été desactivé de App -> pour mettre celle de l'espace perso à la place probablement
Components['MODAL_NEWSLETTER_OPTIN'] = require('./NewsletterOptinModalContent.js').default;
// ok manque acceptation cgu au clic sur inscription sur le footer dans NEwsletterSubscriber - pp ok
Components[
  'MODAL_NEWSLETTER_MISSING_OPT'
] = require('../../../../containers/fragments/modal/modalContents/NewsletterMissingOptModalContentContainer.js').default;
// ok au clic inscription sur le footer dans NEwsletterSubscriber sans erreur - pp peut pas tester
Components[
  'MODAL_NEWSLETTER_SUCCESS'
] = require('../../../../containers/fragments/modal/modalContents/NewsletterSuccessModalContentContainer.js').default;
// ok espace perso page des favoris + page d'un joueur - pp ok
Components[
  'MODAL_REMOVE_FAVORITE'
] = require('../../../../containers/fragments/modal/modalContents/RemoveFavoriteModalContentContainer.js').default;
// ok espace perso page des favoris - pp ok
Components[
  'MODAL_REMOVE_FAVORITE_TEAM'
] = require('../../../../containers/fragments/modal/modalContents/RemoveFavoriteTeamModalContentContainer.js').default;
Components['MODAL_SEMINAIRE_FORM'] = require('./SeminaireFormModalContent.js').default;
// commenté dans eventlistmatchelement
// ! pas pu tester ici : CentenaireFormOkModalContent car n'a pas pu l'atteindre
Components[
  'MODAL_TICKETING'
] = require('../../../../containers/fragments/modal/modalContents/TicketingModalContentContainer.js').default;
// ! apparemment utilisé nulle part
Components[
  'MODAL_TICKETING_REMOVE'
] = require('../../../../containers/fragments/modal/modalContents/TicketingRemoveModalContentContainer.js').default;
// ! pas pu tester ici : CentenaireFormOkModalContent car n'a pas pu l'atteindre
Components[
  'MODAL_TICKETING_SUCCESS'
] = require('../../../../containers/fragments/modal/modalContents/TicketingSuccessModalContentContainer.js').default;
// ! utilisé dans rencontre mais puisque MODAL_TICKETING_REMOVE est appelée nulle part on ne devrait pas pouvoir l'afficher
Components['MODAL_TICKETING_REMOVE_SUCCESS'] = require('./TicketingRemoveSuccessModalContent.js').default;
// commenté dans rencontre donc non utilisée
// mais aussi dans l'espace perso dans ComposeNEwsLetter mais ce composant est commenté
// aussi dans Newsletter sur le bouton me desinscrire mais celui-ci est commenté
Components[
  'MODAL_UNSUBSCRIBE_NEWSLETTER'
] = require('../../../../containers/fragments/modal/modalContents/UnsubscribeNewsletterModalContentContainer.js').default;
// puisqu'on ne peut pas se désinscrire on ne peut pas afficher cette modal
Components['MODAL_UNSUBSCRIBE_NEWSLETTER_DONE'] = require('./UnsubscribeNewsletterDoneModalContent.js').default;

export default Components;
