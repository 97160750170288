// @flow
import { connect } from 'react-redux';

import CardClub from 'components/fragments/card/CardClub';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/card/CardClub';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  })
)(CardClub);
