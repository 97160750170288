// @flow

import {
  FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START,
  FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS,
  FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE
} from 'actions/privateAccountMyBoutiqueActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { PrivateAccountMyBoutiqueType } from 'types/PrivateAccountMyBoutiqueType';
import type { Status } from 'types/Status';

export type State = {
  myBoutique: PrivateAccountMyBoutiqueType[],
  status: Status
};

const initialState: State = {
  myBoutique: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_SUCCESS:
      return {
        ...state,
        myBoutique: action.payload.myBoutique,
        status: STATUS_SUCCESS
      };
    case FETCH_PRIVATE_ACCOUNT_MY_BOUTIQUE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
