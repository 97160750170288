// @flow
import FooterTop from 'components/footer/FooterTop';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/footer/FooterTop';

export default connect(
  (state: StateType): StateProps => ({
    titleLigue: state.settingsState.title,
    liens_rs: state.settingsState.liens_rs,
    userPref: state.userState.userPref
  })
)(FooterTop);
