// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import FormApi from 'api/contact/FormApi';
import { displayModal } from 'actions/modalActions';
import {
  FETCH_CONTACT_FORM_START,
  FETCH_ALL_CONTACT_FORM_START,
  SEND_CONTACT_FORM_START,
  formFetched,
  formListFetched,
  formPosted,
  formFetchedError,
  formPostError,
  type FETCH_CONTACT_FORM_START_ACTION,
  type FETCH_ALL_CONTACT_FORM_START_ACTION,
  type SEND_CONTACT_FORM_START_ACTION
} from 'actions/formActions';

export default function(apiExecutor: ApiExecutorType) {
  const formApi = new FormApi(apiExecutor);

  return function* formSaga(): GeneratorType {
    yield takeLatest(FETCH_CONTACT_FORM_START, fetchForm);
    yield takeLatest(FETCH_ALL_CONTACT_FORM_START, fetchListForm);
    yield takeLatest(SEND_CONTACT_FORM_START, sendForm);
  };

  function* fetchForm(action: FETCH_CONTACT_FORM_START_ACTION): Saga<void> {
    try {
      const resp = yield call(formApi.fetchFirstFormContact);
      yield put(formFetched(resp));
    } catch (e) {
      yield put(formFetchedError());
    }
  }

  function* fetchListForm(action: FETCH_ALL_CONTACT_FORM_START_ACTION): Saga<void> {
    try {
      const resp = yield call(formApi.fetchAllFormContact);
      yield put(formListFetched(resp));
    } catch (e) {
      yield put(formFetchedError());
    }
  }

  function* sendForm(action: SEND_CONTACT_FORM_START_ACTION): Saga<void> {
    try {
      const { formInputs, id } = action.payload;
      const data = new FormData();

      for (const key in formInputs) {
        if (formInputs.hasOwnProperty(key)) {
          data.append(key, formInputs[key]);
        }
      }

      yield call(formApi.postFormContact, data, id);
      if (formInputs.hasOwnProperty('loge-nom') || formInputs.hasOwnProperty('seminaire-nom')) {
        yield put(displayModal('MODAL_LOGE_SUCCESS', {}, true));
      } else if (!formInputs.hasOwnProperty('jeu-concours-nom') && !formInputs.hasOwnProperty('grand-soutien-nom')) {
        yield put(displayModal('MODAL_CONTACT_FORM_SUCCESS', formInputs, true));
      }
      yield put(formPosted());
    } catch (e) {
      const data = {};
      if (e.invalidFields && e.invalidFields.length > 0 && e.invalidFields[0].message === 'Doublon adresse email') {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez participer qu'une seule fois.`;
      } else if (
        e.invalidFields &&
        e.invalidFields.length > 0 &&
        e.invalidFields[0].message === 'Doublon adresse email et video'
      ) {
        data.title = `Désolé !`;
        data.text = `Vous ne pouvez envoyer votre video qu'une seule fois.`;
      }
      yield put(displayModal('MODAL_CONTACT_FORM_FAILED', data, true));
      yield put(formPostError());
    }
  }
}
