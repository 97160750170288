// @flow

import type { StaffType } from 'types/Staff';

export const RESET_FETCH_STAFF = 'RESET_FETCH_STAFF';
export const FETCH_STAFF_START = 'FETCH_STAFF_START';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILURE = 'FETCH_STAFF_FAILURE';
export const FETCH_STAFFTEAM_START = 'FETCH_STAFFTEAM_START';
export const FETCH_STAFFTEAM_INITIAL = 'FETCH_STAFFTEAM_INITIAL';
export const FETCH_STAFFTEAM_SUCCESS = 'FETCH_STAFFTEAM_SUCCESS';
export const FETCH_STAFFTEAM_FAILURE = 'FETCH_STAFFTEAM_FAILURE';

export type RESET_FETCH_STAFF_ACTION = {
  type: 'RESET_FETCH_STAFF',
};

export type FETCH_STAFF_START_ACTION = {
  type: 'FETCH_STAFF_START',
  payload: {
    slug: string
  }
};

export type FETCH_STAFF_FAILURE_ACTION = {
  type: 'FETCH_STAFF_FAILURE'
};

export type FETCH_STAFF_SUCCESS_ACTION = {
  type: 'FETCH_STAFF_SUCCESS',
  payload: {
    staff : StaffType
  },
};

export type FETCH_STAFFTEAM_START_ACTION = {
  type: 'FETCH_STAFFTEAM_START',
  payload: {
    filters: Object
  }
};

export type FETCH_STAFFTEAM_INITIAL_ACTION = {
  type: 'FETCH_STAFFTEAM_INITIAL',
  payload: {
    filters: Object
  }
};

export type FETCH_STAFFTEAM_FAILURE_ACTION = {
  type: 'FETCH_STAFFTEAM_FAILURE'
};

export type FETCH_STAFFTEAM_SUCCESS_ACTION = {
  type: 'FETCH_STAFFTEAM_SUCCESS',
  payload: {
    staffs : Array<StaffType>
  },
};

export type Action =
  | RESET_FETCH_STAFF_ACTION
  | FETCH_STAFF_START_ACTION
  | FETCH_STAFF_FAILURE_ACTION
  | FETCH_STAFF_SUCCESS_ACTION
  | FETCH_STAFFTEAM_START_ACTION
  | FETCH_STAFFTEAM_FAILURE_ACTION
  | FETCH_STAFFTEAM_SUCCESS_ACTION
  | FETCH_STAFFTEAM_INITIAL_ACTION;

export function resetStaff(): RESET_FETCH_STAFF_ACTION {
  return {
    type: RESET_FETCH_STAFF,
  };
}

export function fetchStaff(slug: string): FETCH_STAFF_START_ACTION {
  return {
    type: FETCH_STAFF_START,
    payload: { slug }
  };
}

export function staffFetched(staff: StaffType): FETCH_STAFF_SUCCESS_ACTION {
  return {
    type: FETCH_STAFF_SUCCESS,
    payload: { staff }
  };
}

export function staffFetchError(error: any): FETCH_STAFF_FAILURE_ACTION {
  return {
    type: FETCH_STAFF_FAILURE
  };
}

export function initialFetchStaffsTeam(filters: Object): FETCH_STAFFTEAM_INITIAL_ACTION {
  return {
    type: FETCH_STAFFTEAM_INITIAL,
    payload: { filters }
  };
}

export function fetchStaffsTeam(filters: Object): FETCH_STAFFTEAM_START_ACTION {
  return {
    type: FETCH_STAFFTEAM_START,
    payload: { filters }
  };
}

export function staffsTeamFetched(staffs: Array<StaffType>): FETCH_STAFFTEAM_SUCCESS_ACTION {
  return {
    type: FETCH_STAFFTEAM_SUCCESS,
    payload: { staffs }
  };
}

export function staffsTeamFetchError(error: any): FETCH_STAFFTEAM_FAILURE_ACTION {
  return {
    type: FETCH_STAFFTEAM_FAILURE
  };
}
