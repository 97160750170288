// @flow
import React from 'react';

import ModalFormCentenaire from 'containers/centenaire/ModalFormCentenaireContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function CentenaireFormUploadModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box modal-centenaire modal-centenaire--form">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
      <div className="modal__header">
        <h3 className="ft-400 mb-1">{'Partager votre histoire'}</h3>
      </div>
      <div className="modal__wrapper">
        <ModalFormCentenaire content={content} />
      </div>
    </div>
  );
}

export default CentenaireFormUploadModalContent;
