// @flow
import React, { PureComponent, Fragment } from 'react';
import TextInput from 'components/fragments/input/material/TextInputMaterial';

import { validateEmail } from 'utils/validatorUtils';
import Loading from 'components/fragments/Loading';
import { sendTags } from 'utils/tagManagerUtils';

export type DispatchProps = {
  displayModal: (modalObject: string, content: Object, force: boolean) => void
};

export type StateProps = {};

type Props = {} & DispatchProps;

type State = {
  email: string | null,
  formSent: boolean,
  showEmailError: boolean
};

class ModalFormCentenaireEmail extends PureComponent<Props, State> {
  state: State = {
    email: null,
    formSent: false,
    showEmailError: false
  };

  _handleChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  isFormValid = () => {
    const { email } = this.state;

    return email !== null && email !== '' && validateEmail(email);
  };

  _handleSubmit = (event: Event) => {
    event.preventDefault();
    const { displayModal } = this.props;
    if (this.isFormValid()) {
      this.setState({ formSent: true });
      sendTags('page centenaire', 'popin partager votre histoire', 'email renseigné');
      displayModal('MODAL_CENTENAIRE_FORM_UPLOAD', { email: this.state.email }, true);
    }
  };

  onFocusEmail = () => {
    this.setState({ showEmailError: false });
  };

  onBlurEmail = () => {
    this.setState({ showEmailError: true });
  };

  render() {
    const { email, formSent, showEmailError } = this.state;

    const isValid = this.isFormValid();
    return (
      <Fragment>
        {!formSent && (
          <div>
            <TextInput
              id="email_centenaire"
              label="Renseignez votre email pour continuer *"
              onFocus={this.onFocusEmail}
              onBlur={this.onBlurEmail}
              hasError={showEmailError && (email === '' || (email !== '' && email !== null && !validateEmail(email)))}
              errorMessage={"L'adresse email renseignée est invalide."}
              onChange={value => this._handleChange('email', value)}
            />
            <button
              onClick={this._handleSubmit}
              className="btn btn--primary btn--full btn--gold js-confirm mb-1"
              disabled={!isValid}
              type="submit"
            >
              Partager un souvenir
            </button>
          </div>
        )}
        {formSent && <Loading isWhite style={{ width: '100px', margin: '0 auto' }} />}
      </Fragment>
    );
  }
}

export default ModalFormCentenaireEmail;
