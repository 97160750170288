//@flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { EditProfileValueType } from 'types/User';
import { convertUserFavsFromApi, convertUserFidFromApi, convertUserPrefFromApi } from 'services/User';

function UserApi(executor: ApiExecutorType) {
  return {
    changeUserFid,
    changeUserProfile,
    fetchUserFavs,
    fetchUserFid,
    fetchUserPref,
  };

  function fetchUserFavs(token: string) {
    let endpoint = '/ffr/v1/user_favs';
    
    return executor
      .get(endpoint, {
        timeout: 600000,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response) {
          throw new Error('NOT FOUND');
        }
        return convertUserFavsFromApi(response);
      });
  };

  function fetchUserFid(token: string) {
    let endpoint = '/ffr/v1/user_fid';
    
    return executor
      .get(endpoint, {
        timeout: 600000,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response) {
          throw new Error('NOT FOUND');
        }
        return convertUserFidFromApi(response);
      });
  };

  function changeUserFid(action: string, value: EditProfileValueType, token: string) {
    const endpoint = `/ffr/v1/user_fid?action=${action}`;
    return executor
      .post(endpoint, {
        value
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response) { 
          throw new Error('NOT FOUND');
        }
        
        return convertUserFidFromApi(response);
      });
  };

  function fetchUserPref(full: any, token: string) {
    let endpoint = '/ffr/v1/user_prefs';
    
    if (full === true || (full && full.payload && full.payload.full === true)) {
      endpoint = '/ffr/v1/user_prefs?type=complet';
    }
    return executor
      .get(endpoint, {
        timeout: 600000,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response) {
          throw new Error('NOT FOUND');
        }
        return convertUserPrefFromApi(response);
      });
  };

  function changeUserProfile(action: string, value: EditProfileValueType, token: string) {
    const endpoint = `/ffr/v1/user_prefs?action=${action}`;
    return executor
      .post(endpoint, {
        value
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response) { 
          throw new Error('NOT FOUND');
        }
        
        return convertUserPrefFromApi(response);
      });
  };
};

export default UserApi;