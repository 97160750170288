// @flow
import React from 'react';

import NewsListElement from 'components/newsletter/NewsListElement';

type Props = {
  attributes: any
};

function NewsListElementContentBloc({ attributes }: Props) {
  const getAttributeValue = attrName => {
    const attributeData = attributes.find(attr => attr.name === attrName);

    if (!!attributeData) {
      return attributeData.value;
    } else {
      return '';
    }
  };

  const img = getAttributeValue('img');
  const title = getAttributeValue('title');
  const date = getAttributeValue('date');
  const description = getAttributeValue('description');
  const url = getAttributeValue('url');
  const srcset = getAttributeValue('srcset') ? JSON.parse(getAttributeValue('srcset')) : {};
  const excerpt = getAttributeValue('excerpt');
  const readingTime = getAttributeValue('readingTime');

  return (
    <NewsListElement
      img={img ?? ''}
      title={title ?? ''}
      date={date ?? ''}
      description={description ?? ''}
      url={url ?? ''}
      srcset={srcset}
      tags={[]}
      excerpt={excerpt ?? ''}
      readingTime={typeof readingTime === 'number' ? readingTime : 0}
      hasMetaData
    />
  );
}

export default NewsListElementContentBloc;
