// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_COMPETITIONS_INTERNATIONALES_FAILURE,
  FETCH_COMPETITIONS_INTERNATIONALES_START,
  FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS
} from 'actions/competitionsInternationalesActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import type { CompetitionInternationale } from 'types/competitionsInternationales';
import { nullCompetItl } from 'services/CompetitionsInternationales';

export type State = {
  data: CompetitionInternationale,
  status: Status
};

const initialState: State = {
  status: STATUS_DEFAULT,
  data: nullCompetItl
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_COMPETITIONS_INTERNATIONALES_START:
      return {
        ...state,
        status: STATUS_LOADING
      };

    case FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        status: STATUS_SUCCESS
      };

    case FETCH_COMPETITIONS_INTERNATIONALES_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };

    default:
      return state;
  }
}
