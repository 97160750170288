// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_VIDEOS_CHANNEL_START,
  FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS,
  FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE,
  FETCH_YOUTUBE_VIDEO_CHANNEL_START,
  FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS,
  FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE
} from 'actions/ffr-tv/youtubeVideosChannelActions';

import {
  FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START,
  FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS,
  FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE
} from 'actions/ffr-tv/youtubeMoreVideosChannelActions';

import { nullYoutubeVideo, type YoutubeVideo } from 'types/YoutubeVideo';
import type { YoutubePlaylistItem } from 'types/YoutubePlaylistItems';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  videos: Array<YoutubeVideo>,
  video: YoutubeVideo,
  statusVideos: Status,
  statusVideo: Status,
  idChannelUploads: string,
  nextPageToken: string,
  playlistItems: Array<YoutubePlaylistItem>,
  statusPlaylistItems: Status,
  returnInitialState?: boolean
};

const initialState: State = {
  video: nullYoutubeVideo,
  videos: [],
  statusVideos: STATUS_DEFAULT,
  statusVideo: STATUS_DEFAULT,
  idChannelUploads: '',
  nextPageToken: '',
  playlistItems: [],
  statusPlaylistItems: STATUS_DEFAULT,
  returnInitialState: false
};
export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_VIDEOS_CHANNEL_START:
      return {
        ...state,
        statusPlaylistItems: STATUS_LOADING
      };
    case FETCH_YOUTUBE_VIDEOS_CHANNEL_SUCCESS:
      return {
        ...state,
        playlistItems: action.payload.playlistItems,
        statusPlaylistItems: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_VIDEOS_CHANNEL_FAILURE:
      return {
        ...state,
        statusPlaylistItems: STATUS_FAILURE
      };

    case FETCH_YOUTUBE_VIDEO_CHANNEL_START:
      return {
        ...state,
        statusVideo: STATUS_LOADING
      };

    case FETCH_YOUTUBE_VIDEO_CHANNEL_SUCCESS:
      return {
        ...state,
        video: action.payload.video,
        idChannelUploads: action.payload.idChannelUploads,
        statusVideo: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_VIDEO_CHANNEL_FAILURE:
      return {
        ...state,
        statusVideo: STATUS_FAILURE
      };
    case FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START:
      return {
        ...state,
        statusVideos: STATUS_LOADING
      };
    case FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS: {
      return {
        ...state,
        statusVideos: STATUS_SUCCESS,
        videos: action.payload.returnInitialState
          ? [...initialState.videos, ...action.payload.videos]
          : [...state.videos, ...action.payload.videos],
        returnInitialState: false
      };
    }
    case FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };

    default:
      return state;
  }
}
