// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { ClubPreviewType, ClubType } from 'types/Club';
import { convertClubPreviewFromApi, convertClubFromApi } from 'services/Club';

function ClubApi(executor: ApiExecutorType) {
  return { getClubs, getClub };

  function getClubs(): Promise<Array<ClubPreviewType>> {
    return executor.get('clubssearch.json').then(response => Promise.all(response.map(convertClubPreviewFromApi)));
  }

  function getClub(slug: string): Promise<ClubType> {
    return executor.get(`/ffr/v1/clubs?slug=${slug}`).then(response => convertClubFromApi(response[0]));
  }
}

export default ClubApi;
