// @flow
import { mergeIntoDefault } from 'utils/objectUtils';
import { nullChannel } from 'types/YoutubeChannel';
import { type RawYoutubeChannelInfo, type YoutubeChannelInfo } from 'types/YoutubeChannel';
import { nullPlaylistsChannel } from 'types/YoutubePlaylistsChannel';
import { type RawYoutubePlaylistsChannel, type YoutubePlaylistChannel } from 'types/YoutubePlaylistsChannel';
import { nullYoutubePlaylist, nullYoutubePlaylistsCustom } from 'types/YoutubePlaylist';
import { handleYoutubeThumbnailsUrl } from 'utils/youtubeApiUtils';
export function convertPlaylistsChannelFromApi(
  rawYoutubePlaylistChannel: RawYoutubePlaylistsChannel
): YoutubePlaylistChannel {
  if (!rawYoutubePlaylistChannel) {
    return nullPlaylistsChannel;
  }

  const {
    etag,
    nextPageToken,
    prevPageToken,
    pageInfo: { totalResults },
    items
  } = rawYoutubePlaylistChannel;

  const playlists = {
    etag,
    nextPageToken,
    prevPageToken,
    totalResults,
    items: items.map(item => ({
      id: item.id,
      publishedAt: item.snippet.publishedAt,
      channelId: item.snippet.channelId,
      title: item.snippet.title,
      label: item.snippet.description,
      thumbnails: item.snippet.thumbnails,
      image: handleYoutubeThumbnailsUrl(item.snippet.thumbnails),
      url: `/ffr-tv-playlist/${item.id}`,
      itemCount: item.contentDetails.itemCount
    }))
  };

  return mergeIntoDefault(nullPlaylistsChannel, playlists);
}

export function verifPlaylistsChannelFromApi(rawYoutubePlaylistChannel: RawYoutubePlaylistsChannel): any {
  if (!rawYoutubePlaylistChannel || !rawYoutubePlaylistChannel.items || rawYoutubePlaylistChannel.items.length === 0) {
    return nullPlaylistsChannel;
  }

  return mergeIntoDefault(nullYoutubePlaylistsCustom, {
    youtubePlaylists: rawYoutubePlaylistChannel.items.map(item =>
      mergeIntoDefault(nullYoutubePlaylist, {
        id: item.id,
        title: item.snippet.title,
        description: item.snippet.description,
        image: handleYoutubeThumbnailsUrl(item.snippet.thumbnails),
        itemCount: item.contentDetails.itemCount,
        url: `/ffr-tv-playlist/${item.id}`
      })
    ),
    etag: rawYoutubePlaylistChannel.etag,
    nextPageToken: rawYoutubePlaylistChannel.nextPageToken
  });
}

export function convertChannelInfoFromApi(rawYoutubeChannelInfo: RawYoutubeChannelInfo): YoutubeChannelInfo {
  if (!rawYoutubeChannelInfo) {
    return nullChannel;
  }

  const { items } = rawYoutubeChannelInfo;

  const channelsInfo = {
    id: items[0].id,
    title: items[0].snippet.localized.title,
    description: items[0].snippet.localized.description,
    publishedAt: items[0].snippet.publishedAt
  };

  return mergeIntoDefault(nullChannel, channelsInfo);
}
