// @flow

import type { SeminaireType } from 'types/Seminaire';

export const FETCH_SEMINAIRE_START = 'FETCH_SEMINAIRE_START';
export const FETCH_SEMINAIRE_SUCCESS = 'FETCH_SEMINAIRE_SUCCESS';
export const FETCH_SEMINAIRE_FAILURE = 'FETCH_SEMINAIRE_FAILURE';

export type FETCH_SEMINAIRE_START_ACTION = {
  type: 'FETCH_SEMINAIRE_START',
};

export type FETCH_SEMINAIRE_FAILURE_ACTION = {
  type: 'FETCH_SEMINAIRE_FAILURE',
};

export type FETCH_SEMINAIRE_SUCCESS_ACTION = {
  type: 'FETCH_SEMINAIRE_SUCCESS',
  payload: {
    seminaires: Array<SeminaireType>,
  },
};

export type Action =
  | FETCH_SEMINAIRE_START_ACTION
  | FETCH_SEMINAIRE_FAILURE_ACTION
  | FETCH_SEMINAIRE_SUCCESS_ACTION;

export function fetchSeminaire(): FETCH_SEMINAIRE_START_ACTION {
  return {
    type: FETCH_SEMINAIRE_START,
  };
}

export function seminaireFetched(
  seminaires: Array<SeminaireType>,
): FETCH_SEMINAIRE_SUCCESS_ACTION {
  return {
    type: FETCH_SEMINAIRE_SUCCESS,
    payload: { seminaires },
  };
}

export function seminaireFetchError(): FETCH_SEMINAIRE_FAILURE_ACTION {
  return {
    type: FETCH_SEMINAIRE_FAILURE,
  };
}
