// @flow

export type SeasonListType = {
  id: number,
  slug: string,
  name: string,
  joueurs: boolean,
  staff: boolean,
  rencontres: string[],
  resultats: boolean,
  competitions: { label: string, value: string }[]
};

export type RawSeasonsListType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  joueurs: boolean,
  staff: boolean,
  rencontres: string[],
  resultats: boolean,
  competitions: { label: string, value: string }[],
  meta: Array<any>,
  links: Object
};

export const nullSeasonsList: SeasonListType = {
  id: 0,
  slug: '',
  name: '',
  joueurs: false,
  staff: false,
  rencontres: [],
  resultats: false,
  competitions: []
};
