// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  convertLogeListFromApi,
  convertLogeDetailFromApi,
  convertLogeDetailPreviewFromApi,
} from 'services/Loge';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function LogeApi(executor: ApiExecutorType) {
  return {
    getLogeList,
    fetchLogeDetail,
    fetchLogePreviewById,
    getTypeOfMatch,
  };

  function getLogeList(match?: number) {
    let url = `/ffr/v1/loges`;
    if (match) {
      url += `?type-de-match=${match}`;
    }
    return executor.get(url)
      .then(response => Promise.all(response.map(convertLogeListFromApi)));
  }

  function fetchLogeDetail(slug: string) {
    return executor.get(`/ffr/v1/loges?slug=${slug}`).then(response => {
      if (!response || response.length === 0) {
        throw new Error('NOT FOUND');
      }

      return convertLogeDetailFromApi(response);
    });
  }

  function fetchLogePreviewById(id: number) {
    return executor.get(`/ffr/v1/loges/${id}`, {
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`
        }
      }).then(response => convertLogeDetailPreviewFromApi(response));
  }

  function getTypeOfMatch() {
    return executor.get('/type-de-match.json');
  }
}

export default LogeApi;
