// @flow
import React, { PureComponent } from 'react';

import Loading from 'components/fragments/Loading';
import { STATUS_SUCCESS } from 'constants/statusConstants';
import ItemBadge from 'containers/profile/MesFavoris/ItemBadgeContainer';
import { removeAccents } from 'utils/nameUtils';

import type { Status } from 'types/Status';
import type { EquipeSearchType } from 'types/TeamList';

export type DispatchProps = {
  fetchEquipeSearch: () => void
};

export type StateProps = {
  equipes_favoris: number[],
  equipes_search: EquipeSearchType[],
  equipes_search_status: Status,
  postUserPrefStatus: Status
};

type Props = DispatchProps &
  StateProps & {
    hideModal: Function
  };

type State = {
  filteredTeams: any[],
  query: string
};

class AddAFavoriteTeamModalContent extends PureComponent<Props, State> {
  state: State = {
    filteredTeams: [],
    query: ''
  };

  componentDidMount() {
    const { equipes_search, fetchEquipeSearch } = this.props;

    //* launch equipeSearch
    fetchEquipeSearch();

    //*
    this.setState({ filteredTeams: equipes_search });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { equipes_search } = this.props;
    const { equipes_search: previous_equipes_search } = this.props;
    const { query } = this.state;
    const { query: previousQuery } = prevState;

    //* update filteredTeams when equipes_search is fetched
    if (equipes_search !== previous_equipes_search) {
      this.setState({ filteredTeams: equipes_search });
    }

    // when the user is looking for an item
    // we filter the items according to his query
    if (query !== previousQuery) {
      const filteredTeams = equipes_search.filter(team => {
        return removeAccents(team.title.toLowerCase()).includes(query);
      });

      this.setState({ filteredTeams: filteredTeams.slice(0, 10) });
    }
  }

  /**
   * Change the value of query on the input change
   * save it without accent and in lower case
   */
  handleInputChanged = (event: any) => {
    this.setState({ query: removeAccents(event.target.value.toLowerCase()) });
  };

  render() {
    const { hideModal, equipes_favoris, equipes_search, equipes_search_status, postUserPrefStatus } = this.props;
    const { filteredTeams, query } = this.state;
    const teamsToDisplay = query.length !== 0 ? filteredTeams : equipes_search;

    return (
      <>
        <h3>Ajouter une équipe favorite</h3>
        <div className="mt-4">
          <div className="input input--search ">
            <input
              type="search"
              // TODO : id pour rechercher une équipe ?
              id="yagd2adssd3"
              placeholder="Rechercher une équipe"
              autoComplete="off"
              // onClick={this.props.handleInputClicked} // not necessary
              onChange={this.handleInputChanged} // will filter the result
              value={query}
              aria-label={'Rechercher une équipe'}
            />
          </div>
          <div className="add-favorite-item-modal__content">
            {/* Loader */}
            {(equipes_search_status !== STATUS_SUCCESS || postUserPrefStatus !== STATUS_SUCCESS) && (
              <Loading isWhite style={{ height: '168px', display: 'flex' }} />
            )}
            {/* teams accordingly to the user query */}
            {equipes_search_status === STATUS_SUCCESS &&
              postUserPrefStatus === STATUS_SUCCESS &&
              equipes_search &&
              !!teamsToDisplay.length && (
                <ul className="item-list">
                  {teamsToDisplay.map(team => {
                    const nameToDisplay = `${team.title}`;

                    return (
                      <ItemBadge
                        key={team.id}
                        featured_image={team.featured_image}
                        id={team.id}
                        item={'equipes'}
                        isFavoriteItem={equipes_favoris.includes(team.id)} // TODO : change when teams_favoris
                        title={nameToDisplay}
                      />
                    );
                  })}
                </ul>
              )}
            {/*  when no team is returned to the user query */}
            {equipes_search_status === STATUS_SUCCESS && equipes_search && !teamsToDisplay.length && (
              <div className="box">
                <div className="placeholder ">
                  <p className="ft-h5">
                    <i className="icon icon-ball"></i>
                    <br />
                    Aucune équipe pour cette recherche
                  </p>
                </div>
              </div>
            )}
          </div>
          <button className="btn btn--primary mt-3 mt-md-2" onClick={hideModal}>
            Terminer
          </button>
        </div>
      </>
    );
  }
}

export default AddAFavoriteTeamModalContent;
