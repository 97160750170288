// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import StaffApi from 'api/staff/StaffApi';
import SeasonsApi from 'api/seasons/SeasonsApi';
import {
  FETCH_STAFF_START,
  staffFetched,
  staffFetchError,
  FETCH_STAFFTEAM_START,
  FETCH_STAFFTEAM_INITIAL,
  staffsTeamFetched,
  staffsTeamFetchError
} from 'actions/staffActions';
import {
  seasonsFetched,
} from 'actions/seasonsActions';
import { setDefaultSeason } from 'actions/filterOptionsActions';
import type {
  FETCH_STAFF_START_ACTION,
  FETCH_STAFFTEAM_START_ACTION,
  FETCH_STAFFTEAM_INITIAL_ACTION
} from 'actions/staffActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const staffApi = new StaffApi(apiExecutor);
  const seasonsApi = new SeasonsApi(staticApiExecutor);
  return function* staffSaga(): GeneratorType {
    yield takeLatest(FETCH_STAFF_START, fetchStaff);
    yield takeLatest(FETCH_STAFFTEAM_START, fetchStaffsTeam);
    yield takeLatest(FETCH_STAFFTEAM_INITIAL, initialFetchStaffsTeam);
  };

  function* fetchStaff(action: FETCH_STAFF_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const staff = yield call(staffApi.fetchStaffBySlug, slug);
      yield put(staffFetched(staff));
    } catch (e) {
      yield put(staffFetchError(e));
    }
  }

  function* initialFetchStaffsTeam(action: FETCH_STAFFTEAM_INITIAL_ACTION): Saga<void> {
    try {
      const { filters, filters: { equipe }} = action.payload;
      const seasons = yield call(seasonsApi.getSeasonsList, equipe);
      const seasonsStaff = seasons.filter((season) => season.staff === true);
      const staffs = yield call(staffApi.fetchStaffsTeam, { 
        season : seasonsStaff[seasonsStaff.length - 1].id, ...filters 
      });
      yield put(setDefaultSeason(seasonsStaff[seasonsStaff.length - 1].name));
      yield put(seasonsFetched(seasons));
      yield put(staffsTeamFetched(staffs));
    } catch (e) {
      yield put(staffsTeamFetchError(e));
    }
  }

  function* fetchStaffsTeam(action: FETCH_STAFFTEAM_START_ACTION): Saga<void> {
    try {
      const { filters } = action.payload;

      const staffs = yield call(staffApi.fetchStaffsTeam, filters);
      yield put(staffsTeamFetched(staffs));
    } catch (e) {
      yield put(staffsTeamFetchError(e));
    }
  }
}
