// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import NewsByTheme from 'components/newsletter/NewsByTheme';
import NewsListElement from 'components/newsletter/NewsListElement';
import HtmlElementWrapper from 'containers/newsletter/blocs/HtmlElementWrapperContainer';
import type { CompetitionInternationale } from 'types/competitionsInternationales';
import type { Status } from 'types/Status';
import type { FullUserType } from 'types/User';

export type OwnProps = {
  slug?: string,
  blockformat?: string,
  competitionrankingactivated?: string,
  childrens?: any,
  articles: any,
  title: string
};

export type StateProps = {
  status?: Status,
  userPref?: FullUserType,
  competition?: CompetitionInternationale
};

type State = {
  shouldDisplayLoading: boolean
};
type Props = OwnProps & StateProps;

export default class Article extends PureComponent<Props, State> {
  props: Props;
  render() {
    const { articles, title, blockformat, competition, competitionrankingactivated, childrens, userPref } = this.props;

    let articlesParsed = articles ? JSON.parse(articles) : [];

    let isThreeBigBlockList = false;
    if (blockformat && blockformat === 'simple_list') {
      isThreeBigBlockList = true;
    }

    let hasClassement = false;
    if (competitionrankingactivated && competitionrankingactivated === 'true' && childrens && childrens.length > 0) {
      hasClassement = true;
    }
    const link = competition ? `/competitions-internationales/${competition.slug}/actualites` : '';

    // version liste avec 3 grandes actus
    if (!!isThreeBigBlockList && !!articlesParsed && articlesParsed.length > 0) {
      return (
        <section className="section section-competition-news section-competition-news--no-background">
          <h3>{title || `Actualités`}</h3>

          <div className="row grid-news">
            {articlesParsed.map(article => (
              <div className="col col-lg-3on9" key={article.id}>
                <NewsListElement
                  srcset={{}}
                  key={article.id}
                  url={article.link || `/actualites/${article.label.slug}/${article.slug}`}
                  img={article.illustration}
                  title={article.title}
                  date={article.date}
                  description={article.caption}
                  userPref={userPref}
                  tags={article.tags}
                  excerpt={article.excerpt !== '' ? article.excerpt : article.meta_description}
                  readingTime={article.readingTime}
                  hasMetaData
                />
              </div>
            ))}
          </div>
        </section>
      );
    }

    // version avec le classement
    if (hasClassement && childrens && childrens.length > 0) {
      return (
        <section className="section section--tag-articles section--tag-articles-classement">
          <HtmlElementWrapper
            childrens={childrens[0].children}
            attributes={childrens[0].attributes}
            tagName={childrens[0].tagName}
          />
          <div className="liste-news">
            <h3>{title || `Actualités`}</h3>

            {articlesParsed && articlesParsed.length > 0 && (
              <NewsByTheme news={articlesParsed} theme="" url={link} userPref={userPref} />
            )}

            {link && (
              <Link to={link} className="link-chevron link-chevron--block mt-3">
                <span>Voir toutes les actualités</span>
                <i className="icon icon-chevron-right"></i>
              </Link>
            )}
          </div>
        </section>
      );
    }

    // version sans le classement
    return (
      <section className="section section--tag-articles scrollable-row mb-7">
        <h3>{title || `Actualités`}</h3>

        <NewsByTheme news={articlesParsed} theme="" url={link} userPref={userPref} />

        {link && (
          <Link to={link} className="link-chevron link-chevron--block mt-3">
            <span>Voir toutes les actualités</span>
            <i className="icon icon-chevron-right"></i>
          </Link>
        )}
      </section>
    );
  }
}
