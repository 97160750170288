// @flow

export type RawYoutubePlaylistItems = {
  kind: string,
  etag: string,
  nextPageToken: string,
  prevPageToken: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: string,
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      playlistId: number,
      position: number,
      resourceId: {
        kind: string,
        videoId: string
      }
    }
  }>
};

export const nullRawYoutubePlaylistItems = {
  kind: '',
  etag: '',
  nextPageToken: '',
  prevPageToken: '',
  pageInfo: {
    totalResults: 0,
    resultsPerPage: 0
  },
  items: [
    {
      kind: '',
      etag: '',
      id: '',
      snippet: {
        publishedAt: '',
        channelId: '',
        title: '',
        description: '',
        thumbnails: {
          default: {
            url: '',
            width: 0,
            height: 0
          },
          medium: {
            url: '',
            width: 0,
            height: 0
          },
          high: {
            url: '',
            width: 0,
            height: 0
          },
          standard: {
            url: '',
            width: 0,
            height: 0
          },
          maxres: {
            url: '',
            width: 0,
            height: 0
          }
        },
        channelTitle: '',
        playlistId: 0,
        position: 0,
        resourceId: {
          kind: '',
          videoId: ''
        }
      }
    }
  ]
};

export const nullYoutubePlaylistItems = {
  youtubePlaylistItem: [],
  etag: '',
  channelId: '',
  nextPageToken: '',
  title: ''
};
export const nullYoutubePlaylistItem = {
  id: '',
  title: ''
};

export type YoutubePlaylistItem = {
  id: string,
  title: string
};

export type YoutubePlaylistItems = {
  youtubePlaylistItem: Array<YoutubePlaylistItem>,
  etag: string,
  nextPageToken?: string,
  channelId: string
};
