// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';
import type { YoutubePlaylistItem } from 'types/YoutubePlaylistItems';

export const FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START = 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START';
export const FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS = 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS';
export const FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE = 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE';

export type FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START',
  payload: { playlistItems: Array<YoutubePlaylistItem>, returnInitialState: boolean }
};

export type FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE'
};

export type FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>,
    returnInitialState: boolean
  }
};

export type Action =
  | FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START_ACTION
  | FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION
  | FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION;

export function fetchMoreYoutubeVideosChannel(
  playlistItems: Array<YoutubePlaylistItem>,
  returnInitialState: boolean
): FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START,
    payload: { playlistItems, returnInitialState }
  };
}

export function moreVideosChannelFetched(
  videos: Array<YoutubeVideo>,
  returnInitialState: boolean
): FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_SUCCESS,
    payload: { videos, returnInitialState }
  };
}

export function moreVideosChannelFetchError(): FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_FAILURE
  };
}
