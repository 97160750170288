// @flow
import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_MATCH_EVENTS_START,
  FETCH_MATCH_EVENTS_SUCCESS,
  FETCH_MATCH_EVENTS_FAILURE
} from 'actions/matchEventsActions';

import type { EventMatchType } from 'types/Event';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  matchEvents: Array<EventMatchType>,
  status: Status
};

const initialState: State = {
  matchEvents: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_MATCH_EVENTS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCH_EVENTS_SUCCESS:
      return {
        ...state,
        matchEvents: action.payload.matchEvents,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCH_EVENTS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
