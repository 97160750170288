// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_YOUTUBE_SPOTLIGHT_START = 'FETCH_YOUTUBE_SPOTLIGHT_START';
export const FETCH_YOUTUBE_SPOTLIGHT_SUCCESS = 'FETCH_YOUTUBE_SPOTLIGHT_SUCCESS';
export const FETCH_YOUTUBE_SPOTLIGHT_FAILURE = 'FETCH_YOUTUBE_SPOTLIGHT_FAILURE';

export type FETCH_YOUTUBE_SPOTLIGHT_START_ACTION = {
  type: 'FETCH_YOUTUBE_SPOTLIGHT_START'
};

export type FETCH_YOUTUBE_SPOTLIGHT_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_SPOTLIGHT_FAILURE'
};

export type FETCH_YOUTUBE_SPOTLIGHT_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_SPOTLIGHT_SUCCESS',
  payload: {
    video: YoutubeVideo
  }
};

export type Action =
  | FETCH_YOUTUBE_SPOTLIGHT_START_ACTION
  | FETCH_YOUTUBE_SPOTLIGHT_FAILURE_ACTION
  | FETCH_YOUTUBE_SPOTLIGHT_SUCCESS_ACTION;

export function fetchSpotlightVideo(): FETCH_YOUTUBE_SPOTLIGHT_START_ACTION {
  return {
    type: FETCH_YOUTUBE_SPOTLIGHT_START
  };
}

export function SpotlightVideoFetched(video: YoutubeVideo): FETCH_YOUTUBE_SPOTLIGHT_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_SPOTLIGHT_SUCCESS,
    payload: { video }
  };
}

export function SpotlightVideoFetchError(error: any): FETCH_YOUTUBE_SPOTLIGHT_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_SPOTLIGHT_FAILURE
  };
}
