// @flow

import type { CompetitionType, RawCompetitionType } from 'types/Competitions';
import { nullCompetition } from 'types/Competitions';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertCompetitionsFromApi(rawCompetition: RawCompetitionType): CompetitionType {
  if (!rawCompetition) {
    return nullCompetition;
  }
  const { 
    id,
    slug,
    title,
    content,
    featured_media,
    meta,
    saison,
    meta_title,
    dernieres_rencontres,
    prochaines_rencontres,
    link,
  } = rawCompetition;
  
  return mergeIntoDefault(nullCompetition, {
    id,
    slug,
    title: title.rendered,
    content,
    featured_media,
    meta,
    saison,
    meta_title,
    dernieres_rencontres,
    prochaines_rencontres,
    link,
  });
};

