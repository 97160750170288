// @flow
import React, { Component } from 'react';
import jwt from 'jsonwebtoken';
import { sha256 } from 'js-sha256';
import { NavLink } from 'react-router-dom';

import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';
import SelectInput from 'components/fragments/input/SelectInput';
import { options } from 'constants/profile';
import { STATUS_SUCCESS } from 'constants/statusConstants';
import { validateEmail, validatePhone } from 'utils/validatorUtils';
import type { Status } from 'types/Status';
import type { FullUserType, UserProfile } from 'types/User';

export type StateProps = {
  userPref: FullUserType,
  status: Status,
  profileLater: number
};

export type DispatchProps = {
  postUserPref: (action: string, value: any, token: string) => void,
  setProfileLater: (profileLater: number) => void
};

type Props = StateProps & DispatchProps;

type State = {
  error: string,
  inputs: string[],
  isCompleted: boolean,
  isCompletedProfileBlockClosedFromLocalStorage: string,
  isLicensedFromLocalStorage: string,
  isNotAssociatedToOvaleFromLocalStorage: string,
  isNotLicensedFromLocalStorage: string,
  profil: UserProfile,
  profileCompletionRate: number,
  step: number
};

class FormProfil extends Component<Props, State> {
  state: State = {
    error: '',
    inputs: [],
    isCompleted: false,
    isCompletedProfileBlockClosedFromLocalStorage: 'false',
    isLicensedFromLocalStorage: 'false',
    isNotLicensedFromLocalStorage: 'false',
    isNotAssociatedToOvaleFromLocalStorage: 'false',
    profil: {
      id: '',
      nom: '',
      prenom: '',
      licence: '',
      situation: '',
      email: '',
      genre: '',
      date_naissance: '',
      ville_naissance: '',
      telephone: '',
      surnom: '',
      fonction: ''
    },
    profileCompletionRate: 0,
    step: 0
  };

  componentDidMount() {
    const { profil } = this.props.userPref;

    this.setState({
      profil: {
        ...profil
      },
      isCompletedProfileBlockClosedFromLocalStorage: localStorage.getItem('completed-profile-block-closed') ?? 'false',
      isLicensedFromLocalStorage: localStorage.getItem('license-from-user') ?? 'false',
      isNotLicensedFromLocalStorage: localStorage.getItem('no-license-from-user') ?? 'false',
      isNotAssociatedToOvaleFromLocalStorage: localStorage.getItem('no-association-to-ovale-from-user') ?? 'false',
      profileCompletionRate: this.percentProfileCompleted()
    });

    const emptyInputs = Object.keys(profil).filter(
      val => val !== 'situation' && val !== 'licence' && val !== 'id' && profil[val] === ''
    );

    if (emptyInputs.length === 0) {
      this.setState({
        isCompleted: true
      });
    }
    this.setState({
      inputs: emptyInputs
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      (prevProps.status !== this.props.status && this.props.status === STATUS_SUCCESS) ||
      JSON.stringify(prevProps.userPref.profil) !== JSON.stringify(this.props.userPref.profil)
    ) {
      const { profil } = this.props.userPref;
      this.setState({
        profil: {
          ...profil
        }
      });

      const emptyInputs = Object.keys(profil).filter(
        val => val !== 'situation' && val !== 'licence' && val !== 'id' && profil[val] === ''
      );

      if (emptyInputs.length === 0) {
        this.setState({
          isCompleted: true
        });
      }
      this.setState({
        inputs: emptyInputs
      });
    }

    if (prevState.inputs !== this.state.inputs) {
      this.setState({
        profileCompletionRate: this.percentProfileCompleted()
      });
    }
  }

  percentProfileCompleted = () => {
    const { profil } = this.state;

    if (Object.keys(profil).length === 0) {
      return 0;
    }

    const emptyInputs = Object.keys(profil).filter(
      val => val !== 'situation' && val !== 'id' && val !== 'licence' && profil[val] === ''
    );
    if (emptyInputs.length === 0) {
      return 100;
    }

    /**
     * Attention ici on enlève 3 éléments pour :
     * - id
     * - licence
     * - situation
     */
    const amount = Math.round(
      ((Object.entries(profil).length - 3 - emptyInputs.length) / (Object.entries(profil).length - 3)) * 100
    );

    return amount;
  };

  handleChange = (value: string, type: string) => {
    const { profil } = this.state;

    if (['nom', 'prenom', 'email', 'surnom'].indexOf(type) > -1 && value.length <= 2) {
      this.setState({
        error: 'Ce champ est obligatoire'
      });
      return;
    }
    if (type === 'email' && !validateEmail(value)) {
      this.setState({
        error: 'Veillez insérer un email valide'
      });
      return;
    }
    if (type === 'telephone' && value !== '' && !validatePhone(value)) {
      this.setState({
        error: 'Veillez insérer un numéro de téléphone valide'
      });
      return;
    }
    if (type == 'date_naissance' && value !== '') {
      let dateReg = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!value.match(dateReg)) {
        this.setState({
          error: 'Veuillez formatter votre date sous la forme JJ/MM/AAAA'
        });
        return;
      }
      let dateSplit = value.split('/');
      if (
        parseInt(dateSplit[0], 10) < 1 ||
        parseInt(dateSplit[0], 10) > 31 ||
        parseInt(dateSplit[1], 10) < 1 ||
        parseInt(dateSplit[1], 10) > 12 ||
        parseInt(dateSplit[2], 10) < new Date().getUTCFullYear() - 120 ||
        parseInt(dateSplit[2], 10) > new Date().getUTCFullYear() - 10
      ) {
        this.setState({
          error: 'Veuillez insérer une date valide'
        });
        return;
      }
    }
    this.setState({
      error: '',
      profil: {
        ...profil,
        [type]: value
      }
    });
  };

  handleSubmit = () => {
    const { profil, error } = this.state;
    if (!error) {
      const { postUserPref } = this.props;
      postUserPref('change-profil', profil, this.props.userPref.token);
    } else {
      alert('Il y a une erreur');
    }
    this.setState({
      profileCompletionRate: this.percentProfileCompleted()
    });
  };

  nextStep = (e: MouseEvent) => {
    e.preventDefault();
    const { step, inputs } = this.state;
    if (inputs.length >= step + 2) {
      this.setState({ step: step + 1 });
    }
  };

  pastStep = (e: MouseEvent) => {
    e.preventDefault();
    const { step } = this.state;
    if (step >= 1) {
      this.setState({ step: step - 1 });
    }
  };

  finishLater = () => {
    const date = Date.now() + 2 * 86400 * 1000;
    this.props.setProfileLater(date);
  };

  renderInputs = (): Array<React$Element<any> | null> => {
    const { profil } = this.props.userPref;
    const { profil: stateProfil, inputs, step, error } = this.state;

    return inputs
      .filter(input => input !== 'id')
      .slice(step, step + 1)
      .map((input: string) => {
        if (input === 'fonction') {
          return (
            <SelectInput
              key={`Input_${input}`}
              id={input}
              className="select--material mb-2 js-other"
              placeholder="Indiquez votre poste"
              label="Poste"
              onChange={value => this.setState({ profil: { ...stateProfil, fonction: value } })}
              errorMessage={error}
              hasError={error !== ''}
              options={options}
              defaultValue={profil[input] || stateProfil[input]}
            />
          );
        } else if (input === 'genre') {
          const genres = [
            { value: '', label: '-' },
            { value: 'F', label: 'Féminin' },
            { value: 'M', label: 'Masculin' }
          ];
          return (
            <SelectInput
              key={`Input_${input}`}
              id={input}
              className="select--material mb-2 js-other"
              placeholder=""
              label="Genre"
              onChange={value => this.setState({ profil: { ...stateProfil, genre: value } })}
              options={genres}
              errorMessage={error}
              hasError={error !== ''}
              defaultValue={profil[input] || stateProfil[input]}
            />
          );
        } else if (input === 'date_naissance') {
          return (
            <TextInputMaterial
              key={`Input_${input}`}
              id={input}
              isLight
              placeholder={`JJ/MM/AAAA`}
              label={`${input.substr(0, 1).toUpperCase()}${input.substr(1, input.length).replace('_', ' de ')}`}
              onChange={value => this.handleChange(value, input)}
              errorMessage={error}
              hasError={error !== ''}
              defaultValue={profil[input] || stateProfil[input]}
            />
          );
        }
        return (
          <TextInputMaterial
            key={`Input_${input}`}
            id={input}
            isLight
            label={`${input.substr(0, 1).toUpperCase()}${input.substr(1, input.length).replace('_', ' de ')}`}
            onChange={value => this.handleChange(value, input)}
            errorMessage={error}
            hasError={error !== ''}
            defaultValue={profil[input] || stateProfil[input]}
          />
        );
      });
  };

  renderProfileToComplete = () => {
    const { step, inputs, error } = this.state;

    return (
      <form>
        <div className="card__body">
          {this.renderInputs()}
          <div className="input-group input-group--edit" style={{ overflow: 'hidden' }}>
            <div className="button-next-past">
              {inputs.length > 1 && (
                <>
                  {step !== 0 && (
                    <button
                      className="ft-500 btn btn--white btn--edit"
                      onClick={this.pastStep}
                      style={{ marginRight: '16px', color: '#999' }}
                    >
                      Précédent
                    </button>
                  )}
                  {inputs.length > step + 1 && (
                    <button
                      className="ft-500 btn btn--white btn--edit"
                      style={{ color: '#999' }}
                      onClick={this.nextStep}
                    >
                      Suivant
                    </button>
                  )}{' '}
                  <button
                    className="ft-500 btn btn--white btn--edit"
                    style={{ color: '#999', marginLeft: inputs.length > step + 1 ? '16px' : '0px' }}
                    onClick={this.finishLater}
                  >
                    Plus tard
                  </button>
                </>
              )}
            </div>
            <button
              className="btn btn--primary js-profile-save"
              type="button"
              onClick={this.handleSubmit}
              disabled={error}
            >
              Valider
            </button>
          </div>
        </div>
      </form>
    );
  };

  /**
   * Save not a licensee from the user action into local storage and change state
   */
  handleNotLicensedStep = () => {
    localStorage.setItem('no-license-from-user', 'true');
    this.setState({
      isNotLicensedFromLocalStorage: 'true'
    });
  };

  /**
   * Save not a licensee from the user action into local storage and change state
   */
  handleLicensedStep = () => {
    localStorage.setItem('license-from-user', 'true');
    this.setState({
      isLicensedFromLocalStorage: 'true'
    });
  };

  renderLicencieStep = () => {
    return (
      <div className="license-step">
        <button className="btn btn--license-step" type="button" onClick={this.handleLicensedStep}>
          <i className="icon icon-shirt is-inline"></i>
          Je suis licencié(e) à la FFR
        </button>
        <button className="btn btn--license-step" type="button" onClick={this.handleNotLicensedStep}>
          <i className="icon icon-account is-inline"></i>
          Je ne suis pas licencié(e) à la FFR
        </button>
      </div>
    );
  };

  handleOvaleAccountNoAssociation = () => {
    localStorage.setItem('no-association-to-ovale-from-user', 'true');
    this.setState({
      isNotAssociatedToOvaleFromLocalStorage: 'true'
    });
  };

  renderOvaleAccountAssociationStep = () => {
    const {
      userPref: { token, url_lier_ovale }
    } = this.props;

    /**
     * Pour créer l'url pour l'association du compte Oval-e
     * Adaptation de https://gist.github.com/kukat/49f2a53b6d74140fc28d2ef8fb6f44ae
     */
    let hash = '';
    let nonce = '';
    if (token) {
      const provider = 'ovale';
      const token_final = jwt.decode(token);
      nonce = Date.now();
      var input = '' + nonce + token_final.session_state + token_final.azp + provider;
      var inputArrayBuffer = new TextEncoder().encode(input);
      var check = sha256.array(inputArrayBuffer);
      var test = String.fromCharCode.apply(null, new Uint8Array(check));
      var base64string = window.btoa(test);
      hash = base64string.replace(/\+/g, '-').replace(/\//g, '_');
    }

    return (
      <div className="ovale-association">
        <div className="ovale-association__description">
          <h3 className="ft-h5">Fusionnez votre espace perso avec votre compte Oval-e</h3>
          <div className="card__body card__desc">
            <p>
              Fusionnez tous vos comptes de la Fédération avec le seul compte oval-e pour pouvoir accéder à tout
              l&apos;écosystème FFR d&apos;un seul compte
            </p>
          </div>
        </div>
        <div className="ovale-association__cta">
          <a
            className="btn btn--primary"
            href={url_lier_ovale + '&nonce=' + nonce + '&hash=' + hash}
            rel="noopener noreferrer"
          >
            Fusionner mes comptes
          </a>
          <button className="btn btn--white" type="button" onClick={this.handleOvaleAccountNoAssociation}>
            Peut-être plus tard
          </button>
        </div>
      </div>
    );
  };

  handleCloseCompletedProfile = () => {
    localStorage.setItem('completed-profile-block-closed', 'true');
    this.setState({
      isCompletedProfileBlockClosedFromLocalStorage: 'true'
    });
  };

  renderCompletedProfileBlock = () => {
    return (
      <div className="completed-profile">
        <div className="completed-profile__body">
          <div className="completed-profile__left">
            <div className="completed-profile__checked">
              <i className="icon icon-check is-inline"></i>
            </div>
            <div className="completed-profile__description">
              <h3 className="ft-h5 ft-up">Votre profil est complété à 100%</h3>
              <div className="card__body card__desc">
                <p>
                  Vous pouvez désormais accéder à vos statistiques en tant que joueur si vous êtes licencié, à des
                  offres exclusives dans la boutique et la billetterie et aux actualités de vos joueurs favoris
                </p>
              </div>
            </div>
          </div>
          <div className="completed-profile__cta">
            <NavLink
              activeClassName="active"
              className="btn btn--primary"
              to="/tableau-de-bord/mes-favoris"
              title="mes favoris"
            >
              Ajouter des joueurs favoris
            </NavLink>
          </div>
        </div>
        <div className="completed-profile__close">
          <button onClick={this.handleCloseCompletedProfile}>
            <i className="icon icon-close is-inline"></i>
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      profileLater,
      status,
      userPref: { joueurs_favoris, licence }
    } = this.props;
    const {
      isCompleted,
      isCompletedProfileBlockClosedFromLocalStorage,
      isLicensedFromLocalStorage,
      isNotAssociatedToOvaleFromLocalStorage,
      isNotLicensedFromLocalStorage,
      profileCompletionRate
    } = this.state;

    if (isCompleted || status !== STATUS_SUCCESS || (profileLater && profileLater > Date.now())) {
      return null;
    }

    const percent = profileCompletionRate;
    const degrees = (360 * percent) / 100;

    const class_slice = degrees > 180 ? 'slice sup180' : 'slice';
    const styles_fill = {
      transform: degrees > 180 ? 'rotate(180deg)' : 'rotate(0deg)'
    };

    return (
      <div className="box box--white card card--profile card--form mb-7">
        {// if the profile is completed we won't display this header
        percent !== 100 && (
          <div className="card__header profile-completed">
            <h3 className="ft-h4 ft-up">Complétez votre profil</h3>
            <div className="result">
              <span className="ft-700 percent">{String(percent) + '%'}</span>
              <div className="graph">
                <div className={class_slice}>
                  <div className="bar" style={{ transform: 'rotate(' + String(degrees) + 'deg)' }}></div>
                  <div className="fill" style={styles_fill}></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {// if the user doesn't have a license and has neither indicated that he was nor wasn't a licensee
        // we'll want to display the first step to registration
        !licence &&
          isNotLicensedFromLocalStorage === 'false' &&
          isLicensedFromLocalStorage === 'false' &&
          this.renderLicencieStep()}
        {// if the user has indicated he is a licensee but has not yet associated his oval-e account
        // then we'll ask him to do so
        !licence &&
          isLicensedFromLocalStorage === 'true' &&
          isNotAssociatedToOvaleFromLocalStorage === 'false' &&
          this.renderOvaleAccountAssociationStep()}
        {// if the user is a licensee or that he has indicated that he wasn't a licensee  or that he has indicated that he wants to associate his oval-e account later
        // and that his profile is not completed
        // then we can display the profile to complete
        (licence || isNotLicensedFromLocalStorage === 'true' || isNotAssociatedToOvaleFromLocalStorage === 'true') &&
          percent !== 100 &&
          this.renderProfileToComplete()}
        {// if the user has completed his profile and has no favorite player
        // and has not closed the completed profile block yet
        // then we'll want to inform him
        percent === 100 &&
          joueurs_favoris.length < 1 &&
          isCompletedProfileBlockClosedFromLocalStorage === 'false' &&
          this.renderCompletedProfileBlock()}
      </div>
    );
  }
}

export default FormProfil;
