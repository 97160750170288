// @flow

import type { RencontreType } from 'types/Rencontre';

export const FETCH_RENCONTRE_START = 'FETCH_RENCONTRE_START';
export const INITAL_FETCH_RENCONTRE_BY_FILTERS_START = 'INITAL_FETCH_RENCONTRE_BY_FILTERS_START';
export const FETCH_RENCONTRE_BY_FILTERS_START = 'FETCH_RENCONTRE_BY_FILTERS_START';
export const FETCH_RENCONTRE_BY_FILTERS_SUCCESS = 'FETCH_RENCONTRE_BY_FILTERS_SUCCESS';
export const FETCH_RENCONTRE_SUCCESS = 'FETCH_RENCONTRE_SUCCESS';
export const FETCH_RENCONTRE_FAILURE = 'FETCH_RENCONTRE_FAILURE';

export type FETCH_RENCONTRE_START_ACTION = {
  type: 'FETCH_RENCONTRE_START',
  payload: {
    slug: string
  }
};

export type FETCH_RENCONTRE_BY_FILTERS_START_ACTION = {
  type: 'FETCH_RENCONTRE_BY_FILTERS_START',
  payload: {
    filters: Object
  }
};

export type INITAL_FETCH_RENCONTRE_BY_FILTERS_START_ACTION = {
  type: 'INITAL_FETCH_RENCONTRE_BY_FILTERS_START',
  payload: {
    filters: Object,
  }
};

export type FETCH_RENCONTRE_FAILURE_ACTION = {
  type: 'FETCH_RENCONTRE_FAILURE'
};

export type FETCH_RENCONTRE_SUCCESS_ACTION = {
  type: 'FETCH_RENCONTRE_SUCCESS',
  payload: {
    rencontre : RencontreType,
  },
};

export type FETCH_RENCONTRE_BY_FILTERS_SUCCESS_ACTION = {
  type: 'FETCH_RENCONTRE_BY_FILTERS_SUCCESS',
  payload: {
    rencontres : Array<RencontreType>,
  },
}

export type Action = 
  | FETCH_RENCONTRE_START_ACTION 
  | FETCH_RENCONTRE_FAILURE_ACTION 
  | FETCH_RENCONTRE_SUCCESS_ACTION
  | FETCH_RENCONTRE_BY_FILTERS_START_ACTION
  | INITAL_FETCH_RENCONTRE_BY_FILTERS_START_ACTION
  | FETCH_RENCONTRE_BY_FILTERS_SUCCESS_ACTION;

export function fetchRencontre(slug: string): FETCH_RENCONTRE_START_ACTION {  
  return {
    type: FETCH_RENCONTRE_START,
    payload: { slug }
  };
};

export function fetchRencontreByFilters(filters: Object): FETCH_RENCONTRE_BY_FILTERS_START_ACTION {  
  return {
    type: FETCH_RENCONTRE_BY_FILTERS_START,
    payload: { filters }
  };
};

export function InitialFetchRencontreByFilters(filters: Object): INITAL_FETCH_RENCONTRE_BY_FILTERS_START_ACTION {  
  return {
    type: INITAL_FETCH_RENCONTRE_BY_FILTERS_START,
    payload: { filters }
  };
};

export function rencontreFetched(rencontre: RencontreType): FETCH_RENCONTRE_SUCCESS_ACTION {  
  return {
    type: FETCH_RENCONTRE_SUCCESS,
    payload: { rencontre }
  };
};

export function rencontreByFiltersFetched(rencontres: Array<RencontreType>): 
  FETCH_RENCONTRE_BY_FILTERS_SUCCESS_ACTION {  
  return {
    type: FETCH_RENCONTRE_BY_FILTERS_SUCCESS,
    payload: { rencontres }
  };
};

export function rencontreFetchError(error: any): FETCH_RENCONTRE_FAILURE_ACTION {
  return {
    type: FETCH_RENCONTRE_FAILURE
  };
};
