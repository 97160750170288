// @flow

import type { RawYoutubeVideo, YoutubeVideoPlayer } from 'types/YoutubeVideo';
import type { RawYoutubeVideoChannelPlayer, RawYoutubeVideoPlaylistPlayer } from 'types/YoutubeVideo';
import type { RawYoutubePlaylistChannel, RawYoutubePlaylistId } from 'types/YoutubePlaylist';
import type { YoutubeVideo } from 'types/YoutubeVideo';
import { nullYoutubeVideoPlayer } from 'types/YoutubeVideo';
import { nullYoutubeVideo } from 'types/YoutubeVideo';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertVideoPlayerFromApi(rawYoutubeVideo: RawYoutubeVideo): YoutubeVideoPlayer {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return nullYoutubeVideoPlayer;
  }

  const video = rawYoutubeVideo.items[0];

  const {
    id,
    snippet: { title, description, channelId, channelTitle, tags }
  } = video;

  return mergeIntoDefault(nullYoutubeVideoPlayer, {
    id,
    title,
    description,
    channelId,
    channelTitle,
    tags
  });
}

export function convertVideoPlayerIdChannelFromApi(rawYoutubeVideo: RawYoutubeVideoChannelPlayer): Array<string> {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return [];
  }

  const videos = rawYoutubeVideo.items;

  const videosId = videos.map(video => video.id.videoId);

  return videosId;
}

export function convertVideoPlayerIdPlaylistFromApi(
  rawYoutubeVideo: RawYoutubeVideoPlaylistPlayer,
  idVideo: string
): Array<string> {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return [];
  }
  const videos = rawYoutubeVideo.items;
  let videoFilter = videos.filter(video => video.snippet.resourceId.videoId !== idVideo);
  if (videoFilter.length !== 3) videoFilter = videos.slice(0, 3).concat(videos.slice(4, videos.length));
  const videosId = videoFilter.map(video => video.snippet.resourceId.videoId);

  return videosId;
}

export function convertVideoNameFromApi(response: RawYoutubeVideo): string {
  const videoName = response.items[0].snippet.localized.title;
  return videoName;
}

export function convertPlaylistIdFromApi(response: RawYoutubePlaylistId): ?string {
  const playlistId = response.items[0].id.playlistId;
  return playlistId;
}

export function convertRandomVideosFromApi(response: RawYoutubePlaylistChannel, idVideo: string): Array<YoutubeVideo> {
  const videos = response.items.filter(video => video.snippet.resourceId.videoId !== idVideo);
  const randomIndex = getRandomInts(videos.length - 1, 3);
  const randomVideos = randomIndex.map(index => videos[index].snippet);
  return randomVideos.map(video =>
    mergeIntoDefault(nullYoutubeVideo, {
      id: video.resourceId.videoId,
      url: video.thumbnails.medium.url,
      ...video
    })
  );
}

function getRandomInts(max: number, num: number): Array<number> {
  let ints = [];
  while (ints.length < num) {
    let randNum = Math.floor(Math.random() * Math.floor(max));
    if (ints.indexOf(randNum) === -1) {
      ints.push(randNum);
    }
  }
  return ints;
}
