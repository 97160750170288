//@flow

import type { UserFavsType, UserFidType, RawUserFavsType, FullUserType, RawFullUserType } from 'types/User';
import { nullSaleforce, nullUserFavs, nullUserFid, nullUserPrefs } from 'types/User';
import { mergeIntoDefault } from 'utils/objectUtils';
import { convertMatchEventFromApi } from 'services/Event';
import { convertArticleFromApi } from 'services/Article';

export function convertUserFavsFromApi(userFavs: RawUserFavsType): UserFavsType {
  if (!userFavs) {
    return nullUserFavs;
  }

  return mergeIntoDefault(nullUserFavs, { ...userFavs });
}

export function convertUserFidFromApi(userFid: UserFidType): UserFidType {
  if (!userFid) {
    return nullUserFid;
  }

  return mergeIntoDefault(nullUserFid, { ...userFid });
}

export function convertUserPrefFromApi(user: RawFullUserType): FullUserType {
  if (!user) {
    return nullUserPrefs;
  }

  const {
    alertes_billetterie_detail: events,
    dashboard_profile_list,
    data_salesforce,
    my_boutique_profile_list,
    newsletter_feed: feed
  } = user;

  const newsletter_feed = feed && feed.length > 0 && feed.map(article => convertArticleFromApi(article));
  const alertes_billetterie_detail =
    events &&
    events.length > 0 &&
    events.map(event => ({ ...convertMatchEventFromApi(event), title: event && event.title }));

  return mergeIntoDefault(nullUserPrefs, {
    ...user,
    newsletter_feed,
    alertes_billetterie_detail,
    saleforce: { ...mergeIntoDefault(nullSaleforce, data_salesforce[0]) },
    dashboard_profile_list: dashboard_profile_list && !!dashboard_profile_list.length ? 
      dashboard_profile_list.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)) : [],
    my_boutique_profile_list: my_boutique_profile_list && !!my_boutique_profile_list.length ? 
      my_boutique_profile_list.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)) : [],
  });
}
