// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START = 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START';
export const FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS = 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS';
export const FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE = 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE';

export type FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START',
  payload: { idPlaylist: string, nextPageToken: string }
};

export type FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE'
};

export type FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION = {
  type: 'FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>,
    nextPageToken: string
  }
};

export type Action =
  | FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION
  | FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION
  | FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION;

export function fetchMoreYoutubeVideosPlaylist(
  idPlaylist: string,
  nextPageToken: string
): FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_START,
    payload: { idPlaylist, nextPageToken }
  };
}

export function moreVideosPlaylistFetched(
  videos: Array<YoutubeVideo>,
  nextPageToken: string
): FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS,
    payload: { videos, nextPageToken }
  };
}

export function moreVideosPlaylistFetchError(): FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION {
  return {
    type: FETCH_MORE_YOUTUBE_VIDEOS_PLAYLIST_FAILURE
  };
}
