// @flow

import React from 'react';
import type { MatchStatsProps } from 'components/rencontre/MatchStats';

export const doesLocalTeamWon = (
  rencontre: { france_score: number, adversaire_score: number },
  francePlaysAtHome: boolean
) => {
  const { france_score, adversaire_score } = rencontre;
  const className = 'ft-700';
  if (francePlaysAtHome && france_score > adversaire_score) {
    return className;
  } else if (adversaire_score === france_score) {
    return '';
  } else if (!francePlaysAtHome && adversaire_score > france_score) {
    return className;
  }
  return '';
};

export const doesChallengerTeamWon = (
  rencontre: { france_score: number, adversaire_score: number },
  francePlaysAtHome: boolean
) => {
  const { france_score, adversaire_score } = rencontre;
  const className = 'ft-700';
  if (francePlaysAtHome && france_score > adversaire_score) {
    return '';
  } else if (!francePlaysAtHome && adversaire_score > france_score) {
    return '';
  } else if (adversaire_score === france_score) {
    return '';
  }
  return className;
};

export const getFaitsDeJeuType = (props: MatchStatsProps) => {
  const {
    faits_de_jeu,
    meta: {
      adversaire_nb_essais,
      adversaire_nb_drops,
      adversaire_nb_cartons_jaunes,
      adversaire_nb_cartons_rouges,
      adversaire_nb_penalites,
      adversaire_nb_transformations,
      france_nb_essais,
      france_nb_drops,
      france_nb_cartons_jaunes,
      france_nb_cartons_rouges,
      france_nb_penalites,
      france_nb_transformations
    }
  } = props.rencontreData;
  let typeOfFait = [];
  faits_de_jeu.forEach(faitDeJeu => {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === faitDeJeu.quoi);
    if (isFaitTypeExists === -1) {
      typeOfFait.push(faitDeJeu.quoi);
    }
  });

  if ((adversaire_nb_essais && adversaire_nb_essais > 0) || (france_nb_essais && france_nb_essais > 0)) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'essai');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('essai');
    }
  }

  if ((adversaire_nb_penalites && adversaire_nb_penalites > 0) || (france_nb_penalites && france_nb_penalites > 0)) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'penalite');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('penalite');
    }
  }

  if (
    (adversaire_nb_transformations && adversaire_nb_transformations > 0) ||
    (france_nb_transformations && france_nb_transformations > 0)
  ) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'transformation');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('transformation');
    }
  }

  if ((adversaire_nb_drops && adversaire_nb_drops > 0) || (france_nb_drops && france_nb_drops > 0)) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'drop');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('drop');
    }
  }

  if (
    (adversaire_nb_cartons_jaunes && adversaire_nb_cartons_jaunes > 0) ||
    (france_nb_cartons_jaunes && france_nb_cartons_jaunes > 0)
  ) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'carton_jaune');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('carton_jaune');
    }
  }

  if (
    (adversaire_nb_cartons_rouges && adversaire_nb_cartons_rouges > 0) ||
    (france_nb_cartons_rouges && france_nb_cartons_rouges > 0)
  ) {
    const isFaitTypeExists = typeOfFait.findIndex(fait => fait === 'carton_rouge');
    if (isFaitTypeExists === -1) {
      typeOfFait.push('carton_rouge');
    }
  }

  return { typeOfFait };
};

export const reorderDateFormat = (date: string) => {
  return date
    .substring(0, 10)
    .split('-')
    .reverse()
    .join('/');
};

export const getRencontresDateFormat = (date: string) => {
  let newDate = date.substring(0, 10).split('-');
  const month = newDate[1];
  let slicedMonth;
  switch (month) {
    case '01':
      slicedMonth = 'Jan.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '02':
      slicedMonth = 'Fev.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '03':
      slicedMonth = 'Mars';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '04':
      slicedMonth = 'Avr.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '05':
      slicedMonth = 'Mai';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '06':
      slicedMonth = 'Juin';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '07':
      slicedMonth = 'Jul.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '08':
      slicedMonth = 'Août';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '09':
      slicedMonth = 'Sep.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '10':
      slicedMonth = 'Oct.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '11':
      slicedMonth = 'Nov.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    case '12':
      slicedMonth = 'Dec.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return newDate.join(' ');
    default:
      break;
  }
  return false;
};

export const getHeaderDateFormat = (date: string) => {
  let newDate = date.substring(0, 10).split('-');
  const hours = date.substring(11, 16);
  const month = newDate[1];
  let slicedMonth;
  switch (month) {
    case '01':
      slicedMonth = 'Jan.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '02':
      slicedMonth = 'Fev.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '03':
      slicedMonth = 'Mars';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '04':
      slicedMonth = 'Avr.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '05':
      slicedMonth = 'Mai';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '06':
      slicedMonth = 'Juin';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '07':
      slicedMonth = 'Jul.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '08':
      slicedMonth = 'Août';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '09':
      slicedMonth = 'Sep.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '10':
      slicedMonth = 'Oct.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '11':
      slicedMonth = 'Nov.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    case '12':
      slicedMonth = 'Dec.';
      newDate.reverse().splice(1, 1, slicedMonth);
      return (
        <p className="ft-h2 ft-up ft-center mb-0">
          {newDate.join(' ')}
          <br />
          {hours}
        </p>
      );
    default:
      break;
  }
  return false;
};

export const getIconClassName = (faitType: string) => {
  switch (faitType) {
    case 'essai':
      return 'icon icon-ball';
    case 'transformation':
      return 'icon icon-cage';
    case 'penalite':
      return 'icon icon-cage';
    case 'drop':
      return 'icon icon-drop';
    case 'carton_rouge':
      return 'icon icon-red-card';
    case 'carton_jaune':
      return 'icon icon-yellow-card';
    case 'remplacement':
      return 'icon-sub';
    default:
      return false;
  }
};

export const getWinnerTeamBolded = (localScore: number, visitorScore: number) => {
  return {
    localClass: localScore > visitorScore ? 'ft-700' : '',
    visitorClass: visitorScore > localScore ? 'ft-700' : ''
  };
};
