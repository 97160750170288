// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function ModalSuccessModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-valid ">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <div className="modal__body modal__body--medium narrow-2">
          <div className="circle-icon circle-icon--check" />
          <h3 className="mb-1 mt-2 ft-h2">{content && content.title ? content.title : 'Demande enregistrée'}</h3>
          <span>{content && content.text ? content.text : 'Mise à jour réussie'}</span>
        </div>
      </div>
    </div>
  );
}

export default ModalSuccessModalContent;
