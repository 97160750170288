// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_YOUTUBE_LIVE_START = 'FETCH_YOUTUBE_LIVE_START';
export const FETCH_YOUTUBE_LIVE_SUCCESS = 'FETCH_YOUTUBE_LIVE_SUCCESS';
export const FETCH_YOUTUBE_LIVE_FAILURE = 'FETCH_YOUTUBE_LIVE_FAILURE';

export type FETCH_YOUTUBE_LIVE_START_ACTION = {
  type: 'FETCH_YOUTUBE_LIVE_START'
};

export type FETCH_YOUTUBE_LIVE_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_LIVE_FAILURE'
};

export type FETCH_YOUTUBE_LIVE_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_LIVE_SUCCESS',
  payload: {
    video: ?YoutubeVideo
  }
};

export type Action =
  | FETCH_YOUTUBE_LIVE_START_ACTION
  | FETCH_YOUTUBE_LIVE_FAILURE_ACTION
  | FETCH_YOUTUBE_LIVE_SUCCESS_ACTION;

export function fetchLiveVideo(): FETCH_YOUTUBE_LIVE_START_ACTION {
  return {
    type: FETCH_YOUTUBE_LIVE_START
  };
}

export function liveVideoFetched(video: ?YoutubeVideo): FETCH_YOUTUBE_LIVE_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_LIVE_SUCCESS,
    payload: { video }
  };
}

export function liveVideoFetchError(error: any): FETCH_YOUTUBE_LIVE_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_LIVE_FAILURE
  };
}
