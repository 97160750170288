// @flow
import React, { PureComponent } from 'react';
import FFRtvCardNavigator from 'components/FFRtv/FFRtvCardNavigator';
import { STATUS_SUCCESS } from 'constants/statusConstants';
import LazyLoad from 'components/fragments/LazyLoad';
import { getPublicAssets } from 'utils/assetsUtils';
import { Link } from 'react-router-dom';
import type { YoutubeContent } from 'types/YoutubeContent';

export type StateProps = {
  status: string,
  posts: YoutubeContent[],
  title?: string,
  titleClass?: string,
  sectionClass?: string,
  containerClass?: string,
  hideDescriptions?: boolean,
  hideTvLink?: boolean,
  hideTvLinkBottom?: boolean,
  tvLinkText?: string,
  tvLinkClass?: string,
  type?: string,
  video_fond?: string,
  image_fond?: string
};

export type DispatchProps = {
  fetchYoutubePosts: () => void
};

type Props = StateProps & DispatchProps;

class FFRtvHub extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchYoutubePosts();
  }

  render() {
    const {
      title,
      titleClass,
      sectionClass,
      containerClass,
      hideTvLink,
      hideTvLinkBottom,
      hideDescriptions,
      posts,
      status,
      tvLinkText,
      tvLinkClass,
      type,
      video_fond,
      image_fond
    } = this.props;
    let styleMade = {};
    if (hideTvLink || hideTvLinkBottom) {
      styleMade = {
        backgroundColor: '#002348',
        padding: '10px',
        height: 'auto'
      };
    }
    let classes = sectionClass ? sectionClass : 'section section-blueAlt';
    if (type) classes += ' ' + type;

    let classesContainer = containerClass ? containerClass : 'container';
    return (
      <section className={classes}>
        <LazyLoad isDarkBlue>
          <div className={classesContainer}>
            {!hideTvLink && (
              <Link
                to="/ffr-tv"
                className={tvLinkClass ? tvLinkClass : 'link-chevron link-chevron--right section__link mt-1'}
              >
                <span>{tvLinkText ? tvLinkText : `Accéder à la FFR TV`}</span>
                <i className="icon icon-chevron-right"></i>
              </Link>
            )}
            <h2 className={titleClass ? titleClass : 'mb-2 mb-lg-4'}>{title ? title : 'Dernières vidéos FFR TV'}</h2>

            <div>
              <FFRtvCardNavigator
                FFRtvCards={posts}
                type={type || 'home'}
                hideDescriptions={hideDescriptions}
                image_fond={image_fond}
                video_fond={video_fond}
                showCards={status === STATUS_SUCCESS}
              />
            </div>

            {!hideTvLink && !hideTvLinkBottom && (
              <Link
                to="/ffr-tv"
                className={tvLinkClass ? tvLinkClass : 'link-chevron link-chevron--right section__link mt-1'}
              >
                <span>{tvLinkText ? tvLinkText : `Accéder à la FFR TV`}</span>
                <i className="icon icon-chevron-right"></i>
              </Link>
            )}

            <div className="mt-3">
              {type !== 'competition' && (
                <a
                  href="https://www.youtube.com/channel/UCnH0bSmQqDBfNj9kZzCaINw"
                  target="_blank"
                  className="btn"
                  style={styleMade}
                  rel="noreferrer noopener"
                >
                  <img
                    src={getPublicAssets('/img/youtube/developed-with-youtube-small.png')}
                    height="50"
                    width="auto"
                  />
                </a>
              )}
              {type == 'competition' && (
                <a
                  href="https://www.youtube.com/channel/UCnH0bSmQqDBfNj9kZzCaINw"
                  target="_blank"
                  className="btn btn-white"
                  rel="noreferrer noopener" 
                >
                  Accéder à la FFR TV <i className="icon icon-chevron-right" />
                </a>
              )}
            </div>
          </div>
        </LazyLoad>
      </section>
    );
  }
}

export default FFRtvHub;
