// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { 
  FETCH_CENTENAIREDOCUMENTS_START,
  FETCH_CENTENAIREDOCUMENTS_SUCCESS,
  FETCH_CENTENAIREDOCUMENTS_FAILURE,
  FETCH_CENTENAIREDOCUMENT_START,
  FETCH_CENTENAIREDOCUMENT_SUCCESS,
  FETCH_CENTENAIREDOCUMENT_FAILURE,
  FETCH_CENTENAIRECLUBS_START,
  FETCH_CENTENAIRECLUBS_SUCCESS,
  FETCH_CENTENAIRECLUBS_FAILURE,
  FETCH_CENTENAIREDATAS_START,
  FETCH_CENTENAIREDATAS_SUCCESS,
  FETCH_CENTENAIREDATAS_FAILURE,
} from 'actions/centenaireActions';

import { nullCentenaireDocument } from 'types/Centenaire';
import type { CentenaireDocumentType, CentenaireClubPreviewType } from 'types/Centenaire';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  clubs: Array<CentenaireClubPreviewType>,
  annees: Array<string>,
  niveaux: Array<string>,
  equipes: Array<string>,
  villes: Array<string>,
  documents: Array<CentenaireDocumentType>,
  document: CentenaireDocumentType,
  total: number,
  status: Status,
  statusDocument: Status,
  statusClubs: Status,
  statusDatas: Status
};

const initialState: State = {
  clubs: [],
  annees: [],
  niveaux: [],
  equipes: [],
  villes: [],
  documents: [],
  document: nullCentenaireDocument,
  total: 0,
  status: STATUS_DEFAULT,
  statusDocument: STATUS_DEFAULT,
  statusClubs: STATUS_DEFAULT,
  statusDatas: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CENTENAIREDOCUMENTS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_CENTENAIREDOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.documents,
        total: action.payload.total,
        status: STATUS_SUCCESS
      };
    case FETCH_CENTENAIREDOCUMENTS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_CENTENAIREDOCUMENT_START:
      return {
        ...state,
        statusDocument: STATUS_LOADING
      };
    case FETCH_CENTENAIREDOCUMENT_SUCCESS:
      return {
        ...state,
        document: action.payload.document,
        statusDocument: STATUS_SUCCESS
      };
    case FETCH_CENTENAIREDOCUMENT_FAILURE:
      return {
        ...state,
        statusDocument: STATUS_FAILURE
      };
    case FETCH_CENTENAIRECLUBS_START:
      return {
        ...state,
        statusClubs: STATUS_LOADING
      };
    case FETCH_CENTENAIRECLUBS_SUCCESS:
      return {
        ...state,
        clubs: action.payload.clubs,
        statusClubs: STATUS_SUCCESS
      };
    case FETCH_CENTENAIRECLUBS_FAILURE:
      return {
        ...state,
        statusClubs: STATUS_FAILURE
      };
    case FETCH_CENTENAIREDATAS_START:
      return {
        ...state,
        statusDatas: STATUS_LOADING
      };
    case FETCH_CENTENAIREDATAS_SUCCESS:
      return {
        ...state,
        clubs: action.payload.clubs,
        annees: action.payload.annees,
        niveaux: action.payload.niveaux,
        equipes: action.payload.equipes,
        villes: action.payload.villes,
        statusDatas: STATUS_SUCCESS
      };
    case FETCH_CENTENAIREDATAS_FAILURE:
      return {
        ...state,
        statusDatas: STATUS_FAILURE
      };
    default:
      return state;
  }
}
