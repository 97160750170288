// @flow
export type AcademiePreviewType = {
  name: string,
  lon: number,
  lat: number,
  lycee: string,
  ligue: string,
  adresse: string,
  ville: string,
  formateur: string,
  formateur_email: string,
  formateur_telephone: string,
};

export type AcademieType =  {
  name: string,
  lat: number,
  lon: number,
  lycee: string,
  ligue: string,
  adresse: string,
  ville: string,
  formateur: string,
  formateur_email: string,
  formateur_telephone: string,
};

export const nullAcademie: AcademieType = {
  name: '',
  lat: 0,
  lon: 0,
  lycee: '',
  ligue: '',
  adresse: '',
  ville: '',
  formateur: '',
  formateur_email: '',
  formateur_telephone: '',
};
