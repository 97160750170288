// @flow

import { connect } from 'react-redux';

import { postUserPref } from 'actions/userActions';
import FormProfil from 'components/profile/FormProfil';
import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/profile/FormProfil';
import type { EditProfileValueType } from 'types/User';
import { setProfileLater } from 'actions/appActions';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref,
    status: state.userState.status,
    error: state.userState.error,
    profileLater: state.appState.profileLater
  }),
  (dispatch: DispatchType): DispatchProps => ({
    postUserPref: (action: string, value: EditProfileValueType, token: string) => dispatch(postUserPref(action, value, token)),
    setProfileLater: (profileLater: number) => dispatch(setProfileLater(profileLater))
  })
)(FormProfil);