// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';

export type SeminaireType = {
  id: number,
  title: string,
  meta_title: string,
  meta_description: string,
  slug: string,
  link: string,
  duree: string,
  prix_base: number,
  featured_media: {
    src: string,
  },
  featured_video: {
    data: Array<BlocContent>,
  },
  content: {
    data: Array<BlocContent>,
  },
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
  options: [
    {
      titre: string,
      prix: number,
    }
  ],
  dejeuner: [
    {
      titre: string,
      prix: number,
    }
  ],
};

export type RawSeminaireType = SEOMetaType & {
  id: number,
  date: string,
  title: {
    raw: string,
  },
  content: {
    data: Array<BlocContent>
  },
  slug: string,
  link: string,
  featured_media: {
    src: string,
    legend: string
  },
  meta: {
    duree: string,
    prix_base: number,
  },
  featured_video: {
    rendered: string,
    data: Array<BlocContent>,
  },
  tags_list: Array<{
    id: number,
    name: string,
    image: {
      src: string,
    },
  }>,
  options: [
    {
      titre: string,
      prix: number,
    }
  ],
  dejeuner: [
    {
      titre: string,
      prix: number,
    }
  ],
};

export const nullSeminaire: SeminaireType = {
  id: 0,
  title: '',
  slug: '',
  link: '',
  meta_title: '',
  meta_description: '',
  duree: '',
  prix_base: 0,
  featured_media: {
    src: '',
  },
  options: [
    {
      titre: '',
      prix: 0,
    }
  ],
  content: {
    data: []
  },
  dejeuner: [
    {
      titre: '',
      prix: 0,
    }
  ],
  featured_video: {
    data: []
  },
  tags_list: [{
    id: 0,
    name: '',
    image: {
      src: '',
    },
  }],
};
