// @flow
import type {
  rawContactFormResultType,
  ContactFormResultType,
  rawContactFormType,
  ContactFormType
} from 'types/ContactForm';
import { nullContactFormResult, nullContactForm } from 'types/ContactForm';
import { mergeIntoDefault } from 'utils/objectUtils';

class ErreurForm extends Error{
  invalidFields: Array<{ into: string, message: string }>;
  constructor(invalidFields, ...params) {
    // Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
    super(...params);

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErreurForm);
    }
    this.name = 'Error form';
    // Informations de déboguage personnalisées
    this.invalidFields = invalidFields;
  }
}

export function convertFormContactResponseFromApi(
  rawContactFormResult: rawContactFormResultType
): ContactFormResultType {
  if (!rawContactFormResult) {
    return nullContactFormResult;
  }

  const { into, status, message, invalidFields } = rawContactFormResult;

  if (status === 'validation_failed') {
    const error = new ErreurForm(invalidFields, 'Validation failed');
    throw error;
  }

  return mergeIntoDefault(nullContactFormResult, {
    into,
    status,
    message
  });
}

export function convertFormContactFromApi(
  rawContactForm: Array<rawContactFormType>
): ContactFormType {
  if (!rawContactForm) {
    return nullContactForm;
  }

  const { id, slug, title, locale } = rawContactForm[0];

  return mergeIntoDefault(nullContactForm, {
    id,
    slug,
    title,
    locale
  });
}

export function convertOneFormContactFromApi(
  rawContactForm: rawContactFormType
): ContactFormType {
  if (!rawContactForm) {
    return nullContactForm;
  }

  const { id, slug, title, locale } = rawContactForm;

  return mergeIntoDefault(nullContactForm, {
    id,
    slug,
    title,
    locale
  });
}
