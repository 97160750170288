// @flow

export type YoutubeVideo = {
  id: string,
  title: string,
  description: string,
  channelId: string,
  channelTitle: string,
  playlistId: string,
  playlistTitle: string,
  publishedAt: string,
  duration: string,
  url: string,
  img: string,
  tags: Array<string>
};

export const nullYoutubeVideo: YoutubeVideo = {
  id: '',
  title: '',
  description: '',
  playlistId: '',
  playlistTitle: '',
  channelId: '',
  channelTitle: '',
  publishedAt: '',
  url: '',
  img: '',
  duration: '00:00',
  tags: []
};

export type YoutubeVideoPlayer = {
  id: string,
  title: string,
  description: string,
  channelId: string,
  channelTitle: string,
  playlistId: string,
  playlistTitle: string,
  tags: Array<string>
};

export const nullYoutubeVideoPlayer: YoutubeVideoPlayer = {
  id: '',
  title: '',
  description: '',
  channelId: '',
  channelTitle: '',
  playlistId: '',
  playlistTitle: '',
  tags: []
};

export type RawYoutubeVideoPlaylistPlayer = {
  etag: string,
  items: Array<{
    etag: string,
    id: string,
    kind: string,
    snippet: {
      channelId: string,
      channelTitle: string,
      description: string,
      playlistId: string,
      position: string,
      publishedAt: string,
      resourceId: {
        kind: string,
        videoId: string
      },
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      title: string
    }
  }>,
  kind: string,
  nextPageToken: string,
  pageInfo: {
    totalResults: string,
    resultsPerPage: string
  }
};

export type RawYoutubeVideoChannelPlayer = {
  kind: string,
  etag: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: string,
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      tags: Array<string>,
      categoryId: string,
      liveBroadcastContent: string,
      localized: {
        title: string,
        description: string
      },
      defaultAudioLanguage: string
    },
    id: {
      kind: string,
      videoId: string
    }
  }>
};

export type RawYoutubeVideo = {
  kind: string,
  etag: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: string,
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      tags: Array<string>,
      categoryId: string,
      liveBroadcastContent: string,
      localized: {
        title: string,
        description: string
      },
      defaultAudioLanguage: string
    },
    contentDetails: {
      duration: string,
      dimension: string,
      definition: string,
      caption: string,
      licensedContent: boolean,
      projection: string
    }
  }>
};

export type RawYoutubeSpotlightType = Array<{
  featured_media: {
    src: string
  },
  youtube_video: {
    kind: string,
    etag: string,
    id: string,
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        },
        standard: {
          url: string,
          width: number,
          height: number
        },
        maxres: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      tags: Array<string>,
      categoryId: string,
      liveBroadcastContent: string,
      localized: {
        title: string,
        description: string
      },
      defaultAudioLanguage: string
    },
    contentDetails: {
      duration: string,
      dimension: string,
      definition: string,
      caption: string,
      licensedContent: boolean,
      projection: string
    }
  }
}>;
