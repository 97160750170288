// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import MenuDashboard from 'components/header/MenuDashboard';
import type { StateProps } from 'components/header/MenuDashboard';
import type { StateType } from 'types/Actions';

export default withRouter(
  connect((state: StateType): StateProps => ({
    userFid: state.userState.userFid,
    userFidStatus: state.userState.userFidStatus,
    userPref: state.userState.userPref,
    userPrefStatus: state.userState.status,
    menuElements: state.menuState.quickAccess
  }))(MenuDashboard)
);
