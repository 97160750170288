// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import EventApi from 'api/events/EventApi';
import SeasonsApi from 'api/seasons/SeasonsApi';
import { setDefaultSeason } from 'actions/filterOptionsActions';
import { convertSeasonToDate } from 'utils/dateUtils';

import {
  FETCH_EVENT_START,
  eventFetched,
  eventFetchError
} from 'actions/eventActions';
import type { FETCH_EVENT_START_ACTION } from 'actions/eventActions';
import {
  seasonsFetched,
} from 'actions/seasonsActions';
import {
  FETCH_EVENTS_PREVIEW_START,
  eventsPreviewFetched,
  eventsPreviewFetchError
} from 'actions/eventsPreviewActions';
import type { FETCH_EVENTS_PREVIEW_START_ACTION } from 'actions/eventsPreviewActions';

import { 
  FETCH_EVENTS_START,
  FETCH_EVENTS_BY_SEASON_START,
  INITIAL_FETCH_EVENTS_BY_SEASON_START,
  eventsFetched,
  eventsFetchError
} from 'actions/eventsActions';
import type { 
  FETCH_EVENTS_START_ACTION,
  FETCH_EVENTS_BY_SEASON_START_ACTION,
  INITIAL_FETCH_EVENTS_BY_SEASON_START_ACTION
} from 'actions/eventsActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const eventsApi = new EventApi(apiExecutor);
  const seasonsApi = new SeasonsApi(staticApiExecutor);

  return function* eventsSaga(): GeneratorType {
    yield takeLatest(FETCH_EVENT_START, fetchEvent);
    yield takeLatest(FETCH_EVENTS_PREVIEW_START, fetchEventsPreview);
    yield takeLatest(FETCH_EVENTS_START, fetchEvents);
    yield takeLatest(FETCH_EVENTS_BY_SEASON_START, fetchEventsByTeamSeason);
    yield takeLatest(INITIAL_FETCH_EVENTS_BY_SEASON_START, initialFetchEventsByTeamSeason);
  };

  function* fetchEventsPreview(action: FETCH_EVENTS_PREVIEW_START_ACTION): Saga<void> {
    try {
      const events = yield call(eventsApi.fetchEventsPreview);
      yield put(eventsPreviewFetched(events));
    } catch (e) {
      yield put(eventsPreviewFetchError());
    }
  }

  function* initialFetchEventsByTeamSeason(action: INITIAL_FETCH_EVENTS_BY_SEASON_START_ACTION): Saga<void> {
    try {
      const { season, equipe, equipeTitle } = action.payload;
      const seasons = yield call(seasonsApi.getSeasonsList, equipe);
      const seasonsRencontres = seasons.filter((season) => !!season.rencontres.length);
      let defaultSeason = seasonsRencontres[seasonsRencontres.length - 1].name;
      let seasonId = season ? season : seasonsRencontres[seasonsRencontres.length - 1].id;
      if (season && season[0]) {
        const saisonFilter = seasonsRencontres.filter(saison => saison.id === season[0]);
        if (saisonFilter && saisonFilter[0]) {
          seasonId = saisonFilter[0].id;
          defaultSeason = saisonFilter[0].name;
        } else {
          seasonId = seasonsRencontres[seasonsRencontres.length - 1].id;
        }
      }

      const saison = seasons.filter((season) => season.id === seasonId);
      const { startDate, endDate } = convertSeasonToDate(saison[0].name);

      yield put(setDefaultSeason(defaultSeason));
      yield put(seasonsFetched(seasons));
      const events = yield call(
        eventsApi.fetchEventsByTeamSeason, 
        { equipe, equipeTitle, season : seasonId, startDate:startDate, endDate:endDate }
      );
      yield put(eventsFetched(events));
    } catch (e) {
      yield put(eventsFetchError());
    }
  }

  function* fetchEventsByTeamSeason(action: FETCH_EVENTS_BY_SEASON_START_ACTION): Saga<void> {
    try {
      const filters = action.payload;
      const events = yield call(eventsApi.fetchEventsByTeamSeason, filters);
      yield put(eventsFetched(events));
    } catch (e) {
      yield put(eventsFetchError());
    }
  }

  function* fetchEvents(action: FETCH_EVENTS_START_ACTION): Saga<void> {
    try {
      const { from, to } = action.payload;
      const events = yield call(eventsApi.fetchEvents, from, to);
      yield put(eventsFetched(events));
    } catch (e) {
      yield put(eventsFetchError());
    }
  }

  function* fetchEvent(action: FETCH_EVENT_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const events = yield call(eventsApi.fetchEvent, slug);
      yield put(eventFetched(events, true));
    } catch (e) {
      yield put(eventFetchError());
    }
  }
}
