// @flow
import React, { PureComponent } from 'react';
import type { NewsletterSubscriptionType, NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import type { Status } from 'types/Status';
import { STATUS_DEFAULT } from 'constants/statusConstants';
import SelectInput from 'components/fragments/input/SelectInput';
import TextInput from 'components/fragments/input/TextInput';
import CheckboxInput from 'components/fragments/input/CheckboxInput';

import { validateEmail } from 'utils/validatorUtils';

export type StateProps = {
  options: NewsletterSubscriptionType,
  status: Status,
  newsletterSetting: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  titleLigue: string
};

export type DispatchProps = {
  fetchOptions: (campaignMonitorKey: string) => void,
  subscribeNewsletter: (
    subscription: NewsletterSubscriptionEntryType,
    campaignMonitorKey: string,
    typeNewsletter: string | boolean
  ) => void,
  displayModal: (modalObject: string, content: Object, force: boolean) => void
};

type Props = {
  isContentBloc?: boolean,
  mode?: string
} & StateProps &
  DispatchProps;

type State = {
  profil: null | string,
  email: null | string,
  emailValide: boolean,
  consentOpt1: boolean,
  consentOpt2: boolean
};

class NewsletterSubscriber extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    profil: null,
    email: null,
    emailValide: false,
    consentOpt1: false,
    consentOpt2: false
  };

  componentDidMount() {
    const { newsletterSetting, fetchOptions } = this.props;

    if (newsletterSetting.type === 'campaign_monitor') {
      fetchOptions(newsletterSetting.key || '');
    }
  }

  componentDidUpdate() {
    if (this.props.newsletterSetting.type === 'campaign_monitor' && this.props.status === STATUS_DEFAULT)
      this.props.fetchOptions(this.props.newsletterSetting.key || '');
  }

  setProfil = (profil: string) => {
    this.setState({ profil });
  };

  setEmail = (email: string) => {
    if (validateEmail(email)) {
      this.setState({
        email,
        emailValide: true
      });
    } else {
      this.setState({ emailValide: false });
    }
  };

  setConsent = (value: boolean, checkboxType: string) => {
    this.setState({ [checkboxType]: value });
  };

  handleSubmitClicked = (e: MouseEvent) => {
    const { email, profil, consentOpt1, consentOpt2 } = this.state;
    const {
      subscribeNewsletter,
      newsletterSetting: { key, type },
      displayModal
    } = this.props;
    const campaignMonitorKey = !key ? '' : key;

    e.preventDefault();
    if (profil === null || email === null) {
      this.setState({
        profil: profil === null ? '' : profil,
        email: email === null ? '' : email
      });
    } else if (!consentOpt1) {
      displayModal('MODAL_NEWSLETTER_MISSING_OPT', {}, true);
    } else if (profil !== '' && validateEmail(email)) {
      const { email, profil } = this.state;

      const options = {
        ConsentToTrack: consentOpt1 ? 'yes' : 'no',
        CustomFields: [
          {
            Key: 'Jesuis',
            Value: profil
          },
          {
            Key: 'Optin1',
            Value: consentOpt1 ? 'yes' : 'no'
          },
          {
            Key: 'Optin2',
            Value: consentOpt2 ? 'yes' : 'no'
          }
        ],
        EmailAddress: email,
        Name: '',
        action: 'subscribe'
      };
      subscribeNewsletter(options, campaignMonitorKey, type);
      //displayModal('MODAL_CUSTOMIZE_NEWSLETTER', { options, campaignMonitorKey }, true);
      displayModal('MODAL_NEWSLETTER_SUCCESS', { options, campaignMonitorKey }, true);
    }
  };

  renderLinkButton = () => {
    const { titleLigue } = this.props;
    return (
      <div className="col col-lg-9">
        <h5 className="mb-1">Newsletter</h5>
        <p className="mb-2">Recevez par email les nouveautés de la {titleLigue} en fonction de votre profil</p>
        <a
          title={`S'inscrire à la newsletter de la ${titleLigue}`}
          className="btn btn--primary"
          href={this.props.newsletterSetting.lien || '#'}
        >
          {"S'inscrire"}
        </a>
      </div>
    );
  };

  render() {
    const {
      isContentBloc,
      titleLigue,
      options: { options },
      newsletterSetting: { type },
      status,
      mode
    } = this.props;

    const { emailValide, email, profil } = this.state;
    const labels = {
      optin1: `J’autorise la FFR à utiliser mes données personnelles 
      (e-mail, adresse postale) à des fins promotionnelles 
      ou commerciales pour ses propres activités (billetterie, boutique officielle).`,
      optin2: `J’autorise la FFR à transmettre certaines de mes données personnelles 
      (nom, prénom, e-mail, adresse postale) à des tiers, et notamment ses 
      partenaires et fournisseurs officiels, à des fins commerciales, 
      associatives ou humanitaires.`
    };

    if (type === false || (type === 'campaign_monitor' && status === STATUS_DEFAULT)) return null;
    if (type === 'lien') return this.renderLinkButton();
    else {
      if (options.length === 0 && type !== 'salesforce') {
        return null;
      }
      let jesuis = [];
      if (type === 'campaign_monitor') {
        jesuis = options;
      } else if (type === 'salesforce') {
        jesuis = ['Licencié', 'Dirigeant de club', 'Bénévole', 'Autre'];
      }
      if (mode === 'sidebar' || mode === 'competition') {
        return (
          <div className="card__body">
            <h3 className="card__title">{mode === 'competition' ? `Inscrivez-vous à notre Newsletter !` : `Newsletter FFR`}</h3>
            {mode !== 'competition' &&
            <p className="card__desc">
              Recevez par email les nouveautés de la {titleLigue} en fonction de votre profil
            </p>}
            <form action="#">
              <div className="fields">
              <SelectInput
                isDark
                placeholder="Je suis..."
                options={jesuis.map(option => ({ label: option, value: option }))}
                onChange={this.setProfil}
                hasError={profil === ''}
              />
              <TextInput
                id="email"
                isDark
                placeholder={mode === 'competition' ? "Entrez votre adresse e-mail...*" : "Adresse mail *"}
                hasError={email !== null && !validateEmail(email)}
                onChange={this.setEmail}
              />
              {mode === 'sidebar' && 
              <>
              <CheckboxInput
                key={`consentOpt1${isContentBloc ? '-contentbloc' : ''}`}
                className={'checkbox'}
                id={`hgsdfsddf123${isContentBloc ? '-contentbloc' : ''}`}
                label={labels['optin1']}
                onChange={value => this.setConsent(value, 'consentOpt1')}
              />
              <CheckboxInput
                key={`consentOpt2${isContentBloc ? '-contentbloc' : ''}`}
                className={'checkbox'}
                id={`hgsdfsddf1dsds23${isContentBloc ? '-contentbloc' : ''}`}
                label={labels['optin2']}
                onChange={value => this.setConsent(value, 'consentOpt2')}
              />
              </>}
              <button
                className="btn btn--primary btn--full desktop-only"
                type="submit"
                onClick={this.handleSubmitClicked}
                disabled={!emailValide && true}
              >
                <i className={mode === 'competition' ?  "icon icon-arrow-right is-inline" : "icon icon-valid is-inline"} />
                {mode=== 'sidebar' && `Inscription`}
              </button>
              </div>
            {mode === 'competition' && 
              <>
              <CheckboxInput
                key={`consentOpt1${isContentBloc ? '-contentbloc' : ''}`}
                className={'checkbox'}
                id={`hgsdfsddf123${isContentBloc ? '-contentbloc' : ''}`}
                label={labels['optin1']}
                onChange={value => this.setConsent(value, 'consentOpt1')}
              />
              <CheckboxInput
                key={`consentOpt2${isContentBloc ? '-contentbloc' : ''}`}
                className={'checkbox'}
                id={`hgsdfsddf1dsds23${isContentBloc ? '-contentbloc' : ''}`}
                label={labels['optin2']}
                onChange={value => this.setConsent(value, 'consentOpt2')}
              />
              <button
                className="btn btn--primary btn--full mobile-only"
                type="submit"
                onClick={this.handleSubmitClicked}
                disabled={!emailValide && true}
              >
                {`S\'inscrire `}<i className={mode === 'competition' ?  "icon icon-arrow-right is-inline" : "icon icon-valid is-inline"} />
              </button>
              </>}
              </form>
          </div>
        );
      }
      return (
        <div className="col col-lg-9">
          <h5 className="mb-1">Newsletter</h5>
          <p className="mb-2">Recevez par email les nouveautés de la {titleLigue} en fonction de votre profil</p>
          <form action="#">
            <SelectInput
              isDark
              placeholder="Je suis..."
              options={jesuis.map(option => ({ label: option, value: option }))}
              onChange={this.setProfil}
              hasError={profil === ''}
            />
            <TextInput
              id="email"
              isDark
              placeholder="Adresse mail*"
              hasError={email !== null && !validateEmail(email)}
              onChange={this.setEmail}
            />
            
            <CheckboxInput
              className={'checkbox'}
              id="hgsdfsddf123"
              label={labels['optin1']}
              onChange={value => this.setConsent(value, 'consentOpt1')}
            />
            <CheckboxInput
              className={'checkbox'}
              id="hgsdfsddf1dsds23"
              label={labels['optin2']}
              onChange={value => this.setConsent(value, 'consentOpt2')}
            />
            <div></div>
            <button
              className="btn btn--primary"
              type="submit"
              onClick={this.handleSubmitClicked}
              disabled={!emailValide && true}
            >
              <i className="icon icon-valid is-inline" />
              Inscription
            </button>
          </form>
        </div>
      );
    }
  }
}

export default NewsletterSubscriber;
