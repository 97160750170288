// @flow

import type { EquipeSearchType, TeamList } from 'types/TeamList';

export const FETCH_TEAMS_START = 'FETCH_TEAMS_START';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';

export const FETCH_EQUIPESEARCH_START = 'FETCH_EQUIPESEARCH_START';
export const FETCH_EQUIPESEARCH_SUCCESS = 'FETCH_EQUIPESEARCH_SUCCESS';
export const FETCH_EQUIPESEARCH_FAILURE = 'FETCH_EQUIPESEARCH_FAILURE';


export type FETCH_TEAMS_START_ACTION = {
  type: 'FETCH_TEAMS_START'
};

export type FETCH_TEAMS_FAILURE_ACTION = {
  type: 'FETCH_TEAMS_FAILURE'
};

export type FETCH_TEAMS_SUCCESS_ACTION = {
  type: 'FETCH_TEAMS_SUCCESS',
  payload: {
    teamlist: Array<TeamList>
  }
};

export type FETCH_EQUIPESEARCH_START_ACTION = {
  type: 'FETCH_EQUIPESEARCH_START'
};

export type FETCH_EQUIPESEARCH_SUCCESS_ACTION = {
  type: 'FETCH_EQUIPESEARCH_SUCCESS',
  payload: {
    equipe_search: EquipeSearchType[],
  }
};

export type FETCH_EQUIPESEARCH_FAILURE_ACTION = {
  type: 'FETCH_EQUIPESEARCH_FAILURE'
};

export type Action =
  FETCH_TEAMS_START_ACTION
  | FETCH_TEAMS_FAILURE_ACTION
  | FETCH_TEAMS_SUCCESS_ACTION
  | FETCH_EQUIPESEARCH_START_ACTION
  | FETCH_EQUIPESEARCH_FAILURE_ACTION
  | FETCH_EQUIPESEARCH_SUCCESS_ACTION
  ;

export function fetchTeamList(): FETCH_TEAMS_START_ACTION {
  return {
    type: FETCH_TEAMS_START
  };
}

export function teamListFetched(teamlist: Array<TeamList>): FETCH_TEAMS_SUCCESS_ACTION {
  return {
    type: FETCH_TEAMS_SUCCESS,
    payload: { teamlist }
  };
}

export function teamListFetchError(): FETCH_TEAMS_FAILURE_ACTION {
  return {
    type: FETCH_TEAMS_FAILURE
  };
}

export function fetchEquipeSearch(): FETCH_EQUIPESEARCH_START_ACTION {
  return {
    type: FETCH_EQUIPESEARCH_START
  };
}

export function equipeSearchFetched(equipe_search: EquipeSearchType[]): FETCH_EQUIPESEARCH_SUCCESS_ACTION {
  return {
    type: FETCH_EQUIPESEARCH_SUCCESS,
    payload: { equipe_search }
  };
}

export function equipeSearchFetchError(): FETCH_EQUIPESEARCH_FAILURE_ACTION {
  return {
    type: FETCH_EQUIPESEARCH_FAILURE
  };
}