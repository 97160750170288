// @flow
import { convertFromApi } from 'services/CompetitionsInternationales';
import type { ApiExecutorType } from 'types/ApiExecutorType';

function CompetitionApi(executor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  function fetchCompetitionInternationale(slug: string) {
    return staticApiExecutor.get('competition_' + slug+'.json')
      .then(response => convertFromApi(response))
      .catch( error => {
        return executor.get('/ffr/v1/competitions?slug=' + slug)
          .then(response => convertFromApi(response));
      }
    );
  }

  return { fetchCompetitionInternationale };
}

export default CompetitionApi;
