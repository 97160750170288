// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { dynamicClassName } from 'utils/dynamicClassName';
import { getTimeDifference, getDateFromString } from 'utils/dateUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';
import SocialButtons from 'containers/social/SocialButtonsContainer';
import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType,
}

type Props = StateProps & {
  id: string,
  thumbnail: string,
  title: string,
  date: string,
  club: string,
  author: string,
  year: string,
  type: string,
  queryCentenaire?: string,
  mode?: string, 
};

type State = {
  shareOpen: boolean
};

class CentenaireCard extends PureComponent<Props, State> {
  props: Props;

  state = {
    shareOpen: false
  };

  toggleShare = () => {
    this.setState({ shareOpen: !this.state.shareOpen });
  };

  render() {
    const { userPref, id, thumbnail, title, date, author, year, club, type, queryCentenaire, mode } = this.props;
    const datejs = getDateFromString(date);
    const classNamesShareOpen = dynamicClassName('share share--left share--transparent');
    const url = window.location.origin+`/histoires-de-rugby/${id}`;
    this.state.shareOpen && classNamesShareOpen.add('is-open');
    return (
      <div className="box card--centenaire">
        <div className="card__social">
          <div className={classNamesShareOpen.build()}>
            <button className="btn btn--white" onClick={this.toggleShare} aria-label="Partager">
              <i className="icon icon-share" />
            </button>
            <div className="share__wrapper">
              <i className="icon icon-delete" role="button" tabIndex={0} onClick={this.toggleShare} />
              <SocialButtons url={url} title={title} />
            </div>
          </div>
        </div>
        <Link
          onClick={handleTagEvent(
            'recherche_centenaire',
            'clic_result_villes',
            queryCentenaire ? queryCentenaire : '',
            userPref
          )}
          to={{
            pathname: `/histoires-de-rugby/${id}`,
            state: {
              queryCentenaire: queryCentenaire ? queryCentenaire : '',
              mode: mode ? mode: '',
            }
          }}
          className="card__container"
          title={title}>
          {type === 'Video' &&
            <div className="card__video">
              <div className="link-icon is-dark">
              <i className="icon icon-play"></i>
              </div>
            </div>
          }
          {thumbnail &&
            <img 
              className="card__thumbnail" 
              src={thumbnail}
              alt={title} /> }
          <div className="card__club ft-h6">
            {club && 
            <span>
              <i className="icon icon-jersey"></i> {club.substring(0,25)}{club.length > 26 ? '...' : ''}
            </span>}
            <span>
              <i className="icon icon-calendar"></i> {year}
            </span>
          </div>
          <div className="card__footer ft-h6">
            <div className="card__stat">
            </div>
            <span className="ft-up">
              {`${getTimeDifference(new Date(), datejs)}`}
              {author && 
                <span> - {author}</span>}
            </span>
          </div>
        </Link>
      </div>
    );
  }
};

export default CentenaireCard;
