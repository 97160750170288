// @flow

import type { CentenaireDocumentType, CentenaireClubPreviewType } from 'types/Centenaire';

export const FETCH_CENTENAIREDOCUMENTS_START = 'FETCH_CENTENAIREDOCUMENTS_START';
export const FETCH_CENTENAIREDOCUMENTS_SUCCESS = 'FETCH_CENTENAIREDOCUMENTS_SUCCESS';
export const FETCH_CENTENAIREDOCUMENTS_FAILURE = 'FETCH_CENTENAIREDOCUMENTS_FAILURE';
export const FETCH_CENTENAIREDOCUMENT_START = 'FETCH_CENTENAIREDOCUMENT_START';
export const FETCH_CENTENAIREDOCUMENT_SUCCESS = 'FETCH_CENTENAIREDOCUMENT_SUCCESS';
export const FETCH_CENTENAIREDOCUMENT_FAILURE = 'FETCH_CENTENAIREDOCUMENT_FAILURE';
export const FETCH_CENTENAIRECLUBS_START = 'FETCH_CENTENAIRECLUBS_START';
export const FETCH_CENTENAIRECLUBS_SUCCESS = 'FETCH_CENTENAIRECLUBS_SUCCESS';
export const FETCH_CENTENAIRECLUBS_FAILURE = 'FETCH_CENTENAIRECLUBS_FAILURE';
export const FETCH_CENTENAIREDATAS_START = 'FETCH_CENTENAIREDATAS_START';
export const FETCH_CENTENAIREDATAS_SUCCESS = 'FETCH_CENTENAIREDATAS_SUCCESS';
export const FETCH_CENTENAIREDATAS_FAILURE = 'FETCH_CENTENAIREDATAS_FAILURE';

export type FETCH_CENTENAIREDOCUMENTS_START_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENTS_START',
  payload: {
    data: Object
  }
};

export type FETCH_CENTENAIREDOCUMENTS_FAILURE_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENTS_FAILURE'
};

export type FETCH_CENTENAIREDOCUMENTS_SUCCESS_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENTS_SUCCESS',
  payload: {
    documents : Array<CentenaireDocumentType>,
    total: number,
  },
};

export type FETCH_CENTENAIREDOCUMENT_START_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENT_START',
  payload: {
    slug: string
  }
};

export type FETCH_CENTENAIREDOCUMENT_FAILURE_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENT_FAILURE'
};

export type FETCH_CENTENAIREDOCUMENT_SUCCESS_ACTION = {
  type: 'FETCH_CENTENAIREDOCUMENT_SUCCESS',
  payload: {
    document : CentenaireDocumentType,
    total: number, 
  },
};

export type FETCH_CENTENAIRECLUBS_START_ACTION = {
  type: 'FETCH_CENTENAIRECLUBS_START'
};

export type FETCH_CENTENAIRECLUBS_FAILURE_ACTION = {
  type: 'FETCH_CENTENAIRECLUBS_FAILURE'
};

export type FETCH_CENTENAIRECLUBS_SUCCESS_ACTION = {
  type: 'FETCH_CENTENAIRECLUBS_SUCCESS',
  payload: {
    clubs: Array<CentenaireClubPreviewType>
  }
};

export type FETCH_CENTENAIREDATAS_START_ACTION = {
  type: 'FETCH_CENTENAIREDATAS_START'
};

export type FETCH_CENTENAIREDATAS_FAILURE_ACTION = {
  type: 'FETCH_CENTENAIREDATAS_FAILURE'
};

export type FETCH_CENTENAIREDATAS_SUCCESS_ACTION = {
  type: 'FETCH_CENTENAIREDATAS_SUCCESS',
  payload: {
    clubs: Array<CentenaireClubPreviewType>,
    annees: Array<string>,
    equipes: Array<string>,
    niveaux: Array<string>,
    villes: Array<string>,
  }
};

export type Action =
  | FETCH_CENTENAIREDOCUMENTS_START_ACTION
  | FETCH_CENTENAIREDOCUMENTS_FAILURE_ACTION
  | FETCH_CENTENAIREDOCUMENTS_SUCCESS_ACTION
  | FETCH_CENTENAIREDOCUMENT_START_ACTION
  | FETCH_CENTENAIREDOCUMENT_FAILURE_ACTION
  | FETCH_CENTENAIREDOCUMENT_SUCCESS_ACTION
  | FETCH_CENTENAIRECLUBS_START_ACTION
  | FETCH_CENTENAIRECLUBS_FAILURE_ACTION
  | FETCH_CENTENAIRECLUBS_SUCCESS_ACTION
  | FETCH_CENTENAIREDATAS_START_ACTION
  | FETCH_CENTENAIREDATAS_FAILURE_ACTION
  | FETCH_CENTENAIREDATAS_SUCCESS_ACTION;

export function fetchCentenaireDocuments(data: Object): FETCH_CENTENAIREDOCUMENTS_START_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENTS_START,
    payload: { data }
  };
}

export function centenaireDocumentsFetched(
  documents: Array<CentenaireDocumentType>, total: number
  ): FETCH_CENTENAIREDOCUMENTS_SUCCESS_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENTS_SUCCESS,
    payload: { documents, total }
  };
}

export function centenaireDocumentsFetchError(error: any): FETCH_CENTENAIREDOCUMENTS_FAILURE_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENTS_FAILURE
  };
}

export function fetchCentenaireDocument(slug: string): FETCH_CENTENAIREDOCUMENT_START_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENT_START,
    payload: { slug }
  };
}

export function centenaireDocumentFetched(
  document: CentenaireDocumentType,
  total: number
  ): FETCH_CENTENAIREDOCUMENT_SUCCESS_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENT_SUCCESS,
    payload: { document, total }
  };
}

export function centenaireDocumentFetchError(error: any): FETCH_CENTENAIREDOCUMENT_FAILURE_ACTION {
  return {
    type: FETCH_CENTENAIREDOCUMENT_FAILURE
  };
}

export function fetchCentenaireClubs(): FETCH_CENTENAIRECLUBS_START_ACTION {
  return {
    type: FETCH_CENTENAIRECLUBS_START
  };
}

export function centenaireClubsFetched(clubs: Array<CentenaireClubPreviewType>): FETCH_CENTENAIRECLUBS_SUCCESS_ACTION {
  return {
    type: FETCH_CENTENAIRECLUBS_SUCCESS,
    payload: { clubs }
  };
}

export function centenaireClubsFetchError(error: any): FETCH_CENTENAIRECLUBS_FAILURE_ACTION {
  return {
    type: FETCH_CENTENAIRECLUBS_FAILURE
  };
}

export function fetchCentenaireDatas(): FETCH_CENTENAIREDATAS_START_ACTION {
  return {
    type: FETCH_CENTENAIREDATAS_START
  };
}

export function centenaireDatasFetched(datas: any): FETCH_CENTENAIREDATAS_SUCCESS_ACTION {
  const { clubs, annees, niveaux, equipes, villes } = datas;
  return {
    type: FETCH_CENTENAIREDATAS_SUCCESS,
    payload: {clubs, annees, niveaux, equipes, villes }
  };
}

export function centenaireDatasFetchError(error: any): FETCH_CENTENAIREDATAS_FAILURE_ACTION {
  return {
    type: FETCH_CENTENAIREDATAS_FAILURE
  };
}