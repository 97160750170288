// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_ARTICLES_START,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_BILLBOARD_START,
  FETCH_ARTICLES_BILLBOARD_SUCCESS,
  FETCH_ARTICLES_BILLBOARD_FAILURE,
  FETCH_ARTICLES_CATEGORIES_START,
  FETCH_ARTICLES_CATEGORIES_SUCCESS,
  FETCH_ARTICLES_CATEGORIES_FAILURE
} from 'actions/newsletterActions';

import type { ArticleType, CategoryArticleType } from 'types/Article';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  articles: Array<ArticleType>,
  categoriesArticle: Array<CategoryArticleType>,
  status: Status
};

const initialState: State = {
  articles: [],
  categoriesArticle: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_ARTICLES_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_ARTICLES_BILLBOARD_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLES_BILLBOARD_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLES_BILLBOARD_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_ARTICLES_CATEGORIES_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_ARTICLES_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesArticle: action.payload.categories,
        status: STATUS_SUCCESS
      };
    case FETCH_ARTICLES_CATEGORIES_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
