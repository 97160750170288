// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import type { RouterProps } from 'types/Router';

type Props = RouterProps & {
  attributes: any
};

class CompetitionClassementTitle extends Component<Props> {
  render() {
    const { attributes } = this.props;

    const getAttributeValue = attrName => {
      const attributeData = attributes.find(attr => attr.name === attrName);

      if (!!attributeData) {
        return attributeData.value;
      } else {
        return false;
      }
    };

    const isClassementRoute = this.props.location.pathname.includes('classement');
    const isPostCompetition = getAttributeValue('ispostcompetition') === 'true';
    const titlefirstpart = getAttributeValue('titlefirstpart');

    if ((!isPostCompetition && !!titlefirstpart) || isClassementRoute) {
      return <h3>{titlefirstpart}</h3>;
    }

    if (!!isPostCompetition) {
      const firstTitle = getAttributeValue('postcompetitiontitlefirstpart');
      const secondTitle = getAttributeValue('postcompetitiontitlesecondpart');

      if (!firstTitle && !secondTitle) {
        return null;
      }

      return (
        <h2 className="divided">
          {!!firstTitle && <span className="first">{firstTitle}</span>}
          {!!secondTitle && <span className="second second--dark">{secondTitle}</span>}
        </h2>
      );
    }

    return null;
  }
}

export default withRouter(CompetitionClassementTitle);
