// @flow

import React, { Component } from 'react';
import './CountDown.css';

export type Props = {
    date: string,
    message?: string,
};

type State = {
    years: number,
    days: number,
    hours: number,
    min: number,
    sec: number,
};

class Countdown extends Component<Props, State> { 
  interval: IntervalID;
  state: State = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  };

  static defaultProps = {
    date: new Date().toString()
  };

  componentDidMount() {
    // update every second
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.stop();
    }, 1000);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate: string) {
    let diff = (Date.parse(new Date(endDate).toString()) - Date.parse(new Date().toString())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value: number) {
    let valueZeros:string = value.toString();
    while (valueZeros.length < 2) {
        valueZeros = '0' + valueZeros;
    }
    return valueZeros;
  }

  render() {
    const countDown = this.state;
    const { message } = this.props;

    return (
    <>
      <div className="Countdown">
        <span className="Countdown-col">
          <span className="Countdown-col-element">
              <strong>{this.addLeadingZeros(countDown.days)}</strong>
              <span>{countDown.days === 1 ? 'Jour' : 'Jours'}</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.hours)}</strong>
            <span>Heures</span>
          </span>
        </span>


        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.min)}</strong>
            <span>Min</span>
          </span>
        </span>

        <span className="Countdown-col">
          <span className="Countdown-col-element">
            <strong>{this.addLeadingZeros(countDown.sec)}</strong>
            <span>Sec</span>
          </span>
        </span>
      </div>
      {countDown.days === 0 && countDown.hours === 0 && countDown.min === 0 && countDown.sec === 0 && message && 
      <div className="Countdown-message">
          {message}
      </div>}
      </>
    );
  }
}

export default Countdown;