// @flow
import async from 'async';
import type { CentenaireClubPreviewType } from 'types/Centenaire';
import { SORT_NAME } from 'constants/sortConstants';

export function get_club_from_tags(tags: string[]) {
  const tags_manuels = [
    'International',
    'Fédérale 1',
    'Fédérale 2',
    'Fédérale 3',
    'Honneur',
    '1ère série',
    '2ème série',
    '3ème série',
    '4ème série',
    'Élite 1',
    'Élite 2',
    'Fédérale 1 féminine',
    'Fédérale 2 féminine',
    'Reichel',
    'Crabos',
    'Balandrade',
    'Philiponneau',
    'Alamercery',
    'Gauderman',
    'Teulières',
    'Niveau indéterminé',
    'Équipe indéterminée',
    'Senior masculin',
    'Senior féminine',
    'Cadet masculin',
    'Cadet féminine',
    'École de rugby'
  ];
  const tags_ok: string[] = tags.filter((tag) => {
    return tags_manuels.indexOf(tag) === -1;
  });

  return tags_ok;
}

export function get_niveau_from_tags(tags: string[]) {
  const tags_manuels = [
    'International',
    'Fédérale 1',
    'Fédérale 2',
    'Fédérale 3',
    'Honneur',
    '1ère série',
    '2ème série',
    '3ème série',
    '4ème série',
    'Élite 1',
    'Élite 2',
    'Fédérale 1 féminine',
    'Fédérale 2 féminine',
    'Reichel',
    'Crabos',
    'Balandrade',
    'Philiponneau',
    'Alamercery',
    'Gauderman',
    'Teulières',
    'Niveau indéterminé',
  ];
  const tags_ok: string[] = tags.filter((tag) => {
    return tags_manuels.includes(tag);
  });
  return tags_ok;
}

export function get_equipe_from_tags(tags: Array<string>) {
  const tags_manuels = [
    'Équipe indéterminée',
    'Senior masculin',
    'Senior féminine',
    'Cadet masculin',
    'Cadet féminine',
    'École de rugby'
  ];
  const tags_ok: string[] = tags.filter((tag) => {
    return tags_manuels.includes(tag);
  });

  return tags_ok;
}

export function queryCentenaireCitiesFromClubs(
  clubList: Array<CentenaireClubPreviewType>,
  query: string,
): Promise<{ clubs: Array<CentenaireClubPreviewType> }> {
  return new Promise((resolve, reject) => {
    let clubs = [];
    async.each(
      clubList,
      (club, callback) => {
        const city = club.adress.locality.toLowerCase();
        if (city.includes(query.toLowerCase())) clubs = [...clubs.filter(c => c !== club.name), club.name];
        callback();
      },
      err => {
        if (!err) resolve({ clubs });
        else reject(err);
      }
    );
  });
}

export function queryCentenaireCities(
  cityList: Array<string>,
  query: string,
): Promise<Array<string>> {
  return new Promise((resolve, reject) => {
    let cities = [];
    async.each(
      cityList,
      (city, callback) => {
        if (city.includes(query.toLowerCase())) cities = [...cities, city];
        callback();
      },
      err => {
        if (!err) resolve(cities);
        else reject(err);
      }
    );
  });
}

export function queryCentenaireClubsAndCities(
  clubList: Array<CentenaireClubPreviewType>,
  query: string,
): Promise<{ cities: Array<string>, clubs: Array<CentenaireClubPreviewType> }> {
  return new Promise((resolve, reject) => {
    let cities = [];
    let clubs = [];

    async.each(
      clubList,
      (club, callback) => {
        if (clubs.length < 5 && club.searchName.toLowerCase().includes(query)) 
          clubs = [...clubs, club];
        if (cities.length < 5) {
          const city = club.adress.locality.toLowerCase();
          if (city.includes(query)) cities = [...cities.filter(c => c !== club.adress.locality), club.adress.locality];
        }
        callback();
      },
      err => {
        if (!err) resolve({ cities, clubs });
        else reject(err);
      }
    );
  });
}

export function getQueryCentenaireFinale(queryCentenaire: string, props?: any, state?: any) {
  let data = {};
  let query_finale = '*';
  if (queryCentenaire) {
    queryCentenaire = queryCentenaire.replace(/"/g, '');
    query_finale = queryCentenaire;
  }
  if (props) {
    let tags = [];
    query_finale = query_finale;
    if (tags.length > 0) {
      query_finale += '&tags='+tags.join(',');
    }
  }
  data.queryCentenaire = query_finale;
  if (state && state.sort) {
    if (state.sort === '') {
      query_finale += '&orderBy=dateCreated desc';
      data.sort = ['dateCreated desc'];
    } else if (state.sort === SORT_NAME) {
      query_finale += '&orderBy=name asc';
      data.sort = ['name asc'];
    } else if (state.sort === 'annee') {
      query_finale += '&orderBy=datePublished asc';
      data.sort = ['datePublished asc'];
    }
  }
  return data;
}

export function getCentenaireHash() {
  return new Date().getTime().toString().substring(7).split('').reverse().join('');
}