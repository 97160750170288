// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { debounce } from 'lodash';

import qs from 'querystring';

type Props = {
  query: string
};

const API_ADRESSE = 'https://api-adresse.data.gouv.fr';

async function search(args: any) {
  const url = `${API_ADRESSE}/search/?${qs.stringify(args)}`;
  return await _fetch(url);
}

export async function _fetch(url: string) {
  const options = {
    mode: 'cors',
    method: 'GET'
  };

  const response = await fetch(url, options);
  const contentType = response.headers.get('content-type');

  if (!response.ok) {
    throw new Error(response);
  }

  if (response.ok && contentType && contentType.includes('application/json')) {
    return response.json();
  }

  throw new Error('Une erreur est survenue');
}

function ClubFinderBan(props: Props) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [banQuery, setQueryBan] = useState('');
  const [error, setError] = useState(null);

  const { query } = props;

  const handleSearch = useCallback(
    debounce(async input => {
      try {
        if (input && input.trim().length >= 3) {
          const results: any = await search({ q: input.trim(), limit: 10, type: 'municipality' });
          setResults(results.features);
        }
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    }, 300),
    []
  );

  useEffect(() => {
    if (results && results.length > 0) {
      const orderResults = [];
      results.map(feature => {
        if (!orderResults.some(item => item.header === feature.properties.type)) {
          orderResults.push({
            header: feature.properties.type
          });
        }

        return orderResults.push(feature);
      });
    }
  }, [results]);

  useEffect(() => {
    if (query && query !== banQuery) {
      setResults([]);
      setLoading(true);
      setQueryBan(query);
      handleSearch(query);
    }
  }, [handleSearch, props.query]);

  return (
    <>
      {loading && `Chargement des villes`}
      {error}
      {!loading && (
        <>
          <p className="ft-500 mb-1 mb-md-2">Autour de : </p>
          {results.length > 0 && (
            <ul>
              {results.map(result => {
                return (
                  <li key={result.properties.id}>
                    <Link
                      to={{
                        pathname: `/trouver-un-club-de-rugby/resultats-de-recherche`,
                        state: {
                          municipality: result.properties.label + ' (' + result.properties.postcode + ')',
                          lat: result.geometry.coordinates[1],
                          lng: result.geometry.coordinates[0]
                        }
                      }}
                      onClick={() => {
                        handleTagEvent(
                          'recherche geolocalisee',
                          'clic dropdown page ville',
                          result.properties.label + ' (' + result.properties.postcode + ')'
                        );
                      }}
                    >
                      {result.properties.label + ' (' + result.properties.postcode + ')'}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {query.length < 3 && <p>{`Veuillez saisir au minimum 3 caractères pour lancer la recherche`}</p>}
          {query.length >= 3 && results.length === 0 && <p>{`Aucune ville ne correspond à votre recherche`}</p>}
        </>
      )}
    </>
  );
}

export default ClubFinderBan;
