// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  FETCH_RANDOM_VIDEO_PLAYER_START,
  randomVideosPlayerFetched,
  randomVideosPlayerFetchError
} from 'actions/ffr-tv/youtubeRandomVideosPlayerActions';
import RandomVideosApi from 'api/ffrtv/RandomVideosApi';
import VideoApi from 'api/ffrtv/VideosChannelApi';
import type { FETCH_RANDOM_VIDEO_PLAYER_START_ACTION } from 'actions/ffr-tv/youtubeRandomVideosPlayerActions';

export default function(apiExecutor: ApiExecutorType) {
  const randomVideosApi = new RandomVideosApi(apiExecutor);
  const videoApi = new VideoApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_RANDOM_VIDEO_PLAYER_START, fetchVideoPlayer);
  };

  function* fetchVideoPlayer(action: FETCH_RANDOM_VIDEO_PLAYER_START_ACTION): Saga<void> {
    const {
      payload: { idVideo }
    } = action;
    const videoName = yield call(randomVideosApi.fetchVideoNameFromId, idVideo);
    const playlistId = yield call(randomVideosApi.fetchPlaylistIdFromName, videoName);
    if (playlistId === undefined) {
      yield put(randomVideosPlayerFetchError());
    } else {
      const randomVideoId = yield call(randomVideosApi.fetchRandomVideoIdPlaylistId, playlistId, idVideo);
      const randomVideo = yield call(videoApi.fetchYoutubeVideoFromIds, randomVideoId);
      yield put(randomVideosPlayerFetched(randomVideo));
    }
    try {
    } catch (e) {
      yield put(randomVideosPlayerFetchError(e));
    }
  }
}
