// @flow
import type { RawSettingsType, SettingsType } from 'types/Settings';
import { nullParionsSportCote, nullSettings } from 'types/Settings';
import { mergeIntoDefault } from 'utils/objectUtils';
import { convertRencontreFromApi } from 'services/Rencontre';

export function convertSettingsFromApi(rawSettings: RawSettingsType): SettingsType {
  if (!rawSettings) {
    return nullSettings;
  }

  const {
    ligue,
    title,
    description,
    url,
    logo,
    logo_footer,
    logo_transparent,
    cat_calendrier_equipes,
    saison_en_cours,
    liste_cards,
    class_ligue,
    titre_liste_cards,
    trouver_pratiques,
    accreditations,
    clubs_semaine,
    bloc_entreprise,
    blocs_faq,
    blocs_trouver_club,
    bloc_trouver_club,
    contacts,
    favicons,
    breadcrumb_offres_entreprise,
    dashboard_popup,
    liens_rs,
    newsletter,
    cookies,
    actualite_topbar,
    pratiques,
    push_rencontre,
    boutique,
    home_push_event,
    fid_page,
    private_gestion_activite,
    private_maboutique,
    login_url,
    logout_url,
    current_competition,
    current_competitions,
    parions_sport_cote
  } = rawSettings;

  const liste_cardsMerged = liste_cards.map(liste_card => {
    return mergeIntoDefault(nullSettings.liste_cards[0], liste_card);
  });

  const gerer_clubMerged = mergeIntoDefault(nullSettings.gerer_club, rawSettings.liste_blocs_un.gros_bloc);

  const bulletin_officielMerged = mergeIntoDefault(
    nullSettings.bulletin_officiel,
    rawSettings.liste_blocs_un.petit_bloc
  );

  const private_gestion_activiteMerged = private_gestion_activite.map(card => {
    return mergeIntoDefault(nullSettings.private_gestion_activite[0], card);
  });

  const blocsFaqMerged = blocs_faq.map(blocsFaq => {
    return mergeIntoDefault(nullSettings.blocs_faq[0], blocsFaq);
  });

  const blocsTrouverClubMerged = blocs_trouver_club.map(blocsTrouverClub => {
    return mergeIntoDefault(nullSettings.blocs_faq[0], blocsTrouverClub);
  });

  return {
    ...mergeIntoDefault(nullSettings, {
      ligue,
      title,
      description,
      url,
      breadcrumb_offres_entreprise,
      dashboard_popup,
      logo,
      logo_footer,
      logo_transparent,
      cat_calendrier_equipes,
      saison_en_cours,
      liste_cards: liste_cardsMerged,
      class_ligue,
      titre_liste_cards,
      gerer_club: gerer_clubMerged,
      bulletin_officiel: bulletin_officielMerged,
      trouver_pratiques,
      accreditations: accreditations.map(accreditation => ({
        label: accreditation,
        value: accreditation
      })),
      clubs_semaine,
      bloc_entreprise,
      blocs_faq: blocsFaqMerged,
      blocs_trouver_club: blocsTrouverClubMerged,
      bloc_trouver_club,
      contacts,
      favicons,
      liens_rs: {
        fb_lien: liens_rs.fb_lien ? liens_rs.fb_lien : '',
        instagram_lien: liens_rs.instagram_lien ? liens_rs.instagram_lien : '',
        twitter_lien: liens_rs.twitter_lien ? liens_rs.twitter_lien : '',
        youtube_lien: liens_rs.youtube_lien ? liens_rs.youtube_lien : '',
        linkedin_lien: liens_rs.linkedin_lien ? liens_rs.linkedin_lien : '',
        twitch_lien: liens_rs.twitch_lien ? liens_rs.twitch_lien : '',
        snapchat_lien: liens_rs.snapchat_lien ? liens_rs.snapchat_lien : '',
        tik_tok_lien: liens_rs.tik_tok_lien ? liens_rs.tik_tok_lien : '',
        x_lien: liens_rs.x_lien ? liens_rs.x_lien : '',
        discord_lien: liens_rs.discord_lien ? liens_rs.discord_lien : ''
      },
      newsletter,
      cookies,
      actualite_topbar,
      pratiques_clubs: pratiques,
      boutique,
      fid_page,
      home_push_event: home_push_event
        ? home_push_event
        : {
            title: '',
            lien: '',
            cta: '',
            description: '',
            background: '',
            background_mobile: '',
            classsup: ''
          },
      private_gestion_activite: private_gestion_activiteMerged,
      private_maboutique,
      login_url,
      logout_url,
      current_competition: {
        slug: current_competition.slug || '',
        name: current_competition.name || '',
        label: current_competition.label || ''
      },
      current_competitions,
      parionsSportCote: parions_sport_cote ?? nullParionsSportCote
    }),
    // $FlowFixMe
    push_rencontre: push_rencontre && convertRencontreFromApi(push_rencontre)
  };
}
