// @flow
import { type RawYoutubeVideo } from 'types/YoutubeVideo';
import type { RawYoutubePlaylistChannel, RawYoutubePlaylistId } from 'types/YoutubePlaylist';
import { getRandomInts } from 'utils/arrayUtils';

export function convertVideoNameFromApi(rawYoutubeVideo: RawYoutubeVideo): string {
  if (!rawYoutubeVideo || !rawYoutubeVideo.items || rawYoutubeVideo.items.length === 0) {
    return '';
  }
  const videoName = rawYoutubeVideo.items[0].snippet.localized.title;
  return videoName;
}

export function convertPlaylistIdFromApi(rawYoutubePlaylistId: RawYoutubePlaylistId): ?string {
  if (!rawYoutubePlaylistId || !rawYoutubePlaylistId.items || rawYoutubePlaylistId.items.length === 0) {
    return undefined;
  }
  const playlistId = rawYoutubePlaylistId.items[0].id.playlistId;
  return playlistId;
}

export function convertRandomVideoIdFromApi(
  rawYoutubeVideo: RawYoutubePlaylistChannel,
  idVideo: string
): Array<string> {
  const videos = rawYoutubeVideo.items.filter(video => video.snippet.resourceId.videoId !== idVideo);
  const randomIndex = getRandomInts(videos.length, videos.length < 3 ? videos.length : 3);
  const randomVideos = randomIndex.map(index => videos[index].snippet);
  return randomVideos.map(video => video.resourceId.videoId);
}
