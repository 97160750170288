// @flow
import type { JoueurType } from 'types/Joueur';

export const FETCH_VIDEO_PLAYERS_START = 'FETCH_VIDEO_PLAYERS_START';
export const FETCH_VIDEO_PLAYERS_SUCCESS = 'FETCH_VIDEO_PLAYERS_SUCCESS';
export const FETCH_VIDEO_PLAYERS_FAILURE = 'FETCH_VIDEO_PLAYERS_FAILURE';

export type FETCH_VIDEO_PLAYERS_START_ACTION = {
  type: 'FETCH_VIDEO_PLAYERS_START',
  payload: { playersName: Array<string> }
};

export type FETCH_VIDEO_PLAYERS_FAILURE_ACTION = {
  type: 'FETCH_VIDEO_PLAYERS_FAILURE'
};

export type FETCH_VIDEO_PLAYERS_SUCCESS_ACTION = {
  type: 'FETCH_VIDEO_PLAYERS_SUCCESS',
  payload: {
    players: Array<JoueurType>
  }
};

export type Action =
  | FETCH_VIDEO_PLAYERS_START_ACTION
  | FETCH_VIDEO_PLAYERS_FAILURE_ACTION
  | FETCH_VIDEO_PLAYERS_SUCCESS_ACTION;

export function fetchPlayersFromVideo(playersName: Array<string>): FETCH_VIDEO_PLAYERS_START_ACTION {
  return {
    type: FETCH_VIDEO_PLAYERS_START,
    payload: { playersName }
  };
}

export function playersFromVideoFetched(
  players: Array<JoueurType>
): FETCH_VIDEO_PLAYERS_SUCCESS_ACTION {
  return {
    type: FETCH_VIDEO_PLAYERS_SUCCESS,
    payload: { players }
  };
}

export function playersFromVideoFetchError(error: any): FETCH_VIDEO_PLAYERS_FAILURE_ACTION {
  return {
    type: FETCH_VIDEO_PLAYERS_FAILURE
  };
}
