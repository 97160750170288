// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_JOB_LIST_SUCCESS,
  FETCH_UNKNOWN,
  FETCH_JOB_LIST_START,
  FETCH_JOB_LIST_WITHOUT_FILTER_START,
  FETCH_JOB_LIST_FILTER_START,
  FETCH_JOB_LIST_FILTER_SUCCESS,
} from 'actions/jobActions';

import type { Status } from 'types/Status';
import type { JobListType } from 'types/JobList';
import type { JobFilterType } from 'types/JobFilter';
import type { ActionType } from 'types/Actions';

export type State = {
  jobs: Array<JobListType>,
  jobFilters: {
    domaines: Array<JobFilterType>,
    typesContrats: Array<JobFilterType>,
    regions: Array<JobFilterType>,
  },
  maxPage: number,
  totalJobs: number,
  status: Status
};

const initialState: State = {
  jobs: [],
  jobFilters: {
    domaines: [],
    typesContrats: [],
    regions: [],
  },
  maxPage: 1,
  totalJobs: 0,
  status: STATUS_UNKNOWN
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_JOB_LIST_START:
    case FETCH_JOB_LIST_FILTER_START:
    case FETCH_JOB_LIST_WITHOUT_FILTER_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_JOB_LIST_SUCCESS:
      return {
        ...state,
        jobs: action.payload.jobs,
        maxPage: action.payload.maxPage,
        totalJobs: action.payload.totalJobs,
        jobFilters: action.payload.jobFilters || state.jobFilters,
        status: STATUS_SUCCESS
      };
    case FETCH_JOB_LIST_FILTER_SUCCESS:
      return {
        ...state,
        jobFilters: action.payload.jobFilters || state.jobFilters,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
