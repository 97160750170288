// @flow

import type { FidOffresType, FidModalesType } from 'types/Fid';

export const FETCH_FID_MODALES_START = 'FETCH_FID_MODALES_START';
export const FETCH_FID_MODALES_SUCCESS = 'FETCH_FID_MODALES_SUCCESS';
export const FETCH_FID_MODALES_FAILURE = 'FETCH_FID_MODALES_FAILURE';

export const FETCH_FID_OFFRES_START = 'FETCH_FID_OFFRES_START';
export const FETCH_FID_OFFRES_SUCCESS = 'FETCH_FID_OFFRES_SUCCESS';
export const FETCH_FID_OFFRES_FAILURE = 'FETCH_FID_OFFRES_FAILURE';

export type FETCH_FID_MODALES_START_ACTION = {
  type: 'FETCH_FID_MODALES_START'
};

export type FETCH_FID_MODALES_FAILURE_ACTION = {
  type: 'FETCH_FID_MODALES_FAILURE'
};

export type FETCH_FID_MODALES_SUCCESS_ACTION = {
  type: 'FETCH_FID_MODALES_SUCCESS',
  payload: {
    fid_modales_data: FidModalesType
  }
};
export type FETCH_FID_OFFRES_START_ACTION = {
  type: 'FETCH_FID_OFFRES_START'
};

export type FETCH_FID_OFFRES_FAILURE_ACTION = {
  type: 'FETCH_FID_OFFRES_FAILURE'
};

export type FETCH_FID_OFFRES_SUCCESS_ACTION = {
  type: 'FETCH_FID_OFFRES_SUCCESS',
  payload: {
    fid_offres: FidOffresType
  }
};

export type Action =
  | FETCH_FID_MODALES_START_ACTION
  | FETCH_FID_MODALES_FAILURE_ACTION
  | FETCH_FID_MODALES_SUCCESS_ACTION
  | FETCH_FID_OFFRES_START_ACTION
  | FETCH_FID_OFFRES_FAILURE_ACTION
  | FETCH_FID_OFFRES_SUCCESS_ACTION;

export function fetchFidModales(): FETCH_FID_MODALES_START_ACTION {
  return {
    type: FETCH_FID_MODALES_START
  };
}

export function fidModalesFetched(fid_modales_data: FidModalesType): FETCH_FID_MODALES_SUCCESS_ACTION {
  return {
    type: FETCH_FID_MODALES_SUCCESS,
    payload: { fid_modales_data }
  };
}

export function fidModalesFetchError(error: any): FETCH_FID_MODALES_FAILURE_ACTION {
  return {
    type: FETCH_FID_MODALES_FAILURE
  };
}

export function fetchFidOffres(): FETCH_FID_OFFRES_START_ACTION {
  return {
    type: FETCH_FID_OFFRES_START
  };
}

export function fidOffresFetched(fid_offres: FidOffresType): FETCH_FID_OFFRES_SUCCESS_ACTION {
  return {
    type: FETCH_FID_OFFRES_SUCCESS,
    payload: { fid_offres }
  };
}

export function fidOffresFetchError(error: any): FETCH_FID_OFFRES_FAILURE_ACTION {
  return {
    type: FETCH_FID_OFFRES_FAILURE
  };
}
