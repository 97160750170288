// @flow
import { connect } from 'react-redux';

import Download from 'components/fragments/Download';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/Download';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  })
)(Download);
