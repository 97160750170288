// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  FETCH_YOUTUBE_LIVE_START,
  liveVideoFetched,
  liveVideoFetchError,
  type FETCH_YOUTUBE_LIVE_START_ACTION
} from 'actions/ffr-tv/youtubeLiveActions';
import {
  FETCH_YOUTUBE_SPOTLIGHT_START,
  SpotlightVideoFetched,
  SpotlightVideoFetchError
} from 'actions/ffr-tv/youtubeSpotlightActions';

import TvApi from 'api/ffrtv/TvApi';
import SpotlightApi from 'api/ffrtv/SpotlightApi';
import type { FETCH_YOUTUBE_START_ACTION } from 'actions/youtubeActions';

export default function(apiExecutor: ApiExecutorType, spotlightApiExecutor: ApiExecutorType) {
  const tvApi = new TvApi(apiExecutor);
  const spotlightApi = new SpotlightApi(spotlightApiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_LIVE_START, fetchYoutubeLive);
    yield takeLatest(FETCH_YOUTUBE_SPOTLIGHT_START, fetchYoutubeSpotlight);
  };

  function* fetchYoutubeLive(action: FETCH_YOUTUBE_LIVE_START_ACTION): Saga<void> {
    try {
      const video = yield call(tvApi.fetchYoutubeLiveVideo);
      yield put(liveVideoFetched(video));
    } catch (e) {
      yield put(liveVideoFetchError());
    }
  }

  function* fetchYoutubeSpotlight(action: FETCH_YOUTUBE_START_ACTION): Saga<void> {
    try {
      const video = yield call(spotlightApi.fetchSpotlightVideo);
      yield put(SpotlightVideoFetched(video));
    } catch (e) {
      yield put(SpotlightVideoFetchError());
    }
  }
}
