// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { RawPrivateAccountDashboardType } from 'types/PrivateAccountDashboardType';

function PrivateAccountDashboardApi(executor: ApiExecutorType) {
  return { fetchPrivateAccountDashboard };

  function fetchPrivateAccountDashboard(): Promise<RawPrivateAccountDashboardType[]> {
    return executor.get(`/ffr/v1/private_account_dashboard`).then(response => response);
  }
}

export default PrivateAccountDashboardApi;
