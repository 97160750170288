// @flow

import type { MatchResultType } from 'types/Match';

export const FETCH_MATCHRESULT_START = 'FETCH_MATCHRESULT_START';
export const FETCH_MATCHRESULT_SUCCESS = 'FETCH_MATCHRESULT_SUCCESS';
export const FETCH_MATCHRESULT_FAILURE = 'FETCH_MATCHRESULT_FAILURE';
export const FETCH_MATCHFUTURETEAM_START = 'FETCH_MATCHFUTURETEAM_START';
export const FETCH_MATCHFUTURETEAM_SUCCESS = 'FETCH_MATCHFUTURETEAM_SUCCESS';
export const FETCH_MATCHFUTURETEAM_FAILURE = 'FETCH_MATCHFUTURETEAM_FAILURE';
export const FETCH_MATCHPASTTEAM_START = 'FETCH_MATCHPASTTEAM_START';
export const FETCH_MATCHPASTTEAM_SUCCESS = 'FETCH_MATCHPASTTEAM_SUCCESS';
export const FETCH_MATCHPASTTEAM_FAILURE = 'FETCH_MATCHPASTTEAM_FAILURE';

export type FETCH_MATCHRESULT_START_ACTION = {
  type: 'FETCH_MATCHRESULT_START'
};

export type FETCH_MATCHRESULT_FAILURE_ACTION = {
  type: 'FETCH_MATCHRESULT_FAILURE'
};

export type FETCH_MATCHRESULT_SUCCESS_ACTION = {
  type: 'FETCH_MATCHRESULT_SUCCESS',
  payload: {
    result: MatchResultType
  }
};

export type FETCH_MATCHFUTURETEAM_START_ACTION = {
  type: 'FETCH_MATCHFUTURETEAM_START',
  payload: {
    start_date: string,
    team: string,
    per_page: number
  }
}

export type FETCH_MATCHFUTURETEAM_FAILURE_ACTION = {
  type: 'FETCH_MATCHFUTURETEAM_FAILURE'
};

export type FETCH_MATCHFUTURETEAM_SUCCESS_ACTION = {
  type: 'FETCH_MATCHFUTURETEAM_SUCCESS',
  payload: {
    results_future: Array<MatchResultType>
  }
};

export type FETCH_MATCHPASTTEAM_START_ACTION = {
  type: 'FETCH_MATCHPASTTEAM_START',
  payload: {
    end_date: string,
    team: string,
    per_page: number
  }
}

export type FETCH_MATCHPASTTEAM_FAILURE_ACTION = {
  type: 'FETCH_MATCHPASTTEAM_FAILURE'
};

export type FETCH_MATCHPASTTEAM_SUCCESS_ACTION = {
  type: 'FETCH_MATCHPASTTEAM_SUCCESS',
  payload: {
    results_past: Array<MatchResultType>
  }
};
export type Action =
  | FETCH_MATCHRESULT_START_ACTION
  | FETCH_MATCHRESULT_FAILURE_ACTION
  | FETCH_MATCHRESULT_SUCCESS_ACTION
  | FETCH_MATCHFUTURETEAM_START_ACTION
  | FETCH_MATCHFUTURETEAM_FAILURE_ACTION
  | FETCH_MATCHFUTURETEAM_SUCCESS_ACTION
  | FETCH_MATCHPASTTEAM_START_ACTION
  | FETCH_MATCHPASTTEAM_FAILURE_ACTION
  | FETCH_MATCHPASTTEAM_SUCCESS_ACTION;

export function fetchMatchResult(): FETCH_MATCHRESULT_START_ACTION {
  return {
    type: FETCH_MATCHRESULT_START,
  };
}

export function matchResultFetched(result: MatchResultType): FETCH_MATCHRESULT_SUCCESS_ACTION {
  return {
    type: FETCH_MATCHRESULT_SUCCESS,
    payload: { result }
  };
}

export function matchResultFetchError(): FETCH_MATCHRESULT_FAILURE_ACTION {
  return {
    type: FETCH_MATCHRESULT_FAILURE
  };
}

export function fetchMatchsFutureTeam(
  start_date: string, 
  team: string, 
  per_page: number): FETCH_MATCHFUTURETEAM_START_ACTION {
  return {
    type: FETCH_MATCHFUTURETEAM_START,
    payload: { start_date, team, per_page }
  };
}

export function matchsFutureTeamFetched(results_future: Array<MatchResultType>): FETCH_MATCHFUTURETEAM_SUCCESS_ACTION {
  return {
    type: FETCH_MATCHFUTURETEAM_SUCCESS,
    payload: { results_future }
  };
}

export function matchsFutureTeamFetchError(): FETCH_MATCHFUTURETEAM_FAILURE_ACTION {
  return {
    type: FETCH_MATCHFUTURETEAM_FAILURE
  };
}

export function fetchMatchsPastTeam(
  end_date: string,
  team: string,
  per_page: number): FETCH_MATCHPASTTEAM_START_ACTION {
  return {
    type: FETCH_MATCHPASTTEAM_START,
    payload: { end_date, team, per_page }
  };
}

export function matchsPastTeamFetched(results_past: Array<MatchResultType>): FETCH_MATCHPASTTEAM_SUCCESS_ACTION {
  return {
    type: FETCH_MATCHPASTTEAM_SUCCESS,
    payload: { results_past }
  };
}

export function matchsPastTeamFetchError(): FETCH_MATCHPASTTEAM_FAILURE_ACTION {
  return {
    type: FETCH_MATCHPASTTEAM_FAILURE
  };
}