// @flow
import { FETCH_ARTICLE_SUCCESS } from 'actions/articleActions';
import { FETCH_CENTENAIREDOCUMENTS_SUCCESS } from 'actions/centenaireActions';
import { FETCH_CLUB_SUCCESS } from 'actions/clubActions';
import { FETCH_CLUBS_SUCCESS } from 'actions/clubsActions';
import { FETCH_COMPETITION_SUCCESS } from 'actions/competitionActions';
import { FETCH_EVENT_SUCCESS } from 'actions/eventActions';
import { FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE } from 'actions/eventsActions';
import { FETCH_FAQ_THEME_SUCCESS } from 'actions/faqActions';
import { SEND_VPV } from 'actions/hitTagsActions';
import { FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS } from 'actions/ffr-tv/homeYoutubePlaylistsActions';
import { SEND_CONTACT_FORM_START } from 'actions/formActions';
import { FETCH_JOB_LIST_SUCCESS, FETCH_JOB_DETAIL_SUCCESS } from 'actions/jobActions';
import { FETCH_LOGE_LIST_SUCCESS, FETCH_LOGE_DETAIL_SUCCESS } from 'actions/logeActions';
import { DISPLAY_MODAL } from 'actions/modalActions';
import {
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_BILLBOARD_SUCCESS,
  FETCH_ARTICLES_CATEGORIES_SUCCESS
} from 'actions/newsletterActions';
import { FETCH_ARTICLES_CATEGORY_SUCCESS } from 'actions/newsletterCategoryActions';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_PAGE_NODE_SUCCESS, FETCH_PAGE_DETAIL_SUCCESS } from 'actions/pageActions';
import { FETCH_SEMINAIRE_SUCCESS } from 'actions/seminaireActions';
import { FETCH_STAFFTEAM_SUCCESS } from 'actions/staffActions';
import { FETCH_TAG_ARTICLE_SUCCESS } from 'actions/tagArticleActions';
import { FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS } from 'actions/competitionsInternationalesActions';

import type { FETCH_ARTICLE_SUCCESS_ACTION } from 'actions/articleActions';
import type { FETCH_CENTENAIREDOCUMENTS_SUCCESS_ACTION } from 'actions/centenaireActions';
import type { FETCH_CLUB_SUCCESS_ACTION } from 'actions/clubActions';
import type { FETCH_CLUBS_SUCCESS_ACTION } from 'actions/clubsActions';
import type { FETCH_COMPETITION_SUCCESS_ACTION } from 'actions/competitionActions';
import type { FETCH_EVENT_SUCCESS_ACTION } from 'actions/eventActions';
import type { FETCH_EVENTS_SUCCESS_ACTION, FETCH_EVENTS_FAILURE_ACTION } from 'actions/eventsActions';
import type { FETCH_FAQ_THEME_SUCCESS_ACTION } from 'actions/faqActions';
import type { SEND_VPV_ACTION } from 'actions/hitTagsActions';
import type { FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS_ACTION } from 'actions/ffr-tv/homeYoutubePlaylistsActions';
import type { SEND_CONTACT_FORM_START_ACTION } from 'actions/formActions';
import type { FETCH_JOB_LIST_SUCCESS_ACTION, FETCH_JOB_DETAIL_SUCCESS_ACTION } from 'actions/jobActions';
import type { FETCH_LOGE_LIST_SUCCESS_ACTION, FETCH_LOGE_DETAIL_SUCCESS_ACTION } from 'actions/logeActions';
import type { DISPLAY_MODAL_ACTION } from 'actions/modalActions';
import type {
  FETCH_ARTICLES_SUCCESS_ACTION,
  FETCH_ARTICLES_CATEGORIES_SUCCESS_ACTION,
  FETCH_ARTICLES_BILLBOARD_SUCCESS_ACTION
} from 'actions/newsletterActions';
import type { FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION } from 'actions/newsletterCategoryActions';
import type {
  FETCH_PAGE_LIST_SUCCESS_ACTION,
  FETCH_PAGE_NODE_SUCCESS_ACTION,
  FETCH_PAGE_DETAIL_SUCCESS_ACTION
} from 'actions/pageActions';
import type { FETCH_SEMINAIRE_SUCCESS_ACTION } from 'actions/seminaireActions';
import type { FETCH_STAFFTEAM_SUCCESS_ACTION } from 'actions/staffActions';
import type { FETCH_TAG_ARTICLE_SUCCESS_ACTION } from 'actions/tagArticleActions';
import type { FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS_ACTION } from 'actions/competitionsInternationalesActions';

export const hitTagsArray = [
  DISPLAY_MODAL,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLES_BILLBOARD_SUCCESS,
  FETCH_ARTICLES_CATEGORIES_SUCCESS,
  FETCH_ARTICLES_CATEGORY_SUCCESS,
  FETCH_ARTICLES_SUCCESS,
  FETCH_CENTENAIREDOCUMENTS_SUCCESS,
  FETCH_CLUB_SUCCESS,
  FETCH_CLUBS_SUCCESS,
  FETCH_COMPETITION_SUCCESS,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  FETCH_FAQ_THEME_SUCCESS,
  FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS,
  FETCH_JOB_DETAIL_SUCCESS,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_LOGE_DETAIL_SUCCESS,
  FETCH_LOGE_LIST_SUCCESS,
  FETCH_PAGE_DETAIL_SUCCESS,
  FETCH_PAGE_LIST_SUCCESS,
  FETCH_PAGE_NODE_SUCCESS,
  FETCH_TAG_ARTICLE_SUCCESS,
  FETCH_SEMINAIRE_SUCCESS,
  FETCH_STAFFTEAM_SUCCESS,
  SEND_CONTACT_FORM_START,
  SEND_VPV,
  FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS
];

export type hitTagsArrayType =
  | DISPLAY_MODAL_ACTION
  | FETCH_ARTICLE_SUCCESS_ACTION
  | FETCH_ARTICLES_BILLBOARD_SUCCESS_ACTION
  | FETCH_ARTICLES_CATEGORIES_SUCCESS_ACTION
  | FETCH_ARTICLES_CATEGORY_SUCCESS_ACTION
  | FETCH_ARTICLES_SUCCESS_ACTION
  | FETCH_CENTENAIREDOCUMENTS_SUCCESS_ACTION
  | FETCH_CLUB_SUCCESS_ACTION
  | FETCH_CLUBS_SUCCESS_ACTION
  | FETCH_COMPETITION_SUCCESS_ACTION
  | FETCH_EVENT_SUCCESS_ACTION
  | FETCH_EVENTS_FAILURE_ACTION
  | FETCH_EVENTS_SUCCESS_ACTION
  | FETCH_FAQ_THEME_SUCCESS_ACTION
  | FETCH_HOME_YOUTUBE_PLAYLISTS_SUCCESS_ACTION
  | FETCH_JOB_LIST_SUCCESS_ACTION
  | FETCH_JOB_DETAIL_SUCCESS_ACTION
  | FETCH_LOGE_DETAIL_SUCCESS_ACTION
  | FETCH_LOGE_LIST_SUCCESS_ACTION
  | FETCH_PAGE_DETAIL_SUCCESS_ACTION
  | FETCH_PAGE_LIST_SUCCESS_ACTION
  | FETCH_PAGE_NODE_SUCCESS_ACTION
  | FETCH_SEMINAIRE_SUCCESS_ACTION
  | FETCH_STAFFTEAM_SUCCESS_ACTION
  | FETCH_TAG_ARTICLE_SUCCESS_ACTION
  | SEND_CONTACT_FORM_START_ACTION
  | SEND_VPV_ACTION
  | FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS_ACTION;
