// @flow

import type { NewsletterSubscriptionEntryType, NewsletterSubscriptionType } from 'types/NewsletterSubscription';

export const FETCH_NEWSLETTER_OPTIONS_START = 'FETCH_NEWSLETTER_OPTIONS_START';
export const FETCH_NEWSLETTER_OPTIONS_SUCCESS = 'FETCH_NEWSLETTER_OPTIONS_SUCCESS';
export const FETCH_NEWSLETTER_OPTIONS_FAILURE = 'FETCH_NEWSLETTER_OPTIONS_FAILURE';

export const SUBSCRIBE_NEWSLETTER_START = 'SUBSCRIBE_NEWSLETTER_START';
export const SUBSCRIBE_NEWSLETTER_SUCCESS = 'SUBSCRIBE_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_NEWSLETTER_FAILURE = 'SUBSCRIBE_NEWSLETTER_FAILURE';

export type FETCH_NEWSLETTER_OPTIONS_START_ACTION = {
  type: 'FETCH_NEWSLETTER_OPTIONS_START',
  payload: {
    campaignMonitorKey: string
  }
};

export type FETCH_NEWSLETTER_OPTIONS_FAILURE_ACTION = {
  type: 'FETCH_NEWSLETTER_OPTIONS_FAILURE'
};

export type FETCH_NEWSLETTER_OPTIONS_SUCCESS_ACTION = {
  type: 'FETCH_NEWSLETTER_OPTIONS_SUCCESS',
  payload: {
    options: NewsletterSubscriptionType
  }
};

export type SUBSCRIBE_NEWSLETTER_START_ACTION = {
  type: 'SUBSCRIBE_NEWSLETTER_START',
  payload: {
    subscription: NewsletterSubscriptionEntryType,
    campaignMonitorKey: string,
    typeNewsletter: string | boolean,
    categories?: string[],
    token?: string,
  }
};

export type SUBSCRIBE_NEWSLETTER_FAILURE_ACTION = {
  type: 'SUBSCRIBE_NEWSLETTER_FAILURE'
};

export type SUBSCRIBE_NEWSLETTER_SUCCESS_ACTION = {
  type: 'SUBSCRIBE_NEWSLETTER_SUCCESS'
};

export type Action =
  | FETCH_NEWSLETTER_OPTIONS_START_ACTION
  | FETCH_NEWSLETTER_OPTIONS_FAILURE_ACTION
  | FETCH_NEWSLETTER_OPTIONS_SUCCESS_ACTION
  | SUBSCRIBE_NEWSLETTER_START_ACTION
  | SUBSCRIBE_NEWSLETTER_FAILURE_ACTION
  | SUBSCRIBE_NEWSLETTER_SUCCESS_ACTION;

export function fetchNewsletterOptions(campaignMonitorKey: string): FETCH_NEWSLETTER_OPTIONS_START_ACTION {
  return {
    type: FETCH_NEWSLETTER_OPTIONS_START,
    payload: {
      campaignMonitorKey
    }
  };
}

export function newsletterOptionsFetched(options: NewsletterSubscriptionType): FETCH_NEWSLETTER_OPTIONS_SUCCESS_ACTION {
  return {
    type: FETCH_NEWSLETTER_OPTIONS_SUCCESS,
    payload: { options }
  };
}

export function newsletterOptionsFetchError(): FETCH_NEWSLETTER_OPTIONS_FAILURE_ACTION {
  return {
    type: FETCH_NEWSLETTER_OPTIONS_FAILURE
  };
}

export function subscribeNewsletter(
  subscription: NewsletterSubscriptionEntryType,
  campaignMonitorKey: string,
  typeNewsletter: string | boolean,
  categories?: Array<string>,
  token?: string
): SUBSCRIBE_NEWSLETTER_START_ACTION {
  if (categories) {
    return {
      type: SUBSCRIBE_NEWSLETTER_START,
      payload: { subscription, campaignMonitorKey, categories, typeNewsletter, token }
    };
  }
  return {
    type: SUBSCRIBE_NEWSLETTER_START,
    payload: { subscription, campaignMonitorKey, typeNewsletter }
  };
}

export function newsletterSubscribed(): SUBSCRIBE_NEWSLETTER_SUCCESS_ACTION {
  return {
    type: SUBSCRIBE_NEWSLETTER_SUCCESS
  };
}

export function newsletterSubscribeError(): SUBSCRIBE_NEWSLETTER_FAILURE_ACTION {
  return {
    type: SUBSCRIBE_NEWSLETTER_FAILURE
  };
}
