// @flow
import React, { PureComponent } from 'react';

import Loading from 'components/fragments/Loading';
import { STATUS_SUCCESS } from 'constants/statusConstants';
import ItemBadge from 'containers/profile/MesFavoris/ItemBadgeContainer';
import { removeAccents } from 'utils/nameUtils';

import type { JoueurType } from 'types/Joueursearch';
import type { Status } from 'types/Status';
import type { JoueurSuivisType } from 'types/User';

export type DispatchProps = {
  fetchJoueursearch: () => void
};

export type StateProps = {
  joueurs_favoris: number[],
  joueurs_search: JoueurType[],
  joueurs_search_status: Status,
  joueurs_suivis_detail: JoueurSuivisType[],
  postUserPrefStatus: Status
};

type Props = DispatchProps &
  StateProps & {
    hideModal: Function
  };

type State = {
  filteredPlayers: any[],
  query: string
};

class AddAFavoritePlayerModalContent extends PureComponent<Props, State> {
  state: State = {
    filteredPlayers: [],
    query: ''
  };

  componentDidMount() {
    const { fetchJoueursearch } = this.props;

    //* launch joueursearch
    fetchJoueursearch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { joueurs_search } = this.props;
    const { query } = this.state;
    const { query: previousQuery } = prevState;

    // when the user is looking for an item and he has written at least 2 characters
    // we filter the items according to his query
    if (query.length > 2 && query !== previousQuery) {
      const filteredPlayers = joueurs_search.filter(player => {
        const playerFullName = `${player.nom} ${player.prenom}`;
        return removeAccents(playerFullName.toLowerCase()).includes(query);
      });

      this.setState({ filteredPlayers: filteredPlayers.slice(0, 10) });
    }
  }

  /**
   * Change the value of query on the input change
   * save it without accent and in lower case
   */
  handleInputChanged = (event: any) => {
    this.setState({ query: removeAccents(event.target.value.toLowerCase()) });
  };

  render() {
    const { hideModal, joueurs_favoris, joueurs_search_status, joueurs_suivis_detail, postUserPrefStatus } = this.props;
    const { filteredPlayers, query } = this.state;

    return (
      <>
        <h3>Ajouter un joueur ou une joueuse</h3>

        <div className="mt-4">
          <div className="input input--search ">
            <input
              type="search"
              // TODO : id pour rechercher un joueur ?
              id="yagd2adssd3"
              placeholder="Rechercher un joueur ou une joueuse"
              autoComplete="off"
              onChange={this.handleInputChanged}
              value={query}
              aria-label={'Rechercher un joueur ou une joueuse'}
            />
          </div>

          <div className="add-favorite-player-modal__content">
            {/* Loader */}
            {(joueurs_search_status !== STATUS_SUCCESS || postUserPrefStatus !== STATUS_SUCCESS) && (
              <Loading isWhite style={{ height: '152px', display: 'flex' }} />
            )}

            {/* most followed players */}
            {joueurs_search_status === STATUS_SUCCESS &&
              postUserPrefStatus === STATUS_SUCCESS &&
              query.length < 3 &&
              joueurs_suivis_detail &&
              !!joueurs_suivis_detail.length && (
                <>
                  <div className="ft-black-tertiary ft-700 mb-2">Les joueurs et joueuses les plus suivis</div>
                  <ul className="item-list item-list--most-followed">
                    {joueurs_suivis_detail.map(player => {
                      const nameToDisplay = `${player.nom.slice(0, 1)}${player.nom
                        .slice(1)
                        .toLowerCase()} ${player.prenom.slice(0, 1)}.`;
                      let imageFinale = player.featured_media.src;
                      if (player.featured_media && player.featured_media.sizes && player.featured_media.sizes.medium) {
                        imageFinale = player.featured_media.sizes.medium.src;
                      }

                      return (
                        <ItemBadge
                          key={player.id}
                          featured_image={imageFinale}
                          id={player.id}
                          item={'joueurs'}
                          isFavoriteItem={joueurs_favoris.includes(player.id)}
                          link={player.link}
                          title={nameToDisplay}
                        />
                      );
                    })}
                  </ul>
                </>
              )}

            {/* players accordingly to the user query */}
            {joueurs_search_status === STATUS_SUCCESS &&
              postUserPrefStatus === STATUS_SUCCESS &&
              query.length >= 3 &&
              filteredPlayers &&
              !!filteredPlayers.length && (
                <ul className="item-list">
                  {filteredPlayers.map(player => {
                    const nameToDisplay = `${player.nom.slice(0, 1)}${player.nom
                      .slice(1)
                      .toLowerCase()} ${player.prenom.slice(0, 1)}.`;

                    return (
                      <ItemBadge
                        key={player.id}
                        featured_image={player.featured_image}
                        id={player.id}
                        item={'joueurs'}
                        isFavoriteItem={joueurs_favoris.includes(player.id)}
                        link={player.slug}
                        title={nameToDisplay}
                      />
                    );
                  })}
                </ul>
              )}

            {/* when no player is returned to the user query*/}
            {joueurs_search_status === STATUS_SUCCESS &&
              query.length >= 3 &&
              filteredPlayers &&
              !filteredPlayers.length && (
                <div className="box">
                  <div className="placeholder ">
                    <p className="ft-h5">
                      <i className="icon icon-jersey-empty"></i>
                      <br />
                      Aucun joueur / joueuse pour cette recherche
                    </p>
                  </div>
                </div>
              )}
          </div>

          <button className="btn btn--primary mt-3 mt-md-2" onClick={hideModal}>
            Terminer
          </button>
        </div>
      </>
    );
  }
}

export default AddAFavoritePlayerModalContent;
