/* eslint-disable no-undef */
// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import rematchFormApi from 'api/rematch/rematchFormApi';
import { displayModal } from 'actions/modalActions';
import { setRematchVote } from 'actions/appActions';
import {
  SEND_REMATCH_FORM_START,
  FETCH_REMATCH_START,
  rematchFormPosted,
  rematchFetched,
  rematchFormPostError,
  rematchFetchError,
  type SEND_REMATCH_FORM_START_ACTION,
  type FETCH_REMATCH_START_ACTION
} from 'actions/rematchFormActions';

export default function(apiExecutor: ApiExecutorType) {
  const formApi = new rematchFormApi(apiExecutor);
  return function* formSaga(): GeneratorType {
    yield takeLatest(SEND_REMATCH_FORM_START, sendForm);
    yield takeLatest(FETCH_REMATCH_START, fetchRematch);
  };

  function* sendForm(action: SEND_REMATCH_FORM_START_ACTION): Saga<void> {
    try {
      const { formInputs } = action.payload;
      const data = new FormData();

      for (const key in formInputs) {
        if (formInputs.hasOwnProperty(key)) {
          data.append(key, formInputs[key]);
        }
      }

      const data_final = yield call(formApi.postFormRematch, data);
      const title = formInputs.video ? formInputs.video.club : '';
      const text = `Votre vote a bien été pris en compte`;
      yield put(displayModal('MODAL_FORM_SUCCESS_SIMPLE', { title, text }, false));
      yield put(setRematchVote(formInputs.video));
      yield put(rematchFormPosted(data_final));
    } catch (e) {
      // const { formInputs } = action.payload;
      // const title = formInputs.video ? formInputs.video.club : '';
      // const text = `Votre vote n\'a pu être pris en compte`;
      // yield put(displayModal(26, { title, text }, false)); // la modal 26 n'existe pas
      yield put(rematchFormPostError());
    }
  }

  function* fetchRematch(action: FETCH_REMATCH_START_ACTION): Saga<void> {
    try {
      const data = yield call(formApi.fetchRematch);
      yield put(rematchFetched(data));
    } catch (e) {
      console.log(e);
      yield put(rematchFetchError());
    }
  }
}
