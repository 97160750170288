/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  id: string,
  label: string,
  isDark?: boolean,
  onChange?: (checked: boolean) => void,
  className?: string,
  labelClassName?: string,
  checked?: boolean,
  paddingLeft?: Object,
};

class CheckboxInput extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    id: 'checkboxinput123',
    label:
      'J’accepte les emails de la Ligue Franche comté de rugby à propos des nouveautés et promotions de la Ligue. ' +
      ' Je peux me désinscrire à tout moment en utilisant le lien pour me désabonner au pied de la newsletter.'
  };

  handleCheckboxCliked = (e: any) => {
    if (this.props.onChange) this.props.onChange(e.target.checked);
  };

  render() {
    const { isDark, className, checked , labelClassName, paddingLeft: style } = this.props;
    const classNames = dynamicClassName('checkbox col col-lg-4');
    
    isDark && classNames.add('is-dark');
    return (
      <div className={className ? className : classNames.build()}>
        <input type="checkbox" id={this.props.id} onClick={this.handleCheckboxCliked} defaultChecked={checked} />
        <label
          htmlFor={this.props.id}
          dangerouslySetInnerHTML={{ __html: this.props.label }}
          style={style}
          className={labelClassName ? labelClassName : ""}
        />
      </div>
    );
  }
}

export default CheckboxInput;
