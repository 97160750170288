// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertSpotlightVideoIdFromApi } from 'services/Tv';

function TvApi(executor: ApiExecutorType) {
  return { fetchSpotlightVideo };

  function fetchSpotlightVideo() {
    return executor.get('/ffr/v1/pages?slug=ffr-tv').then(convertSpotlightVideoIdFromApi);
  }
}

export default TvApi;
