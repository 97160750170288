// @flow
import { getDateFromString } from 'utils/dateUtils';
import type { EventType, EventMatchType, EventDetailDateType } from 'types/Event';

export function getEventsLabels(events: Array<EventType>) {
  return events
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .reduce(
      (accumulator, currentValue) =>
        accumulator.find(label => label === currentValue.label) ? accumulator : [...accumulator, currentValue.label],
      []
    );
}

export function getEventDate(detail_date: EventDetailDateType) {
  const date: any = Object.keys(detail_date).reduce(
    (accumulator, key: string) => ({
      ...accumulator,
      [key]: parseInt(detail_date[key], 10)
    }),
    {}
  );
  return new Date(date.year, date.month, date.day, date.hour, date.minutes, date.seconds);
}

export function sortEventsByStartDate(events: Array<EventType | EventMatchType>) {
  return events
    .reduce<any>(
      (
        accumulator: Array<{ date: Date, events: Array<EventType | EventMatchType> }>,
        currentValue: EventType | EventMatchType
      ) => {
        const listWithCurrentDay = accumulator.find(
          l => getDateFromString(currentValue.startDate).getDate() === l.date.getDate()
        );
        if (listWithCurrentDay)
          return [
            ...accumulator.filter(l => l.date.getDate() !== getDateFromString(currentValue.startDate).getDate()),
            { date: getDateFromString(currentValue.startDate), events: [currentValue, ...listWithCurrentDay.events] }
          ];
        else return [...accumulator, { date: getDateFromString(currentValue.startDate), events: [currentValue] }];
      },
      []
    )
    .sort<any>((a, b) => {
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      return 0;
    });
}

export function isEventInCalendarTeam(calendarTeamIds: Array<string>, event: any) {
  const isInTeamCalendar = event.categories ?
    event.categories.some((category) => calendarTeamIds ? calendarTeamIds.includes(category.id) : false )
    : false;

  return isInTeamCalendar || event.hasOwnProperty('adversaire');
};
