// @flow

/**
 * Only for debug env
 * Use idf as default api source
 * instead of host name
 */

function getBaseUrl(): string {
  let source = 'pp.website.ffr.nextmap.io';
  // @TODO Remove comment when push in pp
  if (process.env.NODE_ENV === 'production' && 
    window.location.hostname !== 'dev.website.ffr.nextmap.io' && 
    window.location.hostname !== '127.0.0.1' &&
    window.location.hostname !== '0.0.0.0' &&
    window.location.hostname !== 'localhost') {
    source = window.location.hostname;
  }

  return `https://api.${source}`;
}

function get_youtube_base_url(): string {
  let youtube_proxy = 'https://youtube-api-dev.website.ffr.nextmap.io/youtube/v3';
  if (process.env.NODE_ENV === 'production' && window.location.hostname === 'www.ffr.fr') {
    youtube_proxy = 'https://youtube-api.ffr.nextmap.eu/youtube/v3';
  }

  return youtube_proxy;
}

function getMeilisearchUrl(): string {
  if (process.env.NODE_ENV === 'production' && 
    window.location.hostname !== 'pp.website.ffr.nextmap.io') {
    return 'https://commons-docker-meilisearch.ffr.nextmap.cloud/';
  }

  return 'https://commons-docker-meilisearch-staging.ffr.nextmap.cloud/';
}

function getMeilisearchKey(): string {
  if (process.env.NODE_ENV === 'production' && 
    window.location.hostname !== 'pp.website.ffr.nextmap.io') {
    return '28867d0ba127ab4c4a629a682309c8b8eed9fb3514d9cf47d014fd9caefab4b0';
  }

  return '1944cc48b8eaf238a4798e97e4856c2a3608c8833b3f7e11ea4778289308ef96';
}

export function getAgregateurUrl(): string {
	return 'https://api-agregateur.ffr.fr';
}

export const TIMEOUT = 45000;
export const BASE_URL = getBaseUrl() + '/wp-json';
export const STATIC_BASE_URL = getBaseUrl() + '/static';
export const GRAPH_URL = getAgregateurUrl() + '/graphql';
export const FORM_CONTACT_TOKEN = 'YXBpLWZmcjowUmZ4TkdCJXl3V0huVTNsWWdENzdjNTI=';
export const FORM_CRYPTO_KEY = '0123456789abcdef0123456789abcdef';
export const FORM_CRYPTO_IV = "abcdef9876543210abcdef9876543210";
export const MEILISEARCH_URL = getMeilisearchUrl();
export const MEILISEARCH_KEY = getMeilisearchKey();

export const YOUTUBE_BASE_URL = get_youtube_base_url();

export const RECETTAGE_YOUTUBE_ACCESS_TOKEN_TAB = [
  //'AIzaSyBEmt6F4KuvtK_ZfsZLpgXwkWBZJWBzvtw',
  //'AIzaSyAHR4Lm9SoqN_WUQqyF7CzeFeYHtbuNwaw',
  'AIzaSyAHHQjuc8AmsdLO9RgOMcLl67WezBsETpQ',
  //'AIzaSyBWy73WeNhbg_kR8PuUXnjCYtjpXgN1FMk',
  //'AIzaSyAol2NDGtf_sUMqsrVkNIjhXGL-nUb5eM4',
  //'AIzaSyAYDRsEWCM6qkR649ongqFw15elMYWM4x0',
  //'AIzaSyDyY1NhDTDO-fPk_p4nlQZ_MAdtrnBAmio',
  //'AIzaSyDt_izuof1QylOWPwz5NJfesUbeuJUH8Zo',
  //'AIzaSyCHuiD5FibWuiTqfaGK-pZnlqNNBlVpjAQ'
];
export const YOUTUBE_ACCESS_TOKEN =
  RECETTAGE_YOUTUBE_ACCESS_TOKEN_TAB[Math.floor(Math.random() * RECETTAGE_YOUTUBE_ACCESS_TOKEN_TAB.length)]; //'AIzaSyAHHQjuc8AmsdLO9RgOMcLl67WezBsETpQ';

/*function get_sportagraph_base_url() {
  let url = 'https://apidev.sportagraph.com/';
  if (process.env.NODE_ENV === 'production') {
    url = 'https://api.sportagraph.com/';
  }
  url = 'https://api.sportagraph.com/';
  return url;
}*/

function get_sportagraph_download_url() {
  let url = 'https://dow4dev.sportagraph.com/';
  if (process.env.NODE_ENV === 'production') {
    url = 'https://dow4.sportagraph.com/';
  }
  url = 'https://dow4.sportagraph.com/';
  return url;
}

function get_sportagraph_widget_id() {
  let widget_id = '7cb2-9596-5b80-44e3a28e9856';
  if (process.env.NODE_ENV === 'production') {
    widget_id = 'b883-397c-85c9-400bbfb91731';
  }

  //widget_id = '7cb2-9596-5b80-44e3a28e9856';
  widget_id = 'b883-397c-85c9-400bbfb91731';
  //widget_id = 'eab7-63c5-ac76-460d937a93b0'; // test video
  return widget_id;
}
//export const SPORTAGRAPH_BASE_URL = get_sportagraph_base_url();
export const SPORTAGRAPH_BASE_URL = 'https://mediatheque.ffr.fr/api/v4/';
export const SPORTAGRAPH_DOWNLOAD_URL = get_sportagraph_download_url();
export const SPORTAGRAPH_WIDGET_ID = get_sportagraph_widget_id();