// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_NEW_VIDEOS_START,
  FETCH_NEW_VIDEOS_SUCCESS,
  FETCH_NEW_VIDEOS_FAILURE
} from 'actions/ffr-tv/newVideosActions';

import { type YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  videos: Array<YoutubeVideo>,
  statusVideos: Status
};

const initialState: State = {
  videos: [],
  statusVideos: STATUS_DEFAULT
};
export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_NEW_VIDEOS_START:
      return {
        ...state,
        statusVideos: STATUS_LOADING
      };
    case FETCH_NEW_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.payload.videos,
        statusVideos: STATUS_SUCCESS
      };
    case FETCH_NEW_VIDEOS_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };

    default:
      return state;
  }
}
