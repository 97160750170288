// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import RencontreApi from 'api/rencontre/RencontreApi';
import SeasonsApi from 'api/seasons/SeasonsApi';

import { 
  FETCH_RENCONTRE_START, 
  FETCH_RENCONTRE_BY_FILTERS_START,
  INITAL_FETCH_RENCONTRE_BY_FILTERS_START,
  rencontreFetched, 
  rencontreByFiltersFetched,
  rencontreFetchError,
} from 'actions/rencontreActions';
import type { 
  FETCH_RENCONTRE_START_ACTION,
  FETCH_RENCONTRE_BY_FILTERS_START_ACTION,
  INITAL_FETCH_RENCONTRE_BY_FILTERS_START_ACTION,
} from 'actions/rencontreActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const rencontreApi = new RencontreApi(apiExecutor);
  const seasonsApi = new SeasonsApi(staticApiExecutor);

  return function* rencontreSaga(): GeneratorType {
    yield takeLatest(FETCH_RENCONTRE_START, fetchRencontre);
    yield takeLatest(INITAL_FETCH_RENCONTRE_BY_FILTERS_START, initialFetchRencontreByFilters);
    yield takeLatest(FETCH_RENCONTRE_BY_FILTERS_START, fetchRencontreByFilters);
  };

  function* fetchRencontre(action: FETCH_RENCONTRE_START_ACTION): Saga<void> {
    try {      
      const { slug } = action.payload;
      const rencontre = yield call(rencontreApi.fetchRencontreBySlug, slug);
      yield put(rencontreFetched(rencontre));
    } catch (e) {      
      yield put(rencontreFetchError(e));
    }
  }

  function* initialFetchRencontreByFilters(action: INITAL_FETCH_RENCONTRE_BY_FILTERS_START_ACTION): Saga<void> {
    try {
      const { filters, filters: { equipe }} = action.payload;
      const seasons = yield call(seasonsApi.getSeasonsList, equipe);
      const seasonsResultats = seasons.filter((season) => season.resultats === true);
      let season = seasonsResultats[seasonsResultats.length - 1].id;
      if (filters.season) {
        const seasonsFilter = seasonsResultats.filter((season) => season.id === filters.season);
        if (seasonsFilter && seasonsFilter[0]) {
          season = filters.season;
        }
      }
      const rencontres = yield call(
        rencontreApi.fetchRencontreByFilters, 
        { ...filters, season }
      );
      
      yield put(rencontreByFiltersFetched(rencontres));
    } catch (e) {
      yield put(rencontreFetchError(e));
    }
  }

  function* fetchRencontreByFilters(action: FETCH_RENCONTRE_BY_FILTERS_START_ACTION): Saga<void> {
    try {      
      const { filters } = action.payload;
      const rencontre = yield call(rencontreApi.fetchRencontreByFilters, filters);
      yield put(rencontreByFiltersFetched(rencontre));
    } catch (e) {      
      yield put(rencontreFetchError(e));
    }
  }
}