// @flow
import React, { PureComponent } from 'react';
import FFRtvCard from 'components/FFRtv/FFRtvCard';
import CardCarousel from 'components/fragments/card/CardCarousel';
import CardVideo from 'components/fragments/card/CardVideo';
import type { YoutubeContent } from 'types/YoutubeContent';

type Props = {
  FFRtvCards: YoutubeContent[],
  showCards: boolean,
  hideDescriptions?: boolean,
  image_fond?: string,
  video_fond?: string,
  type: string
};

class FFRtvCardNavigator extends PureComponent<Props> {
  renderCards = (FFRtvCards: YoutubeContent[], type?: string,  video_fond?: string, image_fond?: string): any => {
    if (type === 'liste') {
      return (
        <CardCarousel
          url=""
          elements={FFRtvCards.slice(1).map((data) => ({
            id:data.id,
            title: data.description,
            duration: '',
            img:data.backgroundImage,
            url: `/ffr-tv-player/${data.id}`,
            play_button: `/img/svg/btn_play_competition.svg`
          }))}
          component={CardVideo}
          isSmall={false}
          displayed={3}
        />);
    }
    return FFRtvCards.map((c: YoutubeContent, index: number) => {
      return <FFRtvCard 
        key={c.id} 
        content={c} 
        hideDescriptions={this.props.hideDescriptions === true ? this.props.hideDescriptions : false}
        idnumber={index}
        video_fond={video_fond}
        image_fond={image_fond}
        type={this.props.type} />;
    });
  };

  render() {
    const {type, FFRtvCards, showCards, video_fond, image_fond } = this.props;
    const displayedFFRtvCards = FFRtvCards.slice(type !== 'competition' ? 0 : 1, type !== 'competition' ? 4 : 100);
    if (type === 'competition') {
      return (
        <div className="videos competition">
          <div className="home-tv main">{showCards && this.renderCards(FFRtvCards.slice(0,1), 'main', video_fond, image_fond)}</div>
          <div className="home-tv liste">{showCards && this.renderCards(displayedFFRtvCards, 'liste')}</div>
        </div>
      )
    }
    else {
      return <div className="home-tv">{showCards && this.renderCards(displayedFFRtvCards)}</div>;
    }
  }
}

export default FFRtvCardNavigator;
