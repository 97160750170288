// @flow

import type { ArticleType } from 'types/Article';

export const FETCH_ALL_ARTICLES_START = 'FETCH_ALL_ARTICLES_START';
export const FETCH_ALL_ARTICLES_SUCCESS = 'FETCH_ALL_ARTICLES_SUCCESS';
export const FETCH_ALL_ARTICLES_FAILURE = 'FETCH_ALL_ARTICLES_FAILURE';

export type FETCH_ALL_ARTICLES_START_ACTION = {
  type: 'FETCH_ALL_ARTICLES_START',
  payload: {
    category: any,
    per_page: number
  }
};

export type FETCH_ALL_ARTICLES_FAILURE_ACTION = {
  type: 'FETCH_ALL_ARTICLES_FAILURE'
};

export type FETCH_ALL_ARTICLES_SUCCESS_ACTION = {
  type: 'FETCH_ALL_ARTICLES_SUCCESS',
  payload: {
    articles: ArticleType[]
  }
};

export type Action = FETCH_ALL_ARTICLES_START_ACTION |
  FETCH_ALL_ARTICLES_FAILURE_ACTION |
  FETCH_ALL_ARTICLES_SUCCESS_ACTION;

export function fetchArticles(category: any, per_page?: number): FETCH_ALL_ARTICLES_START_ACTION {
  return {
    type: FETCH_ALL_ARTICLES_START,
    payload: {
      category,
      per_page: per_page ? per_page : 4
    }
  };
}

export function articlesFetched(articles: ArticleType[]): FETCH_ALL_ARTICLES_SUCCESS_ACTION {
  return {
    type: FETCH_ALL_ARTICLES_SUCCESS,
    payload: {
      articles
    }
  };
}

export function articlesFetchError(error: any): FETCH_ALL_ARTICLES_FAILURE_ACTION {
  return {
    type: FETCH_ALL_ARTICLES_FAILURE
  };
}
