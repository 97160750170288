// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertVideoIdFromApiSearch, convertMultipleVideoFromApi } from 'services/Tv';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';

function MostPopularVideosChannel(executor: ApiExecutorType) {
  return { fetchSearchMostPopularVideosChannel, fetchYoutubeVideoFromIds };

  function fetchSearchMostPopularVideosChannel(idChannel: string, maxResults: number = 50) {
    return executor
      .get(
        `search?part=id&key=${YOUTUBE_ACCESS_TOKEN}&channelId=${idChannel}&type=video&maxResults=${maxResults}&order=viewCount`
      )
      .then(convertVideoIdFromApiSearch);
  }

  function fetchYoutubeVideoFromIds(videoIds: Array<string>) {
    return executor
      .get(`videos?key=${YOUTUBE_ACCESS_TOKEN}&part=contentDetails,snippet&id=${videoIds.join()}`)
      .then(convertMultipleVideoFromApi);
  }
}

export default MostPopularVideosChannel;
