// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_FID_MODALES_START,
  FETCH_FID_MODALES_SUCCESS,
  FETCH_FID_MODALES_FAILURE,
  FETCH_FID_OFFRES_START,
  FETCH_FID_OFFRES_SUCCESS,
  FETCH_FID_OFFRES_FAILURE
} from 'actions/fidActions';

import { nullFidModales, nullFidOffres } from 'types/Fid';
import type { FidModalesType, FidOffresType } from 'types/Fid';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  fid_modales_data: FidModalesType,
  fid_modales_status: Status,
  fid_offres: FidOffresType,
  fid_offres_status: Status
};

const initialState: State = {
  fid_modales_data: nullFidModales,
  fid_modales_status: STATUS_LOADING,
  fid_offres: nullFidOffres,
  fid_offres_status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_FID_MODALES_START:
      return {
        ...state,
        fid_modales_status: STATUS_LOADING
      };
    case FETCH_FID_MODALES_SUCCESS:
      return {
        ...state,
        fid_modales_data: action.payload.fid_modales_data,
        fid_modales_status: STATUS_SUCCESS
      };
    case FETCH_FID_MODALES_FAILURE:
      return {
        ...state,
        fid_modales_status: STATUS_FAILURE
      };
    case FETCH_FID_OFFRES_START:
      return {
        ...state,
        fid_offres_status: STATUS_LOADING
      };
    case FETCH_FID_OFFRES_SUCCESS:
      return {
        ...state,
        fid_offres: action.payload.fid_offres,
        fid_offres_status: STATUS_SUCCESS
      };
    case FETCH_FID_OFFRES_FAILURE:
      return {
        ...state,
        fid_offres_status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
