// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_CHANNEL_START,
  FETCH_YOUTUBE_CHANNEL_SUCCESS,
  FETCH_YOUTUBE_CHANNEL_FAILURE
} from 'actions/ffr-tv/youtubeChannelAction';

import { nullChannel, type YoutubeChannelInfo } from 'types/YoutubeChannel';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  channel: YoutubeChannelInfo,
  statusChannel: Status
};

const initialState: State = {
  channel: nullChannel,
  statusChannel: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_CHANNEL_START:
      return {
        ...state,
        statusChannel: STATUS_LOADING
      };
    case FETCH_YOUTUBE_CHANNEL_SUCCESS:
      return {
        ...state,
        channel: action.payload.channel,
        statusChannel: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_CHANNEL_FAILURE:
      return {
        ...state,
        statusChannel: STATUS_FAILURE
      };
    default:
      return state;
  }
}
