// @flow

export type CentenaireClubPreviewType = {
    img: string,
    name: string,
    long: number,
    lat: number,
    adress: {
        locality: string,
    },
    nb_documents: number,
};

export type RawCentenaireClubPreviewType = {
    title: string,
    latitude: number,
    longitude: number,
    embleme: string,
    ville: string,
    nb_documents: Number,
};
export type CentenaireDocumentType = {
  id: string;
  img: string,
  imgPreview: string,
  download: string,
  video: string,
  title: string,
  description: string,
  author: string,
  thumbnail: string,
  date: string,
  tags: string[],
  club: string,
  year: string, 
  copyright: string,
  equipe: string,
  niveau: string,
  type: string,
  lat: null | number,
  lon: null | number,
};

export type RawCentenaireDocumentType = {
    id: string,
    name: string,
    description: string,
    copyright: string,
    thumbnails: {
        mini: string,
        webimage: string,
        thul: string
    },
    videoPreviewURLs: string[],
    tags: string[],
    type: string,
    datePublished: string,
    dateCreated: string,
    model: {
        contentType: string,
        coreDatesCreatedAt: string,
        coreState: string,
        dcSubject: string[],
        xmpDMreleaseDate: string,
        iptcCoreContactCreator: string,
        iptcCoreContentDescription: string,
        iptcCoreContentHeadline: string,
        iptcCoreContentKeywords: Array<string>,
        iptcCoreStatusCopyrightNotice: string,
        coreDatesOriginalDate: string,
        resourceFormat: {
            framing: string,
            height: Number,
            width: Number,
        },
        resourceSizeInBytes: Number,
        resourceThumbnail: {
            data: string,
            height: Number,
            width: Number,
        },
    },
    timestamp: {
        creation: string,
        lastUpdate: string,
    }
};

  
export const nullCentenaireDocument: CentenaireDocumentType = {
    id: '',
    img: '',
    imgPreview: '',
    download: '',
    video: '',
    title: '',
    description: '',
    author: '',
    thumbnail: '',
    date: '',
    tags: ['', '', ''],
    club: '',
    year: '',
    copyright: '',
    equipe: '',
    niveau: '',
    type: '',
    lon: null,
    lat: null,
  };

  export const nullCentenaireClubPreview: CentenaireClubPreviewType = {
    img: '',
    name: '',
    searchName: '',
    long: 0,
    lat: 0,
    adress: {
        locality: '',
    },
    nb_documents: 0,
  };