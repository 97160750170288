// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertFidModalesFromApi, convertFidOffresFromApi } from 'services/Fid';

function FidApi(executor: ApiExecutorType) {
  return {
    fetchFidModales,
    fetchFidOffres
  };

  function fetchFidModales() {
    return executor.get(`/ffr/v1/fid_modales`).then(response => convertFidModalesFromApi(response));
  }

  function fetchFidOffres() {
    return executor.get(`/ffr/v1/fid_offres`).then(response => convertFidOffresFromApi(response));
  }
}

export default FidApi;
