// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_MATCHFUTURETEAM_START,
  FETCH_MATCHFUTURETEAM_SUCCESS,
  FETCH_MATCHFUTURETEAM_FAILURE,
} from 'actions/matchActions';

import type { MatchResultType } from 'types/Match';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  results_future: Array<MatchResultType>,
  status: Status
};

const initialState: State = {
  results_future: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_MATCHFUTURETEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHFUTURETEAM_SUCCESS:
      return {
        ...state,
        results_future: action.payload.results_future,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHFUTURETEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
