// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type CardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_media: {
    src: string
  },
  texte_cta: string,
  liste_enfants?: Array<CardType>,
};

export type NewsNunType = {
  title: string,
  date: Date | string,
  link: string,
  slug: string,
  illustration: string,
  label: {
    id: number,
    name: string,
    slug: string,
  },
}

export type YoutubeVideoType = {
  kind: string,
  etag: string,
  id: string,
  snippet: {
    publishedAt: string,
    channelId: string,
    title: string,
    description: string,
    thumbnails: {
      standard: {
        url: string,
        width: number,
        height: number,
      },
      maxres: {
        url: string,
        width: number,
        height: number
      }
    },
    channelTitle: string,
    tags: Array<string>,
    categoryId: string,
    liveBroadcastContent: string,
    localized: {
      title: string,
      description: string,
    },
    defaultAudioLanguage: string
  },
  contentDetails: {
    duration: string,
    dimension: string,
    definition: string,
    caption: string,
    licensedContent: boolean,
    projection: string
  },
}
export type PageListType = SEOMetaType & {
  title: string,
  description: Array<BlocContent>,
  excerpt: string,
  slug: string,
  typeFlow: string,
  link: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  rencontre_background: string,
  liste_enfants: Array<CardType>,
  parent: number,
  template: string,
  sidebar_content:  Array<BlocContent>,
  niveau: number,
  titre_youtube: string,
  redirect_resultats: string,
  redirect_competitions: string,
  youtube_playlist: Array<YoutubeVideoType>,
  news: Array<NewsNunType>,
  redirection: string,
};

export type RawPageListType = SEOMetaType & {
  title: {
    raw: string
  },
  link: string,
  content: {
    data: Array<BlocContent>
  },
  excerpt: {
    raw: string
  },
  slug: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  liste_enfants: Array<CardType>,
  meta: {
    titre_youtube: string,
    equipe_redirection_competitions: string,
    equipe_redirection_resultats: string,
  },
  youtube_playlist: Array<YoutubeVideoType>,
  parent: number,
  template: string,
  sidebar_content: {
    data: Array<BlocContent>
  },
  niveau: number,
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  },
  news: Array<NewsNunType>
};

export const nullPageList: PageListType = {
  ...nullSeoMeta,
  title: '',
  link: '',
  typeFlow: 'PageListType',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  excerpt: '',
  slug: '',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: ''
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      liste_enfants: [],
    }
  ],
  youtube_playlist: [],
  template: '',
  titre_youtube: '',
  redirect_resultats: '',
  redirect_competitions: '',
  parent: 1,
  sidebar_content: [],
  niveau: 0,
  news: [],
  redirection: '',
};
