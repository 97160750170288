// @flow

import { type YoutubePlaylist } from 'types/YoutubePlaylist';

export const FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START = 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START';
export const FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS = 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS';
export const FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE = 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE';

export type FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START',
  payload: { idChannel: string }
};

export type FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE'
};

export type FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS',
  payload: {
    playlists: Array<YoutubePlaylist>
  }
};

export type Action =
  | FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START_ACTION
  | FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE_ACTION
  | FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS_ACTION;

export function fetchYoutubePlaylistsChannel(idChannel: string): FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START,
    payload: { idChannel }
  };
}

export function playlistsChannelFetched(
  playlists: Array<YoutubePlaylist>
): FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_CHANNEL_SUCCESS,
    payload: { playlists }
  };
}

export function playlistsChannelFetchError(error: any): FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_PLAYLISTS_CHANNEL_FAILURE
  };
}
