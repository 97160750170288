// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import { convertVideoPlayerIdChannelFromApi, convertVideoPlayerIdPlaylistFromApi } from 'services/PlayerVideo';
import { convertMultipleVideoFromApi } from 'services/Tv';

function VideoPlayerChannelApi(executor: ApiExecutorType) {
  return { fetchVideoPlayerChannelId, fetchVideoPlayerChannel, fetchVideoPlayerPlaylist };

  function fetchVideoPlayerChannelId(idVideo: string, idChannel: string) {
    const url = `/search?part=snippet&key=${YOUTUBE_ACCESS_TOKEN}&channelId=${idChannel}&type=video&maxResults=50&order=date`;
    return executor.get(url).then(response => convertVideoPlayerIdChannelFromApi(response));
  }
  function fetchVideoPlayerChannel(idVideos: Array<string>) {
    const url = `/videos?part=snippet,contentDetails&key=${YOUTUBE_ACCESS_TOKEN}&id=${idVideos.join(',')}`;
    return executor.get(url).then(response => convertMultipleVideoFromApi(response));
  }
  function fetchVideoPlayerPlaylist(idPlaylist: string, idVideo: string) {
    const url = `/playlistItems?key=${YOUTUBE_ACCESS_TOKEN}&part=snippet,id&playlistId=${idPlaylist}&maxResults=50`;
    return executor.get(url).then(response => convertVideoPlayerIdPlaylistFromApi(response, idVideo));
  }
}

export default VideoPlayerChannelApi;
