// @flow
import { connect } from 'react-redux';

import { setAcceptCookie } from 'actions/appActions';
import CookieButton, { type StateProps, type DispatchProps } from 'components/Cookie/CookieButton';
import type { DispatchType, StateType } from 'types/Actions';

const CookieButtonContainer = connect(
    (state: StateType): StateProps => ({
        acceptCookie: state.appState.acceptCookie,
    }),
    (dispatch: DispatchType): DispatchProps => ({
        setCookieInfo: (accept: boolean) => dispatch(setAcceptCookie(accept))
    })
)(CookieButton);

export default CookieButtonContainer;
