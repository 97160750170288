//@flow
import type { PrivateAccountDashboardType, RawPrivateAccountDashboardType } from 'types/PrivateAccountDashboardType';

export function convertPrivateAccountDashboardFromApi(
  rawPrivateAccountDashboard: RawPrivateAccountDashboardType[]
): PrivateAccountDashboardType[] {
  if (!rawPrivateAccountDashboard) {
    return [];
  }

  const privateAccountDashboard = rawPrivateAccountDashboard.map(dashboard => {
    return {
      content: dashboard.content.data,
      title: dashboard.title.raw,
      user_profile: dashboard.meta.user_profile
    };
  });

  return privateAccountDashboard;
}
