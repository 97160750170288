// @flow
import React from 'react';
import * as Keycloak from 'keycloak-js';

import dashboardImg from 'assets/img/desktop-space.jpg';
import dashboardMobileImg from 'assets/img/mobile-space.jpg';
import dashboardSixNationsImg from 'assets/img/desktop-tournament-int.jpg';
import dashboardSixNationsMobileImg from 'assets/img/mobile-tournament.jpg';
import { MOBILE_SIZE } from 'constants/app';
import { getCasEndpoint, getInitSsoOptions } from 'constants/sso';

type Props = {
  content: any,
  hideModal: () => void
};

function DashboardAccessNotConnectedImgModalContent({ content, hideModal }: Props) {
  let image_desktop = dashboardImg;
  let image_mobile = dashboardMobileImg;
  let classesParent = 'modal-content box access-dashboard-has-img has-bg';

  if (content && content.dashboard_popup && content.dashboard_popup.format) {
    if (content.dashboard_popup.format === 'image_six_nations') {
      image_desktop = dashboardSixNationsImg;
      image_mobile = dashboardSixNationsMobileImg;
      classesParent = 'modal-content box access-dashboard-not-tournament has-bg';
    } else if (content.dashboard_popup.format === 'sans_image') {
      image_desktop = false;
      image_mobile = false;
      classesParent = 'modal-content box access-dashboard';
    } else if (content.dashboard_popup.format === 'image_perso') {
      if (content.dashboard_popup.image_desktop) {
        image_desktop = content.dashboard_popup.image_desktop;
      }
      if (content.dashboard_popup.image_mobile) {
        image_mobile = content.dashboard_popup.image_mobile;
      }
    }
  }

  const redirectRegister = () => {
    const initSsoOptions = getInitSsoOptions();
    const keycloak = Keycloak(initSsoOptions);
    keycloak.init().then(() => {
      window.location.href = keycloak.createRegisterUrl();
    });
  };

  return (
    <div className={classesParent}>
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0"></i>
      <div
        className={image_mobile ? 'modal-access-dashboard modal-access-dashboard--has-img' : 'modal-access-dashboard'}
      >
        {image_desktop && (
          <div className="modal-access-dashboard__left" style={{ backgroundImage: 'url(' + image_desktop + ')' }}></div>
        )}
        <div className="modal-access-dashboard__right">
          <div className="modal__header" style={{ backgroundImage: image_mobile ? 'url(' + image_mobile + ')' : '' }}>
            <h3 className="mb-lg-4">
              Accédez à votre espace perso FFR et suivez toutes les actualités des Equipes de France !
            </h3>
          </div>
          <div className="modal__wrapper">
            <div className="modal__body mb-3 mb-md-5">
              {window.innerWidth <= MOBILE_SIZE && (
                <ul>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Suivez vos joueurs et joueuses favoris des Équipes de France</p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Consultez des actualités personnalisées</p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">
                      Accédez aux statistiques de votre équipe et aux informations de votre club et de votre ligue
                    </p>
                  </li>
                </ul>
              )}

              {window.innerWidth > MOBILE_SIZE && (
                <ul>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Suivez vos joueurs et joueuses favoris des Équipes de France</p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Consultez des actualités personnalisées</p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Gérez vos préférences newsletters</p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">
                      Accédez aux statistiques de votre équipe et aux informations de votre club et de votre ligue
                    </p>
                  </li>
                  <li className="mb-2">
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Accédez rapidement à tous les services de la FFR</p>
                  </li>
                  <li>
                    <span>
                      <i className="icon icon-check"></i>
                    </span>
                    <p className="mb-0">Bénéficiez d’offres personnalisées de la boutique</p>
                  </li>
                </ul>
              )}
            </div>
            <a className="btn btn--primary btn--full mb-2" role="button" tabIndex={0} onClick={redirectRegister}>
              Créer mon espace perso FFR
            </a>
            <a
              className="btn btn--primary btn--white btn--full"
              href={getCasEndpoint(
                content && content.login_url ? content.login_url : 'https://api.www.ffr.fr/keycloak_login',
                '/tableau-de-bord'
              )}
            >
              Connexion à mon espace perso FFR
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardAccessNotConnectedImgModalContent;
