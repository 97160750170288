// @flow
import type { RawLogeDetailType, LogeDetailType } from 'types/LogeDetail';
import type { LogeListType, RawLogeListType } from 'types/LogeList';
import { nullLogeDetail } from 'types/LogeDetail';
import { nullLogeList } from 'types/LogeList';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertLogeListFromApi(rawLogeList: RawLogeListType): LogeListType {
  if (!rawLogeList) {
    return nullLogeList;
  }

  const {
    title,
    slug,
    excerpt,
    id,
    link,
    meta_title,
    menu_order,
    meta_description,
    rencontres,
    featured_media,
    meta: {
      nombreDePlace,
      exclusivite,
      repas,
      cadeau,
      placeDeParking,
      description,
      prix,
      prix_base,
      venue,
      position,
      featuredVideo,
    },
    tags_list,
  } = rawLogeList;

  return mergeIntoDefault(nullLogeList, {
    title: title.raw,
    description: excerpt.raw,
    slug,
    id,
    link,
    rencontres,
    meta_title,
    meta_description,
    nombreDePlace,
    exclusivite,
    repas,
    order: menu_order,
    cadeau,
    placeDeParking,
    logeDescription: description,
    featured_media,
    prix,
    prix_base,
    venue,
    position,
    tags_list,
    featuredVideo,
  });
}

export function convertLogeDetailFromApi(rawLogeDetails: Array<RawLogeDetailType>): LogeDetailType {
  if (!rawLogeDetails) {
    return nullLogeDetail;
  }

  if (rawLogeDetails[0]) {
    const {
      id,
      title,
      content,
      excerpt,
      slug,
      link,
      liste_enfants,
      meta_title,
      menu_order,
      meta_description,
      featured_media,
      featured_video,
      rencontres,
      meta: {
        nombreDePlace,
        exclusivite,
        repas,
        cadeau,
        prix_base,
        placeDeParking,
        prix,
        venue,
        position,
        featuredVideo,
      },
      tags_list,
    } = rawLogeDetails[0];

    return mergeIntoDefault(nullLogeDetail, {
      title: title.raw,
      id,
      description: excerpt.raw,
      content,
      slug,
      link,
      meta_title,
      order: menu_order,
      rencontres,
      meta_description,
      nombreDePlace,
      exclusivite,
      repas,
      cadeau,
      prix_base,
      placeDeParking,
      liste_enfants,
      featured_media,
      featured_video,
      prix,
      venue,
      position,
      tags_list,
      featuredVideo,
    });
  }

  return nullLogeDetail;
}

export function convertLogeDetailPreviewFromApi(rawLogeDetails: RawLogeDetailType): LogeDetailType {
  if (!rawLogeDetails) {
    return nullLogeDetail;
  }

  const {
    title,
    id,
    content,
    slug,
    link,
    meta_title,
    menu_order,
    meta_description,
    featured_media,
    meta: {
      nombreDePlace,
      exclusivite,
      repas,
      cadeau,
      placeDeParking,
      prix,
      prix_base,
      venue,
      position,
      featuredVideo,
    },
    tags_list,
  } = rawLogeDetails;

  return mergeIntoDefault(nullLogeDetail, {
    title: title.raw,
    description: content.data,
    slug,
    link,
    id,
    order: menu_order,
    meta_title,
    meta_description,
    prix_base,
    nombreDePlace,
    exclusivite,
    repas,
    cadeau,
    placeDeParking,
    featured_media,
    prix,
    venue,
    position,
    tags_list,
    featuredVideo,
  });
}
