function init(appId) {
  (function() {
    var fs = document.createElement('script');

    fs.setAttribute('src', 'https://cdn.facil-iti.app/tags/faciliti-tag.min.js');

    fs.dataset.applicationIdentifier = appId;

    document.head.appendChild(fs);
  })();
}

export default {
  init
};
