// @flow

import type { ArticleType } from 'types/Article';
import type { AuteurType } from 'types/Auteur';

export const FETCH_AUTEUR_START = 'FETCH_AUTEUR_START';
export const FETCH_AUTEUR_SUCCESS = 'FETCH_AUTEUR_SUCCESS';
export const FETCH_AUTEUR_FAILURE = 'FETCH_AUTEUR_FAILURE';
export const FETCH_ARTICLES_AUTEUR_START = 'FETCH_ARTICLES_AUTEUR_START';
export const FETCH_ARTICLES_AUTEUR_SUCCESS = 'FETCH_ARTICLES_AUTEUR_SUCCESS';
export const FETCH_ARTICLES_AUTEUR_FAILURE = 'FETCH_ARTICLES_AUTEUR_FAILURE';

export type FETCH_AUTEUR_START_ACTION = {
  type: 'FETCH_AUTEUR_START',
  payload: {
    slug: string
  }
};

export type FETCH_AUTEUR_FAILURE_ACTION = {
  type: 'FETCH_AUTEUR_FAILURE'
};

export type FETCH_AUTEUR_SUCCESS_ACTION = {
  type: 'FETCH_AUTEUR_SUCCESS',
  payload: {
    auteur : AuteurType
  },
};

export type FETCH_ARTICLES_AUTEUR_START_ACTION = {
  type: 'FETCH_ARTICLES_AUTEUR_START',
  payload: {
    auteur: number,
    page: number,
    per_page: number
  } 
};

export type FETCH_ARTICLES_AUTEUR_FAILURE_ACTION = {
  type: 'FETCH_ARTICLES_AUTEUR_FAILURE'
};

export type FETCH_ARTICLES_AUTEUR_SUCCESS_ACTION = {
  type: 'FETCH_ARTICLES_AUTEUR_SUCCESS',
  payload: {
    articles: Array<ArticleType>,
    articleNumber: number,
  }
};

export type Action = FETCH_ARTICLES_AUTEUR_START_ACTION
                  | FETCH_ARTICLES_AUTEUR_FAILURE_ACTION
                  | FETCH_ARTICLES_AUTEUR_SUCCESS_ACTION
                  | FETCH_AUTEUR_START_ACTION
                  | FETCH_AUTEUR_FAILURE_ACTION
                  | FETCH_AUTEUR_SUCCESS_ACTION;

export function fetchAuteur(slug: string): FETCH_AUTEUR_START_ACTION {
  return {
    type: FETCH_AUTEUR_START,
    payload: { slug }
  };
}

export function auteurFetched(auteur: AuteurType): FETCH_AUTEUR_SUCCESS_ACTION {
  return {
    type: FETCH_AUTEUR_SUCCESS,
    payload: { auteur }
  };
}

export function auteurFetchError(error: any): FETCH_AUTEUR_FAILURE_ACTION {
  return {
    type: FETCH_AUTEUR_FAILURE
  };
}
export function fetchArticlesAuteur(auteur: number, page: number, per_page: number):
  FETCH_ARTICLES_AUTEUR_START_ACTION {
  return {
    type: FETCH_ARTICLES_AUTEUR_START,
    payload: { auteur, page, per_page }
  };
}

export function articlesAuteurFetched(articles: Array<ArticleType>, articleNumber: number):
  FETCH_ARTICLES_AUTEUR_SUCCESS_ACTION {
  return {
    type: FETCH_ARTICLES_AUTEUR_SUCCESS,
    payload: {
      articles,
      articleNumber
    }
  };
}

export function articlesAuteurFetchError(error: any): FETCH_ARTICLES_AUTEUR_FAILURE_ACTION {
  return {
    type: FETCH_ARTICLES_AUTEUR_FAILURE
  };
}
