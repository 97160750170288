// @flow
import FilterOptionsClub from 'components/header/FilterOptionsClub';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/header/FilterOptionsClub';
import { setQuery } from 'actions/filterOptionsActions';

export default connect(
  (state: StateType): StateProps => ({
    query: state.filterOptionsState.query,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    setQuery: (query: string) => dispatch(setQuery(query)),
  })
)(FilterOptionsClub);
