// @flow
import React, { Component } from 'react';

import StatsMatch from 'components/rencontre/StatsMatch';
import { getFaitsDeJeuType } from 'utils/rencontreUtils';

export type MatchStatsProps = {
  reception: any,
  equipeNom: any,
  adversaire: any,
  rencontreData: any
};

type Props = MatchStatsProps;

type State = {
  typeOfFait: Array<string>
};

const convertToVariableName = {
  penalite: 'penalites',
  essai: 'essais',
  transformation: 'transformations',
  carton_jaune: 'cartons_jaunes',
  carton_rouge: 'cartons_rouges',
  drop: 'drops'
};

class MatchStats extends Component<Props, State> {
  state = {
    typeOfFait: []
  };

  static getDerivedStateFromProps(props: Props) {
    const {
      rencontreData: { faits_de_jeu }
    } = props;

    if (faits_de_jeu) {
      return getFaitsDeJeuType(props);
    }

    return null;
  }

  renderStatsMatch = (faitTypes: any) => {
    const {
      rencontreData: {
        meta,
        faits_de_jeu,
        meta: { reception }
      }
    } = this.props;

    return faitTypes.map(faitType => {
      const faitFranceVariableName = 'france_nb_' + convertToVariableName[faitType];
      const faitAdversaireVariableName = 'adversaire_nb_' + convertToVariableName[faitType];

      if (meta.hasOwnProperty(faitFranceVariableName) && meta.hasOwnProperty(faitAdversaireVariableName)) {
        return (
          <StatsMatch
            key={`${faitType}-${meta.equipeNom}-${meta.adversaire}`}
            faitType={faitType}
            faitsDeJeu={faits_de_jeu}
            france_stat={parseInt(meta[faitFranceVariableName], 10)}
            adversaire_stat={parseInt(meta[faitAdversaireVariableName], 10)}
            afficher_faits={meta.afficher_faits_de_jeu || meta.afficher_faits}
            reception={reception}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { reception, equipeNom, adversaire } = this.props;
    const { typeOfFait } = this.state;

    return (
      <>
        <h3 className="mb-3">Statistiques du match</h3>
        <div className="stats-match mb-2">
          <div className="stats-match__header">
            <span>{reception ? equipeNom : adversaire}</span>
            <span>{reception ? adversaire : equipeNom}</span>
          </div>
          <hr />
          <div className="stats-match__body">
            <div className="score">{this.renderStatsMatch(typeOfFait)}</div>
          </div>
        </div>
      </>
    );
  }
}

export default MatchStats;
