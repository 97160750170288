// @flow

import type { JoueurAgregateurType, JoueurType } from 'types/Joueur';

export const RESET_FETCH_JOUEUR = 'RESET_FETCH_JOUEUR';
export const FETCH_JOUEUR_START = 'FETCH_JOUEUR_START';
export const FETCH_JOUEUR_SUCCESS = 'FETCH_JOUEUR_SUCCESS';
export const FETCH_JOUEUR_FAILURE = 'FETCH_JOUEUR_FAILURE';
export const FETCH_JOUEUR_AGREGATEUR_START = 'FETCH_JOUEUR_AGREGATEUR_START';
export const FETCH_JOUEUR_AGREGATEUR_SUCCESS = 'FETCH_JOUEUR_AGREGATEUR_SUCCESS';
export const FETCH_JOUEUR_AGREGATEUR_FAILURE = 'FETCH_JOUEUR_AGREGATEUR_FAILURE';
export const FETCH_JOUEURTEAM_START = 'FETCH_JOUEURTEAM_START';
export const FETCH_JOUEURTEAM_INITIAL = 'FETCH_JOUEURTEAM_INITIAL';
export const FETCH_JOUEURTEAM_SUCCESS = 'FETCH_JOUEURTEAM_SUCCESS';
export const FETCH_JOUEURTEAM_FAILURE = 'FETCH_JOUEURTEAM_FAILURE';

export type RESET_FETCH_JOUEUR_ACTION = {
  type: 'RESET_FETCH_JOUEUR'
};

export type FETCH_JOUEUR_START_ACTION = {
  type: 'FETCH_JOUEUR_START',
  payload: {
    slug: string
  }
};

export type FETCH_JOUEUR_FAILURE_ACTION = {
  type: 'FETCH_JOUEUR_FAILURE'
};

export type FETCH_JOUEUR_SUCCESS_ACTION = {
  type: 'FETCH_JOUEUR_SUCCESS',
  payload: {
    joueur: JoueurType
  }
};

export type FETCH_JOUEUR_AGREGATEUR_START_ACTION = {
  type: 'FETCH_JOUEUR_AGREGATEUR_START',
  payload: {
    id: number
  }
};

export type FETCH_JOUEUR_AGREGATEUR_FAILURE_ACTION = {
  type: 'FETCH_JOUEUR_AGREGATEUR_FAILURE'
};

export type FETCH_JOUEUR_AGREGATEUR_SUCCESS_ACTION = {
  type: 'FETCH_JOUEUR_AGREGATEUR_SUCCESS',
  payload: {
    joueurAgregateur: JoueurAgregateurType
  }
};

export type FETCH_JOUEURTEAM_START_ACTION = {
  type: 'FETCH_JOUEURTEAM_START',
  payload: {
    filters: Object
  }
};

export type FETCH_JOUEURTEAM_INITIAL_ACTION = {
  type: 'FETCH_JOUEURTEAM_INITIAL',
  payload: {
    filters: Object
  }
};

export type FETCH_JOUEURTEAM_FAILURE_ACTION = {
  type: 'FETCH_JOUEURTEAM_FAILURE'
};

export type FETCH_JOUEURTEAM_SUCCESS_ACTION = {
  type: 'FETCH_JOUEURTEAM_SUCCESS',
  payload: {
    joueurs: Array<JoueurType>
  }
};

export type Action =
  | RESET_FETCH_JOUEUR_ACTION
  | FETCH_JOUEUR_START_ACTION
  | FETCH_JOUEUR_FAILURE_ACTION
  | FETCH_JOUEUR_SUCCESS_ACTION
  | FETCH_JOUEUR_AGREGATEUR_START_ACTION
  | FETCH_JOUEUR_AGREGATEUR_FAILURE_ACTION
  | FETCH_JOUEUR_AGREGATEUR_SUCCESS_ACTION
  | FETCH_JOUEURTEAM_START_ACTION
  | FETCH_JOUEURTEAM_FAILURE_ACTION
  | FETCH_JOUEURTEAM_SUCCESS_ACTION
  | FETCH_JOUEURTEAM_INITIAL_ACTION;

export function resetJoueur(): RESET_FETCH_JOUEUR_ACTION {
  return {
    type: RESET_FETCH_JOUEUR
  };
}

export function fetchJoueur(slug: string): FETCH_JOUEUR_START_ACTION {
  return {
    type: FETCH_JOUEUR_START,
    payload: { slug }
  };
}

export function joueurFetched(joueur: JoueurType): FETCH_JOUEUR_SUCCESS_ACTION {
  return {
    type: FETCH_JOUEUR_SUCCESS,
    payload: { joueur }
  };
}

export function joueurFetchError(error: any): FETCH_JOUEUR_FAILURE_ACTION {
  return {
    type: FETCH_JOUEUR_FAILURE
  };
}

export function fetchJoueurAgregateur(id: number): FETCH_JOUEUR_AGREGATEUR_START_ACTION {
  return {
    type: FETCH_JOUEUR_AGREGATEUR_START,
    payload: { id }
  };
}

// eslint-disable-next-line max-len
export function joueurAgregateurFetched(
  joueurAgregateur: JoueurAgregateurType
): FETCH_JOUEUR_AGREGATEUR_SUCCESS_ACTION {
  return {
    type: FETCH_JOUEUR_AGREGATEUR_SUCCESS,
    payload: { joueurAgregateur }
  };
}

export function joueurAgregateurFetchError(error: any): FETCH_JOUEUR_AGREGATEUR_FAILURE_ACTION {
  return {
    type: FETCH_JOUEUR_AGREGATEUR_FAILURE
  };
}

export function initialFetchJoueursTeam(filters: Object): FETCH_JOUEURTEAM_INITIAL_ACTION {
  return {
    type: FETCH_JOUEURTEAM_INITIAL,
    payload: { filters }
  };
}

export function fetchJoueursTeam(filters: Object): FETCH_JOUEURTEAM_START_ACTION {
  return {
    type: FETCH_JOUEURTEAM_START,
    payload: { filters }
  };
}

export function joueursTeamFetched(joueurs: Array<JoueurType>): FETCH_JOUEURTEAM_SUCCESS_ACTION {
  return {
    type: FETCH_JOUEURTEAM_SUCCESS,
    payload: { joueurs }
  };
}

export function joueursTeamFetchError(error: any): FETCH_JOUEURTEAM_FAILURE_ACTION {
  return {
    type: FETCH_JOUEURTEAM_FAILURE
  };
}
