/* eslint-disable no-undef */
// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import centenaireApi from 'api/centenaire/centenaireApi';
import {
  FETCH_CENTENAIREDOCUMENTS_START,
  FETCH_CENTENAIREDOCUMENT_START,
  FETCH_CENTENAIRECLUBS_START,
  FETCH_CENTENAIREDATAS_START,
  centenaireDocumentsFetched,
  centenaireDocumentFetched,
  centenaireDocumentsFetchError,
  centenaireDocumentFetchError,
  centenaireClubsFetched,
  centenaireClubsFetchError,
  centenaireDatasFetched,
  centenaireDatasFetchError,
  type FETCH_CENTENAIREDOCUMENTS_START_ACTION,
  type FETCH_CENTENAIREDOCUMENT_START_ACTION,
  type FETCH_CENTENAIRECLUBS_START_ACTION,
  type FETCH_CENTENAIREDATAS_START_ACTION
} from 'actions/centenaireActions';
import { SPORTAGRAPH_WIDGET_ID } from 'constants/api';

export default function(apiExecutor: ApiExecutorType, ffrStaticApiExecutor: ApiExecutorType) {
  const centenaireApiStatic = new centenaireApi(ffrStaticApiExecutor);
  const centenaireDocumentsApi = new centenaireApi(apiExecutor);

  return function* centenaireSaga(): GeneratorType {
    yield takeLatest(FETCH_CENTENAIREDOCUMENT_START, fetchCentenaireDocument);
    yield takeLatest(FETCH_CENTENAIREDOCUMENTS_START, fetchCentenaireDocuments);
    yield takeLatest(FETCH_CENTENAIRECLUBS_START, fetchCentenaireClubs);
    yield takeLatest(FETCH_CENTENAIREDATAS_START, fetchCentenaireDatas);
  };

  function* fetchCentenaireClubs(action: FETCH_CENTENAIRECLUBS_START_ACTION): Saga<void> {
    try {
      const clubs = yield call(centenaireApiStatic.getCentenaireClubs);
      yield put(centenaireClubsFetched(clubs));
    } catch (e) {
      yield put(centenaireClubsFetchError(e));
    }
  }

  function* fetchCentenaireDatas(action: FETCH_CENTENAIREDATAS_START_ACTION): Saga<void> {
    try {
      const datas = yield call(centenaireApiStatic.getCentenaireDatas);
      yield put(centenaireDatasFetched(datas));
    } catch (e) {
      yield put(centenaireDatasFetchError(e));
    }
  }
  function* fetchCentenaireDocuments(action: FETCH_CENTENAIREDOCUMENTS_START_ACTION): Saga<void> {
    try {
      let datas = {
        id: "d40b0ce1-8c42-47d0-a1df-7615f9eed12b", 
        jsonrpc: "2.0", 
        method: "searchByWidget", 
        params: {
          widgetId: SPORTAGRAPH_WIDGET_ID,
          identified: false,
          bypassCache: true,
          request: {
            query: action.payload.data.queryCentenaire ? action.payload.data.queryCentenaire : "*",
            index: action.payload.data.index ? action.payload.data.index : 0,
            length: action.payload.data.pagination ? action.payload.data.pagination : 10,
            //sort: action.payload.data.sort ? action.payload.data.sort : [{'timestamp.creation': 'desc'}],
            sort: action.payload.data.sort ? action.payload.data.sort : ['dateCreated desc'],
            scope: ['Own', 'Shared'],
            projection: {
              excluded: [
                'model.captureId',
                'model.contentType',
                'model.coreContinentCode',
                'model.coreCountryCode',
                'model.fileName',
                'model.resourceSizeInBytes',
                'model.coreDatesTakenAt',
                'model.exifCopyright',
                'model.exifDatesDateTime',
                'model.exifDatesDateTimeDigitized',
                'model.exifDatesDateTimeOriginal',
                'model.coreState',
                'model.detectedFaces',
                'model.detectedLanguages',
                'model.discardedFaces',
                'model.drm',
                'model.discardedLabels',
                'model.drmNsfwStatus',
                'model.labels',
                'model.bid',
                'model.lastCopyId',
                'model.mainLanguage',
                'model.notifyContentPoolPublication',
                'model.rekognition',
                'model.streamIds',
                'model.taggedFaces',
                'model.vision',
                'model.uploadSource',
                'model.iptcCoreCity',
                'model.iptcCoreCountry',
                'model.iptcCoreCountryCode',
                'model.iptcCoreStatusCreditLine',
                'model.iptcCoreStatusSource',
                'model.resourceThumbnail', 
                'model.coreDatesCreatedAt', 
                'model.coreDatesModifiedAt', 
                'model.resourceFormat',
                'security',
                'index', 
                'comments', 
                'status', 
                'systemTags',
                'timestamp.deletion'
              ],
            }
          },
        }
      };

      const data_document = yield call(centenaireDocumentsApi.getCentenaireDocuments, datas);
      
      if( action.payload.data.documents && 
        (action.payload.data.documents.length > 0) && 
        action.payload.data.mode &&
        (action.payload.data.mode === 'add')) {
        yield put(
          centenaireDocumentsFetched(
            action.payload.data.documents.concat(data_document.documents), 
            data_document.total
          )
        );
      } else {
        yield put(centenaireDocumentsFetched(data_document.documents, data_document.total));
      }
    } catch (e) {
      yield put(centenaireDocumentsFetchError());
    }
  }
  function* fetchCentenaireDocument(action: FETCH_CENTENAIREDOCUMENT_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const data_document = yield call(centenaireDocumentsApi.getCentenaireDocument, slug);
      const data_document_download = yield call(centenaireDocumentsApi.getCentenaireDocumentDownload, slug);
      if (data_document) {
        if (data_document_download) {
          data_document.download = data_document_download;
        }
        yield put(centenaireDocumentFetched(data_document, 1));
      } else {
        yield put(centenaireDocumentFetchError());
      }
    } catch (e) {
      yield put(centenaireDocumentFetchError());
    }
  }
}
