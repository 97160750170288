// @flow
import React from 'react';

import ModalFormCentenaireDownload from 'containers/centenaire/ModalFormCentenaireDownloadContainer';

import logoCentenaireDownload from 'assets/img/centenaire/centenaire-1.jpg';

type Props = {
  content: any,
  hideModal: () => void
};

function CentenaireFormDownloadModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box modal-centenaire modal-centenaire--download">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
      <div className="modal__header">
        <img src={logoCentenaireDownload} alt="" />
        <p>
          Vous êtes sur le point de télécharger une image <br />
          soumise à des droits d’auteurs, veuillez confirmez le respect de ses droits.
        </p>
      </div>
      <div className="modal__wrapper">
        <ModalFormCentenaireDownload {...content} />
      </div>
    </div>
  );
}

export default CentenaireFormDownloadModalContent;
