// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import { FETCH_PAGE_LIST_SUCCESS, FETCH_UNKNOWN, FETCH_PAGE_LIST_START } from 'actions/pageActions';

import type { BlocContent } from 'types/NewsletterBloc';
import type { Status } from 'types/Status';
import type { CardType, NewsNunType, YoutubeVideoType } from 'types/PageList';
import type { ActionType } from 'types/Actions';

export type State = {
  title: string,
  titleParent: string,
  description: Array<BlocContent>,
  excerpt: string,
  slug: string,
  label: {
    id: number,
    name: string,
    slug: string
  },
  featured_media: {
    src: string,
    legend: string
  },
  rencontre_background: string,
  status: Status,
  sidebar_content: Array<BlocContent>,
  meta_title: string,
  meta_description: string,
  template: string,
  titre_youtube: string,
  redirect_resultats: string,
  news: Array<NewsNunType>,
  redirect_competitions: string,
  liste_enfants: Array<CardType>,
  youtube_playlist: Array<YoutubeVideoType>,
  redirection: string,
};

const initialState: State = {
  title: '',
  titleParent: '',
  excerpt: '',
  description: [
    {
      tagName: 'p',
      type: 'element',
      attributes: [],
      children: [
        {
          type: 'text',
          text: 'Chargement du contenu de la page...'
        }
      ]
    }
  ],
  slug: '',
  label: {
    id: 0,
    name: '',
    slug: ''
  },
  featured_media: {
    src: '',
    legend: ''
  },
  rencontre_background: '',
  status: STATUS_LOADING,
  sidebar_content: [],
  meta_title: '',
  meta_description: '',
  template: '',
  titre_youtube: '',
  redirect_resultats: '',
  redirect_competitions: '',
  liste_enfants: [],
  youtube_playlist: [],
  news: [],
  redirection: '',
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PAGE_LIST_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_PAGE_LIST_SUCCESS:
      return {
        ...state,
        title: action.payload.pageList.title,
        description: action.payload.pageList.description,
        excerpt: action.payload.pageList.excerpt,
        slug: action.payload.pageList.slug,
        label: action.payload.pageList.label,
        featured_media: action.payload.pageList.featured_media,
        rencontre_background: action.payload.pageList.rencontre_background,
        sidebar_content: action.payload.pageList.sidebar_content,
        liste_enfants: action.payload.pageList.liste_enfants,
        meta_title: action.payload.pageList.meta_title,
        meta_description: action.payload.pageList.meta_description,
        news: action.payload.pageList.news,
        youtube_playlist: action.payload.pageList.youtube_playlist,
        template: action.payload.pageList.template,
        titre_youtube: action.payload.pageList.titre_youtube,
        redirect_resultats: action.payload.pageList.redirect_resultats,
        redirect_competitions: action.payload.pageList.redirect_competitions,
        redirection: action.payload.pageList.redirection,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN,
      };
    default:
      return state;
  }
}
