// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START,
  youtubeVideosPlaylistsFetched,
  youtubeVideosPlaylistsFetchError,
  type FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START_ACTION
} from 'actions/ffr-tv/youtubeVideosPlaylistsActions';

import VideosPlaylistApi from 'api/ffrtv/VideosPlaylistApi';

export default function(apiExecutor: ApiExecutorType) {
  const videosPlaylistApi = new VideosPlaylistApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START, fetchMonthlyYoutubeVideosPlaylist);
  };

  function* fetchMonthlyYoutubeVideosPlaylist(action: FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START_ACTION): Saga<void> {
    try {
      const {
        payload: { tabIdsPlaylist }
      } = action;
      let arrayVideosPlaylist = [];
      for (let i = 0; i < tabIdsPlaylist.length; i++) {
        const rawYoutubePlaylistItems = yield call(videosPlaylistApi.fetchPlaylistItems, tabIdsPlaylist[i], 50);
        const listvideoId = rawYoutubePlaylistItems.youtubePlaylistItem.map(item => item.id);
        const listvideos = yield call(videosPlaylistApi.fetchYoutubeVideoFromIds, listvideoId);
        arrayVideosPlaylist.push(listvideos);
      }
      yield put(youtubeVideosPlaylistsFetched(arrayVideosPlaylist));
    } catch (e) {
      yield put(youtubeVideosPlaylistsFetchError());
    }
  }
}
