// @flow
import React, { Component } from 'react';
import type { BlocContent, Bloc, Attributes } from 'types/NewsletterBloc';
import ContentBloc from 'components/newsletter/blocs/ContentBloc';

export type Props = {
  childrens: Array<Bloc | BlocContent>,
  attributes: Array<Attributes>,
  tagName: string
};

class HtmlScript extends Component<Props> {
  instance: HTMLElement;

  componentDidMount() {
    const s = (document.createElement('script'): any);
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = this.props.childrens.map((c: Bloc | BlocContent, index) => {
      if (c.type === 'text' && c.text) return c.text;
      else if (c.attributes) return <ContentBloc {...c} key={index} />;
      else return '';
    });
    if (this.props.attributes.length > 0) {
      this.props.attributes.forEach(attr => {
        s[attr.name] = attr.value;
      });
    }
    this.instance.appendChild(s);
  }

  render() {
    return <div ref={(el: any) => (this.instance = el)} />;
  }
}

export default HtmlScript;
