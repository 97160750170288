// @flow
import React, { PureComponent } from 'react';

import DropdownInput from 'components/fragments/input/DropdownInput';
import DropdownRadioInput from 'components/fragments/input/DropdownRadioInput';
import FilterOptionsClubsContainer from 'containers/header/FilterOptionsClubsContainer';
import { getPracticesFromClubs, getCompetitionsFromClubs } from 'utils/clubUtils';
import { CLUB_FILTER_DISTANCES } from 'constants/clubConstants';
import type { ClubPreviewType } from 'types/Club';
import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  clubs: ClubPreviewType[],
  practices: string[],
  competitions: string[],
  activateDistance: boolean,
  location: {
    state: {
      autour: boolean,
      municipality: string | false,
      lat: number | false,
      lng: number | false,
    }
  },
  userPref: FullUserType
};

export type DispatchProps = {
  setPractices: (practice: Array<string>) => void,
  setDistance: (distance: string) => void,
  setCompetitions: (competition: Array<string>) => void
};

type Props = StateProps &
  DispatchProps;

type State = {
  open: boolean,
  practices: Array<{ value: string, label: string }>,
  competitions: Array<{ value: string, label: string }>,
  distances: Array<{ value: string, label: string }>,
  distance: string,
};

class HeaderFindClub extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    open: false,
    practices: [],
    competitions: [],
    distance: '',
    distances: [{ value: '', label: 'Tout' }]
  };

  setPractice = (practice: string) => {
    const { userPref } = this.props;
    if (this.props.practices.includes(practice))
      this.props.setPractices(this.props.practices.filter(p => p !== practice));
    else {
      handleTagEvent(
        'trouver_club_recherche_geolocalisee', 
        'filtre_rugby',
        practice,
        userPref
      );
      this.props.setPractices([...this.props.practices, practice]);
    }
  };
  // comment for later : faut-il garder les filtres distance et competitions ? Pas plus d'indication sur le plan de taggage.

  setDistance = (distance: string) => {
    const { userPref } = this.props;

    handleTagEvent(
      'trouver_club_recherche_geolocalisee', 
      'filtre_distance', 
      `${distance}km`,
      userPref
    );
    this.props.setDistance(distance);
  };

  setCompetition = (competition: string) => {
    const { userPref } = this.props;

    if (this.props.competitions.includes(competition))
      this.props.setCompetitions(this.props.competitions.filter(c => c !== competition));
    else {
      handleTagEvent(
        'trouver_club_recherche_geolocalisee', 
        'filtre_competition', 
        competition,
        userPref  
      );
      this.props.setCompetitions([...this.props.competitions, competition]);
    }
  };

  componentDidMount() {
    this.props.setPractices([]);
    this.props.setDistance('');
    this.props.setCompetitions([]);
    if (this.props.location && this.props.location.state && this.props.activateDistance) {
      this.props.setDistance('80');
      this.setState({distance: '80'});
    }
  }

  handleDistanceChanged = (distance: string) => {
    this.setDistance(distance);
    this.setState({
      distance: distance,
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.activateDistance === false && this.props.activateDistance === true) {
      this.props.setDistance('80');
      this.setState({distance: '80'});
    }
    if (prevProps.clubs !== this.props.clubs) {
      const practices = getPracticesFromClubs(this.props.clubs)
        .filter(p => p)
        .map(p => ({ value: p, label: p }));
      this.setState({ practices });
      const competitions = getCompetitionsFromClubs(this.props.clubs)
        .filter(p => p)
        .map(p => ({ value: p, label: p }));
      this.setState({ competitions });
      const distances = [
        ...CLUB_FILTER_DISTANCES.map(p => ({ value: p, label: `${p}km` })),
        { value: '', label: 'Tout' }
      ];
      this.setState({ distances });
    }
  }

  render() {
    const { activateDistance, practices, competitions } = this.props;
    return (
      <FilterOptionsClubsContainer location={this.props.location}>
        { this.state.practices.length > 0 && 
        <DropdownInput
          label="Types de rugby"
          counter={0}
          id="Types de rubgy"
          options={this.state.practices}
          inputChanged={this.setPractice}
          selected={practices}
        /> }
        { this.state.competitions.length > 0 && 
        <DropdownInput
          label="Compétitions"
          counter={0}
          id="Compétitions"
          options={this.state.competitions}
          inputChanged={this.setCompetition}
          selected={competitions}
        /> }
        <DropdownRadioInput
          prelabel="Distance : "
          isPreLabelThin={false}
          counter={0}
          id="Distance"
          label={ this.state.distance === '' ? 'Tout' : this.state.distance + 'km'}
          options={this.state.distances}
          inputChanged={this.handleDistanceChanged}
          maj={true}
          title={true}
          isActive={activateDistance}
        />
      </FilterOptionsClubsContainer>
    );
  }
}

export default HeaderFindClub;
