//@flow

export const nullMatchResult = {
  id: 0,
  slug: '',
  link: '',
  featured_media: {},
  meta: {},
  joueurs: {},
  competition: {},
  journee: false,
  meta_title: '',
  meta_description: ''
};

export type MatchResultType = {
  id: number,
  slug: string,
  link: string,
  featured_media: Object,
  meta: Object,
  joueurs: Object,
  journee: number|boolean,
  competition: Object,
  meta_title: string,
  meta_description: string
};

export type rawMatchResultType = {
  id: number,
  slug: string,
  link: string,
  featured_media: Object,
  meta: Object,
  joueurs: Object,
  journee: number|false,
  competition: Object,
  meta_title: string,
  meta_description: string,
};
