// @flow
import React from 'react';

import ComposeNewsLetterCat from 'containers/profile/settings/ComposeNewsletterContainerCats';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsSubscriptionModalContent({ content, hideModal }: Props) {
  if (!content) {
    return null;
  }

  return (
    <div className="modal-content box news-subscription-modal modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3>Préférences d&apos;actualités</h3>
        <div className="mt-4">
          <ComposeNewsLetterCat />
        </div>
      </div>
    </div>
  );
}

export default NewsSubscriptionModalContent;
