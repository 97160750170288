// @flow
import React, { PureComponent, Fragment } from 'react';
import ContentBloc from 'components/newsletter/blocs/ContentBloc';
import type { BlocContent, Attributes } from 'types/NewsletterBloc';
import { dynamicClassName } from 'utils/dynamicClassName';

export type Props = {
    childrens: Array<BlocContent>,
    attributes: Array<Attributes>,
    tagName: string
};

export type ComponentState = {
    isOpen: Array<string>,
    index: number
}

class HTMLAccordion extends PureComponent<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            index: -1,
            isOpen: []
        };
    }
    handleClick(index: number) {
        let stateOpen = this.state.isOpen;
        if (stateOpen[index] === 'non') {
            stateOpen[index] = 'oui';
        } else {
            stateOpen[index] = 'non';
        }
        this.setState({
            index: this.state.index + 1,
            isOpen: stateOpen,
        });
    }

    componentDidMount() {
        const { childrens } = this.props;
        const stateOpen = this.state.isOpen;
        childrens.forEach((subchildren: any, index) => {
            stateOpen[index] = 'non';
            if (subchildren.attributes) {
                subchildren.attributes.forEach((attribute: any, index) => {
                    if (attribute.name === 'open') {
                        stateOpen[index] = 'oui';
                    }
                });
            }

            this.setState({ isOpen: stateOpen, index: this.state.index + 1, });
        });
    }

    renderContent = (children: any, index: number) => {
        const stateOpen = this.state.isOpen;
        const contentClassName = dynamicClassName('accordion-panel article');
        if (stateOpen[index] === 'non') {
            contentClassName.add('is-hidden');
        }
        return children.children && children.children.map((subchildren: any, subindex) => {
            if (subchildren.tagName === 'div') {
                return (
                    <div key={subindex} className={contentClassName.build()}>
                        <div className="content">
                            <ContentBloc {...subchildren} />
                        </div>
                    </div>
                );
            }
            return null;
        });
    }

    renderTitle = (children: any, index: number) => {
        return children.children && children.children.map((subchildren: any, subindex) => {
            if (subchildren.tagName === 'summary') {
                const child = (subchildren && subchildren.children) ? subchildren.children[0] : false;
                return (
                    <Fragment key={index}>
                        { child &&
                            <h4 key={subindex}>
                                {(subchildren.children.length > 1 || child.type === 'element') ?
                                    <ContentBloc {...subchildren} /> : child.text
                                }
                            </h4>}
                        { !child &&
                            <h4 key={subindex}></h4>}
                    </Fragment>
                );
            }
            return null;
        });
    }

    renderDetails = () => {
        const { childrens } = this.props;
        const stateOpen = this.state.isOpen;
        const triggerClassName = dynamicClassName('accordion-trigger list-competition__head');
        return childrens.map<any>((children: any, index) => {
            if (stateOpen[index] === 'oui') {
                triggerClassName.add('is-selected');
            } else {
                triggerClassName.remove('is-selected');
            }

            return (
                <Fragment key={index}>
                    <div role="button" tabIndex="0"
                        className={triggerClassName.build()}
                        onClick={this.handleClick.bind(this, index)}
                        onKeyPress={this.handleClick.bind(this, index)}>
                        {this.renderTitle(children, index)}
                        <span className="btn btn--icon" ><i className="icon icon-zoom_plus "></i></span>
                    </div>
                    { this.renderContent(children, index)}
                </Fragment>
            );
        });
    }

    render() {
        return (
            <div className="accordion list-competition box box--white mb-3">
                { this.renderDetails()}
            </div>
        );

    }
}

export default HTMLAccordion;
