// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START,
  FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS,
  FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE
} from 'actions/ffr-tv/youtubeVideosPlaylistsActions';

import { type YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  videos: Array<Array<YoutubeVideo>>,
  statusVideos: Status
};

const initialState: State = {
  videos: [],
  statusVideos: STATUS_DEFAULT,
  nextPageToken: ''
};
export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START:
      return {
        ...state,
        statusVideos: STATUS_LOADING
      };
    case FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS:
      return {
        ...state,
        videos: action.payload.videos,
        statusVideos: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };

    default:
      return state;
  }
}
