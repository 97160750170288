// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import EventApi from 'api/events/EventApi';

import {
  FETCH_MATCH_EVENTS_START,
  matchEventsFetched,
  matchEventsFetchError
} from 'actions/matchEventsActions';
import type { FETCH_MATCH_EVENTS_START_ACTION } from 'actions/matchEventsActions';

export default function(apiExecutor: ApiExecutorType) {
  const matchEventsApi = new EventApi(apiExecutor);

  return function* matchEventsSaga(): GeneratorType {
    yield takeLatest(FETCH_MATCH_EVENTS_START, fetchMatchEvents);
  };

  function* fetchMatchEvents(action: FETCH_MATCH_EVENTS_START_ACTION): Saga<void> {
    try {
      const { from, to } = action.payload;
      const matchEvents = yield call(matchEventsApi.fetchMatchEvents, from, to);
      yield put(matchEventsFetched(matchEvents));
    } catch (e) {
      yield put(matchEventsFetchError());
    }
  }
}
