// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertMatchResultFromApi } from 'services/Match';

function MatchApi(executor: ApiExecutorType) {
  return { 
    fetchMatchResultOfTheWeek, 
    fetchMatchBySlug, 
    fetchMatchsFutureTeam,
    fetchMatchsPastTeam
  };

  function fetchMatchResultOfTheWeek(matchId: number) {
    return executor.get(`/ffr/v1/rencontres_itl/${matchId}`).then(response => convertMatchResultFromApi(response));
  }
  function fetchMatchBySlug(slug: string) {
    return executor
      .get(`/ffr/v1/rencontres_itl?slug=${slug}&per_page=1`)
      .then(response => convertMatchResultFromApi(response));
  }
  function fetchMatchsFutureTeam(start_date: string, team: string, per_page?: number) {
    if (!per_page) per_page = 10;
    return executor
      .get(`/ffr/v1/rencontres_itl?start_date=${start_date}&equipe=${team}&per_page=${per_page}`)
      .then(response => response.map(a => convertMatchResultFromApi(a)));
  }
  function fetchMatchsPastTeam(end_date: string, team: string, per_page?: number) {
    if (!per_page) per_page = 10;
    return executor
      .get(`/ffr/v1/rencontres_itl?end_date=${end_date}&equipe=${team}&per_page=${per_page}`)
      .then(response => response.map(a => convertMatchResultFromApi(a)));
  }
}
export default MatchApi;
