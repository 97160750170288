// @flow
import gql from 'graphql-tag';

import { client } from 'infrastructure/apollo';
import { convertJoueurAgragateurFromGraph } from 'services/Joueur';
import type { JoueurAgregateurType } from 'types/Joueur';

const joueurAgregateurQuery = gql`
  query Joueur($id: Int) {
    Joueur(id: $id) {
      statistique: Statistique {
        pointsMarques
        matchsJoues
        essaisMarques
        penalitesMarques
        transformations
        dropsMarques
      }
    }
  }
`;

export const fetchJoueurAgregateurFromGraph = async (id: number): Promise<JoueurAgregateurType> => {
  const joueurAgregateur = await client.query({
    query: joueurAgregateurQuery,
    variables: { id: id }
  });

  return convertJoueurAgragateurFromGraph(joueurAgregateur.data.Joueur);
};
