// @flow
import React from 'react';

import logoCentenaire from 'assets/img/logo/centenaire_histoire.svg';

export type DispatchProps = {
  displayModal: (modalObject: string) => void
};

type Props = DispatchProps & {
  content: any,
  hideModal: () => void
};

function CentenaireFormErrorModalContent({ content, displayModal, hideModal }: Props) {
  const showModalCentenaire = (e: MouseEvent) => {
    const { displayModal } = this.props;

    e.preventDefault();
    displayModal('MODAL_TICKETING');
  };

  return (
    <div className="modal-content box modal-centenaire modal-centenaire--with-bg modal-centenaire--end ">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
      <div className="modal__header">
        <img src={logoCentenaire} alt="Centenaire FFR" />
        <h3 className="ft-h2 mb-1">{'Oups !'}</h3>
        <p>
          {`Votre fichier n'a pas pu être envoyé.`}
          <br />
          {`Veuillez réessayer ultérieurement (la limite est de 50 Mo).`}
        </p>
        <button className="btn btn--primary btn--white btn--full" onClick={hideModal}>
          Retour au site
        </button>
        <button className="btn btn--primary btn--gold btn--full" onClick={showModalCentenaire}>
          Partager un souvenir
        </button>
      </div>
    </div>
  );
}

export default CentenaireFormErrorModalContent;
