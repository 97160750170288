// @flow

import type { ApiExecutorType } from 'types/ApiExecutorType';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';
import {
  convertVideoNameFromApi,
  convertPlaylistIdFromApi,
  convertRandomVideoIdFromApi
} from 'services/YoutubePlayerVideo';

function RandomVideosApi(executor: ApiExecutorType) {
  return { fetchVideoNameFromId, fetchPlaylistIdFromName, fetchRandomVideoIdPlaylistId };

  function fetchVideoNameFromId(idVideo: string) {
    const url = `/videos?key=${YOUTUBE_ACCESS_TOKEN}&part=snippet&id=${idVideo}`;
    return executor.get(url).then(response => convertVideoNameFromApi(response));
  }
  function fetchPlaylistIdFromName(nameVideo: string) {
    const url = `/search?key=${YOUTUBE_ACCESS_TOKEN}&part=id&type=playlist&maxResults=1&channelId=UCnH0bSmQqDBfNj9kZzCaINw&q=${nameVideo}`;
    return executor.get(url).then(response => convertPlaylistIdFromApi(response));
  }
  function fetchRandomVideoIdPlaylistId(playlistId: string, idVideo: string) {
    const url = `/playlistItems?key=${YOUTUBE_ACCESS_TOKEN}&part=snippet&playlistId=${playlistId}&maxResults=50`;
    return executor.get(url).then(response => convertRandomVideoIdFromApi(response, idVideo));
  }
}

export default RandomVideosApi;
