// @flow
import type { RawPageListType, PageListType } from 'types/PageList';
import type { RawPageNodeType, PageNodeType } from 'types/PageNode';
import type { RawPageDetailType, PageDetailType } from 'types/PageDetail';
import { nullPageList } from 'types/PageList';
import { nullPageNode } from 'types/PageNode';
import { nullPageDetail } from 'types/PageDetail';
import { mergeIntoDefault, convertToValidDateFormat } from 'utils/objectUtils';
import { convertEncodedHTML } from 'utils/convertUtils';
import { store } from './../store';

/**
 * Pages de niveau 1
 */
export function convertPageListFromApi(rawPageList: Array<RawPageListType>): PageListType {
  if (!rawPageList) {
    return nullPageList;
  }

  let liste_enfantsMerged = nullPageList.liste_enfants;
  let youtube_playlistMerged = nullPageList.youtube_playlist;
  let labelMerged = nullPageList.label;

  if (rawPageList.length === 0) {
    return nullPageList;
  }

  const {
    title,
    parent,
    content,
    excerpt,
    slug,
    label,
    link,
    featured_media,
    rencontre_background,
    liste_enfants,
    meta,
    niveau,
    template,
    sidebar_content,
    meta_title,
    meta_description,
    youtube_playlist,
    news,
    redirect: {
      url
    },
  } = rawPageList[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageList.liste_enfants[0], liste_enfant);
    });
  }

  if (youtube_playlist) {
    youtube_playlistMerged = youtube_playlist.map(youtube_video => {
      return mergeIntoDefault(nullPageList.youtube_playlist[0], youtube_video);
    });
  }

  if (label) {
    labelMerged = label;
  }

  return mergeIntoDefault(nullPageList, {
    title: title.raw,
    description: content.data,
    excerpt: excerpt.raw,
    parent,
    link,
    slug,
    label: {
      id: labelMerged.id,
      name: convertEncodedHTML(labelMerged.name),
      slug: labelMerged.slug
    },
    featured_media,
    rencontre_background,
    liste_enfants: liste_enfantsMerged,
    youtube_playlist: youtube_playlistMerged,
    niveau,
    titre_youtube: meta ? meta.titre_youtube : '',
    redirect_resultats: meta ? meta.equipe_redirection_resultats : '',
    redirect_competitions: meta ? meta.equipe_redirection_competitions : '',
    template,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    meta_title,
    meta_description,
    news ,
    redirection: url,
  });
}

/**
 * Page de niveau 2
 */
export function convertPageNodeFromApi(rawPageNode: Array<RawPageNodeType>): PageNodeType {
  if (!rawPageNode) {
    return nullPageNode;
  }

  let liste_enfantsMerged;
  if (rawPageNode.length === 0) {
    return nullPageNode;
  }

  const {
    id,
    title,
    title_parent,
    parent,
    excerpt,
    slug_parent,
    slug,
    rencontre_background,
    featured_media,
    liste_enfants,
    content,
    sidebar_content,
    niveau,
    template,
    meta_title,
    meta_description,
    redirect: {
      url
    },
  } = rawPageNode[0];

  if (liste_enfants) {
    liste_enfantsMerged = liste_enfants.map(liste_enfant => {
      return mergeIntoDefault(nullPageNode.liste_enfants[0], liste_enfant);
    });
  } else {
    liste_enfantsMerged = nullPageNode.liste_enfants;
  }

  return mergeIntoDefault(nullPageNode, {
    id: id,
    title: title.raw,
    titleParent: convertEncodedHTML(title_parent),
    parent,
    description: excerpt.raw,
    content: content.data,
    sidebar_content: sidebar_content ? sidebar_content.data : [],
    slug_parent,
    slug,
    featured_media,
    rencontre_background,
    liste_enfants: liste_enfantsMerged,
    niveau,
    template,
    meta_title,
    meta_description,
    redirection: url,
  });
}

/**
 * Page de niveau 3
 */
export function convertPageDetailFromApi(rawPageDetails: Array<RawPageDetailType>): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }
  const { pageNodeState } = store.getState();

  let rawPageDetailFiltered = rawPageDetails.filter(
    rawPageDetail => rawPageDetail.parent === pageNodeState.pageNode.id
  );

  if (rawPageDetails[0].status === 'draft') {
    rawPageDetailFiltered = rawPageDetails;
  }

  if (rawPageDetailFiltered[0]) {

    const {
      title,
      content,
      slug,
      featured_media,
      rencontre_background,
      niveau,
      documents: rawDocuments,
      personnes,
      sidebar_content,
      personnes_departement,
      personnes_arbitres,
      academies,
      template,
      meta_title,
      meta_description,
      liste_enfants,
      redirect: {
        url
      },
    } = rawPageDetailFiltered[0];

    let membersGroups;

    const documents = (rawDocuments || []).map(doc => {
      if (!doc.date) {
        return doc;
      }

      return {
        ...doc,
        date: convertToValidDateFormat(doc.date),
      };
    });

    if (personnes) {
      membersGroups = personnes.reduce((acc, obj) => {
        const cle = obj.title[0];
        const tmp = acc.filter(tab => tab.name === cle);
        if (tmp.length > 0) {
          tmp[0].child.push(obj);
          return acc;
        } else {
          return [
            ...acc,
            {
              name: cle,
              child: [
                obj
              ],
            }
          ];
        }
      }, [])
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }

    return mergeIntoDefault(nullPageDetail, {
      content: content.data,
      title: title ? title.raw : '',
      slug,
      featured_media,
      rencontre_background,
      niveau,
      documents,
      membersGroups,
      liste_enfants,
      sidebar_content: sidebar_content.data,
      departmentsGroups: personnes_departement,
      arbitresGroups: personnes_arbitres,
      academies,
      template,
      meta_title,
      meta_description,
      redirection: url
    });
  }
  return nullPageDetail;
}

export function convertPageDetailCustomFromApi(rawPageDetails: Array<RawPageDetailType>): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }

  if (rawPageDetails[0]) {

    const {
      title,
      content,
      slug,
      label,
      featured_media,
      niveau,
      sidebar_content,
      personnes_departement,
      personnes_arbitres,
      academies,
      template,
      meta_title,
      meta_description,
      liste_enfants,
      redirect: {
        url
      },
    } = rawPageDetails[0];


    return mergeIntoDefault(nullPageDetail, {
      content: content.data,
      title: title ? title.raw : '',
      slug,
      featured_media,
      niveau,
      label,
      liste_enfants,
      sidebar_content: sidebar_content.data,
      departmentsGroups: personnes_departement,
      arbitresGroups: personnes_arbitres,
      academies,
      template,
      meta_title,
      meta_description,
      redirection: url
    });
  }
  return nullPageDetail;
}

export function convertPageDetailPreviewFromApi(rawPageDetails: RawPageDetailType): PageDetailType {
  if (!rawPageDetails) {
    return nullPageDetail;
  }

  const {
    title,
    content,
    slug,
    featured_media,
    niveau,
    documents: rawDocuments,
    personnes,
    link,
    liste_enfants,
    sidebar_content,
    personnes_departement,
    personnes_arbitres,
    academies,
    template,
    meta_title,
    meta_description,
    redirect: {
      url
    },
  } = rawPageDetails;
  let membersGroups;

  const documents = (rawDocuments || []).map(doc => {
    if (!doc.date) {
      return doc;
    }

    return {
      ...doc,
      date: convertToValidDateFormat(doc.date),
    };
  });

  if (personnes) {
    membersGroups = personnes.reduce((acc, obj) => {
      const cle = obj.title[0];
      const tmp = acc.filter(tab => tab.name === cle);
      if (tmp.length > 0) {
        tmp[0].child.push(obj);
        return acc;
      } else {
        return [
          ...acc,
          {
            name: cle,
            child: [
              obj
            ],
          }
        ];
      }
    }, [])
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  return mergeIntoDefault(nullPageDetail, {
    content: content.data,
    title: title ? title.raw : '',
    slug,
    link,
    featured_media,
    niveau,
    documents,
    membersGroups,
    liste_enfants,
    sidebar_content: sidebar_content.data,
    departmentsGroups: personnes_departement,
    arbitresGroups: personnes_arbitres,
    academies,
    template,
    meta_title,
    meta_description,
    redirection: url
  });
}
