// @flow
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import type { RouterProps } from 'types/Router';

import Components from './modalContents/ModalContentList';

export type StateProps = {
  modalObject?: string,
  content?: Object,
  force?: boolean
};

export type DispatchProps = {
  hideModal: () => void
};

type Props = RouterProps & StateProps & DispatchProps;

type State = {
  currentModal: string,
  isOpen: boolean
};

class Modal extends Component<Props, State> {
  state: State = {
    currentModal: 'NO_MODAL',
    isOpen: false
  };

  static defaultProps = {
    modalObject: 'NO_MODAL',
    content: {},
    props: false
  };

  componentDidMount() {
    this.setState({ currentModal: this.props.modalObject });
  }

  /**
   * Avant l'update, vérifier que l'on puisse faire cet update
   * Une modale ouverte ne peut être remplacée, sauf par la modale 0 ou si on force le changement
   */
  shouldComponentUpdate(nextProps: Props) {
    const { modalObject, force } = nextProps;
    const { isOpen } = this.state;

    if (isOpen && modalObject !== 'NO_MODAL' && !force) {
      return false;
    }

    return true;
  }

  /**
   * Au changement de numéro de modal,
   * si le numéro est à 0, on indique qu'elle est fermée, sinon ouverte
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.modalObject !== this.props.modalObject) {
      if (this.props.modalObject === 'NO_MODAL') {
        this.setState({ currentModal: this.props.modalObject, isOpen: false });
      } else {
        this.setState({ currentModal: this.props.modalObject, isOpen: true });
      }
    }
  }

  render() {
    const { hideModal, content } = this.props;
    // https://stackoverflow.com/a/48269720
    const ComponentToRender = Components[this.state.currentModal];

    if (!ComponentToRender) {
      return null;
    }

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay"></div>
          <ComponentToRender hideModal={hideModal} content={content} />
        </div>
      </section>
    );
  }
}

export default withRouter(Modal);
