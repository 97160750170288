// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import { 
  FETCH_RENCONTRE_START,
  FETCH_RENCONTRE_SUCCESS,
  FETCH_RENCONTRE_FAILURE,
  FETCH_RENCONTRE_BY_FILTERS_START,
  INITAL_FETCH_RENCONTRE_BY_FILTERS_START,
  FETCH_RENCONTRE_BY_FILTERS_SUCCESS,
} from 'actions/rencontreActions';
import { nullRencontre } from 'types/Rencontre';

import type { RencontreType } from 'types/Rencontre';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  rencontre: RencontreType,
  rencontres: Array<RencontreType>,
  status: Status
};

const initialState: State = {
  rencontre: nullRencontre,
  rencontres: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RENCONTRE_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_RENCONTRE_BY_FILTERS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case INITAL_FETCH_RENCONTRE_BY_FILTERS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_RENCONTRE_SUCCESS:
      return {
        ...state,
        rencontre: action.payload.rencontre,
        status: STATUS_SUCCESS
      };
    case FETCH_RENCONTRE_BY_FILTERS_SUCCESS:
      return {
        ...state,
        rencontres: action.payload.rencontres,
        status: STATUS_SUCCESS
      };
    case FETCH_RENCONTRE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}