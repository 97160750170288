// @flow
import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { dynamicClassName } from 'utils/dynamicClassName';
import CardChannel from 'components/fragments/card/CardChannel';
import withScreenType from 'components/fragments/withScreenType';
import { areArrayEquals } from 'utils/objectUtils';

type Props = {
  isSmall: boolean,
  title?: string,
  subtitle?: string,
  url?: string,
  elements: Array<any>,
  component: any,
  displayed: number
};

type State = {
  skipped: number,
  elements: Array<any>
};

class CardCarousel extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    skipped: 0,
    elements: []
  };

  static defaultProps = {
    elements: [],
    component: CardChannel,
    url: '#',
    displayed: 4
  };

  componentDidMount() {
    this.setElementsWithoutDuplicate();
  }

  componentDidUpdate() {
    if (
      !areArrayEquals(
        this.state.elements.map(e => e.id),
        this.props.elements.map(e => e.id)
      )
    ) {
      this.setElementsWithoutDuplicate();
    }
  }

  setElementsWithoutDuplicate = () => {
    this.setState({
      elements: this.props.elements.reduce((accumulator, currentValue) => {
        if (accumulator.find(e => e.id === currentValue.id)) return accumulator;
        else return [...accumulator, currentValue];
      }, [])
    });
  };

  handleCarouselNext = (e: MouseEvent) => {
    e.preventDefault();
    if (this.state.skipped < this.state.elements.length - (this.props.displayed - 1))
      this.setState(previousState => ({ skipped: previousState.skipped + 1 }));
  };

  handleCarouselPrevious = (e: MouseEvent) => {
    e.preventDefault();
    if (this.state.skipped > 0) this.setState(previousState => ({ skipped: previousState.skipped - 1 }));
  };

  renderElements = (): Array<any> => {
    const { component: Component } = this.props;
    return this.state.elements
      .slice(this.state.skipped, this.state.skipped + this.props.displayed)
      .map(element => <Component key={element.id} {...element} />);
  };

  renderTitleLink = (forSmall: boolean) => {
    if (forSmall !== this.props.isSmall || !this.props.subtitle) return null;

    const classNamesLink = dynamicClassName('link-chevron link-chevron--white section__link mt-1');
    !forSmall && classNamesLink.add('link-chevron--right');
    return (
      <Link to={this.props.url || '#'} className={classNamesLink.build()} data-label="">
        {this.props.subtitle && <span>{this.props.subtitle}</span>}
        <i className="icon icon-chevron-right" />
      </Link>
    );
  };

  render() {
    const classNamesPrevious = dynamicClassName('link-icon link-icon--large link-icon--white carousel__prev');
    this.state.skipped === 0 && classNamesPrevious.add('is-disabled');

    const classNamesNext = dynamicClassName('link-icon link-icon--large link-icon--white carousel__next');
    this.state.skipped >= this.state.elements.length - (this.props.displayed - 1) && classNamesNext.add('is-disabled');

    return (
      <Fragment>
        {this.renderTitleLink(false)}
        {this.props.title && <h3 className="ft-h2 mb-3 mb-lg-4">{this.props.title}</h3>}
        <div className="carousel carousel--tv">
          <div className="carousel__nav">
            <span
              className={classNamesPrevious.build()}
              onClick={this.handleCarouselPrevious}
              role="button"
              tabIndex={0}
            >
              <i className="icon icon-chevron-left" />
            </span>
            <span className={classNamesNext.build()} onClick={this.handleCarouselNext} role="button" tabIndex={0}>
              <i className="icon icon-chevron-right" />
            </span>
          </div>
          <div className="carousel__wrapper">{this.renderElements()}</div>
          {this.renderTitleLink(true)}
        </div>
      </Fragment>
    );
  }
}

export default withScreenType(CardCarousel);
