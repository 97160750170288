// @flow
import type { RawNewsletterFavoritesArticleType, ArticleType } from 'types/Article';
import type { EventMatchType, RawEventMatchType } from 'types/Event';
import type { BlocContent } from 'types/NewsletterBloc';

export type UserProfile = {
  id: string,
  nom: string,
  prenom: string,
  surnom: string,
  email: string,
  fonction: string,
  genre: string,
  date_naissance: string,
  ville_naissance: string,
  telephone: string,
  situation: string,
  licence: string
};

export type LastMatchsAgregatorType = {
  id: string,
  dateEffective: string,
  Competition: {
    id: string,
    nom: string,
    identifiant: string
  },
  CompetitionEquipeLocale: {
    id: string,
    nom: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      nom: string,
      StructuresItem?: {
        id: string,
        nom: string
      }[]
    }
  },
  CompetitionEquipeVisiteuse: {
    id: string,
    nom: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      nom: string,
      StructuresItem?: {
        id: string,
        nom: string
      }[]
    }
  },
  RencontreResultatLocale: {
    pointsDeMarque: number,
    essais: number,
    butsApresEssai: number,
    butsPenalite: number,
    dropGoals: number,
    essaisDePenalite: number
  },
  RencontreResultatVisiteuse: {
    pointsDeMarque: number,
    essais: number,
    butsApresEssai: number,
    butsPenalite: number,
    dropGoals: number,
    essaisDePenalite: number
  }
};

type UserType = {
  identifie: boolean,
  login: string,
  joueurs_favoris: number[],
  joueurs_suivis: number[],
  equipes_favoris: number[],
  alertes_billetterie: number[],
  newsletter: string[],
  avatar: string,
  profil: UserProfile,
  newsletters: { rendered: string, data: Array<BlocContent> }
};

type SizeType = {
  src: string,
  width: number,
  height: number
};

export type FeaturedMediaType = {
  id: number,
  src: string,
  title: string,
  legend: string,
  alt: string,
  width: number,
  height: number,
  sizes: {
    thumbnail: SizeType,
    medium: SizeType,
    medium_large: SizeType,
    large: SizeType
  }
};

export type JoueurFavorisType = {
  id: number,
  title: string,
  equipe: string,
  link: string,
  nom: string,
  prenom: string,
  featured_media: FeaturedMediaType,
  poste: {
    id: number,
    name: string
  }
};

export type JoueurSuivisType = {
  id: number,
  title: string,
  equipe: string,
  link: string,
  views: string,
  nom: string,
  prenom: string,
  featured_media: FeaturedMediaType
};

export type EquipeFavorisType = {
  id: number,
  title: string,
  equipe: string,
  link: string,
  nom: string,
  prenom: string,
  featured_media: FeaturedMediaType,
  poste: {
    id: number,
    name: string
  }
};

export type MyClubType = {
  id: number,
  nom: string,
  telephone: string,
  website: string,
  email: string,
  adresse: string,
  ville: string,
  code_postal: string,
  featured_media: string,
  id_competition?: string,
  clubs_ligue?: number,
  licencies?: number,
  clubs_importants?: any[],
  rs?: {
    facebook?: string,
    twitter?: string
  },
  stade?: string,
  competitions?: any[],
  last_results?: LastMatchsAgregatorType[],
  last_article?: any,
  next_event?: any,
  link_news?: string,
  link_events?: string
};

export type NewsletterContenuType = {
  [key: number]: string
};

export type ClubActuelType = {
  id: string,
  code: string,
  nom: string
};

type Sexe = {
  code: string,
  id: string,
  nom: string
};

type ClasseAge = {
  Sexe: Sexe,
  code: string,
  id: string,
  nom: string
};

type FullScoreType = {
  bonusDefensif: number,
  bonusOffensif: number,
  essaisConcedes: number,
  essaisMarques: number,
  gagnes: number,
  goalAverage: number,
  joues: number,
  nuls: number,
  perdus: number,
  pointTerrain: number,
  pointsDeMarqueAquis: number,
  pointsDeMarqueConcedes: number,
  position: number,
  regulationPointsTerrain: number | null
};

type StructureType = {
  code_postal: string,
  couleurs: string,
  est_rassemblement: boolean,
  id: number,
  identifiant: string,
  logo: boolean,
  nom: string,
  region?: string,
  regroupementsParticipants?: any[],
  ville: string
};

type ClassementType = {
  poule_id: number,
  poule_rang: number,
  score: FullScoreType,
  structure: StructureType,
  structure_id: number
};

type ClassementAgregatorType = {
  positionIntraPoule: number,
  positionInterPoule: number,
  id: string,
  Equipe: {
    nom: string,
    identifiant: string,
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      embleme: string,
      StructuresItem: {
        id: string,
        nom: string
      }[]
    }
  }[],
  Classement: {
    gagnes: number,
    joues: number,
    nuls: number,
    perdus: number,
    pointTerrain: number
  }
};

export type ClassementIntraAgregatorType = {
  id: string,
  ordrePoule: number,
  pouleId: string,
  position: number,
  Classement: {
    gagnes: number,
    joues: number,
    nuls: number,
    perdus: number,
    pointTerrain: number
  },
  Classements: {
    position: number,
    Equipe: {
      nom: string,
      identifiant: string,
      DerniersResultats?: any[],
      Structure?: {
        id: string,
        nom: string,
        embleme: string
      },
      Regroupement?: {
        id: string,
        embleme: string,
        StructuresItem: {
          id: string,
          nom: string
        }[]
      }
    },
    Classement: {
      gagnes: number,
      joues: number,
      nuls: number,
      perdus: number,
      pointTerrain: number
    }
  }[],
  dernieresRencontresPoule: LastMatchsAgregatorType[]
};

type CompetitionsPhases = {
  classement?: ClassementType[],
  id: string,
  nom: string,
  nombrePoules: number,
  CompetitionPhaseType: { id: string, nom: string }
};

type LastMatchsType = {
  competition: string,
  date: string,
  id: number,
  journee: string,
  local_structure: StructureType,
  score_structure_locale: number,
  score_structure_visiteur: number,
  statut: string,
  terrain: {
    nom: string,
    lat: number,
    lng: number
  },
  visitor_structure: StructureType
};

export type UserTeamPoints = {
  structureId?: number,
  nom: string,
  embleme: string,
  totalpointdemarque: string
};

type UserCompetitionType = {
  id: string,
  nom: string,
  nomCategorie: string,
  currentPhaseId: string,
  idCategorie: string,
  identifiant: string,
  identifiantCategorie: string,
  nomCourt: string,
  classeAge: ClasseAge,
  Famille: { id: string, nom: string },
  classement_club: ClassementAgregatorType,
  classement_club_intra: ClassementIntraAgregatorType,
  poules: any[],
  Phases: CompetitionsPhases[],
  rencontres_club: LastMatchsAgregatorType[],
  Equipes: {
    id: string,
    nom: string,
    CompetitionPoule: { id: string, ordre: number },
    Statistique: {
      pointsMarques: number,
      pointsEncaisses: number
    },
    Structure?: {
      id: string,
      nom: string,
      embleme: string
    },
    Regroupement?: {
      id: string,
      nom: string,
      embleme: string,
      StructuresItem?: {
        id: string,
        nom: string,
        embleme: string
      }[]
    }
  }[],
  pointsMarquesParClub: UserTeamPoints[],
  pointsEncaissesParClub: UserTeamPoints[]
};
type UserTeamType = {
  competitions: UserCompetitionType[],
  derniers_matchs: LastMatchsType[]
};

export type FavoriteClubsType = {
  fav_competition: null,
  id: number,
  rang: number,
  structure: StructureType
};

export type NewsletterThemesType = {
  [key: string]: string
};

export type MainNewsType = {
  id: number,
  date: string,
  duration: string,
  featured_media: string,
  link: string,
  title: string,
  type: string
};

export type RawDataSaleforce = {
  XV_de_France_Feminin: boolean,
  XV_de_France_Masculin: boolean,
  au_coeur_du_jeu: boolean,
  barbarians_Francais: boolean,
  billingCity: string,
  billingCountry: string,
  billingPostalCode: string,
  billingStreet: string,
  birthdate: Date,
  city: string,
  clubs: number[],
  country: string,
  emailAddress: string,
  equipes_de_France: boolean,
  etre_acteur: boolean,
  federation: boolean,
  firstName: string,
  france_7_Masculin: boolean,
  france_7_feminin: boolean,
  france_moins_de_20: boolean,
  joueurs_favoris: number[],
  lastName: string,
  licenseNumber: string,
  optin_FFR: boolean,
  optin_newsletter: boolean,
  optin_partenaires: boolean,
  phone: string,
  postalCode: string,
  salesforceId: string,
  salutation: string,
  ssoIdentifier: string,
  street: string
};

export type DataSaleforce = {
  emailAddress: string,
  optin_FFR: boolean,
  optin_newsletter: boolean,
  optin_partenaires: boolean
};

type MiddlewareUserType = {
  birth_city: string,
  birth_date: string,
  competition_user: number,
  date_created: string,
  date_updated: string,
  email: string,
  espace_perso: any, // TBD
  espace_perso_error: any, // TBD
  first_name: string,
  gender: string,
  id_sso: string,
  last_name: string,
  licenced: boolean,
  nickname: string,
  phone: string,
  phone_mobile: string,
  poste: string,
  sf_id: string
};

export type FullUserType = MiddlewareUserType & {
  alertes_billetterie_detail: EventMatchType[],
  annees_pratiques: number,
  club: MyClubType,
  club_actuel: ClubActuelType,
  clubs_pratiques: any[],
  competitions_clubs_favoris: FavoriteClubsType[],
  dashboard_profile_list: string[],
  equipes_favoris_detail: EquipeFavorisType[],
  historique_pratiques: any[],
  is_arbitre: boolean,
  is_dirigeant: boolean,
  is_player: boolean,
  is_player_club?: string,
  joueurs_favoris_detail: JoueurFavorisType[],
  joueurs_suivis_detail: JoueurSuivisType[],
  licence: string,
  licenceefunctions: string,
  ligue: MyClubType,
  main_news: MainNewsType,
  mon_equipe: UserTeamType,
  my_boutique_profile_list: string[],
  newsletter: string[],
  newsletter_feed: ArticleType[],
  newsletter_themes: NewsletterThemesType,
  percent_profile_completed: number,
  profile: string,
  saleforce: DataSaleforce,
  token: string,
  url_change_mail: string,
  url_change_pass: string,
  url_lier_ovale: string
} & UserType;

export type RawFullUserType = {
  alertes_billetterie_detail: RawEventMatchType[],
  annees_pratiques: number,
  club: MyClubType,
  club_actuel: ClubActuelType,
  clubs_pratiques: any[],
  competitions_clubs_favoris: FavoriteClubsType[],
  dashboard_profile_list: string[],
  data_salesforce: RawDataSaleforce[],
  equipes_favoris_detail: EquipeFavorisType[],
  historique_pratiques: any[],
  is_arbitre: boolean,
  is_dirigeant: boolean,
  is_player: boolean,
  is_player_club?: string,
  joueurs_favoris_detail: JoueurFavorisType[],
  joueurs_suivis_detail: JoueurSuivisType[],
  licence: string,
  licenceefunctions: string,
  ligue: MyClubType,
  main_news: MainNewsType,
  mon_equipe: UserTeamType,
  my_boutique_profile_list: string[],
  newsletter: string[],
  newsletter_feed: RawNewsletterFavoritesArticleType[],
  newsletter_themes: NewsletterThemesType,
  percent_profile_completed: number,
  profile: string,
  token: string,
  url_change_mail: string,
  url_change_pass: string,
  url_lier_ovale: string
} & UserType;

export type UserFavsType = {
  joueurs_favoris: number[],
  joueurs_favoris_nom_img: { [key: string]: any[] },
  joueurs_favs_videos: FavVideoType[],
  joueurs_favs_news: FavNewsType[],
  joueurs_tags: string[],
  joueurs_tags_id: number[],
  joueurs_tags_nom_id: { [key: string]: number },
  equipes_favoris_nom_img: { [key: string]: any[] },
  equipes_cats_id: number[],
  equipes_favs_news: FavNewsType[],
  equipes_favs_videos: FavVideoType[]
};

export type RawUserFavsType = UserFavsType;

export type FavNewsType = {
  id: number,
  title: string,
  type: string,
  date: string,
  duration: string,
  link: string,
  featured_media: string,
  illustration: string,
  tags: NewsTagType[],
  label: any
};

export type FavVideoType = {
  id: number,
  title: string,
  type: string,
  date: string,
  duration: string,
  featured_media: string,
  illustration: string,
  link: string,
  tags: any[],
  label: any
};

export type NewsTagType = {
  name: string,
  featured_image: string
};

export type UserFidType = {
  fid: any,
  fid_error: any,
}

export const nullUserFavs: UserFavsType = {
  joueurs_favoris: [],
  joueurs_favoris_nom_img: {},
  // joueurs_favoris_nom_id: {},
  joueurs_favs_videos: [],
  joueurs_favs_news: [],
  joueurs_tags: [],
  joueurs_tags_id: [],
  joueurs_tags_nom_id: {},
  equipes_favoris_nom_img: {},
  equipes_cats_id: [],
  equipes_favs_news: [],
  equipes_favs_videos: []
};

export type EditProfileValueType = UserProfile | string[] | string;

export const nullSaleforce: DataSaleforce = {
  emailAddress: '',
  optin_FFR: false,
  optin_newsletter: false,
  optin_partenaires: false
};

const nullMiddlewareUser: MiddlewareUserType = {
  birth_city: '',
  birth_date: '',
  competition_user: 0,
  date_created: '',
  date_updated: '',
  email: '',
  espace_perso: {}, // TBD
  espace_perso_error: false, // TBD
  first_name: '',
  gender: '',
  id_sso: '',
  last_name: '',
  licenced: false,
  nickname: '',
  phone: '',
  phone_mobile: '',
  poste: '',
  sf_id: ''
};

export const nullUserPrefs: FullUserType = {
  ...nullMiddlewareUser,
  alertes_billetterie: [],
  alertes_billetterie_detail: [],
  annees_pratiques: 0,
  avatar: '',
  club: {
    id: 0,
    nom: '',
    telephone: '',
    website: '',
    email: '',
    adresse: '',
    ville: '',
    code_postal: '',
    featured_media: '',
    last_results: []
  },
  club_actuel: {
    id: '',
    code: '',
    nom: ''
  },
  clubs_pratiques: [],
  competitions_clubs_favoris: [],
  dashboard_profile_list: [],
  equipes_favoris: [],
  historique_pratiques: [],
  identifie: false,
  is_arbitre: false,
  is_dirigeant: false,
  is_player: false,
  is_player_club: '',
  joueurs_favoris: [],
  joueurs_favoris_detail: [],
  joueurs_suivis: [],
  licence: '',
  licenceefunctions: '',
  ligue: {
    id: 0,
    nom: '',
    telephone: '',
    website: '',
    email: '',
    adresse: '',
    ville: '',
    code_postal: '',
    featured_media: ''
  },
  joueurs_suivis_detail: [],
  equipes_favoris_detail: [],
  login: '',
  main_news: {
    id: 0,
    date: '',
    duration: '',
    featured_media: '',
    link: '',
    title: '',
    type: ''
  },
  mon_equipe: {
    competitions: [],
    competition: {
      age: '',
      competition_type: {
        genre: 0,
        nom_famille: '',
        nom_age: '',
        nom: ''
      },
      id: 0,
      identifiant: '',
      nom: '',
      phases: {
        ClassementsIntraPoule: [],
        ClassementsInterPoule: [],
        id: 0,
        nom: '',
        nombrePoules: '',
        CompetitionPhaseType: {
          id: 0,
          nom: ''
        }
      }
    },
    pointsMarquesParClub: [],
    pointsEncaissesParClub: [],
    derniers_matchs: []
  },
  my_boutique_profile_list: [],
  newsletter: [],
  newsletter_feed: [],
  newsletter_themes: {},
  newsletters: { rendered: '', data: [] },
  percent_profile_completed: 0,
  profil: {
    id: '',
    nom: '',
    prenom: '',
    surnom: '',
    email: '',
    fonction: '',
    genre: '',
    date_naissance: '',
    ville_naissance: '',
    situation: '',
    telephone: '',
    licence: ''
  },
  profile: 'simple',
  saleforce: {
    ...nullSaleforce
  },
  token: '',
  url_change_mail: '',
  url_change_pass: '',
  url_lier_ovale: ''
};

export const nullUserFid = {
  fid: {},
  fid_error: false,
}