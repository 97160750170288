// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_TEAMS_START, FETCH_TEAMS_SUCCESS, FETCH_TEAMS_FAILURE,
  FETCH_EQUIPESEARCH_START, FETCH_EQUIPESEARCH_SUCCESS, FETCH_EQUIPESEARCH_FAILURE
} from 'actions/teamlistActions';

import type { EquipeSearchType, TeamList } from 'types/TeamList';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  equipe_search: EquipeSearchType[],
  equipe_search_status: Status,
  teamlist: TeamList[],
  status: Status
};

const initialState: State = {
  equipe_search: [
    {
      featured_image: '',
      id: 0,
      title: ''
    }
  ],
  equipe_search_status: STATUS_LOADING,
  teamlist: [],
  status: STATUS_LOADING,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type)
  {
    case FETCH_TEAMS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        teamlist: action.payload.teamlist,
        status: STATUS_SUCCESS
      };
    case FETCH_TEAMS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_EQUIPESEARCH_START:
      return {
        ...state,
        equipe_search_status: STATUS_LOADING
      };
    case FETCH_EQUIPESEARCH_SUCCESS:
      return {
        ...state,
        equipe_search: action.payload.equipe_search,
        equipe_search_status: STATUS_SUCCESS
      };
    case FETCH_EQUIPESEARCH_FAILURE:
      return {
        ...state,
        equipe_search_status: STATUS_FAILURE
      };    
    default:
      return state;
  }
}
