// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START = 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START';
export const FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS = 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS';
export const FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE = 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE';

export type FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START_ACTION = {
  type: 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START',
  payload: { idChannel: string }
};

export type FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>
  }
};

export type FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE'
};

export type Action =
  | FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START_ACTION
  | FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS_ACTION
  | FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE_ACTION;

export function fetchMostPopularVideosChannel(
  idChannel: string
): FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START_ACTION {
  return {
    type: FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START,
    payload: {
      idChannel
    }
  };
}

export function MostPopularVideosChannelFetched(
  videos: Array<YoutubeVideo>
): FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS,
    payload: { videos }
  };
}

export function MostPopularVideosChannelFetchError(): FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE
  };
}