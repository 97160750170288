// @flow

import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';
import type { BlocContent } from 'types/NewsletterBloc';

type FeaturedMediaType = {
  id: number,
  src: string,
  title: string,
  legend: string,
  alt: string,
  width: number,
  height: number,
  sizes: {
    thumbnail: {
      src: string,
      width: number,
      height: number
    },
    medium: {
      src: string,
      width: number,
      height: number
    }
  }
};

type RencontreMetaType = {
  equipeId: number,
  equipeNom: string,
  competitionId: number,
  adversaire: string,
  reception: boolean,
  billetterie: string,
  cta_video: string,
  url_video: string,
  date_rencontre: string,
  arbitre: string,
  lieu: string,
  spectateurs: number,
  france_score: number,
  france_score_mi_temps: number,
  france_logo: string,
  france_drapeau: string,
  france_nb_essais: number,
  france_nb_transformations: number,
  france_nb_drops: number,
  france_nb_penalites: number,
  france_nb_cartons_jaunes: number,
  france_nb_cartons_rouges: number,
  adversaire_logo: string,
  adversaire_drapeau: string,
  adversaire_score: number,
  adversaire_score_mi_temps: number,
  adversaire_nb_essais: number,
  adversaire_nb_transformations: number,
  adversaire_nb_drops: number,
  adversaire_nb_penalites: number,
  adversaire_nb_cartons_jaunes: number,
  adversaire_nb_cartons_rouges: number,
  chef_delegation_id: number,
  chef_delegation_nom: string,
  manager_id: number,
  manager_nom: string,
  entraineur_01_id: number,
  entraineur_01_nom: string,
  entraineur_02_id: number,
  entraineur_02_nom: string,
  entraineur_03_id: number,
  entraineur_03_nom: string,
  equipe_adverse: string,
  journee?: number,
  afficher_composition: boolean,
  afficher_faits_de_jeu: boolean,
  afficher_stats: boolean,
  rencontre_terminee: boolean,
  rencontre_live: boolean,
  broadcaster_logo: string,
};

export type FaitDeJeuType = {
  quoi: string,
  joueur: string,
  joueur_court: string,
  raison: string,
  quand: string,
  equipe: string,
  remplace: string
};

export type DerniereRencontreType = {
  adversaire: string,
  embleme_france: string,
  embleme_adversaire: string,
  reception: number,
  competition: string,
  journee: string,
  date_rencontre: string,
  lieu: string,
  france_score: number,
  adversaire_score: number,
  link: string
};

export type PosteJoueurType = {
  featured_media: string,
  poste: string,
  id: number,
  ordre: number,
  essais: number,
  transformations: number,
  drops: number,
  penalites: number,
  cartons_jaunes: number,
  cartons_rouges: number,
  remplace_ordre: number,
  remplace_minute: number,
  est_remplace_ordre: number,
  est_remplace_minute: number,
  nom: string,
  nom_long: string,
  link: string,
  capitaine: boolean,
  titulaire: boolean
};

export type PosteStaffType = {
  featured_media: string,
  id: number,
  role: string,
  nom: string,
  nom_long: string,
  link: string
};

export type RencontreJoueursType = {
  poste_1: PosteJoueurType,
  poste_2: PosteJoueurType,
  poste_3: PosteJoueurType,
  poste_4: PosteJoueurType,
  poste_5: PosteJoueurType,
  poste_6: PosteJoueurType,
  poste_7: PosteJoueurType,
  poste_8: PosteJoueurType,
  poste_9: PosteJoueurType,
  poste_10: PosteJoueurType,
  poste_11: PosteJoueurType,
  poste_12: PosteJoueurType,
  poste_13: PosteJoueurType,
  poste_14: PosteJoueurType,
  poste_15: PosteJoueurType,
  poste_16: PosteJoueurType,
  poste_17: PosteJoueurType,
  poste_18: PosteJoueurType,
  poste_19: PosteJoueurType,
  poste_20: PosteJoueurType,
  poste_21: PosteJoueurType,
  poste_22: PosteJoueurType,
  poste_23: PosteJoueurType,
  poste_24: PosteJoueurType,
  poste_25: PosteJoueurType,
  poste_26: PosteJoueurType,
  poste_27: PosteJoueurType,
  poste_28: PosteJoueurType
};

export type PrevAndNextMatchesLinksType = {
  prev_match: {
    link: string,
    label: string
  },
  next_match: {
    link: string,
    label: string
  }
};

export type RencontreType = SEOMetaType & {
  id: number,
  date: string,
  slug: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    raw: string,
    data: Array<any>
  },
  content_match_to_come: {
    data: Array<BlocContent>
  },
  content_match_live: {
    data: Array<BlocContent>
  },
  content_match_over: {
    data: Array<BlocContent>
  },
  content_next_match_block: {
    data: Array<BlocContent>
  },
  rencontre_terminee: boolean,
  rencontre_live: boolean,
  featured_media: FeaturedMediaType,
  meta: RencontreMetaType,
  saison: Array<number>,
  niveau: number,
  competition: {
    id: number,
    name: string,
    slug: string
  },
  faits_de_jeu: Array<FaitDeJeuType>,
  dernieres_rencontres: Array<Object>,
  joueurs: RencontreJoueursType,
  staffs: Array<PosteStaffType>,
  news_prematch: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  news_debrief: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  news_analyse: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  prev_and_next_match_links: PrevAndNextMatchesLinksType
};

export type RawRencontreType = SEOMetaType & {
  id: number,
  date: string,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  content_match_to_come: {
    data: Array<BlocContent>
  },
  content_match_live: {
    data: Array<BlocContent>
  },
  content_match_over: {
    data: Array<BlocContent>
  },
  content_next_match_block: {
    data: Array<BlocContent>
  },
  rencontre_terminee: boolean,
  rencontre_live: boolean,
  featured_media: FeaturedMediaType,
  template: string,
  meta: RencontreMetaType,
  saison: Array<number>,
  excerpt: {
    raw: string
  },
  niveau: number,
  competition: {
    id: number,
    name: string,
    slug: string
  },
  faits_de_jeu: Array<FaitDeJeuType>,
  dernieres_rencontres: Array<DerniereRencontreType>,
  joueurs: RencontreJoueursType,
  staffs: Array<PosteStaffType>,
  news_prematch: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  news_debrief: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  news_analyse: {
    title: string,
    content: {
      data: BlocContent[]
    }
  },
  prev_and_next_match_links: PrevAndNextMatchesLinksType
};

const nullPosteJoueur: PosteJoueurType = {
  featured_media: '',
  poste: '',
  id: 0,
  ordre: 0,
  essais: 0,
  transformations: 0,
  drops: 0,
  penalites: 0,
  cartons_jaunes: 0,
  cartons_rouges: 0,
  remplace_ordre: 0,
  remplace_minute: 0,
  est_remplace_ordre: 0,
  est_remplace_minute: 0,
  nom: '',
  nom_long: '',
  link: '#',
  capitaine: false,
  titulaire: false
};

const nullJoueur: RencontreJoueursType = {
  poste_1: nullPosteJoueur,
  poste_2: nullPosteJoueur,
  poste_3: nullPosteJoueur,
  poste_4: nullPosteJoueur,
  poste_5: nullPosteJoueur,
  poste_6: nullPosteJoueur,
  poste_7: nullPosteJoueur,
  poste_8: nullPosteJoueur,
  poste_9: nullPosteJoueur,
  poste_10: nullPosteJoueur,
  poste_11: nullPosteJoueur,
  poste_12: nullPosteJoueur,
  poste_13: nullPosteJoueur,
  poste_14: nullPosteJoueur,
  poste_15: nullPosteJoueur,
  poste_16: nullPosteJoueur,
  poste_17: nullPosteJoueur,
  poste_18: nullPosteJoueur,
  poste_19: nullPosteJoueur,
  poste_20: nullPosteJoueur,
  poste_21: nullPosteJoueur,
  poste_22: nullPosteJoueur,
  poste_23: nullPosteJoueur,
  poste_24: nullPosteJoueur,
  poste_25: nullPosteJoueur,
  poste_26: nullPosteJoueur,
  poste_27: nullPosteJoueur,
  poste_28: nullPosteJoueur
};

export const nullRencontre: RencontreType = {
  ...nullSeoMeta,
  id: 1,
  date: '',
  slug: '',
  link: '',
  title: {
    rendered: '',
    raw: '',
    data: [
      {
        type: '',
        text: ''
      }
    ]
  },
  content: {
    rendered: '',
    raw: '',
    data: []
  },
  content_match_to_come: {
    data: []
  },
  content_match_live: {
    data: []
  },
  content_match_over: {
    data: []
  },
  content_next_match_block: {
    data: []
  },
  rencontre_terminee: false,
  rencontre_live: false,
  featured_media: {
    id: 1,
    src: '',
    title: '',
    legend: '',
    alt: '',
    width: 1,
    height: 1,
    sizes: {
      thumbnail: {
        src: '',
        width: 1,
        height: 1
      },
      medium: {
        src: '',
        width: 1,
        height: 1
      }
    }
  },
  meta: {
    equipeId: 1,
    equipeNom: '',
    competitionId: 1,
    adversaire: '',
    reception: false,
    billetterie: '',
    cta_video: '',
    url_video: '',
    date_rencontre: '',
    arbitre: '',
    lieu: '',
    spectateurs: 1,
    france_score: 1,
    france_score_mi_temps: 1,
    france_logo: '',
    france_drapeau: '',
    france_nb_essais: 1,
    france_nb_transformations: 1,
    france_nb_drops: 1,
    france_nb_penalites: 1,
    france_nb_cartons_jaunes: 1,
    france_nb_cartons_rouges: 1,
    adversaire_logo: '',
    adversaire_drapeau: '',
    adversaire_score: 1,
    adversaire_score_mi_temps: 1,
    adversaire_nb_essais: 1,
    adversaire_nb_transformations: 1,
    adversaire_nb_drops: 1,
    adversaire_nb_penalites: 1,
    adversaire_nb_cartons_jaunes: 1,
    adversaire_nb_cartons_rouges: 1,
    chef_delegation_id: 1,
    chef_delegation_nom: '',
    manager_id: 1,
    manager_nom: '',
    entraineur_01_id: 1,
    entraineur_01_nom: '',
    entraineur_02_id: 1,
    entraineur_02_nom: '',
    entraineur_03_id: 1,
    entraineur_03_nom: '',
    equipe_adverse: '',
    afficher_composition: false,
    afficher_faits_de_jeu: false,
    afficher_stats: false,
    rencontre_terminee: false,
    rencontre_live: false,
    broadcaster_logo: '',
  },
  saison: [],
  niveau: 1,
  competition: {
    id: 1,
    name: '',
    slug: ''
  },
  faits_de_jeu: [],
  dernieres_rencontres: [],
  joueurs: nullJoueur,
  staffs: [],
  news_prematch: {
    title: '',
    content: {
      data: []
    }
  },
  news_debrief: {
    title: '',
    content: {
      data: []
    }
  },
  news_analyse: {
    title: '',
    content: {
      data: []
    }
  },
  prev_and_next_match_links: {
    prev_match: {
      link: '',
      label: ''
    },
    next_match: {
      link: '',
      label: ''
    }
  }
};
