//@flow

import { connect } from 'react-redux';

import ComposeNewsletterCats from 'components/profile/settings/ComposeNewsletterCats';
import { postUserPref } from 'actions/userActions';
import { displayModal } from 'actions/modalActions';
import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/profile/settings/ComposeNewsletterCats';
import type { EditProfileValueType } from 'types/User';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref,
    status: state.userState.status,
    categories: state.categoriesState.categories
  }),
  (dispatch: DispatchType): DispatchProps => ({
    postUserPref: (action: string, value: EditProfileValueType, token: string) =>
      dispatch(postUserPref(action, value, token)),
    displayModal: () => dispatch(displayModal('MODAL_UNSUBSCRIBE_NEWSLETTER')),
    displayModalSuccess: (content: Object) => dispatch(displayModal('MODAL_SUCCESS', content))
  })
)(ComposeNewsletterCats);
