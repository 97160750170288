// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';
import qs from 'querystring';

export type StateProps = {
  query: string
};

export type DispatchProps = {
  setQuery: (query: string) => void
};

type Props = {
  changeOnEnter?: boolean,
  changeOnInput?: boolean,
  style?: string,
  children?: any,
  location?: any
} & StateProps &
  DispatchProps;

type State = {
  open: boolean,
  resultVille: any[]
};

const API_ADRESSE = 'https://api-adresse.data.gouv.fr';

function search(args: any) {
  const url = `${API_ADRESSE}/search/?${qs.stringify(args)}`;
  return _fetch(url);
}

export async function _fetch(url: string) {
  const options = {
    mode: 'cors',
    method: 'GET'
  };

  const response = await fetch(url, options);
  const contentType = response.headers.get('content-type');

  if (!response.ok) {
    throw new Error(response);
  }

  if (response.ok && contentType && contentType.includes('application/json')) {
    return response.json();
  }

  throw new Error('Une erreur est survenue');
}

class FilterOptions extends PureComponent<Props, State> {
  debounce: TimeoutID;
  debouncemunicipality: TimeoutID;
  props: Props;
  static defaultProps:any = {
    changeOnInput: true,
    changeOnEnter: false,
    style: 'normal'
  };
  state: State = {
    open: false,
    resultVille: []
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  handleInputChanged = (e: any) => {
    clearTimeout(this.debounce);

    const key = e ? e.key : false;
    const query = e ? e.target.value.toLowerCase() : '';
    this.debounce = setTimeout(() => {
      if (this.props.changeOnInput || (this.props.changeOnEnter && key === 'Enter') || query === '') {
        this.props.setQuery(query);
      }
    }, 400);
  };

  handleInputMunicipalityChanged = async (e: any) => {
    clearTimeout(this.debouncemunicipality);
    const query = e ? e.target.value.toLowerCase() : '';
    if (query && query.trim().length >= 3) {
      const results = await search({ q: query.trim(), limit: 10, type: 'municipality' });
      if (results && results.features) {
        this.setState({ resultVille: results.features });
      }
    } else {
      this.setState({ resultVille: [] });
    }
  };

  render() {
    const classNamesFilterButton = dynamicClassName('btn btn--white filter-option__cta js-showFilter ft-up');
    const classNamesFilterSection = dynamicClassName('filter filter--clubs');
    this.props.style === 'centenaire' && classNamesFilterSection.add('filter--centenaire');
    this.state.open && classNamesFilterButton.add('is-active');
    const { resultVille } = this.state;
    return (
      <>
        <section className={classNamesFilterSection.build()}>
          <div className="filter__wrapper">
            <div className="input input--search" data-overlay="false">
              <input
                type="search"
                id="form_recherche_filtre"
                placeholder="Rechercher un club"
                defaultValue={this.props.query}
                onInput={this.handleInputChanged}
                //onChange={this.handleInputChanged}
                //onKeyPress={this.handleInputChanged}
              />
              <label htmlFor="form_recherche_filtre">Recherche</label>
            </div>
            <div className="input input--search input--loc" data-overlay="false">
              {this.props.location && this.props.location.state && this.props.location.state.municipality && (
                <Link
                  to={{
                    pathname: `/trouver-un-club-de-rugby/resultats-de-recherche`,
                    state: {
                      municipality: '',
                      lat: false,
                      lng: false
                    }
                  }}
                >
                  {this.props.location.state.municipality}
                </Link>
              )}
              {(!this.props.location || !this.props.location.state || !this.props.location.state.municipality) && (
                <div>
                  <input
                    type="search"
                    id="form_municipality_filtre"
                    placeholder="Rechercher une ville"
                    defaultValue={''}
                    onInput={this.handleInputMunicipalityChanged}
                  />
                  <div>
                    {resultVille.length > 0 && (
                      <ul>
                        {resultVille.map(result => {
                          return (
                            <li key={result.properties.id}>
                              <Link
                                to={{
                                  state: {
                                    municipality: result.properties.label + ' (' + result.properties.postcode + ')',
                                    lat: result.geometry.coordinates[1],
                                    lng: result.geometry.coordinates[0]
                                  }
                                }}
                                onClick={() => {
                                  this.setState({ resultVille: [] });
                                }}
                              >
                                {result.properties.label + ' (' + result.properties.postcode + ')'}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="filter-option filter-option--mobile">
              <button
                className={classNamesFilterButton.build()}
                type="button"
                data-label={this.state.open ? 'Fermer' : 'Filtres'}
                onClick={this.toggleMenu}
              >
                {this.state.open ? 'Fermer' : 'Filtres'}
              </button>
              <div className="filter-option__list">
                {this.props.children}

                <div className="filter-option__action">
                  <button className="btn btn--white">
                    <i className="icon icon-sync" />
                  </button>
                  <button className="btn btn--primary js-showFilter" onClick={() => this.toggleMenu()}>
                    <i className="icon icon-valid is-inline" />
                    <span>Appliquer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.props.style === 'centenaire' && <div className="overlay"></div>}
      </>
    );
  }
}

export default FilterOptions;
