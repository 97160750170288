// @flow
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type AuteurType = SEOMetaType & {
  id: number,
  slug: string,
  title: string,
  link: string,
  content: string,
  role_edition: string,
  logo: string,
};

export type RawAuteurType = SEOMetaType & {
  id: number,
  name: string,
  description: string,
  link: string,
  slug: string,
  role_edition: string,
  avatar_urls: {
    media_id?: number,
    '24'?: string,
    '26'?: string,
    '32'?: string,
    '48'?: string,
    '64'?: string,
    '96'?: string,
    full?: string,
  }
};

export const nullAuteur: AuteurType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  logo: '',
  content: '',
  role_edition: '',
  slug: '',
  link: '',
};
