// @flow

import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import CheckboxInput from 'components/fragments/input/CheckboxInput';
import Loading from 'components/fragments/Loading';
import { STATUS_SUCCESS } from 'constants/statusConstants';
import type { Status } from 'types/Status';
import type { CategoryType } from 'types/Article';
import type { FullUserType } from 'types/User';

export type DispatchProps = {
  postUserPref: (action: string, value: any, token: string) => void,
  displayModal: () => void,
  displayModalSuccess: (content: Object) => void
};

export type StateProps = {
  userPref: FullUserType,
  status: Status,
  categories: CategoryType[],

  optIn?: boolean,
  optOut?: boolean,
  title?: string
};

type State = {
  categories: string[],
  doNotResetState: boolean,
  hasModifiedPrefs: boolean
};

type Props = StateProps & DispatchProps;

export default class ComposeNewsletterCats extends Component<Props, State> {
  state: State = {
    categories: [],
    doNotResetState: false,
    hasModifiedPrefs: false
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { categories, doNotResetState } = state;
    const { userPref: { newsletter } } = props;

    if (categories.length < 1 && !doNotResetState) {
      return {
        categories: newsletter,
      };
    }
    return state;
  }

  selectCategory = (id: string) => {
    const { categories: stateCategories, doNotResetState } = this.state;

    if (stateCategories.includes(id)) {
      const categories = stateCategories.filter(category => category !== id);
      const shouldCategoriesBeEmptyArray =
        categories.length === 0 && !doNotResetState
          ? { categories, doNotResetState: true, hasModifiedPrefs: true }
          : { categories, hasModifiedPrefs: true };
      this.setState(shouldCategoriesBeEmptyArray);
    } else {
      let categories = stateCategories;
      categories.push(id);
      this.setState({ categories, hasModifiedPrefs: true });
    }
  };

  renderCheckboxes = (): React$Element<any>[] => {
    const { categories: categoriesState } = this.state;
    const categories: Array<{ id: string, name: string }> = [
      {
        id: 'federation',
        name: 'Fédération'
      },
      {
        id: 'au_coeur_du_jeu',
        name: 'Au cœur du jeu'
      },
      {
        id: 'barbarians_Francais',
        name: 'Barbarians Français'
      },
      {
        id: 'equipes_de_France',
        name: 'Équipes de France'
      },
      {
        id: 'etre_acteur',
        name: 'Être acteur'
      },
      {
        id: 'XV_de_France_Feminin',
        name: 'XV de France Féminin'
      },
      {
        id: 'XV_de_France_Masculin',
        name: 'XV de France Masculin'
      },
      {
        id: 'france_moins_de_20',
        name: 'France moins de 20 ans'
      },
      {
        id: 'france_7_Masculin',
        name: 'France 7 Masculin'
      },
      {
        id: 'france_7_feminin',
        name: 'France 7 Féminin'
      }
    ];

    return categories.map((category): React$Element<any> => {
      const { id, name } = category;
      const paddingLeft = 0;
      return (
        <li
          key={name}
          // style={{ paddingLeft: 0 }}
        >
          <CheckboxInput
            id={id}
            label={name}
            onChange={() => this.selectCategory(id)}
            className="checkbox check-right ft-500"
            checked={categoriesState.includes(id)}
            labelClassName="ft-truncat"
            paddingLeft={{ paddingLeft }}
          />
        </li>
      );
    });
  };

  handleSubmitNewsletter = (unsubscribeNewsletter: boolean) => {
    const { postUserPref, displayModal, optIn, optOut, userPref } = this.props;
    const { categories } = this.state;

    if (unsubscribeNewsletter) {
      if (optOut) {
        displayModal();
      }

      return;
    }

    const categoriesFinales = categories.filter(cat => !Number.isInteger(cat));
    if (optIn && !userPref.saleforce.optin_newsletter) {
      postUserPref('newsletter-add', categoriesFinales, this.props.userPref.token);
    } else {
      postUserPref('newsletter-change', categoriesFinales, this.props.userPref.token);
    }
  };

  componentDidUpdate(prevProps: Props) {
    const {
      optIn,
      optOut,
      status,
      userPref: {
        saleforce: { optin_newsletter }
      },
      displayModalSuccess
    } = this.props;
    const { hasModifiedPrefs } = this.state;

    if (prevProps.status !== status && status === STATUS_SUCCESS && hasModifiedPrefs) {
      this.setState({ hasModifiedPrefs: false });

      if (optin_newsletter !== prevProps.userPref.saleforce.optin_newsletter) {
        if (optIn && optin_newsletter) {
          displayModalSuccess({
            title: 'Inscription validée',
            text:
              'Nous avons bien pris en compte votre inscription à la newsletter. Vous pouvez modifier vos centres d’intérêts ou vous désinscrire au même endroit dans l’onglet Newsletter.'
          });

          return;
        }

        if (optOut && !optin_newsletter) {
          displayModalSuccess({
            title: 'Désinscription validée',
            text:
              'Nous avons bien pris en compte votre désinscription à la newsletter. Vous pouvez modifier vos centres d’intérêts ou vous inscrire au même endroit dans l’onglet Newsletter.'
          });

          return;
        }
      }

      displayModalSuccess({ text: 'Vos préférences ont été mises à jour' });
    }
  }

  render() {
    const { hasModifiedPrefs } = this.state;
    const { status, title } = this.props;

    if (status !== STATUS_SUCCESS) {
      return <Loading isWhite />;
    }

    return (
      <>
        <p className="ft-black-secondary mb-2">{title || 'Sélectionnez les thématiques qui vous passionnent !'}</p>
        <ul>{this.renderCheckboxes()}</ul>
        <a
          className="btn btn--primary mt-3 mt-md-2"
          onClick={() => this.handleSubmitNewsletter(false)}
          role="button"
          tabIndex="0"
        >
          Enregistrer
        </a>
        {/*<a
          className="ft-black-secondary ft-center mt-2 ft-500"
          title="Me désinscrire"
          onClick={() => this.handleSubmitNewsletter(true)}
          role="button"
          tabIndex="0"
        >Me désinscrire</a>*/}
        <Prompt when={hasModifiedPrefs} message={`Êtes vous sûr(e) de vouloir quitter cette page ?`} />
      </>
    );
  }
}
