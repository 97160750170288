// @flow

import React, { PureComponent, type ComponentType } from 'react';

function withScreenType(WrappedComponent: ComponentType<any>) {
  type Props = {};
  type State = {
    isMobile: boolean,
    isSmall: boolean
  };

  class ScreenTypeHandler extends PureComponent<Props, State> {
    props: Props;
    state: State = {
      isMobile: false,
      isSmall: false
    };

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions.bind(this));
    }

    updateDimensions = () => {
      const isMobile = this.isMobile();
      const isSmall = this.isSmall();
      if (isMobile !== this.state.isMobile || this.state.isSmall !== isSmall) this.setState({ isMobile, isSmall });
    };

    isMobile = () => {
      return window.innerWidth <= 1280;
    };

    isSmall = () => {
      return window.innerWidth <= 750;
    };

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }

  return ScreenTypeHandler;
}

export default withScreenType;
