// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertFacebookPostsFromApi } from 'services/Social';


function FacebookApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts() {
    return executor.get(`rs_facebook.json`)
            .then(responses => {
              return responses.data.map(resp => convertFacebookPostsFromApi(resp));
            });
  }
}

export default FacebookApi;
