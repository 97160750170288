// @flow
import { nullJobFiltersSearch } from 'types/JobFilter';
import type { EntrepriseBreadcrumb, BreadcrumbType } from 'types/Settings';

export const getAccountDeletionUrl = () => {
  const { host } = window.location;

  let accountDeletionUrl = 'https://79-review-develop-3zknud.ffr2.nextmap.cloud/supprimer-mon-compte/';

  if (host.includes('ffr.fr')) {
    accountDeletionUrl = 'https://connexion.ffr.fr/supprimer-mon-compte';
  }

  return accountDeletionUrl;
};

// @TODO changer plus tard en fonction du nom des route de submenu
export function getUrlName(name: string): string {
  return name
    .replace(/ /g, '-')
    .replace(/'/g, '-')
    .normalize('NFD')
    .replace(/’/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

export function getPathname(url: string): string {
  const link = document.createElement('a');
  link.href = url;

  return link.pathname;
}

export function getEntrepriseBreadcrumb(
  breadcrumb_offres_entreprise: Array<EntrepriseBreadcrumb>
): Array<BreadcrumbType> {
  const elementBreadcrumb = [];

  breadcrumb_offres_entreprise.forEach((curr, index) => {
    let to = `/${curr.slug}`;

    if (index > 0 && elementBreadcrumb[index - 1]) {
      to = `${elementBreadcrumb[index - 1].to}/${curr.slug}`;
    }

    elementBreadcrumb.push({ name: curr.title, to });
  });

  return elementBreadcrumb;
}

export function convertObjectToQueryString(query?: Object): string {
  let queryString: string = ``;
  if (query) {
    queryString += `?`;
    queryString += Object.keys(query)
      .filter(
        filterKey =>
          query &&
          query.hasOwnProperty(filterKey) &&
          (query[filterKey].length > 0 || typeof query[filterKey] === 'number')
      )
      .map(key => {
        const value = (query && query.hasOwnProperty(key) && query[key]) || '';

        return `${key}=${value}`;
      })
      .join('&');
  }
  return queryString;
}

export function convertQueryStringToObject(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    const value = item
      .substring(indexOfEqual + 1)
      .split(',')
      .map(valueItem => (isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)));

    return {
      ...result,
      ...{ [key]: value }
    };
  }, nullJobFiltersSearch);
}

export function convertQueryStringToContactObject(queryString: string, initialObject: Object): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce((result, item) => {
    const indexOfEqual = item.indexOf('=');
    const key = item.slice(0, indexOfEqual);
    const value = item
      .substring(indexOfEqual + 1)
      .split(',')
      .map(valueItem => (isNaN(valueItem) ? decodeURI(valueItem) : parseInt(valueItem, 10)));

    return {
      ...result,
      ...{ [key]: value }
    };
  }, initialObject);
}

export function convertQueryStringToTeam(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce(
    (result, item) => {
      const indexOfEqual = item.indexOf('=');
      const key = item.slice(0, indexOfEqual);
      let value = item
        .substring(indexOfEqual + 1)
        .split(',')
        .map(valueItem => (isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)));
      if ((key === 'season' || key === 'end_date') && value.length === 1) {
        value = value[0];
      }
      return {
        ...result,
        ...{ [key]: value }
      };
    },
    {
      equipe: '',
      season: '',
      poste: [],
      competitions: []
    }
  );
}

export function convertQueryStringToEvent(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce(
    (result, item) => {
      const indexOfEqual = item.indexOf('=');
      const key = item.slice(0, indexOfEqual);
      let value = item
        .substring(indexOfEqual + 1)
        .split(',')
        .map(valueItem => (isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)));
      if ((key === 'season' || key === 'end_date') && value.length === 1) {
        value = value[0];
      }
      return {
        ...result,
        ...{ [key]: value }
      };
    },
    {
      equipe: '',
      season: ''
    }
  );
}

export function convertQueryStringToCompetitions(queryString: string): Object {
  let searchQS = queryString.substring(1);
  searchQS = searchQS.split('&');

  return searchQS.reduce(
    (result, item) => {
      const indexOfEqual = item.indexOf('=');
      const key = item.slice(0, indexOfEqual);
      let value = item
        .substring(indexOfEqual + 1)
        .split(',')
        .map(valueItem => (isNaN(valueItem) ? valueItem : parseInt(valueItem, 10)));
      if ((key === 'season' || key === 'end_date') && value.length === 1) {
        value = value[0];
      }
      return {
        ...result,
        ...{ [key]: value }
      };
    },
    {
      equipe: '',
      season: ''
    }
  );
}

export function getFileNameFromPath(path: string): string {
  return path.replace(/^.*[\\/]/, '');
}
