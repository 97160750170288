// @flow

import React, { PureComponent } from 'react';

import { Link } from 'react-router-dom';
import { formatFullDateString } from 'utils/dateUtils';
import DropdownRadioInput from 'components/fragments/input/DropdownRadioInput';
import { usePlaceholderOnImageError } from 'utils/assetsUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

import type { FullUserType } from 'types/User';


export type StateProps = {
  userPref: FullUserType,
};

type Props = StateProps & {
  currentUserProfile: string
};

export type State = {
  selectedCompetition: string,
}
export default class MyTeamHome extends PureComponent<Props, State> {
  state: State = {
    selectedCompetition: '0',
  }

  handleChange = (value: any) => {
    this.setState({
      selectedCompetition: value
    });
  };

  render() {
    const {
      userPref,
      currentUserProfile,
      userPref: {
        mon_equipe: {
          competitions
        }
      }
    } = this.props;

    const {
      selectedCompetition
    } = this.state;
    if ((currentUserProfile && currentUserProfile === 'defaut') || !competitions || competitions.length === 0) {
      return null;
    }

    const competitions_liste = competitions.map((comp) => {return {label: comp.nom, value: comp.id};});
    let dropdownLabel = competitions[0].nom;
    if (selectedCompetition) {
      const dropdownLabels = competitions_liste.filter((comp) => {
        return comp.value === selectedCompetition;
      });
      if (dropdownLabels.length > 0) {
        dropdownLabel = dropdownLabels[0].label;
      }
    }
    let data_competition = competitions[0];
    if (selectedCompetition) {
      const listeCompetitions = competitions.filter((comp) => {
        return comp.id === selectedCompetition;
      });
      if (listeCompetitions.length > 0) {
        data_competition = listeCompetitions[0];
      }
    }
    const rencontre = data_competition.rencontres_club && data_competition.rencontres_club.length > 0 ? data_competition.rencontres_club[0] : false;
    let classement_data = data_competition.classement_club.Classement;
    let noStats = !data_competition.classement_club.Classement || 
      !data_competition.classement_club.Classement.joues || 
      data_competition.classement_club.Classement.joues === 0;

    if (noStats && data_competition.classement_club_intra) {
      noStats = false;
      classement_data = data_competition.classement_club_intra.Classement;
    }
    const classement_general = data_competition.classement_club.positionInterPoule ? data_competition.classement_club.positionInterPoule : (data_competition.classement_club_intra.position ? data_competition.classement_club_intra.position : 0);
    
    
    if (classement_data.joues === 0) {
      return (
        <div className='box box--white card card--profile card--teamStatsHome mb-7'>
          <div className="card__header">
            <h3 className="ft-h4">
              <span className="ft-up">
                Mon équipe cette saison
              </span>
              <DropdownRadioInput 
                options={competitions_liste}
                inputChanged={this.handleChange}
                label={dropdownLabel}
                radio={true}
                maj={true}
                title={true}
                noBorder={true}
              />
            </h3>
          </div>
          <div className='card__body'>
            <div>
              <div className="placeholder">
                <p className="ft-h5">
                  <i className="icon icon-jersey-empty"></i>
                  <br />
                  Aucune rencontre ni aucune statistique cette saison pour cette équipe.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    return (
        <div className='box box--white card card--profile card--teamStatsHome mb-7'>
          <div className="card__header">
            <h3 className="ft-h4">
              <span className="ft-up">
                Mon équipe cette saison
              </span>
              <DropdownRadioInput 
                options={competitions_liste}
                inputChanged={this.handleChange}
                label={dropdownLabel}
                radio={true}
                maj={true}
                title={true}
                noBorder={true}
              />
            </h3>
          </div>
          <div className='card__body'>
            <div className="row">
              <div className="col col-md-3 col-xs-2 ft-center border-right">
                <p className="ft-h1 ft-up ft-truncat">{classement_general}<sup>ème</sup></p>
                <p className="ft-truncat">Au classement général</p>
              </div>
              <div className="col col-md-3 col-xs-2 ft-center border-right">
                <p className="ft-h1 ft-up ft-truncat">{classement_data.gagnes} </p>
                <p className="ft-truncat">{classement_data.gagnes > 1 ? 'Matchs remportés' : 'Match remporté'}</p>
              </div>
              <div className="col col-md-3 col-xs-2 ft-center border-right">
                <p className="ft-h1 ft-up ft-truncat" style={{opacity: '.6'}}>{classement_data.perdus}</p>
                <p className="ft-truncat">{classement_data.perdus > 1 ? 'Matchs perdus' : 'Match perdu'}</p>
              </div>
              <div className="col col-md-3 col-xs-2 ft-center">
                <p className="ft-h1 ft-up ft-truncat" style={{opacity: '.3'}}>{classement_data.nuls}</p>
                <p className="ft-truncat">{classement_data.nuls > 1 ? 'Matchs nuls' : 'Match nul'}</p>
              </div>
            </div>
          </div>
          {rencontre && rencontre.id &&
          <>
            <div className="card__header border-top">
              <h3 className="ft-h4 ft-up">
                Dernier match
                <small className="ft-right ft-black-secondary">
                  {`${formatFullDateString(new Date(rencontre.dateEffective.substring(0, 10)))}`}
                </small>
              </h3>
            </div>
            <div className="card--result">
              <a 
                className="match"
                title="Voir les informations du match"
                href={'https://competitions.ffr.fr/competitions/'+data_competition.identifiant+'/match-'+rencontre.id+'.html'}
                target="_blank"
                rel="noopener noreferrer">
                <div className="match__club">
                  <p className={
                        rencontre.RencontreResultatLocale.pointsDeMarque > rencontre.RencontreResultatVisiteuse.pointsDeMarque ? 
                        'ft-700': ''
                    }>
                      <span>{ rencontre.CompetitionEquipeLocale.nom}</span>
                  </p>
                  <img
                    src={
                      rencontre.CompetitionEquipeLocale.Structure ? 
                        'https://api-agregateur-static.ffr.fr/assets/embleme/club/'+rencontre.CompetitionEquipeLocale.Structure.id+'.jpg' : 
                        ''
                    }
                    alt="Logo"
                    onError={(e) => usePlaceholderOnImageError(e.target, '/img/logo/placeholder-club.png')} />
                </div>
                <div className="match__info">
                  <p className="match__score">
                    <span className={
                        rencontre.RencontreResultatLocale.pointsDeMarque > rencontre.RencontreResultatVisiteuse.pointsDeMarque ? 
                        'ft-700': ''
                    }>{rencontre.RencontreResultatLocale.pointsDeMarque}</span>
                    <span className="match__colon">-</span>
                    <span className={
                        rencontre.RencontreResultatVisiteuse.pointsDeMarque > rencontre.RencontreResultatLocale.pointsDeMarque ? 
                        'ft-700': ''
                    }>
                        {rencontre.RencontreResultatVisiteuse.pointsDeMarque}
                      </span>
                  </p>
                </div>
                <div className="match__club">
                  <img 
                    src={
                      rencontre.CompetitionEquipeVisiteuse.Structure ? 
                        'https://api-agregateur-static.ffr.fr/assets/embleme/club/'+rencontre.CompetitionEquipeVisiteuse.Structure.id+'.jpg' : 
                        ''
                    } 
                    alt="Logo"
                    onError={(e) => usePlaceholderOnImageError(e.target, '/img/logo/placeholder-club.png')}
                  />
                  <p  className={
                    rencontre.RencontreResultatVisiteuse.pointsDeMarque > rencontre.RencontreResultatLocale.pointsDeMarque ? 
                    'ft-700': ''
                  }><span>{ rencontre.CompetitionEquipeVisiteuse.nom}</span></p>
                </div>
              </a>
            </div>
          </>}
          <div className='card__footer border-top'>
            <Link
              to='/tableau-de-bord/mes-statistiques'
              title='Voir toutes les statistiques de mon équipe'
              className='ft-700 ft-center'
              onClick={handleTagEvent(
                'espace perso',
                'clic_stats_equipe',
                '',
                userPref
              )}
            >
              <span className="link-chevron link-chevron--blue">
                Voir toutes les statistiques de mon équipe
                <i className="icon icon-chevron-right" />
              </span>
            </Link>
          </div>
        </div>
    );
  }
}
