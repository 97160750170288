// @flow
import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { youtubeDurationToTime } from 'utils/socialUtils';
import type { YoutubeContent } from 'types/YoutubeContent';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  content: YoutubeContent,
  idnumber?: number,
  hideDescriptions?: boolean,
  type: string,
  video_fond?: string,
  image_fond?: string
};

class FFRtvCard extends PureComponent<Props> {
  render() {
    const { type, hideDescriptions, image_fond, video_fond } = this.props;
    const content = { ...this.props.content };
    const idnumber = this.props.idnumber;
    const props = { ...this.props, content };
    const { backgroundImage, backgroundImageStandard, description, tags } = props.content;
    let descriptionFinale = description;
    if (hideDescriptions) {
      descriptionFinale = description.split('<br />')[0];
    }
    const hastags = tags.length > 0;
    const tags_valides = tags.filter(function(e) {
      return ['FFR', 'Rugby', 'rugby'].indexOf(e) === -1;
    });
    let style = { backgroundImage: `url(${image_fond || backgroundImageStandard || ''})` };
    let classeVideo = type === 'competition' ? 'card__thumbnail' : 'card__thumbnail img-blue-dark';
    if (image_fond) {
      classeVideo = 'card__thumbnail img-blue-dark';
    }

    return (
      <Link to={`/ffr-tv-player/${props.content.id}`} className="box card card--video" title="Voir cette vidéo">
        {!video_fond && idnumber === 0 && backgroundImageStandard && (
          <div className={classeVideo} style={style}>
            <img src={backgroundImageStandard} alt="" />
          </div>
        )}
        {!video_fond && idnumber !== 0 && backgroundImage && (
          <div className={classeVideo} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <img src={backgroundImage} alt="" />
          </div>
        )}

        {//object-fit cover sur la video // banner__video__thumbnail => 100% et 100% +
        !!video_fond && (
          <div className="ffr-tv-card">
            <ReactPlayer
              className="banner__video__thumbnail img-blue-dark"
              url={video_fond}
              loop
              muted
              playsinline
              playing
            />
          </div>
        )}
        <div className="card__footer">
          <span className="link-icon link-icon--medium is-dark" style={{ border: 'none' }}>
            <img
              src={
                type === 'competition'
                  ? getPublicAssets('/img/svg/btn_play_competition.svg')
                  : getPublicAssets('/img/youtube/youtube_social_square_dark.png')
              }
              alt="Lire la vidéo"
            />
          </span>
          <h5 className="card__title ft-truncat" dangerouslySetInnerHTML={{ __html: descriptionFinale }} />
          <p className="card__date">{youtubeDurationToTime(content.duration)}</p>
        </div>
        {hastags && (
          <div className="card__type">
            <span className="label label--red">{tags_valides[0] ? tags_valides[0] : content.tags[0]}</span>
          </div>
        )}
      </Link>
    );
  }
}

export default FFRtvCard;
