// @flow
import { UNTRACKED_PATH } from 'constants/numberlyConstants';
import Numberly from 'tracking/numberly';

export const numberlyPageView = (keycloakData: any, pathname: string) => {
  // on ne veut pas traquer les pages ayant des tags spécifiques indiquées dans UNTRACKED_PATH
  if (!UNTRACKED_PATH.includes(pathname)) {
    const numberlyEvent = {
      rtgenv: 'ffr',
      rtgpg: 'generic',
      rtglanguage: 'fr',
      rtglogged: keycloakData && keycloakData.authenticated ? '1' : '0'
    };
    Numberly.tag(numberlyEvent);
  } else {
    // Ici on traque spécifiquement la home
    if (pathname === '/') {
      const event = {
        rtgpg: 'hp',
        rtgenv: 'ffr',
        rtglanguage: 'fr',
        rtglogged: keycloakData && keycloakData.authenticated ? '1' : '0'
      };

      Numberly.tag(event);
    }

    if (pathname === '/trouver-un-club-de-rugby') {
      const event = {
        rtgpg: 'trouver_un_club',
        rtgenv: 'ffr',
        rtglanguage: 'fr',
        rtglogged: keycloakData && keycloakData.authenticated ? '1' : '0'
      };

      Numberly.tag(event);
    }
  }
};
