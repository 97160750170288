// @flow

import type { formCentenaireInputsType } from 'types/CentenaireForm';

export const SEND_CENTENAIRE_FORM_START = 'SEND_CENTENAIRE_FORM_START';
export const SEND_CENTENAIRE_FORM_SUCCESS = 'SEND_CENTENAIRE_FORM_SUCCESS';
export const SEND_CENTENAIRE_FORM_FAILURE = 'SEND_CENTENAIRE_FORM_FAILURE';

export type SEND_CENTENAIRE_FORM_START_ACTION = {
  type: 'SEND_CENTENAIRE_FORM_START',
  payload: {
    formInputs: formCentenaireInputsType
  }
};

export type SEND_CENTENAIRE_FORM_FAILURE_ACTION = {
  type: 'SEND_CENTENAIRE_FORM_FAILURE'
};

export type SEND_CENTENAIRE_FORM_SUCCESS_ACTION = {
  type: 'SEND_CENTENAIRE_FORM_SUCCESS'
};

export type Action =
  | SEND_CENTENAIRE_FORM_START_ACTION
  | SEND_CENTENAIRE_FORM_FAILURE_ACTION
  | SEND_CENTENAIRE_FORM_SUCCESS_ACTION;

export function postCentenaireForm(formInputs: formCentenaireInputsType): SEND_CENTENAIRE_FORM_START_ACTION {
  return {
    type: SEND_CENTENAIRE_FORM_START,
    payload: {
      formInputs,
    }
  };
}

export function centenaireFormPosted(): SEND_CENTENAIRE_FORM_SUCCESS_ACTION {
  return {
    type: SEND_CENTENAIRE_FORM_SUCCESS
  };
}

export function centenaireFormPostError(error: any): SEND_CENTENAIRE_FORM_FAILURE_ACTION {
  return {
    type: SEND_CENTENAIRE_FORM_FAILURE
  };
}
