// @flow

export type RawYoutubePlaylistId = {
  etag: string,
  items: Array<{
    etag: string,
    id: {
      kind: string,
      playlistId: string
    },
    kind: string
  }>,
  kind: string,
  nextPageToken: string,
  pageInfo: {
    totalResults: string,
    resultsPerPage: string
  },
  regionCode: string
};

export type RawYoutubePlaylistChannel = {
  kind: string,
  etag: string,
  nextPageToken: string,
  prevPageToken: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: [
    {
      kind: string,
      etag: string,
      id: string,
      snippet: {
        publishedAt: string,
        channelId: string,
        title: string,
        description: string,
        thumbnails: {
          default: {
            url: string,
            width: number,
            height: number
          },
          medium: {
            url: string,
            width: number,
            height: number
          },
          high: {
            url: string,
            width: number,
            height: number
          },
          standard: {
            url: string,
            width: number,
            height: number
          },
          maxres: {
            url: string,
            width: number,
            height: number
          }
        },
        resourceId: {
          kind: string,
          videoId: string
        },
        channelTitle: string,
        localized: {
          title: string,
          description: string
        }
      },
      contentDetails: {
        itemCount: number
      }
    }
  ]
};

export type RawYoutubePlaylist = {
  kind: string,
  etag: string,
  pageInfo: {
    totalResults: number,
    resultsPerPage: number
  },
  items: Array<{
    kind: string,
    etag: string,
    id: string,
    snippet: {
      publishedAt: string,
      channelId: string,
      title: string,
      description: string,
      thumbnails: {
        default: {
          url: string,
          width: number,
          height: number
        },
        medium: {
          url: string,
          width: number,
          height: number
        },
        high: {
          url: string,
          width: number,
          height: number
        }
      },
      channelTitle: string,
      localized: {
        title: string,
        description: string
      }
    }
  }>
};

export type YoutubePlaylist = {
  id: string,
  publishedAt: string,
  channelId: string,
  title: string,
  description: string,
  image: string,
  url: string,
  itemCount: number,
  thumbnails: {
    default: {
      url: string,
      width: number,
      height: number
    },
    medium: {
      url: string,
      width: number,
      height: number
    },
    high: {
      url: string,
      width: number,
      height: number
    }
  }
};

export const nullYoutubePlaylist = {
  id: '',
  publishedAt: '',
  channelId: '',
  title: '',
  description: '',
  image: '',
  url: '',
  label: '',
  itemCount: 0,
  thumbnails: {
    default: {
      url: '',
      width: 0,
      height: 0
    },
    medium: {
      url: '',
      width: 0,
      height: 0
    },
    high: {
      url: '',
      width: 0,
      height: 0
    },
    standard: {
      url: '',
      width: 0,
      height: 0
    },
    maxres: {
      url: '',
      width: 0,
      height: 0
    }
  }
};

export const nullYoutubePlaylistsCustom = {
  youtubePlaylists: {
    id: '',
    publishedAt: '',
    channelId: '',
    title: '',
    description: '',
    image: '',
    url: '',
    label: '',
    itemCount: 0,
  },
  etag: '',
  nextPageToken: ''
};
