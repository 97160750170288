// @flow

import type { ContactFormType, formInputsType } from 'types/ContactForm';

export const FETCH_CONTACT_FORM_START = 'FETCH_CONTACT_FORM_START';
export const FETCH_CONTACT_FORM_SUCCESS = 'FETCH_CONTACT_FORM_SUCCESS';
export const FETCH_CONTACT_FORM_FAILURE = 'FETCH_CONTACT_FORM_FAILURE';
export const FETCH_ALL_CONTACT_FORM_START = 'FETCH_ALL_CONTACT_FORM_START';
export const FETCH_ALL_CONTACT_FORM_SUCCESS = 'FETCH_ALL_CONTACT_FORM_SUCCESS';

export const SEND_CONTACT_FORM_START = 'SEND_CONTACT_FORM_START';
export const SEND_CONTACT_FORM_SUCCESS = 'SEND_CONTACT_FORM_SUCCESS';
export const SEND_CONTACT_FORM_FAILURE = 'SEND_CONTACT_FORM_FAILURE';

export type FETCH_CONTACT_FORM_START_ACTION = {
  type: 'FETCH_CONTACT_FORM_START'
};

export type FETCH_CONTACT_FORM_FAILURE_ACTION = {
  type: 'FETCH_CONTACT_FORM_FAILURE'
};

export type FETCH_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'FETCH_CONTACT_FORM_SUCCESS',
  payload: {
    contactForm: ContactFormType,
  }
};

export type FETCH_ALL_CONTACT_FORM_START_ACTION = {
  type: 'FETCH_ALL_CONTACT_FORM_START'
};

export type FETCH_ALL_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'FETCH_ALL_CONTACT_FORM_SUCCESS',
  payload: {
    contactFormList: Array<ContactFormType>,
  }
};

export type SEND_CONTACT_FORM_START_ACTION = {
  type: 'SEND_CONTACT_FORM_START',
  payload: {
    formInputs: formInputsType,
    id: number
  }
};

export type SEND_CONTACT_FORM_FAILURE_ACTION = {
  type: 'SEND_CONTACT_FORM_FAILURE'
};

export type SEND_CONTACT_FORM_SUCCESS_ACTION = {
  type: 'SEND_CONTACT_FORM_SUCCESS'
};

export type Action =
  | FETCH_CONTACT_FORM_START_ACTION
  | FETCH_CONTACT_FORM_FAILURE_ACTION
  | FETCH_CONTACT_FORM_SUCCESS_ACTION
  | FETCH_ALL_CONTACT_FORM_START_ACTION
  | FETCH_ALL_CONTACT_FORM_SUCCESS_ACTION
  | SEND_CONTACT_FORM_START_ACTION
  | SEND_CONTACT_FORM_FAILURE_ACTION
  | SEND_CONTACT_FORM_SUCCESS_ACTION;

export function fetchContactForm(): FETCH_CONTACT_FORM_START_ACTION {
  return {
    type: FETCH_CONTACT_FORM_START
  };
}

export function fetchAllContactForm(): FETCH_ALL_CONTACT_FORM_START_ACTION {
  return {
    type: FETCH_ALL_CONTACT_FORM_START
  };
}

export function formFetched(contactForm: ContactFormType): FETCH_CONTACT_FORM_SUCCESS_ACTION {
  return {
    type: FETCH_CONTACT_FORM_SUCCESS,
    payload: { contactForm }
  };
}

export function formListFetched(contactFormList: Array<ContactFormType>): FETCH_ALL_CONTACT_FORM_SUCCESS_ACTION {
  return {
    type: FETCH_ALL_CONTACT_FORM_SUCCESS,
    payload: { contactFormList }
  };
}

export function formFetchedError(error: any): FETCH_CONTACT_FORM_FAILURE_ACTION {
  return {
    type: FETCH_CONTACT_FORM_FAILURE,
  };
}

export function postContactForm(formInputs: formInputsType, id: number): SEND_CONTACT_FORM_START_ACTION {
  return {
    type: SEND_CONTACT_FORM_START,
    payload: {
      formInputs,
      id
    }
  };
}

export function formPosted(): SEND_CONTACT_FORM_SUCCESS_ACTION {
  return {
    type: SEND_CONTACT_FORM_SUCCESS
  };
}

export function formPostError(error: any): SEND_CONTACT_FORM_FAILURE_ACTION {
  return {
    type: SEND_CONTACT_FORM_FAILURE
  };
}
