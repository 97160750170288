// @flow
import Article from 'components/competitionsInternationales/competitionsInternationalesArticles';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type {
  StateProps
} from 'components/competitionsInternationales/competitionsInternationalesArticles';

export default connect(
  (state: StateType): StateProps => ({
    status: state.tagArticleState.status,
    userPref: state.userState.userPref,
    competition: state.competitionsInternationalesState.data
  })
)(Article);
