// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertSeminaireFromApi } from 'services/Seminaire';

function SeminaireApi(executor: ApiExecutorType) {
  return {
    getSeminaires,
  };

  function getSeminaires() {
    const url = `/ffr/v1/seminaires`;
    return executor.get(url)
      .then(response => Promise.all(response.map(convertSeminaireFromApi)));
  }
}

export default SeminaireApi;
