// @flow

export type JobFilterType = {
  id: number,
  slug: string,
  name: string,
  link: string,
};

export type JobFiltersType = {
  domaines: Array<JobFilterType>,
  regions: Array<JobFilterType>,
  typesContrats: Array<JobFilterType>,
};

export type JobFiltersSearchType = {
  domaine_emploi: Array<number>,
  region: Array<number>,
  type_contrat: Array<number>,
  search: string,
};

export type RawJobFilterType = {
  id: number,
  count: number,
  description: string,
  link: string,
  name: string,
  slug: string,
  taxonomy: string,
  parent: number,
  meta: Array<any>,
};

export const nullJobFilter: JobFilterType = {
  id: -1,
  slug: '',
  name: '',
  link: '',
};

export const nullJobFiltersSearch: JobFiltersSearchType = {
  domaine_emploi: [],
  region: [],
  type_contrat: [],
  search: '',
};
