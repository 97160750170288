// @flow

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { getCasEndpoint } from 'constants/sso';
import { getAccountDeletionUrl } from 'utils/urlUtils';

type Props = $Exact<{
  hasMyBoutiquePrivatePage: boolean,
  logoutUrl: string,

  onCloseMenu: () => void
}>;

type State = { isOpen: boolean };

const NavLinkAttr = {
  activeClassName: 'active',
  className: 'ft-up ft-700 ft-black-secondary'
};

class General extends React.PureComponent<Props, State> {
  state = { isOpen: true };

  onClick = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }));

  render() {
    const { isOpen } = this.state;
    const { hasMyBoutiquePrivatePage, logoutUrl, onCloseMenu } = this.props;
    const deletionLinkClasses = `${NavLinkAttr.className} account-deletion-link`;

    return (
      <div className={`dashboard-menu__settings ${isOpen ? '' : 'hide-dashboard-links'}`}>
        <p className="ft-700 mb-2 ft-h5 dashboard-link-header" onClick={this.onClick}>
          Général
          <i className={`icon icon-chrevron-${isOpen ? 'down' : 'up'}`} />
        </p>

        <div className="dashboard-links">
          <NavLink {...NavLinkAttr} to="/tableau-de-bord/actualites" onClick={onCloseMenu} title="Mes actualités">
            <i className="icon icon-ball is-inline" />
            Actualites
            <i className="icon icon-record dashboard-link-right-icon icon-red small-icon" />
          </NavLink>

          {hasMyBoutiquePrivatePage ? (
            <a
              href={'/tableau-de-bord/ma-boutique'}
              className={NavLinkAttr.className}
              onClick={onCloseMenu}
              title="Boutique"
            >
              <i className="icon icon-gift is-inline" />
              Boutique
            </a>
          ) : (
            <a
              href={'https://boutique.ffr.fr'}
              className={NavLinkAttr.className}
              onClick={onCloseMenu}
              title="Boutique"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon icon-gift is-inline" />
              Boutique
              <i className="icon icon-external dashboard-link-right-icon" />
            </a>
          )}
          <NavLink {...NavLinkAttr} to="/tableau-de-bord/newsletter" onClick={onCloseMenu} title="Newsletter">
            <i className="icon icon-mail is-inline" />
            Newsletter
          </NavLink>

          <NavLink
            {...NavLinkAttr}
            to="/tableau-de-bord/mes-alertes-billetterie"
            onClick={onCloseMenu}
            style={{ display: 'none' }}
            title="Mes alertes billetterie"
          >
            <i className="icon icon-ticket is-inline" />
            Billetterie
          </NavLink>

          <a className={NavLinkAttr.className} href="/contact" title="Nous contacter">
            <i className="icon icon-arobase is-inline" />
            Nous contacter
          </a>
          <a className={NavLinkAttr.className} href={getCasEndpoint(logoutUrl, '/')} title="Me déconnecter">
            <i className="icon icon-logout is-inline" />
            Me déconnecter
          </a>
          <a className={deletionLinkClasses} href={getAccountDeletionUrl()}>
            <i className="icon icon-shield-alt is-inline" />
            Supprimer mon compte
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(General);
