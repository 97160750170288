// @flow
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string,
  url: string,
  image: string,
  label: string
};

const CardChannel = (props: Props) => (
  <Link key={props.title} to={props.url} title={props.title} className="box card card--channel ">
    <div className="card__thumbnail img-blue-dark" style={{ backgroundImage: `url(${props.image})` }}>
      <img src={props.image} alt="TBD" />
    </div>
    <div className="card__body">
      <p className="card__desc ft-h6 ft-up">{props.label}</p>
      <h3 className="card__title ft-h2">{props.title}</h3>
    </div>
    <div className="card__footer" />
  </Link>
);

export default CardChannel;
