// @flow
import React, { PureComponent } from 'react';
import type { Bloc, BlocContent, Attributes } from 'types/NewsletterBloc';
import TagManager from 'react-gtm-module';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { dynamicClassName } from 'utils/dynamicClassName';
import SimpleCarousel from 'components/fragments/SimpleCarousel/SimpleCarousel';

import './HTMLGallery.css';

export type Props = {
  childrens: Array<Bloc|BlocContent>,
  attributes: Attributes[],
  tagName: string
};

export type ComponentState = {
  focusImg: number,
  caroussel: boolean
}

class HTMLGallery extends PureComponent <Props, ComponentState> {
  state: ComponentState = {
    focusImg: 0,
    caroussel: false
  };

  handleClick = (id: number, typeGalleryFinale: string) => {
    return () => {
      TagManager.dataLayer({
        dataLayer: {
          'event': 'eventGA',
          'eventCategory' : 'module gallerie',
          'eventAction' : 'clic photo',
          'eventLabel' : window.location.pathname
        },
        dataLayerName: 'dataLayer'
      });
      this.setState({
        focusImg: id,
      });
      if (typeGalleryFinale && typeGalleryFinale === 'rencontre_itl') {
        this.openCaroussel();
      }
    };
  }

  renderBigImg = (typeGalleryFinale: string) => {
    if (typeGalleryFinale && typeGalleryFinale === 'rencontre_itl') {
      return null
    }
    const { focusImg } = this.state;
    const { childrens } = this.props;

    if (childrens && childrens.length > 0 && childrens[focusImg]) {
      if (childrens[focusImg].featured_image) {
        return (
          <figure
            onClick={this.openCaroussel}
            role="button"
            tabIndex={0}>
            <img alt="" src={childrens[focusImg].featured_image.src} className={childrens[focusImg].featured_image.class} />
            <figcaption>
            </figcaption>
          </figure>
        );
      }
    }
    return null;
  }

  openCaroussel = () => {
    this.setState({
      caroussel: true
    });
  }

  closeCaroussel = () => {
    this.setState({
      caroussel: false
    });
  }

  renderListImg = (typeGalleryFinale: string) => {
    const { childrens } = this.props;
    const { focusImg } = this.state;

    return childrens.map<any>((blocFigure: any, index) => {
      if (blocFigure.featured_image) {
        const pictureClassName = dynamicClassName("picture");
        if (index === focusImg) {
          pictureClassName.add("picture--active");
        }
        if (blocFigure.featured_image.class) {
          pictureClassName.add(blocFigure.featured_image.class);
        }
        return (
          <div
            className={pictureClassName.build()}
            key={index}
            onClick={this.handleClick(index, typeGalleryFinale)}
            role="button"
            tabIndex={0}>
            <BackgroundImage
              className="picture__thumbnail"
              illustration={blocFigure.featured_image.src}
              srcset={blocFigure.featured_image.srcset} />
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const { caroussel, focusImg } = this.state;
    const { childrens, attributes } = this.props;
    let typeGallery = attributes.filter(attr => attr.name === 'typeGallery');
    let typeGalleryFinale = '';
    let classes = "container--full carousel-photo";
    if (typeGallery && !!typeGallery.length) {
      classes += ' '+typeGallery[0].value;
      typeGalleryFinale = typeGallery[0].value;
    }

    return (
      <section className={classes}>
        {typeGalleryFinale === 'rencontre_itl' &&
          <>
          <h2 id="galerie">Galerie du match</h2>
          <p className="description mb-3">Revivez les moments les plus forts du match en image.</p>
          </>
        }
        { caroussel &&
          <SimpleCarousel
            onCloseRequest={this.closeCaroussel}
            data={childrens}
            initialIndex={focusImg} />
        }
        {this.renderBigImg(typeGalleryFinale)}
        <div className="news-slider">
          <div
            className="news-slider__wrapper news-slider__wrapper--picture">
            {this.renderListImg(typeGalleryFinale)}
          </div>
        </div>
      </section>
    );
  }
}

export default HTMLGallery;
