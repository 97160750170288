// @flow
import UnsubscribeNewsletterModalContent from 'components/fragments/modal/modalContents/UnsubscribeNewsletterModalContent';
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import { postUserPref } from 'actions/userActions';
import type {
  DispatchProps,
  StateProps
} from 'components/fragments/modal/modalContents/UnsubscribeNewsletterModalContent';
import type { DispatchType, StateType } from 'types/Actions';
import type { EditProfileValueType } from 'types/User';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  }),
  (dispatch: DispatchType): DispatchProps => ({
    displayModal: (modalObject: string) => dispatch(displayModal(modalObject)),
    postUserPref: (action: string, id: EditProfileValueType, token: string) => dispatch(postUserPref(action, id, token))
  })
)(UnsubscribeNewsletterModalContent);
