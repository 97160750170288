// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertStaffFromApi } from 'services/Staff';
import { convertObjectToQueryString } from 'utils/urlUtils';

function StaffApi(executor: ApiExecutorType) {
  return {
    fetchStaffBySlug,
    fetchStaffsTeam,
  };

  function fetchStaffBySlug(slug: string) {
    return executor
      .get(`/ffr/v1/staffs?slug=${slug}&per_page=1`)
      .then(response => convertStaffFromApi(response[0]));
  }

  function fetchStaffsTeam(filters?: Object) {
    if (filters && filters.poste && filters.poste.length > 0) {
      filters.poste = [];
    }
    if (filters && filters.saison) {
      delete filters.saison;
    }
    let url = `/ffr/v1/staffs${convertObjectToQueryString({ ...filters, per_page: 200})}`;
    return executor
      .get(url)
      .then(response => response.map(a => convertStaffFromApi(a)));
  }
}

export default StaffApi;
