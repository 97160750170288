// @flow
import React, { PureComponent } from 'react';
import ReactPixel from 'react-facebook-pixel';

import gtag from '../../../../tracking/ga-gtag';

export type StateProps = {
  acceptCookie: boolean
};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

class LogeSuccessModalContent extends PureComponent<Props> {
  componentDidMount() {
    if (this.props.acceptCookie) {
      gtag('event', 'conversion', { send_to: 'AW-854380631/bf0cCKXK6-wBENegs5cD' });
      ReactPixel.fbq('track', 'LeadEntreprise');
      // linkedin
      if (typeof window.lintrk === 'function') {
        window.lintrk('track', { conversion_id: 6807633 });
      }
    }
  }

  render() {
    const { hideModal } = this.props;

    return (
      <div className="modal-content box keep-in-touch-done modal-content--stripe">
        <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
        <div className="modal__wrapper">
          <h3 className="mb-4">Demande de proposition envoyée</h3>
          <div className="modal__body modal__body--medium narrow-2">
            <div className="circle-icon circle-icon--check"></div>
            <span>
              Votre demande a bien été envoyée. Vous serez recontacté dès que possible par l’un de nos commerciaux.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default LogeSuccessModalContent;
