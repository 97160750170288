// @flow
import React from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

import CardPlayer from 'containers/fragments/card/CardPlayerContainer';

type Props = {
  attributes: any
};

function PlayerSmallGroupCard({ attributes }: Props) {
  const hitData = attributes.find(attr => attr.name === 'hit');
  if (!hitData) {
    return null;
  }

  const hit = JSON.parse(hitData.value);
  const featured_media = {
    src: hit.image_thumbnail
  };

  let description = '';
  const descriptionData = attributes.find(attr => attr.name === 'card_title');
  if (!!descriptionData) {
    description = descriptionData.value;
  }

  let parentBlockName = '';
  const parentBlockData = attributes.find(attr => attr.name === 'parent_block_name');
  if (!!parentBlockData) {
    parentBlockName = parentBlockData.value;
  }

  const containerClasses = dynamicClassName('');
  parentBlockName === 'ffr-rencontres-itl/competition-players' && containerClasses.add('col col-lg-4');
  parentBlockName === 'ffr-rencontres-itl/conteneur-bleu' && containerClasses.add('');

  return (
    <div className={containerClasses.build()}>
      <CardPlayer
        featured_media={featured_media}
        noTextOverflow
        poste={hit.poste.name}
        title={hit.title}
        description={description}
        url={hit.url_relative}
        isCompetitionPlayer={parentBlockName === 'ffr-rencontres-itl/competition-players'}
        isConteneurBleu={parentBlockName === 'ffr-rencontres-itl/conteneur-bleu'}
      />
    </div>
  );
}

export default PlayerSmallGroupCard;
