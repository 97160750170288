// @flow
import React from 'react';
import FFRtvHub from 'components/FFRtv/FFRtvHub';
import { STATUS_SUCCESS } from 'constants/statusConstants';

type Props = {
  attrs: any
}

function YoutubePlaylistHome({attrs}: Props) {
  let videos = attrs.videos;
  if (!videos) {
    return null;
  }

  try {
    videos = JSON.parse(videos);
  } catch (e) {
    return null;
  }

  if (!Array.isArray(videos)) {
    return null;
  }

  videos = videos.filter(
    ({ kind, status }) => 'youtube#playlistItem' && status && status.privacyStatus === 'public'
  );
  if (!videos.length) {
    return null;
  }

  return (
    <FFRtvHub
      status={STATUS_SUCCESS}
      posts={videos.map(({ id, snippet, contentDetails }) => ({
        id: contentDetails.videoId,
        type: '',
        date: '',
        backgroundImage: snippet.thumbnails.medium.url,
        backgroundImageStandard: snippet.thumbnails.standard.url,
        description: snippet.title,
        comments: '',
        likes: '',
        share: '',
        duration: '',
        player: '',
        link: '',
        tags: []
      }))}
      type={attrs.type || 'home'}
      video_fond={attrs.video_fond || ''}
      image_fond={attrs.image_fond || ''}
      title={attrs.title || 'Vidéos à la une'}
      titleClass="ft-up ft-h5 mb-4"
      sectionClass="section-blueAlt section-playlist"
      containerClass=""
      hideDescriptions
      hideTvLink
      hideTvLinkBottom
      tvLinkText={`Voir toutes les vidéos`}
      tvLinkClass="link-chevron link-chevron--right section__link"
      fetchYoutubePosts={() => {}}
    />
  );
}

export default YoutubePlaylistHome