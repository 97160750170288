// @flow
import PrivatePushEvent from 'components/profile/DashboardHome/PrivatePushEvent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/profile/DashboardHome/PrivatePushEvent';

export default connect(
    (state: StateType): StateProps => ({
      userPref: state.userState.userPref,
    }),
)(PrivatePushEvent);
