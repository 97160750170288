// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS, STATUS_DEFAULT } from 'constants/statusConstants';
import {
  FETCH_CONTACT_FORM_START,
  FETCH_CONTACT_FORM_SUCCESS,
  FETCH_CONTACT_FORM_FAILURE,
  SEND_CONTACT_FORM_START,
  FETCH_ALL_CONTACT_FORM_SUCCESS,
  SEND_CONTACT_FORM_SUCCESS,
  SEND_CONTACT_FORM_FAILURE
} from 'actions/formActions';

import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';
import { nullContactForm, type ContactFormType } from 'types/ContactForm';

export type State = {
  sendStatus: Status,
  fetchStatus: Status,
  contactForm: ContactFormType,
  contactFormList: Array<ContactFormType>,
};

const initialState: State = {
  sendStatus: STATUS_DEFAULT,
  fetchStatus: STATUS_DEFAULT,
  contactForm: nullContactForm,
  contactFormList: [],
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_CONTACT_FORM_START:
      return {
        ...state,
        fetchStatus: STATUS_LOADING
      };
    case FETCH_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactForm: action.payload.contactForm,
        fetchStatus: STATUS_SUCCESS
      };
    case FETCH_ALL_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactFormList: action.payload.contactFormList,
        fetchStatus: STATUS_SUCCESS
      };
    case FETCH_CONTACT_FORM_FAILURE:
      return {
        ...state,
        fetchStatus: STATUS_FAILURE
      };
    case SEND_CONTACT_FORM_START:
      return {
        ...state,
        sendStatus: STATUS_LOADING
      };
    case SEND_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        sendStatus: STATUS_SUCCESS
      };
    case SEND_CONTACT_FORM_FAILURE:
      return {
        ...state,
        sendStatus: STATUS_FAILURE
      };
    default:
      return state;
  }
}
