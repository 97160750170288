// @flow
import React from 'react';
import type { ArticleType } from 'types/Article';
import type { FullUserType } from 'types/User';
import SideBillboardCard from 'components/billboard/SideBillboardCard';
import BillboardCard from 'components/billboard/BillboardCard';
import { Link } from 'react-router-dom';
import LazyLoad from 'components/fragments/LazyLoad';

type Props = {
  news: Array<ArticleType>,
  theme: string,
  url: string,
  userPref?: FullUserType,
};

function renderSideNews(news: Array<ArticleType>, userPref?: FullUserType) {
  return news.slice(0,3).map((news, index) => <SideBillboardCard largeRow={true} key={index} content={news} userPref={userPref} />);
}

const NewsByTheme = (props: Props) => (
  <LazyLoad isWhite>
    <div className="row">
      <div className="col col-lg-8on9">
        <Link to={props.url} className="link-chevron link-chevron--right" data-label="">
          <span>Tout voir</span>
          <i className="icon icon-chevron-right" />
        </Link>
        <h3 className="mb-3 ft-400">{props.theme}</h3>
      </div>
      <div className="col col-lg-5on9">
        <BillboardCard
          content={props.news[0]}
          small={true}
          containerStyle={'mb-4 mb-md-5 mb-lg-6'}
          userPref={props.userPref}
        />
      </div>
      <div className="col col-lg-3on9">
        <div className="news-slider mb-6 mb-md-7">
          <div className="news-slider__wrapper">{renderSideNews(props.news.slice(1), props.userPref)}</div>
        </div>
      </div>
    </div>
  </LazyLoad>
);

export default NewsByTheme;
