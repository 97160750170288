// @flow
import type { SocialContent, SocialProvider } from 'types/SocialContent';
import { AllProvider } from 'constants/socialConstants';

export function getValidSocialCards(socialCards: Array<SocialContent>, provider: SocialProvider): Array<SocialContent> {
  const validSocialCards = provider === AllProvider ? socialCards : socialCards.filter(card => card.type === provider);
  return validSocialCards.sort(function(a, b) {
    const aDate = a.date instanceof Date ? a.date : new Date(a.date);
    const bDate = b.date instanceof Date ? b.date : new Date(b.date);
    return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
  });
}
/**
 * Transforme une durée envoyée par l'API de Youtube en durée intelligible
 * 
 * @param string duration 
 */
export function youtubeDurationToTime(duration: string) {
  const match = /PT(\d+H)?(\d+M)?(\d+S)?/.exec(duration);
  let timeString = duration;
  if (null !== match) {
    const match_sliced = match.slice(1).map(function(x) {
      if (x != null) {
          return x.replace(/\D/, '');
      }
      return x;
    });

    const hours = (parseInt(match_sliced[0], 10) || 0);
    const minutes = (parseInt(match_sliced[1], 10) || 0);
    const seconds = (parseInt(match_sliced[2], 10) || 0);
    const total_seconds = hours * 3600 + minutes * 60 + seconds;

    const date = new Date(1970, 1, 1 , hours, minutes, seconds);
    timeString = date.toISOString().substr(total_seconds > 3600 ? 11 : 14, total_seconds > 3600 ? 8 : 5);
  }
  return timeString;
}
