// @flow

import React, { PureComponent } from 'react';
import { Link, NavLink } from 'react-router-dom';

import DropdownRadioInput from 'components/fragments/input/DropdownRadioInput';
import { getPublicAssets } from 'utils/assetsUtils';
import { formatFullDate } from 'utils/dateUtils';
import { removeAccents } from 'utils/nameUtils';
import { youtubeDurationToTime } from 'utils/socialUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

import type { ArticleType } from 'types/Article';
import type { FullUserType } from 'types/User';

function MockComponent() {
  return (
    <article className="news news--list news--listSmall loading-linear-background">
      <div className="news__thumbnail" style={{ backgroundImage: `url('')` }}></div>
      <div className="news__data">
        <span className="news__title ft-h5"></span>
        <p className="ft-h6">
          <span className="news__cat ft-up"></span>
          <span className="news__date"></span>
        </p>
      </div>
    </article>
  );
}

export type DispatchProps = {
  displayModal: (modalObject: string, content: Object) => void
};

export type StateProps = {
  userPref: FullUserType
};

type Props = DispatchProps &
  StateProps & {
    articles: ArticleType[],
    handleDropdownCategory?: Function,
    link_all?: string,
    link_text_all?: string,
    loading?: boolean,
    displayMainNews?: boolean,
    selectedArticleCategory?: string,
    show_link_preferences?: boolean,
    title?: string
  };

type State = {
  isAccordionOpen: boolean
};

function getImageForThumbnail(actu: ArticleType) {
  let imageSource;
  const { illustration, srcset } = actu;

  if (srcset) {
    if (srcset.thumbnail) {
      imageSource = srcset.thumbnail.src;
    } else if (srcset.medium) {
      imageSource = srcset.medium.src;
    } else if (srcset.medium_large) {
      imageSource = srcset.medium_large.src;
    } else if (srcset.large) {
      imageSource = srcset.large.src;
    }
  }

  imageSource = imageSource || illustration;

  return imageSource ? imageSource : getPublicAssets('/img/placeholder-ffr.jpg');
}

export default class MyNewsletterFeed extends PureComponent<Props, State> {
  state: State = {
    isAccordionOpen: false
  };

  renderMainNews = () => {
    const {
      userPref,
      userPref: {
        equipes_favoris,
        joueurs_favoris,
        main_news: { duration, featured_media, link, title, type }
      }
    } = this.props;
    const videoDuration = youtubeDurationToTime(duration);
    const mainNewsTitle =
      (joueurs_favoris && joueurs_favoris.length > 0) || (equipes_favoris && equipes_favoris.length > 0)
        ? 'à la une - mes favoris'
        : 'à la une';

    return (
      <section className="private-main-news">
        <div className="row">
          <div className="col">
            <div className="box card card--hub-alt card--small">
              <div className="card__thumbnail img-blue-dark" style={{ backgroundImage: `url(${featured_media})` }}>
                <img src={featured_media} alt="" />
              </div>
              <div className="card__body">
                <h3 className="ft-h5 ft-up mb-2">{mainNewsTitle}</h3>
                <h4 className="card__title ft-h1" dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              <div className="card__footer">
                <a
                  href={link}
                  onClick={handleTagEvent('module_ecosysteme', link, '', userPref)}
                  title={title}
                  rel="noopener noreferrer"
                  className="private-main-news__link"
                >
                  <div className="private-main-news__icon">
                    {type === 'video_joueur' ? (
                      <i className="icon icon-play"></i>
                    ) : (
                      <i className="icon icon-chevron-right"></i>
                    )}
                  </div>
                  {type === 'video_joueur' ? (
                    <div className="ft-white">
                      Voir la vidéo
                      <p className="ft-white-tertiary">{videoDuration}</p>
                    </div>
                  ) : (
                    <div>Lire l&apos;article</div>
                  )}
                </a>
                {// display the link to my favorites page if the user has some
                joueurs_favoris && joueurs_favoris.length > 0 && (
                  <NavLink
                    activeClassName="active"
                    className="private-main-news__link"
                    to="/tableau-de-bord/mes-favoris"
                    title="Mes favoris"
                  >
                    Voir toutes les actualités de mes joueurs favoris
                    <i className="icon icon-chevron-right"></i>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  renderNewsletterFeed = (isMainDisplayedFeed: boolean) => {
    const { userPref, articles } = this.props;
    const { isAccordionOpen } = this.state;

    return articles.map<any>((actu, i) => {
      const {
        title,
        link,
        date,
        label: { name: categoryName }
      } = actu;
      const imgSource = getImageForThumbnail(actu);

      return (
        <Link
          key={`newsletter_feed_${title}_${i}`}
          title={title}
          className="news news--list news--listSmall"
          to={link}
          onClick={handleTagEvent('espace perso', 'clic_actu', title, userPref)}
        >
          <article>
            <div className="news__thumbnail" style={{ backgroundImage: `url(${imgSource})` }}>
              <img src={imgSource} alt={`Illustration de l'article ${title}`} />
            </div>

            <div
              style={isMainDisplayedFeed && i === 8 && isAccordionOpen ? { borderBottom: '1px solid #ebebeb' } : null}
            >
              <span className="news__title ft-h5">{title}</span>
              <p className="ft-h6">
                <span className="news__cat ft-up">{categoryName}</span>
                <span className="news__date">{formatFullDate(new Date(date))}</span>
              </p>
            </div>
          </article>
        </Link>
      );
    });
  };

  renderThematicMenu = () => {
    const {
      userPref: { newsletter_themes },
      selectedArticleCategory,
      handleDropdownCategory
    } = this.props;

    // transform newsletter list from objet into an array
    const newsletterKeys = Object.keys(newsletter_themes);
    const newsletterList = newsletterKeys.map(key => ({
      label: newsletter_themes[key],
      value: key,
      isActive: key === selectedArticleCategory
    }));

    // sort newsletterList alphabetically
    const sortedNewsletterList = newsletterList.sort((a, b) => {
      const aLabel = removeAccents(a.label);
      const bLabel = removeAccents(b.label);

      return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
    });

    // get user's newsletter
    const userNewsletter = Object.keys(newsletter_themes).join(',');

    // add All Themes option
    if (newsletterList.length > 1) {
      const allThemeExists = sortedNewsletterList.filter(category => category.value === userNewsletter);
      if (allThemeExists.length < 1) {
        sortedNewsletterList.unshift({ label: 'Toutes les thématiques', value: userNewsletter });
      }

      // current value for the dropdown
      const dropdownLabel =
        selectedArticleCategory !== ''
          ? sortedNewsletterList.filter(category => category.value === selectedArticleCategory)[0].label
          : '';

      return (
        <DropdownRadioInput
          options={newsletterList}
          inputChanged={handleDropdownCategory}
          label={dropdownLabel}
          radio={true}
          maj={true}
          noBorder={true}
        />
      );
    } else if (newsletterList.length === 1) {
      return <p className="user__unique-theme">{newsletterList[0].label}</p>;
    }
    return null;
  };

  renderLoading = () => {
    return (
      <>
        <MockComponent />
        <MockComponent />
        <MockComponent />
        <MockComponent />
        <MockComponent />
      </>
    );
  };

  render() {
    const { renderNewsletterFeed, renderThematicMenu, renderLoading } = this;
    const {
      articles,
      displayMainNews,
      displayModal,
      link_all,
      link_text_all,
      loading,
      show_link_preferences,
      title,
      userPref
    } = this.props;

    if ((!articles || articles.length === 0) && !loading) {
      return null;
    }

    return (
      <div id="newsletterfeed" className="box box--white card card--profile card--actus mb-7">
        {// we want to display the main news only when we are on the dashboard
        !!displayMainNews && this.renderMainNews()}
        <div className="card__header">
          {show_link_preferences && (
            <button
              style={{ float: 'right', color: '#2A9BFF', fontSize: '.9em', fontWeight: 'bold', paddingRight: '0' }}
              onClick={() => displayModal('MODAL_NEWS_SUBSCRIPTION', {})}
            >
              Gérer mes préférences <i className="icon icon-gear is-inline"></i>
            </button>
          )}
          <h3 className="ft-h4">
            <span className="ft-up">{title ? title : 'Actualités'}</span>
            {!show_link_preferences && renderThematicMenu()}
          </h3>
        </div>
        <div className="card__body">{loading === true ? renderLoading() : renderNewsletterFeed(true)}</div>
        <div className="card__footer">
          <Link
            className="ft-700 ft-center"
            title="Découvrir plus d'article"
            to={link_all ? link_all : '/tableau-de-bord/actualites'}
            role="button"
            tabIndex="0"
            onClick={handleTagEvent('espace_perso', 'clic_toutes_actus', '', userPref)}
          >
            <span>
              {link_text_all ? link_text_all : 'Voir toutes les actualités'} <i className={'icon icon-chevron-right'} />
            </span>
          </Link>
        </div>
      </div>
    );
  }
}
