// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_AUTEUR_START,
  FETCH_AUTEUR_SUCCESS,
  FETCH_AUTEUR_FAILURE,
  FETCH_ARTICLES_AUTEUR_START,
  FETCH_ARTICLES_AUTEUR_SUCCESS,
  FETCH_ARTICLES_AUTEUR_FAILURE,
} from 'actions/auteurActions';

import { nullAuteur } from 'types/Auteur';
import type { ArticleType } from 'types/Article';
import type { AuteurType } from 'types/Auteur';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  auteur: AuteurType,
  articles: Array<ArticleType>,
  status: Status,
  statusArticles: Status,
  articleNumber: number
};

const initialState: State = {
  auteur: nullAuteur,
  articles: [],
  status: STATUS_LOADING,
  statusArticles: STATUS_LOADING,
  articleNumber: 0,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_AUTEUR_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_AUTEUR_SUCCESS:
      return {
        ...state,
        auteur: action.payload.auteur,
        status: STATUS_SUCCESS
      };
    case FETCH_AUTEUR_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_ARTICLES_AUTEUR_START:
      return {
        ...state,
        statusArticles: STATUS_LOADING
      };
    case FETCH_ARTICLES_AUTEUR_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        articleNumber: action.payload.articleNumber,
        statusArticles: STATUS_SUCCESS
      };
    case FETCH_ARTICLES_AUTEUR_FAILURE:
      return {
        ...state,
        statusArticles: STATUS_FAILURE
      };
    default:
      return state;
  }
}
