// @flow

import type { LogeListType } from 'types/LogeList';
import type { LogeDetailType } from 'types/LogeDetail';

export const FETCH_LOGE_LIST_START = 'FETCH_LOGE_LIST_START';
export const FETCH_LOGE_LIST_SUCCESS = 'FETCH_LOGE_LIST_SUCCESS';
export const FETCH_LOGE_LIST_FAILURE = 'FETCH_LOGE_LIST_FAILURE';

export const FETCH_LOGE_DETAIL_START = 'FETCH_LOGE_DETAIL_START';
export const FETCH_LOGE_DETAIL_SUCCESS = 'FETCH_LOGE_DETAIL_SUCCESS';
export const FETCH_LOGE_DETAIL_FAILURE = 'FETCH_LOGE_DETAIL_FAILURE';

export const FETCH_LOGE_PREVIEWBYID_START = 'FETCH_LOGE_PREVIEWBYID_START';
export const FETCH_LOGE_PREVIEWBYID_SUCCESS = 'FETCH_LOGE_PREVIEWBYID_SUCCESS';
export const FETCH_LOGE_PREVIEWBYID_FAILURE = 'FETCH_LOGE_PREVIEWBYID_FAILURE';

export const FETCH_UNKNOWN = 'FETCH_UNKNOWN';

export type FETCH_LOGE_LIST_START_ACTION = {
  type: 'FETCH_LOGE_LIST_START',
  payload: {
    match: string,
  },
};

export type FETCH_LOGE_LIST_FAILURE_ACTION = {
  type: 'FETCH_LOGE_LIST_FAILURE',
};

export type FETCH_LOGE_LIST_SUCCESS_ACTION = {
  type: 'FETCH_LOGE_LIST_SUCCESS',
  payload: {
    loges: Array<LogeListType>,
  },
};

export type FETCH_LOGE_DETAIL_START_ACTION = {
  type: 'FETCH_LOGE_DETAIL_START',
  payload: {
    slug: string,
  },
};

export type FETCH_LOGE_DETAIL_FAILURE_ACTION = {
  type: 'FETCH_LOGE_DETAIL_FAILURE'
};

export type FETCH_LOGE_DETAIL_SUCCESS_ACTION = {
  type: 'FETCH_LOGE_DETAIL_SUCCESS',
  payload: {
    details: LogeDetailType,
    analytics: boolean,
  },
};

export type FETCH_LOGE_PREVIEWBYID_START_ACTION = {
  type: 'FETCH_LOGE_PREVIEWBYID_START',
  payload: {
    id: number,
  },
};

export type FETCH_LOGE_PREVIEWBYID_FAILURE_ACTION = {
  type: 'FETCH_LOGE_PREVIEWBYID_FAILURE'
};

export type FETCH_LOGE_PREVIEWBYID_SUCCESS_ACTION = {
  type: 'FETCH_LOGE_PREVIEWBYID_SUCCESS',
  payload: {
    details: LogeDetailType,
    analytics: boolean
  }
};

export type FETCH_UNKNOWN_ACTION = {
  type: 'FETCH_UNKNOWN'
};

export type Action =
  | FETCH_LOGE_LIST_START_ACTION
  | FETCH_LOGE_LIST_FAILURE_ACTION
  | FETCH_LOGE_LIST_SUCCESS_ACTION
  | FETCH_LOGE_DETAIL_START_ACTION
  | FETCH_LOGE_DETAIL_FAILURE_ACTION
  | FETCH_LOGE_DETAIL_SUCCESS_ACTION
  | FETCH_LOGE_PREVIEWBYID_START_ACTION
  | FETCH_LOGE_PREVIEWBYID_FAILURE_ACTION
  | FETCH_LOGE_PREVIEWBYID_SUCCESS_ACTION
  | FETCH_UNKNOWN_ACTION;

export function fetchLogeList(match?: string): FETCH_LOGE_LIST_START_ACTION {
  return {
    type: FETCH_LOGE_LIST_START,
    payload: {
      match: match ? match : '',
    },
  };
}

export function logeListFetched(
  loges: Array<LogeListType>,
): FETCH_LOGE_LIST_SUCCESS_ACTION {
  return {
    type: FETCH_LOGE_LIST_SUCCESS,
    payload: { loges },
  };
}

export function logeListFetchError(): FETCH_LOGE_LIST_FAILURE_ACTION {
  return {
    type: FETCH_LOGE_LIST_FAILURE,
  };
}

export function fetchLogeDetail(slug: string): FETCH_LOGE_DETAIL_START_ACTION {
  return {
    type: FETCH_LOGE_DETAIL_START,
    payload: { slug },
  };
}

export function logeDetailFetched(details: LogeDetailType, analytics?: boolean): FETCH_LOGE_DETAIL_SUCCESS_ACTION {
  return {
    type: FETCH_LOGE_DETAIL_SUCCESS,
    payload: {
      details: details,
      analytics: analytics ? analytics : false,
    },
  };
}

export function logeDetailFetchError(): FETCH_LOGE_DETAIL_FAILURE_ACTION {
  return {
    type: FETCH_LOGE_DETAIL_FAILURE,
  };
}

export function fetchLogeDetailPreview(id: number): FETCH_LOGE_PREVIEWBYID_START_ACTION {
  return {
    type: FETCH_LOGE_PREVIEWBYID_START,
    payload: { id }
  };
}

export function logeDetailPreviewFetched(
  details: LogeDetailType,
  analytics?: boolean
): FETCH_LOGE_PREVIEWBYID_SUCCESS_ACTION {
  return {
    type: FETCH_LOGE_PREVIEWBYID_SUCCESS,
    payload: {
      details,
      analytics: analytics ? analytics : false
    }
  };
}

export function logeDetailPreviewFetchError(): FETCH_LOGE_PREVIEWBYID_FAILURE_ACTION {
  return {
    type: FETCH_LOGE_PREVIEWBYID_FAILURE,
  };
}

export function fetchUnknown(): FETCH_UNKNOWN_ACTION {
  return {
    type: FETCH_UNKNOWN,
  };
}
