
// @flow

function ucwords (str: string) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
    });
}

export function ffrUserName(name: string) {
    const nom = name.toLowerCase().replace('-',' _-_ ');
    return ucwords(nom).replace('Xv ', 'XV ').replace('A S M', 'ASM').replace('As ', 'AS ').replace(' De ', ' de ').replace(' _-_ ', '-').trim();
}

export const removeAccents = (s: string) => {
    return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};