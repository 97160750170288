// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertEventPreviewFromApi, convertEventFromApi, convertMatchEventFromApi } from 'services/Event';

function EventApi(executor: ApiExecutorType) {
  return { fetchEvents, fetchMatchEvents, fetchEvent, fetchEventsPreview, fetchEventsByTeamSeason };

  function fetchEvents(from: Date, to: Date) {
    from.setDate(from.getDate());
    to.setDate(to.getDate() + 1);
    return executor
      .get(`/ffr/v1/events?start_date=${from.toISOString()}&end_date=${to.toISOString()}&per_page=300`)
      .then(response => {
        return response.events.map(event => convertEventFromApi(event));
      });
  }

  function fetchEventsByTeamSeason(filters: Object) {
    return Promise.all([
      filters.equipeTitle ? executor
        .get(`/ffr/v1/events?categories=${filters.equipeTitle}&start_date=${filters.startDate}&end_date=${filters.endDate}&per_page=300`)
        .then(response => {
          return response.events.map(event => convertEventFromApi(event));
      }) : [],
      executor
        .get(`/ffr/v1/rencontres_itl?equipe=${filters.equipe}&season=${filters.season}&per_page=300`)
        .then(response => {
          return response.map(match => convertMatchEventFromApi(match));
        })
    ]).then(([events, rencontres]) => {
      return [...events, ...rencontres];
    });
  }

  function fetchMatchEvents(from: Date, to: Date) {
    from.setDate(from.getDate());
    to.setDate(to.getDate() + 1);
    return executor
      .get(`/ffr/v1/rencontres_itl?start_date=${from.toISOString()}&end_date=${to.toISOString()}&per_page=300`)
      .then(response => {
        return response.map(match => convertMatchEventFromApi(match));
      });
  }

  function fetchEventsPreview() {
    return executor
      .get('/ffr/v1/events')
      .then(response => response.events.map(event => convertEventPreviewFromApi(event)));
  }

  function fetchEvent(slug: string) {
    return executor.get(`/ffr/v1/events/by-slug/${slug}`).then(response => convertEventFromApi(response));
  }
}

export default EventApi;
