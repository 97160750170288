// @flow
import { connect } from 'react-redux';

import SocialButtons from 'components/social/SocialButtons';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/social/SocialButtons';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  })
)(SocialButtons);
