// @flow
import React from 'react';

import SingleMatchCard from 'components/competitionsInternationales/SingleMatchCard';

type Props = {
  attributes: any
};

function SingleMatchCardBlocContent({ attributes }: Props) {
  const matchHitData = attributes.find(attr => attr.name === 'hit');
  if (!matchHitData || !matchHitData.value) {
    return null;
  }

  const rencontre = JSON.parse(matchHitData.value);
  const titleData = attributes.find(attr => attr.name === 'title');
  const title = !!titleData && !!titleData.value ? titleData.value : '';
  const titleColorData = attributes.find(attr => attr.name === 'titlecolor');
  const titleColor = !!titleColorData && !!titleColorData.value ? titleColorData.value : '';

  return (
    <>
      {!!title && (
        <>
          <h3 className={titleColor ? `single-match-card__title is-${titleColor}` : ''}>{title}</h3>
          <hr className="single-match-card__hr mb-6" />
        </>
      )}

      <SingleMatchCard rencontre={rencontre} equipeNom={'France'} />
    </>
  );
}

export default SingleMatchCardBlocContent;
