// @flow
import ClubFinderHub from 'components/club/ClubFinderHub';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/club/ClubFinderHub';

export default connect(
  (state: StateType): StateProps => ({
    blocs_trouver_club: state.settingsState.blocs_trouver_club,
    clubs: state.clubsState.clubs,
    clubsSemaine: state.settingsState.clubs_semaine,
    featured_media: state.settingsState.bloc_trouver_club.featured_media,
    pratiques: state.settingsState.pratiques_clubs,
    pratiques_ok: state.searchState.pratiques_ok,
    searchCities: [],
    searchClubs: [],
    searchTypes: [],
    status: state.clubsState.status,
    userPref: state.userState.userPref
  })
)(ClubFinderHub);
