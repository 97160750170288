// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START = 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START';
export const FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS = 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS';
export const FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE = 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE';

export type FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START',
  payload: { tabIdsPlaylist: Array<string> }
};

export type FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS',
  payload: {
    videos: Array<Array<YoutubeVideo>>
  }
};

export type FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE'
};

export type Action =
  | FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START_ACTION
  | FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE_ACTION
  | FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS_ACTION;

export function fetchYoutubeVideosPlaylists(
  tabIdsPlaylist: Array<string>
): FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLISTS_START,
    payload: {
      tabIdsPlaylist
    }
  };
}

export function youtubeVideosPlaylistsFetched(
  videos: Array<Array<YoutubeVideo>>,
): FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLISTS_SUCCESS,
    payload: { videos }
  };
}

export function youtubeVideosPlaylistsFetchError(): FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLISTS_FAILURE
  };
}
