// @flow
import React, { Component, Fragment } from 'react';
import isNil from 'lodash/fp/isNil';
import type { Bloc, BlocContent } from 'types/NewsletterBloc';
import 'components/fragments/SimpleCarousel/SimpleCarousel.css';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  onCloseRequest: Function,
  data: Array<Bloc|BlocContent>,
  initialIndex: number,
};

type State = {
  focusImg: number,
};

class SimpleCarousel extends Component<Props, State> {
  modal: ?HTMLElement

  static defaultProps = {
    initialIndex: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      focusImg: props.initialIndex,
    };
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false);
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleKeyUp = (e: KeyboardEvent) => {
    const { onCloseRequest } = this.props;
    const keys = {
      '27': () => {
        e.preventDefault();
        onCloseRequest();
        window.removeEventListener('keyup', this.handleKeyUp, false);
      },
      '37': () => {
        e.preventDefault();
        this._handleClickLeft();
      },
      '39': () => {
        e.preventDefault();
        this._handleClickRight();
      }
    };

    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }

  handleOutsideClick = (e: Event) => {
    const { onCloseRequest } = this.props;

    if (!isNil(this.modal) && this.modal) {
      if (e.target instanceof HTMLInputElement) {
        if (!this.modal.contains(e.target)) {
          onCloseRequest();
          document.removeEventListener('click', this.handleOutsideClick, false);
        }
      }
    }
  }

  _renderChildrens = () => {
    const { data } = this.props;
    const { focusImg } = this.state;
    let nextImage, prevImage;
    const prev = focusImg - 1;
    const next = focusImg + 1;
    if (data[prev] && data[prev].featured_image && data[prev].featured_image.src) {
      const prevClassName = dynamicClassName("prevImage");
      if (data[prev].featured_image && data[prev].featured_image.class) {
        prevClassName.add(data[prev].featured_image.class);
      }
      if (data[prev].featured_image && data[prev].featured_image.src) {
        prevImage = <div className={prevClassName.build()}>
          <img alt="Précédente" src={data[prev].featured_image.src}/>
        </div>;
      }
    }
    if (data[next] && data[next].featured_image && data[next].featured_image.src) {
      const nextClassName = dynamicClassName("nextImage");
      if (data[next].featured_image && data[next].featured_image.class) {
        nextClassName.add(data[next].featured_image.class);
      }
      if (data[next].featured_image && data[next].featured_image.src) {
        nextImage = <div className={nextClassName.build()}>
          <img alt="Suivante" src={data[next].featured_image.src}/>
        </div>;
      }
    }
    
    const pictureClassName = dynamicClassName("centeredImage");
    if (data[focusImg].featured_image && data[focusImg].featured_image.class) {
      pictureClassName.add(data[focusImg].featured_image.class);
    }

    if (data[focusImg].featured_image) {
      return (
        <Fragment>
          {prevImage}
          <div className={pictureClassName.build()}><img alt="" src={data[focusImg].featured_image.src} /></div>
          {nextImage}
        </Fragment>
      );
    }
    return null;
  }

  _handleClickRight = () => {
    const { data } = this.props;
    const { focusImg } = this.state;

    if (data.length - 1 <= focusImg) {
      this.setState({
        focusImg: 0,
      });
    } else {
      this.setState((prevState) => ({
        focusImg: prevState.focusImg + 1,
      }));
    }
  }

  _handleClickLeft = () => {
    const { data } = this.props;
    const { focusImg } = this.state;

    if (focusImg <= 0) {
      this.setState({
        focusImg: data.length - 1,
      });
    } else {
      this.setState((prevState) => ({
        focusImg: prevState.focusImg - 1,
      }));
    }
  }

  handleClick = (id: number) => {
    return () => {
      this.setState({
        focusImg: id,
      });
    };
  }

  renderListImg = () => {
    const { data } = this.props;
    const { focusImg } = this.state;

    return data.map<any>((blocFigure: any, index) => {
      if (blocFigure.featured_image) {
        const pictureClassName = dynamicClassName("picture");
        if (focusImg === index) {
          pictureClassName.add("picture--active");
        }
        if (blocFigure.featured_image.class) {
          pictureClassName.add(blocFigure.featured_image.class);
        }
        return (
          <div
            className={pictureClassName.build()}
            role="button"
            tabIndex={0}
            key={index} onClick={this.handleClick(index)}>
            <BackgroundImage 
              className="picture__thumbnail" 
              illustration={blocFigure.featured_image.src} 
              srcset={blocFigure.featured_image.srcset}
            />
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const {
      onCloseRequest,
    } = this.props;
    return (
      <div className='modalOverlay'
        ref={r => (this.modal = r)}>
        <div className='modalTop'>
          <icon
            type="button"
            className='icon icon-close_alt menu__close js-showMenu'
            onClick={onCloseRequest}/>
        </div>
        <div className="modalWrapper">
          <div className='leftButtonContainer'>
            <button
              type='button'
              className='leftButton'
              onClick={this._handleClickLeft}>
              {'<'}
            </button>
          </div>
          <div className='modalContent'>
            {this._renderChildrens()}
          </div>
          <div className='rightButtonContainer'>
            <button
              type='button'
              className='rightButton'
              onClick={this._handleClickRight}>
              {'>'}
            </button>
          </div>
        </div>
        <div className="modalFooter">
          <div className="news-slider">
            <div className="news-slider__wrapper news-slider__wrapper--picture">
              {this.renderListImg()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleCarousel;
