// @flow

import type { formRematchInputsType } from 'types/RematchForm';

export const FETCH_REMATCH_START = 'FETCH_REMATCH_START';
export const FETCH_REMATCH_SUCCESS = 'FETCH_REMATCH_SUCCESS';
export const FETCH_REMATCH_FAILURE = 'FETCH_REMATCH_FAILURE';
export const SEND_REMATCH_FORM_START = 'SEND_REMATCH_FORM_START';
export const SEND_REMATCH_FORM_SUCCESS = 'SEND_REMATCH_FORM_SUCCESS';
export const SEND_REMATCH_FORM_FAILURE = 'SEND_REMATCH_FORM_FAILURE';

export type FETCH_REMATCH_START_ACTION = {
  type: 'FETCH_REMATCH_START',
  payload: {}
};

export type FETCH_REMATCH_FAILURE_ACTION = {
  type: 'FETCH_REMATCH_FAILURE'
};

export type FETCH_REMATCH_SUCCESS_ACTION = {
  type: 'FETCH_REMATCH_SUCCESS',
  payload: {
    data: Object
  },
};

export type SEND_REMATCH_FORM_START_ACTION = {
  type: 'SEND_REMATCH_FORM_START',
  payload: {
    formInputs: formRematchInputsType
  }
};

export type SEND_REMATCH_FORM_FAILURE_ACTION = {
  type: 'SEND_REMATCH_FORM_FAILURE'
};

export type SEND_REMATCH_FORM_SUCCESS_ACTION = {
  type: 'SEND_REMATCH_FORM_SUCCESS',
  payload: {
    data: Object
  },
};

export type Action =
  | FETCH_REMATCH_START_ACTION
  | FETCH_REMATCH_FAILURE_ACTION
  | FETCH_REMATCH_SUCCESS_ACTION
  | SEND_REMATCH_FORM_START_ACTION
  | SEND_REMATCH_FORM_FAILURE_ACTION
  | SEND_REMATCH_FORM_SUCCESS_ACTION;


export function fetchRematch(): FETCH_REMATCH_START_ACTION {
  return {
    type: FETCH_REMATCH_START,
    payload: {}
  };
}

export function rematchFetched(
  data: Object
  ): FETCH_REMATCH_SUCCESS_ACTION {
  return {
    type: FETCH_REMATCH_SUCCESS,
    payload: { data }
  };
}

export function rematchFetchError(error: any): FETCH_REMATCH_FAILURE_ACTION {
  return {
    type: FETCH_REMATCH_FAILURE
  };
}
  
export function postRematchForm(formInputs: formRematchInputsType): SEND_REMATCH_FORM_START_ACTION {
  return {
    type: SEND_REMATCH_FORM_START,
    payload: {
      formInputs,
    }
  };
}

export function rematchFormPosted(data:Object): SEND_REMATCH_FORM_SUCCESS_ACTION {
  return {
    type: SEND_REMATCH_FORM_SUCCESS,
    payload: { data }
  };
}

export function rematchFormPostError(error: any): SEND_REMATCH_FORM_FAILURE_ACTION {
  return {
    type: SEND_REMATCH_FORM_FAILURE
  };
}
