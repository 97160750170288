// @flow
import React from 'react';

export type StateProps = {
  titleLigue: string
};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

function NewsletterMissingOptModalContent({ content, hideModal, titleLigue }: Props) {
  return (
    <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0"></i>
      <div className="modal__wrapper">
        <h3 className="mb-2">
          {content && content.text}
          {(!content || !content.text) && `Pour vous inscrire, merci d'accepter les conditions de la ${titleLigue}`}
        </h3>
        <div className="modal__body modal__body--medium narrow-2 mb-3">
          <div className="circle-icon circle-icon--alert"></div>
          <p></p>
        </div>
      </div>
    </div>
  );
}

export default NewsletterMissingOptModalContent;
