//@flow

import type { EditProfileValueType, FullUserType, UserFavsType, UserFidType } from 'types/User';

export const FETCH_USER_PREF_START = 'FETCH_USER_PREF_START';
export const FETCH_USER_PREF_SUCCESS = 'FETCH_USER_PREF_SUCCESS';
export const FETCH_USER_PREF_FAILURE = 'FETCH_USER_PREF_FAILURE';

export const POST_USER_PREF_START = 'POST_USER_PREF_START';
export const POST_USER_PREF_SUCCESS = 'POST_USER_PREF_SUCCESS';
export const POST_USER_PREF_FAILURE = 'POST_USER_PREF_FAILURE';

export const FETCH_USER_FID_START = 'FETCH_USER_FID_START';
export const FETCH_USER_FID_SUCCESS = 'FETCH_USER_FID_SUCCESS';
export const FETCH_USER_FID_FAILURE = 'FETCH_USER_FID_FAILURE';

export const POST_USER_FID_START = 'POST_USER_FID_START';
export const POST_USER_FID_SUCCESS = 'POST_USER_FID_SUCCESS';
export const POST_USER_FID_FAILURE = 'POST_USER_FID_FAILURE';

export const FETCH_USER_FAVS_START = 'FETCH_USER_FAVS_START';
export const FETCH_USER_FAVS_SUCCESS = 'FETCH_USER_FAVS_SUCCESS';
export const FETCH_USER_FAVS_FAILURE = 'FETCH_USER_FAVS_FAILURE';

export const KEYCLOAK_CONNECT = 'KEYCLOAK_CONNECT';
export const KEYCLOAK_CONNECT_SUCCESS = 'KEYCLOAK_CONNECT_SUCCESS';
export const KEYCLOAK_CONNECT_FAILURE = 'KEYCLOAK_CONNECT_FAILURE';

export type FETCH_USER_PREF_START_ACTION = {
  type: 'FETCH_USER_PREF_START',
  payload: {
    full: boolean,
    token: string
  }
};

export type FETCH_USER_PREF_SUCCESS_ACTION = {
  type: 'FETCH_USER_PREF_SUCCESS',
  payload: {
    userPref: FullUserType
  }
};

export type FETCH_USER_PREF_FAILURE_ACTION = {
  type: 'FETCH_USER_PREF_FAILURE',
  payload: {
    error: string
  }
};

export type POST_USER_PREF_START_ACTION = {
  type: 'POST_USER_PREF_START',
  payload: {
    action: string,
    value: EditProfileValueType,
    token: string,
    redirect?: string
  }
};

export type POST_USER_PREF_SUCCESS_ACTION = {
  type: 'POST_USER_PREF_SUCCESS',
  payload: {
    response: FullUserType
  }
};

export type POST_USER_PREF_FAILURE_ACTION = {
  type: 'POST_USER_PREF_FAILURE',
  payload: {
    error: string
  }
};
export type FETCH_USER_FID_START_ACTION = {
  type: 'FETCH_USER_FID_START',
  payload: {
    token: string
  }
};

export type FETCH_USER_FID_SUCCESS_ACTION = {
  type: 'FETCH_USER_FID_SUCCESS',
  payload: {
    userFid: UserFidType
  }
};

export type FETCH_USER_FID_FAILURE_ACTION = {
  type: 'FETCH_USER_FID_FAILURE',
  payload: {
    error: string
  }
};

export type POST_USER_FID_START_ACTION = {
  type: 'POST_USER_FID_START',
  payload: {
    action: string,
    value: EditProfileValueType,
    token: string,
    redirect?: string
  }
};

export type POST_USER_FID_SUCCESS_ACTION = {
  type: 'POST_USER_FID_SUCCESS',
  payload: {
    response: UserFidType
  }
};

export type POST_USER_FID_FAILURE_ACTION = {
  type: 'POST_USER_FID_FAILURE',
  payload: {
    error: string
  }
};

export type FETCH_USER_FAVS_START_ACTION = {
  type: 'FETCH_USER_FAVS_START',
  payload: {
    token: string
  }
};

export type FETCH_USER_FAVS_SUCCESS_ACTION = {
  type: 'FETCH_USER_FAVS_SUCCESS',
  payload: {
    userFavs: UserFavsType
  }
};

export type FETCH_USER_FAVS_FAILURE_ACTION = {
  type: 'FETCH_USER_FAVS_FAILURE',
  payload: {
    error: string
  }
};

export type KEYCLOAK_CONNECT_ACTION = {
  type: 'KEYCLOAK_CONNECT'
};

export type KEYCLOAK_CONNECT_SUCCESS_ACTION = {
  type: 'KEYCLOAK_CONNECT_SUCCESS',
  payload: {
    keycloakData: any
  }
};

export type KEYCLOAK_CONNECT_FAILURE_ACTION = {
  type: 'KEYCLOAK_CONNECT_FAILURE',
  payload: {
    error: string
  }
};

export type Action =
  | FETCH_USER_PREF_START_ACTION
  | FETCH_USER_PREF_SUCCESS_ACTION
  | FETCH_USER_PREF_FAILURE_ACTION
  | POST_USER_PREF_START_ACTION
  | POST_USER_PREF_SUCCESS_ACTION
  | POST_USER_PREF_FAILURE_ACTION
  | FETCH_USER_FID_START_ACTION
  | FETCH_USER_FID_SUCCESS_ACTION
  | FETCH_USER_FID_FAILURE_ACTION
  | POST_USER_FID_START_ACTION
  | POST_USER_FID_SUCCESS_ACTION
  | POST_USER_FID_FAILURE_ACTION
  | FETCH_USER_FAVS_START_ACTION
  | FETCH_USER_FAVS_SUCCESS_ACTION
  | FETCH_USER_FAVS_FAILURE_ACTION
  | KEYCLOAK_CONNECT_ACTION
  | KEYCLOAK_CONNECT_SUCCESS_ACTION
  | KEYCLOAK_CONNECT_FAILURE_ACTION;

export const fetchUserPref = (full: boolean, token: string): FETCH_USER_PREF_START_ACTION => ({
  type: FETCH_USER_PREF_START,
  payload: {
    full,
    token
  }
});

export const userPrefFetched = (userState: FullUserType): FETCH_USER_PREF_SUCCESS_ACTION => {
  return {
    type: FETCH_USER_PREF_SUCCESS,
    payload: {
      userPref: userState
    }
  };
};

export const userPrefFetchError = (error: string): FETCH_USER_PREF_FAILURE_ACTION => ({
  type: FETCH_USER_PREF_FAILURE,
  payload: {
    error
  }
});

export const postUserPref = (
  action: string,
  value: EditProfileValueType,
  token: string,
  redirect?: string
): POST_USER_PREF_START_ACTION => {
  return {
    type: POST_USER_PREF_START,
    payload: {
      action,
      value,
      token,
      redirect
    }
  };
};

export const postUserPrefSuccess = (response: FullUserType): POST_USER_PREF_SUCCESS_ACTION => {
  return {
    type: POST_USER_PREF_SUCCESS,
    payload: {
      response
    }
  };
};

export const postUserPrefFailure = (error: string): POST_USER_PREF_FAILURE_ACTION => ({
  type: POST_USER_PREF_FAILURE,
  payload: {
    error
  }
});

export const fetchUserFid = (token: string): FETCH_USER_FID_START_ACTION => ({
  type: FETCH_USER_FID_START,
  payload: {
    token
  }
});

export const userFidFetched = (userFid: UserFidType): FETCH_USER_FID_SUCCESS_ACTION => {
  return {
    type: FETCH_USER_FID_SUCCESS,
    payload: {
      userFid: userFid
    }
  };
};

export const userFidFetchError = (error: string): FETCH_USER_FID_FAILURE_ACTION => ({
  type: FETCH_USER_FID_FAILURE,
  payload: {
    error
  }
});

export const postUserFid = (
  action: string,
  value: EditProfileValueType,
  token: string,
  redirect?: string
): POST_USER_FID_START_ACTION => {
  return {
    type: POST_USER_FID_START,
    payload: {
      action,
      value,
      token,
      redirect
    }
  };
};

export const postUserFidSuccess = (response: UserFidType): POST_USER_FID_SUCCESS_ACTION => {
  return {
    type: POST_USER_FID_SUCCESS,
    payload: {
      response
    }
  };
};

export const postUserFidFailure = (error: string): POST_USER_FID_FAILURE_ACTION => ({
  type: POST_USER_FID_FAILURE,
  payload: {
    error
  }
});

export const fetchUserFavs = (token: string): FETCH_USER_FAVS_START_ACTION => ({
  type: FETCH_USER_FAVS_START,
  payload: {
    token
  }
});

export const userFavsFetched = (userFavsState: UserFavsType): FETCH_USER_FAVS_SUCCESS_ACTION => {
  return {
    type: FETCH_USER_FAVS_SUCCESS,
    payload: {
      userFavs: userFavsState
    }
  };
};

export const userFavsFetchError = (error: string): FETCH_USER_FAVS_FAILURE_ACTION => ({
  type: FETCH_USER_FAVS_FAILURE,
  payload: {
    error
  }
});

export const keycloakConnect = (): KEYCLOAK_CONNECT_ACTION => ({
  type: KEYCLOAK_CONNECT
});

export const keycloakConnected = (keycloakData: any): KEYCLOAK_CONNECT_SUCCESS_ACTION => {
  return {
    type: KEYCLOAK_CONNECT_SUCCESS,
    payload: {
      keycloakData: keycloakData
    }
  };
};

export const keycloakConnectError = (error: string): KEYCLOAK_CONNECT_FAILURE_ACTION => ({
  type: KEYCLOAK_CONNECT_FAILURE,
  payload: {
    error
  }
});
