// @flow
import React from 'react';

import NewsletterOptinModal from 'containers/footer/NewsletterOptinModalContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function NewsletterOptinModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-input ">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__header">
        <h3 className="ft-h2 mb-2">
          Newsletter <br /> de la Fédération
        </h3>
      </div>
      <div className="modal__wrapper">
        <div className="modal__body modal__body--medium mb-3">
          <p className="ft-center mb-0">
            Vous souhaitez être informé des dernières actualités des équipes de France, mais aussi des grands projets du
            rugby en France ? Inscrivez-vous à la Newsletter FFR !
          </p>
        </div>
        <NewsletterOptinModal content={content} />
      </div>
    </div>
  );
}

export default NewsletterOptinModalContent;
