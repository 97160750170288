// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertYoutubePostsFromApi } from 'services/Youtube';

function YoutubeApi(executor: ApiExecutorType) {
  return { fetchPosts };

  function fetchPosts() {
    return executor.get(`rs_youtube.json`).then(responses => {
      return responses.map(resp => convertYoutubePostsFromApi(resp));
    });
  }
}

export default YoutubeApi;
