// @flow
import TopBarUserConnection from 'components/header/TopBarUserConnection';
import { connect } from 'react-redux';
import type { StateType } from 'types/Actions';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect((state: StateType, ...ownProps: any) => ({
    connectionInProgress: state.userState.connectionInProgress,
    keycloakData: state.userState.keycloakData,
    userFid: state.userState.userFid,
    userPref: state.userState.userPref,
    login_url: state.settingsState.login_url,
    logout_url: state.settingsState.logout_url,
    fid_page: state.settingsState.fid_page
  }))(TopBarUserConnection)
);
