// @flow
import { connect } from 'react-redux';

import ModalFormSeminaire from 'components/entreprise/seminaire/ModalFormSeminaire';
import { postContactForm } from 'actions/formActions';

import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/entreprise/seminaire/ModalFormSeminaire';

export default connect(
  (state: StateType): StateProps => ({
    contactFormList: state.formState.contactFormList,
    fetchStatus: state.formState.fetchStatus,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    onSubmit: (formInputs: Object, id: number) => dispatch(postContactForm(formInputs, id)),
  })
)(ModalFormSeminaire);
