// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertMultipleVideoFromApi, convertVideoIdFromApiPlaylistItems } from 'services/Tv';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';

function VideosPlaylistApi(executor: ApiExecutorType) {
  return { fetchYoutubeVideoFromIds, fetchPlaylistItems };

  function fetchPlaylistItems(idPlaylist: string, maxResults: number = 50, nextPageTokenVideos?: string) {
    let request = `playlistItems?part=snippet&key=${YOUTUBE_ACCESS_TOKEN}&playlistId=${idPlaylist}&maxResults=${maxResults}`;
    if (nextPageTokenVideos) request = request.concat(`&pageToken=${nextPageTokenVideos}`);
    return executor.get(request).then(convertVideoIdFromApiPlaylistItems);
  }

  function fetchYoutubeVideoFromIds(videoIds: Array<string>) {
    return executor
      .get(`videos?key=${YOUTUBE_ACCESS_TOKEN}&part=contentDetails,snippet&id=${videoIds.join()}`)
      .then(convertMultipleVideoFromApi);
  }
}

export default VideosPlaylistApi;
