// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import FidApi from 'api/fid/FidApi';
import {
  FETCH_FID_MODALES_START,
  fidModalesFetched,
  fidModalesFetchError,
  FETCH_FID_OFFRES_START,
  fidOffresFetched,
  fidOffresFetchError
} from 'actions/fidActions';
import type { FETCH_FID_MODALES_START_ACTION, FETCH_FID_OFFRES_START_ACTION } from 'actions/fidActions';

export default function(apiExecutor: ApiExecutorType) {
  const fidApi = new FidApi(apiExecutor);

  return function* seasonsSaga(): GeneratorType {
    yield takeLatest(FETCH_FID_MODALES_START, fetchFidModales);
    yield takeLatest(FETCH_FID_OFFRES_START, fetchFidOffres);
  };

  function* fetchFidModales(action: FETCH_FID_MODALES_START_ACTION): Saga<void> {
    try {
      const fid_modales_data = yield call(fidApi.fetchFidModales);
      yield put(fidModalesFetched(fid_modales_data));
    } catch (error) {
      yield put(fidModalesFetchError(error));
    }
  }

  function* fetchFidOffres(action: FETCH_FID_OFFRES_START_ACTION): Saga<void> {
    try {
      const fid_offres = yield call(fidApi.fetchFidOffres);
      yield put(fidOffresFetched(fid_offres));
    } catch (error) {
      yield put(fidOffresFetchError(error));
    }
  }
}
