//@flow
import {
  FETCH_USER_PREF_START,
  FETCH_USER_PREF_SUCCESS,
  FETCH_USER_PREF_FAILURE,
  POST_USER_PREF_START,
  POST_USER_PREF_SUCCESS,
  POST_USER_PREF_FAILURE,
  FETCH_USER_FID_START,
  FETCH_USER_FID_SUCCESS,
  FETCH_USER_FID_FAILURE,
  POST_USER_FID_START,
  POST_USER_FID_SUCCESS,
  POST_USER_FID_FAILURE,
  FETCH_USER_FAVS_START,
  FETCH_USER_FAVS_SUCCESS,
  FETCH_USER_FAVS_FAILURE,
  KEYCLOAK_CONNECT,
  KEYCLOAK_CONNECT_SUCCESS,
  KEYCLOAK_CONNECT_FAILURE
} from 'actions/userActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';

import type { FullUserType, UserFavsType, UserFidType } from 'types/User';
import type { Status } from 'types/Status';
import type { Action } from 'actions/userActions';

import { nullUserFavs, nullUserFid, nullUserPrefs } from 'types/User';

export type State = {
  error: string,
  status: Status,
  userFavs: UserFavsType,
  userFavs_status: Status,
  userFid: UserFidType,
  userFidError: string,
  userFidStatus: Status,
  userFidPostStatus: Status,
  userFidPostError: string,
  userPref: FullUserType,
  keycloakData?: Object,
  connectionInProgress: boolean
};

const initialState: State = {
  error: '',
  status: STATUS_LOADING,
  userFavs: nullUserFavs,
  userFavs_status: STATUS_LOADING,
  userFid: nullUserFid,
  userFidError: '',
  userFidStatus: STATUS_LOADING,
  userFidPostError: '',
  userFidPostStatus: STATUS_LOADING,
  userPref: nullUserPrefs,
  connectionInProgress: false,
  keycloakData: {}
};

export default function(state: State = initialState, action: Action): State {
  switch (action.type) {
    case KEYCLOAK_CONNECT_SUCCESS:
      return {
        ...state,
        connectionInProgress: false,
        keycloakData: {
          ...action.payload.keycloakData
        }
      };
    case KEYCLOAK_CONNECT_FAILURE:
      return {
        ...state,
        connectionInProgress: false
      };
    case KEYCLOAK_CONNECT:
      return {
        ...state,
        connectionInProgress: true
      };
    case FETCH_USER_PREF_START:
      return {
        ...state,
        status: STATUS_LOADING,
        connectionInProgress: true
      };
    case FETCH_USER_PREF_SUCCESS:
      return {
        ...state,
        userPref: action.payload.userPref,
        status: STATUS_SUCCESS,
        connectionInProgress: false
      };
    case FETCH_USER_PREF_FAILURE:
      return {
        ...state,
        userPref: nullUserPrefs,
        error: action.payload.error,
        status: STATUS_FAILURE,
        connectionInProgress: false
      };
    case POST_USER_PREF_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case POST_USER_PREF_SUCCESS:
      return {
        ...state,
        userPref: { ...state.userPref, ...action.payload.response },
        status: STATUS_SUCCESS
      };
    case POST_USER_PREF_FAILURE:
      return {
        ...state,
        userPref: nullUserPrefs,
        error: action.payload.error,
        status: STATUS_SUCCESS
      };
    case FETCH_USER_FID_START:
      return {
        ...state,
        userFidError: '',
        userFidStatus: STATUS_LOADING
      };
    case FETCH_USER_FID_SUCCESS:
      return {
        ...state,
        userFid: action.payload.userFid,
        userFidStatus: STATUS_SUCCESS
      };
    case FETCH_USER_FID_FAILURE:
      return {
        ...state,
        userFid: nullUserFid,
        userFidError: action.payload.error,
        userFidStatus: STATUS_FAILURE
      };
    case POST_USER_FID_START:
      return {
        ...state,
        userFidPostError: '',
        userFidPostStatus: STATUS_LOADING
      };
    case POST_USER_FID_SUCCESS:
      return {
        ...state,
        userFid: { ...state.userFid, ...action.payload.response },
        userFidPostStatus: STATUS_SUCCESS
      };
    case POST_USER_FID_FAILURE:
      return {
        ...state,
        userFid: nullUserFid,
        userFirPostError: action.payload.error,
        userFidPostStatus: STATUS_SUCCESS
      };
    case FETCH_USER_FAVS_START:
      return {
        ...state,
        userFavs_status: STATUS_LOADING
      };
    case FETCH_USER_FAVS_SUCCESS:
      return {
        ...state,
        userFavs: action.payload.userFavs,
        userFavs_status: STATUS_SUCCESS
      };
    case FETCH_USER_FAVS_FAILURE:
      return {
        ...state,
        userFavs: nullUserFavs,
        error: action.payload.error,
        userFavs_status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
