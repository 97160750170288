// @flow

import React from 'react';

import './CookieButton.css';

export type StateProps = {
    acceptCookie: boolean,
};

export type DispatchProps = {
    setCookieInfo(accept: boolean): void
}

type Props = StateProps & DispatchProps & { className?: string };

export default function CookieButton({ acceptCookie, className, setCookieInfo }: Props) {
    const toggle = () => setCookieInfo(!acceptCookie);

    let cn = "accept-cookie-container";
    if (className) {
        cn += ` ${className}`;
    }

    return (
        <div className={cn}>
            <p>
                Accepter les cookies
            </p>

            {/* from https://www.w3schools.com/howto/howto_css_switch.asp */}
            <label className="switch">
                <input type="checkbox" onChange={toggle} checked={acceptCookie} />
                <span className="slider round" />
            </label>
        </div>
    );
}