// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import PlaylistApi from 'api/ffrtv/PlaylistApi';

import {
  FETCH_HOME_YOUTUBE_PLAYLISTS_START,
  playlistsFetched,
  playlistFetchError,
  type FETCH_HOME_YOUTUBE_PLAYLISTS_START_ACTION
} from 'actions/ffr-tv/homeYoutubePlaylistsActions';

export default function(apiExecutor: ApiExecutorType) {
  const playlistApi = new PlaylistApi(apiExecutor);
  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_HOME_YOUTUBE_PLAYLISTS_START, homeFetchYoutubePlaylists);
  };

  function* homeFetchYoutubePlaylists(action: FETCH_HOME_YOUTUBE_PLAYLISTS_START_ACTION): Saga<void> {
    const {
      payload: { tabIdPlaylists }
    } = action;
    try {
      const playlists = yield call(playlistApi.fetchPlaylists, tabIdPlaylists);
      yield put(playlistsFetched(playlists));
    } catch (e) {
      yield put(playlistFetchError());
    }
  }
}
