// @flow
import type { FidModalesType, FidOffresType } from 'types/Fid';
import { nullFidModales, nullFidOffres } from 'types/Fid';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertFidModalesFromApi(rawFidModales: FidModalesType): FidModalesType {
  if (!rawFidModales) {
    return nullFidModales;
  }

  return mergeIntoDefault(nullFidModales, {
    ...rawFidModales
  });
}

export function convertFidOffresFromApi(rawFidOffres: FidOffresType): FidOffresType {
  if (!rawFidOffres) {
    return nullFidOffres;
  }

  return mergeIntoDefault(nullFidOffres, {
    ...rawFidOffres
  });
}
