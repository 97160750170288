import { RESET_SEASON, FETCH_SEASON_START, FETCH_SEASON_SUCCESS, FETCH_SEASON_FAILURE } from 'actions/seasonsActions';

const initialState = {
  seasons : [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_SEASON:
      return initialState;
    case FETCH_SEASON_START:
      return { ...state };
    case FETCH_SEASON_SUCCESS:
      return {
        ...state,
        seasons: action.payload.seasons,
      };
    case FETCH_SEASON_FAILURE:
      return { ...state };
  default:
    return state;
  }
};
