// @flow
import React from 'react';

import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { getCasEndpoint, getCasRegister } from 'constants/sso';

export type StateProps = {
  userPref: FullUserType
};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

function NewsletterSuccessModalContent({ content, hideModal, userPref }: Props) {
  return (
    <div className="modal-content box newsletter-valid ">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <div className="modal__body modal__body--medium narrow-2">
          <div className="circle-icon circle-icon--blue"></div>
          <h3 className="mb-1 mt-2 ft-h2">Inscription validée</h3>
          <p className="mb-1 ft-center">
            Nous avons bien pris en compte votre inscription à la newsletter de la Fédération.
          </p>
          <p className="mb-5 ft-center">
            Pour modifier vos centre d’intérêts ou vous désinscrire, créez votre espace perso ou connectez-vous.
          </p>
        </div>
        <ul className="list list--check mb-5">
          <li>
            <span>{`Un espace unique et personnalisé`}</span>
          </li>
          <li>
            <span>{`La gestion de vos centres d'intérêts`}</span>
          </li>
          <li>
            <span>{`Pour les licenciés : vos statistiques de jeu rassemblées`}</span>
          </li>
        </ul>
        <a
          className="btn btn--primary btn--full js-confirm mb-2"
          href={getCasRegister('/tableau-de-bord')}
          onClick={handleTagEvent('pop_in_ajout_joueur', 'clic_creation_espace_perso', '', userPref)}
        >
          Créer mon espace perso (1 min)
        </a>
        <p className="modal__footer-option">
          {`J'ai déjà un compte ! `}
          <a
            className="footer-option__link"
            href={getCasEndpoint(
              content && content.login_url ? content.login_url : 'https://api.www.ffr.fr/keycloak_login',
              '/tableau-de-bord'
            )}
            onClick={handleTagEvent('connexion', 'Mail', `pop-in inscription newsletter`, userPref)}
          >
            Me connecter
          </a>
        </p>
      </div>
    </div>
  );
}

export default NewsletterSuccessModalContent;
