// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import BackgroundImage from 'components/fragments/BackgroundImage';
import Label from 'components/fragments/text/Label';
import type { TagListType } from 'types/Article';
import type { FullUserType } from 'types/User';
import { formatFullDate, getDateFromString, formatMinutes } from 'utils/dateUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

type Props = {
  img: string,
  title: string,
  date: Date | string,
  description: string,
  url: string,
  srcset: Object,
  userPref?: FullUserType,
  tags: TagListType[],
  excerpt: string,
  readingTime: number,
  hasMetaData?: boolean
};

const NewsListElement = (props: Props) => {
  const { userPref } = props;
  const date = getDateFromString(props.date);

  return (
    <Link
      to={props.url}
      className="news news--list "
      onClick={handleTagEvent('module_actualite', props.title, '', userPref)}
    >
      <article className="news-list-element">
        <div className="news-list-element__top">
          <BackgroundImage
            className="news__thumbnail img-blue"
            illustration={props.img}
            srcset={props.srcset}
            forceState={'medium'}
          />
          <p className="news__title ft-h3">{props.title}</p>
          <p className="ft-h6 news__date">
            {`${formatFullDate(date)}`}
            {props.hasMetaData && props.readingTime > 0 ? (
              <span className="news_temps__lecuture">{` - Temps de lecture : ${formatMinutes(
                new Date(props.readingTime)
              )}`}</span>
            ) : null}
          </p>
          {props.hasMetaData && (props.excerpt || props.description) && (
            <p className="news__desc">{props.excerpt !== '' ? props.excerpt : props.description}</p>
          )}
        </div>

        <div className="news-list-element__bottom">
          {props.tags && !!props.tags.length && (
            <div className="mb-1 mt-md-2">
              {props.tags.map(tag => (
                <Label key={tag.id} label={tag.name} />
              ))}
            </div>
          )}
        </div>
      </article>
    </Link>
  );
};

export default NewsListElement;
