// @flow
import type {
  RawCentenaireDocumentType,
  RawCentenaireClubPreviewType,
  CentenaireDocumentType,
  CentenaireClubPreviewType
} from 'types/Centenaire';
import { nullCentenaireDocument, nullCentenaireClubPreview } from 'types/Centenaire';
import { mergeIntoDefault } from 'utils/objectUtils';
import { get_club_from_tags, get_equipe_from_tags, get_niveau_from_tags } from 'utils/centenaireUtils';

export function convertCentenaireClubPreviewFromApi(
  rawCentenaireClubPreview: RawCentenaireClubPreviewType): CentenaireClubPreviewType {
  if (!rawCentenaireClubPreview) {
    return nullCentenaireClubPreview;
  }

  const {
    title,
    latitude,
    longitude,
    embleme,
    ville,
    nb_documents
  } = rawCentenaireClubPreview;

  return mergeIntoDefault(nullCentenaireClubPreview, {
    img: embleme ? embleme : '/img/logo/placeholder-club.png',
    name: title,
    searchName: title,
    long: longitude,
    lat: latitude,
    adress: {
      locality: ville,
    },
    nb_documents
  });
}

export function convertFormCentenaireResponseFromApi(
  response: Object
) {
  return {
    response: response,
  };
}
export function convertCentenaireDocumentFromApi(
  rawCentenaireDocument: RawCentenaireDocumentType
  ): CentenaireDocumentType {
  if (!rawCentenaireDocument) {
    return nullCentenaireDocument;
  }
  const {
    id,
    name,
    description,
    copyright,
    tags,
    datePublished,
    dateCreated,
    thumbnails,
    videoPreviewURLs,
    type,
  } = rawCentenaireDocument;
  const club = tags && tags.length > 0 && get_club_from_tags(tags);
  const equipe = tags && tags.length > 0 && get_equipe_from_tags(tags);
  const niveau = tags && tags.length > 0 && get_niveau_from_tags(tags);
  /**
   * La taille maximale pour un fichier visible dans l'interface (imgPreview) est :
   * - 550px de haut
   * - 1170px de large
   * - On utilise une résolution "high"
   * 
   * La taille maximage d'une thumbnail visible dans une card (thumbnail) est :
   * - 340 px de large
   * - On met 550px de haut pour éviter d'aller trop loin
   * - On utilise une résolution "medium"
   */
  return mergeIntoDefault(nullCentenaireDocument, {
    id: id,
    img: thumbnails.webimage,
    imgPreview: thumbnails.webimage,
    download: thumbnails.webimage,
    video: (type === 'video' && videoPreviewURLs.length > 0) ? 
      videoPreviewURLs[0] : 
      '',
    title: name,
    description: description,
    author: copyright,
    thumbnail: thumbnails.webimage,
    date: dateCreated,
    club: club && club[0] ? club[0] : '',
    equipe: equipe && equipe[0] ? equipe[0] : 'Équipe indéterminée',
    niveau: niveau && niveau[0] ? niveau[0] : 'Niveau indeterminé',
    year: datePublished.substring(0, 4),
    type,
    copyright: copyright,
  });
}