// @flow
import { connect } from 'react-redux';

import CardPlayer from 'components/fragments/card/CardPlayer';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/card/CardPlayer';

export default connect((state: StateType): StateProps => ({
  userPref: state.userState.userPref
}))(CardPlayer);
