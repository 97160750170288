// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import SeminaireApi from 'api/seminaire/SeminaireApi';
import {
  FETCH_SEMINAIRE_START,
  seminaireFetched,
  seminaireFetchError,
} from 'actions/seminaireActions';

import type { FETCH_SEMINAIRE_START_ACTION } from 'actions/seminaireActions';

export default function(apiExecutor: ApiExecutorType) {
  const seminaireApi = new SeminaireApi(apiExecutor);

  return function* seminaireSaga(): GeneratorType {
    yield takeLatest(FETCH_SEMINAIRE_START, fetchSeminaire);
  };

  function* fetchSeminaire(action: FETCH_SEMINAIRE_START_ACTION): Saga<void> {
    try {
      const seminaires = yield call(seminaireApi.getSeminaires);
      yield put(seminaireFetched(seminaires));
    } catch (e) {
      yield put(seminaireFetchError());
    }
  }
}
