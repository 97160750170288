// @flow
import type { DirectusBlocksType } from 'types/Directus';

export type FidOffresType = {
  fid_offres: OfferType[],
  fid_offres_error: string
};

export type OfferType = {
  color: string,
  conditions?: Object,
  date_created: string,
  date_updated: string,
  external_identifier?: string,
  id_offre: number,
  identifiant: string,
  logo?: string,
  points: string,
  reward_type: string,
  seuil: string,
  source: number,
  status: string,
  texte?: Object,
  instructions?: Object,
  titre: string,
  type: number,
  type_offre: {
    id_offre_type: number,
    user_created: string,
    user_updated: string,
    date_created: string,
    date_updated: string,
    color: string,
    icon?: string,
    priority: string,
    title: string
  },
  vouchers_limited: Boolean,
  vouchers_list?: string
};

export const nullFidOffres = {
  fid_offres: [],
  fid_offres_error: ''
};

type ModalType = {
  id: number,
  content_title: string,
  name: string,
  content_description_1: DirectusBlocksType,
  content_description_2: DirectusBlocksType,
  logo: string,
  has_button_one: boolean | null,
  has_button_two: boolean | null,
  cta_button_one: string | null,
  cta_button_two: string | null
};
export type FidModalesType = {
  fid_modales: ModalType[],
  fid_modales_error: string
};

export const nullFidModales = {
  fid_modales: [],
  fid_modales_error: ''
};
