// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
  FETCH_PRIVATE_ACCOUNT_DASHBOARD_START,
  privateAccountDashboardFetched,
  privateAccountDashboardFetchError
} from 'actions/privateAccountDashboardActions';
import type { FETCH_PRIVATE_ACCOUNT_DASHBOARD_START_ACTION } from 'actions/privateAccountDashboardActions';
import PrivateAccountDashboardApi from 'api/privateAccountDashboard/PrivateAccountDashboardApi';
import type { GeneratorType } from 'sagas/root';
import { convertPrivateAccountDashboardFromApi } from 'services/PrivateAccountDashboard';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function(apiExecutor: ApiExecutorType) {
  const privateAccountDashboardApi = new PrivateAccountDashboardApi(apiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_PRIVATE_ACCOUNT_DASHBOARD_START, fetchPrivateAccountDashboard);
  };

  function* fetchPrivateAccountDashboard(action: FETCH_PRIVATE_ACCOUNT_DASHBOARD_START_ACTION): Saga<void> {
    try {
      const dashboard = yield call(privateAccountDashboardApi.fetchPrivateAccountDashboard);

      yield put(privateAccountDashboardFetched(convertPrivateAccountDashboardFromApi(dashboard)));
    } catch (e) {
      yield put(privateAccountDashboardFetchError(e));
    }
  }
}
