// @flow
import React from 'react';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  isWhite?: boolean,
  isBlue?: boolean,
  isDarkBlue?: boolean,
  backgroundColor?: string,
  style?: Object,
};

const Loading = (props?: Props) => {
  let link;
  const style = {
    height:'500px',
    display:'flex',
    backgroundColor: (props && props.backgroundColor) ? props.backgroundColor : 'transparent'
  };
  if (props && props.isWhite) {
    link = '/img/FFR-Loader-White_2019.gif';
  } else if (props && props.isBlue) {
    link = '/img/FFR-Loader-Blue_2019.gif';
  } else if (props && props.isDarkBlue) {
    link = '/img/FFR-Loader-Dark-Blue_2019.gif';
  } else {
    link = '/img/FFR-Loader-LightBlue_2019.gif';
  }

  return (
    <div style={props && props.style ? props.style : style}>
      <img src={getPublicAssets(link)} alt={'Chargement ...'} style={{margin:'auto'}} />
    </div>
  );
};

export default Loading;
