// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START,
  MostPopularVideosChannelFetched,
  MostPopularVideosChannelFetchError,
  type FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START_ACTION
} from 'actions/ffr-tv/youtubeMostPopularVideosChannelActions';

import MostPopularVideosChannelApi from 'api/ffrtv/MostPopularVideosChannelApi';

export default function(apiExecutor: ApiExecutorType) {
  const mostPopularVideosChannelApi = new MostPopularVideosChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START, fetchMostPopularYoutubeVideoChannel);
  };

  function* fetchMostPopularYoutubeVideoChannel(
    action: FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START_ACTION
  ): Saga<void> {
    try {
      const {
        payload: { idChannel }
      } = action;

      const rawYoutubeSearch = yield call(
        mostPopularVideosChannelApi.fetchSearchMostPopularVideosChannel,
        idChannel,
        15
      );
      const listvideoId = rawYoutubeSearch.items.map(item => item.id.videoId);
      const videoTab = yield call(mostPopularVideosChannelApi.fetchYoutubeVideoFromIds, listvideoId);
      yield put(MostPopularVideosChannelFetched(videoTab));
    } catch (e) {
      yield put(MostPopularVideosChannelFetchError());
    }
  }
}
