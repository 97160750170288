
// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import SearchApi from 'api/search/SearchApi';

import {
  FETCH_SEARCH_START,
  searchFetched,
  searchFetchError,
} from 'actions/searchActions';

export default function(apiExecutor: ApiExecutorType) {
  const searchApi = new SearchApi(apiExecutor);

  return function* newsletterSaga(): GeneratorType {
    yield takeLatest(FETCH_SEARCH_START, fetchSearch);
  };

  function* fetchSearch(action): Saga<void> {
    try {
      const { input } = action.payload;
      const articles = yield call(searchApi.fetchArticlesByPage, 1, 200, input);
      const pages = yield call(searchApi.fetchPages, input);
      const events = yield call(searchApi.fetchEventsPreview, input);
      const questions = yield call(searchApi.fetchQuestions, input);
      const joueurs = yield call(searchApi.fetchJoueurs, input);

      yield put(searchFetched({
        articles,
        events,
        questions,
        joueurs,
        pages,
      }));
    } catch (e) {
      yield put(searchFetchError(e));
    }
  }
}
