// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_VIDEO_PLAYER_START,
  FETCH_VIDEO_PLAYER_SUCCESS,
  FETCH_VIDEO_PLAYER_FAILURE
} from 'actions/ffr-tv/youtubeVideoPlayerActions';

import { nullYoutubeVideoPlayer, type YoutubeVideoPlayer, type YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  video: YoutubeVideoPlayer,
  suggestedVideos: Array<YoutubeVideo>,
  status: Status
};

const initialState: State = {
  video: nullYoutubeVideoPlayer,
  suggestedVideos: [],
  status: STATUS_DEFAULT,
  error: {}
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_VIDEO_PLAYER_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_VIDEO_PLAYER_SUCCESS:
      return {
        ...state,
        video: action.payload.video,
        suggestedVideos: action.payload.suggestedVideos,
        status: STATUS_SUCCESS
      };
    case FETCH_VIDEO_PLAYER_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
