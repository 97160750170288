// @flow

import { connect } from 'react-redux';

import NewsletterInterestsList from 'components/newslettermodal/NewsletterInterestsList';
import type { StateProps, DispatchProps } from 'components/newslettermodal/NewsletterInterestsList';
import type { StateType, DispatchType } from 'types/Actions';
import type { NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';
import { subscribeNewsletter } from 'actions/newsletterSubscriptionActions';

export default connect(
  (state: StateType): StateProps => ({
    categories: state.categoriesState.categories,
    userPref: state.userState.userPref,
    error: state.userState.error,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    subscribeNewsletter: (
      subscription: NewsletterSubscriptionEntryType,
      campaignMonitorKey: string,
      typeNewsletter: string | boolean,
      categories?: Array<string>
      ) => categories ? dispatch(subscribeNewsletter(subscription, campaignMonitorKey, typeNewsletter, categories)) : 
      dispatch(subscribeNewsletter(subscription, campaignMonitorKey, typeNewsletter)),
  }),
)(NewsletterInterestsList);