// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_NEW_VIDEOS_START,
  newVideosFetched,
  newVideosFetchError,
  type FETCH_NEW_VIDEOS_START_ACTION
} from 'actions/ffr-tv/newVideosActions';

import VideosChannelApi from 'api/ffrtv/VideosChannelApi';

export default function(apiExecutor: ApiExecutorType) {
  const videosChannelApi = new VideosChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_NEW_VIDEOS_START, fetchNewYoutubeVideos);
  };

  function* fetchNewYoutubeVideos(action: FETCH_NEW_VIDEOS_START_ACTION): Saga<void> {
    try {
      const {
        payload: { idChannel }
      } = action;
      const rawYoutubeChannel = yield call(videosChannelApi.fetchChannel, idChannel);
      const idChannelUploads = rawYoutubeChannel.items[0].contentDetails.relatedPlaylists.uploads;
      const rawYoutubePlaylistItems = yield call(videosChannelApi.fetchPlaylistItems, idChannelUploads, 50);
      const listvideos = yield call(
        videosChannelApi.fetchYoutubeVideoFromIds,
        rawYoutubePlaylistItems.youtubePlaylistItem.map(item => item.id)
      );
      yield put(newVideosFetched(listvideos));
    } catch (e) {
      yield put(newVideosFetchError());
    }
  }
}
