// @flow
import type { BlocContent } from 'types/NewsletterBloc';

export type EventType = {
  id: number,
  startDate: Date,
  endDate: Date,
  startDateDetail: Date,
  endDateDetail: Date,
  title: string,
  label: string,
  categoryId: number,
  categories: Array<any>,
  tag: string,
  team: string,
  slug: string,
  image: string,
  description: string,
  venue: string,
  adress: string,
  zip: string,
  city: string,
  organizer: string,
  email: string,
  phone: string,
  lat: number,
  long: number,
  all_day: boolean,
  sidebar: Array<BlocContent>,
};

export type EventPreviewType = {
  slug: string,
  startDate: Date,
  endDate: Date,
  title: string,
  team: string,
  categories: Array<any>,
  label: string,
  categoryId: number,
  tag: string,
  venue: string,
  adress: string,
  zip: string,
  city: string,
  sidebar: Array<BlocContent>,
};

export type EventMatchType = {
  id: number,
  startDate: Date,
  endDate: Date,
  billetterie: string,
  title: string,
  label: string,
  all_day: boolean,
  team: string,
  categoryId: number,
  tag: string,
  venue: string,
  adress: string,
  zip: string,
  city: string,
  reception: boolean,
  link: string,
  adversaire: {
    name: string,
    logo: string
  },
  france: {
    logo: string
  }
};

export type RawEventMatchType = {
  meta: {
    date_rencontre: string,
    lieu: string,
    france_logo: string,
    billetterie: string,
    adversaire_logo: string,
    adversaire: string,
    equipeId: number,
    equipeNom: string,
    reception: boolean,
  },
  id: number,
  title: {
    rendered: string
  },
  competition: {
    name: string
  },
  link: string,
};

export const nullEventMatch: EventMatchType = {
  id: 0,
  startDate: new Date(),
  endDate: new Date(),
  title: '',
  label: '',
  categoryId: 0,
  tag: '',
  team: '',
  venue: '',
  billetterie: '',
  all_day: false,
  adress: '',
  zip: '',
  city: '',
  reception: false,
  link: '',
  adversaire: {
    name: '',
    logo: ''
  },
  france: {
    logo: ''
  }
};

export type RawEventType = {
  id: number,
  global_id: string,
  global_id_lineage: Array<string>,
  author: string,
  status: string,
  date: string,
  equipe: {
    name: string,
  },
  date_utc: string,
  modified: string,
  modified_utc: string,
  url: string,
  rest_url: string,
  title: {
    rendered: string,
    raw: string,
    data: Array<{
      type: string,
      text: string
    }>
  },
  description: {
    rendered: string,
    raw: string,
    data: Array<{
      tagName: string,
      attributes: [],
      children: Array<{
        type: string,
        text: string
      }>
    }>
  },
  excerpt: string,
  slug: string,
  image: {
    url: string,
    id: string,
    extension: string,
    width: number,
    height: number,
    sizes: {
      thumbnail: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      },
      medium: {
        width: number,
        height: number,
        'mime-type': string,
        url: string
      }
    }
  },
  all_day: boolean,
  start_date: string,
  start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  end_date: string,
  end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_start_date: string,
  utc_start_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  utc_end_date: string,
  utc_end_date_details: {
    year: string,
    month: string,
    day: string,
    hour: string,
    minutes: string,
    seconds: string
  },
  timezone: string,
  timezone_abbr: string,
  cost: string,
  cost_details: {
    currency_symbol: string,
    currency_position: string,
    values: Array<any>
  },
  website: string,
  show_map: boolean,
  show_map_link: boolean,
  hide_from_listings: boolean,
  sticky: boolean,
  featured: boolean,
  categories: Array<any>,
  tags: [
    {
      name: string,
      slug: string,
      term_group: number,
      term_taxonomy_id: number,
      taxonomy: string,
      description: string,
      parent: number,
      count: number,
      filter: string,
      id: number,
      urls: {
        self: string,
        collection: string
      }
    }
  ],
  venue: {
    id: number,
    author: string,
    status: string,
    date: string,
    date_utc: string,
    modified: string,
    modified_utc: string,
    url: string,
    venue: string,
    slug: string,
    address: string,
    city: string,
    country: string,
    zip: string,
    show_map: boolean,
    show_map_link: boolean,
    global_id: string,
    global_id_lineage: Array<string>,
    geo_lat: number,
    geo_lng: number
  },
  organizer: [
    {
      id: number,
      author: string,
      status: string,
      date: string,
      date_utc: string,
      modified: string,
      modified_utc: string,
      url: string,
      organizer: string,
      slug: string,
      website: string,
      global_id: string,
      global_id_lineage: Array<string>
    }
  ],
  sidebar_content: {
    data: Array<BlocContent>
  },
};

export type EventDetailDateType = {
  year: string,
  month: string,
  day: string,
  hour: string,
  minutes: string,
  seconds: string
};

export const nullEvent: EventType = {
  id: 0,
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: '',
  tag: '',
  categoryId: 0,
  slug: '',
  image: '',
  description: '',
  venue: '',
  team: '',
  adress: '',
  zip: '',
  city: '',
  organizer: '',
  email: '',
  phone: '',
  categories: [],
  lat: 0,
  long: 0,
  all_day: false,
  sidebar: []
};

export const nullEventPreview: EventPreviewType = {
  startDate: new Date(),
  endDate: new Date(),
  startDateDetail: new Date(),
  endDateDetail: new Date(),
  title: '',
  label: '',
  tag: '',
  team: '',
  categories: [],
  categoryId: 0,
  venue: '',
  adress: '',
  zip: '',
  city: '',
  slug: '',
  sidebar: [],
};
