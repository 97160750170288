// @flow
import type { PrivateAccountDashboardType } from 'types/PrivateAccountDashboardType';

export const FETCH_PRIVATE_ACCOUNT_DASHBOARD_START = 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_START';
export const FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE = 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE';
export const FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS = 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS';

export type FETCH_PRIVATE_ACCOUNT_DASHBOARD_START_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_START'
};

export type FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE'
};

export type FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS_ACTION = {
  type: 'FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS',
  payload: {
    dashboard: PrivateAccountDashboardType[]
  }
};

export type Action =
  | FETCH_PRIVATE_ACCOUNT_DASHBOARD_START_ACTION
  | FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE_ACTION
  | FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS_ACTION;

export function fetchPrivateAccountDashboard(): FETCH_PRIVATE_ACCOUNT_DASHBOARD_START_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_DASHBOARD_START
  };
}

export function privateAccountDashboardFetched(
  dashboard: PrivateAccountDashboardType[]
): FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS,
    payload: { dashboard }
  };
}

export function privateAccountDashboardFetchError(error: any): FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE_ACTION {
  return {
    type: FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE
  };
}
