// @flow
import React from 'react';

import logoCentenaire from 'assets/img/logo/centenaire_histoire.svg';
import ModalFormCentenaireEmail from 'containers/centenaire/ModalFormCentenaireEmailContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function CentenaireFormEmailModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box modal-centenaire modal-centenaire--with-bg modal-centenaire--intro ">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
      <div className="modal__header">
        <img src={logoCentenaire} alt="Centenaire FFR" />
        <h3 className="ft-h2 mb-1">{'Partager votre histoire'}</h3>
        <p>
          Bénévoles, joueurs, entraîneurs…
          <br />
          Passionnés, partagez vos souvenirs et ensemble, retraçons l’histoire du rugby.
        </p>
        <a href="https://youtu.be/mnHnODPOuaM" target="_blank" rel="noopener noreferrer">
          Comment faire ?
        </a>
      </div>
      <div className="modal__wrapper">
        <ModalFormCentenaireEmail />
      </div>
    </div>
  );
}

export default CentenaireFormEmailModalContent;
