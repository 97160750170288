// @flow

import PrivateRoute from 'components/app/PrivateRoute';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/app/PrivateRoute';

export default connect(
  (state: StateType): StateProps => ({
    identifie: state.userState.userPref.identifie,
    error: state.userState.error,
  }),
)(PrivateRoute);
