// @flow
import type { RawJoueurType, JoueurType, JoueurAgregateurType } from 'types/Joueur';
import { nullJoueur, nullJoueurAgregateur } from 'types/Joueur';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertJoueurFromApi(rawJoueur: RawJoueurType): JoueurType {
  if (!rawJoueur) {
    return nullJoueur;
  }

  const {
    title,
    content,
    slug,
    featured_media,
    sidebar_content,
    meta,
    link,
    meta_title,
    meta_description,
    poste,
    annee_premiere_selection,
    nombre_selections,
    nombre_transformations,
    nombre_penalites,
    nombre_essais,
    nombre_drops,
    matchs_joues,
    youtube_playlist,
    equipes,
    joueurs_favs_news,
    joueurs_tags_names,
    competitions_jouees,
    historique,
    id
  } = rawJoueur;

  return mergeIntoDefault(nullJoueur, {
    title: title.raw,
    logo: typeof featured_media === 'number' ? null : featured_media.src,
    featured_media: featured_media,
    content: content.data,
    dateNaissance: meta.dateNaissance,
    genre: meta.genre,
    numInternational: meta.numInternational,
    poids: meta.poids,
    taille: meta.taille,
    club_formateur: meta.clubFormateur,
    age: meta.age,
    nom: meta.nom,
    prenom: meta.prenom,
    poste: { id: poste.id, name: poste.name, ordre: poste.ordre },
    sidebar: sidebar_content ? sidebar_content.data : [],
    slug,
    link,
    annee_premiere_selection,
    nombre_selections,
    selections_origine: isNaN(parseInt(meta.selections_origine, 10)) ? 0 : parseInt(meta.selections_origine, 10),
    nombre_transformations,
    nombre_penalites,
    nombre_essais,
    nombre_drops,
    matchs_joues,
    meta_title,
    meta_description,
    equipes,
    joueurs_favs_news,
    joueurs_tags_names,
    competitions_jouees,
    historique,
    id,
    videos: youtube_playlist,
    allMatches: meta.match
  });
}

export function convertJoueurAgragateurFromGraph(rawJoueurAgregateur: JoueurAgregateurType): JoueurAgregateurType {
  if (!rawJoueurAgregateur) {
    return nullJoueurAgregateur;
  }

  return mergeIntoDefault(nullJoueurAgregateur, rawJoueurAgregateur);
}
