// @flow
import { connect } from 'react-redux';

import CentenaireCard from 'components/centenaire/CentenaireCard';
import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/centenaire/CentenaireCard';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  })
)(CentenaireCard);
