// @flow
import NewsletterMissingOptModalContent from 'components/fragments/modal/modalContents/NewsletterMissingOptModalContent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/modal/modalContents/NewsletterMissingOptModalContent';

export default connect((state: StateType): StateProps => ({
  titleLigue: state.settingsState.title
}))(NewsletterMissingOptModalContent);
