// @flow
import TopBar from 'components/header/TopBar';
import { connect } from 'react-redux';
import type { StateType } from 'types/Actions';
import { withRouter } from 'react-router-dom';

export default withRouter(
  connect((state: StateType, ...ownProps: any) => ({
    topBarElements: state.menuState.topBar,
    tickerElement: state.settingsState.actualite_topbar,
    userPref: state.userState.userPref,
    keycloakData: state.userState.keycloakData,
    current_competition: state.settingsState.current_competition
  }))(TopBar)
);
