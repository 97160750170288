// @flow
import React from 'react';

import { handleTagEvent } from 'utils/tagManagerUtils';
import type { FullUserType } from 'types/User';
import { getCasEndpoint, getCasRegister } from 'constants/sso';

export type StateProps = {
  login_url: string,
  userPref: FullUserType
};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

function AddFavoritePlayerModalContent({ content, hideModal, login_url, userPref }: Props) {
  if (!content) {
    return null;
  }

  return (
    <div className="modal-content box add-favorite-player modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-4">Ajout d’un joueur à vos favoris</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-5">
          <div className="circle-icon circle-icon--star" />
          <p className="mb-0">
            {'Pour suivre l’actualité de '}
            <strong>{content.title}</strong>
            {' et de centaines d’autres joueurs, créez votre espace perso FFR ou connectez-vous.'}
          </p>
        </div>
        <ul className="list list--check mb-3">
          <li>
            <span>Un espace unique et personnalisé</span>
          </li>
          <li>
            <span>La gestion de vos centres d’intérêts</span>
          </li>
          <li>
            <span>Pour les licenciés : vos statistiques de jeu rassemblées</span>
          </li>
        </ul>
        <a
          className="btn btn--primary btn--full js-confirm mb-2"
          href={getCasRegister('/tableau-de-bord')}
          onClick={handleTagEvent('pop_in_ajout_joueur', 'clic_creation_espace_perso', '', userPref)}
        >
          Créer mon espace perso (1 min)
        </a>
        <p className="modal__footer-option">
          {"J'ai déjà un compte ! "}
          <a
            className="footer-option__link"
            href={getCasEndpoint(login_url ? login_url : 'https://api.www.ffr.fr/keycloak_login', content.redirection)}
            onClick={handleTagEvent('connexion', 'Mail', `pop-in ajout joueur favoris`, userPref)}
            role="button"
            tabIndex="0"
          >
            Me connecter
          </a>
        </p>
      </div>
    </div>
  );
}

export default AddFavoritePlayerModalContent;
