// @flow
import CentenaireFormErrorModalContent from 'components/fragments/modal/modalContents/CentenaireFormErrorModalContent';
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import type { DispatchProps } from 'components/fragments/modal/modalContents/CentenaireFormErrorModalContent';
import type { DispatchType } from 'types/Actions';

export default connect(null, (dispatch: DispatchType): DispatchProps => ({
  displayModal: (modalObject: string) => dispatch(displayModal(modalObject))
}))(CentenaireFormErrorModalContent);
