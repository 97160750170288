// @flow

import type { YoutubeChannelInfo } from 'types/YoutubeChannel';

export const FETCH_YOUTUBE_CHANNEL_START = 'FETCH_YOUTUBE_CHANNEL_START';
export const FETCH_YOUTUBE_CHANNEL_SUCCESS = 'FETCH_YOUTUBE_CHANNEL_SUCCESS';
export const FETCH_YOUTUBE_CHANNEL_FAILURE = 'FETCH_YOUTUBE_CHANNEL_FAILURE';

export type FETCH_YOUTUBE_CHANNEL_START_ACTION = {
  type: 'FETCH_YOUTUBE_CHANNEL_START',
  payload: { idChannel: string }
};
export type FETCH_YOUTUBE_CHANNEL_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_CHANNEL_FAILURE'
};

export type FETCH_YOUTUBE_CHANNEL_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_CHANNEL_SUCCESS',
  payload: {
    channel: YoutubeChannelInfo
  }
};

export type Action =
  | FETCH_YOUTUBE_CHANNEL_START_ACTION
  | FETCH_YOUTUBE_CHANNEL_FAILURE_ACTION
  | FETCH_YOUTUBE_CHANNEL_SUCCESS_ACTION;

export function fetchChannelData(idChannel: string): FETCH_YOUTUBE_CHANNEL_START_ACTION {
  return {
    type: FETCH_YOUTUBE_CHANNEL_START,
    payload: { idChannel }
  };
}

export function channelDataFetched(channel: YoutubeChannelInfo): FETCH_YOUTUBE_CHANNEL_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_CHANNEL_SUCCESS,
    payload: { channel }
  };
}

export function channelDataFetchedError(error: any): FETCH_YOUTUBE_CHANNEL_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_CHANNEL_FAILURE
  };
}
