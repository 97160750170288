// @flow
import React, { PureComponent, Fragment } from 'react';
import TextInput from 'components/fragments/input/material/TextInputMaterial';
import CheckboxInput from 'components/fragments/input/CheckboxInput';
import { validateEmail } from 'utils/validatorUtils';
import Loading from 'components/fragments/Loading';

export type DispatchProps = {
  displayModal: (modalObject: string, content: Object) => void
};

export type StateProps = {};

type Props = {
  src: string
} & DispatchProps;

type State = {
  checked: boolean,
  email: string | null,
  formSent: boolean,
  showEmailError: boolean
};

class ModalFormCentenaireDownload extends PureComponent<Props, State> {
  state: State = {
    checked: false,
    email: null,
    formSent: false,
    showEmailError: false
  };

  _handleChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  };

  isFormValid = () => {
    const { email, checked } = this.state;

    return email !== null && email !== '' && validateEmail(email) && checked;
  };

  _handleSubmit = (event: Event) => {
    const { src, displayModal } = this.props;
    event.preventDefault();
    if (this.isFormValid()) {
      this.setState({ formSent: true });
      window.location = src;
      displayModal('NO_MODAL', {});
    }
  };

  onFocusEmail = () => {
    this.setState({ showEmailError: false });
  };

  onBlurEmail = () => {
    this.setState({ showEmailError: true });
  };

  render() {
    const { email, formSent, showEmailError } = this.state;

    const isValid = this.isFormValid();
    const checkboxLabel = `J'ai lu et j'accepte les <a href="/cgu" target="_blank">conditions générales d’utilisation du site</a>`;
    return (
      <Fragment>
        {!formSent && (
          <div className="mt-2">
            <CheckboxInput
              label={checkboxLabel}
              id="centenaire_checked"
              className="checkbox checkbox--gold mb-2"
              onChange={(checked: boolean) => this.setState({ checked })}
            />
            <TextInput
              id="email_centenaire"
              label="Adresse email"
              placeholder="Adresse email"
              onFocus={this.onFocusEmail}
              onBlur={this.onBlurEmail}
              hasError={showEmailError && (email === '' || (email !== '' && email !== null && !validateEmail(email)))}
              errorMessage={"L'adresse email renseignée est invalide."}
              onChange={value => this._handleChange('email', value)}
            />
            <button
              onClick={this._handleSubmit}
              className="btn btn--primary btn--full btn--gold js-confirm mb-1"
              disabled={!isValid}
              type="submit"
            >
              <i className="icon icon-download is-inline"></i>
              Télécharger
            </button>
          </div>
        )}
        {formSent && <Loading isWhite style={{ width: '100px', margin: '0 auto' }} />}
      </Fragment>
    );
  }
}

export default ModalFormCentenaireDownload;
