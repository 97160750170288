// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_MATCHPASTTEAM_START,
  FETCH_MATCHPASTTEAM_SUCCESS,
  FETCH_MATCHPASTTEAM_FAILURE,
} from 'actions/matchActions';

import type { MatchResultType } from 'types/Match';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  results_past: Array<MatchResultType>,
  status: Status
};

const initialState: State = {
  results_past: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_MATCHPASTTEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHPASTTEAM_SUCCESS:
      return {
        ...state,
        results_past: action.payload.results_past,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHPASTTEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
