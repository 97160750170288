// @flow
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import MyNewsletterFeed from 'components/profile/DashboardHome/MyNewsletterFeed';
import type { DispatchProps, StateProps } from 'components/profile/DashboardHome/MyNewsletterFeed';
import type { DispatchType, StateType } from 'types/Actions';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  }),
  (dispatch: DispatchType): DispatchProps => ({
    displayModal: (modalObject: string, content: Object) => dispatch(displayModal(modalObject, content))
  })
)(MyNewsletterFeed);
