// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function ContactFormFailedModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0"></i>
      <div className="modal__wrapper">
        <h3 className="mb-2">
          {(content && content.title) || "Nous avons rencontré un problème lors de l'envoi de votre message"}
        </h3>
        <div className="modal__body modal__body--medium narrow-2 mb-3">
          <div className="circle-icon circle-icon--alert"></div>
          <p>{(content && content.text) || `Merci de vérifier les informations envoyées!`}</p>
        </div>
      </div>
    </div>
  );
}

export default ContactFormFailedModalContent;
