// @flow
import { connect } from 'react-redux';

import { displayModal } from 'actions/modalActions';
import { postUserPref } from 'actions/userActions';
import ItemBadge from 'components/profile/MesFavoris/ItemBadge';

import type { DispatchProps, StateProps } from 'components/profile/MesFavoris/ItemBadge';
import type { DispatchType, StateType } from 'types/Actions';
import type { EditProfileValueType } from 'types/User';

export default connect(
  (state: StateType): StateProps => ({
    userPref: state.userState.userPref
  }),
  (dispatch: DispatchType): DispatchProps => ({
    displayModal: (modalObject: string, content: Object, force: boolean) =>
      dispatch(displayModal(modalObject, content, force)),
    postUserPref: (action: string, value: EditProfileValueType, token: string) =>
      dispatch(postUserPref(action, value, token))
  })
)(ItemBadge);
