// @flow
import type { ArticleType } from 'types/Article';

export function getArticlesLabels(articles: Array<ArticleType>) {
  return articles.reduce(
    (accumulator, currentValue) =>
      accumulator.find(label => label.slug === currentValue.label.slug)
        ? accumulator
        : [...accumulator, currentValue.label],
    []
  );
}

const WORDS_PER_MINUTE = 175;

export function getReadingTime(articleContent: string) {
  const stripedString = articleContent.replace(/(<([^>]+)>)/gi, '');
  const articleContentWordNumber = stripedString.split(' ').length;

  return (articleContentWordNumber / WORDS_PER_MINUTE) * 60 * 1000;
}
