// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export type StateProps = {
  identifie: boolean,
  error: string,
};

type Props = {
  component: React$ComponentType<any>,
  path: string,
  exact?: boolean,
} & StateProps;

const PrivateRoute = ({ component, path, exact, identifie, error }: Props) => {
  if (error === 'rest_logged_user' && !identifie) {
    return <Redirect to='/' />;
  }

  return <Route exact={exact} path={path} component={component} />;
};

export default PrivateRoute;
