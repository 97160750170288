/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';

type Props = {
  id: string,
  label: string,
  hasError?: boolean,
  isDark?: boolean,
  classNames?: string,
};

class RadioInput extends PureComponent<Props> {
  props: Props;

  static defaultProps = {
    id: 'radioinput123',
    label: 'Adresse email'
  };

  render() {
    const { isDark, classNames: propsClasses } = this.props;
    const divClassNames = dynamicClassName('radio');
    isDark && divClassNames.add('is-dark');
    const classNames = !propsClasses ? divClassNames.build() : propsClasses;
    return (
      <div className={classNames}>
        <input type="radio" id={this.props.id} />
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    );
  }
}

export default RadioInput;
