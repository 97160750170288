// @flow
import React, { useState, useEffect, useRef } from 'react';
import type { ClubPreviewType } from 'types/Club';
import type { AcademiePreviewType } from 'types/Academie';
import type { CentenaireDocumentType } from 'types/Centenaire';
import type { EventType } from 'types/Event';

import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon2xCentenaire from 'assets/img/centenaire/map-marker.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconCentenaire from 'assets/img/centenaire/map-marker.png';
import markerIconRed from 'assets/img/marker/marker-red.png';
import markerIcon2xRed from 'assets/img/marker/marker-redx2.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, GeoJSON, Tooltip } from 'react-leaflet';
import { withHelmet } from 'infrastructure/seo/Helmet';
import 'components/fragments/map/OpenStreetMap.css';
import { getDistanceFromLatLonInKm } from 'utils/distanceUtils';
import CardClub from 'containers/fragments/card/CardClubContainer';
import CardAcademie from 'components/fragments/card/CardAcademie';
import CentenaireCard from 'containers/centenaire/CentenaireCardContainer';
import MarkerClusterGroup from 'react-leaflet-markercluster';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

const iconHome = new L.Icon({
  iconUrl: markerIconRed,
  iconRetinaUrl: markerIcon2xRed,
  shadowUrl: markerShadow,
  iconSize: [25, 40],
  iconAnchor: [18, 18],
  popupAnchor: [-5, -13],
  shadowSize: [0, 0],
  shadowAnchor: [10, 10]
});

type Props = {
  clubs?: ClubPreviewType[],
  municipality?: string,
  academies?: AcademiePreviewType[],
  centenaireDocuments?: CentenaireDocumentType[],
  eventDetail?: EventType,
  autour?: boolean,
  geolocation?: boolean,
  style?: string,
  width: string,
  height: string,
  long: number,
  lat: number,
  zoom: number,
  openPopup: number,
  geojson: string,
};

function onEachFeature(feature, layer){
  let popupContent = '';
  if (feature.properties) {
    if (feature.properties.label) {
      popupContent += '<h3>' + feature.properties.label + '</h3>';
    } else if (feature.properties.title) {
      popupContent += '<h3>' + feature.properties.title + '</h3>';
    } else if (feature.properties.titre) {
      popupContent += '<h3>' + feature.properties.titre + '</h3>';
    }

    if (feature.properties.description || feature.properties.descriptif) {
      popupContent += '<p>' + feature.properties.description || feature.properties.descriptif + '</p>';
    }

    if (feature.properties.contact) {
      popupContent += '<p><strong>Contact(s)</strong></p>';
      popupContent += '<p>' + feature.properties.contact + '</p>';
    }
    if (feature.properties.adresse || feature.properties.address) {
      popupContent += '<p><strong>Adresse</strong></p>';
      popupContent += '<p>' + feature.properties.adresse || feature.properties.address + '</p>';
    }
  }
  layer.bindPopup(popupContent);
  layer.on({
    click: e => {
      layer.openPopup();
    }
  });
};

function OpenStreetMap(props:Props) {
  const [geolocation, setGeolocation] = useState(false);
  const [dataGeojson, setDataGeoJson] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(false);
  const [updateData, setUpdateData] = useState(new Date());
  const [map, setMap] = useState(null);
  const groupRef = useRef();
  const markers = useRef();
  markers.current = [];
  const renderMarker = () => {
    const {
      clubs,
      academies,
      eventDetail,
      centenaireDocuments,
      lat,
      long,
    } = props;
  
    if (clubs && clubs.length > 0) {
      return clubs.map((club, index) => {
        const position = [club.lat, club.long];
        const distance = geolocation ? parseInt(getDistanceFromLatLonInKm(lat, long, club.lat, club.long), 10)+'km' : '';
        let tooltip = club.name;
        if (distance) {
          tooltip += ' ('+distance+')';
        }
        return (
          <Marker position={position} key={index} ref={markers.current && markers.current[index]} >
            <Tooltip>{tooltip}</Tooltip>
            <Popup closeButton={false}>
              <CardClub
                key={index}
                club={club}
                distance={geolocation ?
                  getDistanceFromLatLonInKm(lat, long, club.lat, club.long) :
                  0} />
            </Popup>
          </Marker>
        );
      });
    }
  
    if (academies && academies.length > 0) {
      return academies.map((academie, index) => {
        const position = [academie.lat, academie.lon];
        return (
          <Marker position={position} key={index} ref={markers.current && markers.current[index]} >
            <Popup>
              <CardAcademie
                key={index}
                academie={academie}
                position={index + 1}
                design={'marker'} />
            </Popup>
          </Marker>
        );
      });
    }

    if (eventDetail) {
      const position = [eventDetail.lat, eventDetail.long];
      // @XXX Add an event detail popup ? 
      return (
        <Marker position={position} />
      );
    }

    // Centenaire Map
    if (centenaireDocuments) {
      return centenaireDocuments.map((document, index) => {
        const position = [document.lat, document.lon];
        return (
          <Marker position={position} key={index} ref={markers.current && markers.current[index]} >
            <Popup>
              <CentenaireCard
                {...document}
                mode="map"
                key={index}
                position={index + 1}
                design={'marker'} />
            </Popup>
          </Marker>
        );
      });
    }
    const position = [lat, long];
    return <Marker position={position} />;
  };

  const { height, lat, long, zoom, clubs, autour, centenaireDocuments, style, geojson, municipality } = props;

  useEffect(() => {
    if (props.geojson && !dataGeojson) {
      fetch(props.geojson) // Call the fetch function passing the url of the API as a parameter
        .then(function (data) {
          return data.json();
        }).then(function (dataGeojson) {
          setDataGeoJson(dataGeojson);
          setUpdateData(new Date());
        })
        .catch(function (error) {
          // This is where you run code if the server returns any errors
          console.error(error);
        });
    }
  }, [geojson]);

  useEffect(() => {
    if (map && lat && long) {
      map.setView([parseFloat(lat) || 0, parseFloat(long) || 0]);
    }
  }, [lat, long]);

  useEffect(() => {
    if (!geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setGeolocation(true);
      });
    }
  }, [geolocation, navigator.geolocation]);

  useEffect(() => {
    if (map) {
      /*const group = groupRef && groupRef.leafletElement;
      console.log(map, group);
      if (map && group) {
        console.log(group);
        map.fitBounds(group.getBounds());
      }*/
    }
  }, [updateData, map]);

  useEffect(() => {
    if (lat && long ) {
      setMarkerPosition(new L.latLng(lat, long))
    } else {
      setMarkerPosition(false)
    }
  }, [municipality]);

  useEffect(() => {
    if (map && props.openPopup >= 0) {
      if (markers && markers.current && markers.current[props.openPopup] && markers.current[props.openPopup]._leaflet_id) {
        var marker = null;
        var id = markers.current[props.openPopup]._leaflet_id;
        map.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
                if (layer._leaflet_id === id) {
                    marker = layer;
                }
            }
        });
        if (marker) {
          marker.openPopup();
        }
      }
    }
  }, [props.openPopup, map]);
  
  useEffect(() => {
    if (map) {
      const {clubs, lat, long} = props;
      if (clubs && clubs.length === 0) {
        const position = [parseFloat(lat) || 0, parseFloat(long) || 0];
        if (map) {
          map.setView(position, 13);
        }
      }
      if (clubs && clubs.length > 0) {
        const bounds = clubs.map<any>((club) => ([club.lat, club.long]));
        if (markerPosition) {
          bounds.push(markerPosition);
        }
        const boundsLoc = L.latLngBounds(bounds);
        map.fitBounds(boundsLoc);
        const zoom = map.getZoom();
        if (zoom > 15) {
          map.setZoom(15);
        }
      }
      map.invalidateSize();
    }
  }, [clubs, map]);
  const position = [parseFloat(lat) || 0, parseFloat(long) || 0];

  // CentenaireMap
  if (centenaireDocuments) {
    // icon managment
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: markerIcon2xCentenaire,
      iconUrl: markerIconCentenaire,
      shadowUrl: '',
      popupAnchor: [0, -20],
      iconAnchor: [21, 24],
      iconSize: [43, 48],
      shadowSize: [0, 0]
    });

    // map size
    let boundsLoc = false;
    if (centenaireDocuments.length > 0) {
      boundsLoc = centenaireDocuments.map((document) => ([document.lat, document.lon]));
      if (autour) {
        boundsLoc.push([lat, long]);
      }
    }

    // with document to place on the map
    if (boundsLoc) {
      return (
        <MapContainer
          id="map"
          className={style}
          center={position}
          zoom={8}
          maxZoom={15}
          whenCreated={setMap}
          bounds={boundsLoc}
          boundsOptions={{ padding: [25, 25] }}
          zoomControl={false}>
          <TileLayer
            attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
            url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
          <MarkerClusterGroup showCoverageOnHover={false}>
            {renderMarker()}
          </MarkerClusterGroup>
        </MapContainer>
      );
    }

    // without document to place on the map
    return (
      <MapContainer
        id="map"
        center={position}
        zoom={5}
        maxZoom={15}
        whenCreated={setMap}
        zoomControl={false}>
          <TileLayer
            attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
            url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
        <MarkerClusterGroup showCoverageOnHover={false}>
          {renderMarker()}
        </MarkerClusterGroup>
        <ZoomControl position="topright" />
      </MapContainer>
    );
  }

  // ClubMap
  if (clubs && clubs.length > 1) {
    return (
      <MapContainer
        center={position}
        zoom={8}
        maxZoom={15}
        whenCreated={setMap}
        style={{ height: `${height}px` }}
        boundsOptions={{ padding: [25, 25] }}>
        <TileLayer
          attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
          url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
        <MarkerClusterGroup showCoverageOnHover={false}>
          {renderMarker()}
        </MarkerClusterGroup>
        {municipality && lat && long &&
          <Marker position={position} icon={iconHome} >
            <Tooltip>{municipality}</Tooltip>
            <Popup closeButton={false}>
              <p>{municipality}</p>
            </Popup>
          </Marker>}
        {!municipality && geolocation && lat && long &&
        <Marker position={position} icon={iconHome}>
          <Tooltip>{`Votre position`}</Tooltip>
          <Popup closeButton={false}>
            <p>{`Votre position`}</p>
          </Popup>
        </Marker>}
      </MapContainer>
    );
  } else if (clubs && clubs.length > 0) {
    return (
      <MapContainer
        center={[clubs[0].lat, clubs[0].long]}
        zoom={10}
        maxZoom={18}
        whenCreated={setMap}
        style={{ height: `${height}px` }}>
        <TileLayer
          attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
          url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
        {renderMarker()}
        {municipality && lat && long &&
          <Marker position={position}>
            <Tooltip>{municipality}</Tooltip>
            <Popup closeButton={false}>
              <p>{municipality}</p>
            </Popup>
          </Marker>}
        {!municipality && geolocation && lat && long &&
        <Marker position={position} icon={iconHome}>
          <Tooltip>{`Votre position`}</Tooltip>
          <Popup closeButton={false}>
            <p>{`Votre position`}</p>
          </Popup>
        </Marker>}
      </MapContainer>
    );
  } else if (clubs && clubs.length === 0) {
    return (
      <MapContainer
        center={position}
        zoom={zoom ? zoom : 13}
        maxZoom={18}
        whenCreated={setMap}
        style={{ height: `${height}px` }}>
        <TileLayer
          attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
          url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
        {municipality && lat && long &&
        <Marker position={position}>
          <Tooltip>{municipality}</Tooltip>
          <Popup closeButton={false}>
            <p>{municipality}</p>
          </Popup>
        </Marker>}
        {!municipality && geolocation  && lat && long &&
        <Marker position={position} icon={iconHome}>
          <Tooltip>{`Votre position`}</Tooltip>
          <Popup closeButton={false}>
            <p>{`Votre position`}</p>
          </Popup>
        </Marker>}
      </MapContainer>
    );
  } else if (geojson) {
    return (
      <MapContainer
        center={position}
        zoom={zoom ? zoom : 13}
        maxZoom={18}
        whenCreated={setMap}
        boundsOptions={{ padding: [20, 20] }}
        style={{ height: `${height}px` }}>
        <TileLayer
          attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
          url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
          {dataGeojson &&
          <MarkerClusterGroup showCoverageOnHover={false}>
            <GeoJSON
              ref={groupRef}
              data={dataGeojson}
              onEachFeature={onEachFeature} />
          </MarkerClusterGroup>}
      </MapContainer>
    );
  }

  // every other map
  return (
    <MapContainer
      center={position}
      zoom={zoom ? zoom : 13}
      maxZoom={18}
      whenCreated={setMap}
      style={{ height: `${height}px` }}>
      <TileLayer
        attribution="donn&eacute;es &copy; <a href='//osm.org/copyright'>OpenStreetMap</a>/ODbL - rendu <a href='//openstreetmap.fr'>OSM France</a>"
        url="https://cache-osm.ffr.fr/tile/{z}/{x}/{y}.png" />
      <MarkerClusterGroup showCoverageOnHover={false}>
        {renderMarker()}
      </MarkerClusterGroup>
      
    </MapContainer>
  );
}

export default withHelmet(OpenStreetMap)((Helmet, pageProps) => (
  <Helmet>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.8.0/leaflet.css" />
    <link rel="stylesheet" href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css" />
  </Helmet>
));
