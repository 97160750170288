// @flow

import {
  FETCH_PRIVATE_ACCOUNT_DASHBOARD_START,
  FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS,
  FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE
} from 'actions/privateAccountDashboardActions';
import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import type { ActionType } from 'types/Actions';
import type { PrivateAccountDashboardType } from 'types/PrivateAccountDashboardType';
import type { Status } from 'types/Status';

export type State = {
  dashboard: PrivateAccountDashboardType[],
  status: Status
};

const initialState: State = {
  dashboard: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_PRIVATE_ACCOUNT_DASHBOARD_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_PRIVATE_ACCOUNT_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        status: STATUS_SUCCESS
      };
    case FETCH_PRIVATE_ACCOUNT_DASHBOARD_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
