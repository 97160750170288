// @flow
import HeaderMobile from 'components/header/HeaderMobile';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { StateType } from 'types/Actions';

export default withRouter(
  connect((state: StateType, ...ownProps: any) => ({
    keycloakData: state.userState.keycloakData,
    logo: state.settingsState.logo,
    logoOver: state.settingsState.logo_transparent,
    userPref: state.userState.userPref
  }))(HeaderMobile)
);
