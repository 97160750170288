// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { RawPrivateAccountMyBoutiqueType } from 'types/PrivateAccountMyBoutiqueType';

function PrivateAccountMyBoutiqueApi(executor: ApiExecutorType) {
  return { fetchPrivateAccountMyBoutique };

  function fetchPrivateAccountMyBoutique(): Promise<RawPrivateAccountMyBoutiqueType[]> {
    return executor.get(`/ffr/v1/private_account_my_boutique`).then(response => response);
  }
}

export default PrivateAccountMyBoutiqueApi;
