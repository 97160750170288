// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import PlaylistsChannelApi from 'api/ffrtv/PlaylistsChannelApi';

import {
  FETCH_YOUTUBE_PLAYLISTS_LINKED_START,
  playlistsLinkedFetched,
  playlistsLinkedFetchError,
  type FETCH_YOUTUBE_PLAYLISTS_LINKED_START_ACTION
} from 'actions/ffr-tv/youtubePlaylistsLinkedActions';

export default function(apiExecutor: ApiExecutorType) {
  const playlistsChannelApi = new PlaylistsChannelApi(apiExecutor);
  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_PLAYLISTS_LINKED_START, fetchYoutubePlaylist);
  };

  function* fetchYoutubePlaylist(action: FETCH_YOUTUBE_PLAYLISTS_LINKED_START_ACTION): Saga<void> {
    const {
      payload: { idChannel, idPlaylist }
    } = action;
    try {
      const playlists = [];
      let nextPageToken = null;
      let isFirst = true;
      while ((nextPageToken !== null && nextPageToken !== undefined && nextPageToken !== '') || isFirst) {
        isFirst = false;
        const rawYoutubePlaylists = yield call(
          playlistsChannelApi.fetchPlaylistsChannelInfo,
          idChannel,
          50,
          nextPageToken
        );
        nextPageToken = rawYoutubePlaylists.nextPageToken;

        const newplaylists = rawYoutubePlaylists.youtubePlaylists.filter(item => item.id !== idPlaylist);

        playlists.push(...newplaylists);
      }
      //  const tabPlaylists = idPlaylist;
      //  const playlists = yield call(playlistApi.fetchPlaylists, tabPlaylists);
      yield put(playlistsLinkedFetched(playlists));
    } catch (e) {
      yield put(playlistsLinkedFetchError());
    }
  }
}
