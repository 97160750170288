// @flow

export type YoutubeContent = {
  id: string,
  type: 'Youtube',
  date: Date | string,
  backgroundImage?: string,
  backgroundImageStandard?: string,
  description: string,
  comments: number,
  likes: number,
  share?: number,
  duration?: string,
  player?: string,
  link?: string,
  tags: Array<string>
};


export const nullYoutubePosts: YoutubeContent = {
    id: '',
    type: 'Youtube',
    date: '',
    backgroundImage: '',
    backgroundImageStandard: '',
    description: '',
    comments: 0,
    likes: 0,
    share: 0,
    player: '',
    duration: '',
    tags: []
};

export type RawYoutubePostsType = {
  id: string,
  contentDetails: {
    caption: string,
    definition: string,
    duration: string,
  },
  player: {
    embedHtml: string
  },
  snippet: {
    description: string,
    publishedAt: Date | string,
    tags: Array<string>,
    title: string,
    thumbnails: {
      medium: {
        url: string,
      },
      standard: {
        url: string,
      }
    }
  },
  statistics: {
    likeCount: number,
    commentCount: number,
    dislikeCount: number,
    favoriteCount: number,
    viewCount: number
  }
}