// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_STAFFTEAM_START,
  FETCH_STAFFTEAM_SUCCESS,
  FETCH_STAFFTEAM_FAILURE
} from 'actions/staffActions';

import type { StaffType } from 'types/Staff';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  staffs: Array<StaffType>,
  status: Status
};

const initialState: State = {
  staffs: [],
  status: STATUS_LOADING
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_STAFFTEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_STAFFTEAM_SUCCESS:
      return {
        ...state,
        staffs: action.payload.staffs,
        status: STATUS_SUCCESS
      };
    case FETCH_STAFFTEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
