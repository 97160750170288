// @flow
import React from 'react';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { liensRsType } from 'types/Settings';
import type { FullUserType } from 'types/User';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';

export type StateProps = {
  titleLigue: string,
  liens_rs: liensRsType,
  userPref: FullUserType
};

const FooterTop = (props: StateProps) => {
  const {
    liens_rs: {
      fb_lien,
      twitter_lien,
      x_lien,
      instagram_lien,
      youtube_lien,
      linkedin_lien,
      twitch_lien,
      snapchat_lien,
      tik_tok_lien,
      discord_lien
    },
    titleLigue,
    userPref
  } = props;
  if (
    fb_lien === '' &&
    twitter_lien === '' &&
    x_lien === '' &&
    instagram_lien === '' &&
    youtube_lien === '' &&
    linkedin_lien === '' &&
    twitch_lien === '' &&
    snapchat_lien === '' &&
    tik_tok_lien === '' &&
    discord_lien === ''
  ) {
    return null;
  }

  return (
    <div className="container footer__top">
      <div className="row">
        <div className="col col-lg-3">
          <h5 className="mb-1">Nous suivre</h5>
          <div className="mt-3 mb-5">
            {fb_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={fb_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Facebook de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', 'facebook', userPref)}
              >
                <i className="icon icon-facebook" />
              </a>
            )}
            {(twitter_lien || x_lien) && (
              <a
                className="link-icon is-dark mb-2"
                href={twitter_lien || x_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page X de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `x`, userPref)}
              >
                <i className="icon icon-twitter" />
              </a>
            )}
            {instagram_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={instagram_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Instagram de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `instagram`, userPref)}
              >
                <i className="icon icon-instagram" />
              </a>
            )}
            {youtube_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={youtube_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Youtube de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `youtube`, userPref)}
              >
                <i className="icon icon-youtube" />
              </a>
            )}
            {linkedin_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={linkedin_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Linkedin de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `linkedin`, userPref)}
              >
                <i className="icon icon-linkedin" />
              </a>
            )}
            {twitch_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={twitch_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Twitch de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `twitch`, userPref)}
              >
                <i className="icon icon-twitch" />
              </a>
            )}
            {snapchat_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={snapchat_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Snaptchat de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `snapchat`, userPref)}
              >
                <i className="icon icon-snapchat" />
              </a>
            )}
            {tik_tok_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={tik_tok_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page TikTok de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `tik-tok`, userPref)}
              >
                <i className="icon icon-tik-tok" />
              </a>
            )}
            {discord_lien && (
              <a
                className="link-icon is-dark mb-2"
                href={discord_lien}
                target="_blank"
                rel="noopener noreferrer"
                title={`Se rendre sur la page Discord de ${titleLigue}`}
                onClick={handleTagEvent('footer', 'clic_icone_sociale', `discord`, userPref)}
              >
                <i className="icon icon-discord" />
              </a>
            )}
          </div>
        </div>
        <NewsletterSubscriberContainer />
      </div>
      <hr />
    </div>
  );
};

export default FooterTop;
