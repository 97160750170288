// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type CompetitionListElementType = {
  division: string,
  slug: string,
  recents: number,
  age: string,
  sexe: string,
  famille: string,
  first: {
    slug: string,
    title: string,
    src: string
  }
};

export type CompetitionListCategoryType = {
  title: string,
  index: number,
  championships: Array<CompetitionListElementType>
};

export const nullCompetitionElement = {
  division: '',
  slug: '',
  recents: 0,
  age: '',
  sexe: '',
  famille: '',
  first: {
    slug: '',
    title: '',
    src: ''
  }
};

export type ParticipantType = {
  id: number,
  nom: string,
  embleme: string,
  slug: string,
  url_type: string,
  url: string
};

export type MatchType = {
  id_ovale: string,
  locaux: string,
  locaux_score: string,
  visiteurs: string,
  visiteurs_score: string,
  rencontre_id: number
};

export type CompetitionType = SEOMetaType & {
  slug: string,
  division: string,
  participants: Array<ParticipantType>,
  prevDay: {
    poules: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    journee_id: string,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string,
    phase_id: string,
    phase_nom: string
  },
  nextDay: {
    poules: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    journee_id: boolean,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string
  },
  ranking: {
    poules: Array<{
      classement: Array<any>,
      pouleId: number
    }>
  },
  documents: {
    documents: Array<BlocContent>,
  },
  medias: {
    content: Array<BlocContent>,
    media: Array<any>
  },
  sidebar: Array<BlocContent>
};

export const nullCompetition: CompetitionType = {
  ...nullSeoMeta,
  slug: '',
  division: '',
  participants: [],
  prevDay: {
    poules: [
      {
        pouleId: 0,
        rencontres: []
      }
    ],
    journee_id: '',
    nombre_rencontres: 0,
    journee_date: '',
    journee_nom: '',
    phase_id: '',
    phase_nom: ''
  },
  nextDay: {
    poules: [
      {
        pouleId: 0,
        rencontres: []
      }
    ],
    journee_id: false,
    nombre_rencontres: 0,
    journee_date: '',
    journee_nom: ''
  },
  ranking: {
    poules: [
      {
        classement: [
          {
            equipe: {
              nom: ''
            },
            position: '',
            pointTerrain: '',
            joues: '',
            gagnes: '',
            nuls: '',
            perdus: '',
            last_matchs: [
              {
                win: '',
                name: ''
              }
            ]
          }
        ],
        pouleId: 0
      }
    ]
  },
  documents: {
    documents: []
  },
  medias: {
    content: [],
    media: []
  },
  sidebar: []
};

export type RawCompetitionType = {
  id: number,
  famille: string,
  officielle: string,
  nouveaux_resultats: number,
  date: string,
  date_gmt: string,
  guid: {
    rendered: string
  },
  modified: string,
  modified_gmt: string,
  slug: string,
  competitions_ffr_api_slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: Array<{
      type: string,
      text: string
    }>
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  categories: Array<any>,
  tags: Array<any>,
  sexe: string,
  classe_age: string,
  classe_age_code: string,
  ovale_id: number,
  tags_list: Array<any>,
  participants: Array<ParticipantType>,
  derniere_journee: {
    poules?: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    rencontres?: Array<MatchType>,
    journee_id: string,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string,
    phase_id: string,
    phase_nom: string
  },
  prochaine_journee: {
    poules?: Array<{
      pouleId: number,
      rencontres: Array<MatchType>
    }>,
    rencontres?: Array<MatchType>,
    journee_id: boolean,
    nombre_rencontres: number,
    journee_date: string,
    journee_nom: string
  },
  classement_intra: {
    poules: Array<{
      classement: Array<any>,
      pouleId: number
    }>
  },
  liste_enfants: {
    documents: {
      content: {
        rendered: string,
        data: Array<BlocContent>
      }
    },
    medias: {
      content: {
        rendered: string,
        data: Array<BlocContent>
      },
      albums: Array<any>
    }
  },
  sidebar_content: {
    data: Array<BlocContent>
  }
} & SEOMetaType;
