// @flow
import NewsletterSubscriber from 'components/footer/NewsletterSubscriber';
import { connect } from 'react-redux';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/footer/NewsletterSubscriber';

import { fetchNewsletterOptions, subscribeNewsletter } from 'actions/newsletterSubscriptionActions';
import { displayModal } from 'actions/modalActions';
import type { NewsletterSubscriptionEntryType } from 'types/NewsletterSubscription';

export default connect(
  (state: StateType): StateProps => ({
    options: state.newsletterSubscriptionState.options,
    newsletterSetting: state.settingsState.newsletter,
    titleLigue: state.settingsState.title,
    status: state.newsletterSubscriptionState.status
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchOptions: (campaignMonitorKey: string) => dispatch(fetchNewsletterOptions(campaignMonitorKey)),
    subscribeNewsletter: (
      subscription: NewsletterSubscriptionEntryType,
      campaignMonitorKey: string,
      typeNewsletter: string | boolean
    ) => dispatch(subscribeNewsletter(subscription, campaignMonitorKey, typeNewsletter)),
    displayModal: (modalObject: string, content: Object, force: boolean) =>
      dispatch(displayModal(modalObject, content, force))
  })
)(NewsletterSubscriber);
