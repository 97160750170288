// @flow

import { DISPLAY_MODAL, HIDE_MODAL } from 'actions/modalActions';

import type { ActionType } from 'types/Actions';

export type State = {
  modalObject: string,
  content: Object,
  force: boolean
};

const initialState: State = {
  modalObject: 'NO_MODAL',
  content: {},
  force: false
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case DISPLAY_MODAL:
      return {
        ...state,
        modalObject: action.payload.modalObject,
        content: action.payload.content,
        force: action.payload.force
      };
    case HIDE_MODAL:
      return {
        ...state,
        modalObject: action.payload.modalObject,
        content: {},
        force: false
      };
    default:
      return state;
  }
}
