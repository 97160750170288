/// @flow
import React, { PureComponent } from 'react';
import type { ClubPreviewType } from 'types/Club';
import { dynamicClassName } from 'utils/dynamicClassName';
import { queryClubsAndCities } from 'utils/clubUtils';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  id: string,
  label?: string,
  placeholder?: string,
  options: ClubPreviewType[],
  className?: string,
  errorMessage?: string,
  hasError?: boolean,
  select?: boolean,
  inputChanged?: (value: string) => void,
  onFocus?: (value: string) => void,
  onBlur?: (value: string) => void,
  dropdownStyle?: Object,
};

type State = {
  label: string,
  isOpen: boolean,
  clubs: ClubPreviewType[],
  selected: string,
};

class DropdownClubInput extends PureComponent<Props, State> {
  props: Props;
  wrapperRef: any;

  static defaultProps = {
    id: 'dropdownradioinput123',
    options: [
      {
        value: '',
        label: ''
      }
    ],
    onBlur: () => {},
    onFocus: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      label: '',
      clubs: [],
      isOpen: false,
      selected: ''
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { label: propsLabel } = props;
    const { label } = state;

    if (propsLabel && label !== propsLabel) {
      return { label: propsLabel };
    }

    return null;
  }

  componentDidMount() {
    if (this.state.selected === '' || this.state.selected.length < 3 ) {
      this.setState({ clubs: [], isOpen: false});
    } else {
      queryClubsAndCities(this.props.options, this.state.selected.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        .then(({ cities, clubs }) => this.setState({ clubs }));
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { id, placeholder, hasError, className, errorMessage, onBlur, onFocus } = this.props;
    const { isOpen, clubs, selected } = this.state;
    const classNames = dynamicClassName('input input--dropdown input--material');
    className && classNames.add(className);
    hasError && classNames.add('has-error');
    isOpen && classNames.add('is-open');
  
    const classNamesDropdownList = dynamicClassName('dropdown__list');

    return (
      <>
        <div className={classNames.build()} ref={this.setWrapperRef} style={this.props.dropdownStyle}>
          <input type="text" 
            id={id}
            autoComplete="off"
            className={!placeholder ? "is-empty" : ''}
            value={selected}
            onChange={(e) => this.handleChange(e)}
            onFocus={(event) => {
              this._onInputStatusChange(event, true);
              onFocus && onFocus(event);
            }}
            onBlur={(event) => {
              this._onInputStatusChange(event, false);
              onBlur && onBlur(event.target.value);
            }}
            placeholder={placeholder} />
          <label htmlFor={id} data-label={this.state.label}>{this.state.label}</label>
          {hasError && <i className="icon icon-info" />}
          {clubs.length > 0 &&
          <div className={classNamesDropdownList.build()}>
            <div className="dropdown__wrapper" style={{maxHeight: '1000px'}}>
              <p className="ft-500 mb-1">
                Clubs
              </p>
              <ul>
                {this.renderOptions(clubs)}
              </ul>
            </div>
          </div>}
        </div>
        {hasError && errorMessage && <ErrorMessage message={errorMessage} />}
      </>
    );
  }

  renderOptions = (options: any) => {
    return options.map((option, index) => (
        <li className="mb-1" key={index}>
          <a role="button" tabIndex={0} style={{display: 'block'}} onClick={() => {this.handleSelect(option);}}>
            <img src={option.img} width={16} height={16} />
            {option.name}
          </a>
        </li>
      ));
  };

  _onInputStatusChange = (event: SyntheticFocusEvent<EventTarget>, isFocus: boolean) => {
    const { target } = event;
    const { placeholder } = this.props;

    if (target instanceof HTMLInputElement) {
      if (isFocus || target.value !== '') {
        target.classList.remove('is-empty');
      } else if (!placeholder) {
        target.classList.add('is-empty');
      }
    }
  }

  handleSelect = (option: any) => {
    this.setState({selected: option.name});
    queryClubsAndCities(this.props.options, option.name.toLowerCase())
      .then(({ cities, clubs }) => this.setState({ clubs }));
    if (this.props.inputChanged) {
      this.props.inputChanged(option.name);
    }
  }

  handleChange = (e: any) => {
    this.setState({ selected : e.target.value });
    const query = e ? e.target.value.toLowerCase() : '';
    if (query === '' || query.length < 3 ) {
      this.setState({ clubs: []});
    } else {
      queryClubsAndCities(this.props.options, query.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        .then(({ cities, clubs }) => this.setState({ clubs}));
    }
    if (this.props.inputChanged) this.props.inputChanged(e.target.value);
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.state.selected === '' || this.state.selected.length < 3 ) {
      this.setState({ clubs: [], isOpen: false});
    } else {
      queryClubsAndCities(this.props.options, this.state.selected.toLowerCase())
        .then(({ cities, clubs }) => this.setState({ clubs }));
    }
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };
}

export default DropdownClubInput;