//@flow
import type { PrivateAccountMyBoutiqueType, RawPrivateAccountMyBoutiqueType } from 'types/PrivateAccountMyBoutiqueType';

export function convertPrivateAccountMyBoutiqueFromApi(
  rawPrivateAccountMyBoutique: RawPrivateAccountMyBoutiqueType[]
): PrivateAccountMyBoutiqueType[] {
  if (!rawPrivateAccountMyBoutique) {
    return [];
  }

  const privateAccountMyBoutique = rawPrivateAccountMyBoutique.map(myBoutique => {
    return {
      content: myBoutique.content.data,
      title: myBoutique.title.raw,
      user_profile: myBoutique.meta.user_profile
    };
  });

  return privateAccountMyBoutique;
}
