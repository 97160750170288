// @flow
import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

import type { CompetitionsInternationalesLink } from 'types/competitionsInternationales';

type Props = {
  current_competition: CompetitionsInternationalesLink,
  location: any
};

function EventDrivenPageBanner({ current_competition, location }: Props) {
  if (
    current_competition &&
    current_competition.slug &&
    !!current_competition.label &&
    !location.pathname.match(/competitions-internationales/)
  ) {
    return (
      <div className="topbar-competition-alert">
        <Marquee autoFill={true} pauseOnHover={true}>
          <Link to={`/competitions-internationales/${current_competition.slug}`}>
            <div className="marquee__content">
              {current_competition.label || ` Suivez les bleus pendant la coupe du monde !`}
            </div>
          </Link>
        </Marquee>
        <span className="separateur"> | </span>
        <Link to={`/competitions-internationales/${current_competition.slug}`} className="btn btn--white">
          Voir toutes les infos
        </Link>
      </div>
    );
  }

  return null;
}

export default EventDrivenPageBanner;
