// @flow
import React from 'react';

import ModalFormSeminaire from 'containers/entreprise/seminaire/ModalFormSeminaireContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function SeminaireFormModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box keep-in-touch">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
      <div className="modal__wrapper">
        <h3 className="mb-2">{'Être recontacté pour obtenir une proposition'}</h3>
        <div className="modal__body modal__body--medium mb-4">
          <p className="mb-0">
            Il ne vous reste plus qu’une étape ! Remplissez ce formulaire pour que l’un de nos commerciaux puisse vous
            recontacter rapidement afin d’affiner votre demande et vous faire une proposition.
          </p>
        </div>
        {content && <ModalFormSeminaire content={content} />}
        {!content && <p>Erreur lors de la propagation des données, renvoyez le formulaire.</p>}
      </div>
    </div>
  );
}

export default SeminaireFormModalContent;
