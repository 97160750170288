// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function FavoriteBadgeRedirectionOnMobileModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box favorite-badge-redirection-modal modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />

      <div className="modal__wrapper">
        <div className="modal__wrapper__header mb-2">
          <div className="badge--mini">
            <div className="badge__circle">
              <div className="badge__inner-circle">
                <div className="badge__inner-image" style={{ backgroundImage: `url(${content.featured_image})` }}></div>
              </div>
            </div>
          </div>
          <p className="mt-1 ft-tertiary ft-700 badge__desc">{content.title}</p>
        </div>

        <a className="btn btn--primary btn--full js-confirm mb-1" href={content.itemLink}>
          {`Accéder à la fiche ${content.favItems}`}
        </a>

        <button className="btn btn--full modal__footer-option" onClick={content.removeFromFavorite}>
          Supprimer des favoris
        </button>
      </div>
    </div>
  );
}

export default FavoriteBadgeRedirectionOnMobileModalContent;
