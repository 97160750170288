export const initWeglotCDN = apiKey => {
  const scriptId = `weglot-cdn-${apiKey}`;

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.src = `https://cdn.weglot.com/weglot.min.js`;

  head.append(script, head.lastChild);
};

export const initWeglotInitialize = apiKey => {
  const scriptId = `weglot-init-${apiKey}`;

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.innerHTML = `if (typeof Weglot === 'object') 
    Weglot.initialize({ api_key: "${apiKey}"}); else {setTimeout(function() { Weglot.initialize({ api_key: "${apiKey}"})}, 300);}`;
  head.append(script, head.lastChild);
};
