// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';

import { handleTagEvent } from 'utils/tagManagerUtils';
import type { RouterProps } from 'types/Router';
import type { FullUserType } from 'types/User';
import { getCasEndpoint, getCasRegister } from 'constants/sso';

export type StateProps = {
  login_url: string,
  userPref: FullUserType
};

type Props = RouterProps &
  StateProps & {
    content: any,
    hideModal: () => void
  };

function TicketingModalContent({ content, hideModal, login_url, location: { pathname }, userPref }: Props) {
  return (
    <div className="modal-content box ticketing-signup modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0"></i>
      <div className="modal__wrapper">
        <h3 className="mb-4">Alerte billetterie</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-2">
          <div className="circle-icon circle-icon--check"></div>
          <p className="mb-1">
            Entrez dans la mêlée, profitez-en pour créer votre <strong>espace perso FFR.</strong>
          </p>
          <p className="mb-0">Il ne vous manque plus qu’un mot de passe</p>
        </div>
        <ul className="list list--check mb-3">
          <li>
            <span>La gestion de vos alertes billetteries</span>
          </li>
          <li>
            <span>La gestion de vos centres d’intérêts</span>
          </li>
          <li>
            <span>Pour les licenciés : vos statistiques de jeu rassemblées</span>
          </li>
        </ul>
        <a
          className="btn btn--primary btn--full js-confirm mb-2"
          href={getCasRegister('/tableau-de-bord')}
          onClick={handleTagEvent('pop_in_ajout_joueur', 'clic_creation_espace_perso', '', userPref)}
        >
          Créer mon espace perso (1 min)
        </a>
        <p className="modal__footer-option">
          {`J'ai déjà un compte ! `}
          <a
            className="footer-option__link"
            href={getCasEndpoint(login_url ? login_url : 'https://api.www.ffr.fr/keycloak_login', pathname)}
            onClick={handleTagEvent('connexion', 'Mail', `pop-in alerte billetterie`, userPref)}
          >
            Me connecter
          </a>
        </p>
      </div>
    </div>
  );
}

export default withRouter(TicketingModalContent);
