// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { ContactFormType } from 'types/ContactForm';

import {
  convertFormContactResponseFromApi,
  convertFormContactFromApi,
  convertOneFormContactFromApi,
} from 'services/Form';
import { FORM_CONTACT_TOKEN } from 'constants/api';

function FormApi(executor: ApiExecutorType) {
  return { postFormContact, fetchFirstFormContact, fetchAllFormContact };

  function fetchFirstFormContact(): Promise<ContactFormType> {
    return executor
      .get(`/contact-form-7/v1/contact-forms/`, {
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`
        }
      })
      .then(response => convertFormContactFromApi(response));
  }

  function fetchAllFormContact(): Promise<ContactFormType> {
    return executor
      .get(`/contact-form-7/v1/contact-forms/`, {
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`
        }
      })
      .then(response => Promise.all(response.map(convertOneFormContactFromApi)));
  }

  function postFormContact(formInputs: any, id: number) {
    return executor
      .post(`/contact-form-7/v1/contact-forms/${id}/feedback`, formInputs, {
        headers: {
          Authorization: `Basic ${FORM_CONTACT_TOKEN}`,
          'content-type': 'multipart/form-data'
        },
        timeout: 6000000
      })
      .then(response => convertFormContactResponseFromApi(response));
  }
}
export default FormApi;
