// @flow

export type ContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string
};

export const nullContactForm = {
  id: -1,
  slug: '',
  title: '',
  locale: ''
};

export type formInputsType = {
  'ffr-name': string,
  'ffr-email': string,
  'ffr-profil': string,
  'ffr-subject': string,
  'ffr-message': string,
  'ffr-contact-file'?: any
};

export type rawContactFormResultType = {
  into: string,
  status: string,
  message: string,
  invalidFields: Array<{ into: string, message: string }>
};

export type ContactFormResultType = {
  into: string,
  status: string,
  message: string
};

export const nullContactFormResult = {
  into: '',
  status: '',
  message: ''
};

export type rawContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string
};
