// @flow
import React from 'react';

import NewsletterInterestsList from 'containers/newslettermodal/NewsletterInterestsListContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function CustomizeNewsletterModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-subscription modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-2">Inscription newsletter</h3>
        <div className="modal__body mb-3">
          <p>{"Recevez une newsletter encore plus personnalisée, en choisissant vos centres d'intérêts : "}</p>
        </div>
        <NewsletterInterestsList content={content} />
      </div>
    </div>
  );
}

export default CustomizeNewsletterModalContent;
