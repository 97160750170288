// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_LOGE_DETAIL_SUCCESS,
  FETCH_UNKNOWN,
  FETCH_LOGE_DETAIL_START,
} from 'actions/logeActions';
import { nullLogeDetail } from 'types/LogeDetail';

import type { Status } from 'types/Status';
import type { LogeDetailType } from 'types/LogeDetail';
import type { ActionType } from 'types/Actions';

export type State = {
  details: LogeDetailType,
  status: Status,
};

const initialState: State = {
  details: {
    ...nullLogeDetail,
  },  status: STATUS_UNKNOWN,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_LOGE_DETAIL_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_LOGE_DETAIL_SUCCESS:
      return {
        ...state,
        details: action.payload.details,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
