// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  convertVideoIdFromApiSearch,
  convertMultipleVideoFromApi,
  convertChannelFromApi,
  convertVideoIdFromApiPlaylistItems
} from 'services/Tv';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';

function VideosChannelApi(executor: ApiExecutorType) {
  return { fetchSearch, fetchYoutubeVideoFromIds, fetchChannel, fetchPlaylistItems };

  function fetchChannel(idChannel: string) {
    return executor
      .get(`channels?part=contentDetails&id=${idChannel}&key=${YOUTUBE_ACCESS_TOKEN}`)
      .then(convertChannelFromApi);
  }

  function fetchPlaylistItems(idChannelUploads: string, maxResults: number = 50, nextPageTokenVideos?: ?string) {
    let request = `playlistItems?part=snippet&key=${YOUTUBE_ACCESS_TOKEN}&playlistId=${idChannelUploads}&maxResults=${maxResults}`;
    if (nextPageTokenVideos && nextPageTokenVideos !== '') {
      request = request.concat(`&pageToken=${nextPageTokenVideos}`);
    }
    return executor.get(request).then(convertVideoIdFromApiPlaylistItems);
  }

  function fetchSearch(idChannel: string, maxResults: number = 50, nextPageTokenVideos?: string) {
    let request = `search?part=id&key=${YOUTUBE_ACCESS_TOKEN}&channelId=${idChannel}&type=video&maxResults=${maxResults}&order=date`;
    if (nextPageTokenVideos) {
      request = request.concat(`&pageToken=${nextPageTokenVideos}`);
    }
    return executor.get(request).then(convertVideoIdFromApiSearch);
  }

  function fetchYoutubeVideoFromIds(videoIds: Array<string>) {
    return executor
      .get(`videos?key=${YOUTUBE_ACCESS_TOKEN}&part=contentDetails,snippet&id=${videoIds.join()}`)
      .then(convertMultipleVideoFromApi);
  }
}

export default VideosChannelApi;
