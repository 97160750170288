// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';
import type { YoutubeVideoType } from 'types/PageList';
import type { SEOMetaType } from 'types/SEOType';
import type { FavNewsType } from 'types/User';
import { nullSeoMeta } from 'types/SEOType';

export type MatchSimple = {
  adversaire: string,
  reception: boolean,
  france_logo: string,
  adversaire_logo: string,
  france_score: number,
  adversaire_score: number,
  date_rencontre: string,
  link: string
};

export type ClubJoueur = {
  age: string,
  club: string,
  embleme: string,
  link: string,
  saisons: string
};

export type EquipeJoueur = {
  id: number,
  title: string,
  link: string,
  matchs: number[],
  essais: number,
  transformations: number,
  penalites: number,
  drops: number,
  type_rugby: string,
  classe_age: string,
  afficher_stats: boolean,
  matchs_joues: MatchSimple[],
  competitions_jouees: number[]
};

export type JoueurType = SEOMetaType & {
  id: number,
  slug: string,
  title: string,
  link: string,
  content: BlocContent[],
  numInternational: number,
  poids: string,
  taille: string,
  club_formateur: string,
  poste: { id: number, name: string, ordre: number },
  age: number,
  dateNaissance: string,
  genre: string,
  logo: string,
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  sidebar: BlocContent[],
  nom: string,
  prenom: string,
  annee_premiere_selection: number,
  nombre_selections: number,
  selections_origine: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  matchs_joues: MatchSimple[],
  competitions_jouees: number[],
  historique: ClubJoueur[],
  equipes: EquipeJoueur[],
  joueurs_favs_news: FavNewsType[],
  joueurs_tags_names: string[],
  videos: YoutubeVideoType[],
  allMatches: number[]
};

export type RawJoueurType = SEOMetaType & {
  id: number,
  date: string,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: any[]
  },
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: any[]
  },
  template: string,
  meta: {
    age: number,
    nom: string,
    prenom: string,
    numInternational: number,
    dateNaissance: string,
    poids: string,
    taille: string,
    clubFormateur: string,
    genre: string,
    match: number[],
    selections_origine: number
  },
  historique: ClubJoueur[],
  poste: { id: number, name: string, ordre: number },
  annee_premiere_selection: number,
  nombre_selections: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  sidebar_content: {
    data: BlocContent[]
  },
  matchs_joues: MatchSimple[],

  competitions_jouees: number[],
  equipes: EquipeJoueur[],
  joueurs_favs_news: FavNewsType[],
  joueurs_tags_names: string[],
  youtube_playlist: YoutubeVideoType[]
};

export type JoueurFiltersSearchType = {
  competitions: number[],
  equipe: string,
  poste: number[],
  season?: number | null
};

export const nullFeatureMediaType = {
  featured_media: {
    src: '',
    legend: '',
    sizes: {}
  }
};

export const nullJoueur: JoueurType = {
  ...nullSeoMeta,
  title: 'Chargement',
  poste: { id: 0, name: '', ordre: 0 },
  slug: '',
  age: 0,
  dateNaissance: '',
  genre: '',
  numInternational: 0,
  poids: '',
  taille: '',
  club_formateur: '',
  content: [],
  logo: '',
  link: '',
  featured_media: {
    src: '',
    legend: '',
    sizes: {}
  },
  historique: [],
  nom: '',
  prenom: '',
  annee_premiere_selection: 0,
  nombre_selections: 0,
  selections_origine: 0,
  nombre_transformations: 0,
  nombre_penalites: 0,
  nombre_essais: 0,
  nombre_drops: 0,
  matchs_joues: [],
  competitions_jouees: [],
  equipes: [],
  joueurs_favs_news: [],
  joueurs_tags_names: [],
  videos: [],
  sidebar: [],
  id: 0,
  allMatches: []
};

export const nullPlayersFiltersSearch: JoueurFiltersSearchType = {
  competitions: [],
  equipe: '',
  poste: []
};

export type JoueurAgregateurType = {
  statistique: {
    pointsMarques: number,
    matchsJoues: number,
    essaisMarques: number,
    penalitesMarques: number,
    transformations: number,
    dropsMarques: number
  }
};

export const nullJoueurAgregateur = {
  statistique: {
    pointsMarques: -1,
    matchsJoues: -1,
    essaisMarques: -1,
    penalitesMarques: -1,
    transformations: -1,
    dropsMarques: -1
  }
};
