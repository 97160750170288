export const TYPE_TERRITORIAL = 'territoriale';
export const TYPE_FEDERAL = 'federale';
export const FAMILLE_TEXT = [{ type: 'federale', text: 'Federales' }, { type: 'territoriale', text: 'Territoriales' }];
export const AGE_TEXT = [
  { type: '-19', text: 'Juniors' },
  { type: '-18', text: 'Juniors' },
  { type: '+18', text: 'Seniors' },
  { type: '-16', text: 'Cadets' }
];
export const SEXE_TEXT = [{ type: 'F', text: ' Féminines' }, { type: 'M', text: '' }];
