// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertJoueurFromApi } from 'services/Joueur';
import { convertObjectToQueryString } from 'utils/urlUtils';

function JoueurApi(executor: ApiExecutorType) {
  return {
    fetchJoueurBySlug,
    fetchJoueursTeam,
    fetchJoueurByName
  };

  function fetchJoueurBySlug(slug: string) {
    return executor
      .get(`/ffr/v1/joueurs?slug=${slug}&per_page=1`)
      .then(response => {
        if (!response || response.length === 0) {
          throw new Error('NOT FOUND');
        }
        return convertJoueurFromApi(response[0]);
      });
  }

  function fetchJoueurByName(name: string) {
    return executor
      .get(`/ffr/v1/joueurs?search=${name}&per_page=1`)
      .then(response => convertJoueurFromApi(response[0]));
  }

  function fetchJoueursTeam(filters?: Object) {
    if (filters && filters.poste && filters.poste.length > 0) {
      filters.poste = [];
    }
    if (filters && filters.saison) {
      delete filters.saison;
    }
    let url = `/ffr/v1/joueurs${convertObjectToQueryString({ ...filters, per_page: 200})}`;
    return executor
      .get(url)
      .then(response => response.map(a => convertJoueurFromApi(a)));
  }
}

export default JoueurApi;
