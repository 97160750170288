// @flow

export const FILTER_OPTIONS_SET_QUERY = 'FILTER_OPTIONS_SET_QUERY';
export const FILTER_OPTIONS_SET_QUERYCENTENAIRE = 'FILTER_OPTIONS_SET_QUERYCENTENAIRE';
export const FILTER_OPTIONS_SET_COMPETITON = 'FILTER_OPTIONS_SET_COMPETITON';
export const FILTER_OPTIONS_SET_PRACTICE = 'FILTER_OPTIONS_SET_PRACTICE';
export const FILTER_OPTIONS_SET_DISTANCE = 'FILTER_OPTIONS_SET_DISTANCE';
export const FILTER_OPTIONS_SET_ANNEE = 'FILTER_OPTIONS_SET_ANNEE';
export const FILTER_OPTIONS_SET_EQUIPE = 'FILTER_OPTIONS_SET_EQUIPE';
export const FILTER_OPTIONS_SET_NIVEAU = 'FILTER_OPTIONS_SET_NIVEAU';
export const FILTER_OPTIONS_ACTIVATE_DISTANCE = 'FILTER_OPTIONS_ACTIVATE_DISTANCE';
export const REINITIALISE_SEARCH = 'REINITIALISE_SEARCH';
export const FILTER_OPTIONS_SEARCH_PLAYER = 'FILTER_OPTIONS_SEARCH_PLAYER';
export const FILTER_OPTIONS_SEARCH_STAFF = 'FILTER_OPTIONS_SEARCH_STAFF';
export const FILTER_OPTIONS_SET_DEFAULT_SEASON = 'FILTER_OPTIONS_SET_DEFAULT_SEASON';
export const FILTER_OPTIONS_SET_POSTES = 'FILTER_OPTIONS_SET_POSTES';
export const FILTER_OPTIONS_SET_TYPELOGETITLE = 'FILTER_OPTIONS_SET_TYPELOGETITLE';
export const FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION = 'FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION';
export const FILTER_OPTIONS_SET_TYPELOGESLUG = 'FILTER_OPTIONS_SET_TYPELOGESLUG';
 
export type FILTER_OPTIONS_SET_QUERY_ACTION = {
  type: 'FILTER_OPTIONS_SET_QUERY',
  payload: {
    query: string
  }
};

export type FILTER_OPTIONS_SET_QUERYCENTENAIRE_ACTION = {
  type: 'FILTER_OPTIONS_SET_QUERYCENTENAIRE',
  payload: {
    queryCentenaire: string
  }
};

export type FILTER_OPTIONS_SET_COMPETITION_ACTION = {
  type: 'FILTER_OPTIONS_SET_COMPETITON',
  payload: {
    competitions: Array<string>
  }
};

export type FILTER_OPTIONS_SET_PRACTICE_ACTION = {
  type: 'FILTER_OPTIONS_SET_PRACTICE',
  payload: {
    practices: Array<string>
  }
};

export type FILTER_OPTIONS_SET_DISTANCE_ACTION = {
  type: 'FILTER_OPTIONS_SET_DISTANCE',
  payload: {
    distance: string
  }
};

export type FILTER_OPTIONS_SET_ANNEE_ACTION = {
  type: 'FILTER_OPTIONS_SET_ANNEE',
  payload: {
    annees: Array<string>
  }
};
export type FILTER_OPTIONS_SET_EQUIPE_ACTION = {
  type: 'FILTER_OPTIONS_SET_EQUIPE',
  payload: {
    equipes: Array<string>
  }
};
export type FILTER_OPTIONS_SET_NIVEAU_ACTION = {
  type: 'FILTER_OPTIONS_SET_NIVEAU',
  payload: {
    niveaux: Array<string>
  }
};
export type REINITIALISE_SEARCH_ACTION = {
  type: 'REINITIALISE_SEARCH',
};

export type FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION = {
  type: 'FILTER_OPTIONS_ACTIVATE_DISTANCE',
  payload: {
    activateDistance: boolean
  }
};

export type FILTER_OPTIONS_SEARCH_PLAYER_ACTION = {
  type: 'FILTER_OPTIONS_SEARCH_PLAYER',
  payload: {
    searchedPlayer: string
  }
};

export type FILTER_OPTIONS_SEARCH_STAFF_ACTION = {
  type: 'FILTER_OPTIONS_SEARCH_STAFF',
  payload: {
    searchedStaff: string
  }
};

export type FILTER_OPTIONS_SET_DEFAULT_SEASON_ACTION = {
  type: 'FILTER_OPTIONS_SET_DEFAULT_SEASON',
  payload: {
    defaultSeason: string
  }
};

export type FILTER_OPTIONS_SET_POSTES_ACTION = {
  type: 'FILTER_OPTIONS_SET_POSTES',
  payload : {
    poste: Array<string>
  }
};

export type FILTER_OPTIONS_SET_TYPELOGETITLE_ACTION = {
  type: 'FILTER_OPTIONS_SET_TYPELOGETITLE',
  payload : {
    typeLogeTitle: string
  }
};

export type FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION_ACTION = {
  type: 'FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION',
  payload : {
    typeLogeDescription: string
  }
};

export type FILTER_OPTIONS_SET_TYPELOGESLUG_ACTION = {
  type: 'FILTER_OPTIONS_SET_TYPELOGESLUG',
  payload : {
    typeLogeSlug: string
  }
};

export type Action =
  | FILTER_OPTIONS_SET_QUERY_ACTION
  | FILTER_OPTIONS_SET_QUERYCENTENAIRE_ACTION
  | FILTER_OPTIONS_SET_COMPETITION_ACTION
  | FILTER_OPTIONS_SET_PRACTICE_ACTION
  | FILTER_OPTIONS_SET_DISTANCE_ACTION
  | FILTER_OPTIONS_SET_ANNEE_ACTION
  | FILTER_OPTIONS_SET_EQUIPE_ACTION
  | FILTER_OPTIONS_SET_NIVEAU_ACTION
  | FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION
  | REINITIALISE_SEARCH_ACTION
  | FILTER_OPTIONS_SEARCH_PLAYER_ACTION
  | FILTER_OPTIONS_SEARCH_STAFF_ACTION
  | FILTER_OPTIONS_SET_DEFAULT_SEASON_ACTION
  | FILTER_OPTIONS_SET_POSTES_ACTION
  | FILTER_OPTIONS_SET_POSTES_ACTION
  | FILTER_OPTIONS_SET_TYPELOGETITLE_ACTION
  | FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION_ACTION
  | FILTER_OPTIONS_SET_TYPELOGESLUG_ACTION;

export function setQuery(query: string): FILTER_OPTIONS_SET_QUERY_ACTION {
  return {
    type: FILTER_OPTIONS_SET_QUERY,
    payload: { query }
  };
}

export function setQueryCentenaire(queryCentenaire: string): FILTER_OPTIONS_SET_QUERYCENTENAIRE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_QUERYCENTENAIRE,
    payload: { queryCentenaire }
  };
}

export function setCompetitions(competitions: Array<string>): FILTER_OPTIONS_SET_COMPETITION_ACTION {
  return {
    type: FILTER_OPTIONS_SET_COMPETITON,
    payload: { competitions }
  };
}

export function setPractices(practices: Array<string>): FILTER_OPTIONS_SET_PRACTICE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_PRACTICE,
    payload: { practices }
  };
}

export function setDistance(distance: string): FILTER_OPTIONS_SET_DISTANCE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_DISTANCE,
    payload: { distance }
  };
}

export function setAnnees(annees: Array<string>): FILTER_OPTIONS_SET_ANNEE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_ANNEE,
    payload: { annees }
  };
}

export function setEquipes(equipes: Array<string>): FILTER_OPTIONS_SET_EQUIPE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_EQUIPE,
    payload: { equipes }
  };
}

export function setNiveaux(niveaux: Array<string>): FILTER_OPTIONS_SET_NIVEAU_ACTION {
  return {
    type: FILTER_OPTIONS_SET_NIVEAU, 
    payload: { niveaux }
  };
}

export function reinitialiseSearch(): REINITIALISE_SEARCH_ACTION {
  return {
    type: REINITIALISE_SEARCH
  };
}

export function activateDistance(): FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION {
  return {
    type: FILTER_OPTIONS_ACTIVATE_DISTANCE,
    payload: {
      activateDistance: true
    }
  };
}

export function deactivateDistance(): FILTER_OPTIONS_ACTIVATE_DISTANCE_ACTION {
  return {
    type: FILTER_OPTIONS_ACTIVATE_DISTANCE,
    payload: {
      activateDistance: false
    }
  };
}

export function searchPlayer(searchedPlayer: string): FILTER_OPTIONS_SEARCH_PLAYER_ACTION {
  return {
    type: FILTER_OPTIONS_SEARCH_PLAYER,
    payload: { searchedPlayer }
  };
}

export function searchStaff(searchedStaff: string): FILTER_OPTIONS_SEARCH_STAFF_ACTION {
  return {
    type: FILTER_OPTIONS_SEARCH_STAFF,
    payload: { searchedStaff }
  };
}

export function setDefaultSeason(defaultSeason: string): FILTER_OPTIONS_SET_DEFAULT_SEASON_ACTION {
  return {
    type: FILTER_OPTIONS_SET_DEFAULT_SEASON,
    payload: { defaultSeason }
  };
}

export function selectPostes(poste: Array<string>): FILTER_OPTIONS_SET_POSTES_ACTION {
  return {
    type: FILTER_OPTIONS_SET_POSTES,
    payload: { poste }
  };
}

export function setTypeLogeTitle(typeLogeTitle: string): FILTER_OPTIONS_SET_TYPELOGETITLE_ACTION {
  return {
    type: FILTER_OPTIONS_SET_TYPELOGETITLE,
    payload: { typeLogeTitle }
  };
}

export function setTypeLogeDescription(typeLogeDescription: string): FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION_ACTION {
  return {
    type: FILTER_OPTIONS_SET_TYPELOGEDESCRIPTION,
    payload: { typeLogeDescription }
  };
}

export function setTypeLogeSlug(typeLogeSlug: string): FILTER_OPTIONS_SET_TYPELOGESLUG_ACTION {
  return {
    type: FILTER_OPTIONS_SET_TYPELOGESLUG,
    payload: { typeLogeSlug }
  };
}