// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';
import type { CardType } from 'types/PageList';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type PageChildType = {
  menu_order: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  type_name: string,
  description?: string,
  featured_media?: {
    src: string,
  },
  texte_cta?: string,
  liste_enfants?: Array<CardType>,
}
export type PageNodeType = SEOMetaType & {
  id: number,
  title: string,
  titleParent: string,
  description: string,
  sidebar_content: Array<BlocContent>,
  content: Array<BlocContent>,
  slug_parent: string,
  slug: string,
  parent: number,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string,
    size: SourceSetType
  },
  liste_enfants: Array<PageChildType>,
  template: string,
  niveau: number,
  redirection: string,
};

export type RawPageNodeType = SEOMetaType & {
  id: number,
  title: {
    raw: string
  },
  title_parent: string,
  parent: number,
  excerpt: {
    raw: string
  },
  sidebar_content: {
    data: Array<BlocContent>
  },
  content: {
    data: Array<BlocContent>
  },
  slug: string,
  slug_parent: string,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string,
    size: SourceSetType
  },
  liste_enfants: Array<PageChildType>,
  template: string,
  niveau: number,
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  }
};

export const nullPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: '',
  titleParent: '',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: '',
    size: {}
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: '',
      liste_enfants: [],
    }
  ],
  template: '',
  niveau: 0,
  redirection: '',
};

export const loadingPageNode: PageNodeType = {
  ...nullSeoMeta,
  id: 0,
  title: 'Chargement',
  titleParent: 'Chargement',
  description: '',
  content: [],
  sidebar_content: [],
  parent: 0,
  slug_parent: '',
  slug: '',
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: '',
    size: {}
  },
  liste_enfants: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_media: {
        src: ''
      },
      texte_cta: ''
    }
  ],
  template: '',
  niveau: 2,
  redirection: '',
};
