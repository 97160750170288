// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  convertFormRematchResponseFromApi,
} from 'services/Rematch';

function rematchFormApi(executor: ApiExecutorType) {
  return { postFormRematch, fetchRematch };

  function postFormRematch(formInputs: any) {
    return executor
      .post(`/ffr/v1/rematch`, formInputs, {
        headers: {
          'Content-type': 'multipart/form-data'
        },
        timeout: 600000
      })
      .then(response => convertFormRematchResponseFromApi(response));
  }
  function fetchRematch() {
    return executor
      .get(`/ffr/v1/rematch`)
      .then(response => {return response;});
  }
}
export default rematchFormApi;
