// @flow
import LogeSuccessModalContent from 'components/fragments/modal/modalContents/LogeSuccessModalContent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/modal/modalContents/LogeSuccessModalContent';

export default connect((state: StateType): StateProps => ({
  acceptCookie: state.appState.acceptCookie
}))(LogeSuccessModalContent);
