/**
 * Numberly Module
 *
 */

//
let initialized = false;
let debug = false;

/**
 * Utilities
 */

const verifyInit = () => {
  if (typeof window._troq === 'function') {
    initialized = true;
  }

  if (!initialized) {
    console.warn('numberly not initialized before using call Numberly.init with required params');
  }

  return initialized;
};

//
const log = (...args) => {
  console.info(...['[react-adnxs-numberly]'].concat(args));
};

const defaultOptions = {
  debug: false
};

// eslint-disable-next-line consistent-return
function init(numberlyId, event, options = defaultOptions) {
  if (!numberlyId) {
    return console.warn('Please insert numberly ID for initializing');
  }

  /* eslint-disable */
  (function(d, e, f, g, a, b, c) {
    d._troq ||
      ((a = d._troq = function() {
        a.callTro ? a.callTro.apply(a, arguments) : a.queue.push(arguments);
      }),
      (a.push = a),
      (a.queue = []),
      (a.version = '1.0'),
      (b = e.createElement(f)),
      (b.async = !0),
      (b.src = g),
      (c = e.getElementsByTagName(f)[0]),
      c.parentNode.insertBefore(b, c));
  })(window, document, 'script', 'https://mmtro.com/tro.js');

  _troq('session_ttl', 1800);
  _troq('init', numberlyId);
  /* eslint-enable */
}

function tag(event) {
  if (!verifyInit()) {
    return;
  }

  if (debug) {
    log("called numberly('event', 'PageView');");
    return;
  }

  // tag
  window._troq('tag', event);
}

export default {
  init,
  tag
};
