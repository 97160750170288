// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
// import type { JoueursearchType } from 'types/Joueursearch';
// import { convertJoueursearchFromApi } from 'services/Joueursearch';

function joueursearchApi(executor: ApiExecutorType) {
  return { getJoueursearchApi };

  function getJoueursearchApi() {
    return executor.get('joueursearch.json').then(response => {
      return {
        joueursearch: response
      };
    });
  }
}

export default joueursearchApi;
