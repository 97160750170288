// @flow
import React, { PureComponent } from 'react';
import type { BlocFaq, ClubSemaine } from 'types/Settings';
import type { Status } from 'types/Status';
import type { FullUserType } from 'types/User';
import ClubSearch from 'components/club/ClubSearch';
import { dynamicClassName } from 'utils/dynamicClassName';
import LazyLoad from 'components/fragments/LazyLoad';
import CardThumbnail from 'components/fragments/card/CardThumbnail';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { Link, Redirect } from 'react-router-dom';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  blocs_trouver_club: BlocFaq[],
  clubsSemaine: ClubSemaine[],
  featured_media: Object,
  pratiques: string[],
  pratiques_ok: string[],
  searchCities: string[],
  searchClubs: { logo: string, name: string }[],
  searchTypes: string[],
  status: Status,
  userPref: FullUserType
};

type Props = StateProps & {
  private_account?: boolean,
};

type State = {
  inputFocused: boolean,
  redirect: boolean,
  rugbyTypes: string
};

class ClubFinderHub extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    inputFocused: false,
    redirect: false,
    rugbyTypes: ''
  };

  renderSearched(content: Array<string>): any {
    return content.map((search, index) => (
      <li key={index}>
        <Link className="label label--rounded label--blue-dark" to="#">
          {search}
        </Link>
      </li>
    ));
  }

  renderSearchedPracticies(content: Array<{value:string, label:string}>): any {
    const { pratiques_ok, private_account, userPref } = this.props;

    let rugbyTypes = content.filter((pratique) => {
      return pratiques_ok.indexOf(pratique.value) > -1;
    }).sort(function(a, b) {
      let x = a.value.toLowerCase();
      let y = b.value.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    if (private_account === true) {
      rugbyTypes = rugbyTypes.slice(0, 8);
    }
    
    return rugbyTypes.map((search, index) => (
      <li key={index}>
        <button
          className="label label--rounded label--blue-dark"
          onClick={handleTagEvent(
            'recherche geolocalisee',
            'filtre rugby',
            search.label,
            userPref,
            this.handleClickPracticies(search.label)
          )}>
          {search.label}
        </button>
      </li>
    ));
  }

  renderClubsSemaine() {
    return this.props.clubsSemaine.map<any>((club, index) => (
      <li key={index}>
        <Link className="link-club-alt " to={club.slug_complet}>
          <img src={club.embleme} height="24" width="24" alt="Logo Avant Garde Laique de Fougeres"></img>
          {club.title}
        </Link>
      </li>
    ));
  }

  renderSearchedClubs(): any {
    return this.props.searchClubs.map<any>((club, index) => (
      <li key={index}>
        <Link className="link-club " to="#">
          <img src={club.logo} alt={club.name} height="32" width="32" />
          {club.name}
        </Link>
      </li>
    ));
  }

  renderSearchedClub(content: Array<string>): any {
    return content.map((search, index) => (
      <li key={index}>
        <Link className="label label--rounded label--khaki" to="#">
          {search}
        </Link>
      </li>
    ));
  }

  renderForm = () => {
    const { userPref, private_account } = this.props;
    const classNamesInput = dynamicClassName('search-club row');
    this.state.inputFocused && classNamesInput.add('has-autocompletion');
    const practices = this.props.pratiques.map(p => ({ value: p, label: p }));

    return (
      <form action="trouver-un-club-result.html" className={classNamesInput.build()}>
        <div className="col col-lg-6">
          <h2 className="ft-h2">Trouver un club</h2>
          <p className="banner__desc mb-5 mb-md-4">
            Retrouvez les clubs les plus proches de chez vous, filtrés par pratique, nombre de licenciés, horaires,
            compétitions et apprenez en plus sur votre futur club !
          </p>
          <ClubSearch
            handleInputClicked={this.handleInputClicked}
            handleClickOutside={this.handleClickOutside}
            geolocalisation={false}
            userPref={userPref}
          />
          <div className="search-club__type">
            <p className="ft-h3 ft-400 mb-2">Types de rugby</p>
            <ul className="list-label mb-2">{this.renderSearchedPracticies(practices)}</ul>
          </div>
        </div>
        { // club of the week is not displayed in private account dashboard (Profile/DashboardHome)
          private_account !== true && (
            <div className="col col-lg-4 col-lg-offset-2">
              <div className="search-club__club">
                <p className="ft-h3 ft-400 mb-2">Les clubs de la semaine</p>
                <ul className="list-label mb-0">{this.renderClubsSemaine()}</ul>
              </div>
            </div>
          )
        }
      </form>
    );
  };

  handleClickPracticies = (value: string) => {
    return () => {
      this.setState({
        redirect: true,
        rugbyTypes: value
      });
    };
  }

  handleInputClicked = () => {
    this.setState({ inputFocused: true });
  };

  handleClickOutside = () => {
    this.setState({ inputFocused: false });
  };

  renderCardThumbnail = () => {
    const { blocs_trouver_club } = this.props;

    return blocs_trouver_club.map<any>((thumbnail, index) => {
      let url = '';
      if (thumbnail.parent_slugs[1]) {
        url = `${thumbnail.parent_slugs[1]}/`;
      }
      if (thumbnail.parent_slugs[2]) {
        url = `${url}${thumbnail.parent_slugs[2]}/`;
      }
      url = `${url}${thumbnail.object_slug}`;
      return (
        <div key={index} className="col col-md-4 mb-2 mb-md-0">
          <CardThumbnail
            url={`/${url}`}
            backgroundUrl={thumbnail.featured_image}
            title={thumbnail.title}
            desc={thumbnail.description}
            linkLabel={thumbnail.texte_cta} />
        </div>
      );
    });
  };

  render() {
    //if (this.props.status !== STATUS_SUCCESS) {
    //  return <Loading isBlue backgroundColor={'#013974'}/>;
    //}
    if (this.state.redirect)
      return (
        <Redirect
          to={{
            pathname: '/trouver-un-club-de-rugby/resultats-de-recherche/',
            state: {
              rugbyTypes: this.state.rugbyTypes
            }
          }}
        />
      );
    return (
      <>
         <LazyLoad>
          <section className="section section--bg  club-finder-hub__section">
            <div className="container">
              {this.renderForm()}
            </div>
            <BackgroundImage
              className="img-bg img-blue-dark"
              illustration={this.props.featured_media && this.props.featured_media.src}
              srcset={this.props.featured_media && this.props.featured_media.sizes}
              forceState={'large'}
            />
          </section>
        </LazyLoad>
      </>
    );
  }
}

export default ClubFinderHub;