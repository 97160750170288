// @flow
import React, { PureComponent } from 'react';


import competitionPlaceholder from 'assets/img/placeholder-competition.png';
import { COMPETITION_BASE_URL } from 'constants/profile';
import { shuffleArray } from 'utils/arrayUtils';
import { usePlaceholderOnImageError } from 'utils/assetsUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';

import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType,
  format?: string,
  cta?: string,
};

export default class MyFavoriteClubs extends PureComponent<StateProps> {
  renderFavoriteClubs = () => {
    const { userPref: {competitions_clubs_favoris: clubs} } = this.props;

    const shuffledClubs = shuffleArray(clubs);

    return shuffledClubs.slice(0, 3).map<any>(({structure: { nom, identifiant }}, i) => {
      return (
        <div className="col-md-4 logo-club" key={`favorite_club_${i}`}>
            <a
            href={`https://competitions.ffr.fr/clubs/${identifiant}`}
            target='_blank'
            rel='noreferrer noopener'
            title={nom}
            >
              <img
                id={identifiant}
                src={`${COMPETITION_BASE_URL}/logo/${identifiant}.jpeg`}
                alt={`Logo ${nom}`}
                onError={(e) => usePlaceholderOnImageError(e.target, '/img/logo/placeholder-club.png')}
              />
              <p className="ft-truncat">{nom}</p>
            </a>
        </div>
      );
    });
  }

  render() {
    const { userPref, userPref: { competitions_clubs_favoris }, format } = this.props;
    const divClassName = dynamicClassName('box box--white card card--profile card--favoris');

    if (format === 'small') {
      divClassName.add('card--favoris-small');
    } else {
      divClassName.add('card--favoris-normal');
    }

    return (
      <>
        <div className='row mb-7'>
          <div className='col'>
            <div className={divClassName.build()}>
              <div className="col-md-5 bloc-clubs-favoris-right">
                  <div className="card__header clubs-favoris-title">
                    <h2 className="ft-h4 ft-up ft-black">Mes clubs favoris</h2>
                  </div>
                  <div className="card__body card__desc">
                    {
                      competitions_clubs_favoris.length > 0 
                        ? <p>Accédez aux résultats des derniers matchs, classement et calendriers de vos clubs favoris.</p>
                        : <p>Recherchez votre club favoris, accédez aux résultats des derniers matchs, classement et calendrier. Inscrivez-vous ou liez votre compte Compétitions pour y accéder depuis votre espace perso.</p>
                    }
                    
                  </div>
                  <div className="card__footer ft-center">
                    <a
                      href='https://competitions.ffr.fr/'
                      title='Accéder au site au Compétitions FFR'
                      target='_blank'
                      rel='noreferrer noopener'
                      onClick={handleTagEvent(
                        'espace perso',
                        'clic_module_compétitions',
                        '',
                        userPref
                      )}
                    >
                      <span className="link-chevron link-chevron--blue">
                        Accéder au site Compétitons
                        <i className="icon icon-chevron-right" />
                      </span>
                    </a>
                    {
                      competitions_clubs_favoris.length > 0 
                        && (
                          <a
                            href='https://competitions.ffr.fr/compte'
                            title='Accéder à mon compte Compétitions FFR'
                            target='_blank'
                            rel='noreferrer noopener'
                            onClick={handleTagEvent(
                              'espace perso',
                              'clic_gestion_notifs',
                              '',
                              userPref
                            )}
                          >
                            <span className="link-chevron link-chevron--blue">
                              Gérer mes notifications
                              <i className="icon icon-chevron-right" />
                            </span>
                          </a>
                        )

                    }
                  </div>
              </div>
              <div className="col-md-7 clubs-favoris">
                {
                  competitions_clubs_favoris.length > 0 
                    ? this.renderFavoriteClubs()
                    : (
                      <img
                        src={competitionPlaceholder}
                        alt="competition placeholder"
                        className="competition__placeholder"
                      />
                    )
                }
              </div> 
            </div>
          </div>
        </div>
      </>
    );
  }
}
