// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  FETCH_YOUTUBE_VIDEOS_CHANNEL_START,
  FETCH_YOUTUBE_VIDEO_CHANNEL_START,
  VideosChannelFetched,
  VideosChannelFetchError,
  VideoChannelFetched,
  VideoChannelFetchError,
  type FETCH_YOUTUBE_VIDEOS_CHANNEL_START_ACTION,
  type FETCH_YOUTUBE_VIDEO_CHANNEL_START_ACTION
} from 'actions/ffr-tv/youtubeVideosChannelActions';

import {
  FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START,
  moreVideosChannelFetched,
  moreVideosChannelFetchError,
  type FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START_ACTION
} from 'actions/ffr-tv/youtubeMoreVideosChannelActions';

import VideosChannelApi from 'api/ffrtv/VideosChannelApi';

export default function(apiExecutor: ApiExecutorType) {
  const videosChannelApi = new VideosChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_VIDEO_CHANNEL_START, fetchYoutubeVideoChannel);
    yield takeLatest(FETCH_YOUTUBE_VIDEOS_CHANNEL_START, fetchYoutubeVideosChannel);
    yield takeLatest(FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START, fetchMoreYoutubeVideosChannel);
  };

  function* fetchYoutubeVideoChannel(action: FETCH_YOUTUBE_VIDEO_CHANNEL_START_ACTION): Saga<void> {
    try {
      const {
        payload: { idChannel }
      } = action;
      const rawYoutubeChannel = yield call(videosChannelApi.fetchChannel, idChannel);
      const idChannelUploads = rawYoutubeChannel.items[0].contentDetails.relatedPlaylists.uploads;
      const rawPlaylistItems = yield call(videosChannelApi.fetchPlaylistItems, idChannelUploads, 1);
      const firstvideoId = rawPlaylistItems.youtubePlaylistItem[0].id;
      const videoTab = yield call(videosChannelApi.fetchYoutubeVideoFromIds, [firstvideoId]);
      yield put(VideoChannelFetched(videoTab[0], idChannelUploads));
    } catch (e) {
      yield put(VideoChannelFetchError());
    }
  }

  function* fetchYoutubeVideosChannel(action: FETCH_YOUTUBE_VIDEOS_CHANNEL_START_ACTION): Saga<void> {
    try {
      const {
        payload: { idChannel, firstvideoId }
      } = action;

      const playlistItems = [];
      let nextPageTokenPlaylistItems = null;
      let isFirst = true;
      while (
        (nextPageTokenPlaylistItems !== null &&
          nextPageTokenPlaylistItems !== undefined &&
          nextPageTokenPlaylistItems !== '') ||
        isFirst
      ) {
        isFirst = false;
        const rawPlaylistItems = yield call(
          videosChannelApi.fetchPlaylistItems,
          idChannel,
          50,
          nextPageTokenPlaylistItems
        );

        nextPageTokenPlaylistItems = rawPlaylistItems.nextPageToken;
        const newPlaylistItems = rawPlaylistItems.youtubePlaylistItem.filter(item => item.id !== firstvideoId);
        playlistItems.push(...newPlaylistItems);
      }
      yield put(VideosChannelFetched(playlistItems));
    } catch (e) {
      yield put(VideosChannelFetchError());
    }
  }

  function* fetchMoreYoutubeVideosChannel(action: FETCH_MORE_YOUTUBE_VIDEOS_CHANNEL_START_ACTION): Saga<void> {
    const {
      payload: { playlistItems, returnInitialState }
    } = action;
    try {
      const listvideoId = playlistItems.map(item => item.id);
      const listvideos = yield call(videosChannelApi.fetchYoutubeVideoFromIds, listvideoId);
      yield put(moreVideosChannelFetched(listvideos, returnInitialState));
    } catch (e) {
      yield put(moreVideosChannelFetchError());
    }
  }
}
