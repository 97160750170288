// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import PlaylistsChannelApi from 'api/ffrtv/PlaylistsChannelApi';

import {
  FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START,
  playlistsChannelFetched,
  playlistsChannelFetchError,
  type FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START_ACTION
} from 'actions/ffr-tv/youtubePlaylistsChannelActions';

export default function(apiExecutor: ApiExecutorType) {
  const playlistsChannelApi = new PlaylistsChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START, fetchPlaylistsChannel);
  };

  function* fetchPlaylistsChannel(action: FETCH_YOUTUBE_PLAYLISTS_CHANNEL_START_ACTION): Saga<void> {
    const {
      payload: { idChannel }
    } = action;
    try {
      const playlists = [];
      let nextPageTokenPlaylists = null;
      let isFirst = true;
      while (
        (nextPageTokenPlaylists !== null && nextPageTokenPlaylists !== undefined && nextPageTokenPlaylists !== '') ||
        isFirst
      ) {
        isFirst = false;
        const rawPlaylists = yield call(
          playlistsChannelApi.fetchPlaylistsChannel,
          idChannel,
          50,
          nextPageTokenPlaylists
        );

        nextPageTokenPlaylists = rawPlaylists.nextPageToken;
        playlists.push(...rawPlaylists.items);
      }

      yield put(playlistsChannelFetched(playlists));
    } catch (e) {
      yield put(playlistsChannelFetchError());
    }
  }
}
