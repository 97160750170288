// @flow
import type { rawMatchResultType, MatchResultType } from 'types/Match';
import { nullMatchResult } from 'types/Match';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertMatchResultFromApi(rawMatchResult: rawMatchResultType): MatchResultType {
  if (!rawMatchResult) {
    return nullMatchResult;
  }

  const {
    id,
    slug,
    link,
    featured_media,
    meta,
    joueurs,
    competition,
    meta_title,
    meta_description
  } = rawMatchResult;

  return mergeIntoDefault(nullMatchResult, {
    id: id,
    slug: slug,
    link: link,
    featured_media:featured_media,
    meta: meta,
    joueurs: joueurs,
    competition: competition,
    meta_title: meta_title,
    meta_description: meta_description
  });
}
