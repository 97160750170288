// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_YOUTUBE_VIDEOS_PLAYLIST_START = 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_START';
export const FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS = 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS';
export const FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE = 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE';

export type FETCH_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_START',
  payload: { idPlaylist: string }
};

export type FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>,
    nextPageToken: string
  }
};

export type FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION = {
  type: 'FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE'
};

export type Action =
  | FETCH_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION
  | FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION
  | FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION;

export function fetchVideosPlaylist(idPlaylist: string): FETCH_YOUTUBE_VIDEOS_PLAYLIST_START_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLIST_START,
    payload: {
      idPlaylist
    }
  };
}

export function VideosPlaylistFetched(
  videos: Array<YoutubeVideo>,
  nextPageToken: string
): FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLIST_SUCCESS,
    payload: { videos, nextPageToken }
  };
}

export function VideosPlaylistFetchError(): FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE_ACTION {
  return {
    type: FETCH_YOUTUBE_VIDEOS_PLAYLIST_FAILURE
  };
}
