// @flow
import React from 'react';
import type { ArticleType } from 'types/Article';
import type { FullUserType } from 'types/User';
import { dynamicClassName } from 'utils/dynamicClassName';
import { formatFullDate } from 'utils/dateUtils';
import { Link } from 'react-router-dom';
import { getDateFromString } from 'utils/dateUtils';
import BackgroundImage from 'components/fragments/BackgroundImage';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  userPref?: FullUserType,
}

type Props = StateProps & {
  content: ArticleType,
  row?: boolean,
  largeRow?: boolean
};

const SideBillboardCard = (props: Props) => {
  const { largeRow, row, userPref } = props;
  const { illustration, title, label, slug, srcset } = props.content;
  const date = getDateFromString(props.content.date);

  const classNamesNewsContainer = dynamicClassName('news');
  largeRow && classNamesNewsContainer.add('news--row-lg');
  row && classNamesNewsContainer.add('news--row');

  return (
    <Link
      to={{
        pathname: `/actualites/${label.slug}/${slug}`,
        state: { article: props.content }
      }}
      title={`Lire la suite de l'article : ${title}`}
      role="button"
      tabIndex={0}
      className={classNamesNewsContainer.build()}
      onClick={handleTagEvent(
        'module_actualite',
        title,
        '',
        userPref
      )}
    >
      {' '}
      <article>
        <span className="border" style={{display:'none'}}></span>
        <BackgroundImage
          className="news__thumbnail img-blue"
          illustration={illustration}
          srcset={srcset}
          forceState={largeRow ? 'thumbnail' : 'medium'} />
        <div>
          <span className="news__title ft-h4">{title}</span>
          <p className="ft-h6">
            <span className="news__cat ft-up">{label.name}</span>
            <span className="news__date">{formatFullDate(date)}</span>
          </p>
        </div>
      </article>
    </Link>
  );
};

export default SideBillboardCard;
