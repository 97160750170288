// @flow
import React from 'react';

import AddAFavoritePlayerModalContent from 'containers/profile/MesFavoris/AddAFavoritePlayerModalContentContainer';

type Props = {
  content: any,
  hideModal: () => void
};

function AddAFavoritePlayerSearchPanelModalContent({ content, hideModal }: Props) {
  if (!content) {
    return null;
  }

  return (
    <div className="modal-content box add-favorite-player-modal modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <AddAFavoritePlayerModalContent hideModal={hideModal} />
      </div>
    </div>
  );
}

export default AddAFavoritePlayerSearchPanelModalContent;
