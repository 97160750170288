/* eslint-disable no-undef */
// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import {
    FETCH_JOUEURSEARCH_START,
    joueursearchFetched,
    joueursearchFetchError,
    type FETCH_JOUEURSEARCH_START_ACTION,
} from 'actions/joueursearchActions';
import joueursearchApi from 'api/joueursearch/joueursearchApi';

import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

export default function(apiExecutor: ApiExecutorType) {
    const joueursearchApiStatic = new joueursearchApi(apiExecutor);

    return function* joueursearchSaga(): GeneratorType {
        yield takeLatest(FETCH_JOUEURSEARCH_START, fetchJoueursearch);
    };

    function* fetchJoueursearch(action: FETCH_JOUEURSEARCH_START_ACTION): Saga<void> {
        try {
            const joueursearch = yield call(joueursearchApiStatic.getJoueursearchApi);
            yield put(joueursearchFetched(joueursearch));
        } catch (e) {
            yield put(joueursearchFetchError(e));
        }
    }
}
