/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow
import React from 'react';
import type { Bloc, BlocContent, Attributes, LinkAttributes } from 'types/NewsletterBloc';
import type { FullUserType } from 'types/User';
import ContentBloc from 'components/newsletter/blocs/ContentBloc';
import { convertCSS } from 'utils/convertUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';

export type StateProps = {
  userPref: FullUserType
};

export type Props = {
  userPref: FullUserType,
  childrens: Array<Bloc | BlocContent>,
  attributes: Attributes[],
  tagName: string
};

export const Component = (props: Props) => {
  const { userPref } = props;
  const { pathname } = window.location;

  if (props.tagName === 'a') {
    const attr = renderLinkAttributes(props.attributes);

    if (!!attr.className && attr.className.includes('card__footer--boutique__liste')) {
      return (
        <a {...attr} onClick={() => handleTagEvent('espace perso', 'clic_boutique', '', userPref)()}>
          {renderElement(props)}
        </a>
      );
    } else if (!!attr.className && attr.className.includes('card--private-push-boutique__ticketing')) {
      return (
        <a {...attr} onClick={handleTagEvent('espace perso', 'clic_billetterie', '', userPref)}>
          {renderElement(props)}
        </a>
      );
    } else if (!!attr.className && attr.className.includes('card--private-push-boutique')) {
      return (
        <a {...attr} onClick={handleTagEvent('espace perso', 'clic_boutique', '', userPref)}>
          {renderElement(props)}
        </a>
      );
    } else if (!!attr.className && attr.className.includes('card--next-match')) {
      const matchName = attr.match_name;

      return (
        <a {...attr} onClick={handleTagEvent('espace perso', 'clic_acheter_billets', matchName, userPref)}>
          {renderElement(props)}
        </a>
      );
    } else if (attr.href && attr.href.includes('mailto') && pathname && pathname.includes('organigramme')) {
      return (
        <a
          {...attr}
          onClick={handleTagEvent('module_organigramme_technique', 'clic mail responsable', pathname, userPref)}
        >
          {renderElement(props)}
        </a>
      );
    } else if (attr.href && attr.href.includes('mailto')) {
      return (
        <a {...attr} onClick={handleTagEvent('module_contact', 'clic mail', pathname, userPref)}>
          {renderElement(props)}
        </a>
      );
    } else if (attr.className && attr.className.includes('news news--row') && attr.title) {
      return (
        <a {...attr} onClick={handleTagEvent('module_actualite', attr.title, '', userPref)}>
          {renderElement(props)}
        </a>
      );
    } else if (attr.href === 'http://cote-dirigeants.ffr.fr/login' || 'http://formation.ffr.fr/') {
      return (
        <a {...attr} onClick={handleTagEvent('module_ecosysteme', attr.href, '', userPref)}>
          {renderElement(props)}
        </a>
      );
    }

    return <a {...attr}>{renderElement(props)}</a>;
  }

  if(props.tagName === 'img') {
    return <props.tagName {...renderAttributes(props.attributes)} />;
  }

  const attrtag = renderAttributes(props.attributes);
  if (props.tagName === 'iframe' && attrtag && attrtag.src && attrtag.src.match('youtube')) {
    attrtag.src += '&enablejsapi=1';
  }

  return <props.tagName {...attrtag}>{renderElement(props)}</props.tagName>;
};

const renderElement = (props: Props) => {
  if (!!props.childrens && !!props.childrens.length) {
    return props.childrens.map((c: Bloc | BlocContent, index) => {
      if (c.type === 'text' && c.text) return c.text;
      else if (c.attributes) return <ContentBloc {...c} key={index} />;
      else return null;
    });
  }
  return null;
};

const renderAttributes = (attributes: Array<Attributes>) => {
  if (attributes && attributes.length > 0) {
    return attributes.reduce((acc, obj) => {
      if (obj.name === 'class' || obj.name === 'classname') {
        acc['className'] = obj.value;
      } else if (obj.name === 'stroke-width') {
        acc['strokeWidth'] = obj.value;
      } else if (obj.name === 'fill-rule') {
        acc['fillRule'] = obj.value;
      } else if (obj.name === 'datetime') {
        acc['dateTime'] = obj.value;
      } else if (obj.name === 'srcset') {
        acc['srcSet'] = obj.value;
      } else if (obj.name === 'frameborder') {
        acc['frameBorder'] = obj.value;
      } else if (obj.name === 'allowfullscreen') {
        acc['allowFullScreen'] = obj.value;
      } else if (obj.name === 'style') {
        acc[obj.name] = convertCSS(obj.value);
      } else if (obj.name === 'controls') {
        acc['controls'] = true;
      } else if (obj.name === 'tabindex') {
        acc['tabIndex'] = obj.value;
      } else {
        acc[obj.name] = obj.value;
      }
      return { ...acc };
    }, {});
  }
  return null;
};

const renderLinkAttributes = (attributes: Array<Attributes>): LinkAttributes => {
  if (attributes.length > 0) {
    const attrObject = attributes.reduce((acc, obj): LinkAttributes => {
      if (obj.name === 'class') {
        acc['className'] = obj.value;
      } else if (obj.name === 'href') {
        acc['href'] = obj.value;
      } else if (obj.name === 'style') {
        acc[obj.name] = convertCSS(obj.value);
      } else if (obj.name === 'rel') {
        acc[obj.name] = obj.value;
      } else if (obj.name === 'tabindex') {
        acc['tabIndex'] = obj.value;
      } else {
        acc[obj.name] = obj.value;
      }
      return { ...acc };
    }, {});

    return attrObject;
  }
  return { href: '#' };
};

export default Component;
