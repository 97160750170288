// @flow

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

type Props = $Exact<{
    hasFidelite: boolean,
    hasClub: boolean,
    hasLicence: boolean,
    hasLigue: boolean,
    isClubOfficial: boolean,
    isPlayer: boolean,
    isReferee: boolean,
    onCloseMenu: () => void,
}>;

type State = { isOpen: boolean };

const NavLinkAttr = {
    activeClassName: 'active',
    className: 'ft-up ft-700',
};

class ForYou extends React.PureComponent<Props, State> {
    state = { isOpen: true }

    onClick = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))

    render() {
        const { isOpen } = this.state;
        const { hasFidelite, hasClub, hasLicence, hasLigue, isClubOfficial, isPlayer, isReferee, onCloseMenu } = this.props;

        return (
            <div className={`dashboard-menu__nav ${isOpen ? '' : 'hide-dashboard-links'}`}>
                <p className="ft-700 mb-2 ft-h5 dashboard-link-header" onClick={this.onClick}>
                    Pour vous

                    <i className={`icon icon-for-you icon-chrevron-${isOpen ? 'down' : 'up'}`} />
                </p>

                <div className="dashboard-links">
                    <NavLink
                        exact
                        {...NavLinkAttr}
                        onClick={onCloseMenu}
                        to="/tableau-de-bord"
                        title="Tableau de bord">
                        <i className="icon icon-dashboard is-inline" />

                        Tableau de bord
                    </NavLink>

                    {hasFidelite && (
                        <NavLink
                            {...NavLinkAttr}
                            onClick={onCloseMenu}
                            to="/tableau-de-bord/ma-fidelite"
                            title="Mon club France Rugby">
                            <i className="icon icon-gift is-inline" />
                            Mon club France Rugby
                        </NavLink>
                    )}

                    {hasLicence && isPlayer && (
                        <NavLink
                            {...NavLinkAttr}
                            onClick={onCloseMenu}
                            to="/tableau-de-bord/mes-statistiques"
                            title="Mes statistiques">
                            <i className="icon icon-chart is-inline" />

                            Mes statistiques
                        </NavLink>
                    )}

                    {hasLigue && (
                        <NavLink
                            {...NavLinkAttr}
                            onClick={onCloseMenu}
                            to="/tableau-de-bord/ma-ligue"
                            title="Ma ligue">
                            <i className="icon icon-trophy is-inline" />

                            Ma ligue
                        </NavLink>
                    )}

                    {hasClub && (
                        <NavLink
                            {...NavLinkAttr}
                            onClick={onCloseMenu}
                            to="/tableau-de-bord/mon-club"
                            title="Mon club">
                            <i className="icon icon-shield is-inline" />

                            Mon club
                        </NavLink>
                    )}

                    <NavLink
                        {...NavLinkAttr}
                        onClick={onCloseMenu}
                        to="/tableau-de-bord/mes-favoris"
                        title="Mes favoris">
                        <i className="icon icon-shirt is-inline" />

                        Mes favoris
                    </NavLink>

                    {hasLicence && (isClubOfficial || isReferee) && (
                        <NavLink
                            {...NavLinkAttr}
                            onClick={onCloseMenu}
                            to="/tableau-de-bord/mon-activite"
                            title="Boîte à outils">
                            <i className="icon icon-info is-inline" />

                         Boîte à outils
                        </NavLink>
                    )}

                    <NavLink
                        {...NavLinkAttr}
                        onClick={onCloseMenu}
                        to="/tableau-de-bord/mon-profil"
                        title="Mon profil">
                        <i className="icon icon-account is-inline" />

                        Mon profil
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default withRouter(ForYou);