// @flow

import { STATUS_UNKNOWN, STATUS_LOADING, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_LOGE_LIST_SUCCESS,
  FETCH_UNKNOWN,
  FETCH_LOGE_LIST_START,
} from 'actions/logeActions';

import type { Status } from 'types/Status';
import type { LogeListType } from 'types/LogeList';
import type { ActionType } from 'types/Actions';

export type State = {
  loges: Array<LogeListType>,
  status: Status,
};

const initialState: State = {
  loges: [],
  status: STATUS_UNKNOWN,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_LOGE_LIST_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_LOGE_LIST_SUCCESS:
      return {
        ...state,
        loges: action.payload.loges,
        status: STATUS_SUCCESS
      };
    case FETCH_UNKNOWN:
      return {
        ...state,
        status: STATUS_UNKNOWN
      };
    default:
      return state;
  }
}
