// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import {
  convertFormCentenaireResponseFromApi,
} from 'services/Centenaire';

function centenaireFormApi(executor: ApiExecutorType) {
  return { postFormCentenaire };

  function postFormCentenaire(formInputs: any) {
    return executor
      .post(`/ffr/v1/centenaire`, formInputs, {
        headers: {
          'Content-type': 'multipart/form-data'
        },
        timeout: 600000
      })
      .then(response => convertFormCentenaireResponseFromApi(response));
  }
}
export default centenaireFormApi;
