// @flow

export const handleYoutubeThumbnailsUrl = (thumbnails: Object) => {
  if (thumbnails.maxres) {
    return thumbnails.maxres.url;
  }
  if (thumbnails.standard) {
    return thumbnails.standard.url;
  }
  if (thumbnails.high) {
    return thumbnails.high.url;
  }
  if (thumbnails.medium) {
    return thumbnails.medium.url;
  }
  if (thumbnails.default) {
    return thumbnails.default.url;
  }
  return '';
};

export const dynamicSort = (property: string) => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a: any, b: any) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export function getPlayersNameFromYoutubeTags(tags: Array<string>) {
  let playersName = [];

  tags.forEach(tag => {
    const playerName: ?string = tag.split('Joueur : ')[1];
    if(playerName) playersName.push(playerName);
  });

  return playersName;
}
export const getIdVideoFromText = (text: string, regex: RegExp) => {
  let m: any;
  let youtubeVideosId = [];

  while ((m = regex.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m && m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    // $FlowFixMe
    const youtubeToAdd = m.map(match => match).filter(match => match !== undefined);
    youtubeVideosId = [...youtubeVideosId, youtubeToAdd[1]];
  }
  return youtubeVideosId;
};

export const getTextWithoutUrlFromText = (text: string, regex: string | RegExp) => {
  return text.replace(regex, '');
};

export const getArticlesSlugFromText = (text: string, regex: RegExp): Array<string> => {
  let m: any;
  let articles = [];

  while ((m = regex.exec(text)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m && m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    // $FlowFixMe
    const article = m.filter(match => match !== undefined);
    articles = [...articles, article[2]];
  }
  return articles;
};
