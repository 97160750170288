// @flow
import type {
  RawAuteurType,
  AuteurType,
} from 'types/Auteur';
import { nullAuteur } from 'types/Auteur';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertAuteurFromApi(rawJoueur: RawAuteurType): AuteurType {
  if (!rawJoueur) {
    return nullAuteur;
  }

  const {
    id,
    name,
    description,
    slug,
    avatar_urls: {
      full
    },
    link,
    role_edition,
  } = rawJoueur;

  return mergeIntoDefault(nullAuteur, {
    id,
    title: name,
    logo: full ? full : null,
    content: description,
    role_edition,
    slug,
    link,
  });
}
