// @flow
import NewsletterSuccessModalContent from 'components/fragments/modal/modalContents/NewsletterSuccessModalContent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/modal/modalContents/NewsletterSuccessModalContent';

export default connect((state: StateType): StateProps => ({
  userPref: state.userState.userPref
}))(NewsletterSuccessModalContent);
