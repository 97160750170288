// @flow

import type { CompetitionInternationale } from 'types/competitionsInternationales';

export const FETCH_COMPETITIONS_INTERNATIONALES_START = 'FETCH_COMPETITIONS_INTERNATIONALES_START';
export const FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS = 'FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS';
export const FETCH_COMPETITIONS_INTERNATIONALES_FAILURE = 'FETCH_COMPETITIONS_INTERNATIONALES_FAILURE';

export type FETCH_COMPETITIONS_INTERNATIONALES_START_ACTION = {
  type: 'FETCH_COMPETITIONS_INTERNATIONALES_START',
  payload: {
    slug: string
  }
};

export type FETCH_COMPETITIONS_INTERNATIONALES_FAILURE_ACTION = {
  type: 'FETCH_COMPETITIONS_INTERNATIONALES_FAILURE'
};

export type FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS_ACTION = {
  type: 'FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS',
  payload: {
    data: CompetitionInternationale
  }
};

export type Action =
  | FETCH_COMPETITIONS_INTERNATIONALES_START_ACTION
  | FETCH_COMPETITIONS_INTERNATIONALES_FAILURE_ACTION
  | FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS_ACTION;

export function fetchCompetitionsInternationales(slug: string): FETCH_COMPETITIONS_INTERNATIONALES_START_ACTION {
  return {
    type: FETCH_COMPETITIONS_INTERNATIONALES_START,
    payload: {
      slug
    }
  };
}

export function competitionsInternationalesFetched(data: CompetitionInternationale): FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS_ACTION {
  return {
    type: FETCH_COMPETITIONS_INTERNATIONALES_SUCCESS,
    payload: { data }
  };
}

export function competitionsInternationalesFetchError(): FETCH_COMPETITIONS_INTERNATIONALES_FAILURE_ACTION {
  return {
    type: FETCH_COMPETITIONS_INTERNATIONALES_FAILURE
  };
}
