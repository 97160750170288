// @flow
import React from 'react';

type Props = {
  content: any,
  hideModal: () => void
};

function UnsubscribeNewsletterDoneModalContent({ content, hideModal }: Props) {
  return (
    <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
      <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <h3 className="mb-4">Désinscription de la newsletter</h3>
        <div className="modal__body modal__body--medium narrow-2 mb-5">
          <div className="circle-icon circle-icon--check" />
          <span>Merci ! Et peut-être à bientôt !</span>
        </div>
      </div>
    </div>
  );
}

export default UnsubscribeNewsletterDoneModalContent;
