// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { FETCH_FACEBOOK_START, facebookPostsFetched, facebookPostsFetchError } from 'actions/facebookActions';
import FacebookApi from 'api/social/FacebookApi';
import type { FETCH_FACEBOOK_START_ACTION } from 'actions/facebookActions';

export default function(apiExecutor: ApiExecutorType) {
  const facebookApi = new FacebookApi(apiExecutor);

  return function* facebookSaga(): GeneratorType {
    yield takeLatest(FETCH_FACEBOOK_START, fetchFacebookPosts);
  };

  function* fetchFacebookPosts(action: FETCH_FACEBOOK_START_ACTION): Saga<void> {
    try {
      const posts = yield call(facebookApi.fetchPosts);
      yield put(facebookPostsFetched(posts));
    } catch (e) {
      yield put(facebookPostsFetchError());
    }
  }
}
