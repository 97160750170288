// @flow

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import './Cookie.css';

type OwnProps = {
  acceptCookies: () => void,
};

export type StateProps = {
  showCookieInfo: boolean,
  title: string,
  cookies: number
};

export type DispatchProps = {
  closeCookieInfo: () => void,
  setAcceptCookie: (accept: boolean) => void
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celle" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres 'intérêts.";

class Cookie extends PureComponent<Props> {
  onAccept = () => {
    this.props.closeCookieInfo();
    this.props.setAcceptCookie(true);
    this.props.acceptCookies();
  }

  onRefuse = () => {
    this.props.closeCookieInfo();
    this.props.setAcceptCookie(false);
  }

  render() {
    const { title, cookies, showCookieInfo } = this.props;

    if (!showCookieInfo) {
      return null;
    }

    return (
      <div className="cookie-container">
        <div className="box cookie">
          <p>
            En poursuivant votre navigation sur{' '}
            {title}
            {cookieEndContent}

            <br />

            {cookies !== 0 &&
              <Link to="/donnees-personnelles">
                En savoir plus sur les cookies
              </Link>
            }

            <br />

            <Link to="/cgu">
              Afficher les CGU
            </Link>
          </p>

          <div className='btn-container'>
            <button onClick={this.onAccept} className="btn btn--primary btn--full">
              Accepter les cookies
              </button>

            <button onClick={this.onRefuse} className="btn btn--primary btn--full">
              Refuser
              </button>
          </div>
        </div>

        <button className="btn cookie-close" onClick={this.onRefuse}>
          Continuer sans accepter &#10142;
        </button>
      </div>
    );
  }
}

export default Cookie;
