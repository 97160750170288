// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertArticleFromApi } from 'services/Article';
import { convertEventPreviewFromApi } from 'services/Event';
import { convertQuestionFromApi } from 'services/Faq';
import { convertJoueurFromApi } from 'services/Joueur';
import { convertPageListFromApi } from 'services/Page';

function SearchApi(executor: ApiExecutorType) {
  return {
    fetchPages,
    fetchArticlesByPage,
    fetchEventsPreview,
    fetchQuestions,
    fetchJoueurs
  };

  function fetchPages(input: string) {
    return executor
      .get(`/ffr/v1/pages?search=${input}&per_page=100&order=asc&orderby=menu_order`)
      .then(response => response.map(page => convertPageListFromApi([page])));
  }

  function fetchArticlesByPage(page: number, per_page: number, input: string) {
    return executor
      .get(`/ffr/v1/posts?page=${page}&per_page=${per_page}&search=${input}`)
      .then(response => response.map(a => convertArticleFromApi(a)));
  }

  function fetchEventsPreview(input: string) {
    return executor
      .get(`/ffr/v1/events?search=${input}`)
      .then(response => response.events.map(event => convertEventPreviewFromApi(event)));
  }

  function fetchQuestions(input: string) {
    return executor
      .get(`/ffr/v1/wp_faq?search=${input}`)
      .then(response => response.map(questions => convertQuestionFromApi(questions)));
  }

  function fetchJoueurs(input: string) {
    return executor
      .get(`/ffr/v1/joueurs?search=${input}`)
      .then(response => response.map(joueurs => convertJoueurFromApi(joueurs)));
  }
}

export default SearchApi;
