// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertSeasonsListFromApi } from 'services/Seasons';

function SeasonsApi(executor: ApiExecutorType) {
  return {
    getSeasonsList,
  };

  function getSeasonsList(slug?: string) {
    if (slug) {      
      return executor.get(`saisons_${slug}.json`)
        .then(response => Promise.all(response.map(convertSeasonsListFromApi)));
    }
    return executor.get(`saisons.json`)
      .then(response => Promise.all(response.map(convertSeasonsListFromApi)));
  }
}

export default SeasonsApi;