// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import UserApi from 'api/user/UserApi';
import {
  FETCH_USER_FAVS_START,
  userFavsFetched,
  userFavsFetchError,
  FETCH_USER_FID_START,
  userFidFetched,
  userFidFetchError,
  FETCH_USER_PREF_START,
  fetchUserFavs as fetchUserFavsAction,
  userPrefFetched,
  userPrefFetchError,
  POST_USER_FID_START,
  postUserFidSuccess,
  postUserFidFailure,
  POST_USER_PREF_START,
  postUserPrefSuccess,
  postUserPrefFailure
} from 'actions/userActions';

import type {
  FETCH_USER_FAVS_START_ACTION,
  FETCH_USER_FID_START_ACTION,
  POST_USER_FID_START_ACTION,
  POST_USER_PREF_START_ACTION,
  FETCH_USER_PREF_START_ACTION
} from 'actions/userActions';

export default function(apiExecutor: ApiExecutorType) {
  const userApi = new UserApi(apiExecutor);

  return function* userSaga(): GeneratorType {
    yield takeLatest(FETCH_USER_FAVS_START, fetchUserFavs);
    yield takeLatest(FETCH_USER_FID_START, fetchUserFid);
    yield takeLatest(FETCH_USER_PREF_START, fetchUserPrefs);
    yield takeLatest(POST_USER_FID_START, changeUserFid);
    yield takeLatest(POST_USER_PREF_START, changeUserPrefs);
  };

  function* fetchUserFavs(action: FETCH_USER_FAVS_START_ACTION): Saga<void> {
    try {
      const { token } = action.payload;
      const userFavs = yield call(userApi.fetchUserFavs, token);

      yield put(userFavsFetched(userFavs));
    } catch (e) {
      if (e.response) {
        yield put(userFavsFetchError(e.response.data.code));
      } else {
        yield put(userFavsFetchError(e.message));
      }
    }
  }

  function* fetchUserFid(action: FETCH_USER_FID_START_ACTION): Saga<void> {
    try {
      const { token } = action.payload;
      const userFid = yield call(userApi.fetchUserFid, token);

      yield put(userFidFetched(userFid));
    } catch (e) {
      if (e.response) {
        yield put(userFidFetchError(e.response.data.code));
      } else {
        yield put(userFidFetchError(e.message));
      }
    }
  }

  function* fetchUserPrefs(action: FETCH_USER_PREF_START_ACTION): Saga<void> {
    try {
      const { full, token } = action.payload;
      const userPrefs = yield call(userApi.fetchUserPref, full, token);
      yield put(userPrefFetched(userPrefs));
    } catch (e) {
      if (e.response) {
        yield put(userPrefFetchError(e.response.data.code));
      } else {
        yield put(userPrefFetchError(e.message));
      }
    }
  }

  function* changeUserFid(action: POST_USER_FID_START_ACTION): Saga<void> {
    try {
      const { action: postAction, value, token, redirect } = action.payload;
      const userFidPosted = yield call(userApi.changeUserFid, postAction, value, token);

      if (redirect) {
        window.location.href = redirect;
        setTimeout(() => {
          put(postUserFidSuccess(userFidPosted));
        }, 1000);
      } else {
        yield put(postUserFidSuccess(userFidPosted));
      }

      window.snack && window.snack.classList.add('is-valid');
      setTimeout(() => {
        window.snack && window.snack.classList.remove('is-valid');
      }, 3000);
    } catch (e) {
      yield put(postUserFidFailure(e.response.data.code));
    }
  }

  function* changeUserPrefs(action: POST_USER_PREF_START_ACTION): Saga<void> {
    try {
      const { action: postAction, value, token, redirect } = action.payload;
      const userPrefsPosted = yield call(userApi.changeUserProfile, postAction, value, token);

      if (postAction.endsWith('-favoris-del') || postAction.endsWith('-favoris-add')) {
        yield put(fetchUserFavsAction(token));
      }

      if (redirect) {
        window.location.href = redirect;
        setTimeout(() => {
          put(postUserPrefSuccess(userPrefsPosted));
        }, 1000);
      } else {
        yield put(postUserPrefSuccess(userPrefsPosted));
      }

      window.snack && window.snack.classList.add('is-valid');
      setTimeout(() => {
        window.snack && window.snack.classList.remove('is-valid');
      }, 3000);
    } catch (e) {
      yield put(postUserPrefFailure(e.response.data.code));
    }
  }
}
