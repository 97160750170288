// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertTeamsListFromApi } from 'services/Teams';

function TeamsApi(executor: ApiExecutorType) {
  return { fetchEquipeSearch, fetchTeamList };

  function fetchTeamList() {
    return executor
      .get('equipes.json')
      .then(response => response.map(teamlist => convertTeamsListFromApi(teamlist)));
  }

  function fetchEquipeSearch() {
    return executor.get('equipesearch.json').then(response => {
      return response;
    });
  }
}

export default TeamsApi;
