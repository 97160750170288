// @flow
import AddFavoritePlayerModalContent from 'components/fragments/modal/modalContents/AddFavoritePlayerModalContent';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/fragments/modal/modalContents/AddFavoritePlayerModalContent';

export default connect((state: StateType): StateProps => ({
  login_url: state.settingsState.login_url,
  userPref: state.userState.userPref
}))(AddFavoritePlayerModalContent);
