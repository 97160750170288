import { 
  FacebookShareButton, 
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton
} from 'react-share';

export const socialNetworks = {
  facebook: FacebookShareButton, 
  twitter: TwitterShareButton,
  whatsapp: WhatsappShareButton,
  linkedin: LinkedinShareButton,
  mail: EmailShareButton
};
