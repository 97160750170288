// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import LogeApi from 'api/loge/LogeApi';
import { setTypeLogeTitle, setTypeLogeDescription, setTypeLogeSlug } from 'actions/filterOptionsActions';
import {
  FETCH_LOGE_LIST_START,
  logeListFetched,
  logeListFetchError,
  FETCH_LOGE_DETAIL_START,
  logeDetailFetched,
  fetchUnknown,
  logeDetailFetchError,
  FETCH_LOGE_PREVIEWBYID_START,
  logeDetailPreviewFetched,
  logeDetailPreviewFetchError,
} from 'actions/logeActions';

import type { FETCH_LOGE_LIST_START_ACTION } from 'actions/logeActions';

export default function(apiExecutor: ApiExecutorType, apiStaticExecutor: ApiExecutorType) {
  const logeApi = new LogeApi(apiExecutor);
  const logeStaticApi = new LogeApi(apiStaticExecutor);

  return function* logeListSaga(): GeneratorType {
    yield takeLatest(FETCH_LOGE_LIST_START, fetchLogeList);
    yield takeLatest(FETCH_LOGE_DETAIL_START, fetchLogeDetail);
    yield takeLatest(FETCH_LOGE_PREVIEWBYID_START, fetchLogeDetailPreview);
  };

  function* fetchLogeList(action: FETCH_LOGE_LIST_START_ACTION): Saga<void> {
    try {
      let loges = [];
      const { match } = action.payload;
      const response = yield call(logeStaticApi.getTypeOfMatch);
      const currentMatch = response.filter((typeOfMatch) => typeOfMatch.slug === match);

      if (currentMatch && currentMatch[0]) {
        loges = yield call(logeApi.getLogeList, currentMatch[0].id);
        const typeLogeTitle = currentMatch[0].name;
        yield put(setTypeLogeTitle(typeLogeTitle));
        const typeLogeDescription = currentMatch[0].description ? 
          currentMatch[0].description : 
          "Vivez les matchs de rugby autrement, au travers d'expérience inédites";
        yield put(setTypeLogeDescription(typeLogeDescription));
        const typeLogeSlug = currentMatch[0].slug;
        yield put(setTypeLogeSlug(typeLogeSlug));
      } else {
        loges = yield call(logeApi.getLogeList);
      }

      yield put(logeListFetched(loges));
    } catch (e) {
      yield put(logeListFetchError());
    }
  }

  function* fetchLogeDetail(action): Saga<void> {
    try {
      const { slug } = action.payload;
      const logeDetail = yield call(logeApi.fetchLogeDetail, slug);
      yield put(logeDetailFetched(logeDetail, true));
    } catch (e) {
      if (e.message === 'NOT FOUND') {
        yield put(fetchUnknown());
      } else {
        yield put(logeDetailFetchError());
      }
    }
  }

  function* fetchLogeDetailPreview(action): Saga<void> {
    try {
      const { id } = action.payload;
      const logeDetailPreview = yield call(logeApi.fetchLogePreviewById, id);
      yield put(logeDetailPreviewFetched(logeDetailPreview, true));
    } catch (e) {
      yield put(logeDetailPreviewFetchError());
    }
  }

}
