// @flow
import TagManager from 'react-gtm-module';
import type { FullUserType } from 'types/User';

export function handleTagEvent(
  eventCategory: string,
  eventAction: ?string,
  eventLabel: ?string,
  userPref?: FullUserType,
  onClickActions?: Function
) {
  return () => {
    // we only want anonymous data
    /* const userId = userPref.login ? userPref.login : '';
    const licensee = userPref.licence !== '' ? 'yes' : 'no';
    const licenseeClub = userPref.club_actuel && userPref.club_actuel.nom ? userPref.club_actuel.nom : '';
    const licenseeFunction = userPref.licenceefunctions ? userPref.licenceefunctions : ''; */
    const newsletterThematic = userPref && Object.values(userPref.newsletter).join(', ');
    const newsletterSubscriber = newsletterThematic && newsletterThematic.length > 0 ? 'yes' : 'no';
    const profileCompletionRatio = (userPref && userPref.percent_profile_completed) || 0;

    TagManager.dataLayer({
      dataLayer: {
        event: 'eventGA',
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel,
        // 'userId' : userId,
        // 'licensee' : licensee,
        // 'licenseeClub' : licenseeClub,
        // 'licenseeFunction' : licenseeFunction,
        newsletterSubscriber: newsletterSubscriber,
        newsletterThematic: newsletterThematic,
        profileCompletionRatio: profileCompletionRatio
      },
      dataLayerName: 'dataLayer'
    });
    if (onClickActions) {
      onClickActions();
    }
  };
}

export const getSocialName = (props: Object) => {
  const { isFacebook, isInstagram, isMail, isTwitter } = props;
  if (isFacebook) {
    return 'facebook';
  } else if (isTwitter) {
    return 'twitter';
  } else if (isInstagram) {
    return 'instagram';
  } else if (isMail) {
    return 'mail';
  }
  return '';
};

export const getModalName = (modalObject: string | number) => {
  switch (modalObject) {
    case 'NO_MODAL':
      return '';
    case 'MODAL_NEWSLETTER_SUCCESS':
      return 'Modale inscription Newsletter réussie';
    case 'MODAL_NEWSLETTER_FAILED':
      return 'Modale échec inscription Newsletter';
    case 21:
      return 'Modale échec inscription newsletter';
    case 'MODAL_NEWSLETTER_MISSING_OPT':
      return 'Modale newsletter optin manquant';
    case 'MODAL_CONTACT_FORM_SUCCESS':
      return 'Modale formulaire contact succès';
    case 'MODAL_CONTACT_FORM_FAILED':
      return 'Modale formulaire contact échec';
    case 'MODAL_LOGE_FORM':
      return 'Modale formulaire contact loge';
    case 'MODAL_LOGE_SUCCESS':
      return 'Modale inscription loge succès';
    case 'MODAL_NEWSLETTER_OPTIN':
      return 'Modale inscription Newsletter';
    case 'MODAL_SEMINAIRE_FORM':
      return 'Modale inscription séminaire';
    default:
      return null;
  }
};

export const sendTags = (category: string, action: string, label?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'eventGA',
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    },
    dataLayerName: 'dataLayer'
  });
};

export const getGmtUserData = (userPref: FullUserType) => {
  const licensee = userPref.licence !== '' ? 'yes' : 'no';
  const newsletterThematic = Object.values(userPref.newsletter).join(', ');
  const newsletterSubscriber =
    newsletterThematic.length > 0 ? `yes | newsletterThematic : ${newsletterThematic}` : 'no';

  return `licensee : ${licensee} | newsletterSubscriber : ${newsletterSubscriber}`;
};

//
// export function getFilterReduced(practices: Array<string>, competitions: Array<string>, distance: string){
//   let reducedPractices = '';
//   let reducedCompetitions = '';
//   if (practices.length > 0) {
//     reducedPractices = practices.reduce((acc, practice) => `${acc}|${practice}`);
//   }
//
//   if (competitions.length > 0) {
//     reducedCompetitions = competitions.reduce((acc, competitions) => `${acc}|${competitions}`);
//   }
//
//   if (reducedPractices !== '' && reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedPractices}|${reducedCompetitions && reducedCompetitions}`;
//   } else if (reducedPractices !== '') {
//     if (distance) {
//       return `${reducedPractices}|${distance}km`;
//     }
//     return `${reducedPractices}`;
//   } else if (reducedCompetitions !== '') {
//     if (distance) {
//       return `${reducedCompetitions}|${distance}km`;
//     }
//     return `${reducedCompetitions}`;
//   }
// }
