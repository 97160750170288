// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertAuteurFromApi } from 'services/Auteur';

function AuteurApi(executor: ApiExecutorType) {
  return {
    fetchAuteurBySlug,
  };

  function fetchAuteurBySlug(slug: string) {
    return executor
      .get(`/ffr/v1/users?slug=${slug}&per_page=1`)
      .then(response => {
        if (!response || response.length === 0) {
          throw new Error('NOT FOUND');
        }
        return convertAuteurFromApi(response[0]);
      });
  }
}

export default AuteurApi;
