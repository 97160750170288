// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { convertPartnerFromApi } from 'services/Partner';

function PartnerApi(executor: ApiExecutorType) {
  return {
    fetchPartners
  };

 function fetchPartners() {
    return executor.get(`logos.json`)
                    .then(response => response.map(convertPartnerFromApi));
  }
}

export default PartnerApi;
