// @flow

import type { ClubPreviewType } from 'types/Club';

export const FETCH_CLUBS_START = 'FETCH_CLUBS_START';
export const FETCH_CLUBS_SUCCESS = 'FETCH_CLUBS_SUCCESS';
export const FETCH_CLUBS_FAILURE = 'FETCH_CLUBS_FAILURE';

export type FETCH_CLUBS_START_ACTION = {
  type: 'FETCH_CLUBS_START'
};

export type FETCH_CLUBS_FAILURE_ACTION = {
  type: 'FETCH_CLUBS_FAILURE'
};

export type FETCH_CLUBS_SUCCESS_ACTION = {
  type: 'FETCH_CLUBS_SUCCESS',
  payload: {
    clubs: Array<ClubPreviewType>
  }
};

export type Action = FETCH_CLUBS_START_ACTION | FETCH_CLUBS_FAILURE_ACTION | FETCH_CLUBS_SUCCESS_ACTION;

export function fetchClubs(): FETCH_CLUBS_START_ACTION {
  return {
    type: FETCH_CLUBS_START
  };
}

export function clubsFetched(clubs: Array<ClubPreviewType>): FETCH_CLUBS_SUCCESS_ACTION {
  return {
    type: FETCH_CLUBS_SUCCESS,
    payload: { clubs }
  };
}

export function clubsFetchError(error: any): FETCH_CLUBS_FAILURE_ACTION {
  return {
    type: FETCH_CLUBS_FAILURE
  };
}
