// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  duration?: string,
  id: string,
  img: string,
  label?: string,
  title: string,
  url: string,
  tags?: string[],
  from?: any,
  play_button?: string
};

const CardVideo = (props: Props) => {
  return (
    <Link to={{ pathname: props.url, state: props.from }} title={props.title} className="box card card--video">
      <div className="card__thumbnail img-blue-dark" style={{ backgroundImage: `url(${props.img})` }}>
        <img alt={`Illustration de la vidéo ${props.title}`} />
      </div>
      <div className="card__footer">
        <span className="link-icon link-icon--medium is-dark" style={{border: 'none'}}>
          <img src={getPublicAssets(props.play_button || '/img/youtube/youtube_social_square_dark.png')} alt="Lecture" style={{display: 'block'}} />
        </span>
        <h5 className="card__title ft-truncat">{props.title}</h5>
        <p className="card__date">{props.duration}</p>
      </div>
      <div className="card__type">
        {props.tags && props.tags[0] && <span className="label label--red">{props.tags[0]}</span>}
      </div>
    </Link>
  );
};
export default CardVideo;
