// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_RANDOM_VIDEO_PLAYER_START,
  FETCH_RANDOM_VIDEO_PLAYER_SUCCESS,
  FETCH_RANDOM_VIDEO_PLAYER_FAILURE
} from 'actions/ffr-tv/youtubeRandomVideosPlayerActions';

import type { YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  randomVideos: Array<YoutubeVideo>,
  status: Status
};

const initialState: State = {
  randomVideos: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_RANDOM_VIDEO_PLAYER_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_RANDOM_VIDEO_PLAYER_SUCCESS:
      return {
        ...state,
        randomVideos: action.payload.randomVideos,
        status: STATUS_SUCCESS
      };
    case FETCH_RANDOM_VIDEO_PLAYER_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
