// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';

import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { FETCH_COMPETITIONS_INTERNATIONALES_START_ACTION } from 'actions/competitionsInternationalesActions';
import {
  FETCH_COMPETITIONS_INTERNATIONALES_START,
  competitionsInternationalesFetchError,
  competitionsInternationalesFetched
} from 'actions/competitionsInternationalesActions';
import CompetitionsInternationalesApi from 'api/competitionsInternationales/CompetitionsInternationalesApi';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const api = new CompetitionsInternationalesApi(apiExecutor, staticApiExecutor);

  function* fetchCompetitionInternationale(action: FETCH_COMPETITIONS_INTERNATIONALES_START_ACTION): Saga<void> {
    try {
      const data = yield call(api.fetchCompetitionInternationale, action.payload.slug);

      yield put(competitionsInternationalesFetched(data));
    } catch (e) {
      yield put(competitionsInternationalesFetchError());
    }
  }

  return function* faqSaga(): GeneratorType {
    yield takeLatest(FETCH_COMPETITIONS_INTERNATIONALES_START, fetchCompetitionInternationale);
  };
}
