// @flow
import React from 'react';

import DirectusBlocks from 'components/fragments/DirectusBlocks';
import type { EditProfileValueType } from 'types/User';

export type DispatchProps = {
  postUserFid: (action: string, value: EditProfileValueType, token: string) => void
};

export type StateProps = {
  token: string
};

type Props = DispatchProps &
  StateProps & {
    content: any,
    hideModal: () => void
  };

function FidNewBadgeModal({ content, hideModal, postUserFid, token }: Props) {
  const handleCloseModal = () => {
    postBadgeSeen();
    hideModal();
  };

  const onFirstCtaClick = () => {
    postBadgeSeen();
    alert("s'affichera alors la modale pour récupérer la surprise");
    // !@todo => il faudra ouvrir la modale pour récupérer le cadeau
    hideModal();
  };

  const postBadgeSeen = () => {
    const usersBadgeId = content.badgeId;

    postUserFid('fid_new_badge_seen', usersBadgeId, token);
  };

  return (
    <div className="modal-content fid-modal fid-modal--blue-background">
      <i className="icon icon-close js-closeModal" onClick={handleCloseModal} role="button" tabIndex="0" />
      <div className="modal__wrapper">
        <div className="modal__body modal__body--medium narrow-2">
          <img src={content.logo} alt="badge_logo" className="mb-1" />
          <h3 className="mb-3 ft-h2">{content.content_title}</h3>
          {!!content.content_description_1 &&
            !!content.content_description_1.blocks &&
            !!content.content_description_1.blocks.length && (
              <div className="mb-2">
                {content.content_description_1.blocks.map(block => (
                  <DirectusBlocks key={block.id} block={block} />
                ))}
              </div>
            )}
          {!!content.hasSurprise && content.has_button_one && (
            <button className="btn btn--primary" onClick={onFirstCtaClick}>
              {content.cta_button_one}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FidNewBadgeModal;
