// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertObjectToQueryString } from 'utils/urlUtils';

import { convertCompetitionLayoutFromApi, convertCompetitionElementFromApi } from 'services/Competition';
import { convertCompetitionsFromApi } from 'services/Competitions';

function CompetitionApi(executor: ApiExecutorType) {
  return { fetchCompetitions, fetchCompetition, fetchCompetitionsElements, fetchCompetitionBySlug };

  function fetchCompetitions() {
    return executor
      .get('/ffr/v1/competitions')
      .then(response => response.map(competition => convertCompetitionLayoutFromApi(competition)));
  }

  function fetchCompetitionsElements() {
    return executor
      .get('/ffr/v1/competitions')
      .then(response => response.map(competition => convertCompetitionElementFromApi(competition)));
  }

  function fetchCompetition(filters: Object) {
    return executor
      .get(`/ffr/v1/competitions${convertObjectToQueryString({ ...filters})}`)
      .then(response => response.map(competition => convertCompetitionsFromApi(competition)));
  }

  function fetchCompetitionBySlug(slug: string) {    
    return executor
    .get(`/ffr/v1/competitions?slug=${slug}`)
    .then(response => convertCompetitionsFromApi(response[0]));
  }
}

export default CompetitionApi;
