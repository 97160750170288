// @flow
import React, { PureComponent, Fragment } from 'react';
import TextInput from 'components/fragments/input/material/TextInputMaterial';
import TextareaInput from 'components/fragments/input/material/TextareaInputMaterial';
import CheckboxInput from 'components/fragments/input/CheckboxInput';

import { validateEmail, validatePhone } from 'utils/validatorUtils';
import Loading from 'components/fragments/Loading';
import { sendTags } from 'utils/tagManagerUtils';

type Props = {
  onSubmit: (values: Object) => void,
  tagManagerContent: string,
};

type State = {
  checked: boolean,
  name: string | null,
  email: string | null,
  phone: string | null,
  message: string | null,
  company: string | null,
  formSent: boolean,
  showEmailError: boolean,
  showPhoneError: boolean,
};

class ModalFormLoge extends PureComponent<Props, State> {
  state: State = {
    name: null,
    email: null,
    company: null,
    message: null,
    phone: null,
    checked: false,
    formSent: false,
    showEmailError: false,
    showPhoneError: false,
  };

  _handleChange = (name: string, value: string) => {
    this.setState({ [name]: value });
  }

  isFormValid = () => {
    const { name, email, company, phone, checked } = this.state;

    return (
      name !== null && name !== '' &&
      email !== null && email !== '' &&
      validateEmail(email) &&
      company !== null && company !== '' &&
      phone !== null && phone !== '' &&
      validatePhone(phone) &&
      checked
    );
  }

  _handleSubmit = (event: Event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    const { tagManagerContent } = this.props;
    const { pathname } = window.location;

    const tagEventCategory = pathname.split('/')
      .includes('seminaires') ? 'recherche_offre_seminaire' : 'recherche_offre_match';

    if (this.isFormValid()) {
      sendTags(tagEventCategory, 'contact', tagManagerContent); 
      onSubmit(this.state);
      this.setState({ formSent: true });
    }
  }

  onFocusEmail = () => {
    this.setState({ showEmailError: false });
  }

  onBlurEmail = () => {
    this.setState({ showEmailError: true });
  }

  onFocusPhone = () => {
    this.setState({ showPhoneError: false });
  }

  onBlurPhone = () => {
    this.setState({ showPhoneError: true });
  }

  render() {
    const { name, email, company, phone, formSent, showEmailError, showPhoneError } = this.state;
    const checkboxLabel = `En soumettant ce formulaire, j'accepte que mes informations soient utilisées uniquement
    dans le cadre de ma demande et de la relation commerciale éthique et personnalisée
    qui peut en découler.`;

    const isValid = this.isFormValid();
    return (
      <Fragment>
        <div className="modal__row">
          <TextInput
            id="NOyDCaUwVScU"
            label="Société *"
            placeholder="Votre société"
            hasError={company === ''}
            errorMessage="Ce champ est requis."
            onChange={(value) => this._handleChange('company', value)}
            />
          <TextInput
            id="nABkexSoqmXb"
            label="Nom Complet *"
            placeholder="Votre nom"
            hasError={name === ''}
            errorMessage="Ce champ est requis."
            onChange={(value) => this._handleChange('name', value)}
            />
        </div>
        <div className="modal__row">
          <TextInput
            id="eedOMRIaHcSq"
            label="Adresse email *"
            placeholder="Votre adresse email"
            onFocus={this.onFocusEmail}
            onBlur={this.onBlurEmail}
            hasError={showEmailError && (email === '' || (email !== '' && email !== null && !validateEmail(email)))}
            errorMessage={"L'adresse email renseignée est invalide."}
            onChange={(value) => this._handleChange('email', value)}
            />
          <TextInput
            id="WwVYngGXpNeO"
            label="Téléphone *"
            placeholder="Votre numéro de téléphone"
            onFocus={this.onFocusPhone}
            onBlur={this.onBlurPhone}
            hasError={showPhoneError && (phone === '' || (phone !== '' && phone !== null && !validatePhone(phone)))}
            errorMessage="Le numéro de téléphone renseigné est invalide."
            onChange={(value) => this._handleChange('phone', value)}
            />
        </div>
        <div className="input">
          <TextareaInput
            id="ANjWXAaPOXnn"
            label="Précisez votre demande."
            placeholder='Votre message'
            onChange={(value) => this._handleChange('message', value)}
            />
        </div>
        <CheckboxInput
          label={checkboxLabel}
          id='UHlRkyWpUjrl'
          className='checkbox mb-3'
          onChange={(checked: boolean) => this.setState({ checked }) } />
        {formSent ?
          <Loading isWhite style={{width: '100px', margin: '0 auto'}} /> :
          <button
          onClick={this._handleSubmit}
          className='btn btn--primary btn--full js-confirm mb-1'
          disabled={!isValid}
          type='submit'>
            Envoyer
        </button>}
      </Fragment>
    );
  }
}

export default ModalFormLoge;
