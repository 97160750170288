// @flow
import type { BlocContent } from 'types/NewsletterBloc';
import type { SourceSetType } from 'types/Article';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type StaffTypes = {
  name: string,
  desc: string,
  mail: string,
  img: string
};

export type MatchSimple = {
  adversaire: string,
  reception: boolean,
  france_logo: string,
  adversaire_logo: string,
  france_score: number,
  adversaire_score: number,
  date_rencontre: string,
  link: string
}

export type StaffType = SEOMetaType & {
  slug: string,
  title: string,
  link: string,
  content: Array<BlocContent>,
  numInternational: number,
  poste: { id: number, name: string, ordre: number },
  postes_groupe: { id: number, name: string, ordre: number },
  age: number,
  dateNaissance: string,
  genre: string,
  logo: string,
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  content: Array<BlocContent>,
  sidebar: Array<BlocContent>,
  nom: string,
  prenom: string,
  nombre_selections: number,
  selections_origine: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  matchs_joues: Array<MatchSimple>
};

export type RawStaffType = SEOMetaType & {
  id: number,
  date: string,
  modified: string,
  slug: string,
  status: string,
  type: string,
  link: string,
  title: {
    rendered: string,
    raw: string,
    data: [
      {
        type: string,
        text: string
      }
    ]
  },
  content: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  featured_media: {
    src: string,
    legend: string,
    sizes: SourceSetType
  },
  excerpt: {
    rendered: string,
    protected: boolean,
    raw: string,
    data: Array<any>
  },
  template: string,
  meta: {
    age: number,
    numInternational: number,
    dateNaissance: string,
    genre: string,
    nom: string,
    prenom: string,
    selections_origine: number,
  },
  poste: { id: number, name: string, ordre: number },
  postes_groupe: { id: number, name: string, ordre: number },
  nombre_selections: number,
  nombre_transformations: number,
  nombre_penalites: number,
  nombre_essais: number,
  nombre_drops: number,
  sidebar_content: {
    data: Array<BlocContent>
  },
  matchs_joues: Array<MatchSimple>
};


export type StaffFiltersSearchType = {
  competitions: number[],
  equipe: string,
  poste_staff?: number[],
  poste: number[],
  season?: number | null,
}

export const nullStaff: StaffType = {
  ...nullSeoMeta,
  title: 'Chargement du membre de staff',
  poste: {id: 0, name: '', ordre: 0},
  postes_groupe: {id: 0, name: '', ordre: 0},
  slug: '',
  age: 0,
  dateNaissance: '',
  genre: '',
  numInternational: 0,
  content: [],
  logo: '',
  link: '',
  featured_media: {
    src: '',
    legend: '',
    sizes: {}
  },
  nom: '',
  prenom: '',
  nombre_selections: 0,
  selections_origine: 0,
  nombre_transformations: 0,
  nombre_penalites: 0,
  nombre_essais: 0,
  nombre_drops: 0,
  matchs_joues: [],
  sidebar: [],
};

export const nullStaffsFiltersSearch: StaffFiltersSearchType = {
  competitions: [],
  equipe: '',
  poste: [],
  poste_staff: [],
};
