// @flow
import { connect } from 'react-redux';

import { fetchEquipeSearch } from 'actions/teamlistActions';
import AddAFavoriteTeamModalContent from 'components/profile/MesFavoris/AddAFavoriteTeamModalContent';

import type { DispatchProps, StateProps } from 'components/profile/MesFavoris/AddAFavoriteTeamModalContent';
import type { DispatchType, StateType } from 'types/Actions';

export default connect(
  (state: StateType): StateProps => ({
    equipes_favoris: state.userState.userPref.equipes_favoris,
    equipes_search: state.teamlistState.equipe_search,
    equipes_search_status: state.teamlistState.equipe_search_status,
    postUserPrefStatus: state.userState.status
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchEquipeSearch: () => dispatch(fetchEquipeSearch())
  })
)(AddAFavoriteTeamModalContent);
