// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  INITIAL_FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_BY_SLUG_START,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_BY_SLUG_SUCCESS,
  FETCH_COMPETITIONS_FAILURE
} from 'actions/competitionsActions';

import type { CompetitionType } from 'types/Competitions';
import { nullCompetition } from 'types/Competitions';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  competitions: Array<CompetitionType>,
  competition: CompetitionType,
  status: Status
};

const initialState: State = {
  competitions: [],
  competition: nullCompetition,
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case INITIAL_FETCH_COMPETITIONS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITIONS_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITIONS_BY_SLUG_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: action.payload.competitions,
        status: STATUS_SUCCESS
      };
    case FETCH_COMPETITIONS_BY_SLUG_SUCCESS:
      return {
        ...state,
        competition: action.payload.competition,
        status: STATUS_SUCCESS
      };
    case FETCH_COMPETITIONS_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
