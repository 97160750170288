// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { convertChannelInfoFromApi } from 'services/Channels';
import { YOUTUBE_ACCESS_TOKEN } from 'constants/api';

function ChannelApi(executor: ApiExecutorType) {
  return { fetchChannelData };

  function fetchChannelData(idChannel: string) {
    return executor
      .get(`/channels?key=${YOUTUBE_ACCESS_TOKEN}&id=${idChannel}&part=snippet`)
      .then(response => convertChannelInfoFromApi(response));
  }
}

export default ChannelApi;
