// @flow

import { takeLatest, put, call, take } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import MatchApi from 'api/match/MatchApi';
import { store } from './../store';
import { 
  FETCH_MATCHRESULT_START, 
  matchResultFetched, 
  matchResultFetchError,
  FETCH_MATCHFUTURETEAM_START,
  matchsFutureTeamFetched,
  matchsFutureTeamFetchError,
  FETCH_MATCHPASTTEAM_START,
  matchsPastTeamFetched,
  matchsPastTeamFetchError

} from 'actions/matchActions';
import type { FETCH_MATCHRESULT_START_ACTION } from 'actions/matchActions';
import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
export default function(apiExecutor: ApiExecutorType) {
  const matchApi = new MatchApi(apiExecutor);

  return function* newsSaga(): GeneratorType {
    yield takeLatest(FETCH_MATCHRESULT_START, fetchNews);
    yield takeLatest(FETCH_MATCHFUTURETEAM_START, fetchMatchsFutureTeam);
    yield takeLatest(FETCH_MATCHPASTTEAM_START, fetchMatchsPastTeam);
  };

  function* fetchNews(action: FETCH_MATCHRESULT_START_ACTION): Saga<void> {
    try {
      const {settingsState} = store.getState();
      let match_semaine = [];
      if (settingsState.match_semaine === 0) {
        const success = yield take(FETCH_SETTINGS_SUCCESS);
        match_semaine = success.payload.settings.match_semaine;
      } else {
        match_semaine = settingsState.match_semaine;
      }
      const matchResult = yield call(matchApi.fetchMatchResultOfTheWeek, match_semaine);
      
      yield put(matchResultFetched(matchResult));
    } catch (e) {
      yield put(matchResultFetchError());
    }
  }

  function* fetchMatchsFutureTeam(action): Saga<void> {
    try {
      const { start_date, team, per_page } = action.payload;
      const matchs = yield call(matchApi.fetchMatchsFutureTeam, start_date, team, per_page);
      yield put(matchsFutureTeamFetched(matchs));
    } catch (e) {
      yield put(matchsFutureTeamFetchError());
    }
  }

  function* fetchMatchsPastTeam(action): Saga<void> {
    try {
      const { end_date, team, per_page } = action.payload;
      const matchs = yield call(matchApi.fetchMatchsPastTeam, end_date, team, per_page);
      yield put(matchsPastTeamFetched(matchs));
    } catch (e) {
      yield put(matchsPastTeamFetchError());
    }
  }
}
