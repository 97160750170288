/**
 * Linkedin Module
 *
 */

//
let initialized = false;
let debug = false;

/**
 * Utilities
 */

const verifyInit = () => {
  if (!initialized) {
    console.warn('linkedin not initialized before using call Linkedin.init with required params');
  }
  return initialized;
};

//
const log = (...args) => {
  console.info(...['[react-adnxs-linkedin]'].concat(args));
};

const defaultOptions = {
  debug: false
};

function init(linkedinId, options = defaultOptions) {
  /* eslint-disable */
  (function() {
    var s = document.getElementsByTagName('script')[0];
    // 1st script
    var a = document.createElement('script');
    a.type = 'text/javascript';
    a.textContent = `_linkedin_partner_id = "${linkedinId}";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `;

    // 2nd script
    var b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

    // insert 2nd script
    s.parentNode.insertBefore(b, s);
    // insert 1st script before 2nd script
    b.parentNode.insertBefore(a, b);
  })();
  /* eslint-enable */

  if (!linkedinId) {
    console.warn('Please insert linkedin ID for initializing');
  } else {
    linkedin('init', linkedinId); // eslint-disable-line no-undef

    initialized = true;
    debug = options.debug;
  }
}

function pageView() {
  if (!verifyInit()) {
    return;
  }

  linkedin('PageView'); // eslint-disable-line no-undef

  if (debug) {
    log("called linkedin('event', 'PageView');");
  }
}

function linkedin(...args) {
  if (!verifyInit()) {
    return;
  }

  linkedin(...args); // eslint-disable-line no-undef

  if (debug) {
    log(`called linkedin('${args.slice(0, 2).join("', '")}')`);

    if (args[2]) {
      log('with data', args[2]);
    }
  }
}

//
export default {
  init,
  pageView,
  linkedin
};
