// @flow
import React, { PureComponent } from 'react';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { MOBILE_SIZE } from 'constants/app';

import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType
};

type Props = { attributes: { name: string, value: string }[] } & StateProps;

class PrivatePushEvent extends PureComponent<Props> {
  render() {
    const { attributes, userPref } = this.props;

    // if no attributes don't go further
    if (!attributes || !attributes.length) {
      return null;
    }

    // convert attributes
    let newProps = {};
    attributes.forEach(attribute => {
      if (!attribute.name.includes('class')) {
        newProps[attribute.name] = attribute.value;
      }
    });

    const {
      push_event_cta: cta,
      push_event_description: description,
      push_event_desktop_background_image: background,
      push_event_label: label,
      push_event_link: lien,
      push_event_mobile_background_image: background_mobile,
      push_event_title: title
    } = newProps;

    let background_final = background;
    if (window.innerWidth <= MOBILE_SIZE && background_mobile) {
      background_final = background_mobile;
    }

    //* don't display if the private push event has neither a title nor a cta
    if (title === '' || cta === '' || lien === '' || background === '') {
      return null;
    }

    //* otherwise display the private push event
    return (
      <section className="container mb-7 private-push-event">
        <div className="row">
          <div className="col">
            <a
              href={lien}
              onClick={handleTagEvent('module_ecosysteme', lien, '', userPref)}
              title={title}
              rel="noopener noreferrer"
              // className={classNames}
              className="box card card--hub-alt card--small card--xSmall"
            >
              {label && <span className="label label--red">{label}</span>}
              <div className="card__thumbnail" style={{ backgroundImage: `url(${background_final})` }}>
                <img src={background_final} alt="" />
              </div>
              <div className="card__body">
                <h3 className="card__title ft-up ft-h1" dangerouslySetInnerHTML={{ __html: title }} />
                {description && (
                  <p className="card__desc ft-secondary" dangerouslySetInnerHTML={{ __html: description }}></p>
                )}
              </div>
              <div className="card__footer">
                <span className="btn btn--primary">
                  <span>{cta}</span>
                </span>
              </div>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default PrivatePushEvent;
