// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START,
  FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS,
  FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE
} from 'actions/ffr-tv/youtubeMostPopularVideosChannelActions';

import { type YoutubeVideo } from 'types/YoutubeVideo';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  videos: Array<YoutubeVideo>,
  statusVideos: Status
};

const initialState: State = {
  videos: [],
  statusVideos: STATUS_DEFAULT,
};
export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_START:
      return {
        ...state,
        statusVideos: STATUS_LOADING
      };
    case FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_SUCCESS:
      return {
        ...state,
        videos: action.payload.videos,
        statusVideos: STATUS_SUCCESS,
      };
    case FETCH_MOST_POPULAR_VIDEOS_YOUTUBE_CHANNEL_FAILURE:
      return {
        ...state,
        statusVideos: STATUS_FAILURE
      };

    default:
      return state;
  }
}
