// @flow
import type { RawCompetitionLayoutType, CompetitionLayoutType } from 'types/CompetitionLayout';
import { TYPE_TERRITORIAL } from 'constants/competitionsConstants';
import { mergeIntoDefault, convertToValidDateFormat } from 'utils/objectUtils';
import { nullCompetition, nullCompetitionElement } from 'types/Competition';
import type { CompetitionType, RawCompetitionType, CompetitionListElementType } from 'types/Competition';

const nullCompetitionLayout: CompetitionLayoutType = {
  type: TYPE_TERRITORIAL,
  division: '',
  date: new Date(),
  id: '',
  slug: '',
  nouveaux_resultats: 0
};

export function convertCompetitionLayoutFromApi(rawCompetitionLayout: RawCompetitionLayoutType): CompetitionLayoutType {
  if (!rawCompetitionLayout) {
    return nullCompetitionLayout;
  }
  const {
    id,
    famille,
    date,
    title: { raw },
    slug,
    nouveaux_resultats
  } = rawCompetitionLayout;

  return mergeIntoDefault(nullCompetitionLayout, {
    type: famille,
    division: raw,
    slug,
    id,
    date,
    nouveaux_resultats
  });
}

export function convertCompetitionElementFromApi(rawCompetition: RawCompetitionLayoutType): CompetitionListElementType {
  if (!rawCompetition) {
    return nullCompetitionElement;
  }
  const {
    slug,
    famille,
    classe_age_code,
    sexe,
    title: { raw },
    nouveaux_resultats
  } = rawCompetition;

  return mergeIntoDefault(nullCompetitionElement, {
    division: raw,
    slug,
    recents: nouveaux_resultats,
    age: classe_age_code,
    sexe,
    famille
  });
}

export function convertCompetitionFromApi(rawCompetition: Array<RawCompetitionType>): CompetitionType {
  if (!rawCompetition) {
    return nullCompetition;
  }
  if (rawCompetition.length === 0) {
    return nullCompetition;
  }

  let prochaine_journee_format;
  let derniere_journee_format;
  let mediasMerged;
  let documentsMerged;

  const {
    competitions_ffr_api_slug,
    participants,
    derniere_journee,
    prochaine_journee,
    classement_intra,
    liste_enfants,
    title: { raw },
    sidebar_content,
    meta_title,
    meta_description,
  } = rawCompetition[0];

  const convertNestedDate = (obj: Object): Object => {
    if (!obj.journee_date) {
      return obj;
    }

    return {
      ...obj,
      journee_date: convertToValidDateFormat(obj.journee_date),
    };
  };

  if (!prochaine_journee.poules) {
    prochaine_journee_format = {
      ...convertNestedDate(prochaine_journee),
      poules: [
        {
          pouleId: 0,
          rencontres: prochaine_journee.rencontres
        }
      ]
    };
  } else {
    prochaine_journee_format = convertNestedDate(prochaine_journee);
  }

  if (!derniere_journee.poules) {
    derniere_journee_format = {
      ...convertNestedDate(derniere_journee),
      poules: [
        {
          pouleId: 0,
          rencontres: derniere_journee.rencontres
        }
      ]
    };
  } else {
    derniere_journee_format = convertNestedDate(derniere_journee);
  }

  if (liste_enfants.documents) {
    documentsMerged = mergeIntoDefault({
      documents: nullCompetition.documents
    }, {
      documents: liste_enfants.documents.content.data,
    });
  } else {
    documentsMerged = nullCompetition.documents;
  }

  if (liste_enfants.medias) {
    mediasMerged = mergeIntoDefault({
      content: nullCompetition.medias.content,
      media: nullCompetition.medias.media
    }, {
      content: liste_enfants.medias.content.data,
      media: liste_enfants.medias.albums
    });
  } else {
    mediasMerged = nullCompetition.medias;
  }

  return mergeIntoDefault(nullCompetition, {
    meta_title,
    meta_description,
    slug: competitions_ffr_api_slug,
    participants: participants,
    prevDay: derniere_journee_format,
    nextDay: prochaine_journee_format,
    ranking: classement_intra,
    documents: documentsMerged,
    medias: mediasMerged,
    division: raw,
    sidebar: sidebar_content.data,
  });
}
