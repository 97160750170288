// @flow
import type { RawSeasonsListType, SeasonListType } from 'types/Seasons';
import { nullSeasonsList } from 'types/Seasons';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertSeasonsListFromApi(rawSeasons: RawSeasonsListType): SeasonListType {
  if (!rawSeasons) {
    return nullSeasonsList;
  }

  const { id, slug, name, staff, joueurs, rencontres, competitions, resultats } = rawSeasons;

  return mergeIntoDefault(nullSeasonsList, {
    id,
    slug,
    name,
    staff,
    joueurs,
    rencontres,
    competitions,
    resultats,
  });
}