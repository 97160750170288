// @flow
import React, { PureComponent, Fragment } from 'react';

import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';

export type DispatchProps = {
  postUserPref: (action: string, value: any, token: string) => void,
};

type Props = DispatchProps;

type State = {
  licence: string,
};

// @TODO Remove && false on if edit
class EmptyStateLicence extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    licence: '',
  };

  _handleSubmit = () => {
    const { licence } = this.state;

    if (licence !== '' && false) {
      const { postUserPref } = this.props;
      // @TODO Check action name
      postUserPref('edit-licence', licence, '');
    }
  }

  render() {
    return (
      <Fragment>
        <h2 className="ft-h3 mb-3">Personnalisez votre expérience FFR</h2>
        <div className="box box--white card card--ticket card--ticket--nolicense">
          <div className="card__header">
            <i className="icon icon-jersey-empty"></i>
          </div>
          <div className="card__body">
            <p className="ft-secondary mb-2">
              Personnalisez votre espace FFR avec votre numéro de licence pour débloquer les éléments suivants :
            </p>
            <ul className="ft-black-secondary mb-2">
              <li>
                <i className="icon icon-star ft-color-tertiary"></i>
                <p>les statistiques de votre équipe</p>
              </li>
              <li>
                <i className="icon icon-star ft-color-tertiary"></i>
                <p>les derniers resultats de votre club</p>
              </li>
              <li>
                <i className="icon icon-star ft-color-tertiary"></i>
                <p>les informations de votre ligue et de votre club</p>
              </li>
              <li>
                <i className="icon icon-star ft-color-tertiary"></i>
                <p>les dernières actualités de votre ligue</p>
              </li>
              <li>
                <i className="icon icon-star ft-color-tertiary"></i>
                <p>des accès rapides à des contenus pour améliorer votre rugby</p>
              </li>
            </ul>
            <div>
              <TextInputMaterial
                isLight
                id='yagd48438'
                onChange={(value) => this.setState({ licence: value })}
                placeholder='N° de licence'
                label='N° de licence' />
              <a
                className="btn btn btn--primary btn--full"
                role='button'
                onClick={this._handleSubmit}
                tabIndex='0'
              >
                Ajouter ma license
              </a>
            </div>
          </div>
          <i className="icon icon-close js-closeCard"></i>
        </div>
      </Fragment>
    );
  }
}

export default EmptyStateLicence;
