// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import {
  FETCH_VIDEOS_CHANNEL_PLAYER_START,
  FETCH_VIDEOS_PLAYLIST_PLAYER_START,
  channelVideoPlayerFetched,
  channelVideoPlayerFetchError
} from 'actions/ffr-tv/youtubeVideosChannelPlayerActions';
import VideoPlayerChannelApi from 'api/ffrtv/VideoPlayerChannelApi';
import type {
  FETCH_VIDEOS_CHANNEL_PLAYER_START_ACTION,
  FETCH_VIDEOS_PLAYLIST_PLAYER_START_ACTION
} from 'actions/ffr-tv/youtubeVideosChannelPlayerActions';
import { getRandomInts } from 'utils/arrayUtils';

export default function(apiExecutor: ApiExecutorType) {
  const videoPlayerChannelApi = new VideoPlayerChannelApi(apiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_VIDEOS_CHANNEL_PLAYER_START, fetchVideosChannelPlayer);
    yield takeLatest(FETCH_VIDEOS_PLAYLIST_PLAYER_START, fetchVideosPlaylistPlayer);
  };

  function* fetchVideosChannelPlayer(action: FETCH_VIDEOS_CHANNEL_PLAYER_START_ACTION): Saga<void> {
    const {
      payload: { idVideo, idChannel }
    } = action;
    try {
      const videosId = yield call(videoPlayerChannelApi.fetchVideoPlayerChannelId, idVideo, idChannel);
      //  const randomVideoIds = getRandomInts(videosId.length, Math.min(videosId.length, 3)).map(random => videosId[random]);

      const videos = yield call(videoPlayerChannelApi.fetchVideoPlayerChannel, videosId.slice(0, 3));

      yield put(channelVideoPlayerFetched(videos));
    } catch (e) {
      yield put(channelVideoPlayerFetchError(e));
    }
  }
  function* fetchVideosPlaylistPlayer(action: FETCH_VIDEOS_PLAYLIST_PLAYER_START_ACTION) {
    const {
      payload: { idPlaylist, idVideo }
    } = action;
    try {
      const videosId = yield call(videoPlayerChannelApi.fetchVideoPlayerPlaylist, idPlaylist, idVideo);
      const randomVideoIds = getRandomInts(
        videosId.length,
        Math.min(videosId.length, 3)
      ).map(random => videosId[random]);
      const videos = yield call(videoPlayerChannelApi.fetchVideoPlayerChannel, randomVideoIds);
      yield put(channelVideoPlayerFetched(videos));
    } catch (e) {
      yield put(channelVideoPlayerFetchError(e));
    }
  }
}
