// @flow
import CardEnDirect from 'components/fragments/card/CardEnDirect';
import { connect } from 'react-redux';

import { fetchArticlesStaticCategories } from 'actions/newsletterActions';
import type { DispatchType, StateType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/fragments/card/CardEnDirect';

export default connect(
  (state: StateType): StateProps => ({
    status: state.newsletterState.status,
    categoriesArticle: state.newsletterState.categoriesArticle,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    fetchArticlesStaticCategories: () => dispatch(fetchArticlesStaticCategories())
  })
)(CardEnDirect);
