// @flow
export function getInitSsoOptions(): Object {
  let ssoOptions =  {
    url: "https://connexion.ffr.fr/auth",
    realm: "FFRpp",
    clientId: "ffr_pp",
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  };
  
  if (process.env.NODE_ENV === 'production' && !window.location.hostname.match('nextmap')) {
    ssoOptions.realm = 'FFR';
    ssoOptions.clientId = 'espaceperso';
  }
  return ssoOptions;
}

export function getCasEndpoint(action: string, route: string): string {
  // @TODO Remove comment to go to pp
  const { hostname } = window.location;
  let redirection = 'http://localhost:3000';

  if (process.env.NODE_ENV === 'production') {
  //   source = hostname;
    redirection = `https://${hostname}`;
  };

  return `${action}?redirect=${redirection}${route}`;
}

export function getCasRegister(route: string): string {
  // @TODO Remove comment to go to pp
  const { hostname } = window.location;

  let cas = 'https://connexion.ffr.fr/auth/realms/FFRpp/protocol/openid-connect/registrations?client_id=ffr_pp&response_type=code&scope=openid email offline_access';
  // @TODO Remove comment when push in pp
  if (process.env.NODE_ENV === 'production') {
    cas = 'https://connexion.ffr.fr/auth/realms/FFR/protocol/openid-connect/registrations?client_id=espaceperso&response_type=code&scope=openid email offline_access';
  }
  let redirection = 'http://localhost:3000';

  if (process.env.NODE_ENV === 'production') {
  //   source = hostname;
    redirection = `https://${hostname}`;
  };

  return `${cas}&redirect_uri=${redirection}${route}`;
}
