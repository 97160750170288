// @flow
import type { HomeCardType } from 'types/Settings';
import type { CardType } from 'types/PageList';
import type { AcademieType } from 'types/Academie';
import type { BlocContent } from 'types/NewsletterBloc';
import type { SEOMetaType } from 'types/SEOType';
import { nullSeoMeta } from 'types/SEOType';

export type DocumentType = {
  id: number,
  title: string,
  mime_type: string,
  src: string,
  date: string,
  tags: string
};

export type MemberType = {
  id: number,
  title: string,
  role: string,
  roles: Array<string>,
  email: string,
  telephone: string,
  website: string,
  date_naissance: string,
  structure_arbitrage: string,
  adresse: string,
  ville: string,
  code_postal: string,
};

export type DepartmentType = {
  id: number,
  letter: string,
  title: string,
  adress: {
    complement: string,
    street: string,
    postalCode: string,
    locality: string
  },
  mail: string,
  phone: string,
  personnes: Array<MemberType>
};

export type ArbitreType = {
  id: number,
  title: string,
  letter: string,
  personnes: Array<MemberType>
};

export type PageDetailType = SEOMetaType & {
  title: string,
  content: Array<BlocContent>,
  slug: string,
  link: string,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  template: string,
  label: Object,
  niveau: number,
  documents: Array<DocumentType>,
  membersGroups?: Array<{
    name: string,
    child: Array<MemberType>
  }>,
  liste_enfants?: Array<any>,
  departmentsGroups?: Array<DepartmentType>,
  arbitresGroups?: Array<ArbitreType>,
  academies?: Array<AcademieType>,
  sidebar_content: Array<BlocContent>,
  redirection: string
};

export type RawPageDetailType = SEOMetaType & {
  title: string,
  description: string,
  slug: string,
  link: string,
  content: {
    data: Array<BlocContent>
  },
  title: {
    raw: string
  },
  parent: string,
  label: Object,
  url: string,
  status: string,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  liste_enfants: Array<any>,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  logo_footer: string,
  class_ligue: string,
  titre_liste_cards: string,
  liste_cards: Array<CardType>,
  rencontre_background: string,
  featured_media: {
    src: string,
    legend: string
  },
  'gerer-club': HomeCardType,
  'bulletin-officiel': HomeCardType,
  template: string,
  niveau: number,
  documents?: Array<DocumentType>,
  personnes?: Array<MemberType>,
  personnes_departement?: Array<DepartmentType>,
  personnes_arbitres?: Array<ArbitreType>,
  academies?: Array<AcademieType>,
  sidebar_content: {
    data: Array<BlocContent>
  },
  redirect: {
    type: string,
    postid: string,
    title: string,
    url: string,
  }
};

export const nullPageDetail: PageDetailType = {
  ...nullSeoMeta,
  title: '',
  content: [],
  slug: '',
  link: '',
  label: {},
  rencontre_background: '',
  featured_media: {
    src: '',
    legend: ''
  },
  template: '',
  liste_enfants: [],
  niveau: 0,
  documents: [],
  membersGroups: [],
  departmentsGroups: [],
  arbitresGroups: [],
  academies: [],
  sidebar_content: [],
  redirection: '',
};
