// @flow

import { STATUS_DEFAULT, STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_YOUTUBE_PLAYLIST_START,
  FETCH_YOUTUBE_PLAYLIST_SUCCESS,
  FETCH_YOUTUBE_PLAYLIST_FAILURE
} from 'actions/ffr-tv/youtubePlaylistActions';

import { nullYoutubePlaylist, type YoutubePlaylist } from 'types/YoutubePlaylist';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  playlist: YoutubePlaylist,
  statusPlaylist: Status,
};

const initialState: State = {
  playlist: nullYoutubePlaylist,
  statusPlaylist: STATUS_DEFAULT,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_YOUTUBE_PLAYLIST_START:
      return {
        ...state,
        statusPlaylist: STATUS_LOADING
      };
    case FETCH_YOUTUBE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlist: action.payload.playlist,
        statusPlaylist: STATUS_SUCCESS
      };
    case FETCH_YOUTUBE_PLAYLIST_FAILURE:
      return {
        ...state,
        statusPlaylist: STATUS_FAILURE
      };

    default:
      return state;
  }
}
