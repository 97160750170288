// @flow

import { STATUS_LOADING, STATUS_DEFAULT, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_MATCHRESULT_START,
  FETCH_MATCHRESULT_SUCCESS,
  FETCH_MATCHRESULT_FAILURE,
  FETCH_MATCHFUTURETEAM_START,
  FETCH_MATCHFUTURETEAM_SUCCESS,
  FETCH_MATCHFUTURETEAM_FAILURE,
  FETCH_MATCHPASTTEAM_START,
  FETCH_MATCHPASTTEAM_SUCCESS,
  FETCH_MATCHPASTTEAM_FAILURE,
} from 'actions/matchActions';
import { nullMatchResult } from 'types/Match';

import type { MatchResultType } from 'types/Match';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  result: MatchResultType,
  results_future: Array<MatchResultType>,
  results_past: Array<MatchResultType>,
  status: Status
};

const initialState: State = {
  result: nullMatchResult,
  results_future: [],
  results_past: [],
  status: STATUS_DEFAULT
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_MATCHRESULT_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHRESULT_SUCCESS:
      return {
        ...state,
        result: action.payload.result,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHRESULT_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_MATCHFUTURETEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHFUTURETEAM_SUCCESS:
      return {
        ...state,
        results_future: action.payload.results_future,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHFUTURETEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case FETCH_MATCHPASTTEAM_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_MATCHPASTTEAM_SUCCESS:
      return {
        ...state,
        results_past: action.payload.results_past,
        status: STATUS_SUCCESS
      };
    case FETCH_MATCHPASTTEAM_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    default:
      return state;
  }
}
