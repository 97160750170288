// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import CompetitionApi from 'api/competition/CompetitionApi';
import SeasonsApi from 'api/seasons/SeasonsApi';
import {
  INITIAL_FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_START,
  FETCH_COMPETITIONS_BY_SLUG_START,
  competitionsFetched,
  competitionBySlugFetched,
  competitionsFetchError
} from 'actions/competitionsActions';
import { setDefaultSeason } from 'actions/filterOptionsActions';
import { seasonsFetched } from 'actions/seasonsActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const competitionApi = new CompetitionApi(apiExecutor);
  const seasonsApi = new SeasonsApi(staticApiExecutor);

  return function* competitionsSaga(): GeneratorType {
    yield takeLatest(FETCH_COMPETITIONS_START, fetchCompetitions);
    yield takeLatest(INITIAL_FETCH_COMPETITIONS_START, initialFetchCompetitions);
    yield takeLatest(FETCH_COMPETITIONS_BY_SLUG_START, fetchCompetitionsBySlug);
  };

  function* initialFetchCompetitions(action): Saga<void> {
    const {
      filters,
      filters: { equipe }
    } = action.payload;

    try {
      const seasons = yield call(seasonsApi.getSeasonsList, equipe);
      const seasonsCompetitions = seasons.filter(season => !!season.competitions.length);
      let season = seasonsCompetitions[seasonsCompetitions.length - 1].id;
      if (filters.season) {
        const seasonsFilter = seasonsCompetitions.filter(season => season.id === filters.season);
        if (seasonsFilter && seasonsFilter[0]) {
          season = filters.season;
        }
      }
      const competitions = yield call(competitionApi.fetchCompetition, {
        ...filters,
        season
      });
      let defaultSeason = seasonsCompetitions[seasonsCompetitions.length - 1].name;
      if (filters.season && filters.season[0]) {
        const saisonFilter = seasonsCompetitions.filter(saison => saison.id === filters.season[0]);
        if (saisonFilter && saisonFilter[0]) {
          defaultSeason = saisonFilter[0].name;
        }
      }
      yield put(setDefaultSeason(defaultSeason));
      yield put(seasonsFetched(seasons));
      yield put(competitionsFetched(competitions));
    } catch (e) {
      yield put(competitionsFetchError());
    }
  }

  function* fetchCompetitions(action): Saga<void> {
    const { filters } = action.payload;
    try {
      const competitions = yield call(competitionApi.fetchCompetition, filters);
      yield put(competitionsFetched(competitions));
    } catch (e) {
      yield put(competitionsFetchError());
    }
  }

  function* fetchCompetitionsBySlug(action): Saga<void> {
    const { slug } = action.payload;
    try {
      const competition = yield call(competitionApi.fetchCompetitionBySlug, slug);
      yield put(competitionBySlugFetched(competition));
    } catch (e) {
      yield put(competitionsFetchError());
    }
  }
}
