// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import PlaylistApi from 'api/ffrtv/PlaylistApi';

import {
  FETCH_YOUTUBE_PLAYLIST_START,
  playlistFetched,
  playlistFetchError,
  type FETCH_YOUTUBE_PLAYLIST_START_ACTION
} from 'actions/ffr-tv/youtubePlaylistActions';

export default function(apiExecutor: ApiExecutorType) {
  const playlistApi = new PlaylistApi(apiExecutor);
  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_YOUTUBE_PLAYLIST_START, fetchYoutubePlaylist);
  };

  function* fetchYoutubePlaylist(action: FETCH_YOUTUBE_PLAYLIST_START_ACTION): Saga<void> {
    const {
      payload: { idPlaylist }
    } = action;
    try {
      const playlist = yield call(playlistApi.fetchPlaylist, idPlaylist);
      yield put(playlistFetched(playlist));
    } catch (e) {
      yield put(playlistFetchError());
    }
  }
}
