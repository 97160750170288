// @flow

import type { YoutubeVideo } from 'types/YoutubeVideo';

export const FETCH_VIDEOS_CHANNEL_PLAYER_START = 'FETCH_VIDEOS_CHANNEL_PLAYER_START';
export const FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS = 'FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS';
export const FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE = 'FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE';
export const FETCH_VIDEOS_PLAYLIST_PLAYER_START = 'FETCH_VIDEOS_PLAYLIST_PLAYER_START';

export type FETCH_VIDEOS_PLAYLIST_PLAYER_START_ACTION = {
  type: 'FETCH_VIDEOS_PLAYLIST_PLAYER_START',
  payload: {
    idPlaylist: string,
    idVideo: string
  }
};

export type FETCH_VIDEOS_CHANNEL_PLAYER_START_ACTION = {
  type: 'FETCH_VIDEOS_CHANNEL_PLAYER_START',
  payload: {
    idVideo: string,
    idChannel: string
  }
};

export type FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE_ACTION = {
  type: 'FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE'
};

export type FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS_ACTION = {
  type: 'FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS',
  payload: {
    videos: Array<YoutubeVideo>
  }
};

export type Action =
  | FETCH_VIDEOS_CHANNEL_PLAYER_START_ACTION
  | FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE_ACTION
  | FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS_ACTION
  | FETCH_VIDEOS_PLAYLIST_PLAYER_START_ACTION;

export function fetchPlaylistVideoPlayer(
  idPlaylist: string,
  idVideo: string
): FETCH_VIDEOS_PLAYLIST_PLAYER_START_ACTION {
  return {
    type: FETCH_VIDEOS_PLAYLIST_PLAYER_START,
    payload: {
      idPlaylist,
      idVideo
    }
  };
}

export function fetchChannelVideoPlayer(idVideo: string, idChannel: string): FETCH_VIDEOS_CHANNEL_PLAYER_START_ACTION {
  return {
    type: FETCH_VIDEOS_CHANNEL_PLAYER_START,
    payload: {
      idVideo,
      idChannel
    }
  };
}

export function channelVideoPlayerFetched(videos: Array<YoutubeVideo>): FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS_ACTION {
  return {
    type: FETCH_VIDEOS_CHANNEL_PLAYER_SUCCESS,
    payload: { videos }
  };
}

export function channelVideoPlayerFetchError(error: any): FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE_ACTION {
  return {
    type: FETCH_VIDEOS_CHANNEL_PLAYER_FAILURE
  };
}
