// @flow
import { connect } from 'react-redux';

import EmptyStateLicence from 'components/profile/DashboardHome/EmptyStateLicence';
import { postUserPref } from 'actions/userActions';

import type { EditProfileValueType } from 'types/User';
import type { DispatchProps } from 'components/profile/DashboardHome/EmptyStateLicence';
import type { DispatchType } from 'types/Actions';

export default connect(
  null,
  (dispatch: DispatchType): DispatchProps => ({
    postUserPref: (action: string, value: EditProfileValueType, token: string) => dispatch(postUserPref(action, value, token)),
  })
)(EmptyStateLicence);
