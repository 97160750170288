// @flow
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';

import { MEILISEARCH_KEY, MEILISEARCH_URL } from 'constants/api';
import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

import Clubs from './ClubSearchClubs';
import ClubFinderBan from './ClubFinderBan';

const searchClient = instantMeiliSearch(MEILISEARCH_URL, MEILISEARCH_KEY);

type Props = {
  handleInputClicked: Function,
  handleClickOutside: Function,
  geolocalisation: boolean,
  userPref: FullUserType
};

type State = {
  query: string,
  autour: boolean,
  redirect: boolean
};

class ClubSearch extends PureComponent<Props, State> {
  props: Props;
  state: State = {
    cities: [],
    clubs: [],
    query: '',
    autour: false,
    redirect: false
  };
  _input: ?HTMLElement;

  handleClick = (event: Object) => {
    const { userPref } = this.props;
    const dataSearchType = event.target.dataset.type;
    const dataSearchParam = event.target.dataset.param;

    return handleTagEvent(
      'trouver_club_header',
      `filtre_${dataSearchType}`,
      dataSearchParam,
      userPref
    )();
  }

  handleClickOutside = (e: any) => {
    if (this._input && !this._input.contains(e.target)) {
      this.props.handleClickOutside();
    }
  };

  handleInputChanged = (e: any) => {
    const query = e ? e.target.value.toLowerCase() : '';
    this.setState({ query });
  };

  handleSubmit = () => {
    this.setState({ redirect: true, autour: this.props.geolocalisation });
  };

  handleSubmitWithoutGeoloc = () => {
    this.setState({ redirect: true, autour: false });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.handleInputChanged(null);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    const { userPref } = this.props;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/trouver-un-club-de-rugby/resultats-de-recherche/',
            state: {
              query: this.state.query,
              autour: this.state.autour,
            }
          }}
        />
      );
    }

    return (
      <>
        <div className="search-club__wrapper mb-5 mb-md-3" ref={input => (this._input = input)}>
          <div className="input input--search ">
            <input
              type="search"
              id="yagd2adssd3"
              placeholder="Ville, adresse, nom de club"
              autoComplete="off"
              onClick={this.props.handleInputClicked}
              onChange={this.handleInputChanged}
              aria-label={'Cherchez un club par ville, adresse, nom de club'}
            />
          </div>
          {this.props.geolocalisation &&
            <button
              style={{ display: 'none' }}
              className="btn btn--white"
              type="submit"
              onClick={handleTagEvent(
                'recherche non geolocalisee',
                'recherche non geolocalisee',
                window.location.pathname,
                userPref,
                this.handleSubmitWithoutGeoloc
              )}
            >
              <i className="icon icon-geoloc" />
              <span>Rechercher</span>
            </button>
          }
          <button
            className="btn btn--white"
            type="submit"
            onClick={handleTagEvent(
              'recherche geolocalisee',
              'recherche geolocalisee autour de moi',
              window.location.pathname,
              userPref,
              this.handleSubmit
            )}
          >
            <i className="icon icon-geoloc" />
            <span>{this.props.geolocalisation ? 'Voir les clubs autour de moi' : 'Rechercher'}</span>
          </button>

          <div className="search-club__autocompletion">
            <div>
              <ClubFinderBan query={this.state.query} />
            </div>

            <div>
              <p className="ft-500 mb-1 mb-md-2">Clubs</p>
              <InstantSearch indexName="Clubs" searchClient={searchClient}>
                <Configure query={this.state.query} distinct hitsPerPage={5} />

                <Clubs />
              </InstantSearch>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClubSearch;
