// @flow

import { takeLatest, put, call, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';

import { articlesFetched, articlesFetchError } from 'actions/articlesActions';
import { YOUTUBE_ID_CHANNEL } from 'constants/tvConstants';

import {
  FETCH_VIDEO_PLAYER_START,
  videoPlayerFetched,
  videoPlayerFetchError
} from 'actions/ffr-tv/youtubeVideoPlayerActions';

import { YOUTUBE_REGEX, ARTICLE_REGEX } from 'constants/tvConstants';
import VideosApi from 'api/ffrtv/VideosChannelApi';
import VideoPlayerApi from 'api/ffrtv/VideoPlayerApi';
import ArticleApi from 'api/article/ArticleApi';
import type { FETCH_VIDEO_PLAYER_START_ACTION } from 'actions/ffr-tv/youtubeVideoPlayerActions';
import { getIdVideoFromText, getTextWithoutUrlFromText, getArticlesSlugFromText } from 'utils/youtubeApiUtils';

export default function(apiExecutor: ApiExecutorType, articleApiExecutor: ApiExecutorType) {
  const videoPlayerApi = new VideoPlayerApi(apiExecutor);
  const videosApi = new VideosApi(apiExecutor);
  const articleApi = new ArticleApi(articleApiExecutor);

  return function* youtubeSaga(): GeneratorType {
    yield takeLatest(FETCH_VIDEO_PLAYER_START, fetchVideoPlayer);
  };

  function* fetchVideoPlayer(action: FETCH_VIDEO_PLAYER_START_ACTION): Saga<void> {
    const {
      payload: { idVideo }
    } = action;
    try {
      const video = yield call(videoPlayerApi.fetchVideoPlayer, idVideo);

      if (YOUTUBE_ID_CHANNEL !== video.channelId) throw new Error('Not a video from the right channel');

      const articlesSlug = getArticlesSlugFromText(video.description, ARTICLE_REGEX).slice(0, 3);
      const youtubeIdsToFetch = getIdVideoFromText(video.description, YOUTUBE_REGEX).slice(0, 3 - articlesSlug.length);

      const videoText = getTextWithoutUrlFromText(
        getTextWithoutUrlFromText(video.description, YOUTUBE_REGEX),
        ARTICLE_REGEX
      );

      const articles = yield all(articlesSlug.map(slug => call(articleApi.fetchArticleBySlug, slug)));
      const suggestedVideos =
        youtubeIdsToFetch.length > 0 ? yield call(videosApi.fetchYoutubeVideoFromIds, youtubeIdsToFetch) : [];

      yield all([
        put(videoPlayerFetched({ ...video, description: videoText }, suggestedVideos)),
        put(articlesFetched(articles))
      ]);
    } catch (e) {
      yield put(videoPlayerFetchError(e));
      yield put(articlesFetchError(e));
    }
  }
}
